import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useDispatch, useSelector} from "react-redux";
import DateBlockComponent from "../Forms/NewAddEventForm/DateBlockComponent";
import CustomButton from "../CustomButton/CustomButton";
import {useTranslation} from "react-i18next";
import services from "../../services";
import {formatDate} from "../../helpers/dateFormatter";
import {archivementStatusPreValues} from "../../helpers/archivementStatusStaticList";
import {showSnackbar} from "../../store/snackbar/actions";

const ArchivementForm = ({eventId, onClose}) => {
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [isOpenDateStartPicker, setOpenDateStartPicker] = useState(false);
    const [achievementId, setAchievementId] = useState(null);

    const [listName, setListName] = useState("");
    const [listNameError, setListNameError] = useState(false);

    const [achievementStatus, setAchievementStatus] = useState(null);
    const [achievementStatusError, setAchievementStatusError] = useState(false);

    const darkTheme = useSelector(state => state.darkTheme);

    const [listPreValues, setListPreValues] = useState([]);

    useEffect(() => {
        setLoading(true);
        if(user && user.hasOwnProperty('id')) {
            // fetch user own list
            // if empty add this
            services.userAchievementList.find({
                query: {
                    ownerId: user.id
                }
            }).then((res) => {
                if(res.data.length > 0) {
                    setListPreValues(res.data);
                    if(eventId !== null) {
                        services.userAchievementRecord.find({
                            query: {
                                ownerId: user.id,
                                eventId: eventId
                            }
                        }).then((record) => {
                            if(record.data.length > 0) {
                                let item = record.data[0];
                                setAchievementId(item.id);
                                setStartDate(new Date(item.achieved_date));
                                let selectedStatus = archivementStatusPreValues.find(i => i.id === item.statusId);
                                setAchievementStatus(selectedStatus);
                                let selectedList = res.data.find(i => i.id === item.listId);
                                setListName(selectedList.label);
                                setLoading(false);
                            }else {
                                setLoading(false);
                            }
                        })
                    } else {
                        setLoading(false);
                    }
                } else {
                    setListPreValues([
                        {
                            id: "00000-0000-000-0000-0000",
                            label: `${user.nickname}'s List`
                        }
                    ]);
                    setLoading(false);
                }
            });
        } else {
            setListPreValues([
                {
                    id: "00000-0000-000-0000-0000",
                    label: `Default List`
                },
                {
                    id: "00000-0000-000-0000-0001",
                    label: `Games List`
                },
                {
                    id: "00000-0000-000-0000-0001",
                    label: `Films List`
                },
                {
                    id: "00000-0000-000-0000-0001",
                    label: `TV List`
                }
            ]);
            setLoading(false);
        }
    }, [user, eventId]);

    const handleListInputChange = (e) => {
        let value = e.target.value;
        setListName(value);
        !value || value.length === 0 ? setListNameError(true) : setListNameError(false)
    }

    const handleListOnChange = (e, val) => {
        setListName(val);
        !val ? setListNameError(true) : setListNameError(false)
    }

    const handleStatusOnChange = (e, val) => {
        setAchievementStatus(val);
        !val ? setAchievementStatusError(true) : setAchievementStatusError(false)
    }

    const handleChangeStartDate = (new_date) => {
        setStartDate(new_date);
        setOpenDateStartPicker(false);
    }

    const handleOpenPicker = (picker) => {
        setOpenDateStartPicker(val => !val);
    }

    const handleClosePicker = () => {
        setOpenDateStartPicker(false);
    }

    const handleSubmit = () => {
        if(listName.length > 0 && achievementStatus && achievementStatus.hasOwnProperty('id')) {
            setLoading(true);
            if(achievementId !== null) {
                services.userAchievementRecord.patch(achievementId, {
                    listName: listName,
                    eventId: eventId,
                    statusId: achievementStatus.id,
                    achieved_date: formatDate(startDate, 'yyyy-MM-dd 00:00:00.000')
                }).then(res => {
                    dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_patch_success"), type: "success"}));
                    onClose();
                }).catch(err => {
                    dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_patch_error"), type: "error"}));
                    setLoading(true);
                });
            } else {
                services.userAchievementRecord.create({
                    listName: listName,
                    eventId: eventId,
                    statusId: achievementStatus.id,
                    achieved_date: formatDate(startDate, 'yyyy-MM-dd 00:00:00.000')
                }).then(res => {
                    dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_create_success"), type: "success"}));
                    onClose();
                }).catch(err => {
                    dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_create_error"), type: "error"}));
                    setLoading(false);
                });
            }
        }
    };

    const handleDelete = () => {
        setLoading(true);
        services.userAchievementRecord.remove(achievementId).then(res => {
            dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_remove_success"), type: "success"}));
            onClose();
        }).catch(err => {
            dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_remove_error"), type: "error"}));
            setLoading(false);
        });
    }

    return (
        <Box p={1}>
            <div className="title">{achievementId !== null ? t('archivementForm:archivement_form_edit_title') : t('archivementForm:archivement_form_title')}</div>
            <div className="archivements-modal--fields">
                <div>
                    <label className="field-label">{t('archivementForm:archivement_form_list_label')}</label>
                    <Autocomplete
                        freeSolo
                        disableClearable
                        inputValue={listName}
                        renderInput={params => (
                            <TextField
                                {...params}
                                error={listNameError}

                                variant="outlined"
                                placeholder={t('archivementForm:archivement_form_list_placeholder')}
                                fullWidth
                            />
                        )}
                        className={"tag-select " + (darkTheme ? "tag-select-dark" : "tag-select-light")}
                        autoSelect={false}
                        autoComplete={true}
                        options={listPreValues.map((option) => option.label)}
                        onChange={handleListOnChange}
                        onInputChange={handleListInputChange}
                    />
                </div>
                <div>
                    <label className="field-label">{t('archivementForm:archivement_form_status_label')}</label>
                    <Autocomplete
                        error={achievementStatusError}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('archivementForm:archivement_form_status_placeholder')}
                                fullWidth
                            />
                        )}
                        className={"tag-select " + (darkTheme ? "tag-select-dark" : "tag-select-light")}
                        value={achievementStatus}
                        options={archivementStatusPreValues}
                        getOptionLabel={(option) => option.hasOwnProperty(`text_${i18n.language}`) ? option[`text_${i18n.language}`] : option.label}
                        onChange={handleStatusOnChange}
                        freeSolo={false}
                    />
                </div>
            </div>
            <div className="archivements-modal--fields is-special">
                <div>
                    <label className="field-label">{t('archivementForm:archivement_form_date_label')}</label>
                    <DateBlockComponent
                        type="start"
                        datePrintFormat={'E, dd LLL yyyy'}
                        label={"Fecha"}
                        date={startDate}
                        handleChange={handleChangeStartDate}
                        onOpenPicker={handleOpenPicker}
                        onClosePicker={handleClosePicker}
                        pickerVisible={isOpenDateStartPicker}
                        hideTime={true}/>
                </div>
            </div>
            <Box mt={2} mb={2} ml={1} mr={1} display="flex" flexDirection="row" justifyContent="center">
                <CustomButton className="cancel-btn wide" size="large" disabled={loading}
                              onClick={onClose}> {t('archivementForm:archivement_form_cancel_btn')} </CustomButton>
                <div style={{
                    width: 32
                }}></div>
                <CustomButton className="submit-btn wide" loading={loading} disabled={loading} size="large"
                              onClick={(e) => handleSubmit(e)}> {achievementId !== null ? t('archivementForm:archivement_form_save_btn') : t('archivementForm:archivement_form_create_btn')} </CustomButton>
            </Box>

            {achievementId !== null && (
                <Box mt={2} mb={2} ml={1} mr={1} display="flex" flexDirection="row" justifyContent="center">
                    <CustomButton className="delete-btn wide" loading={loading} disabled={loading} size="large"
                                  onClick={(e) => handleDelete(e)}> {t('archivementForm:archivement_form_delete_btn')} </CustomButton>
                </Box>
            )}

        </Box>
    );
};

export default ArchivementForm;
