const initailState = []

const openedProfileNextEventsReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_OPENED_PROFILE_NEXT_EVENTS':
            return state =  action.payload
        case 'UNSET_OPENED_PROFILE_NEXT_EVENTS':
            return state = initailState
        default:
            return state
    }
}

export default openedProfileNextEventsReducer;
