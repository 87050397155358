import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import { Editor } from '@tinymce/tinymce-react';
import CustomButton from "../../../../../CustomButton/CustomButton";
import './_landing_custom_tab.scss';

const LandingCustomTabForm = ({ defaultData, onSubmit, onClose }) => {
    const user = useSelector(state => state.user);
    const darkTheme = useSelector(state => state.darkTheme);
    const [title, setTitle] = useState("");
    const [errorTitle, setErrorTitle] = useState(false);
    const [body, setBody] = useState("");
    const [previewBody, setPreviewBody] = useState("");

    useEffect(() => {
        if (defaultData && defaultData.hasOwnProperty('id')) {
            setTitle(defaultData.title)
            setBody(defaultData.text)
            setPreviewBody(defaultData.preview_body)
        }
    }, [defaultData])

    const handleSubmit = () => {
        let data = {
            type: 'custom',
            text: body,
            custom_fields: {
                title: title,
                preview_body: previewBody
            }
        }
        onSubmit(data, () => {
            onClose();
        }, () => {
            console.log("error!");
        })
    }

    return (
        <div className={"landing-custom-tab-form"}>
            <div className={"landing-custom-tab-form-row"}>
                <TextField
                    id="tab-title"
                    label="Nombre pestaña"
                    className={darkTheme ? "is-input-dark" : "is-input-light"}
                    value={title}
                    error={errorTitle}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <label>Preview body</label>
                <Editor
                    apiKey={"xvwu6kmhs7cvj1ktqqz2p7xmd99dpvq96vc78ebddwkwnxo1"}
                    initialValue={previewBody}
                    onEditorChange={(value, editor) => setPreviewBody(value)}
                    init={{
                        height: 300,
                        directionality: 'ltr',
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'media table paste code wordcount'
                        ],
                        toolbar:
                            [
                                'bold italic forecolor | outdent indent | alignleft aligncenter alignright alignjustify | bullist numlist | visualblocks code',
                                'link image media menuTwitchBtn'
                            ],
                        setup: (editor) => {
                            editor.ui.registry.addMenuButton('menuTwitchBtn', {
                                text: 'Twitch',
                                fetch: function (callback) {
                                    var items = [
                                        {
                                            type: 'menuitem',
                                            text: 'Insertar Vídeo',
                                            onAction: function (_) {
                                                editor.windowManager.open({
                                                    title: 'Dirección web del video', // The dialog's title - displayed in the dialog header
                                                    body: {
                                                        type: 'panel',
                                                        items: [
                                                            {
                                                                type: 'input',
                                                                name: 'url',
                                                                label: 'URL',
                                                                placeholder: 'https://www.twitch.tv/videos/592577871'
                                                            },
                                                        ]
                                                    },
                                                    buttons: [
                                                        {
                                                            type: 'cancel',
                                                            name: 'closeButton',
                                                            text: 'Cancel'
                                                        },
                                                        {
                                                            type: 'submit',
                                                            name: 'submitButton',
                                                            text: 'Insertar',
                                                            primary: true
                                                        }
                                                    ],
                                                    initialData: {
                                                        url: '',
                                                    },
                                                    onSubmit: (api) => {
                                                        let data = api.getData();
                                                        let url = data.url.replace('https://', '').replace('www.', '');
                                                        let match = url.match(/^twitch\.tv\/videos\/([\w-]+)(?:\?t=([\w-]+)|)/)
                                                        if (match.length > 0) {
                                                            editor.execCommand('mceInsertContent', false, '<p><div style="position: relative; height: 350px; overflow: hidden;">' +
                                                                '<iframe\n' +
                                                                '    src="https://player.twitch.tv/?video=' + match[1] + (match.length === 2 ? "&time=" + match[2] : "") + '&parent=latencyapp.com&parent=www.latencyapp.com&autoplay=false"\n' +
                                                                '    height="350"\n' +
                                                                '    width="100%"\n' +
                                                                '    frameborder="0"\n' +
                                                                '    scrolling="0"\n' +
                                                                '    style="position:absolute;height:100%;width:100%;min-height:350px;"\n' +
                                                                '    allowfullscreen="1">\n' +
                                                                '</iframe>' +
                                                                '</div></p>');
                                                        }
                                                        api.close();
                                                    }
                                                });
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Insertar Clip',
                                            onAction: function (_) {
                                                editor.windowManager.open({
                                                    title: 'Dirección web del clip', // The dialog's title - displayed in the dialog header
                                                    body: {
                                                        type: 'panel',
                                                        items: [
                                                            {
                                                                type: 'input',
                                                                name: 'url',
                                                                label: 'URL',
                                                                placeholder: 'https://www.twitch.tv/starwraith/clip/DeafGloriousChickpeaGrammarKing'
                                                            },
                                                        ]
                                                    },
                                                    buttons: [
                                                        {
                                                            type: 'cancel',
                                                            name: 'closeButton',
                                                            text: 'Cancel'
                                                        },
                                                        {
                                                            type: 'submit',
                                                            name: 'submitButton',
                                                            text: 'Insertar',
                                                            primary: true
                                                        }
                                                    ],
                                                    initialData: {
                                                        url: '',
                                                    },
                                                    onSubmit: (api) => {
                                                        let data = api.getData();
                                                        let url = data.url.replace('https://', '').replace('www.', '');
                                                        let match = url.match(/^twitch\.tv\/([\w-]+)\/clip\/([\w-]+)/)
                                                        if (match && match.length > 0) {
                                                            editor.execCommand('mceInsertContent', false, '<p><div style="position: relative; height: 350px; overflow: hidden;">' +
                                                                '<iframe\n' +
                                                                '    src="https://clips.twitch.tv/embed?clip=' + match[2] + '&parent=latencyapp.com&parent=www.latencyapp.com&autoplay=false"\n' +
                                                                '    height="350"\n' +
                                                                '    width="100%"\n' +
                                                                '    frameborder="0"\n' +
                                                                '    scrolling="0"\n' +
                                                                '    style="position:absolute;height:100%;width:100%;min-height:350px;"\n' +
                                                                '    allowfullscreen="1">\n' +
                                                                '</iframe>' +
                                                                '</div></p>');
                                                        } else {
                                                            let newMatch = url.match(/^clips\.twitch\.tv\/([\w-]+)/)
                                                            if (newMatch && newMatch.length > 0) {
                                                                editor.execCommand('mceInsertContent', false, '<p><div style="position: relative; height: 350px; overflow: hidden;">' +
                                                                    '<iframe\n' +
                                                                    '    src="https://clips.twitch.tv/embed?clip=' + newMatch[1] + '&parent=latencyapp.com&parent=www.latencyapp.com&autoplay=false"\n' +
                                                                    '    height="350"\n' +
                                                                    '    width="100%"\n' +
                                                                    '    frameborder="0"\n' +
                                                                    '    scrolling="0"\n' +
                                                                    '    style="position:absolute;height:100%;width:100%;min-height:350px;"\n' +
                                                                    '    allowfullscreen="1">\n' +
                                                                    '</iframe>' +
                                                                    '</div></p>');
                                                            }
                                                        }
                                                        api.close();
                                                    }
                                                });
                                            }
                                        },
                                    ];
                                    callback(items);
                                }
                            });
                        },
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        image_uploadtab: true,
                        relative_urls: false,
                    }}
                />
                <br />
                <label>Body</label>
                <Editor
                    apiKey={"xvwu6kmhs7cvj1ktqqz2p7xmd99dpvq96vc78ebddwkwnxo1"}
                    initialValue={body}
                    onEditorChange={(value, editor) => setBody(value)}
                    init={{
                        height: 300,
                        directionality: 'ltr',
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'media table paste code wordcount'
                        ],
                        toolbar:
                            [
                                'bold italic forecolor | outdent indent | alignleft aligncenter alignright alignjustify | bullist numlist | visualblocks code',
                                'link image media menuTwitchBtn'
                            ],
                        setup: (editor) => {
                            editor.ui.registry.addMenuButton('menuTwitchBtn', {
                                text: 'Twitch',
                                fetch: function (callback) {
                                    var items = [
                                        {
                                            type: 'menuitem',
                                            text: 'Insertar Vídeo',
                                            onAction: function (_) {
                                                editor.windowManager.open({
                                                    title: 'Dirección web del video', // The dialog's title - displayed in the dialog header
                                                    body: {
                                                        type: 'panel',
                                                        items: [
                                                            {
                                                                type: 'input',
                                                                name: 'url',
                                                                label: 'URL',
                                                                placeholder: 'https://www.twitch.tv/videos/592577871'
                                                            },
                                                        ]
                                                    },
                                                    buttons: [
                                                        {
                                                            type: 'cancel',
                                                            name: 'closeButton',
                                                            text: 'Cancel'
                                                        },
                                                        {
                                                            type: 'submit',
                                                            name: 'submitButton',
                                                            text: 'Insertar',
                                                            primary: true
                                                        }
                                                    ],
                                                    initialData: {
                                                        url: '',
                                                    },
                                                    onSubmit: (api) => {
                                                        let data = api.getData();
                                                        let url = data.url.replace('https://', '').replace('www.', '');
                                                        let match = url.match(/^twitch\.tv\/videos\/([\w-]+)(?:\?t=([\w-]+)|)/)
                                                        if (match.length > 0) {
                                                            editor.execCommand('mceInsertContent', false, '<p><div style="position: relative; height: 350px; overflow: hidden;">' +
                                                                '<iframe\n' +
                                                                '    src="https://player.twitch.tv/?video=' + match[1] + (match.length === 2 ? "&time=" + match[2] : "") + '&parent=latencyapp.com&parent=www.latencyapp.com&autoplay=false"\n' +
                                                                '    height="350"\n' +
                                                                '    width="100%"\n' +
                                                                '    frameborder="0"\n' +
                                                                '    scrolling="0"\n' +
                                                                '    style="position:absolute;height:100%;width:100%;min-height:350px;"\n' +
                                                                '    allowfullscreen="1">\n' +
                                                                '</iframe>' +
                                                                '</div></p>');
                                                        }
                                                        api.close();
                                                    }
                                                });
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Insertar Clip',
                                            onAction: function (_) {
                                                editor.windowManager.open({
                                                    title: 'Dirección web del clip', // The dialog's title - displayed in the dialog header
                                                    body: {
                                                        type: 'panel',
                                                        items: [
                                                            {
                                                                type: 'input',
                                                                name: 'url',
                                                                label: 'URL',
                                                                placeholder: 'https://www.twitch.tv/starwraith/clip/DeafGloriousChickpeaGrammarKing'
                                                            },
                                                        ]
                                                    },
                                                    buttons: [
                                                        {
                                                            type: 'cancel',
                                                            name: 'closeButton',
                                                            text: 'Cancel'
                                                        },
                                                        {
                                                            type: 'submit',
                                                            name: 'submitButton',
                                                            text: 'Insertar',
                                                            primary: true
                                                        }
                                                    ],
                                                    initialData: {
                                                        url: '',
                                                    },
                                                    onSubmit: (api) => {
                                                        let data = api.getData();
                                                        let url = data.url.replace('https://', '').replace('www.', '');
                                                        let match = url.match(/^twitch\.tv\/([\w-]+)\/clip\/([\w-]+)/)
                                                        if (match && match.length > 0) {
                                                            editor.execCommand('mceInsertContent', false, '<p><div style="position: relative; height: 350px; overflow: hidden;">' +
                                                                '<iframe\n' +
                                                                '    src="https://clips.twitch.tv/embed?clip=' + match[2] + '&parent=latencyapp.com&parent=www.latencyapp.com&autoplay=false"\n' +
                                                                '    height="350"\n' +
                                                                '    width="100%"\n' +
                                                                '    frameborder="0"\n' +
                                                                '    scrolling="0"\n' +
                                                                '    style="position:absolute;height:100%;width:100%;min-height:350px;"\n' +
                                                                '    allowfullscreen="1">\n' +
                                                                '</iframe>' +
                                                                '</div></p>');
                                                        } else {
                                                            let newMatch = url.match(/^clips\.twitch\.tv\/([\w-]+)/)
                                                            if (newMatch && newMatch.length > 0) {
                                                                editor.execCommand('mceInsertContent', false, '<p><div style="position: relative; height: 350px; overflow: hidden;">' +
                                                                    '<iframe\n' +
                                                                    '    src="https://clips.twitch.tv/embed?clip=' + newMatch[1] + '&parent=latencyapp.com&parent=www.latencyapp.com&autoplay=false"\n' +
                                                                    '    height="350"\n' +
                                                                    '    width="100%"\n' +
                                                                    '    frameborder="0"\n' +
                                                                    '    scrolling="0"\n' +
                                                                    '    style="position:absolute;height:100%;width:100%;min-height:350px;"\n' +
                                                                    '    allowfullscreen="1">\n' +
                                                                    '</iframe>' +
                                                                    '</div></p>');
                                                            }
                                                        }
                                                        api.close();
                                                    }
                                                });
                                            }
                                        },
                                    ];
                                    callback(items);
                                }
                            });
                        },
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        image_uploadtab: true,
                        relative_urls: false,
                    }}
                />
            </div>
            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                <CustomButton className="cancel-btn" onClick={onClose}>Cancel</CustomButton>
                <CustomButton loading={false} className="submit-btn" color="primary" onClick={handleSubmit}>{defaultData && defaultData.hasOwnProperty('id') ? "Save changes" : "Add new"}</CustomButton>
            </Box>
        </div>
    );
};

export default LandingCustomTabForm;
