import React from 'react';
import {Box} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import './_new_explore_page.scss';
import ExploreFeaturedEventsComponent
    from "../../components/Explore/ExploreFeaturedEvents/ExploreFeaturedEventsComponent";
import ExploreFeaturedUsersComponent from "../../components/Explore/ExploreFeaturedUsers/ExploreFeaturedUsersComponent";
import {useSelector} from "react-redux";
import ExploreFeaturedCategoriesComponent
    from "../../components/Explore/ExploreFeaturedCategories/ExploreFeaturedCategoriesComponent";

const NewExplorePage = () => {
    const deviceView = useSelector(state => state.deviceView);
    const { i18n, t } = useTranslation(['explore']);

    return (
        <div className={`super-explore-page is-${deviceView}`}>
            <Helmet>
                <title>{t('UserMenu:menu_label_explore')} / Latency</title>
                <html lang={i18n.language} />
                {i18n.language === "es" ? (
                    <meta name="description" content={`Construye tu agenda interactiva en Latency. Encuentra y sigue tus intereses para disfrutar de un calendario de eventos en cualquier momento del día.`} />
                ) : (
                    <meta name="description" content={`Get your own interactive agenda in Latency. Find and follow your interests to enjoy a calendar of events at any time of the day.`} />
                )}
                <link rel="canonical" href={`https://latencyapp.com/explore`} />
            </Helmet>

            <section className={`super-explore-page--section`}>
                <h2 className={`super-explore-page--section-heading`}>{t('explore_title_featured_users')}</h2>

                <div className={`super-explore-page--section-accounts-content`}>
                    <ExploreFeaturedUsersComponent />
                </div>
            </section>

            <section className={`super-explore-page--section is-double is-${deviceView}`}>
                <div>
                    <h2 className={`super-explore-page--section-heading`}>TRENDING</h2>
                    <div className={`super-explore-page--section-trend-content is-${deviceView}`}>
                        <Box bgcolor="grid.content" className={"landing-box"}>
                            <a href={"/valorant"}><img
                                src={deviceView === "browser" ? "/images/valorant-new.jpg" : "/dist/special/valorant-mobile.jpg"} alt="Valorant España"
                                width={"100%"} height={"100%"}/></a>
                        </Box>
                    </div>
                </div>
                <div>
                    <h2 className={`super-explore-page--section-heading`}>{t('explore_title_more_events')}</h2>
                    <div className={`super-explore-page--section-events-content`}>
                        <ExploreFeaturedEventsComponent />
                    </div>
                </div>
            </section>

            <section className={`super-explore-page--section`}>
                <h2 className={`super-explore-page--section-heading`}>{t('explore_title_featured_cats')}</h2>
                <div className={`super-explore-page--section-cats-content is-${deviceView}`}>
                    <ExploreFeaturedCategoriesComponent />
                </div>
            </section>

            {/*
            <div className={`super-explore-page--section`}>
                <div className={`super-explore-page--section-heading`}>{t('explore_title_featured_trends')}</div>
                <div className={`super-explore-page--section-trends-content`}>
                    <Box bgcolor="grid.main" className={"landing-box"}>
                        <a href={"/espacio.crypto/live"}><img
                            src={"/images/crypto_landing.jpg"} alt="Espacio Crypto"
                            height={"100%"}/></a>
                    </Box>
                    <Box bgcolor="grid.main" className={"landing-box"}>
                        <a href={"/valorant.espana/live"}><img
                            src={"/images/valorant_landing.jpg"} alt="Valorant España"
                            height={"100%"}/></a>
                    </Box>
                    <Box bgcolor="grid.main" className={"landing-box"}>
                        <a href={"/giants.crew.by.latency/live"}><img
                            src={"/images/giantscrew_landing.jpg"} alt="giantscrew"
                            height={"100%"}/></a>
                    </Box>
                </div>
            </div>
            */}
        </div>
    )
        ;
};

export default NewExplorePage;
