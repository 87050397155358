import React, {useState} from 'react';
import './user-bio.scss';
import {Box, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography} from '@material-ui/core';
import ProfileDescription from '../ProfileDescription/ProfileDescription';
import {useSelector} from 'react-redux';
import TopCategoriesByUser from "../../TopCategoriesByUser/TopCategoriesByUser";
import {useTranslation} from "react-i18next";

const UserBio = (props) => {
    const deviceView = useSelector(state => state.deviceView)
    const [show, setShow] = useState(false)
    const {t} = useTranslation('extraData')

    return (
        <Box className="user-bio" display="flex" flexWrap="wrap">
            {deviceView === "mobile" &&
                <ExpansionPanel elevation={0} className="expansion" square expanded={show}
                                onChange={() => setShow(!show)}>
                    <ExpansionPanelSummary classes={{content: "button"}}>
                        <Typography>{t("buttons_more_info")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{root: "data"}}>
                        <Box width="100%">
                            <ProfileDescription responsive/>
                            <Box display="flex"
                                 justifyContent="center"
                                 alignItems="center"
                                 width="100%"
                                 marginTop="0.5rem">
                                <span style={{
                                    fontFamily: "Barlow-SemiBold",
                                    fontSize: "14px"
                                }}>{t("most_used_categories")}</span>
                            </Box>
                            <TopCategoriesByUser
                                changeTab={props.changeTab}
                                userId={props.userId}
                                userSlug={props.userSlug}
                                topCategories={props.topCategories}
                                containerMarginTop="0.5rem"
                                containerPaddingY="0.5rem"
                                boxWidth="6rem"
                                boxMarginBottom="0.5rem"
                                justifyContent="center"/>
                        </Box>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            }
            {(deviceView === "tablet" || deviceView === "browser") && <ProfileDescription/>}
            {(deviceView === "tablet" || deviceView === "browser") && <div className='top-categories'>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                        <span
                            style={{fontFamily: "Barlow-SemiBold", fontSize: "14px"}}>{t("most_used_categories")}</span>
                    </Box>
                    <TopCategoriesByUser
                        changeTab={props.changeTab}
                        userId={props.userId}
                        userSlug={props.userSlug}
                        topCategories={props.topCategories}
                        containerMarginTop="0.5rem"
                        containerPaddingY="0px"
                        boxWidth="6rem"
                        boxMarginBottom="0.25rem"
                        justifyContent="center"/>
                </Box>
            </div>}
        </Box>
    )
}

export default UserBio;
