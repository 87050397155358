import React, {useEffect, useState} from 'react';
import {isValid, formatDate} from "../../../../../../../helpers/dateFormatter";
import {isToday, parseJSON} from "date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
const HtmlToReactParser = require('html-to-react').Parser

const htmlToReactParser = new HtmlToReactParser()
const FeedNewItem = ({itemId, title, date, allowRemove, onClickDeleteButton, onClickEditButton}) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const [time, setTime] = useState("-");
    const reactElement = htmlToReactParser.parse(title)
    useEffect(() => {
        try {
            let convertToDate = parseJSON(date);
            setTime(formatDate(convertToDate, isToday(convertToDate) ? 'HH:mm' : 'dd/MM'))
        } catch (err) {

        }
    }, [date])


    return (
        <div className={`landing-feed-new-item ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
            <div className={"date-time"}>{time}</div>
            <div className={"feed-title"}>{reactElement}</div>

            {allowRemove && <div className="allowed-options">
                <div className={"break"}/>
                <IconButton size={"small"}
                            className="edit-btn"
                            color="secondary"
                            aria-label="edit"
                            onClick={() => onClickEditButton(itemId)}>
                    <EditIcon/>
                </IconButton>
                <IconButton size={"small"}
                            className="delete-btn"
                            aria-label="delete"
                            onClick={() => onClickDeleteButton(itemId)}>
                    <DeleteIcon/>
                </IconButton>
            </div>}
        </div>
    );
};

export default FeedNewItem;
