import React, {useEffect, Fragment, useState, useRef} from 'react';
import './profile.scss';
import {Box} from '@material-ui/core';
import Calendar from '../../components/Calendar/Calendar';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import UserBio from '../../components/Profile/ProfileBio/UserBio';
import ProfileHeader from '../../components/Profile/ProfileHeader/ProfileHeader';
import {prepareProfile, removeProfile} from '../../store/app/actions';
import ProfileTabs from '../../components/Profile/ProfileTabs/ProfileTabs';
import MobileProfileTabs from '../../components/Profile/MobileProfileTabs/MobileProfileTabs';
import FollowersInfo from '../../components/Profile/ProfileFollowersInfo/FollowersInfo';
import {getUserProfile} from "../../store/user/actions";
import services from "../../services";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const Profile = (props) => {
    const { filterByCategory } = props;
    const {userNickname, eventID, tabType, achievementListSlug, primary, secondary} = useParams()
    const [userID, setUserId] = useState(undefined);
    const [profileData, setProfileData] = useState(undefined);
    const [categoryFilterActive, setCategoryFilterActive] = useState(false);
    const dispatch = useDispatch()
    const history = useHistory()
    const deviceView = useSelector(state => state.deviceView)
    const [tab, setTab] = useState(0)
    const {i18n, t} = useTranslation('extraData')
    const uncategorized = {
        id: null,
        cover_url: '/static-categories/uncategorized.jpg',
        parentId: null,
        text: t('uncategorized')
    };
    const openedProfileFilterCategory = useSelector(state => state.openedProfileFilterCategory);
    const eventCategoriesSelector = useSelector(state => state.eventCategories);
    const [topCategories, setTopCategories] = useState([]);
    const [userUseCategories, setUserUseCategories] = useState([]);
    const [eventCategories, setEventCategories] = useState([]);
    const [userArchivementList, setUserArchivementList] = useState({
        data: [],
        total: 0
    });

    useEffect(() => {
        dispatch(getUserProfile(userNickname, redirect, (user) => {
            setProfileData(user);
            checkTabFromParams(tabType);
            fetchUserAchievements(user.id);
            setUserId(user.id)
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNickname, tabType]);

    useEffect(() => {
        if (Array.isArray(eventCategoriesSelector)) {
            setEventCategories([...eventCategoriesSelector, uncategorized]);
        } else {
            setEventCategories([uncategorized]);
        }
    }, [eventCategoriesSelector]);

    useEffect(() => {
        if (userID && eventID) {
            props.onParams({userID, eventID})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID, eventID])

    /*
    useEffect(() => {
        if (userID) {
            let filterByItemCat = null;
            if (filterByCategory && eventCategoriesSelector) {
                if (secondary !== undefined) {
                    filterByItemCat = eventCategoriesSelector.find(i => i.slug === secondary);
                } else {
                    filterByItemCat = eventCategoriesSelector.find(i => i.slug === primary);
                }
                setCategoryFilterActive(filterByItemCat.id);
                dispatch(setOpenedProfileFilterCategory(filterByItemCat.id, () => {
                    dispatch(prepareProfile(new Date(), profileData !== undefined ? profileData :userID, redirect))
                }));
            } else {
                setCategoryFilterActive(false);
                dispatch(setOpenedProfileFilterCategory(null, () => {
                    dispatch(prepareProfile(new Date(), profileData !== undefined ? profileData :userID, redirect))
                }));
            }
            window.scrollTo(0, 0);
        }
        return () => {
            dispatch(removeProfile())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID, profileData, filterByCategory, eventCategoriesSelector, primary, secondary, deviceView]);
     */

    useEffect(() => {
        if (userID) {
            dispatch(prepareProfile(new Date(), userID, redirect))
            window.scrollTo(0, 0);
        }
        return () => {
            dispatch(removeProfile())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID, deviceView]);

    const checkTabFromParams = (_tabType) => {
        if (_tabType !== undefined) {
            if (_tabType === "agenda") {
                setTab(0);
            } else if (_tabType === "logros" || _tabType === "achievements") {
                setTab(1);
            } else if (_tabType === "info") {
                setTab(2);
            } else if (_tabType === "categories" || _tabType === "categorias") {
                setTab(3);
            } else {
                setTab(0);
            }
        } else {
            setTab(0);
        }
    }

    useEffect(() => {
        checkTabFromParams(tabType);
    }, [tabType]);


    const getTopCategories = () => {
        if (!!userID) {
            services.userListCategories.get(userID).then(res => {
                let res_prepared = res.map((i) => {
                    if(i.id === null) {
                        i.cover_url = uncategorized.cover_url;
                        i.text = uncategorized.text;
                    }
                    return i;
                });
                setUserUseCategories(res_prepared);
                let res_filtered = res_prepared.filter(i => i.id !== null).slice(0, 3);
                if(openedProfileFilterCategory !== false) {
                    let res_add_active_filter = res_filtered.find(i => i.id === openedProfileFilterCategory);
                    if(!res_add_active_filter) {
                        let res_add_active_filter = res_prepared.find(i => i.id === openedProfileFilterCategory);
                        setTopCategories([res_add_active_filter, ...res_filtered]);
                    }
                } else {
                    setTopCategories(res_filtered);
                }

            }).catch(err => {
                console.log('userListCategories::err', err);
            });

            /*
            services.topCategoriesByUser.find({query: {limit: 99, userId: userID}})
                .then((resp) => {
                    const cats = resp.filter((c) => eventCategories.find(x => x.id === c.categoryId))
                        .map((c) => {
                            let category = eventCategories.find(x => x.id === c.categoryId);
                            return {...category, count: parseInt(c.count)}
                        });
                    console.log(cats);
                    //setTopCategories(cats);
                })
                .catch((error) => {
                    console.log(error);
                });

             */

        }
    }

    useEffect(getTopCategories, [userID, openedProfileFilterCategory]);

    const fetchUserAchievements = (_userId) => {
        services.userAchievementList.find({
            query: {
                ownerId: _userId,
                $sort: {
                    weight: -1
                }
            }
        }).then(res => {
            setUserArchivementList(res);
        }).catch(console.error);
    }

    const changeTab = (e, val) => {
        setTab(val);
        //                   window.history.replaceState('', document.title, noHashURL)
        if (val === 0) {
            history.replace(`/${userNickname}/agenda`, {
                achievementListSlug: undefined
            });
        } else if (val === 1) {
            if (i18n.language === "es") {
                history.replace(`/${userNickname}/logros`, {
                    achievementListSlug: undefined
                });
            } else {
                history.replace(`/${userNickname}/achievements`, {
                    achievementListSlug: undefined
                });
            }
        } else if (val === 2) {
            history.replace(`/${userNickname}/info`, {
                achievementListSlug: undefined
            });
        } else if (val === 3) {
            if (i18n.language === "es") {
                history.replace(`/${userNickname}/categorias`, {
                    achievementListSlug: undefined
                });
            } else {
                history.replace(`/${userNickname}/categories`, {
                    achievementListSlug: undefined
                });
            }

        }
    }

    const redirect = () => {
        history.push('/not-found');
    }

    return (
        <Box className="profile-page" display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
            {(profileData !== undefined && profileData.hasOwnProperty('nickname')) && (
                <Helmet>
                    <title>{profileData.nickname} / Latency</title>
                    {i18n.language === "es" ? (
                        <meta name="description"
                              content={`Agenda de ${profileData.nickname}, ${profileData.biography?.toLowerCase()} | Sigue todos sus eventos en Latency; la agenda interactiva`}/>
                    ) : (
                        <meta name="description"
                              content={`${profileData.nickname} Agenda's, ${profileData.biography?.toLowerCase()} | Track all of their events in Latency; the interactive agenda`}/>)}
                    {!tabType && (<link rel="canonical" href={`https://latencyapp.com/${profileData.slug}`}/>)}
                    {!tabType && (<link rel="canonical" href={`https://latencyapp.com/${profileData.slug}/agenda`}/>)}
                    {(tabType && tabType === 1) && (<link rel="canonical" hrefLang="es"
                                                          href={`https://latencyapp.com/${profileData.slug}/logros`}/>)}
                    {(tabType && tabType === 1) && (<link rel="canonical" hrefLang="en"
                                                          href={`https://latencyapp.com/${profileData.slug}/achievements`}/>)}
                    {(tabType && tabType === 2) && (
                        <link rel="canonical" href={`https://latencyapp.com/${profileData.slug}/info`}/>)}
                    {(tabType && tabType === 3) && (<link rel="canonical" hrefLang="es"
                                                          href={`https://latencyapp.com/${profileData.slug}/categorias`}/>)}
                    {(tabType && tabType === 3) && (<link rel="canonical" hrefLang="en"
                                                          href={`https://latencyapp.com/${profileData.slug}/categories`}/>)}
                </Helmet>
            )}
            {(deviceView === "browser") &&
            <Fragment>
                <ProfileHeader onTab={changeTab}/>
                <Box className="profile-calendar" bgcolor="grid.content">
                    <Calendar categoryId={categoryFilterActive} ownerId={userID} onTab={changeTab}/>
                </Box>
                <Box className="bio-box">
                    <UserBio userId={userID} userSlug={userNickname} onTab={changeTab} topCategories={topCategories}/>
                </Box>
                <Box className="tabs-box">
                    <ProfileTabs userId={userID} tab={tab} onTab={changeTab} userSlug={userNickname} topCategories={userUseCategories}
                                 userArchivementList={userArchivementList} cbFetchAchievements={fetchUserAchievements}/>
                </Box>
            </Fragment>
            }
            {(deviceView === "tablet") &&
            <Fragment>
                <ProfileHeader onTab={changeTab}/>
                {deviceView === "mobile" && <FollowersInfo/>}
                <Box className="bio-box">
                    <UserBio userId={userID} userSlug={userNickname} topCategories={topCategories}/>
                </Box>
                <Box className="profile-calendar" bgcolor="grid.main">
                    <Calendar categoryId={categoryFilterActive} ownerId={userID} onTab={changeTab}/>
                </Box>
                <Box className="tabs-box">
                    <ProfileTabs userId={userID} tab={tab} onTab={changeTab} userSlug={userNickname} topCategories={userUseCategories}
                                 userArchivementList={userArchivementList} cbFetchAchievements={fetchUserAchievements}/>
                </Box>
            </Fragment>
            }
            {(deviceView === "mobile") &&
            <Fragment>
                <ProfileHeader onTab={changeTab}/>
                {deviceView === "mobile" && <FollowersInfo onTab={changeTab}/>}
                <Box className="bio-box">
                    <UserBio userId={userID} userSlug={userNickname} topCategories={topCategories}/>
                </Box>
                <Box className="profile-calendar" bgcolor="grid.main">
                    <Calendar categoryId={categoryFilterActive} ownerId={userID} onTab={changeTab}/>
                </Box>
                <Box className="tabs-box">
                    <MobileProfileTabs userId={userID} tab={tab} onTab={changeTab} userSlug={userNickname} topCategories={userUseCategories}
                                       userArchivementList={userArchivementList}
                                       cbFetchAchievements={fetchUserAchievements}/>
                </Box>
            </Fragment>
            }
        </Box>
    )
}

export default Profile;
