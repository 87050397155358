import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, CircularProgress, Grid} from "@material-ui/core";
import {closeEventInfoModal} from "../../../../../store/eventInfoModal/actions";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BellIconOn from '../../../../../assets/icons/actions/bell-on.svg';
import BellIconOff from '../../../../../assets/icons/actions/bell-off-grey.svg';


const EmbedTeamItem = ({user, suscribeCategoryId, onFollowClick, onUnfollowClick, gridItemSettings, onClick}) => {
    const { t } = useTranslation('landing');
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isCategorySubscribed, setIsCategorySubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const darkTheme = useSelector(state => state.darkTheme);
    const [follow, setFollow] = useState(false);
    const [isFollow, setIsFollow] = useState(false);
    const handleFollowClick = () => {
        setIsLoading(true);
        onFollowClick(!follow, user.id, suscribeCategoryId, () => {
            setIsCategorySubscribed(true);
            setFollow(true);
            setIsLoading(false);
            setIsFollow(true);
        }, () => setIsLoading(false))
    }

    const handleUnfollowClick = () => {
        setIsLoading(true);
        onUnfollowClick(user.id, suscribeCategoryId, () => {
            setIsCategorySubscribed(false);
            setIsLoading(false);
            setIsFollow(false);
        }, () => setIsLoading(false))
    }

    useEffect(() => {
        setLoading(true);
        setFollow(user.follow);
        setIsCategorySubscribed(user.isCategorySubscribed);
        setIsFollow((user.follow && user.isCategorySubscribed));
        setLoading(false);
    }, [user]);

    const onOpenProfile = (usr) => {
        history.push(`/${usr.slug}`);
        dispatch(closeEventInfoModal());
    }

    const handleClick = () => {
        if(isFollow) {
            handleUnfollowClick();
        } else {
            handleFollowClick();
        }
    }

    return (
        <div data-user-id={user.id}>
            {loading && (
                <Box py={2}
                     px={1}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     className={`landing-teams__item ${darkTheme ? 'dark' : ''}`}>
                    <CircularProgress thickness={5} className="loader"/>
                </Box>)}
            {!loading && (<>
                <div className={`landing-teams__item ${darkTheme ? 'dark' : ''} ${isFollow ? ' is-following' : ' not-following'}`}>
                    <a href={`/${user.slug}`}
                       onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           //onOpenProfile(user)
                           handleClick();
                       }}
                       className="user">
                        {isLoading ? (
                            <Button className='unfollow'>
                                <CircularProgress color="white" size={20} thickness={5} className="loader"/>
                            </Button>
                        ) : (
                            <>
                                <div className="campanita">
                                    <div className="inside">
                                        <div className="triangle" />
                                    </div>

                                    <img className="campana" src={isFollow ? BellIconOn : BellIconOff} />
                                </div>
                            <img src={user.avatar} alt={user.nickname}/>
                            </>
                        )}
                    </a>
                </div>
            </>)}
        </div>
    );
}

EmbedTeamItem.defaultProps = {
    gridItemSettings: {
        item: {
            xs: 12,
            lg: 3,
            md: 3
        },
        boxPadding: {
            py: 1,
            px: 1
        },
        team: {
            hideContentName: false
        }
    }
}

export default EmbedTeamItem;
