import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
// english translations
import frontPageEN from '../locale/en/frontpage.json'
import agendaEN from '../locale/en/agenda.json'
import EventFormEN from '../locale/en/EventForm.json'
import footerEN from '../locale/en/footer.json'
import notificationsEN from '../locale/en/notifications.json'
import QuickEventFormEN from '../locale/en/QuickEventForm.json'
import sidebarEN from '../locale/en/sidebar.json'
import userblockEN from '../locale/en/userblock.json'
import userdetailsEN from '../locale/en/userdetails.json'
import useredit_formEN from '../locale/en/useredit_form.json'
import validationsEN from '../locale/en/validations.json'
import modal_manage_groupsEN from '../locale/en/modal_manage_groups.json'
import stickyToolbarEN from '../locale/en/stickyToolbar.json'
import eventDetailsEN from '../locale/en/eventDetails.json'
import extraDataEN from '../locale/en/extraData.json'
import UserMenuEN from '../locale/en/UserMenu.json'
import GroupsEN from '../locale/en/Groups.json'
import completeSignupEN from '../locale/en/completeSignup.json'
import exploreEN from '../locale/en/explore.json'
import contactFormEN from '../locale/en/ContactForm'
import settingsEN from '../locale/en/settings'
import eventMessageEN from '../locale/en/eventMessage.json'
import feedbackFormEN from '../locale/en/feedbackForm.json'
import landingEN from '../locale/en/landing.json'
import tutorialsEN from '../locale/en/tutorials.json'
import archivementFormEN from '../locale/en/archivementForm.json'



// spanish translations
import frontPageES from '../locale/es/frontpage.json'
import agendaES from '../locale/es/agenda.json'
import EventFormES from '../locale/es/EventForm.json'
import footerES from '../locale/es/footer.json'
import notificationsES from '../locale/es/notifications.json'
import QuickEventFormES from '../locale/es/QuickEventForm.json'
import sidebarES from '../locale/es/sidebar.json'
import userblockES from '../locale/es/userblock.json'
import userdetailsES from '../locale/es/userdetails.json'
import useredit_formES from '../locale/es/useredit_form.json'
import validationsES from '../locale/es/validations.json'
import modal_manage_groupsES from '../locale/es/modal_manage_groups.json'
import stickyToolbarES from '../locale/es/stickyToolbar.json'
import eventDetailsES from '../locale/es/eventDetails.json'
import extraDataES from '../locale/es/extraData.json'
import UserMenuES from '../locale/es/UserMenu.json'
import GroupsES from '../locale/es/Groups.json'
import completeSignupES from '../locale/es/completeSignup.json'
import exploreES from '../locale/es/explore.json'
import contactFormES from '../locale/es/ContactForm'
import settingsES from '../locale/es/settings'
import eventMessageES from '../locale/es/eventMessage.json'
import feedbackFormES from '../locale/es/feedbackForm.json'
import landingES from '../locale/es/landing.json'
import tutorialsES from '../locale/es/tutorials.json'
import archivementFormES from '../locale/es/archivementForm.json'

const resources = {
    en: {
        validations: validationsEN,
        agenda: agendaEN,
        frontPage: frontPageEN,
        footer: footerEN,
        eventForm: EventFormEN,
        quickEventForm: QuickEventFormEN,
        notifications: notificationsEN,
        userBlock: userblockEN,
        userDetails: userdetailsEN,
        modalManageGroups: modal_manage_groupsEN,
        userEditForm: useredit_formEN,
        sideBar: sidebarEN,
        stickyToolbar: stickyToolbarEN,
        eventDetails: eventDetailsEN,
        extraData: extraDataEN,
        UserMenu: UserMenuEN,
        Groups: GroupsEN,
        completeSignup: completeSignupEN,
        explore: exploreEN,
        contactForm: contactFormEN,
        settings: settingsEN,
        eventMessage: eventMessageEN,
        feedbackForm: feedbackFormEN,
        landing: landingEN,
        tutorials: tutorialsEN,
        archivementForm: archivementFormEN
    },
    es: {
        validations: validationsES,
        agenda: agendaES,
        frontPage: frontPageES,
        footer: footerES,
        eventForm: EventFormES,
        quickEventForm: QuickEventFormES,
        notifications: notificationsES,
        userBlock: userblockES,
        userDetails: userdetailsES,
        modalManageGroups: modal_manage_groupsES,
        userEditForm: useredit_formES,
        sideBar: sidebarES,
        stickyToolbar: stickyToolbarES,
        eventDetails: eventDetailsES,
        extraData: extraDataES,
        UserMenu: UserMenuES,
        Groups: GroupsES,
        completeSignup: completeSignupES,
        explore: exploreES,
        contactForm: contactFormES,
        settings: settingsES,
        eventMessage: eventMessageES,
        feedbackForm: feedbackFormES,
        landing: landingES,
        tutorials: tutorialsES,
        archivementForm: archivementFormES
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        whitelist: ['es', 'en'],
        nonExplicitWhitelist: true,
        debug: false,
        fallbackLng: "en",
        defaultNS: 'validations',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
