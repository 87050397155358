import React, {useEffect, useState} from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next';
import {Box} from '@material-ui/core';
import services from '../../../services';
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const ParticipantsList = (props) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const [values, setValues] = useState(props.initialData && props.initialData.length ? props.initialData : [])
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation('eventForm');

    const prepareData = (data) => {
        let newData = []
        let obj = {}
        let groups = data.groups
        let users = data.users
        for (let a = 0; a < groups.length; a++) {
            if (!groups[a].add) {
                obj.type = "group"
                obj.data = groups[a]
                obj.nickname = groups[a].name
                newData.push(obj)
                obj = {}
            }
        }

        for (let a = 0; a < users.length; a++) {
            newData.push(users[a])
        }
        setList(newData)
    }

    const handleChange = (event, data) => {
        let pp = [];
        let group = {};
        for (let a = 0; a < data.length; a++) {
            if (data[a].type === "group") {
                group = data[a];
                data.splice(a, 1);
            } else {
                let found = pp.find(item => item.id === data[a].id);
                if (!found) {
                    pp.push(data[a]);
                }
            }
        }
        if (group.data) {
            for (let b = 0; b < group.data.members.length; b++) {
                let found = pp.find(item => item.id === group.data.members[b].id);
                if (found) {
                    pp = pp.filter(item => item.id !== found.id);
                } else {
                    pp.push(group.data.members[b]);
                }
            }
        }

        setValues(pp);
        props.handleChange(pp);
    };

    const handleSearch = (event) => {
        if (event.target.value && event.target.value.length > 2) {
            setLoading(true)
            services.search.find({
                query: {
                    text: event.target.value,
                    participants: true
                }
            })
                .then((resp) => {
                    prepareData(resp)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setList([])
        }
    }

    const handleEnter = (event) => {
        if (event.keyCode === 13) { // catch enter
            if (list.length > 0) {
                let item = list[0];
                let data = values.length > 0 ? [...values] : [];
                if (item.type === "group") {
                    let group = item;
                    for (let b = 0; b < group.data.members.length; b++) {
                        let found = data.find(item => item.id === group.data.members[b].id);
                        if (found) {
                            data = data.filter(item => item.id !== found.id);
                        } else {
                            data.push(group.data.members[b]);
                        }
                    }
                } else {
                    let found = data.find(a => a.id === item.id);
                    if (!found) {
                        data.push(item)
                    }
                }
                setValues(data)
                props.handleChange(data)
            }
        }
    };

    return (
        <Autocomplete
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        avatar={<Avatar alt={option.nickname} src={option.avatar} />}
                        label={option.nickname}
                    />
                ))
            }
            disabled={props.disabled}
            renderOption={(option, {selected}) => (
                    <Box className="part-list-option" display="flex" flexDirection="row" justifyContent="flex-start"
                         alignItems="center">
                        {option.type === "group" ? <span className={"color-box " + (option.data.color)}></span> :
                            <span className={"avatar"}>
                        <img src={option.avatar ? option.avatar : "/images/defaultAvatar.png"} className={"avatar"}
                             alt={option.nickname}/>
                    </span>}

                        <span className="text">{option.nickname}</span>
                    </Box>
                )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    onKeyDown={handleEnter}
                    onChange={handleSearch}
                    placeholder={!props.private ? t('forms_search_participants_placeholder') : t('forms_search__private_participants_placeholder')}
                    fullWidth
                />
            )}
            className={"tag-select " + (darkTheme ? "tag-select-dark" : "tag-select-light")}
            multiple
            loading={loading}
            classes={{
                tag: "chip",
                popper: props.quick ? "quick-tag-select-dropdown" : "full-tag-select-dropdown",
                endAdornment: "clear-icon",
                popupIndicator: "down-btn",
                loading: "static",
                noOptions: "static"
            }}
            options={list}
            getOptionLabel={option => option.nickname}
            value={values}
            onChange={handleChange}
        />
    )
}


ParticipantsList.defaultProps = {
    disabled: false
}

export default ParticipantsList;
