const initailState = {
    id: "",
    icon: "default",
    name: "",
    color: "none",
    order: 0,
    isUnassigned: false,
    createdAt: "",
    updatedAt: "",
    ownerId: "",
}

const selectedGroupReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_SELECTED_GROUP':
            return state = action.payload;
        case 'UNSET_SELECTED_GROUP':
            return state = initailState
        default:
            return state
    }
}

export default selectedGroupReducer;