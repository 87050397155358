import {addDays, subDays, startOfDay, endOfDay} from 'date-fns';
import {deviceType} from 'react-device-detect';
import { store } from '../';
import {formatDate, getCurrentTimezone} from '../../helpers/dateFormatter';

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"

export const setOpenedDiscoverCategoriesWeeklyEvents = (date, categoryId, sameWeek=false) => {
    return dispatch => {
        const state = store.getState();
        let deviceView = state.deviceView;
        let params = prepareParams(deviceView ? deviceView : deviceType, date)
        if (categoryId) params.query.categoryId = categoryId
        dispatch({
            type: 'SET_OPENED_DISCOVER_CATEGORIES_WEEKLY_SELECTED',
            payload: params.query
        });
    }
}

export const unsetOpenedDiscoverCategoriesWeeklyEvents = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_DISCOVER_CATEGORIES_WEEKLY_SELECTED'})
    }
}

const prepareParams = (type, date) => {
    let dateObj = new Date(date);

    switch(type){
        case "browser":
            return {
                query: {
                    start: formatDate(subDays(dateObj, 1), dateFormat),
                    end: formatDate(addDays(dateObj, 1), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false
                }
            }
        case "mobile":
            return {
                query: {
                    start: formatDate(startOfDay(dateObj), dateFormat),
                    end: formatDate(endOfDay(dateObj), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        case "tablet":
            return {
                query: {
                    start: formatDate(subDays(dateObj, 1), dateFormat),
                    end: formatDate(addDays(dateObj, 0), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        default:
            return {
                query: {
                    start: formatDate(subDays(dateObj, 1), dateFormat),
                    end: formatDate(addDays(dateObj, 1), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
    }
}

