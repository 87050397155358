export const setCookiesTerms = () => {
    return dispatch => {
        dispatch({type: 'SET_COOKIES_TERMS'})
    }
}

export const unsetCookiesTerms = () => {
    return dispatch => {
        dispatch({type: 'UNSET_COOKIES_TERMS'})
    }
}
