import React, {useEffect, useState} from 'react';
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import services from "../../../../../services";
import {ValorantResultEvent} from "./ValorantResultEvent";
import './_embed-result-events.scss';
import {useSelector} from "react-redux";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export const EmbedResultEvents = ({ userId, filterByTournament }) => {
    const deviceView = useSelector(state => state.deviceView);
    const [passedEvents, setPassedEvents] = useState([]);
    const [loadingPassed, setLoadingPassed] = useState(true);

    const fetchMatchesEvents = (user, max, is_live, extraQuery) => {
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 14);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 1);
        let data = {
            start: formatDate(ownStartOfDay(dateObj), dateFormat),
            end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
            timezone: getCurrentTimezone(),
            isLive: is_live
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.landingEventsLiveScores.get(userId, {
            query: data
        });
    }

    useEffect(() => {
        let mounted = true;
        setLoadingPassed(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchMatchesEvents(userId, 24, false, extraQuery).then((res) => {
            if (mounted) {
                setPassedEvents(res);
                setLoadingPassed(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);

    return (
        <div className={`embed-result-events-component is-${deviceView}`}>
            {passedEvents.map(item => <ValorantResultEvent key={item.id} {...item} />)}
        </div>
    );
}
