import services from '../../services';

export const setUserFollowing = (userId) => {
    return dispatch => {
        services.userFollowing.get(userId, {
            query: {
                simple: true,
                $limit: 500
            }
        })
        .then((resp) => {
            let data = [];
            if(resp.hasOwnProperty('data')) {
                data = resp.data.map(it => it.followingId);
            }
            dispatch({
                type: 'SET_USER_FOLLOWING',
                payload: data
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }
}

export const unsetUserFollowing = (data) => {
    return dispatch => {
        dispatch({type: 'UNSET_USER_FOLLOWING'})
    }
}

const prepareData = (resp) => {
    let ff = []
    for(let a=0; a<resp.length; a++){
        ff.push(resp[a])
    }
    return ff
}
