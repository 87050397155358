const initailState = []

const openedProfileCategoriesReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_OPENED_PROFILE_CATEGORIES':
            return state = action.payload
        case 'UNSET_OPENED_PROFILE_CATEGORIES':
            return state = initailState
        default:
            return state
    }
}

export default openedProfileCategoriesReducer;
