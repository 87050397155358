import React from 'react';
import './profile-header.scss';
import { useSelector } from 'react-redux';
import UserInfo from '../../User/UserInfo/UserInfo';
import FollowersInfo from '../ProfileFollowersInfo/FollowersInfo';
import EditIcon from '@material-ui/icons/Edit';
import {Box, Chip} from '@material-ui/core';
import {Link} from 'react-router-dom';

const ProfileHeader = (props) => {
    const {onTab} = props;
    const openedProfile = useSelector(state => state.openedProfile);
    const user = useSelector(state => state.user);
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);

    return (
        <Box className="profile-header">
            <img src={openedProfile.header} className="header-img" alt={openedProfile.nickname} />
            <UserInfo profile={openedProfile} isProfile={true} />
            {(deviceView === "browser" || deviceView === "tablet") && <FollowersInfo onTab={onTab} />}
            {openedProfile.id === user.id && <Link to="/edit/profile"><EditIcon className="edit-icon" /></Link>}
            {user.id && openedProfile.followingMe && <Chip className={"chip-isFollowingMe" + (darkTheme ? " isDark" : " isLight")} size="small" label="Follows You" />}
        </Box>
    )
}

export default ProfileHeader;
