import React, {useEffect, useState} from 'react';
import services from "../../../../../services";
import LandingFeedTimeline from "./Feed/LandingFeedTimeline/LandingFeedTimeline";
import {useSelector} from "react-redux";
import LandingFeedArticles from "./Feed/LandingFeedArticles/LandingFeedArticles";
import {TwitterTimelineEmbed} from "react-twitter-embed";
import CircularProgress from "@material-ui/core/CircularProgress";

const LandingFeedComponent = ({userId}) => {
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const loggedIn = useSelector(state => state.loggedIn)
    const user = useSelector(state => state.user)
    const [allowedManage, setAllowedManage] = useState(false);
    const settingsGeneral = useSelector(state => state.settings.general);

    useEffect(() => {
        if (loggedIn) {
            if (user.id === userId) {
                setAllowedManage(true);
            } else {
                services.userLandingRoles.find({
                    query: {
                        roomId: userId
                    }
                }).then((res) => {
                    if (res.hasOwnProperty('allowed') && res.allowed === true) {
                        setAllowedManage(true);
                    }
                });
            }
        }
    }, [loggedIn, user]);


    const onSubmitModal = (data, onSuccess, onError) => {
        services.landingFeed.create({...data, roomId: userId}).then(res => {
            onSuccess(res);
        }).catch(err => {
            console.log(err)
            onError(err);
        })
    };

    return (
        <div
            className={`landing-feed-container ${deviceView === 'browser' ? ' is-desktop' : ''} ${deviceView === 'mobile' ? ' is-mobile' : ''} ${deviceView === 'tablet' ? ' is-tablet' : ''} ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
            <div className={"feed-section"}>
                <LandingFeedTimeline userId={userId} allowedManage={allowedManage} handleSubmit={onSubmitModal}/>
            </div>
            <div className={"feed-section"}>
                <LandingFeedArticles userId={userId} allowedManage={allowedManage} handleSubmit={onSubmitModal}/>
            </div>
            <div className={"feed-section"}>
                {userId === '4e42510f-afd8-4f3b-b072-32ad4fc9cf19' &&
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="Tortilla_Land"
                    theme={`${darkTheme ? 'dark' : 'light'}`}
                    options={{height: 716 }}
                    lang={settingsGeneral.language}
                    placeholder={<div style={{display: "flex", justifyContent: "center", alignItems: "center", height:716}}><CircularProgress size={32} thickness={5} className="input-loader"/></div>}
                    noFooter
                />}
                {userId === 'ce777e3c-f047-424d-9ee5-070b578caed3' && <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="GiantsXTRA"
                    theme={`${darkTheme ? 'dark' : 'light'}`}
                    options={{height: 716 }}
                    lang={settingsGeneral.language}
                    placeholder={<div style={{display: "flex", justifyContent: "center", alignItems: "center", height:716}}><CircularProgress size={32} thickness={5} className="input-loader"/></div>}
                    noFooter
                />}
            </div>
        </div>
    );
};

export default LandingFeedComponent;
