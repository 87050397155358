import React from "react";
import clsx from "clsx";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {StepConnector, withStyles, makeStyles} from "@material-ui/core";
import {Check} from "@material-ui/icons";


const OwnStepper = ({activeStep, steps}) => {

    const CustomConnector = withStyles({
        alternativeLabel: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        active: {
            '& $line': {
                borderColor: '#00D1BF',
            },
        },
        completed: {
            '& $line': {
                borderColor: '#00D1BF',
            },
        },
        line: {
            borderColor: '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        }
    })(StepConnector);

    const CustomStepIconStyles = makeStyles({
        root: {
            color: '#eaeaf0',
            display: 'flex',
            height: 22,
            alignItems: 'center',
        },
        active: {
            color: '#00D1BF',
        },
        circle: {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
        completed: {
            color: '#00D1BF',
            zIndex: 1,
            fontSize: 18,
        }
    });

    const CustomStepIcon = (step) => {
        const classes = CustomStepIconStyles();
        const { active, completed } = step;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
            </div>
        );
    };

    return (
        <Stepper style={{ backgroundColor: "transparent" }} activeStep={activeStep} alternativeLabel connector={<CustomConnector />}>
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepLabel StepIconComponent={CustomStepIcon}>{step}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );

};

export default OwnStepper;
