import React, { useState, useEffect, Fragment } from 'react';
import {generateDays} from '../../helpers/calendarData';
import { Box } from '@material-ui/core';
import {ownIsSameWeek} from '../../helpers/dateFormatter';
import {useSelector} from 'react-redux';
import CalendarCellItem from "./CalendarCellItem";

const CalendarCells = (props) => {
    const { month, date } = props
    const [tmpMonth, setTmpMonth] = useState(null);
    const [weeks, setWeeks] = useState([])
    const monthlyEventsCount = useSelector(state => state.monthlyEventsCount)

    useEffect(() => {
        if(tmpMonth === month) {

        } else {
            setWeeks(generateDays(month, date, monthlyEventsCount))
            setTmpMonth(month)
        }

    }, [month, date, monthlyEventsCount])

    return (
        <Fragment>
            {weeks.length > 0 && weeks.map((week, index1) =>
                <Box key={`cellweek.${index1}.${props.ownerId ? props.ownerId : 'agenda'}`} display="flex" flexDirection="row" className={ "week " + (ownIsSameWeek(week.week[0].date, props.date) ? 'selectedWeek' : " ")}>
                    {week.week.map((day, index2) =>
                        <CalendarCellItem key={`cellitem.${index1}.${index2}.${props.ownerId ? props.ownerId : 'agenda'}`} onDateClick={props.onDateClick} day={day} date={props.date} />
                    )}
                </Box>
            )}
        </Fragment>
    )
}

export default CalendarCells
