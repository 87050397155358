import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import './_styles.scss';
import {Grid} from "@material-ui/core";
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import services from "../../../../../services";
import VlrItemComponent from "./VlrItemComponent";
import {setOpenedEvent} from "../../../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../../../store/eventInfoModal/actions";
import {Event} from "../../../../../services/tracking";
import Chip from "@material-ui/core/Chip";
import {useTranslation} from "react-i18next";
import VlrGroupTournamentItemComponent from "./VlrGroupTournamentItemComponent";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const VlrLandingMatchesComponent = ({userId}) => {
    const dispatch = useDispatch();
    const nowDate = new Date();

    const { t } = useTranslation('landing');

    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loadingUpcoming, setLoadingUpcoming] = useState(true);

    const [liveEvents, setLiveEvents] = useState([]);
    const [loadingLiveEvents, setLoadingLiveEvents] = useState(true);

    const [passedEvents, setPassedEvents] = useState([]);
    const [loadingPassedEvents, setLoadingPassedEvents] = useState(true);

    const [tournaments, setTournaments] = useState([]);
    const [filterByTournament, setFilterByTournament] = useState(null);

    const [datar, setData] = useState({
        live: [],
        upcoming: {},
        upcomingByTournamnets: {}
    });
    const darkTheme = useSelector(state => state.darkTheme);

    const fetchUpcomingEvents = (user, max, extraQuery) => {
        const dateObj = formatDate(new Date(), dateFormat)
        let data = {
            start: dateObj.substring(0, dateObj.length - 9) + "00:00.000",
            timezone: getCurrentTimezone(),
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.eventsLandingUpcoming.get(user, {
            query: data
        });
    }

    const fetchMatchesEvents = (user, max, is_live, extraQuery) => {
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 2);
        let data = {
            start: formatDate(ownStartOfDay(dateObj), dateFormat),
            end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
            timezone: getCurrentTimezone(),
            isLive: is_live
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.landingEventsLiveScores.get(userId, {
            query: data
        });
    }

    useEffect(() => {
        let mounted = true;
        setLoadingUpcoming(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchUpcomingEvents(userId, 12, extraQuery).then((res) => {
            if (mounted) {
                setUpcomingEvents(res);
                setLoadingUpcoming(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);


    useEffect(() => {
        let mounted = true;
        setLoadingLiveEvents(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchMatchesEvents(userId, 12, true, extraQuery).then((res) => {
            if (mounted) {
                setLiveEvents(res);
                setLoadingLiveEvents(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);

    useEffect(() => {
        let mounted = true;
        setLoadingPassedEvents(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchMatchesEvents(userId, 12, false, extraQuery).then((res) => {
            if (mounted) {
                setPassedEvents(res);
                setLoadingPassedEvents(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);

    useEffect(() => {
        if (!loadingUpcoming && !loadingLiveEvents && !loadingPassedEvents) {
            let combine = [...upcomingEvents, ...liveEvents, ...passedEvents];
            let grouped = combine.reduce(
                (result, item) => ({
                    ...result,
                    [item['tournament']]: [
                        ...(result[item['tournament']] || []),
                        item,
                    ],
                }),
                {},
            );

            if(filterByTournament === null) {
                //setTournaments(Object.keys(grouped));
                setTournaments(grouped);
                console.log('grouped?', grouped);
            }


            let watfaq = upcomingEvents.reduce(
                (result, item) => {
                    let date_formatted = formatDate(new Date(item['start']), 'yyyyMMdd');
                    return {
                        ...result,
                        [date_formatted]: [
                            ...(result[date_formatted] || []),
                            item,
                        ],
                    }
                },
                []);

            let watfaq2 = upcomingEvents.reduce(
                (result, item) => {
                    let date_formatted = item['tournament'];
                    return {
                        ...result,
                        [date_formatted]: [
                            ...(result[date_formatted] || []),
                            item,
                        ],
                    }
                },
                []);

            console.log('watfaq2', watfaq2)



            let new_data = {
                live: [...liveEvents],
                upcoming: {...watfaq},
                upcomingByTournamnets: {...watfaq2}
            };
            setData(new_data);
        }
    }, [upcomingEvents, liveEvents, passedEvents, loadingUpcoming, loadingLiveEvents, loadingPassedEvents, filterByTournament]);


    const OpenInfo = (event) => {
        window.history.pushState({backRef: window.location.pathname}, "Event: " + event.title + " | Latency", `/${event.hasOwnProperty('slug') ? event.slug : event.owner.slug}/${event.id}`)
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("VlrLandingMatchesComponent", "OpenEvent", event.id);
    };

    return (
        <div className="landing-cover-vlr-matches">
            <Box pr={2} pl={2} pb={2}>
                <div className={"title-section is-special"}>{t('label_competitions')}</div>
                <div className="landing-cover-vlr-matches--subnav">
                    <Chip
                          color={filterByTournament === null ? "primary" : "secondary"}
                          size="small"
                          label={t('all_competitions')}
                          onClick={() => setFilterByTournament(null)}
                    />
                    {Object.keys(tournaments).map((group, index) => {
                        let tournament_image = tournaments[group][0].original_poster;

                        return (
                            <Chip key={index}
                                  avatar={<img alt={group} src={tournament_image} />}
                                  color={filterByTournament === group ? "primary" : "secondary"}
                                  size="medium"
                                  onClick={() => setFilterByTournament(group)}
                                  label={group.replace("Champions Tour", "")}
                            />
                        )
                    })}
                </div>
            </Box>


            <Box margin="auto">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} borderRadius={16} margin="auto" p={1}>
                            <div className={"title-section is-special"}>{t('coming_matches')}</div>
                            <div className="matches">
                                {datar.live.map(ev => <VlrItemComponent key={ev.id} event={ev} onOpenEventModal={OpenInfo}
                                                                        nowDate={nowDate} type="live" />)}
                                {Object.keys(datar.upcomingByTournamnets).map((group, index) => <VlrGroupTournamentItemComponent key={index} tournamentName={group} events={datar.upcomingByTournamnets[group]} onOpenEventModal={OpenInfo}
                                                                                                          nowDate={nowDate} type="upcoming" />)}
                                {(datar.live.length === 0 && Object.keys(datar.upcoming).length === 0) && (
                                    <div className="vlr-landing-score-empty">
                                        {t("empty_matches")}
                                    </div>
                                )}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} borderRadius={16} margin="auto" p={1}>
                            <div className={"title-section is-special"}>{t('results_matches')}</div>
                            <div className="matches">
                                {passedEvents.map(ev => <VlrItemComponent key={ev.id} event={ev} onOpenEventModal={OpenInfo}
                                                                          nowDate={nowDate} type="passed" />)}
                                {passedEvents.length === 0 && (
                                    <div className="vlr-landing-score-empty">
                                        {t("empty_results")}
                                    </div>
                                )}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default VlrLandingMatchesComponent;
