import React, { useEffect } from 'react';
import client from "../../services/client";
import {useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import Lottie from "react-lottie-player";
import animationData from "../../assets/animations/success-greentick.json";

const DeepLinkMobile = ({ oauth }) => {
    const loggedIn = useSelector(state => state.loggedIn);

    useEffect(() => {
        const r_oauth = async () => {
            if (loggedIn) {
                const {accessToken} = await client.get('authentication');
                window.location.assign(`latency://login/${accessToken}`)
            } else {
                window.location.assign("latency://login")
            }
        }
        const r_link = () => {
            window.location.assign("latency://home")
        }

        if(oauth) {
            r_oauth().then();
        } else {
            r_link();
        }
    }, [oauth, loggedIn]);

    return (
        <Box className="privacy-policy-page" m={2}>
            <Lottie
                loop={true}
                animationData={animationData}
                play
                style={{ width: 200, height: 200 }}
            />
        </Box>
    );
};

export default DeepLinkMobile;
