import React, { useState, useRef } from 'react';
import './notifications.scss';
import { Box, Grow, Popper, Paper, ClickAwayListener, Button, Badge } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import bell from '../../assets/icons/actions/notification-on.svg';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useTranslation } from 'react-i18next';
import NotificationsWrapper from "./NotificationsWrapper";

const Notification = (props) => {
  const anchorRef = useRef(null);
  const notifications = useSelector(state => state.notifications);
  const invitations = useSelector(state => state.invitations);
  const [open, setOpen] = useState(false);
  const deviceView = useSelector(state => state.deviceView);
  const { t } = useTranslation(['UserMenu', 'notifications']);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
    //dispatch(markAllSeen())
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  return (
    <Box className="notification">
      <Badge badgeContent={notifications.badgeCount + invitations.badgeCount} classes={{ badge: "badge" }} color="error" max={10}>
        {deviceView === "browser" && <Button className="link notify-link" id="notify-link"
                                             onClick={handleToggle}> {t('UserMenu:menu_label_notifications')} </Button>}
        {(deviceView === "mobile" || deviceView === "tablet") &&
        <NotificationsIcon src={bell} alt="latency" className="bell-icon" onClick={handleToggle}/>}
      </Badge>
      <span className="anchor" ref={anchorRef}></span>

      <Popper className={`noti-dropdown`} open={open} anchorEl={anchorRef.current} role={undefined} transition
              disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <Paper className="content" elevation={5}>
                  <Box className="body">
                    <NotificationsWrapper onClose={handleClose} />
                  </Box>
                </Paper>
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default Notification;
