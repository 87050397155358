import React, {Fragment, useState} from 'react';
import ReactDOM from 'react-dom'
import {ReactComponent as ArrowDown} from '../../../assets/icons/actions/arrow_down.svg';

import {Box} from "@material-ui/core";
import Calendar from "../Calendar";
import './agenda_popup_bottom.scss';
import {useSelector} from "react-redux";

const CalendarPopup = () => {
    const darkTheme = useSelector(state => state.darkTheme)
    const [showCalendar, setShowCalendar] = useState(true);
    const toggleShowCalendar = () => setShowCalendar(!showCalendar)

    return (
        <div className={`agenda-popup-cal is-bottom is-${darkTheme ? 'dark' : 'light'}`}>
            <div className="agenda-popup-cal--wrapper">
                <div className="agenda-popup-cal--button" onClick={toggleShowCalendar}>
                    <div className="caption-label">Calendario</div>
                    <ArrowDown fill={"red"} className={"arrow-down"} />
                </div>
                    <Box className={`agenda-calendar ${showCalendar ? 'is-visible' : 'is-invisible'}`} bgcolor="grid.main">
                        <Calendar />
                    </Box>

            </div>
        </div>
    )
}


const CalendarPopupFragment = () => {
    return ReactDOM.createPortal(
        <CalendarPopup />,
        document.getElementById('agenda-calendar-popup-root')
    )
}

export default CalendarPopupFragment;
