const initialState = false

const discoverUsersModalReducer = (state = initialState, action) => {
    switch(action.type){
        case 'OPEN_DISCOVER_USERS_MODAL':
            return state = true;
        case 'CLOSE_DISCOVER_USERS_MODAL':
            return state = initialState
        default:
            return state
    }
}

export default discoverUsersModalReducer;
