import services from '../../services';
import {startOfMonth, endOfMonth} from 'date-fns';
import {formatDate, getCurrentTimezone, ownEndOfWeek, ownStartOfWeek} from '../../helpers/dateFormatter';
import {store} from "../index";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"

export const setMonthlyEventsCount = (date, id=null, groupId=null) => {
    const state = store.getState();
    let dateObj = new Date(date);
    const params = {
        query: {
            start: formatDate(ownStartOfWeek(startOfMonth(dateObj)), dateFormat),
            end: formatDate(ownEndOfWeek(endOfMonth(dateObj)), dateFormat),
            timezone: getCurrentTimezone(),
            onlyCount: true,
            bundle: true,
        }
    };

    let extra_params = {};
    if(state.openedProfileFilterCategory !== false) {
        //extra_params.filter_categories = state.openedProfileFilterCategory;
        extra_params.categoryId = state.openedProfileFilterCategory;
    }

    if(id){params.query.ownerId = id}
    if(groupId){params.query.groupId = groupId}
    if(extra_params != null){params.query = {...params.query, ...extra_params}}

    return dispatch => {
        services.calendarCount.find(params)
        .then((resp) => {
            dispatch({
                type: 'SET_MONTHLY_EVENTS_COUNT',
                payload: resp
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }
}

export const unsetMonthlyEventsCount = () => {
    return dispatch => {
        dispatch({type: 'UNSET_MONTHLY_EVENTS_COUNT'})
    }
}
