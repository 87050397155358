const initialState = false

const addNewAccountModalReducer = (state = initialState, action) => {
    switch(action.type){
        case 'OPEN_ADD_ACCOUNT_MODAL':
            return state = true;
        case 'CLOSE_ADD_ACCOUNT_MODAL':
            return state = initialState
        default:
            return state
    }
}

export default addNewAccountModalReducer;
