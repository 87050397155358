import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Box, Tooltip} from "@material-ui/core";
import './new-event-info.scss';
import {useTranslation} from "react-i18next";
import Popover from "@material-ui/core/Popover";
import privateEvent from "../../../assets/icons/event/icon-visibility-private-red.svg";
import UserCard from "../../User/UserCard/UserCard";

const EventIsPrivate = () => {
    const event = useSelector(state => state.event);
    const user = useSelector(state => state.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPrivateUsersCanSee, setOpenPrivateUserCanSee] = useState(false);
    const {t} = useTranslation(['extraData', 'eventForm'])

    const showPrivateUsersCanSee = (e) => {
        setOpenPrivateUserCanSee(true);
        setAnchorEl(e.currentTarget);
    }

    return <>
        {event.is_private &&
        <>
            <span className="is-private" style={{cursor: 'pointer'}} onClick={(e) => showPrivateUsersCanSee(e)}>
                <span className="text">{t('eventForm:heading_private_event')}</span>
                <Tooltip title={t('extraData:tag_visibility')} placement="top" arrow>
                        <img src={privateEvent} alt="Latency" className="is-private-icon"/>
                </Tooltip>
            </span>
            <Popover
                open={openPrivateUsersCanSee}
                anchorEl={anchorEl}
                onClose={() => setOpenPrivateUserCanSee(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{className: "cat-modal"}}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <Box bgcolor="grid.main" className="event-private-list-users" display="flex"
                     flexDirection="row"
                     justifyContent="center" flexWrap="wrap">
                    <div
                        className={"subtitle"}>{t('eventForm:forms_search__private_participants_placeholder')}</div>
                    <UserCard
                        colored={true}
                        interestsCount={0}
                        toProfile={true}
                        eventInfo={true}
                        user={user}
                        profile={event.owner}
                        displayActionButtons={false}
                        handleOnGoToProfile={() => setOpenPrivateUserCanSee(false)}
                    />
                    {event.private_guest.map(e =>
                        <UserCard
                            colored={true}
                            key={`ev-private-guest-${e.id}`}
                            interestsCount={0}
                            toProfile={true}
                            eventInfo={true}
                            user={user}
                            profile={e}
                            displayActionButtons={false}
                            handleOnGoToProfile={() => setOpenPrivateUserCanSee(false)}
                        />
                    )}
                    {event.guests.filter(e => e.event_guest.isConfirmed === true).map(e =>
                        <UserCard
                            colored={true}
                            key={`ev-private-guest-${e.id}`}
                            interestsCount={0}
                            toProfile={true}
                            eventInfo={true}
                            user={user}
                            profile={e}
                            displayActionButtons={false}
                            handleOnGoToProfile={() => setOpenPrivateUserCanSee(false)}
                        />
                    )}
                </Box>
            </Popover>
        </>
        }
    </>
}

export default EventIsPrivate;
