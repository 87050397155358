const initailState = false

const interestsModalReducer = (state = initailState, action) => {
    switch(action.type){
        case 'OPEN_INTERESTS_MODAL':
            return state = true;
        case 'CLOSE_INTERESTS_MODAL':
            return state = initailState
        default:
            return state
    }
}

export default interestsModalReducer;