import React, {useState, useEffect} from 'react';
import './calendar.scss';
import CalendarHeader from './CalendarHeader';
import CalendarDays from './CalendarDays';
import CalendarCells from './CalendarCells';
import {Grid, Box} from '@material-ui/core';
import {addMonths, subMonths, startOfMonth, isSameMonth} from 'date-fns';
import {setOpenedProfileWeeklyEvents} from '../../store/openedProfileWeeklyEvents/actions';
import {useDispatch, useSelector} from 'react-redux';
import {setMonthlyEventsCount} from '../../store/monthlyEventsCount/actions';
import {setSelectedDate} from '../../store/selectedDate/actions';
import {setAgendaWeeklyEvents} from '../../store/agendaWeeklyEvents/actions';
import {ownIsSameMonth, ownIsSameWeek} from "../../helpers/dateFormatter";
import {setOpenedDiscoverCategoriesWeeklyEvents} from "../../store/openedDiscoverCategoriesWeeklyEvents/actions";


const Calendar = (props) => {
    const [currentMonth, changeCurrentMonth] = useState(new Date())
    const [selectedDate, changeSelectedDate] = useState(new Date())
    const dispatch = useDispatch()
    const {ownerId, categoryId} = props
    const selectedGroup = useSelector(state => state.selectedGroup)
    const agendaFilter = useSelector(state => state.agendaFilter)
    const selectedDateCalendar = useSelector(state => state.selectedDateCalendar)

    useEffect(() => {
        dispatch(setSelectedDate(selectedDate))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    useEffect(() => {
            if (selectedDateCalendar && selectedDateCalendar instanceof Date) {
                onDateClick({date: selectedDateCalendar});
            }
        }
        , [selectedDateCalendar])

    const onDateClick = (day) => {
        if (day.date !== selectedDate) {
            let sameWeek = ownIsSameWeek(selectedDate, day.date);
            let sameMonth = isSameMonth(selectedDate, day.date);

            changeSelectedDate(day.date)

            if (!sameMonth) {
                changeCurrentMonth(day.date)
            }
            if (ownerId) {
                dispatch(setOpenedProfileWeeklyEvents(day.date, ownerId, sameWeek))
                if (!sameMonth) {
                    dispatch(setMonthlyEventsCount(day.date, ownerId))
                }
            } else {
                if(categoryId) {
                    dispatch(setOpenedDiscoverCategoriesWeeklyEvents(day.date, categoryId, sameWeek))
                    if (!sameMonth) {
                        dispatch(setMonthlyEventsCount(day.date, null, null, categoryId))
                    }
                } else {
                    dispatch(setAgendaWeeklyEvents(day.date, agendaFilter, (selectedGroup ? selectedGroup.id : null), sameWeek))
                    if (!sameMonth) {
                        dispatch(setMonthlyEventsCount(day.date, agendaFilter, (selectedGroup ? selectedGroup.id : null)))
                    }
                }
            }
        }
        if (props.onClickDay) {
            props.onClickDay(day.date)
        }
        if(props.hasOwnProperty('onTab')) {
            props.onTab(null, 0);
        }
    }

    const today = () => {
        changeSelectedDate(new Date())
        changeCurrentMonth(new Date())
        if (ownerId) {
            dispatch(setOpenedProfileWeeklyEvents(new Date(), ownerId))
            dispatch(setMonthlyEventsCount(new Date(), ownerId))
        } else {
            dispatch(setAgendaWeeklyEvents(new Date(), agendaFilter, (selectedGroup ? selectedGroup.id : null)))
            dispatch(setMonthlyEventsCount(new Date(), agendaFilter, (selectedGroup ? selectedGroup.id : null)))
        }
        if(props.hasOwnProperty('onTab')) {
            props.onTab(null, 0);
        }
    }

    const prevMonth = () => {
        changeCurrentMonth(subMonths(currentMonth, 1))
        changeSelectedDate(subMonths(currentMonth, 1))
        if (ownerId) {
            dispatch(setMonthlyEventsCount(subMonths(currentMonth, 1), ownerId))
            dispatch(setOpenedProfileWeeklyEvents(subMonths(currentMonth, 1), ownerId))
        } else {
            if(categoryId) {
                dispatch(setMonthlyEventsCount(subMonths(currentMonth, 1), agendaFilter, (selectedGroup ? selectedGroup.id : null), categoryId))
            } else {
                dispatch(setMonthlyEventsCount(subMonths(currentMonth, 1), agendaFilter, (selectedGroup ? selectedGroup.id : null)))
            }
            dispatch(setAgendaWeeklyEvents(subMonths(currentMonth, 1), agendaFilter, (selectedGroup ? selectedGroup.id : null)))
        }
        if(props.hasOwnProperty('onTab')) {
            props.onTab(null, 0);
        }
    }

    const nextMonth = () => {
        let newDate = addMonths(currentMonth, 1)
        newDate = startOfMonth(newDate)
        changeCurrentMonth(newDate)
        changeSelectedDate(newDate)
        if (ownerId) {
            dispatch(setMonthlyEventsCount(newDate, ownerId))
            dispatch(setOpenedProfileWeeklyEvents(newDate, ownerId))
        } else {
            if(categoryId) {
                dispatch(setMonthlyEventsCount(newDate, agendaFilter, (selectedGroup ? selectedGroup.id : null), categoryId))
            } else {
                dispatch(setMonthlyEventsCount(newDate, agendaFilter, (selectedGroup ? selectedGroup.id : null)))
            }
            dispatch(setAgendaWeeklyEvents(newDate, agendaFilter, (selectedGroup ? selectedGroup.id : null)))
        }
        if(props.hasOwnProperty('onTab')) {
            props.onTab(null, 0);
        }
    }

    return (
        <Grid>
            <Box className="calendar">
                <Grid item xs={12}>
                    <Box className="c-header">
                        <CalendarHeader onPrevClick={prevMonth} onNextClick={nextMonth} month={currentMonth}
                                        onToday={today}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <script dangerouslySetInnerHTML={{ __html: `<!--googleoff: all-->` }} />
                    <Box bgcolor="grid.content" className="c-days">
                        <CalendarDays month={currentMonth}/>
                    </Box>
                    <script dangerouslySetInnerHTML={{ __html: `<!--googleon: all-->` }} />
                </Grid>
                <Grid item xs={12}>
                    <script dangerouslySetInnerHTML={{ __html: `<!--googleoff: all-->` }} />
                    <Box className="c-cells">
                        <CalendarCells month={currentMonth} ownerId={ownerId} date={selectedDate}
                                       onDateClick={onDateClick}/>
                    </Box>
                    <script dangerouslySetInnerHTML={{ __html: `<!--googleon: all-->` }} />
                </Grid>
            </Box>
        </Grid>
    )
}

export default Calendar;
