import services from '../../services';

export const setOpenedProfileFollowing = (userId) => {
    return dispatch => {
        services.userFollowing.get(userId)
            .then((resp) => {
                dispatch({
                    type: 'SET_OPENED_PROFILE_FOLLOWING',
                    payload: resp
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetOpenedProfileFollowing = (data) => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE_FOLLOWING'})
    }
}
