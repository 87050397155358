import React, {useEffect, useMemo, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import './_landing_livescores.scss';
import services from "../../../../../services";
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import EventScoresCard from "../../../../Event/EventScoresCard/EventScoresCard";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setOpenedEvent} from "../../../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../../../store/eventInfoModal/actions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {TwitchEmbed} from "react-twitch-embed";
import {Event} from "../../../../../services/tracking";


const LandingLiveScores = ({
                               darkTheme,
                               userId,
                               onOpenPlayer,
                               containerClassname,
                               placeholderEmptyComponent,
                               max,
                               finisheds,
                               onClickMore,
                               extraFilter
                           }) => {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";
    const [loading, setLoading] = useState(true);
    const [eventsLive, setEventsLive] = useState([]);
    const [totalResults, setTotalResults] = useState(false);
    const {t} = useTranslation('landing');
    const dispatch = useDispatch();

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 2);
        let data = {
            start: formatDate(ownStartOfDay(dateObj), dateFormat),
            end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
            timezone: getCurrentTimezone(),
            isLive: !finisheds
        }
        if (max !== undefined && Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        if(extraFilter) {
            data = {...data, ...extraFilter};
        }
        services.landingEventsLiveScores.get(userId, {
            query: data
        }).then((res) => {
            if (mounted) {
                setTotalResults(res.length);
                setEventsLive(res);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId]);

    const OpenInfo = (event) => {
        window.history.pushState({backRef: window.location.pathname}, "my calendar", `/${event.slug}/${event.id}`)
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("LandingLiveScores", "OpenInfo", `/${event.owner.slug}/${event.id}`);
    };

    const RenderEvents = useMemo(() =>
            (eventsLive.length === 0 && placeholderEmptyComponent !== undefined ? placeholderEmptyComponent : [
                ...eventsLive.slice(0, max),
                ...Array.from({length: 1 - eventsLive.length}, () => 'placeholder')
            ].map((item, index) =>
                item.hasOwnProperty('id') ?
                    <div key={`landing-livescore-item-${item.id}`} className={"item--live-score"}>
                        <EventScoresCard event={item} onOpenEventModal={OpenInfo} onOpenPlayer={onOpenPlayer}
                                         isCalendar={false}/>
                    </div>
                    : <div key={index}
                           className={`event-score-card animated fadeIn not-events ${darkTheme ? '  is-dark-theme ' : ' is-light-theme'}`}>
                        <span>{finisheds ? t("no_past_events") : t("events_offline")}</span>
                    </div>
            ))

        , [darkTheme, eventsLive, placeholderEmptyComponent])

    return (
        <div className={containerClassname}>
            {(finisheds === false && totalResults > 0 && userId === "b8b5720c-5cd2-4638-9d51-b832b9443d34") && (
                <TwitchEmbed width={"100%"} height={"200px"} withChat={false} channel={"hitboxking"}
                             theme={darkTheme ? "dark" : "light"} muted/>
            )}
            {loading ? <CircularProgress thickness={5} className="input-loader"/> : RenderEvents}
            {(!loading && totalResults > max) && (
                <div onClick={onClickMore} className="landing-live-scores-load-more animated infinite slower">
                    {t("see_more_matches")} <ArrowRightAltIcon fontSize="small"/>
                </div>
            )}
        </div>
    );
};

LandingLiveScores.defaultProps = {
    containerClassname: 'landing-live-scores-container',
    finisheds: true
}

export default LandingLiveScores;
