import React from "react";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player";
import LandingHeaderTimelineComponent from "./fragments/LandingHeaderTimelineComponent";
import FollowUserButton from "../../../../Buttons/FollowUser/FollowUserButton";
import LandingHeaderCountdownComponent from "./fragments/LandingHeaderCountdownComponent";
import {Button} from "@material-ui/core";
import LandingHeaderCalendarComponent from "./fragments/LandingHeaderCalendarComponent";

const LandingHeader = ({isFollow, userId, userNickname, headerMobile, headerVideo, changeTab, userSlug}) => {
    const currentUser = useSelector(state => state.user);
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme)


    return (
        <div className={`latency-landing-header ${deviceView === "mobile" ? " is-mobile" : " is-desktop"}`}>
            {deviceView === "mobile" && (
                <img className={"header-mobile"} src={headerMobile}/>
            )}
            {deviceView !== "mobile" && (
                <>
                    {currentUser.id !== userId && (
                        <FollowUserButton isFollow={isFollow} userData={{id: userId, nickname: userNickname}}/>
                    )}

                    <Button className={`header-user-nickname ${(darkTheme ? '  is-dark-theme ' : ' is-light-theme')}`}>
                        @{userSlug}
                    </Button>
                    <ReactPlayer url={headerVideo} width="100%" height="300" controls={false}
                                 playing={true} loop={true} muted/>
                    {/*
                    <div className={`landing-header-calendar`}>
                        <Calendar />
                    </div>
                    */}

                    {deviceView === "browser" &&
                    <>
                        {(userId === 'b8b5720c-5cd2-4638-9d51-b832b9443d34') ?
                            <LandingHeaderCountdownComponent userID={userId}/>
                            : <LandingHeaderCalendarComponent handleMoveTab={changeTab} userID={userId}
                                                              userSlug={userSlug}/>
                        }
                    </>
                    }

                    {/*
                    {userSlug !== "valorant.espana" && (
                        <LandingHeaderTimelineComponent userID={userId} />
                    )}
                    */}
                </>
            )}
        </div>
    );
};

export default LandingHeader;
