import React from 'react';
import { Box, Button } from '@material-ui/core';

import ball from '../../../assets/icons/interests-icons/basketball-icon.svg';
import book from '../../../assets/icons/interests-icons/book-icon.svg';
import fashion from '../../../assets/icons/interests-icons/fashion-icon.svg';
import gamepad from '../../../assets/icons/interests-icons/gamepad-icon.svg';
import music from '../../../assets/icons/interests-icons/music-icon.svg';
import tv from '../../../assets/icons/interests-icons/tv-icon.svg';
import car from '../../../assets/icons/interests-icons/cars-icon.svg';
import esports from '../../../assets/icons/interests-icons/esports-icon.svg';

import ballColored from '../../../assets/icons/interests-icons/basketball-icon-colored.svg';
import bookColored from '../../../assets/icons/interests-icons/book-icon-colored.svg';
import fashionColored from '../../../assets/icons/interests-icons/fashion-icon-colored.svg';
import gamepadColored from '../../../assets/icons/interests-icons/gamepad-icon-colored.svg';
import musicColored from '../../../assets/icons/interests-icons/music-icon-colored.svg';
import tvColored from '../../../assets/icons/interests-icons/tv-icon-colored.svg';
import carColored from '../../../assets/icons/interests-icons/cars-icon-colored.svg';
import esportsColored from '../../../assets/icons/interests-icons/esports-icon-colored.svg';

import i18n from '../../../helpers/internationalization';
import { Textfit } from 'react-textfit';
import {useSelector} from "react-redux";

const ChipData = (props) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const {data, interests} = props

    const checkChildren = (data) => {
        for(let a=0; a<interests.length; a++){
            if(interests[a].parentId && interests[a].parentId === data.id){
                return true
            }
        }
    }

    const getName = (item) => {
        const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || 'en-US';
        if(item.translations.length){
            let data = item.translations.find(dd => dd.language.code === getCurrentLng().split("-")[0])
            return data.translation
        } else {
            return item.text
        }
    }

    return (
        <Box className={"parent-chips" + (darkTheme ? ' is-dark' : ' is-light')} p={1}>
            {data.map((item, i) =>
                <Box key={i} onClick = {(()=> {props.addToList(item)})} className={"parent-chip " + (item.userChecked ? ' is-selected' : '')}>
                    {item.icon === "fashion" && <img className="interest-icon" src={item.userChecked ? fashionColored : fashion} alt="Moda" />}
                    {item.icon === "gamepad" && <img className="interest-icon" src={item.userChecked ? gamepadColored : gamepad} alt="Videojuegos" />}
                    {item.icon === "music" && <img className="interest-icon" src={item.userChecked ? musicColored : music} alt="Música" />}
                    {item.icon === "tv" && <img className="interest-icon" src={item.userChecked ? tvColored : tv} alt="Televisión" />}
                    {item.icon === "basketball" && <img className="interest-icon" src={item.userChecked ? ballColored : ball} alt="Deportes" />}
                    {item.icon === "culture" && <img className="interest-icon" src={item.userChecked ? bookColored : book} alt="Cultura" />}
                    {item.icon === "motor" && <img className="interest-icon" src={item.userChecked ? carColored : car} alt="Motor" />}
                    {item.icon === "esports" && <img className="interest-icon" src={item.userChecked ? esportsColored : esports} alt="Esports" />}
                    {item.icon !== "esports" && item.icon !== "motor" && item.icon !== "culture" &&
                        item.icon !== "fashion" &&
                            item.icon !== "music" &&
                                item.icon !== "basketball" &&
                                    item.icon !== "tv" &&
                                        item.icon !== "gamepad" && <img className="interest-icon" src={item.userChecked ? bookColored : book} alt="interest" />
                    }

                    <span className={"subtitle " + (item.userChecked ? "bold" : " light")}>
                        <Textfit mode="single" forceSingleModeWidth={false} min={1} max={16}>
                        {getName(item)}
                        </Textfit>
                    </span>
                    {checkChildren(item) && <Button className="see-more-btn" onClick={(e) => props.onInside(item, e)}>(see more)</Button>}
                    {!checkChildren(item) && <Button className="see-more-btn empty">(no childrens)</Button>}
                </Box>

            )}
        </Box>
    )
}

export default ChipData;
