import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import client from "../../../../services/client";
import Box from "@material-ui/core/Box";
import facebookIcon from '../../../../assets/icons/actions/fb.svg';
import twitterIcon from '../../../../assets/icons/actions/twitter.svg';
import twitchIcon from '../../../../assets/icons/actions/twitch.svg';
import googleIcon from '../../../../assets/icons/actions/google-plus.svg';
import { useDispatch, useSelector } from "react-redux";
import services from "../../../../services";
import { refreshUser } from "../../../../store/user/actions";
import { showSnackbar } from "../../../../store/snackbar/actions";
import {Switch} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const SettingsConnectionsForm = () => {
  const currentUser = useSelector(state => state.user);
  const { t } = useTranslation('settings');
  const [token, setToken] = useState(false);
  const [syncTwitch, setSyncTwitch] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [syncTwitchLoading, setSyncTwitchLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    client.authentication.getAccessToken().then((userToken) => {
      setToken(userToken);
      return services.twitchEventSub.find().then(res => {
        if(res.data === 'subscribed' || (res.hasOwnProperty('total') && res.data.length === 3)) {
          setSyncTwitch(true);
        } else {
          setSyncTwitch(false);
        }
      });
    }).then(() => {
      setLoading(false);
    });
  }, []);

  const secureLinking = (e, provider) => {
    e.preventDefault();
    let url = `${process.env.REACT_APP_API_URL}/oauth/${provider}?feathers_token=${token}&redirect=/edit/settings`;
    window.open(url,"_self")
  };

  const unlinkAccount = (provider) => {
    let data = {};
    if(provider === 'facebook') {
      data.facebookId = null;
    }else if(provider === 'twitter') {
      data.twitterId = null;
    }else if(provider === 'twitch') {
      data.twitchId = null;
    }else if(provider === 'google') {
      data.googleId = null;
    }
    services.users.patch(currentUser.id, data, null)
      .then((resp) => {
        dispatch(refreshUser(currentUser.id));
        dispatch(showSnackbar({message: t("userEditForm:snackbar_profile_saved"), type: "success"}));
      })
      .catch((error) => {
        dispatch(showSnackbar({message: error.message, type: "error"}));
      });
  };

  const handleSyncTwitch = () => {
    if(!syncTwitchLoading) {
      setSyncTwitchLoading(true);
      if(!syncTwitch) {
        services.twitchEventSub.create({}).then(() => {
          setSyncTwitch(true);
          setSyncTwitchLoading(false);
        });
      } else {
        services.twitchEventSub.remove(null, {
          query: {
            userId: currentUser.id
          }
        }).then(() => {
          setSyncTwitch(false);
          setSyncTwitchLoading(false);
        });
      }
    }
  };

  return (
    <div>
      <div className="settings-content-header">
        <div className="settings-content-title">{t('section_settings_connections')}</div>
        <div className="settings-content-title-helper">{t('section_settings_connections_helper')}</div>
      </div>
      <div className="settings-content-main settings-content-connections">
        {isLoading && <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        ><CircularProgress thickness={5} className="input-loader" /></Grid>}
        {!isLoading && currentUser !== undefined && currentUser !== {} && (
          <>
          <Box bgcolor="grid.content" className="connection-wrapper">
            <div className="icon">
              <img src={facebookIcon} alt='facebook-icon'/>
            </div>
            <div className="box-content">
              <div className="connection-name">Facebook</div>
              <div className="connection-explanation">
                {t("settings:section_settings_connections_fb_placeholder")}
              </div>
            </div>
            <div className="conn">
              {(currentUser.hasOwnProperty('facebookId') && (currentUser.facebookId === null || currentUser.facebookId.length === 0)) ? (
                <>
                  {token !== false &&
                  <button className="custom-button submit-btn" onClick={(e) => secureLinking(e, 'facebook')}>
                    {t("settings:section_settings_connections_btn_connect")}
                  </button>}</>) : <button className="custom-button delete-btn" onClick={() => unlinkAccount('facebook')}>{t("settings:section_settings_connections_btn_unlink")}</button>}
            </div>
          </Box>
          <Box bgcolor="grid.content" className="connection-wrapper">
            <div className="icon">
              <img src={twitterIcon} alt='twitter-icon'/>
            </div>
            <div className="box-content">
              <div className="connection-name">Twitter</div>
              <div className="connection-explanation">
                {t("settings:section_settings_connections_twitter_placeholder")}
              </div>
            </div>
            <div className="conn">
              {(currentUser.hasOwnProperty('twitterId') && (currentUser.twitterId === null || currentUser.twitterId.length === 0)) ? (
                <>
                  {token !== false &&
                  <button className="custom-button submit-btn" onClick={(e) => secureLinking(e, 'twitter')}>
                    {t("settings:section_settings_connections_btn_connect")}
                  </button>}</>) : <button className="custom-button delete-btn" onClick={() => unlinkAccount('twitter')}>{t("settings:section_settings_connections_btn_unlink")}</button>}
            </div>
          </Box>
          <Box bgcolor="grid.content" className="connection-wrapper">
            <div className="icon">
              <img src={twitchIcon} alt='twitch-icon'/>
            </div>
            <div className="box-content">
              <div className="connection-name">Twitch</div>
              <div className="connection-explanation">
                {t("settings:section_settings_connections_twitch_placeholder")}
              </div>
              {(currentUser.hasOwnProperty('twitchId') && currentUser.twitchId !== null) && (
                  <div className="connection-expand-sync" onClick={() => handleSyncTwitch()}>
                    {syncTwitchLoading && <CircularProgress size={14} thickness={5} className="input-loader" style={{ marginRight: 8 }} />}
                    {!syncTwitchLoading && <Switch checked={syncTwitch} onChange={(e) => setSyncTwitch(!e.target.checked)} className="switch"/>}
                    <span className="label">Activar sincronización de eventos</span>
                  </div>
              )}
            </div>
            <div className="conn">
              {(currentUser.hasOwnProperty('twitchId') && (currentUser.twitchId === null || currentUser.twitchId.length === 0)) ? (
                <>
                  {token !== false &&
                  <button className="custom-button submit-btn" onClick={(e) => secureLinking(e, 'twitch')}>
                    {t("settings:section_settings_connections_btn_connect")}
                  </button>}</>) : <button className="custom-button delete-btn" onClick={() => unlinkAccount('twitch')}>{t("settings:section_settings_connections_btn_unlink")}</button>}
            </div>
          </Box>
            <Box bgcolor="grid.content" className="connection-wrapper">
              <div className="icon">
                <img src={googleIcon} alt='google-icon'/>
              </div>
              <div className="box-content">
                <div className="connection-name">Google</div>
                <div className="connection-explanation">
                  {t("settings:section_settings_connections_google_placeholder")}
                </div>
              </div>
              <div className="conn">
                {(currentUser.hasOwnProperty('googleId') && (currentUser.googleId === null || currentUser.googleId.length === 0)) ? (
                  <>
                    {token !== false &&
                    <button className="custom-button submit-btn" onClick={(e) => secureLinking(e, 'google')}>
                      {t("settings:section_settings_connections_btn_connect")}
                    </button>}</>) : <button className="custom-button delete-btn" onClick={() => unlinkAccount('google')}>{t("settings:section_settings_connections_btn_unlink")}</button>}
              </div>
            </Box>
          </>
          )}
      </div>
    </div>
  );
};

export default SettingsConnectionsForm;
