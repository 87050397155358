import React from 'react';
import {Box, Grid} from "@material-ui/core";
import LandingUpcomingEvents from "../../../LandingCover/sections/LandingLiveScores/LandingUpcomingEvents";
import LandingLiveScores from "../../../LandingCover/sections/LandingLiveScores/LandingLiveScores";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Event} from "../../../../../services/tracking";
import LandingTeamsComponent from "../LandingTeamsComponent/LandingTeamsComponent";

import headerChampions from "../../../../../assets/landings/champions2021.jpg";


const componentName = "InjectValorantChampionSpecial";

const InjectValorantChampionSpecial = ({
                                           userId,
                                           changeTab,
                                           onOpenPlayer
                                       }) => {
    const {t} = useTranslation('landing');
    const darkTheme = useSelector(state => state.darkTheme);

    const handleOpenAgenda = () => {
        changeTab(null, t("agenda"));
        window.scrollTo(0, 0)
    }

    const handleOpenPlayer = (playerId) => {
        onOpenPlayer(playerId);
        changeTab(null, t("live_now"));
        window.scrollTo(0, 0)
        Event(componentName, "OpenPlayer", playerId);
    }

    return (
        <Box component="div" className={"landing-valorant-espana"} mt={1}>
            <div className={"special-header"}>
                <img src={headerChampions} />
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={8}>
                    <LandingTeamsComponent
                        userId={userId}
                        customClassName={"valorant-champions2021"}
                        customHeadingMessage={"Activa las notificaciones y no te pierdas ningún partido de tu equipo favorito."}
                        customTeamIds={[
                            '5e3a2c57-f089-4e48-aa09-3ae6362ea08d', // KRU
                            '9a019670-b38b-45ea-a0a7-562436665878', // Acend
                            'a7ca550b-6c70-4d5f-b686-97012bdb1de0', // Sentinels
                            '9a9dc58c-4c20-4345-a0ae-7bbf2a2335ff', // TeamLiquid
                            '3b8acca3-57c2-48f5-b695-b8e2ef4f9e83', // Team Envy
                            '0d2ec40c-816b-49a6-9829-19cf58f24d47', // Vivo Keyd
                            '9300cc16-ac03-490d-9b9c-cfa0d0a75d0e', // X10 Esports
                            '5cd14c8e-ee2c-482d-8bd3-ee9a89762cc1', // Furia
                            'c9890b87-5b3c-4df8-ab4b-978b708a6615', // Gambit
                            '5ce09d51-bbfb-4290-aa24-d79824bf114e', // Team Secret
                            '1c74c71a-2bee-4bd3-b5c2-2269ab008f96', // Team Vikings
                            'c5ce9f16-da1e-4036-80d1-5bd9955b5519', // Crazy Racoon
                            'c4d87475-fd69-4c2b-9e4e-7af582225bc5', // Fnatic
                            '81142c97-7303-469f-86fe-e619736ab2bf', // Vision Strikers
                            'e0b1e8cc-f194-44f7-b293-d2dd3d0c2c28', // C9
                            '08ae3d7f-f7f9-4588-b9b9-6c534362da19', // Full Sense
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin={"auto"} p={1} mt={8}
                         borderRadius={16}>
                        <div className={"title-section"}>{t("upcoming_matches")}</div>
                        <LandingUpcomingEvents
                            userId={userId}
                            onClickMore={handleOpenAgenda}
                            darkTheme={darkTheme}
                            max={4}
                            extraFilter={{
                                location: "VALORANT Champions"
                            }}
                        />
                    </Box>
                    <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1}
                         borderRadius={16} mt={2}>
                        <div className={"scores-wrapper"}>
                            <div className={"title-section"}>{t("last_scores")}</div>
                            <LandingLiveScores userId={userId}
                                               onClickMore={handleOpenAgenda}
                                               onOpenPlayer={handleOpenPlayer}
                                               darkTheme={darkTheme}
                                               max={4}
                                               finisheds={true}
                                               extraFilter={{
                                                   location: "VALORANT Champions"
                                               }}
                            />
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InjectValorantChampionSpecial;
