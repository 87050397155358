import {useSelector} from "react-redux";
import {Rating} from "@material-ui/lab";
import {Star} from "@material-ui/icons";
import React from "react";


const FeedbackStarsInput = ({label, value, answer, onChange}) => {
    const darkTheme = useSelector(state => state.darkTheme);

    return <div>
        <label>{label}</label>
        <div>
            <Rating
                name={answer}
                precision={0.5}
                value={value}
                emptyIcon={<Star className={`${darkTheme ? 'dark-star' : ''}`}/>}
                onChange={(event, newValue) => {
                    onChange(answer, newValue)
                }}
            />
        </div>
    </div>
};

export default FeedbackStarsInput;
