import React, {useState, useMemo} from 'react';
import {isToday} from "date-fns";
import EventCard from "../Event/EventCard/EventCard";
import {useTranslation} from "react-i18next";

let MAX_HIDDEN_EVENTS = 3;

const PipelineRenderPastEvents = ({isOpenGroup, handleOnOpenGroup, events, pipelineDate, mainLength, handleOnClickEvent, isCalendar, alwaysShowPing}) => {
    const {t} = useTranslation('eventForm');
    const [slicePastEvents, setSlicePastEvents] = useState(isToday(pipelineDate) ? mainLength > MAX_HIDDEN_EVENTS ? 0 : 3 : MAX_HIDDEN_EVENTS);

    const openPastEvents = () => {
        setSlicePastEvents(events.length);
        handleOnOpenGroup(true);
    }

    const hidePastEvents = () => {
        setSlicePastEvents(MAX_HIDDEN_EVENTS);
        handleOnOpenGroup(false);
    }


    const PastEvents = useMemo(() => {
       return events
           .reverse()
           .slice(0, slicePastEvents)
           .map((event, i2) =>
               <div key={`ps-${event.id}.${i2}.${pipelineDate.getTime()}`} onClick={() => handleOnClickEvent(event)}
                    className="event">
                   <EventCard pipeDate={pipelineDate} isCalendar={isCalendar}
                              alwaysShowPing={alwaysShowPing}
                              event={event}/>
               </div>
           );
    }, [events, slicePastEvents]);


    return (
        <>
            {isToday(pipelineDate) && slicePastEvents === 1 ? (
                <div className={"pipeline-show-past-events-wrap-special"}>
                    {events.length > slicePastEvents && (
                        <div className={"pipeline-show-past-events"}
                             onClick={openPastEvents}>
                            <span>{t('pipeline_button_show_past_events')} ({events.length})</span>
                        </div>
                    )}
                    {PastEvents}
                </div>
            ) : (
                <>
                    {(events.length > slicePastEvents) && (
                        <div className={"pipeline-show-past-events"}
                             onClick={openPastEvents}>
                            <span>{t('pipeline_button_show_past_events')} ({events.length - slicePastEvents})</span>
                        </div>
                    )}
                    {PastEvents}
                </>
            )}

            {isOpenGroup && (
                <div className={"pipeline-show-past-events"}
                     onClick={hidePastEvents}>
                    <span>{t('pipeline_button_hide_past_events')}</span>
                </div>
            )}
        </>
    );

};

export default PipelineRenderPastEvents;
