import React from 'react';
import {Box} from '@material-ui/core';

const CookiesPolicyEN = () => {
    return (
      <Box className="cookies-policy-page" m={2}>
          <div className="static-content">
              <h2>Cookie policy</h2>
              <p>In compliance with the provisions of the section 2 of article 22 of Law 34/2002, of July 11, on Information Society Services and Electronic Commerce, the present cookie policy is intended to inform you clearly and precisely about the cookies used on LATENCY E-SPORTS SL.</p>

              <h2>What are cookies?</h2>
              <p>A cookie is a small file that websites send to your browser and download to your computer. Cookies allow the website to store and retrieve information about your visit, such as your preferred language and other options, in order to improve the services offered and contribute to a better browsing experience for the user.</p>

              <h2>Types of cookies</h2>
              <p>Depending on who manages the domain from which the cookies are sent and the data is processed, a distinction will be made between own cookies and third party cookies.</p>
              <p>Cookies can also be classified according to the length of time they remain stored in the user's browser, with a distinction being made between session cookies and persistent cookies.</p>

              <p>Finally, depending on the purpose for which the information collected is processed, cookies can be classified into the following categories:</p>
              <ul>
                  <li>Technical cookies: they allow the user to navigate through a website and use some of the services offered by it (such as those that serve to remember the elements that make up a shopping cart).</li>
                  <li>Personalisation cookies: these allow the user to access the website with certain characteristics, such as language.</li>
                  <li>Security cookies: they serve to prevent or hinder attacks against the website.</li>
                  <li>Plug-in cookies for exchanging social content: so-called plug-ins, which allow content to be shared on social networks.</li>
                  <li>Analysis cookies: they allow the person in charge to follow the user's behaviour.</li>
                  <li>Advertising cookies: they allow the management of the advertising spaces on the website.</li>
                  <li>Behavioral advertising cookies: like the previous ones, they are used to manage the website's advertising spaces based on the information collected on the user's behavior and habits, adapting advertising to the user's profile.</li>
                  <li>Geolocation cookies: used to find out the country where the user is located.</li>
              </ul>

              <h2>Authorization for the use of cookies</h2>
              <p>In accordance with the cookie notice on the website, the user may expressly consent to the use of cookies that require consent; however, the use of technical or necessary cookies does not require consent. Notwithstanding the above, the user can modify the configuration of his or her browser to reject the use of cookies.</p>

              <h2>How to change your cookie settings</h2>
              <p>You can restrict, block or delete cookies from LATENCY or any other website using your browser. Each browser is different, you can find out how to do this in your browser's help menu where the procedure for deletion is set. For more information:</p>
              <ul>
                  <li>
                      <p>
                          <strong>Google Chrome: </strong>
                          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a>
                      </p>
                  </li>
                  <li>
                      <p>
                          <strong>Mozilla Firefox: </strong>
                          <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>
                      </p>
                  </li>
                  <li>
                      <p>
                          <strong>Safari: </strong>
                          <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
                      </p>
                  </li>
                  <li>
                      <p>
                          <strong>Internet Explorer: </strong>
                          <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer</a>
                      </p>
                  </li>
              </ul>
              <p>Disabling cookies may cause the website to malfunction or prevent you from accessing certain features of the website.</p>

              <h2>Cookies used on the website</h2>
              <p>The web portal uses different types of cookies for different purposes. Below is a list of the cookies used:</p>
              <table className="table table-striped table-bordered mb-5" id="CookieTable">
                  <thead>
                  <tr>
                      <th scope="col">Cookie</th>
                      <th scope="col" width="50%">Description</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Type</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>__cfduid</td>
                      <td>The cookie is set by CloudFare. The cookie is used to identify individual clients behind a
                          shared IP address and apply security settings on a per-client basis. It does not correspond to
                          any user ID in the web application and does not store any personally identifiable information.
                      </td>
                      <td>4 weeks</td>
                      <td>Necessary</td>
                  </tr>
                  <tr>
                      <td>_ga</td>
                      <td>This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session,
                          campaign data and keep track of site usage for the site's analytics report. The cookies store
                          information anonymously and assign a randomly generated number to identify unique visitors.
                      </td>
                      <td>2 years</td>
                      <td>Analytics</td>
                  </tr>
                  <tr>
                      <td>_gid</td>
                      <td>This cookie is installed by Google Analytics. The cookie is used to store information of how
                          visitors use a website and helps in creating an analytics report of how the wbsite is doing. The
                          data collected including the number visitors, the source where they have come from, and the
                          pages viisted in an anonymous form.
                      </td>
                      <td>1 day</td>
                      <td>Analytics</td>
                  </tr>
                  <tr>
                      <td>_gat</td>
                      <td>This cookies is installed by Google Universal Analytics to throttle the request rate to limit
                          the colllection of data on high traffic sites.
                      </td>
                      <td>1 minute</td>
                      <td>Performance</td>
                  </tr>
                  </tbody>
              </table>

              <h2>Modification of the conditions</h2>
              <p>LATENCY E-SPORTS SL expressly reserves the right to unilaterally modify this Cookie Policy, in whole or in part, without prior notice. You acknowledge and agree that it is your responsibility to review this Cookie Policy.</p>
          </div>
      </Box>
    );
};

export default CookiesPolicyEN;
