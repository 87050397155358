export const openInterestsModal = () => {
    return dispatch => {
        dispatch({type: 'OPEN_INTERESTS_MODAL'})
    }
}

export const closeInterestsModal = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_INTERESTS_MODAL'})
    }
}
