import React, {useEffect, useRef, useState} from 'react';
import {Box, Grid, Switch, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { subMonths } from 'date-fns'

import './_add_event_form.scss';
import DateBlockComponent from "./DateBlockComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EventKeywordsInput from "../AddEventForm/EventKeywordsInput";
import ParticipantsList from "../AddEventForm/ParticipantsList";
import NewDescriptionBox from "../AddEventForm/NewDescriptionBox";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Scroller from "../../Scroller/Scroller";
import Modal from "../../Modal/Modal";
import ImageCropper from "../../ImageCropper/ImageCropper";
import CircleAdd from "../../../assets/icons/actions/add_event_circle.svg";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../CustomButton/CustomButton";
import Loader from "../../Loader/Loader";
import {showSnackbar} from "../../../store/snackbar/actions";
import {formatDate} from "../../../helpers/dateFormatter";
import services from "../../../services";
import {updateCalendar} from "../../../store/app/actions";
import {setOpenedProfileWeeklyEvents} from "../../../store/openedProfileWeeklyEvents/actions";
import OwnerList from "../AddEventForm/OwnerList";
import {MetaDataForm} from "../AddEventForm/MetaDataForm";


const AddEventFormComponent = ({event, onClose}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(['eventForm, extraData']);
    const [loading, setLoading] = useState(false);

    const openedProfile = useSelector(state => state.openedProfile);
    const selectedDate = useSelector(state => state.selectedDate);

    const user = useSelector(state => state.user);
    const darkTheme = useSelector(state => state.darkTheme);
    const deviceView = useSelector(state => state.deviceView);

    const [enablePeriodicity, setEnablePeriodicity] = useState(false);
    const [periodicityEndRule, setPeriodicityEndRule] = useState("never");
    const [owner, setOwner] = useState(event && event.hasOwnProperty('owner') && event.owner.id ? event.owner.id : user.id);

    const [title, setTitle] = useState((event && event.title) ? event.title : "");
    const [titleError, setTitleError] = useState(false);

    const minDateCreate = subMonths(new Date(), 18);

    const initDate = new Date(new Date().setHours(0, 0, 0));
    const initDate2 = new Date(new Date().setHours(23, 59, 59));

    const inputPoster = useRef(null);

    const [allDay, setAllDay] = useState((event && event.hasOwnProperty('is_fullday')) ? event.is_fullday : true);
    const [startDate, setStartDate] = useState((event && event.start) ? new Date(event.start) : initDate);
    const [endDate, setEndDate] = useState((event && event.end) ? new Date(event.end) : initDate2);

    const [event_keywords, setEventKeywords] = useState(event.id ? event.categories : []);

    const [description, setDescription] = useState((event && event.description) ? event.description : "")

    const [location, setLocation] = useState((event && event.location) ? event.location : "");
    const [website, setWebsite] = useState((event && event.website) ? event.website : "");
    const [websiteErr, setWebsiteErr] = useState(false);
    const [organizer, setOrganizer] = useState((event && event.organizer) ? event.organizer : "");

    const [participants, setPart] = useState(event.id ? event.guests : []);
    const [isPrivate, setIsPrivate] = useState((event && event.hasOwnProperty('is_private')) ? event.is_private : false);
    const [privateParticipants, setPrivatePart] = useState(event.id ? event.private_guest : [])


    const emptyValue = {text: '', id: ''};
    const eventCategories = useSelector(state => state.eventCategories);
    const [eventCategoryParent, setEventCategoryParent] = useState(emptyValue);

    const [recentEventCategoriesUsed, setRecentEventCategoriesUsed] = useState([]);

    const [isOpenDateStartPicker, setOpenDateStartPicker] = useState(false);
    const [isOpenDateEndPicker, setOpenDateEndPicker] = useState(false);


    const [cropModal, showCropModal] = useState(false);
    const [croppedImage, setCroppedImage] = useState("");
    const [poster, setPoster] = useState(event && event.poster ? event.poster : "/images/defaultHeader2.png");
    const [originalPoster, setOriginalPoster] = useState(event && event.original_poster ? event.original_poster : "");

    const [metadata, setMetadata] = useState(event && event.custom_fields ? event.custom_fields : "")

    const handleMetadata = (data) => {
        console.log('handleMetadata', data);
        setMetadata(data);
    }

    const initCategories = () => {
        if (event && event.categoryId) {
            if(eventCategories && eventCategories.length > 0) {
                let category = eventCategories.find(r => r.id === event.categoryId);
                if (category) {
                    setEventCategoryParent(category);
                }
            }
        }
    };

    useEffect(initCategories, []);
    useEffect(() => {
        if (event && !event.hasOwnProperty('id')) {
            if (eventCategories.length > 0) {
                setEventCategoryParent(eventCategories.find(i => i.slug === "vida-privada"));
            }
        }
        if (eventCategories.length > 0) {
            setRecentEventCategoriesUsed(
                eventCategories.sort(() => Math.random() - Math.random()).slice(0, 3)
            )
        }
    }, [event, eventCategories]);


    const handleChangeAllDay = (ev) => {
        let checked = ev.target.checked
        setAllDay(checked);
        let date_now = new Date();
        let date_start = new Date(startDate);
        let date_end = new Date(endDate);
        if(checked) {
            date_start.setHours(0, 0, 0)
            date_end.setHours(23, 59,59);
        } else {
            if(
                date_now.getFullYear() === date_start.getFullYear() &&
                date_now.getMonth() === date_start.getMonth() &&
                date_now.getDate() === date_start.getDate()
            ) {
                date_start.setHours(date_now.getHours(), date_now.getMinutes(), 0)
                date_end.setHours(date_now.getHours()+1, date_now.getMinutes(),0);
            } else {
                date_start.setHours(date_now.getHours(), 0, 0)
                date_end.setHours(date_now.getHours()+1, 0,0);
            }
        }

        setStartDate(date_start);
        setEndDate(date_end);
    };

    const handleChangeEventCategoryParent = (e, newCategory) => {
        if (newCategory != null) {
            setEventCategoryParent(newCategory);
        } else {
            setEventCategoryParent(emptyValue);
        }
    }

    const handleOpenPicker = (picker) => {
        if (picker === "start") {
            setOpenDateStartPicker(val => !val);
            setOpenDateEndPicker(false);
        } else {
            setOpenDateStartPicker(false);
            setOpenDateEndPicker(val => !val);
        }
    }

    const handleClosePicker = () => {
        setOpenDateStartPicker(false);
        setOpenDateEndPicker(false);
    }

    const handleCatChange = (data) => {
        setEventKeywords(data)
    }

    const handlePrivatePartChange = (data) => {
        setPrivatePart(data)
    }

    const handlePartChange = (data) => {
        setPart(data)
    }

    const handleOwnerChange = (data) => {
        if (data) {
            setOwner(data.id)
        } else {
            setOwner(user.id)
        }
    }

    const posterChange = (e) => {
        showCropModal(true)
        let file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            setPoster(reader.result);
            setOriginalPoster(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleChangeStartDate = (new_date) => {
        setStartDate(new_date);
        if(new_date > endDate) {
            let copy_date = new Date(new_date);
            if(allDay) {
                copy_date.setHours(23, 59,59)
            } else {
                copy_date.setHours(
                    copy_date.getHours()+1,copy_date.getMinutes(), 0
                )
            }
            setEndDate(copy_date)
        }
    }

    const validateDates = (start, end) => {
        if (end.getTime() < start.getTime()) {
            dispatch(showSnackbar({ message: "End Date should be greater than or equal to Start Date", type: "error" }))
            return false
        }
        if (start.getTime() === end.getTime()) {
            if (end.getHours() < start.getHours()) {
                dispatch(showSnackbar({
                    message: "End Time should be greater than Start Time for same day Events",
                    type: "error"
                }))
                return false;
            }
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!title) {
            setTitleError(true)
            dispatch(showSnackbar({ message: "Event title cannot be empty", type: "error" }))
            return;
        }

        let categoryId = eventCategoryParent && eventCategoryParent.id !== ''
            ? eventCategoryParent.id
            : false;

        if(categoryId === false) {
            dispatch(showSnackbar({ message: t("eventForm:event_category_not_empty"), type: "error" }))
            return;
        }

        if (!validateDates(startDate, endDate)) {
            return;
        }

        let start = startDate;
        let end = endDate;
        if (allDay) {
            start.setHours(0);
            start.setMinutes(0);
            end.setHours(23);
            end.setMinutes(59);
        }
        let pp = []
        for (let a = 0; a < participants.length; a++) {
            pp.push(participants[a].id)
        }
        let pp2 = []
        for (let a = 0; a < privateParticipants.length; a++) {
            pp2.push(privateParticipants[a].id)
        }
        let data = {
            title,
            start: formatDate(start, 'yyyy-MM-dd HH:mm:ssXXX'),
            end: formatDate(end, 'yyyy-MM-dd HH:mm:ssXXX'),
            is_private: isPrivate,
            location: location,
            website: website,
            description: description,
            organizer: organizer,
            is_fullday: allDay,
            guests: pp,
            categories: event_keywords,
            private_guest: pp2,
            premium_display_poster: true,
            categoryId: categoryId,
            custom_fields: JSON.stringify(metadata)
        }
        if (owner) {
            data.ownerId = owner
        }
        saveEvent(data)
    };

    const saveEvent = (data) => {
        setLoading(true)
        if (event && event.id) {
            if (croppedImage) {
                data.poster = croppedImage;
                data.original_poster = originalPoster;
            }
            services.events.patch(event.id, data, null)
                .then(() => {
                    dispatch(showSnackbar({ message: "event edited successfully", type: "success" }))
                    onClose();
                    setLoading(false)
                })
                .catch((error) => {
                    dispatch(showSnackbar({ message: error.message, type: "error" }))
                    setLoading(false)
                })
        } else {
            if (croppedImage) {
                data.poster = croppedImage;
                data.original_poster = originalPoster;
            }
            services.events.create(data)
                .then((resp) => {
                    dispatch(updateCalendar(selectedDate))
                    if (openedProfile.id) {
                        dispatch(setOpenedProfileWeeklyEvents(selectedDate, openedProfile.id))
                    }
                    dispatch(showSnackbar({ message: "event added successfully", type: "success" }))
                    onClose()
                    setLoading(false)
                })
                .catch((error) => {
                    dispatch(showSnackbar({ message: error.message, type: "error" }))
                    setLoading(false)
                })
        }
    }

    const handleTogglePeriodicity = (ev) => {
        let checked = ev.target.checked
        setEnablePeriodicity(checked);
    }

    const handlePeriodicityEndRule = (ev) => {
        setPeriodicityEndRule(ev.target.value);
    }

    return (
        <div className={`add-event-form-container`}>
            {loading && (
                <Loader/>
            )}
            <div className="top-header">
                <div className="title">{event.id ? t('eventForm:heading_edit_new_event') : t('eventForm:heading_add_new_event')}</div>
                <CloseIcon className="close-btn" onClick={onClose}/>
            </div>
            {(isOpenDateStartPicker || isOpenDateEndPicker) && (
                <div className="MuiBackdrop-root" aria-hidden="true"
                     style={{
                         opacity: 1,
                         position: 'absolute',
                         zIndex: 9998,
                         transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                     }}></div>
            )}
            <Scroller>
                <Box className="form-field">
                    <div className="field-label"> {t('eventForm:forms_label_title')} </div>
                    <TextField
                        fullWidth
                        required
                        className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                        error={titleError}
                        type="text"
                        placeholder={t('eventForm:forms_no_title')}
                        value={title}
                        variant="outlined"
                        onChange={(e) => {
                            setTitle(e.target.value)
                            !e.target.value ? setTitleError(true) : setTitleError(false)
                        }}
                    />
                </Box>

                {(user && user.roles && user.roles.includes('admin') && !event.id) && (
                    <Box className="form-field mt">
                        <div className="field-label">Event Owner</div>
                        <OwnerList initialData={user} owner={true} handleChange={handleOwnerChange} />
                    </Box>
                )}

                <Box className="form-field is-container container-1-2 mt">
                    <div>
                        <div className="field-label"> {t('eventForm:forms_label_poster')} </div>
                        <Tooltip title={t('extraData:buttons_upload_poster_event')}>
                            <Box bgcolor="grid.main" className="poster-placeholder">
                                <img className="poster-display" src={croppedImage ? croppedImage : poster}
                                     alt="Poster"/>
                                <Box className={`absolute ${croppedImage.length > 0 ? ' is-selected' : ''}`}
                                     display="flex" flexDirection="column"
                                     justifyContent="center" alignItems="center"
                                     onClick={() => inputPoster.current.click()}>
                                    <img src={CircleAdd} alt="Latency"/>
                                    <input ref={inputPoster} type="file" id="add-event-image"
                                           accept="image/gif, image/jpeg, image/png"
                                           onChange={posterChange}/>
                                </Box>
                            </Box>
                        </Tooltip>
                    </div>
                    <div>
                        <div className="field-label-w-action">
                            <div className="field-label"> {t('eventForm:forms_label_date')} </div>
                            <div className="date-switcher-mode">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={allDay}
                                            onChange={handleChangeAllDay}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={t('eventForm:forms_label_allDay')}
                                />
                            </div>
                        </div>

                        <div className={`box-bg date-component${allDay ? " is-all-day" : ""}${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
                            <div className="date-wrapper">
                                <DateBlockComponent
                                    type="start"
                                    label={t('eventForm:forms_label_new_date_start')}
                                    date={startDate}
                                    minDate={minDateCreate}
                                    handleChange={handleChangeStartDate}
                                    onOpenPicker={handleOpenPicker}
                                    onClosePicker={handleClosePicker}
                                    pickerVisible={isOpenDateStartPicker}
                                    hideTime={allDay}/>
                                <DateBlockComponent
                                    type="end"
                                    label={t('eventForm:forms_label_new_date_end')}
                                    date={endDate}
                                    minDate={startDate}
                                    handleChange={setEndDate}
                                    onOpenPicker={handleOpenPicker}
                                    onClosePicker={handleClosePicker}
                                    pickerVisible={isOpenDateEndPicker}
                                    hideTime={allDay}
                                    absPickerStyle={{
                                        right: '24px'
                                    }}
                                />
                            </div>
                            {/*
                            <div className="periodicty-input">
                                <FormControlLabel
                                    labelPlacement="end"
                                    control={
                                        <Checkbox
                                            checked={enablePeriodicity}
                                            onChange={handleTogglePeriodicity}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={t('eventForm:forms_label_configure_periodicity')}
                                />

                                {enablePeriodicity && (
                                    <div className={`box-bg date-component${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Se repite el</FormLabel>
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    value="bottom"
                                                    control={<Checkbox color="primary" />}
                                                    label="D"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="bottom"
                                                    control={<Checkbox color="primary" />}
                                                    label="L"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="bottom"
                                                    control={<Checkbox color="primary" />}
                                                    label="M"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="bottom"
                                                    control={<Checkbox color="primary" />}
                                                    label="X"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="bottom"
                                                    control={<Checkbox color="primary" />}
                                                    label="J"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="bottom"
                                                    control={<Checkbox color="primary" />}
                                                    label="V"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="bottom"
                                                    control={<Checkbox color="primary" />}
                                                    label="S"
                                                    labelPlacement="bottom"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Termina:</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender1" value={periodicityEndRule} onChange={handlePeriodicityEndRule}>
                                                <FormControlLabel value="never" control={<Radio />} label="Nunca" />
                                                <FormControlLabel value="male" control={<Radio />} label="El" />
                                                <FormControlLabel value="other" control={<Radio />} label="Después de X repeticiones" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    )}

                            </div>
                                                            */}
                        </div>
                    </div>

                </Box>

                <Box className="form-field mt">
                    <div className="field-label"> {t('eventForm:forms_label_category')} </div>
                    <div className={`category-field`}>
                        {(eventCategoryParent && eventCategoryParent.hasOwnProperty('id') && eventCategoryParent.id.length > 0) ? (
                            <div>
                                {eventCategoryParent.cover_url &&
                                <img className="category-selected-poster"
                                     src={process.env.REACT_APP_CDN + eventCategoryParent.cover_url}
                                     alt={eventCategoryParent.text}/>}
                            </div>
                        ) : <div></div>}


                        <div>
                            <Autocomplete
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={t('eventForm:forms_search_categories_placeholder')}
                                        fullWidth
                                    />
                                )}
                                renderOption={(option) => (
                                    <Box display="flex" justifyContent="center" alignItems="center"
                                         className={"event-category-list--item"}>
                                        {option.cover_url &&
                                        <img className="category-option-img"
                                             src={process.env.REACT_APP_CDN + option.cover_url}
                                             alt={option.text}/>}
                                        <span> {option.text}</span>
                                    </Box>
                                )}
                                className={"category-select " + (darkTheme ? "category-select-dark" : "category-select-light")}
                                value={eventCategoryParent}
                                options={eventCategories}
                                getOptionLabel={(option) => option.text}
                                onChange={handleChangeEventCategoryParent}
                                freeSolo={false}
                            />
                            <div className="recently-used">
                                <span>{t('eventForm:forms_label_category_recently')}</span>
                                {recentEventCategoriesUsed.map(item => <span key={item.id}
                                                                             onClick={() => handleChangeEventCategoryParent(null, item)}>{item.text}</span>)}
                            </div>
                        </div>
                    </div>
                </Box>

                <Box className="form-field mt">
                    <div className="field-label"> {t('eventForm:heading_participants')} </div>
                    <ParticipantsList quick={false} initialData={participants} handleChange={handlePartChange}/>
                    {isPrivate && (<span className={"input-helper"}>{t('eventForm:helper_participants_priv')}</span>)}
                </Box>

                <Box className="form-field mt">
                    <div className="field-label"> {t('eventForm:forms_description')} </div>
                    <NewDescriptionBox description={description} setDescription={setDescription}/>
                </Box>

                <Box className="form-field is-container r-mobile mt">
                    <div>
                        <div className="field-label">{t('eventForm:forms_location')}</div>
                        <TextField
                            fullWidth
                            className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                            placeholder={t('eventForm:forms_location_placeholder')}
                            value={location}
                            variant="outlined"
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className="field-label">{t('eventForm:forms_website')}</div>
                        <TextField
                            fullWidth
                            className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                            placeholder={t('eventForm:forms_website_placeholder')}
                            value={website}
                            variant="outlined"
                            error={websiteErr}
                            helperText={websiteErr && t('eventForm:forms_err_website_max_length')}
                            onChange={(e) => {
                                setWebsite(e.target.value);
                                if (e.target.value.length > 128) {
                                    setWebsiteErr(true);
                                } else {
                                    setWebsiteErr(false);
                                }
                            }}
                        />
                    </div>
                </Box>

                <Box className="form-field is-container r-mobile mt">
                    <div>
                        <div className="field-label"> {t('eventForm:forms_organizer')} </div>
                        <TextField
                            fullWidth
                            className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                            placeholder={t('eventForm:forms_organizer_placeholder')}
                            value={organizer}
                            variant="outlined"
                            onChange={(e) => setOrganizer(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className="field-label"> {t('eventForm:forms_label_keywords')} </div>
                        <EventKeywordsInput quick={false} initialData={event_keywords} handleChange={handleCatChange}/>
                    </div>
                </Box>

                <Box className="form-field is-container r-mobile mt">
                    <div>
                        <div className="field-label"> {t('eventForm:forms_label_visibility')} </div>
                        <Box bgcolor="grid.main" className={`box-bg is-sm visibility-component ${darkTheme ? 'is-dark-theme' : 'is-light-theme'}`}>
                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <span className={`option-label ${!isPrivate ? "option-selected" : ""}`}
                                  onClick={() => setIsPrivate(false)}>{t('eventForm:heading_public_event')}</span>
                                <Switch checked={isPrivate} onChange={(e) => setIsPrivate(val => !val)}
                                        className="switch"/>
                                <span className={`option-label ${isPrivate ? "option-selected" : ""}`}
                                      onClick={() => setIsPrivate(true)}>{t('eventForm:heading_private_event')}</span>
                            </Box>
                        </Box>
                    </div>
                    <div>
                        <div
                            className={`field-label ${!isPrivate ? " is-disabled" : ""}`}>{t('eventForm:forms_label_guests')} </div>
                        <Grid item xs={12}>
                            <ParticipantsList quick={false} disabled={!isPrivate} private={true}
                                              initialData={privateParticipants}
                                              handleChange={handlePrivatePartChange}/>
                        </Grid>
                    </div>
                </Box>

                <Box className="form-field mt">
                    <div className="field-label"> Metadata </div>
                    <MetaDataForm metadata={metadata} onChange={handleMetadata}  />
                </Box>

            </Scroller>

            <Box mt={1} ml={1} mr={1} display="flex" flexDirection="row" justifyContent="space-between">
                <CustomButton className="cancel-btn wide" size="large" disabled={loading}
                              onClick={onClose}> {t('extraData:buttons_cancel')} </CustomButton>
                <CustomButton className="submit-btn wide" loading={loading} disabled={loading} size="large"
                              onClick={(e) => handleSubmit(e)}> {t('eventForm:buttons_save_event')} </CustomButton>
            </Box>

            <Modal
                state={cropModal}
                persist={false}
                max={"lg"}
                fullScreen={false}
                onClose={() => showCropModal(false)}>

                <ImageCropper
                    cropper={deviceView === "browser" ? {
                        width: 380,
                        height: 680
                    } : (deviceView === "tablet" ? {width: 380, height: 680} : {width: 380, height: 680})}
                    poster={poster}
                    className="add-event"
                    onCrop={(img) => setCroppedImage(img)}
                    onClose={() => showCropModal(false)}/>
            </Modal>

        </div>
    );
};

export default AddEventFormComponent;
