import React from 'react';
import {Box, Tooltip} from "@material-ui/core";
import ArrowDown from '../../../assets/icons/actions/chevron-down.svg';
import IconMinus from '../../../assets/icons/actions/minus.svg';
import abbreviate from "number-abbreviate";

const UserGroupEventsCard = ({profile, count, isExpanded}) => {
    return (
        <Box className="user-card" display="flex" flexDirection="row" bgcolor="events.group">
            <Box>
                <img src={profile.avatar ? profile.avatar : "/images/defaultAvatar.png"} className="avatar"
                     alt={profile.nickname}/>
            </Box>
            <Box className="user-card-content" display="flex" flexDirection="column" justifyContent="center"
                 alignItems="flex-start">
                {profile.nickname &&
                <Tooltip title={profile.nickname} placement="top" arrow>
                    <span className="name">
                        {profile.nickname}
                    </span>
                </Tooltip>}
                <Box>
                    <span className="events-count">{count} Eventos</span>
                </Box>
            </Box>
            <Box className="user-display-arrow" display="flex" flexDirection="column" justifyContent="center"
                 alignItems="flex-start">
                <img src={isExpanded ? IconMinus : ArrowDown} alt="desplegar" className="arrow-down"/>
            </Box>
        </Box>
    )
};

export default UserGroupEventsCard;
