import React from 'react';
import './event-score.scss';
import {Box, Tooltip} from '@material-ui/core';
import {useSelector} from "react-redux";
import {IconButton} from '@material-ui/core';
import twitchLightIcon from '../../../assets/icons/actions/twitch-white.svg';
import twitchDarkIcon from '../../../assets/icons/actions/twitch-dark.svg';

const EventScoresCard = ({event, onOpenPlayer, onOpenEventModal}) => {
    const darkTheme = useSelector(state => state.darkTheme)

    const handleOpenAction = (e) => {
        e.stopPropagation();
        onOpenPlayer(`${event.twitch ? event.twitch : 'no-twitch-channel'}#+#${event.id.replaceAll('-', '')}`);
    }

    return (
        <Tooltip title={event.title} placement="bottom" arrow>
            <Box onClick={() => onOpenEventModal(event)}
                 className={`event-score-card animated fadeIn ${darkTheme ? '  is-dark-theme ' : ' is-light-theme'}
                 ${((event.hasOwnProperty('is_destacado') && event.is_destacado && (event.isLive === true)) ? ' destacado ' : '')}
                 ${(event.isLive === true) ? 'is-live' : ''} `}>
                <div className="event-score-info">
                    {event.poster && <img src={event.poster} alt=""/>}
                </div>
                <div className="event-score-team is-team-home">
                    <img src={event.team_local.image} alt={event.team_local.name}/>
                    <span>{event.team_local.name}</span>
                </div>
                <div className="event-score-center">{event.team_local.scoreboard}:{event.team_guest.scoreboard}</div>
                <div className="event-score-team is-team-guest">
                    <span>{event.team_guest.name}</span>
                    <img src={event.team_guest.image} alt={event.team_guest.name}/>
                </div>
                {(event.isLive === true) &&
                <div className="event-score-action">
                    {(event.twitch) && (
                        <IconButton size="small" onClick={handleOpenAction}>
                            {darkTheme && <img src={twitchLightIcon} alt="twitch icon" style={{width: "18px"}}/>}
                            {!darkTheme && <img src={twitchDarkIcon} alt="twitch icon" style={{width: "18px"}}/>}
                        </IconButton>
                    )}
                </div>
                }
            </Box>
        </Tooltip>
    )
}

export default EventScoresCard;

