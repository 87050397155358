const initailState = {
    open: false,
    message: " ",
    type: "info"
}

const snackbarReducer = (state = initailState, action) => {
    switch(action.type){
        case 'OPEN_SNACKBAR':
            return {...state, open: true, message: action.payload.message, type: action.payload.type}
        case 'CLOSE_SNACKBAR':
            return state = initailState
        default:
            return state
    }
}

export default snackbarReducer;