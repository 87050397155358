import React from 'react';
import Box from "@material-ui/core/Box";
import MultiAccountItem from "./MultiAccountItem";
import { useDispatch, useSelector } from "react-redux";
import './form.scss';
import CustomButton from "../CustomButton/CustomButton";
import { removeAccount } from "../../store/accounts/actions";
import client from "../../services/client";

const ManageMultiAccountForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const multiAccount = useSelector(state => state.multiAccount);

  const removeAccountItem = (userId) => {
    if (user.id === userId) {
      let anotherToken = multiAccount.filter(item => item.id !== userId)[0];
      client.authentication.setAccessToken(anotherToken.token).then(res => {
        dispatch(removeAccount(userId));
        window.location.reload(false);
      }).catch(err => {
        window.location.reload(false);
      })
    } else {
      dispatch(removeAccount(userId));
    }
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center"
         className="manage-multi-acc-form">
      <h1>Manage accounts</h1>
      <div className="multi-account-list">
        {multiAccount.map((account, index) =>
                            <div key={index} className="custom-multi-account-list">
                              <MultiAccountItem account={account}/>
                              <CustomButton
                                onClick={() => {
                                  removeAccountItem(account.id)
                                }}
                                className="close-session-btn">Cerrar sesión</CustomButton>
                            </div>
        )}
      </div>
    </Box>
  );
};

export default ManageMultiAccountForm;
