import services from "../../services";

export const setSubscription = (data) => {
    return dispatch => {
        services.subscriptions.find({
            query: {
                $limit: -1
            }
        }).then((res) => {
            dispatch({
                type: 'SET_SUBSCRIPTION',
                payload: res
            });
        });
    };
};

export const unsetSubscription = () => {
    return dispatch => {
        dispatch({type: 'UNSET_SUBSCRIPTION'})
    };
};
