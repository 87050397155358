import services from '../../services';
import {addDays, endOfDay, isSameDay, parseISO, startOfDay, subDays} from 'date-fns';
import {deviceType} from 'react-device-detect';
import {store} from '../';
import {formatDate, getCurrentTimezone, ownEndOfWeek, ownStartOfWeek} from '../../helpers/dateFormatter';

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"

export const setAgendaWeeklyEvents = (date, id=null, groupId=null, sameWeek=false) => {
    return dispatch => {
        let { deviceView } = store.getState();
        dispatch({
            type: 'UNSET_AGENDA_WEEKLY_SELECTED'
        })
        let params = prepareParams(deviceView ? deviceView : deviceType, date)
        if(id){params.query.ownerId = id}
        if(groupId){params.query.groupId = groupId}

        dispatch({
            type: 'SET_AGENDA_WEEKLY_SELECTED',
            payload: params.query
        });
        /*
        if(!sameWeek || deviceView !== 'browser'){
            dispatch(setAgendaWeeklyEventsLoading())
        }
        let params = prepareParams(state.deviceView ? state.deviceView : deviceType, date)
        if(id){params.query.ownerId = id}
        if(groupId){params.query.groupId = groupId}
        services.calendar.find(params)
            .then((resp) => {
                dispatch({
                    type: 'SET_AGENDA_WEEKLY_EVENTS',
                    payload: prepareWeeklyData(date, resp.data)
                })
            })
            .catch((error) => {
                console.log(error)
            })
         */
    }
}

export const unsetAgendaWeeklyEvents = () => {
    return dispatch => {
        dispatch({type: 'UNSET_AGENDA_WEEKLY_EVENTS'})
    }
}

export const setAgendaWeeklyEventsLoading = () => {
    return dispatch => {
        dispatch({type: 'SET_AGENDA_WEEKLY_EVENTS_LOADING'})
    }
}

// helper funtions are written down here
const prepareWeeklyData = (date, data) => {
    date = new Date(date)
    let week = [];
    data.forEach((item) => {
        let objDate = parseISO(item.day);
        week.push({
           day: item.day,
           date: objDate,
           current:  isSameDay(objDate, date),
           events: item.events
        });
    });
    return week;
}

const prepareParams = (type, date) => {
    let dateObj = new Date(date);
    switch(type){
        case "browser":
            return {
                query: {
                    start: formatDate(ownStartOfWeek(dateObj), dateFormat),
                    end: formatDate(ownEndOfWeek(dateObj), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false
                }
            }
        case "mobile":
            return {
                query: {
                    start: formatDate(startOfDay(dateObj), dateFormat),
                    end: formatDate(endOfDay(dateObj), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        case "tablet":
            return {
                query: {
                    start: formatDate(subDays(dateObj, 1), dateFormat),
                    end: formatDate(addDays(dateObj, 1), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        default:
            return {
                query: {
                    start: formatDate(ownStartOfWeek(dateObj), dateFormat),
                    end: formatDate(ownEndOfWeek(dateObj), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
    }
}

const prepareParamsWithoutDateFormatting = (type, date) => {
    let dateObj = new Date(date);
    switch(type){
        case "browser":
            return {
                query: {
                    start: ownStartOfWeek(dateObj),
                    end: ownEndOfWeek(dateObj),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false
                }
            }
        case "mobile":
            return {
                query: {
                    start: startOfDay(dateObj),
                    end: endOfDay(dateObj),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        case "tablet":
            return {
                query: {
                    start: subDays(dateObj, 1),
                    end: addDays(dateObj, 1),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        default:
            return {
                query: {
                    start: ownStartOfWeek(dateObj),
                    end: ownEndOfWeek(dateObj),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
    }
}
