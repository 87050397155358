import React, {useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './_landing_highlights_carousel.scss';
import services from "../../../../../services";
import {setLandingFeedClips} from "../../../../../store/landingFeedClips/actions";
import LandingPreviewFeedClip
    from "../../../LandingMain/fragments/LandingFeedComponent/Feed/LandingFeedClips/LandingPreviewFeedClip";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {Autoplay, Pagination, Navigation} from 'swiper';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const LandingHighlightsCarousel = ({
                                       type,
                                       userId,
                                       darkTheme,
                                       max,
                                       onClickClip,
                                       showClipInfo,
                                   }) => {
    const dispatch = useDispatch();
    const [highlights, setHighlights] = useState([]);
    const deviceView = useSelector(state => state.deviceView);

    useEffect(() => {
        let mounted = true;
        if (userId) {
            services.landingFeed.find({
                query: {
                    type: type,
                    roomId: userId,
                    $limit: max,
                    $sort: {
                        createdAt: -1
                    }
                }
            }).then((res) => {
                if (mounted) {
                    if (type === "clip") {
                        setHighlights(res.data)
                        dispatch(setLandingFeedClips(userId, res));
                    } else {
                        setHighlights(res.data)
                    }
                    //dispatch(setLandingFeedClips(userId, res));
                }
            });
        }
        return () => mounted = false;
    }, [userId]);

    const RenderHighlights = useMemo(() =>
            [...highlights.slice(0, max)].map((item) => (
                <SwiperSlide key={`landing-highlight-item-${item.id}`} onClick={() => {
                    onClickClip(item.id)
                }}>
                    <LandingPreviewFeedClip item={item} allowRemove={false}
                                            playVideo={false}
                                            showClipInfo={showClipInfo}
                    />
                </SwiperSlide>)
            )
        , [darkTheme, highlights, max])

    return (<Swiper
        slidesPerView={deviceView === 'browser' || deviceView === 'tablet' ? 3 : 1}
        className="mySwiper"
        loop
        speed={2500}
        pagination={{
            "clickable": true
        }}
        navigation={true}
        spaceBetween={20}
        autoplay={{
            "delay": 3500
        }}>
        {RenderHighlights}
    </Swiper>);
};

LandingHighlightsCarousel.defaultProps = {
    type: 'clip',
    containerClassname: 'landing-highlights-container',
    showClipInfo: false
}

export default LandingHighlightsCarousel;
