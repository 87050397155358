export const openCompleteSignupModal = () => {
    return dispatch => {
        dispatch({type: 'OPEN_COMPLETE_SIGNUP_MODAL'})
    }
}

export const closeCompleteSignupModal = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_COMPLETE_SIGNUP_MODAL'})
    }
}
