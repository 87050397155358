import React, {useEffect} from "react";
import {Grid, Box, Paper, FormControl, InputLabel, Input, Select, MenuItem, TextField} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import BirthdayInput from "../../../Inputs/BirthdayInput";
import {useSelector} from "react-redux";

const UserDetails = ({slug, city, birthday, gender, setBirthday, setCity, setGender, onComplete}) => {
    const deviceView = useSelector(state => state.deviceView);
    const {t} = useTranslation(['userEditForm']);

    const checkEnableForm = () => {
        let pass = true;
        if(slug && slug.length === 0) {
            pass = false;
        }
        if(city.length === 0) {
            pass = false;
        }
        if(birthday.length === 0) {
            pass = false;
        }
        if(gender.length === 0) {
            pass = false
        }
        if(pass) {
            onComplete(true);
        }
    };

    useEffect(() => {
      checkEnableForm();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, birthday, city, gender]);

    return (
        <Box className={`complete-signup-user_details ${deviceView === "mobile" ? " is-mobile" : ""}`}>
            <Paper className={'user_details-paper'} variant="outlined">
                <Grid container   direction="column"
                      justifyContent="center"
                      alignItems="stretch" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth margin={'dense'}>
                            <InputLabel shrink={true} htmlFor="birthday-input">{t('userEditForm:forms_birthDate')}</InputLabel>
                            <Input
                                value={birthday}
                                onChange={(text) => setBirthday(text)}
                                name="textmask"
                                id="birthday-input"
                                inputComponent={BirthdayInput}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth margin={'dense'}>
                            <TextField
                                label={t('userEditForm:forms_city')}
                                placeholder={t('userEditForm:forms_placeholder_city')}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth margin={'dense'}>
                            <InputLabel shrink={true}>{t('forms_genre_label')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <MenuItem value="male"> {t('forms_tag_male')} </MenuItem>
                                <MenuItem value="female">{t('forms_tag_female')}</MenuItem>
                                <MenuItem value="other">{t('forms_tag_other')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </Paper>
        </Box>
    );
};

export default UserDetails;
