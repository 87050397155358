import { setRecommendations, unsetRecommendations } from "../recommendations/actions";
import { setInterests } from "../interests/actions";
import { setUserGroups, unsetUserGroups } from "../userGroups/actions";
import { setDiscoverUsers } from "../discoverUsers/actions";
import { unsetOpenedProfileWeeklyEvents, setOpenedProfileWeeklyEvents } from "../openedProfileWeeklyEvents/actions";
import { setMonthlyEventsCount, unsetMonthlyEventsCount } from "../monthlyEventsCount/actions";
import { setUserCategories } from "../userCategories/actions";
import { setOpenedProfile, unsetOpenedProfile, changeOpenedProfile } from "../openedProfile/actions";
import { showSnackbar } from "../snackbar/actions";
import services from "../../services";
import { setAgendaWeeklyEvents, unsetAgendaWeeklyEvents } from "../agendaWeeklyEvents/actions";
import { setOpenedProfileFollowers, unsetOpenedProfileFollowers } from '../openedProfileFollowers/actions';
import { setOpenedProfileFollowing, unsetOpenedProfileFollowing } from '../openedProfileFollowing/actions';
import { setOpenedProfileCategories, unsetOpenedProfileCategories } from "../openedProfileCategories/actions";
import { setOpenedProfileNextEvents } from '../openedProfileNextEvents/actions';
import { setUserFollowing } from '../userFollowing/actions';
import { store } from '../';
import { refreshUser } from '../user/actions';
import { setParticipants } from "../participants/actions";
import {unsetOpenedProfileFilterCategory} from "../openedProfileFilterCategory/actions";

export const updateApp = (userId) => {
    return dispatch => {
        dispatch(setInterests())
        //dispatch(setUserGroups(userId))
        dispatch(setUserFollowing(userId))
        //dispatch(setDiscoverUsers())
        dispatch(setUserCategories(userId))
    }
}

export const updateCalendar = (date, userId=null, groupId=null) => {
    return dispatch => {
        //dispatch(setUserGroups(userId))
        dispatch(setAgendaWeeklyEvents(new Date(date), userId, groupId))
        dispatch(setMonthlyEventsCount(new Date(date), userId, groupId))
    }
}

export const removeCalendar = (date, userId=null, groupId=null) => {
    return dispatch => {
        dispatch(unsetAgendaWeeklyEvents(new Date(date), userId, groupId))
        dispatch(unsetMonthlyEventsCount(userId, groupId))
        dispatch(unsetRecommendations())
        dispatch(unsetUserGroups(userId))
    }
}

export const prepareProfile = (date, userId, redirect=null) => {
    return dispatch => {
        dispatch(unsetOpenedProfileFilterCategory());
        if(!userId.hasOwnProperty('id')) {
            dispatch(setOpenedProfile(userId, redirect))
            dispatch(setOpenedProfileNextEvents(userId))
            dispatch(setOpenedProfileFollowers(userId))
            dispatch(setOpenedProfileFollowing(userId))
            dispatch(setOpenedProfileCategories(userId))
            dispatch(setMonthlyEventsCount(date, userId, null))
            dispatch(setOpenedProfileWeeklyEvents(date, userId, false))
        } else {
            dispatch({type: 'SET_OPENED_PROFILE', payload: userId})
            dispatch(setOpenedProfileNextEvents(userId.id))
            dispatch(setOpenedProfileFollowers(userId.id))
            dispatch(setOpenedProfileFollowing(userId.id))
            dispatch(setOpenedProfileCategories(userId.id))
            dispatch(setMonthlyEventsCount(date, userId.id, null))
            dispatch(setOpenedProfileWeeklyEvents(date, userId.id, false))
        }
    }
}

export const removeProfile = () => {
    return dispatch => {
        dispatch(unsetOpenedProfileWeeklyEvents())
        dispatch(unsetMonthlyEventsCount())
        dispatch(unsetOpenedProfile())
        dispatch(unsetOpenedProfileFollowers())
        dispatch(unsetOpenedProfileFollowing())
        dispatch(unsetOpenedProfileCategories())
        dispatch(unsetMonthlyEventsCount())
        dispatch(unsetOpenedProfileFilterCategory());
    }
}

export const followUser = (profile, redirect, type, setFollow, setLoading, onError) => {
    return dispatch => {
        const state = store.getState()
        const {user, selectedDate, selectedGroup, openedProfile} = state
        if(user.id){
            if(user.id === profile.id){
                setLoading(false)
                dispatch(showSnackbar({message: "You cannot follow yourself!", type: "info"}))
            } else{
                services.follow.create({
                    userId: user.id,
                    followingId: profile.id
                })
                .then((resp) => {
                    setFollow(true)
                    switch(type){
                        case "profile-info":
                            setLoading(false)
                            profile.follow = true
                            dispatch(changeOpenedProfile(profile))
                            dispatch(setOpenedProfileFollowers(profile.id))
                            dispatch(refreshUser(user.id))
                            dispatch(setParticipants())
                            dispatch(showSnackbar({message: `You are now following "${profile.nickname}"`, type: "success"}))
                            break;
                        case "recommendations":
                            setLoading(false)
                            dispatch(updateCalendar(selectedDate, null, selectedGroup.id))
                            dispatch(refreshUser(user.id))
                            dispatch(setParticipants())
                            dispatch(setRecommendations())
                            dispatch(showSnackbar({message: `You are now following "${profile.nickname}"`, type: "success"}))
                            break;
                        case "portfolio":
                            setLoading(false)
                            if(user.id === openedProfile.id){
                                dispatch(setOpenedProfile(openedProfile.id))
                            }
                            dispatch(setOpenedProfileFollowers(openedProfile.id))
                            dispatch(setOpenedProfileFollowing(openedProfile.id))
                            dispatch(refreshUser(user.id))
                            dispatch(setParticipants())
                            dispatch(showSnackbar({message: `You are now following "${profile.nickname}"`, type: "success"}))
                            break;
                        case "agenda-event":
                            setTimeout(function () {
                                setLoading(false)
                                dispatch(updateCalendar(selectedDate, null, selectedGroup.id))
                                dispatch(refreshUser(user.id))
                                dispatch(setParticipants())
                                dispatch(showSnackbar({message: `You are now following "${profile.nickname}"`, type: "success"}))
                            }, 1250);
                            break;
                        case "profile-event":
                            setTimeout(function () {
                                setLoading(false)
                                dispatch(setOpenedProfile(openedProfile.id))
                                dispatch(setOpenedProfileFollowers(openedProfile.id))
                                dispatch(setOpenedProfileFollowing(openedProfile.id))
                                dispatch(setUserFollowing(user.id))
                                dispatch(setParticipants())
                                dispatch(showSnackbar({message: `You are now following "${profile.nickname}"`, type: "success"}))
                            }, 1250);
                            break;
                        case "explore-user":
                            dispatch(refreshUser(user.id))
                            setLoading(false)
                            break;
                        default:
                            setLoading(false)
                            dispatch(refreshUser(user.id))
                            dispatch(showSnackbar({message: `You are now following "${profile.nickname}"`, type: "success"}))
                            break
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false);
                    if(onError) {
                        onError()
                    } else {
                        dispatch(showSnackbar({message: `Something went wrong!`, type: "info"}))
                    }

                })
            }
        } else{
            redirect()
        }
    }
}

export const unfollowUser = (profile, redirect, type, setFollow, setLoading) => {
    return dispatch => {
        const state = store.getState()
        const {user, selectedDate, selectedGroup, openedProfile} = state
        if(user.id){
            services.follow.remove(null, {query: {followingId: profile.id}})
            .then((resp) => {
                setFollow(false)
                switch(type){
                    case "profile-info":
                        setLoading(false)
                        profile.follow = false
                        dispatch(changeOpenedProfile(profile))
                        dispatch(setOpenedProfileFollowers(profile.id))
                        dispatch(refreshUser(user.id))
                        dispatch(setParticipants())
                        dispatch(showSnackbar({message: `You are not following "${profile.nickname}" anymore`, type: "info"}))
                        break
                    case "recommendations":
                        setLoading(false)
                        dispatch(updateCalendar(selectedDate, null, selectedGroup.id))
                        dispatch(refreshUser(user.id))
                        dispatch(showSnackbar({message: `You are not following "${profile.nickname}" anymore`, type: "info"}))
                        break
                    case "portfolio":
                        setLoading(false)
                        if(user.id === openedProfile.id){
                            dispatch(setOpenedProfile(openedProfile.id))
                        }
                        dispatch(setOpenedProfileFollowers(openedProfile.id))
                        dispatch(setOpenedProfileFollowing(openedProfile.id))
                        dispatch(refreshUser(user.id))
                        dispatch(setParticipants())
                        dispatch(showSnackbar({message: `You are not following "${profile.nickname}" anymore`, type: "info"}))
                        break
                    case "agenda-event":
                        setTimeout(function () {
                            setLoading(false)
                            dispatch(updateCalendar(selectedDate, null, selectedGroup.id))
                            dispatch(refreshUser(user.id))
                            dispatch(setParticipants())
                            dispatch(showSnackbar({message: `You are not following "${profile.nickname}" anymore`, type: "info"}))
                        }, 1250);
                        break
                    case "profile-event":
                        setTimeout(function () {
                            setLoading(false)
                            dispatch(setOpenedProfile(openedProfile.id))
                            dispatch(setOpenedProfileFollowers(openedProfile.id))
                            dispatch(setOpenedProfileFollowing(openedProfile.id))
                            dispatch(setUserFollowing(user.id))
                            dispatch(setParticipants())
                            dispatch(showSnackbar({message: `You are now following "${profile.nickname}"`, type: "success"}))
                        }, 1250);
                        break
                    default:
                        setLoading(false)
                        dispatch(refreshUser(user.id))
                        dispatch(showSnackbar({message: `You are not following "${profile.nickname}" anymore`, type: "info"}))
                        break
                }
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
                dispatch(showSnackbar({message: `Something went wrong!`, type: "info"}))
            })
        } else{
            redirect()
        }
    }
}
