const initialState = {
    general: {
        theme: 'light',
        language: 'es',
        experimental: false,
        groupedEvents: true
    }
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SETTINGS':
            return state = action.payload;
        case 'TOGGLE_SETTINGS_GEN_EXPERIMENTAL_MODE':
            return {
                ...state, general: {
                    ...state.general,
                    experimental: action.payload
                }
            };
        case 'SET_SETTINGS_GEN_LANGUAGE':
            return {
                ...state, general: {
                    ...state.general,
                    language: action.payload
                }
            };
        case 'TOGGLE_SETTINGS_GEN_GROUPED_MODE':
            return {
                ...state, general: {
                    ...state.general,
                    groupedEvents: action.payload
                }
            };
        case 'UNSET_SETTINGS':
            return state = initialState;
        default:
            return state
    }
};

export default settingsReducer;
