import React, {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {useDispatch, useSelector} from "react-redux";
import Lottie from "react-lottie-player";
import './_explore_featured_events_component.scss';
import services from "../../../services";
import animationData from "../../../assets/animations/warning-yellow.json";
import {formatDate, getCurrentTimezone, getDateWithoutTime} from "../../../helpers/dateFormatter";
import {isSameDay, parseISO} from "date-fns";
import {useTranslation} from "react-i18next";
import {setOpenedEvent} from "../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../store/eventInfoModal/actions";
import EventCardSimple from "../../Event/EventCardSimple/EventCardSimple";
import {Event} from "../../../services/tracking";

const ExploreFeaturedEventsComponent = ({ userId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('explore');
    const dateToday = new Date();
    const [loading, setLoading] = useState(true);
    const [isErrorFetching, setErrorFetching] = useState(false);
    const darkTheme = useSelector(state => state.darkTheme);
    const deviceView = useSelector(state => state.deviceView);
    const [highlightedItem, setHighlightedItem] = useState(null);
    const [highlightedList, setHighlightedList] = useState([]);


    useEffect(() => {
        let query = {
            timezone: getCurrentTimezone()
        };
        if(userId != null) {
            query.userId = userId;
        }
        services.exploreFeaturedEvents.find({
            query
        }).then(res => {
            setHighlightedItem(res.length > 0 ? res[0] : null)
            setHighlightedList(res.slice(1, 6));
            if(res.length === 0) {
                setLoading(true);
            } else {
                setLoading(false);
            }
            setErrorFetching(false);
        }).catch(err => {
            setLoading(true);
            setErrorFetching(true);
        });
    }, [userId]);

    const handleOpenHighlightItem = (e) => {
        e.preventDefault();
        window.history.pushState({backRef: window.location.pathname}, "Latency", `/${highlightedItem.owner.slug}/${highlightedItem.id}`)
        dispatch(setOpenedEvent(highlightedItem.id))
        dispatch(setOpenedEventMessages(highlightedItem.id))
        dispatch(openEventInfoModal())
        Event("ExploreFeaturedEventsComponent", "handleOpenHighlightItem", `/${highlightedItem.owner.slug}/${highlightedItem.id}`);

    }

    const onClickEvent = (item) => {
        window.history.pushState({backRef: window.location.pathname}, "Latency", `/${item.owner.slug}/${item.id}`)
        dispatch(setOpenedEvent(item.id))
        dispatch(setOpenedEventMessages(item.id))
        dispatch(openEventInfoModal())
        Event("ExploreFeaturedEventsComponent", "onClickEvent", `/${highlightedItem.owner.slug}/${highlightedItem.id}`);

    }

    return (
        <Box bgcolor="grid.content" className={`explore-featured-events--main is-${deviceView}`}>
            {isErrorFetching && (
                <div className="explore-featured-events--main--handling-err">
                    <Lottie
                        loop={true}
                        animationData={animationData}
                        play
                        style={{ width: 200, height: 'auto' }}
                    />
                    <span>ERROR FETCHING</span>
                </div>
            )}

            <article className="explore-featured-events--main--highlighted">
                {loading && (<Skeleton width={"100%"} height={358} variant="rect" className={(darkTheme ? 'dark' : 'light')} />)}
                {(!loading && highlightedItem !== null) && (
                    <h2>
                        <a href={`/${highlightedItem.owner.slug}/${highlightedItem.id}`} onClick={handleOpenHighlightItem}>
                            <div className="explore-featured-events--main--highlighted--item">
                                <div className="explore-featured-events--main--highlighted--item-background" style={{
                                    backgroundImage: `url(${highlightedItem.poster})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover"
                                }}></div>
                                <div className="explore-featured-events--main--highlighted--item-info">
                                    <div className="explore-featured-events--main--highlighted--item-info-avatar">
                                        <img width="72" height="72" src={highlightedItem.owner.avatar ? highlightedItem.owner.avatar : "/images/defaultAvatar.png"} alt={highlightedItem.owner.nickname} className="image" />
                                    </div>
                                    <aside className="explore-featured-events--main--highlighted--item-info-inline">
                                        <div className="explore-featured-events--main--highlighted--item-info-title">{highlightedItem.title}</div>
                                        <div className="explore-featured-events--main--highlighted--item-info-date">{highlightedItem.hasOwnProperty("start")
                                            ? highlightedItem.is_fullday ? `${formatDate(highlightedItem.start, "iii. d")} - ${t('explore_label_event_all_day')}` : isSameDay(new Date(), getDateWithoutTime(parseISO(highlightedItem.start))) ? `${t('explore_label_event_today')} ${formatDate(highlightedItem.start, "HH:mm")}h` : formatDate(highlightedItem.start, "iii. d - HH:mm")
                                            : "-"}</div>
                                    </aside>
                                </div>
                            </div>
                        </a>
                    </h2>

                )}
            </article>
            <div className="explore-featured-events--main--sidebar">
                {loading && [0, 1, 2, 3].map(item =>
                    <Skeleton key={item} width={"100%"} height={72} variant="rect" className={(darkTheme ? 'dark' : 'light')} />)}
                {(!loading && highlightedList.length > 0) && highlightedList.map(item =>
                    <a href={`/${item.owner.slug}/${item.id}`} key={`${item.id}`} onClick={(e) => {
                        e.preventDefault();
                        onClickEvent(item)
                    }}
                         className="event">
                    <EventCardSimple key={item.id} event={item} nowDate={dateToday} />
                    </a>
                )}
            </div>
        </Box>
    );
};

export default ExploreFeaturedEventsComponent;
