import React, {useState, useEffect} from 'react';
import './verify.scss';
import { useQueryParam, StringParam } from 'use-query-params';
import services from "../../services";
import {Box} from "@material-ui/core";
import Lottie from 'react-lottie-player'
import animationData from './../../assets/animations/success-greentick.json';
import errorAnimation from './../../assets/animations/error-redtick.json';

const Verify = () => {
    const token = useQueryParam('token', StringParam);
    const [status, setStatus] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    useEffect(() => {
        if(token != null){
            services.authManagement.create({
                action: 'verifySignupLong',
                value: token[0]
            }).then((res) => {
                setIsSuccess(true);
                setStatus('The account has been verified.');
            }).catch((err) => {
                setIsFailed(true);
                setStatus('The verification has been failed.');
            });
        }
    }, []);

    return (
        <Box className="verify-page" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {isSuccess && (
                <>
                    <Lottie
                        loop={false}
                        animationData={animationData}
                        play
                        style={{ width: 150, height: 150 }}
                    />
                    <p>{status}</p>
                </>
            )}
            {isFailed && (
                <>
                    <Lottie
                        loop={false}
                        animationData={errorAnimation}
                        play
                        style={{ width: 150, height: 150 }}
                    />
                    <p>{status}</p>
                </>
            )}
        </Box>
    )
}

export default Verify;
