import React from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import {useSelector} from 'react-redux';
import {Box} from "@material-ui/core";

const EventCategoriesList = (props) => {
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <Autocomplete
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={props.placeholder}
                    fullWidth
                />
            )}
            renderOption={(option) => (
                <Box display="flex" justifyContent="center" alignItems="center" className={props.quick ? "event-category-list--item quick" : "event-category-list--item"}>
                    {option.cover_url &&
                    <picture>
                        <source srcSet={process.env.REACT_APP_CDN + option.cover_url + ".webp"} type="image/webp"/>
                        <source srcSet={process.env.REACT_APP_CDN + option.cover_url} type="image/jpeg"/>
                        <img className="category-option-img" src={process.env.REACT_APP_CDN + option.cover_url}
                             alt={option.text}/>
                    </picture>}
                    <span> {option.text}</span>
                </Box>
            )}
            className={"tag-select " + (darkTheme ? "tag-select-dark" : "tag-select-light") + (props.quick ? " quick" : "")}
            value={props.value}
            options={props.options}
            getOptionLabel={(option) => option.text}
            onChange={props.onChange}
            classes={{
                popper: props.quick ? "quick-tag-select-dropdown" : "",
                endAdornment: props.quick ? "clear-icon" : "",
            }}
        />
    )
}


export default EventCategoriesList;
