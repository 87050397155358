import React, {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import "./_landing_teams.scss"
import LandingTeamItem from "./LandingTeamItem";
import services from "../../../../../services";
import {showSnackbar} from "../../../../../store/snackbar/actions";
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';

const LandingSmallTeamsComponent = ({userId, onChangeTab, customClassName, customHeadingMessage, customTeamIds}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    let teamIds = customTeamIds ? customTeamIds : [
        'ae6d5576-474e-4b74-8ad9-dfe935519470',
        '4410cc89-840b-4fee-ba1e-9a8378065a42',
        '47c2415c-a945-4e31-bc7e-1d3aaa58117a', // guild
        '9a019670-b38b-45ea-a0a7-562436665878', // acend
        'c4fee41d-5667-4b6b-af8a-f0092afede4a',
        'a7ca550b-6c70-4d5f-b686-97012bdb1de0', // sentinels
        '5e3a2c57-f089-4e48-aa09-3ae6362ea08d',
        '9a9dc58c-4c20-4345-a0ae-7bbf2a2335ff', //team liquid
        'c9890b87-5b3c-4df8-ab4b-978b708a6615', //gambit
        'c4d87475-fd69-4c2b-9e4e-7af582225bc5', //fnatic
        '9f166ccf-f0cf-49dd-8c7a-a15f13735474', // smb
        '6c311de8-60fe-4385-a512-6050f27fa278', // koi
    ];
    if (userId === "b8b5720c-5cd2-4638-9d51-b832b9443d34") {
        teamIds = [
            "ae6d5576-474e-4b74-8ad9-dfe935519470",
            "9a9dc58c-4c20-4345-a0ae-7bbf2a2335ff",
            "9a019670-b38b-45ea-a0a7-562436665878",
            "c9890b87-5b3c-4df8-ab4b-978b708a6615",
            "4410cc89-840b-4fee-ba1e-9a8378065a42",
            "c4d87475-fd69-4c2b-9e4e-7af582225bc5",
            "9858718c-b137-44f9-872b-6d15239e1ff8",
            "3d202d86-eb6c-464f-b407-7614c562f8ff",
            "49434168-8ea5-4b26-bf5a-4deb05ea02b3",
            "f724f5fd-3753-4f79-8c10-3cb6dc32c385",
            "6744c8dc-6f26-4306-a22d-821f05048dfe",
            "01139c58-7660-4eb6-b231-f7bb3537f078",
            "ed97a30b-bf97-49c2-8425-27ce16509bda",
            "6d6b5e67-2240-4510-9b26-c399adacae2f",
            "c05f5fce-aac6-4cd6-b127-86958a4155ce",
            "9f166ccf-f0cf-49dd-8c7a-a15f13735474",

        ]
    }
    const {t} = useTranslation('landing');
    const darkTheme = useSelector(state => state.darkTheme);
    const valorantCategory = "b9815deb-43a0-481d-8f7f-4c606fffc146";
    const history = useHistory();
    const [teams, setTeams] = useState([]);

    const handleFollow = (needUserFollow, userMain, categoryId, onComplete, onError) => {
        if (user && user.hasOwnProperty('id')) {
            if (needUserFollow) {
                services.follow.create({
                    userId: user.id,
                    followingId: userMain
                })
                    .then((resp) => {
                        services.userSubCategory.remove(null, {
                            query: {
                                ownerId: userMain,
                                categoryId: categoryId
                            }
                        }).then((res) => {
                            if (onComplete) {
                                onComplete()
                            }
                        }).catch((error) => {
                            if (onError) {
                                onError()
                            }
                        });
                    }).catch((error) => {
                    if (onError) {
                        onError()
                    }
                    dispatch(showSnackbar({message: `Something went wrong!`, type: "info"}))
                })
            } else {
                services.userSubCategory.remove(null, {
                    query: {
                        ownerId: userMain,
                        categoryId: categoryId
                    }
                }).then((res) => {
                    if (onComplete) {
                        onComplete()
                    }
                }).catch((error) => {
                    if (onError) {
                        onError()
                    }
                })
            }
        } else {
            history.push('/login')
            dispatch(showSnackbar({message: `To do this you must be logged in.`, type: "info"}))
        }
    }

    const handleUnfollow = (userMain, categoryId, onComplete, onError) => {
        if (user && user.hasOwnProperty('id')) {
            services.userSubCategory.create({
                ownerId: userMain,
                categoryId: categoryId
            }).then((res) => {
                if (onComplete) {
                    onComplete()
                }
            }).catch((error) => {
                if (onError) {
                    onError()
                }
            })
        } else {
            history.push('/login')
        }
    }

    useEffect(() => {
        services.users.find({
            query: {
                id: {
                    $in: [...teamIds]
                },
                $limit: 20
            },

        }).then((res) => {
            if (user && user.hasOwnProperty('id')) {
                services.userCategories.find({
                    query: {
                        userId: {$in: [...teamIds]},
                        categoryId: valorantCategory
                    }
                }).then((res1) => {
                    setTeams(prevState => {
                        let teams_res = [];

                        res.data.forEach((t) => {
                            let categoryOwner = res1.find(x => x.ownerId === t.id);
                            let isCategorySubscribed = categoryOwner && categoryOwner.is_subscribed;
                            teams_res.push({
                                ...t,
                                isCategorySubscribed
                            })
                        });

                        return teams_res;
                    });
                });
            } else {
                setTeams(res.data);
            }
        });
    }, [userId])

    return (
        <Box className={`container-background landing-teams-cover-component ${darkTheme ? ' dark' : ' light'}`}
             margin="auto" p={1} borderRadius={16}>
            <div>
                <div className={"title-section"}>{t('teams')}</div>
                <div className={`landing-teams-wrap`}>
                    {(teams && teams.length > 0) &&
                    teams.map(team => <LandingTeamItem key={`landing-team-component-${team.id}`}
                                                       gridItemSettings={{
                                                           item: {
                                                               xs: 12,
                                                               lg: 12,
                                                               md: 12
                                                           },
                                                           boxPadding: {
                                                               py: 1,
                                                               px: 1
                                                           },
                                                           team: {
                                                               hideContentName: false
                                                           }
                                                       }}
                                                       user={team}
                                                       suscribeCategoryId={valorantCategory}
                                                       onFollowClick={handleFollow}
                                                       onUnfollowClick={handleUnfollow}
                    />)}
                    <Box py={1} px={1} display="flex" className={`landing-teams__item-special-paragraph ${darkTheme ? 'dark' : ''}`}>
                        <span>{t('cover_landing_teams_paragraph')}</span>
                    </Box>
                    <Box py={1} px={1} display="flex" className={`landing-teams__item-special ${darkTheme ? 'dark' : ''}`}>
                        <div onClick={() => onChangeTab(null, t("teams"))}>
                            <span>Ver más equipos</span>
                            <ArrowRightIcon fontSize="small" />
                        </div>
                    </Box>
                </div>
            </div>
        </Box>
    );
};

export default LandingSmallTeamsComponent;
