import NavbarSearchForm from "../Forms/NavbarSearchForm/NavbarSearchForm";
import React from "react";
import {Box, Grid} from "@material-ui/core";
import { useLocation } from 'react-router-dom'

const MobileSearchComponent = () => {
    const location = useLocation();

    if(location.pathname === "/agenda" || location.pathname === "/explore") {
        return (
            <Grid item xs={12} style={{
                width: '100%'
            }}>
                <Box bgcolor="grid.content" style={{
                    paddingTop: '0.3rem',
                    paddingBottom: '0.3rem',
                    paddingLeft: '0.15rem',
                    paddingRight: '0.15rem',
                }}>
                    <NavbarSearchForm/>
                </Box>
            </Grid>
        );
    } else {
        return null;
    }


};

export default MobileSearchComponent;
