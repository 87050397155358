import React, { useState } from 'react';
import './interests-form.scss';
import { Grid, Button } from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import services from '../../../services/'
import {refreshUser} from '../../../store/user/actions';
import Modal from '../../Modal/Modal';
import DiscoverForm from '../DiscoverForm/DiscoverForm';
import { useEffect } from 'react';
import ParentChips from './ParentChips';
import ChildrenChips from './ChildrenChips';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useTranslation} from 'react-i18next';
import CustomButton from '../../CustomButton/CustomButton';
import { showSnackbar } from '../../../store/snackbar/actions';

const InterestsForm = (props) => {
    const user = useSelector(state => state.user)
    const [selected, setSelected] = useState([])
    const [parents, setParents] = useState([])
    const [children, setChildren] = useState([])
    const [inside, setInside] = useState(false)
    const [selectedParent, setSelectedParent] = useState({})
    const interests = useSelector(state => state.interests)
    const darkTheme = useSelector(state => state.darkTheme)
    const [discoverModal, setDiscoverModal] = useState(false)
    const dispatch = useDispatch()
    const {t} = useTranslation('extraData')
    const [loading, setLoading] = useState(false)
    const deviceView = useSelector(state => state.deviceView)

    useEffect(() => {
        let ss = []
        for(let a=0; a<interests.length; a++){
            if(interests[a].userChecked === true){
                ss.push(interests[a])
            }
        }
        setSelected(ss)
    }, [interests])

    useEffect(() => {
        seperateParents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interests])

    const seperateParents = () => {
        let pp = []
        for(let a=0; a<interests.length; a++){
            if(!interests[a].parentId){
                pp.push(interests[a])
            }
        }
        setParents(pp)
    }

    const seperateChildren = (data, e) => {
        e.stopPropagation()
        let cc = []
        for(let a=0; a<interests.length; a++){
            if(interests[a].parentId && interests[a].parentId === data.id){
                cc.push(interests[a])
            }
        }
        if(cc.length > 0){
            setInside(true)
        }

        setSelectedParent(data)
        setChildren(cc)
    }

    const addToList = (item) => {
        let data = [...interests]
        for(let a=0; a<data.length; a++){
            if(data[a].id === item.id){
                data[a].userChecked = !data[a].userChecked
            }
        }
        let data2 = (selected && selected.length) ? [...selected] : []
        if(!checkIfExists(item, data2)){
            data2.push(item)
        } else {
            data2 = data2.filter(dd => dd.id !== item.id)
        }

        setSelected([...data2])
        if(props.onChange){
            props.onChange(data2)
        }
    }

    const checkIfExists = (item, list) => {
        for(let a=0; a<list.length; a++){
            if(list[a].id === item.id){
                return true
            }
        }
        return false
    }

    const handleSubmit = () => {
        setLoading(true)
        if(!props.onChange){
            if(selected.length > 0){
                let data = {
                    interests: requestData([...selected])
                }
                services.users.patch(user.id, data, null)
                    .then((resp) => {
                        dispatch(refreshUser(user.id))
                        setDiscoverModal(true)
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log(error)
                        setLoading(false)
                    })
            } else {
                setLoading(false)
                dispatch(showSnackbar({message: "Please select some interest to continue!", type: "info"}))
            }
        }
    }

    const requestData = (data) => {
        let dd = []
        for(let a=0; a<data.length; a++){
            dd.push(data[a].id)
        }
        return dd
    }

    const closeBothForms = () => {
        setDiscoverModal(false)
        props.onClose()
    }

    return (
        <Grid container className="interests-form">
            { !props.onChange &&
                <Grid item xs={12}>
                    <span className="box-title">{t('heading_choose_interests')}</span>
                </Grid>
            }
            <Grid item xs={12}>
                {inside && <Button onClick={() => setInside(false)} className="back-btn" color="secondary"><ArrowBackIosIcon className="icon" color="secondary" /> {t('buttons_back')} </Button>}
                {!inside && <ParentChips data={parents} interests={interests} addToList={addToList} onInside={seperateChildren} />}
                {inside && <ChildrenChips parent={selectedParent} data={children} darkTheme={darkTheme} addToList={addToList} />}
            </Grid>
            {!props.onChange && !inside &&
                <Grid item xs={12} className="bottom" container justifyContent="center">
                    <CustomButton loading={loading} disabled={loading} onClick={handleSubmit} className="submit-btn wide"> {t('buttons_accept')} </CustomButton>
                </Grid>
            }
            {!props.onChange && inside &&
                <Grid item xs={12} className="bottom" container justifyContent="center">
                    <CustomButton onClick={() => setInside(false)} className="cancel-btn wide mr"> {t('buttons_cancel')} </CustomButton>
                    <CustomButton onClick={handleSubmit} className="submit-btn wide"> {t('buttons_accept')} </CustomButton>
                </Grid>
            }
            <Grid item xs={12}>
                <Modal
                    state={discoverModal}
                    persist={true}
                    max={"lg"}
                    radius={true}
                    fullScreen={deviceView === "mobile"}
                    onClose={() => setDiscoverModal(false)}>

                    <DiscoverForm user={user} onBack={() => setDiscoverModal(false)} onClose={closeBothForms} />
                </Modal>
            </Grid>
        </Grid>
    )
}

export default InterestsForm;
