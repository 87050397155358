import services from '../../services/';
import { closeEventInfoModal } from '../eventInfoModal/actions';
import { showSnackbar } from "../snackbar/actions";
import {Event} from "../../services/tracking";

export const setOpenedEvent = (id) => {
    return dispatch => {
        if(id){
            services.events.get(id)
                .then((resp) => {
                    dispatch({
                        type: 'SET_OPENED_EVENT',
                        payload: resp
                    });
                    Event("setOpenedEvent", "click", `/${resp.owner.slug}/${resp.id}`);
                })
                .catch((error) => {
                    if(error.message === 'The event you\'re accessing is private.') {
                        dispatch(showSnackbar({message: error.message, type: "error"}));
                    }
                    dispatch(closeEventInfoModal())
                    dispatch(unsetOpenedEvent())
                })
        }
    }
}

export const unsetOpenedEvent = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_EVENT'})
    }
}
