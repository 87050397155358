import React, {useEffect, useMemo, useState} from 'react';
import {Box} from "@material-ui/core";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {Autoplay, Pagination, Navigation} from 'swiper';
import Lottie from "react-lottie-player";
import Skeleton from "@material-ui/lab/Skeleton";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import services from "../../../services";
import {closeEventInfoModal} from "../../../store/eventInfoModal/actions";
import ExploreFeaturedUserItemComponent from "./ExploreFeaturedUserItemComponent";
import animationData from "../../../assets/animations/warning-yellow.json";
import './_explore_featured_users_component.scss';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const ExploreFeaturedUsersComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isErrorFetching, setErrorFetching] = useState(false);

    useEffect(() => {
        services.exploreLight.get(9).then((res) => {
            setItem(res);
            setLoading(false);
            setErrorFetching(false);
        }).catch(err => {
            setLoading(true);
            setErrorFetching(true);
        });
    }, []);

    const onOpenProfile = (user) => {
        history.push(`/${user.slug}`);
        dispatch(closeEventInfoModal());
    }


    return (
        <Box className="explore-featured-users--main">
            {isErrorFetching && (
                <div className="explore-featured-users--main--handling-err">
                    <Lottie
                        loop={true}
                        animationData={animationData}
                        play
                        style={{width: 200, height: 'auto'}}
                    />
                    <span>ERROR FETCHING</span>
                </div>
            )}
            {loading &&
                <Box className='explore-featured-users--main-skeleton'>
                    {([0, 1, 2, 3].map(item => <Skeleton key={item} width={"100%"} height={224} variant="rect"
                                                         className={(darkTheme ? 'dark' : 'light')}/>))}
                </Box>
            }

            {!loading && (
                <Swiper
                    slidesPerView={deviceView === 'browser' || deviceView === 'tablet' ? 4 : 1}
                    className="mySwiper"
                    loop
                    speed={1500}
                    pagination={{
                        "clickable": true
                    }}
                    navigation={false}
                    spaceBetween={20}
                    autoplay={{
                        "delay": 2000
                    }}>
                    {item.map((item) => (
                    <SwiperSlide key={`explore-user-item-${item.id}`}>
                        <ExploreFeaturedUserItemComponent onClick={onOpenProfile} user={item}/>
                    </SwiperSlide>)
                    )}
                </Swiper>
            )}


        </Box>
    );
};

export default ExploreFeaturedUsersComponent;
