import React, {useEffect, useMemo, useState} from 'react';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {CircularProgress} from "@material-ui/core";
import Lottie from "react-lottie-player";

import './_landing_livenow.scss';
import services from "../../../../../services";
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import LiveNowItem from "../../../MarbellaVice/Landing/LiveNowItem";
import animationData from "../../../../../assets/animations/koala-sleeping.json";
import {getComponentPhrase} from "../../../../../helpers/phrases";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";


const LandingLiveNow = ({darkTheme, userId, onOpenPlayer, onDeleteOpenPlayer, eventsWithPlayerOpen, containerClassname, placeholderEmptyComponent, max}) => {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";
    const [loading, setLoading] = useState(true);
    const [eventsLive, setEventsLive] = useState([]);
    const {t} = useTranslation('landing');
    const lang = useSelector(state => state.settings.general.language);

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 4);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 2);
        let data = {
            start: formatDate(ownStartOfDay(dateObj), dateFormat),
            end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
            timezone: getCurrentTimezone()
        }
        if (max !== undefined && Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        services.landingEventsLive.get(userId, {
            query: data
        }).then((res) => {
            if(mounted) {
                setEventsLive(res);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId]);

    const RenderEvents = useMemo(() =>
            (eventsLive.length === 0 && placeholderEmptyComponent !== undefined ? placeholderEmptyComponent : [
                ...eventsLive.slice(0, 3),
                ...Array.from({length: 1 - eventsLive.length}, () => 'placeholder')
            ].map((item, index) =>
                item.hasOwnProperty('id') ?
                    <div key={`landing-livenow-item-${item.id}`} className={"item--live"} onClick={() => onOpenPlayer(`${item.hasOwnProperty('twitch_channel') ? item.twitch_channel : 'no-twitch-channel'}#+#${item.id.replaceAll('-', '')}`)}>
                        <div className={"play-icon"}>
                            <PlayArrowIcon />
                        </div>

                        <LiveNowItem key={`render-event-live-${item.id}`}
                                     displayActions={false}
                                     item={item}
                                     onPlayerOpen={onOpenPlayer}
                                     isPlayerOpen={eventsWithPlayerOpen && eventsWithPlayerOpen.includes(`${item.hasOwnProperty('twitch_channel') ? item.twitch_channel : 'no-twitch-channel'}#+#${item.id.replaceAll('-', '')}`)}
                        />
                    </div>
                     : <div key={`landing-livenow-placeholder-${index}`} className={`item item-placeholder ${darkTheme ? ' is-dark-theme' : ' is-light-theme'}`}>
                        <div className={"animation"}>
                            <Lottie
                                loop={true}
                                animationData={animationData}
                                play
                                style={{ width: 180 }}
                            />
                        </div>
                        <span>{t("empty_events")}</span>
                        {getComponentPhrase(lang)}
                    </div>
            ))

        , [darkTheme, eventsLive, eventsWithPlayerOpen, placeholderEmptyComponent, max])

    return (
        <div className={containerClassname}>
            {loading ? <CircularProgress thickness={5} className="input-loader" /> : RenderEvents}
        </div>
    );
};

LandingLiveNow.defaultProps = {
    containerClassname: 'landing-live-now-container'
}

export default LandingLiveNow;
