import React, {useState, Fragment, useRef, useEffect} from 'react';
import './user-categories.scss';
import {
    Box,
    Tabs,
    Tab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button, TextField
} from '@material-ui/core';
import Scroller from '../../Scroller/Scroller';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import UserCategoryChip from "./ProfileCategoryChip";
import {
    subscribeUserCategory,
    unsubscribeUserCategory
} from "../../../store/openedProfileCategories/actions";
import {showSnackbar} from "../../../store/snackbar/actions";
import AdvancedButton from "../../Buttons/AdvancedButton/AdvancedButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const UserCategories = (props) => {
    const [isBlockedSub, setIsBlockedSub] = useState(false);
    const [isBlockedUnsub, setIsBlockedUnsub] = useState(false);
    const dispatch = useDispatch();
    const darkTheme = useSelector(state => state.darkTheme);
    const openedProfile = useSelector(state => state.openedProfile);
    const openedProfileCategories = useSelector(state => state.openedProfileCategories);
    const [categories, setCategories] = useState([...openedProfileCategories])
    const deviceView = useSelector(state => state.deviceView)
    const [val, setVal] = useState(0);
    const {t} = useTranslation('extraData');
    const [saveChanges, setSaveChanges] = useState([]);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [confirmationMode, setConfirmationMode] = useState('tabs');
    const [filteredCategories, setFilteredCategories] = useState([...openedProfileCategories])

    useEffect(() => {
        setCategories([...openedProfileCategories]);
        setFilteredCategories([...openedProfileCategories]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedProfile, openedProfileCategories])

    const onHandleChip = (data) => {
        let exists = saveChanges.find(e => e.userId === data.userId && e.categoryId === data.categoryId);
        if (exists !== undefined) {
            let newArr = saveChanges.filter(e => e.categoryId !== data.categoryId);
            setSaveChanges(newArr);
        } else {
            setSaveChanges([...saveChanges, data]);
        }
    };

    const handleSaveChanges = () => {
        let sub = saveChanges.filter(e => e.mode === 'subscribe');
        let unsub = saveChanges.filter(e => e.mode === 'unsubscribe');

        if (!isBlockedSub && sub.length > 0) {
            setIsBlockedSub(true);
            let categoriesArray = sub.map(e => e.categoryId);
            dispatch(
                subscribeUserCategory(openedProfile.id, categoriesArray, true, () => {
                    setIsBlockedSub(false);
                    setSaveChanges([]);
                }, () => {
                    dispatch(showSnackbar({message: "Error! something went wrong.", type: "error"}));
                    setIsBlockedSub(false);
                })
            );
        }

        if (!isBlockedUnsub && unsub.length > 0) {
            setIsBlockedUnsub(true);
            let categoriesArray = unsub.map(e => e.categoryId);
            dispatch(
                unsubscribeUserCategory(openedProfile.id, categoriesArray, true, () => {
                    setIsBlockedUnsub(false);
                    setSaveChanges([]);
                }, () => {
                    dispatch(showSnackbar({message: "Error! something went wrong.", type: "error"}));
                    setIsBlockedUnsub(false);
                })
            );

        }
    }

    const handleChange = (event, newValue) => {
        if (newValue === 0) { // subscribe all cats
            setConfirmationMode('tab-all');
        } else if (newValue === 1) { // unsubscribe all cats
            setConfirmationMode('tab-none');
        }
        setOpenConfirmation(true);
    };

    const onSubscribeCategories = () => {
        setIsBlockedSub(true);
        let categoriesArray = categories.map(e => e.id);
        dispatch(
            subscribeUserCategory(openedProfile.id, categoriesArray, true, () => {
                setVal(0);
                setIsBlockedSub(false);
            }, () => {
                dispatch(showSnackbar({message: "Error! something went wrong.", type: "error"}));
                setIsBlockedSub(false);
            })
        );
        setFilteredCategories(categories.map((x) => {
            x.subscribe = true;
            return x;
        }));
    };

    const filterCategories = (e) => {
        if (e.target.value) {
            let filteredCategories = categories.filter((x) => {
                if (x.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                    return x;
                }
            })
            setFilteredCategories(filteredCategories);
        } else {
            setFilteredCategories([...categories]);
        }
    };

    const onUnsubscribeCategories = () => {
        setIsBlockedUnsub(true);
        let categoriesArray = categories.map(e => e.id);
        dispatch(
            unsubscribeUserCategory(openedProfile.id, categoriesArray, true, () => {
                setVal(1);
                setIsBlockedUnsub(false);
            }, () => {
                dispatch(showSnackbar({message: "Error! something went wrong.", type: "error"}));
                setIsBlockedUnsub(false);
            })
        );
        setFilteredCategories(categories.map((x) => {
            x.subscribe = false;
            return x;
        }));
    };

    return (
        <Fragment>
            {openedProfile.follow &&
            <Box bgcolor="grid.main" className="user-interests" display="flex"
                 flexDirection={deviceView === 'mobile' ? 'column' : 'row'}
                 justifyContent={deviceView === 'mobile' ? 'flex-start' : 'center'} alignItems="flex-start">
                <Box className="text-data" display="flex" flexDirection="column" justifyContent="center"
                     alignItems="center">
                    <span className="subtitle"> {t('heading_user_interests')} </span>
                    <Box mb={1}>
                        <TextField placeholder={t("filter_categories")} type="text" fullWidth onChange={filterCategories}/>
                    </Box>
                    <p className="p-text">{t('text_user_interests')}</p>
                    <Tabs value={val} classes={{indicator: 'indicator'}} onChange={handleChange}
                          className="action-menu">
                        <Tab label="All" classes={{selected: 'selected'}} disabled={isBlockedSub || isBlockedUnsub}/>
                        <Tab label="None" classes={{selected: 'selected'}} disabled={isBlockedSub || isBlockedUnsub}/>
                    </Tabs>
                    {saveChanges.length > 0 && !isBlockedSub && !isBlockedUnsub &&
                    <AdvancedButton
                        onClick={() => {
                            setConfirmationMode('individual');
                            setOpenConfirmation(true);
                        }}
                        label={'Save changes'}
                        status={false}
                    />
                    }
                </Box>
                <Box bgcolor="grid.content" className="interests">
                    {(isBlockedSub || isBlockedUnsub) &&
                    <div className={"wrap-loading"}>
                        <CircularProgress thickness={5} className="loader"/>
                    </div>
                    }
                    <Scroller count={filteredCategories.length} itemHeight={20}>
                        {filteredCategories.map((item, index) =>
                            <UserCategoryChip key={index} userId={openedProfile.id} category={item}
                                              handleOnChange={onHandleChip}/>
                        )}
                    </Scroller>
                </Box>
            </Box>
            }
            {!openedProfile.follow &&
            <Box className="user-interests">
                {deviceView === "mobile" && <p>You need to follow user in order to subscribe his categories.</p>}
            </Box>
            }
            <Dialog
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('eventForm:dialog_erase_event_headtitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"
                                       className={"dialog-description " + (darkTheme ? ' is-dark' : ' is-light')}>
                        {t('extraData:dialog_text_save_changes_user_categories')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmation(false)} color="primary">
                        {t('extraData:dialog_text_save_changes_user_categories_cancel_btn')}
                    </Button>
                    <Button onClick={() => {
                        if (confirmationMode === 'tab-all') {
                            onSubscribeCategories();
                        } else if (confirmationMode === 'tab-none') {
                            onUnsubscribeCategories();
                        } else if (confirmationMode === 'individual') {
                            handleSaveChanges();
                        }

                        setOpenConfirmation(false);
                    }} color="primary" autoFocus>
                        {t('extraData:dialog_text_save_changes_user_categories_accept_btn')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default UserCategories;
