import React, { useState } from 'react';
import { formatDate } from "../../../../../../../helpers/dateFormatter";
import {TextField, Box} from '@material-ui/core';
import CustomButton from "../../../../../../CustomButton/CustomButton";
import { parse } from "date-fns";
import { showSnackbar } from "../../../../../../../store/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";

const LandingFeedTimelineEditForm = ({ onSubmit, onClose, passedItem }) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const dispatch = useDispatch();
    const currentDate = new Date();
    const [time, setTime] = useState(
        formatDate(passedItem
            && passedItem.hasOwnProperty('timeline_date')
            ? passedItem.timeline_date
            : currentDate, "yyyy-MM-dd'T'HH:mm"));
    const [body, setBody] = useState(passedItem &&
        passedItem.hasOwnProperty('timeline_title')
        ? passedItem.timeline_title
        : "");

    const handleSubmit = () => {
        try {
            let data = {
                type: 'timeline',
                custom_fields: {
                    timeline_date: new Date(time).toISOString(),
                    timeline_body: body
                }
            }

            if (passedItem && passedItem.hasOwnProperty('id')) {
                data.id = passedItem.id;
            }

            onSubmit(data, () => {
                onClose();
            }, () => {
                alert('error updating record')
                console.log("error!");
            })
        }
        catch (e) {
            dispatch(showSnackbar({ message: e.message, type: "error" }));
        }
    }

    return (
        <div className={"landing-feed-timeline-form"}>
            <div className={"landing-feed-timeline-form-row"}>
                <TextField
                    id="time"
                    label="Fecha y hora"
                    defaultValue={time}
                    className={darkTheme ? "input-dark" : "input-light"}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type={"datetime-local"}
                    onChange={(event) => {
                        setTime(event.target.value);
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    fullWidth
                />
                <TextField
                    id="standard-full-width"
                    label="Mensaje"
                    className={darkTheme ? "input-dark" : "input-light"}
                    placeholder="Escribe aquí un breve texto"
                    defaultValue={body}
                    onChange={(event) => setBody(event.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                <CustomButton className="cancel-btn"
                    onClick={onClose}>Cancel</CustomButton>
                <CustomButton loading={false} className="submit-btn" color="primary"
                    onClick={handleSubmit}>Save</CustomButton>
            </Box>

        </div>
    );
};

export default LandingFeedTimelineEditForm;
