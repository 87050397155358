import React, {useState} from 'react';
import './login-form.scss';
import {Box, TextField} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../CustomButton/CustomButton';

const LoginForm = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const {t} = useTranslation(['validations, frontPage']);

    const onEmailChange = (e) => {
        setEmail(e.target.value)
        !e.target.value ? setEmailError(true) : setEmailError(false)
        props.onChange()
    };

    const onPasswordChange = (e) => {
        setPassword(e.target.value)
        !e.target.value ? setPasswordError(true) : setPasswordError(false)
        props.onChange()
    };

    const handleSubmit = () => {
        if(email.length > 0){
            if(password && password.length > 0){
                props.onSubmit({email: email, password: password})
            } else {
                setPasswordError(true)
            }
        } else {
            setEmailError(true)
        }
    };

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleSubmit()
        }
    };

    return (
        <Box className="login-form" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <TextField
                className={"email"}
                error={emailError}
                type="email"
                label={t('frontPage:forms_email')}
                value={email}
                variant="outlined"
                onChange={onEmailChange}
                onKeyDown={handleKeyDown} />

            <TextField
                className={"password"}
                error={passwordError}
                type="password"
                label={t('frontPage:forms_password')}
                value={password}
                variant="outlined"
                onChange={onPasswordChange}
                onKeyDown={handleKeyDown} />


            {props.error && <span className="error"> {t('frontPage:alerts_login_error-text')} </span>}

            <CustomButton loading={props.loading} disabled={props.loading} className="submit-btn large" color="primary" onClick={handleSubmit}>Login</CustomButton>
        </Box>
    )
}

export default LoginForm;
