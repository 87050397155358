const initialState = {};

const roomsReducer = (state = initialState, action) => {
    let roomId = undefined;
    let messageId = undefined;
    switch(action.type){
        case 'SET_ROOM_MESSAGES':
            let tmp_state = Object.assign({}, state);
            roomId = action.payload.roomId;
            tmp_state[roomId] = [...action.payload.messages];
            return tmp_state;
        case 'ADD_ROOM_MESSAGE':
            let tmp = Object.assign({}, state);
            roomId = action.payload.roomId;
            if(tmp.hasOwnProperty(roomId)){
                tmp[roomId] = [...tmp[roomId],action.payload.message];
            } else {
                tmp[roomId] = [action.payload.message];
            }
            return tmp;
        case 'EDIT_ROOM_MESSAGE':
            roomId = action.payload.roomId;
            messageId = action.payload.messageId;
            let _arr = Object.assign({}, state);
            if(_arr.hasOwnProperty(roomId)){
                let roomMessages = _arr[roomId]
                roomMessages = roomMessages.map(item => {
                    if(item.id === messageId) {
                        return action.payload.message
                    } else {
                        return item
                    }
                });
                _arr[roomId] = [...roomMessages];
            }
            return _arr;
        case 'ERASE_ROOMS':
            return state = initialState;
        default:
            return state;
    }
}

export default roomsReducer;
