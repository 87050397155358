import React, {useEffect, useState} from 'react';
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import services from "../../../../../services";
const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export const EmbedLeaguesComponent = ({userId}) => {
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loadingUpcoming, setLoadingUpcoming] = useState(true);

    const [liveEvents, setLiveEvents] = useState([]);
    const [loadingLiveEvents, setLoadingLiveEvents] = useState(true);

    const [passedEvents, setPassedEvents] = useState([]);
    const [loadingPassedEvents, setLoadingPassedEvents] = useState(true);

    const [tournaments, setTournaments] = useState([]);

    const fetchUpcomingEvents = (user, max, extraQuery) => {
        const dateObj = formatDate(new Date(), dateFormat)
        let data = {
            start: dateObj.substring(0, dateObj.length - 9) + "00:00.000",
            timezone: getCurrentTimezone(),
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.eventsLandingUpcoming.get(user, {
            query: data
        });
    }

    const fetchMatchesEvents = (user, max, is_live, extraQuery) => {
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 2);
        let data = {
            start: formatDate(ownStartOfDay(dateObj), dateFormat),
            end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
            timezone: getCurrentTimezone(),
            isLive: is_live
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.landingEventsLiveScores.get(userId, {
            query: data
        });
    }

    useEffect(() => {
        let mounted = true;
        setLoadingLiveEvents(true);
        setLoadingUpcoming(true);
        setLoadingPassedEvents(true);
        let extraQuery = {};

        fetchUpcomingEvents(userId, 12, extraQuery).then((res) => {
            if (mounted) {
                setUpcomingEvents(res);
                setLoadingUpcoming(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        fetchMatchesEvents(userId, 12, true, extraQuery).then((res) => {
            if (mounted) {
                setLiveEvents(res);
                setLoadingLiveEvents(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        fetchMatchesEvents(userId, 12, false, extraQuery).then((res) => {
            if (mounted) {
                setPassedEvents(res);
                setLoadingPassedEvents(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId]);

    useEffect(() => {
        if (!loadingUpcoming && !loadingLiveEvents && !loadingPassedEvents) {
            let combine = [...upcomingEvents, ...liveEvents, ...passedEvents];
            let grouped = combine.reduce(
                (result, item) => ({
                    ...result,
                    [item['tournament']]: [
                        ...(result[item['tournament']] || []),
                        item,
                    ],
                }),
                {},
            );
            setTournaments(grouped);
        }
    }, [upcomingEvents, liveEvents, passedEvents, loadingUpcoming, loadingLiveEvents, loadingPassedEvents]);

  return (
    <div className="embed-leagues-component">
        {Object.keys(tournaments).map((group, index) => {
            let tournament_image = tournaments[group][0].original_poster;

            return (
                <div className="embed-league-item" key={index}>
                    <img alt={group} src={tournament_image} />
                    <div>{group.replace("Champions Tour", "")}</div>
                </div>
            )
        })}
    </div>
  );
};
