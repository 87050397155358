import client from './client';

const services = {
    users: client.service('/users'),
    events: client.service('/events'),
    calendar: client.service('/calendar'),
    calendarCount: client.service('/calendar-count'),
    authManagement: client.service('/authManagement'),
    interests: client.service('/interests'),
    categories: client.service('/categories'),
    groups: client.service('/groups'),
    search: client.service('/search'),
    recommendations: client.service('/user-recommendation'),
    follow: client.service('/user-follow'),
    messages: client.service('/event-chat'),
    notifications: client.service('/notifications'),
    ping: client.service('/event-ping'),
    subscribe: client.service('/event-sub'),
    discover: client.service('/discover-user'),
    guests: client.service('/event-guest'),
    userCategories: client.service('/user-categories'),
    userSubCategory: client.service('user-unsub-category'),
    explore: client.service('/explore'),
    messageLike: client.service('/user-like-event-chat'),
    cities: client.service('/cities'),
    places: client.service('/places'),
    contact: client.service('/contact-us'),
    trustedList: client.service('/user-trusted-list'),
    subscriptions: client.service('/subscription'),
    userReminderSettings: client.service('/user-reminder-settings'),
    apiKeys: client.service('/api-keys'),
    twitchEventSub: client.service('/twitch-eventsub'),
    landingChat: client.service('/landing-chat'),
    rooms: client.service('/rooms'),
    userLandingRoles: client.service('/user-landing-roles'),
    landingFeed: client.service('/landing-feed'),
    userLandingFeedLiked: client.service('/user-like-landing-feed-post'),
    landingFeedback: client.service('/landing-feedback'),
    exploreLight: client.service('/explore-light'),
    userFollowing: client.service('/user-following'),
    userFollowers: client.service('/user-followers'),
    landingEventsLive: client.service('/events-landing-live'),
    landingEventsLiveScores: client.service('/events-livescores'),
    landingAgendaToday: client.service('/landing-agenda-today'),
    eventsLandingUpcoming: client.service('/events-landing-upcoming'),
    eventCategories: client.service('/event-categories'),
    eventPings: client.service('/event-ping'),
    customUserRecommendation: client.service('/custom-user-recommendation'),
    topCategoriesByUser: client.service('/top-categories-by-user'),
    eventsLandingCrypto: client.service('/events-landing-crypto'),
    exploreFeaturedEvents: client.service('/explore-featured-events'),
    exploreFeaturedCategories: client.service('/explore-featured-categories'),
    userAchievementList: client.service('/user-achievement-list'),
    userAchievementRecord: client.service('/user-achievement-record'),
    userListCategories: client.service('/user-list-category'),
}

export default services;

