import React from 'react';
import './notification-skeleton.scss'
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {useSelector} from 'react-redux';

const NotificationSkeleton = (props) => {
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <Box bgcolor="background.default" className="notification-skeleton" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Box className={"circle " + (darkTheme ? 'dark' : 'light')}></Box>
            <Box ml={1} minWidth="75%" maxWidth="75%">
                <Skeleton animation="wave" variant="text" width="100%" className={(darkTheme ? 'dark' : 'light')} />
                <Skeleton animation="wave" variant="text" width="20%" className={(darkTheme ? 'dark' : 'light')} />
            </Box>
        </Box>
    )
}

export default NotificationSkeleton;