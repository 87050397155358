import React, {useEffect, useMemo, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import './_landing_livescores.scss';
import services from "../../../../../services";
import {
    formatDate,
    getCurrentTimezone,
    getDateWithoutTime,
    ownEndOfDay,
    ownStartOfDay
} from "../../../../../helpers/dateFormatter";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setOpenedEvent} from "../../../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../../../store/eventInfoModal/actions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import EventUpcomingCard from "../../../../Event/EventUpcomingCard/EventUpcomingCard";
import {Event} from "../../../../../services/tracking";


const LandingUpcomingEvents = ({darkTheme, userId, onOpenPlayer, max, onClickMore, extraFilter}) => {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [totalResults, setTotalResults] = useState(false);
    const {t} = useTranslation('landing');
    const dispatch = useDispatch();
    const nowDate = getDateWithoutTime(new Date());

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const dateObj = formatDate(new Date(), dateFormat)
        let data = {
            start: dateObj.substring(0, dateObj.length - 9) + "00:00.000",
            timezone: getCurrentTimezone(),
        }
        if (max !== undefined && Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        if(extraFilter) {
            data = {...data, ...extraFilter};
        }
        services.eventsLandingUpcoming.get(userId, {
            query: data
        }).then((res) => {
            if (mounted) {
                setTotalResults(0);
                setEvents(res);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId]);

    const OpenInfo = (event) => {
        window.history.pushState({backRef: window.location.pathname}, "my calendar", `/${event.owner.slug}/${event.id}`)
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("LandingUpcomingEvents", "OpenInfo", event.id);
    };

    const PlaceholderEmptyComponent = () =>
        <div
            className={`event-score-card animated fadeIn not-events ${darkTheme ? '  is-dark-theme ' : ' is-light-theme'}`}>
            <span>{t("no_events")}</span>
        </div>;

    const RenderEvents = useMemo(() =>
            (events.length === 0 ? <PlaceholderEmptyComponent/> :
                events.slice(0, max).map((item) =>
                    item.hasOwnProperty('id') ?
                        <div key={item.id} className={"item--live-score"}>
                            <EventUpcomingCard event={item} onOpenEventModal={OpenInfo} nowDate={nowDate}/>
                        </div>
                        : <PlaceholderEmptyComponent/>
                ))
        , [darkTheme, events]);

    return (
        <div className="landing-upcoming-events-container">
            {loading ? <CircularProgress thickness={5} className="input-loader"/> : RenderEvents}
            {(!loading && totalResults > max) && (
                <div onClick={onClickMore} className="landing-live-scores-load-more animated infinite slower">
                    {t("see_more_matches")} <ArrowRightAltIcon fontSize="small"/>
                </div>
            )}
        </div>
    );
};

export default LandingUpcomingEvents;
