import React, {useEffect, useState} from "react";
import Calendar from "../../../../../Calendar/Calendar";
import {updateCalendar} from "../../../../../../store/app/actions";
import {unsetSelectedGroup} from "../../../../../../store/selectedGroup/actions";
import {unsetMonthlyEventsCount} from "../../../../../../store/monthlyEventsCount/actions";
import {useDispatch, useSelector} from "react-redux";

const LandingHeaderCalendarComponent = ({userID, handleMoveTab, userSlug}) => {
    const dispatch = useDispatch()
    const deviceView = useSelector(state => state.deviceView)

    useEffect(() => {
        if (deviceView !== null) {
            dispatch(updateCalendar(new Date(), userID))
        }
        return () => {
            dispatch(unsetSelectedGroup())
            dispatch(unsetMonthlyEventsCount())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceView])

    return (
        <div className={`landing-header-calendar ${userSlug === "valorant.oficial" ? " is-valorant-espana" : ""}`}>
            <Calendar onClickDay={() => handleMoveTab('agenda')} ownerId={userID}/>
        </div>
    );
};

export default LandingHeaderCalendarComponent;
