import {Checkbox} from "@material-ui/core";
import {IndeterminateCheckBox} from "@material-ui/icons";
import React from "react";

const FeedbackCheckboxInput = ({label, value, answer, onChange}) => {
    return <div>
        <label>{label}</label>
        <div className='checkbox-wrapper'>
            <Checkbox checked={value} name={answer}
                      icon={<IndeterminateCheckBox color={"error"}/>}
                      onChange={(event, newValue) => {
                          onChange(answer, newValue)
                      }}/>
            {value && <span>Sí</span>}
            {!value && <span>No</span>}
        </div>
    </div>
};

export default FeedbackCheckboxInput;
