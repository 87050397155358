import React, {useEffect, useState} from 'react';
import {formatDate} from "../../../../helpers/dateFormatter";
import {isToday, parseJSON} from "date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
const HtmlToReactParser = require('html-to-react').Parser

const htmlToReactParser = new HtmlToReactParser()
const FeedNewItem = ({itemId, title, date, allowRemove, onClickDeleteButton}) => {
    const [time, setTime] = useState("-");
    const reactElement = htmlToReactParser.parse(title)


    useEffect(() => {
        try {
            let convertToDate = parseJSON(date);
            setTime(formatDate(convertToDate, isToday(convertToDate) ? 'HH:mm' : 'dd/MM'))
        } catch (err) {

        }
    }, [itemId, date, title])


    return (
            <div className={"landing-feed-new-item"}>
                <div className={"date-time"}>{time}</div>
                <div className={"feed-title"}>{reactElement}</div>

                {allowRemove && <>
                    <div className={"break"}/>
                <div className={"delete-btn"} onClick={() => onClickDeleteButton(itemId)}><DeleteIcon className="icon"/>
                    <span>Borrar</span></div></>}

            </div>
    );
};

export default FeedNewItem;
