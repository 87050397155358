import React, {useEffect, useState} from "react";
import services from "../../../../../../../services";
import CircleAdd from "../../../../../../../assets/icons/actions/add_event_circle.svg";
import FeedNewItem from "./FeedNewItem";
import Modal from "../../../../../../Modal/Modal";
import {useTranslation} from "react-i18next";
import InfiniteList from "react-infinite-scroll-list";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    editLandingFeedTimeline,
    removeLandingFeedTimeline
} from "../../../../../../../store/landingFeedTimeline/actions";
import {useDispatch, useSelector} from "react-redux";
import LandingFeedTimelineEditForm from "./LandingFeedTimelineEditForm";


const LandingFeedTimeline = ({userId, allowedManage, handleSubmit}) => {
    const {t} = useTranslation('extraData');
    const deviceView = useSelector(state => state.deviceView);
    const [openForm, setOpenForm] = useState(false);
    const originalFeedNews = useSelector(state => state.landingFeedTimeline.hasOwnProperty(userId) ? state.landingFeedTimeline[userId] : []);
    const [feedNews, setFeedNews] = useState([...originalFeedNews]);
    const [loadingList, setLoadingList] = useState(false);
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(10);
    const darkTheme = useSelector(state => state.darkTheme);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [editItem, setEditItem] = useState(undefined);


    useEffect(() => {
        if (originalFeedNews.hasOwnProperty(userId)) {
            setTotal(originalFeedNews[userId].total)
            setFeedNews([...originalFeedNews[userId].data]);
        }
        setLoading(false)
    }, [originalFeedNews]);

    useEffect(() => {
        if (!originalFeedNews.hasOwnProperty(userId)) {
            loadData();
        }
    }, [userId]);

    const openWriterModal = () => {
        if (allowedManage) {
            setOpenForm(true);
        }
    }

    const handleRemoveFeedTimeline = (data) => {
        if (data.roomId === userId && data.type === "timeline") {
            let filtered = originalFeedNews[userId].data.filter(i => i.id !== data.id)
            let originalLength = originalFeedNews[userId].data.length;
            setTotal(total - (originalLength - filtered.length))
            setFeedNews([...filtered]);
            dispatch(removeLandingFeedTimeline(userId, data.id))
        }
    }

    const handleDeleteTimelineItem = (itemId) => {
        services.landingFeed.remove(itemId).then((res) => {
            handleRemoveFeedTimeline(res);
        });
    }

    const handleEditTimelineItem = (data) => {
        services.landingFeed.patch(data.id, {...data, roomId: userId}).then((res) => {
            setOpenForm(false);
            setEditItem(undefined);
            loadData();
            dispatch(editLandingFeedTimeline(userId, res.id, res));
        });
    }

    const onSubmitNewForm = (data, onSuccess, onError) => {
        handleSubmit(data, () => {
            onSuccess();
        }, onError)
    };

    const onSubmitForm = (data, onSuccess, onError) => {
        if (!data.hasOwnProperty('id')) {
            onSubmitNewForm(data, () => {
                loadData();
                onSuccess();
            }, onError);
        } else {
            handleEditTimelineItem(data);
        }
    };

    const fetchData = () => {
        if (!loadingList) {
            setLoadingList(true);
            services.landingFeed.find({
                query: {
                    type: 'timeline',
                    roomId: userId,
                    $skip: skip,
                    $limit: 5,
                    $sort: {
                        'timeline': -1
                    }
                }
            })
                .then((resp) => {
                    if (resp.data.length > 0) {
                        setFeedNews(prev => [...prev, ...resp.data]);
                        setSkip(skip + 5);
                    }
                    setLoadingList(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    };

    const loadData = () => {
        if (!loadingList) {
            setLoadingList(true);
            services.landingFeed.find({
                query: {
                    type: 'timeline',
                    roomId: userId,
                    $sort: {
                        'timeline': -1
                    },
                    $limit: 10
                }
            })
                .then((resp) => {
                    if (resp.data.length > 0) {
                        setFeedNews(resp.data);
                        setSkip(10);
                        setTotal(resp.total);
                    }
                    setLoadingList(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    const closeForm = () => {
        setEditItem(undefined);
        setOpenForm(false);
    };

    return (
        <>
            <div className={"feed-heading"}>
                Timeline
                {allowedManage && <img onClick={openWriterModal} src={CircleAdd} alt="add new timeline item"/>}
            </div>
            <div className={`landing-feed-news ${feedNews.length === 0 ? ' is-empty' : ''}`}>
                {loading ? (
                        <div className={"div-loading"}>
                            <CircularProgress size={32} thickness={5} className="input-loader"/>
                        </div>) :
                    <InfiniteList
                        isLoading={loadingList}
                        root="container"
                        isEndReached={feedNews.length === total}
                        onReachThreshold={fetchData}
                        containerClassName={`scroller container-feed-article ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}
                        sentinelClassName="sentinel-feed-timeline"
                        containerTagName="div"
                        sentinelTagName="div"
                        threshold={50}>
                        {feedNews.length === 0 && (
                            <div className={"empty"}>{t('extraData:landing_empty_content')}</div>
                        )}
                        {feedNews.map((item) =>
                            <FeedNewItem key={`landing-feed-timeline-${item.id}`}
                                         itemId={item.id}
                                         date={item.timeline_date}
                                         title={item.timeline_title}
                                         allowRemove={allowedManage}
                                         onClickEditButton={() => {
                                             setEditItem(item);
                                             setOpenForm(true);
                                         }}
                                         onClickDeleteButton={handleDeleteTimelineItem}/>
                        )}
                    </InfiniteList>
                }
            </div>
            <Modal
                state={openForm}
                max={"md"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                onClose={closeForm}>
                <LandingFeedTimelineEditForm onSubmit={onSubmitForm} onClose={closeForm}
                                             passedItem={editItem}/>
            </Modal>
        </>
    );

};

export default LandingFeedTimeline;
