import services from '../../services';

export const setUserCategories = (id) => {
    return dispatch => {
        services.userCategories.get(id)
            .then((resp) => {
                let data = resp.map(item => item.name)
                dispatch({
                    type: 'SET_CATEGORIES',
                    payload: data
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetUserCategories = () => {
    return dispatch => {
        dispatch({type: 'UNSET_CATEGORIES'})
    }
}
