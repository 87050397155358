import React, {useEffect, useMemo, useState} from 'react';
import {TwitchClip} from "react-twitch-embed";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import "./_landing_preview_feed_clip.scss"
import LikeBtn from '../../../../../../../assets/icons/actions/Heart.svg';
import LikeBtnFilled from '../../../../../../../assets/icons/actions/HeartFill.svg';
import {useDispatch, useSelector} from "react-redux";
import services from "../../../../../../../services";
import {CircularProgress} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import abbreviate from "number-abbreviate";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import {showSnackbar} from "../../../../../../../store/snackbar/actions";
import {useTranslation} from "react-i18next";
import {Event} from "../../../../../../../services/tracking";

const componentName = "LandingPreviewFeedClip";

const HandleRenderClip = (item) => {
    if (item.hasOwnProperty('twitch')) {
        return (
            <div className="item item-thumbnail"><TwitchClip
                autoplay={true}
                height={"240"}
                width={"100%"}
                clip={item.twitch}
                parent={['latencyapp.com']}/></div>);
    } else if (item.hasOwnProperty('youtube')) {
        return (
            <div className="item item-thumbnail">
                <iframe
                    width="100%"
                    height="240px"
                    src={`https://www.youtube.com/embed/${item.youtube}?autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"/>
            </div>
        )
    } else {
        return (
            <>invalid clip</>
        )
    }
};

const HandleRenderPreviewClip = (props) => {
        const {item} = props;

        if (item.hasOwnProperty('twitch')) {
            return <div className="item item-thumbnail" onClick={props.onClick}>
                <div className={"play-icon"}>
                    <PlayArrowIcon/>
                </div>
                <img src={item.thumbnails?.medium}/>
            </div>;
        } else if (item.hasOwnProperty('youtube')) {
            return <div className="item item-thumbnail" onClick={props.onClick}>
                <div className={"play-icon"}>
                    <PlayArrowIcon/>
                </div>
                <img src={`https://img.youtube.com/vi/${item.youtube}/mqdefault.jpg`}/>
            </div>;
        } else {
            return (
                <>invalid clip</>
            )
        }
    };

const LandingPreviewFeedClip = ({item, allowRemove, onClickDeleteButton, playVideo, showClipInfo}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('extraData');
    const [isPlay, setIsPlay] = useState(false);
    const [userLiked, setUserLiked] = useState(false);
    const [loadingLike, setLoadingLike] = useState(false);
    const [likeCount, setLikeCount] = useState(0)
    const currentUser = useSelector(state => state.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handlePlayHighlight = () => {
        setIsPlay(true)
        Event(componentName, "PlayHighlight", item.id);
    };

    const memoizedResult = useMemo(() => {
        if (isPlay) {
            return <HandleRenderClip {...item} />
        } else {
            return <HandleRenderPreviewClip item={item} onClick={handlePlayHighlight}/>;
        }
    }, [isPlay, item]);

    useEffect(() => {
        if (currentUser && currentUser.hasOwnProperty('id') && showClipInfo) {
            setLoadingLike(true);
            services.userLandingFeedLiked.find({
                query: {
                    landingFeedId: item.id,
                    $limit: 0
                }
            }).then((res) => {
                if (res.total > 0) {
                    setUserLiked(true);
                } else {
                    setUserLiked(false);
                }
                setLoadingLike(false);
            }).catch((err) => {
                setUserLiked(false);
                setLoadingLike(false);
            });
        } else {
            setUserLiked(false);
            setLoadingLike(false);
        }
    }, [item, currentUser]);

    useEffect(() => {
        setIsPlay(playVideo);
    }, [playVideo]);

    useEffect(() => {
        setLikeCount(item.hasOwnProperty('likes') && typeof (item.likes) === 'number' && item.likes > 0 ? item.likes : 0);
    }, [item])

    const handleOnLikeClip = () => {
        if (currentUser && currentUser.hasOwnProperty('id')) {
            if (!loadingLike) {
                services.userLandingFeedLiked.create({
                    landingFeedId: item.id
                }).then((res) => {
                    if (res.hasOwnProperty('deleted') && res.deleted) {
                        setLikeCount(likeCount - 1);
                        setUserLiked(false);
                        Event(componentName, "UnlikeHighlight", item.id);
                    } else {
                        setLikeCount(likeCount + 1);
                        setUserLiked(true);
                        Event(componentName, "LikeHighlight", item.id);
                    }
                }).catch((err) => {
                    setUserLiked(false);
                });
            }
        } else {
            dispatch(showSnackbar({message: t("extraData:must_be_registered"), type: "error"}))
        }
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"feed-clip-item"}>
            <div className={"feed-clip-item-content"}>
                {memoizedResult}
                {showClipInfo && (
                    <div className="feed-clip-item-content-info">
                        <div className="feed-clip-item-content-info--content">
                            {item.hasOwnProperty('clip_title') &&
                            <div className="feed-clip-item-content-info--title">
                                {item.clip_title}
                            </div>
                            }
                            {item.hasOwnProperty('broadcaster') &&
                            (<div className="feed-clip-item-content-info--channel">
                                {item.hasOwnProperty('broadcaster') && (
                                    <img className="avatar" src={item.broadcaster.logo}
                                         alt={item.broadcaster.display_name}/>
                                )}
                                <div>{item.broadcaster.display_name}</div>
                            </div>)}
                        </div>
                        <div className="feed-clip-item-content-actions">
                            <div className={`feed-clip-item-action--like ${userLiked ? ' is-liked' : ''}`}
                                 onClick={handleOnLikeClip}>
                                {loadingLike && (
                                    <>
                                        <img src={userLiked ? LikeBtnFilled : LikeBtn}/>
                                        <CircularProgress size={12}/>
                                    </>
                                )}
                                {!loadingLike && (
                                    <>
                                        <img src={userLiked ? LikeBtnFilled : LikeBtn}/>
                                        <div className="count-likes">{abbreviate(likeCount, 1)}</div>
                                    </>
                                )}
                            </div>
                            {allowRemove &&
                            <div className={`feed-clip-item-action--more`} onClick={handleClickMenu}>
                                <MoreVertIcon className="icon"/>
                            </div>
                            }
                        </div>
                    </div>
                )}
            </div>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={() => {
                    onClickDeleteButton(item.id);
                    handleCloseMenu();
                }}>
                    <ListItemIcon>
                        <DeleteIcon color="secondary" fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        Borrar
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};

LandingPreviewFeedClip.defaultProps = {
    defaultPreview: true,
    showClipInfo: false
}
export default React.memo(LandingPreviewFeedClip, (prevProps, nextProps) => {
    return prevProps.allowRemove === nextProps.allowRemove && prevProps.item.id === nextProps.item.id;
})
//export default LandingPreviewFeedClip;
