import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import services from "../../../../../services";
import {ValorantUpcomingEvent} from "./ValorantUpcomingEvent";
import './_embed-next-events.scss';
import {useSelector} from "react-redux";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export const EmbedNextEvents = ({ userId, filterByTournament }) => {
    const { t } = useTranslation('landing');
    const deviceView = useSelector(state => state.deviceView);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loadingUpcoming, setLoadingUpcoming] = useState(true);

    const [liveEvents, setLiveEvents] = useState([]);
    const [loadingLiveEvents, setLoadingLiveEvents] = useState(true);

    const fetchUpcomingEvents = (user, max, extraQuery) => {
        const dateObj = formatDate(new Date(), dateFormat)
        let data = {
            start: dateObj.substring(0, dateObj.length - 9) + "00:00.000",
            timezone: getCurrentTimezone(),
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.eventsLandingUpcoming.get(user, {
            query: data
        });
    }

    const fetchMatchesEvents = (user, max, is_live, extraQuery) => {
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 2);
        let data = {
            start: formatDate(ownStartOfDay(dateObj), dateFormat),
            end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
            timezone: getCurrentTimezone(),
            isLive: is_live
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.landingEventsLiveScores.get(userId, {
            query: data
        });
    }

    useEffect(() => {
        let mounted = true;
        setLoadingUpcoming(true);
        setLoadingLiveEvents(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchUpcomingEvents(userId, 12, extraQuery).then((res) => {
            if (mounted) {
                setUpcomingEvents(res);
                setLoadingUpcoming(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        fetchMatchesEvents(userId, 12, true, extraQuery).then((res) => {
            if (mounted) {
                setLiveEvents(res);
                setLoadingLiveEvents(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);

    return (
        <div className={`embed-next-events-component is-${deviceView}`}>
            {upcomingEvents.map(item => <ValorantUpcomingEvent key={item.id} {...item} />)}
        </div>
    );
}
