export const openDiscoverUsersModal = () => {
    return dispatch => {
        dispatch({type: 'OPEN_DISCOVER_USERS_MODAL'})
    }
}

export const closeDiscoverUsersModal = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_DISCOVER_USERS_MODAL'})
    }
}
