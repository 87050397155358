import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import services from "../../../../../services";
import {isToday} from "date-fns";
import {formatDate} from "../../../../../helpers/dateFormatter";
import TablePagination from "@material-ui/core/TablePagination";
import PassedItem from "./PassedItem";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {Box} from "@material-ui/core";

const LandingEventsPassedPipeByDay = ({day, data, query, addDayHandler, subtractDayHandler}) => {
    const {t} = useTranslation(['extraData']);
    const firstUpdate = useRef(true);
    const [events, setEvents] = useState(data.data);
    const [total, setTotal] = useState(data.total.length);
    const [skip, setSkip] = useState(query.$skip);
    const [currentPage, setCurrentPage] = useState(query.$skip / data.total.length);
    const [rowsPerPage, setRowsPerPage] = useState(query.$limit);
    let tom = new Date(day);
    tom.setDate(tom.getDate() + 1)
    const [tomorrow, setTomorrow] = useState(tom > new Date());

    const handleChangePage = (e, newPage) => {
        setCurrentPage(newPage);
        setSkip(rowsPerPage * newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setSkip(0);
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        services.events.find({
            query: {
                ...query,
                $limit: rowsPerPage,
                $skip: skip
            }
        }).then((res) => {
            setEvents(res.data);
        });

    }, [query, rowsPerPage, skip]);

    useEffect(() => {
        if (data && query) {
            setEvents([]);
            setEvents(data.data);
            setSkip(query.$skip);
            setTotal(data.total.length);
            setCurrentPage(query.$skip / data.total.length);
            setRowsPerPage(query.$limit);
            tom = new Date(day);
            tom.setDate(tom.getDate() + 1);
            setTomorrow(tom > new Date());
        }
    }, [data, query]);

    return (<>
            <div className={"landing-container-vods-title"}>
                <Box display="flex" flexDirection="row" className="landing-container-vods-box">
                    <IconButton onClick={subtractDayHandler}> <ArrowBackIos fontSize="small"/></IconButton>
                    <div className={"landing-container-vods-title-wrapper"}>
                        {!isToday(day) ? (
                            <>
                                <div className={"date-title"}>{formatDate(day, "iii d")}</div>
                                <div className={"month-subtitle"}>{formatDate(day, "LLLL")}</div>
                            </>
                        ) : (
                            <div className={"date-title"}>{t('today')}</div>
                        )}
                    </div>
                    <IconButton onClick={addDayHandler}
                                disabled={tomorrow}> <ArrowForwardIos fontSize="small"/>
                    </IconButton>
                </Box>

                <div className={"landing-container-vods-pagination-wrapper"}>
                    {(events && events.length > 0) &&
                    <TablePagination
                        labelRowsPerPage={t('extraData:table_pagination_labelRowsPerPage')}
                        labelDisplayedRows={({
                                                 from,
                                                 to,
                                                 count
                                             }) => t('extraData:table_pagination_labelDisplayedRows', {
                            from: from,
                            to: (to ? to : 0),
                            count: count
                        })}
                        rowsPerPageOptions={[8, 12, 16, 20]}
                        component="div"
                        count={total}
                        page={currentPage}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    }
                </div>
            </div>
            <div className={"landing-passed-vods-grid"}>
                {events.map((item) => <PassedItem key={item.id} item={item}/>)}
            </div>
        </>
    );
};

export default LandingEventsPassedPipeByDay;
