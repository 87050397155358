import React, {useState} from 'react';
import {Box, TextField, withStyles} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Recaptcha from 'react-recaptcha';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './register-form.scss';
import CustomButton from '../../CustomButton/CustomButton';
import Checkbox from '@material-ui/core/Checkbox';
import {Link} from "react-router-dom";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import services from "../../../services";

const GreenCheckbox = withStyles({
    root: {
        color: '#03CDBF',
        '&$checked': {
            color: '#03CDBF',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
    root: {
        color: '#D75454',
        '&$checked': {
            color: '#D75454',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const RegisterForm = (props) => {
    const [nickName, setNick] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [nickError, setNickError] = useState(false);
    const [captchaToken, setCaptchaToken] = useState("");
    const [captchaError, setCaptchaError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const {t} = useTranslation(['validations, frontPage']);
    const [checkTOS, setCheckTOS] = useState(false);
    const [checkTOSError, setCheckTOSError] = useState(false);
    const [nicknameAlreadyTaken, setNicknameAlreadyTaken] = useState(false);

    const onNickChange = (e) => {
        setNick(e.target.value)
        !e.target.value ? setNickError(true) : setNickError(false)
    }

    const onLoseFocusNick = () => {
        services.users.find({
            query: {
                nickname: nickName
            }
        }).then((res) => {
            if(res.total > 0){
                setNickError(true);
                setNicknameAlreadyTaken(true);
            } else {
                setNickError(false);
                setNicknameAlreadyTaken(false);
            }
        });
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value)
        !e.target.value ? setEmailError(true) : setEmailError(false)
    }
    const onPasswordChange = (e) => {
        setPassword(e.target.value)
        !e.target.value ? setPasswordError(true) : setPasswordError(false)
    }

    const handleSubmit = () => {
        if(!nicknameAlreadyTaken) {
            if(checkTOS) {
                if(captchaToken){
                    if(nickName){
                        if(email){
                            if(password){
                                props.onSubmit(
                                    {
                                        nickname: nickName,
                                        email: email,
                                        password: password,
                                        captcha: captchaToken
                                    });
                            } else {
                                setPasswordError(true);
                            }
                        } else {
                            setEmailError(true);
                        }
                    } else {
                        setNickError(true);
                    }
                }else{
                    setCaptchaError(true);
                }
            }else{
                setCheckTOSError(true);
            }
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleSubmit()
        }
    }

    const verifyCallback = (response) => {
        setCaptchaToken(response);
    }


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box className="register-form" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <TextField
                className={"nick"}
                error={nickError}
                type="email"
                label={t('frontPage:forms_nick')}
                value={nickName}
                variant="outlined"
                onChange={onNickChange}
                onBlur={onLoseFocusNick}
                onKeyDown={handleKeyDown} />
            {nicknameAlreadyTaken && <span className="error-line">{t('frontPage:alerts_nickname_taken')}</span>}

            <TextField
                className={"email"}
                error={emailError}
                type="email"
                label={t('frontPage:forms_email')}
                value={email}
                variant="outlined"
                onChange={onEmailChange}
                onKeyDown={handleKeyDown} />

            <TextField
                className={"password"}
                error={passwordError}
                type={showPassword ? 'text' : 'password'}
                label={t('frontPage:forms_password')}
                value={password}
                variant="outlined"
                onChange={onPasswordChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                              {showPassword ? <Visibility style={{ color: '#00D1BF' }} /> : <VisibilityOff style={{ color: '#00D1BF' }} />}
                          </IconButton>
                      </InputAdornment>
                    )
                }}

            />

            <div className={`wrap-tos ${checkTOSError ? ' is-error' : ''}`}>
                {!checkTOSError ? (
                    <GreenCheckbox
                        checked={checkTOS}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        onChange={() => setCheckTOS(!checkTOS)}
                        name="check-TOS"
                        color="primary"
                    />
                ) : (
                    <RedCheckbox
                    checked={checkTOS}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => setCheckTOS(!checkTOS)}
                    name="check-TOS"
                    color="primary"
                    />
                    )}
                <span className="tos-text">
                    <Trans i18nKey="frontPage:forms_labelCheckTOS">
                    I have read and accept the <Link to="/privacy-policy">privacy policy</Link> and <Link to="/legal-notice">legal notice</Link>.
                    </Trans>
                    {checkTOSError && <span className="error-div"> {t('validations:validations_REQUIRED')} </span>}
                </span>
            </div>

            <Recaptcha
                sitekey="6Lf1sMUUAAAAAFGMpHYFlUUv8FCMAj5RFjDhYJCT"
                verifyCallback={verifyCallback}
            />

            {captchaError && <span className="error"> {t('validations:validations_REQUIRED')} </span>}
            {props.error && <span className="error"> Email already exists </span>}

            <CustomButton loading={props.loading} disabled={props.loading} className="submit-btn large" onClick={handleSubmit}>Sign up</CustomButton>

        </Box>
    )
}

export default RegisterForm;
