import React, {Fragment, useState} from "react";
import './edit-profile-form.scss';
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    Box,
    InputLabel
} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CustomButton from "../../CustomButton/CustomButton";
import facebookIcon from '../../../assets/icons/actions/fb.svg';
import linkedinIcon from '../../../assets/icons/actions/in.svg';
import instagramIcon from '../../../assets/icons/actions/insta.svg';
import twitterIcon from '../../../assets/icons/actions/twitter.svg';
import twitchIcon from '../../../assets/icons/actions/twitch.svg';
import youtubeIcon from '../../../assets/icons/actions/youtube.svg';
import BirthdayInput from "../../Inputs/BirthdayInput";
import { format } from 'date-fns'
import services from "../../../services";
import CustomSocialNetworkField from "./CustomSocialNetworkField";

const EditProfileForm = (props) => {
    const {t} = useTranslation(['userEditForm', 'frontPage', 'extraData']);
    const { user } = props;
    const [nickname, setNick] = useState(user.nickname ? user.nickname : "");
    const [email] = useState(user.email ? user.email : "");
    const [firstName, setFirstname] = useState(user.firstName ? user.firstName : "");
    const [lastName, setLastname] = useState(user.lastName ? user.lastName : "");
    const [biography, setBiography] = useState(user.biography ?  user.biography : "");
    const [website, setWebsite] = useState(user.website ? user.website : "");
    const [location, setLocation] = useState(user.location ?  user.location : "");
    const [gender, setGender] = useState(user.gender ?  user.gender : "male");
    const [birthday, setBirthday] = useState(user.birthday ? format(new Date(user.birthday), "dd/MM/yyyy") : "");
    const [facebook, setFacebook] = useState(user.facebook_reference ? user.facebook_reference : "");
    const [twitch, setTwitch] = useState(user.twitch_reference ? user.twitch_reference : "");
    const [twitter, setTwitter] = useState(user.twitter_reference ? user.twitter_reference : "");
    const [instagram, setInstagram] = useState(user.instagram_reference ? user.instagram_reference : "");
    const [linkedin, setLinkedin] = useState(user.linkedin_reference ? user.linkedin_reference : "");
    const [youtube, setYoutube] = useState(user.youtube_reference ? user.youtube_reference : "");

    const [isFacebookError, setFacebookError] = useState(false);
    const [isTwitchError, setTwitchError] = useState(false);
    const [isTwitterError, setTwitterError] = useState(false);
    const [isInstagramError, setInstagramError] = useState(false);
    const [isLinkedinError, setLinkedinError] = useState(false);
    const [isYoutubeError, setYoutubeError] = useState(false);

    const darkTheme = useSelector(state => state.darkTheme);

    const [changePassword, setChangePassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const [nickError, setNickError] = useState(false);
    const [nicknameAlreadyTaken, setNicknameAlreadyTaken] = useState(false);

    const validateSocialNetworkLink = (value) => {
      return !(value.startsWith('http://') ||
        value.startsWith('https://') ||
        value.startsWith('www.instagram.com') ||
        value.startsWith('www.facebook.com') ||
        value.startsWith('www.linkedin.com') ||
        value.startsWith('www.twitter.com') ||
        value.startsWith('www.youtube.com') ||
        value.startsWith('www.twitch.tv') ||
        value.startsWith('instagram.com') ||
        value.startsWith('facebook.com') ||
        value.startsWith('linkedin.com') ||
        value.startsWith('twitter.com') ||
        value.startsWith('youtube.com') ||
        value.startsWith('twitch.tv'));
    };

    const onLoseFocusNick = () => {
        services.users.find({
            query: {
                id: {
                    $ne: user.id
                },
                nickname: nickname
            }
        }).then((res) => {
            if(res.total > 0){
                setNickError(true);
                setNicknameAlreadyTaken(true);
            } else {
                setNickError(false);
                setNicknameAlreadyTaken(false);
            }
        });
    }

    const handleSubmit = (e) => {
        let data = {
            nickname,
            firstName,
            lastName,
            birthday,
            biography,
            website,
            location,
            gender,
            instagram_reference: instagram,
            twitter_reference: twitter,
            linkedin_reference: linkedin,
            twitch_reference: twitch,
            facebook_reference: facebook,
            youtube_reference: youtube
        };
        if(changePassword){
            if((currentPassword.length > 0 || user.hasOwnProperty('facebookId') || user.hasOwnProperty('twitterId') || user.hasOwnProperty('twitchId')) && newPassword.length > 0) {
                if(newPassword.length === newPassword2.length && newPassword === newPassword2) {
                    data.old_password = currentPassword.length > 0 ? currentPassword : null;
                    data.new_password = newPassword;
                } else {
                    alert('las contraseñas no coinciden')
                    return;
                }
            }
        }
        if(isInstagramError || isTwitterError || isLinkedinError || isTwitchError || isFacebookError || isYoutubeError) {
            return;
        }
        props.onSubmit(data, e)
    };

    return (
        <Fragment>
            <Grid container spacing={1} display="flex" direction="column" className="edit-profile-form">
                <Grid container item spacing={5} disply="flex" direction="row">
                    <Grid container item xs={12} sm={6} md={6} lg={4} display="flex" direction="column">
                        <Grid item>
                            <Box mb={4}>
                                <p className={"edit-profile-section-title" + (darkTheme ? " isDark" : " isLight")}>{t('forms_header_personalinfo')}</p>
                            </Box>
                        </Grid>
                        <Grid container spacing={1} item>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={t('userEditForm:forms_firstName')}
                                    className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    placeholder={t('userEditForm:forms_firstName')}
                                    value={firstName}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={t('userEditForm:forms_lastName')}
                                    className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    placeholder={t('userEditForm:forms_lastName')}
                                    value={lastName}
                                    onChange={(e) => setLastname(e.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControl variant="outlined" className={"select-input custom-input " +(darkTheme ? " input-dark " : " input-light")}>
                                <InputLabel shrink={true}>{t('forms_genre_label')}</InputLabel>
                                <Select
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}>
                                    <MenuItem value="male"> {t('forms_tag_male')} </MenuItem>
                                    <MenuItem value="female">{t('forms_tag_female')}</MenuItem>
                                    <MenuItem value="other">{t('forms_tag_other')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                value={birthday}
                                onChange={(e) => setBirthday(e)}
                                name="textmask"
                                label={'Fecha de nacimiento'}
                                className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputComponent: BirthdayInput
                                }}
                                id="birthday-input"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <Box mt={3} mb={4}>
                                <p className={"edit-profile-section-title" + (darkTheme ? " isDark" : " isLight")}>{t('forms_header_security')}</p>
                            </Box>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                required
                                disabled
                                label={t('frontPage:forms_email')}
                                className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="email"
                                placeholder={t('frontPage:forms_email')}
                                value={email}
                                variant="outlined"
                            />
                        </Grid>
                        {!changePassword && (
                            <Grid item>
                                <CustomButton className="cancel-btn full-width large" onClick={(e) => setChangePassword(true)}>Cambiar contraseña</CustomButton>
                            </Grid>
                        )}
                        {changePassword && (
                            <Fragment>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        label={'Contraseña actual'}
                                        className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="password"
                                        placeholder={'Contraseña actual'}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        value={currentPassword}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        label={'Nueva contraseña'}
                                        className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="password"
                                        placeholder={'Nueva contraseña'}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        value={newPassword}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        label={'Repetir nueva contraseña'}
                                        className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="password"
                                        placeholder={'Repetir nueva contraseña'}
                                        onChange={(e) => setNewPassword2(e.target.value)}
                                        value={newPassword2}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                    <Grid container item xs={12} sm={6} md={6} lg={4} display="flex" direction="column">
                        <Grid item>
                            <Box mb={4}>
                                <p className={"edit-profile-section-title" + (darkTheme ? " isDark" : " isLight")}>{t('userEditForm:headings_publicProfile')}</p>
                            </Box>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                required
                                error={nickError}
                                onBlur={onLoseFocusNick}
                                label={t('frontPage:forms_nick')}
                                helperText={nickError ? t('frontPage:alerts_nickname_taken') : ''}
                                className={"custom-input" + (darkTheme ? " input-dark" : " input-light") + (nickError ? " input-error" : "")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                placeholder={t('frontPage:forms_nick')}
                                value={nickname}
                                onChange={(e) => setNick(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label={t('userEditForm:forms_website')}
                                className={"custom-input " + (darkTheme ? " input-dark" : " input-light")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                placeholder={t('userEditForm:forms_website')}
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label={t('userEditForm:forms_city')}
                                className={"custom-input " + (darkTheme ? " input-dark" : " input-light")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                placeholder={t('userEditForm:forms_placeholder_city')}
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                multiline
                                rows={7}
                                label={t('extraData:placeholder_biography')}
                                className={"custom-input input-textarea " + (darkTheme ? " input-dark" : " input-light")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                placeholder={t('extraData:placeholder_biography')}
                                value={biography}
                                onChange={(e) => setBiography(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={7} md={7} lg={4} display="flex" direction="column">
                        <Grid item>
                            <Box mb={2}>
                                <p className={"edit-profile-section-title" + (darkTheme ? " isDark" : " isLight")}>{t('userEditForm:headings_socialNetworks')}</p>
                            </Box>
                        </Grid>
                        <Grid item>
                            <CustomSocialNetworkField
                              imageIcon={instagramIcon}
                              isDarkTheme={darkTheme}
                              placeholder={t('userEditForm:forms_placeholders_instagram_placeholder')}
                              value={instagram}
                              onChange={(e) => {
                                  if(!validateSocialNetworkLink(e.target.value)) {
                                      setInstagramError(true);
                                  } else {
                                      setInstagramError(false);
                                  }
                                  setInstagram(e.target.value)
                              }}
                              isError={isInstagramError}
                              helperText={<span dangerouslySetInnerHTML={{__html:t('userEditForm:forms_placeholders_instagram_helper')}}></span>}
                            />
                        </Grid>
                        <Grid item>
                            <CustomSocialNetworkField
                              imageIcon={twitterIcon}
                              isDarkTheme={darkTheme}
                              placeholder={t('userEditForm:forms_placeholders_twitter_placeholder')}
                              value={twitter}
                              onChange={(e) => {
                                  if(!validateSocialNetworkLink(e.target.value)) {
                                      setTwitterError(true);
                                  } else {
                                      setTwitterError(false);
                                  }
                                  setTwitter(e.target.value)
                              }}
                              isError={isTwitterError}
                              helperText={<span dangerouslySetInnerHTML={{__html:t('userEditForm:forms_placeholders_twitter_helper')}}></span>}
                            />
                        </Grid>
                        <Grid item>
                            <CustomSocialNetworkField
                              imageIcon={linkedinIcon}
                              isDarkTheme={darkTheme}
                              placeholder={t('userEditForm:forms_placeholders_linkedin_placeholder')}
                              value={linkedin}
                              onChange={(e) => {
                                  if(!validateSocialNetworkLink(e.target.value)) {
                                      setLinkedinError(true);
                                  } else {
                                      setLinkedinError(false);
                                  }
                                  setLinkedin(e.target.value)
                              }}
                              isError={isLinkedinError}
                              helperText={<span dangerouslySetInnerHTML={{__html:t('userEditForm:forms_placeholders_linkedin_helper')}}></span>}
                            />
                        </Grid>
                        <Grid item>
                            <CustomSocialNetworkField
                              imageIcon={twitchIcon}
                              isDarkTheme={darkTheme}
                              placeholder={t('userEditForm:forms_placeholders_twitch_placeholder')}
                              value={twitch}
                              onChange={(e) => {
                                  if(!validateSocialNetworkLink(e.target.value)) {
                                      setTwitchError(true);
                                  } else {
                                      setTwitchError(false);
                                  }
                                  setTwitch(e.target.value)
                              }}
                              isError={isTwitchError}
                              helperText={<span dangerouslySetInnerHTML={{__html:t('userEditForm:forms_placeholders_twitch_helper')}}></span>}
                            />
                        </Grid>
                        <Grid item>
                            <CustomSocialNetworkField
                              imageIcon={facebookIcon}
                              isDarkTheme={darkTheme}
                              placeholder={t('userEditForm:forms_placeholders_facebook_placeholder')}
                              value={facebook}
                              onChange={(e) => {
                                  if(!validateSocialNetworkLink(e.target.value)) {
                                      setFacebookError(true);
                                  } else {
                                      setFacebookError(false);
                                  }
                                  setFacebook(e.target.value)
                              }}
                              isError={isFacebookError}
                              helperText={<span dangerouslySetInnerHTML={{__html:t('userEditForm:forms_placeholders_facebook_helper')}}></span>}
                            />
                        </Grid>
                        <Grid item>
                            <CustomSocialNetworkField
                              imageIcon={youtubeIcon}
                              isDarkTheme={darkTheme}
                              placeholder={t('userEditForm:forms_placeholders_youtube_placeholder')}
                              value={youtube}
                              onChange={(e) => {
                                  if(!validateSocialNetworkLink(e.target.value)) {
                                      setYoutubeError(true);
                                  } else {
                                      setYoutubeError(false);
                                  }
                                  setYoutube(e.target.value)
                              }}
                              isError={isYoutubeError}
                              helperText={<span dangerouslySetInnerHTML={{__html:t('userEditForm:forms_placeholders_youtube_helper')}}></span>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction={'row'} spacing={4} className="edit-profile-form">
                <Grid item xs={12} container justifyContent="flex-end">
                    <CustomButton loading={props.loading} disabled={props.loading} className="submit-btn wide" onClick={(e) => handleSubmit(e)}> {t('extraData:buttons_save')} </CustomButton>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default EditProfileForm;
