import React from "react";
import Box from "@material-ui/core/Box";
import InfoIcon from '@material-ui/icons/Info';
import './_adbox.scss';

const AdBoxComponent = ({children, bgColor}) => {
    return (
        <Box bgcolor={bgColor} className="ltncy-adbox">
            <div className="ltncy-adbox-heading">
                <div className="ad-badge">Ad</div>
                <InfoIcon fontSize="small"/>
                <div className="ad-text">Ads help us make Latency possible</div>
            </div>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                {children}
            </Box>
        </Box>
    );
};

AdBoxComponent.defaultProps = {
    bgColor: "grid.main"
}

export default AdBoxComponent;
