import services from '../../services/';
import {store} from '../';

export const setInvitations = () => {
    const state = store.getState()
    const {invitations} = state
    const limit = 10
    const skip = invitations.page * limit
    return dispatch => {
        services.notifications.find({query: {notificationType: 'invitations',$limit: limit, $skip: skip, $sort: {createdAt: -1}}})
            .then((resp) => {
                dispatch({
                    type: 'SET_INVITATIONS',
                    payload: prepareData(invitations, resp.data)
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const addNewInvitation = (id) => {
    const state = store.getState()
    const {invitations} = state
    return dispatch => {
        services.notifications.get(id)
            .then((resp) => {
                invitations.data.unshift(resp)
                invitations.badgeCount = getBadgeCount(invitations.data)
                dispatch({
                    type: 'SET_INVITATIONS',
                    payload: invitations
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetInvitations = () => {
    return dispatch => {
        dispatch({type: 'UNSET_INVITATIONS'})
    }
}

export const changeInvitations = (data) => {
    return dispatch => {
        dispatch({type: 'SET_INVITATIONS', payload: data})
    }
}

export const markAllInvitationsSeen = () => {
    return dispatch => {
        const state = store.getState()
        const {invitations} = state
        let data = {...invitations}
        let arr = []
        for(let a=0; a<data.data.length; a++){
            if(data.data[a].status === 0){
                data.data[a].status = 1
                arr.push(data.data[a].id)
            }
        }
        data.badgeCount = 0
        if(arr.length > 0){
            services.notifications.patch(null, { status: 1 }, { query: { id: {$in: arr} } })
                .then((resp) => {
                    dispatch(changeInvitations(data))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}

export const markInvitationRead = (item) => {
    return dispatch => {
        if(item){
            services.notifications.patch(item.id, {status: 1})
                .then((resp) => {
                    const state = store.getState()
                    const {invitations} = state
                    let data = {...invitations}
                    let index = data.data.findIndex(it => it.id === item.id)
                    data.data[index].status = 1
                    dispatch(changeInvitations(data))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}

export const acceptInvite = (item) => {
    return dispatch => {
        services.guests.patch(item.notificationObject.entity.id, {isConfirmed: true})
            .then((resp) => {
                const state = store.getState()
                const {invitations} = state
                let data = {...invitations}
                let index = data.data.findIndex(it => it.id === item.id)
                data.data[index].status = 1;
                data.data[index].notificationObject.entity.isConfirmed = true
                data.data[index].notificationObject.entity.updatedAt = new Date()
                dispatch(changeInvitations(data))
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const rejectInvite = (item) => {
    return dispatch => {
        services.guests.patch(item.notificationObject.entity.id, {isConfirmed: false})
            .then((resp) => {
                const state = store.getState()
                const {invitations} = state
                let data = {...invitations}
                let index = data.data.findIndex(it => it.id === item.id)
                data.data[index].status = 1;
                data.data[index].notificationObject.entity.updatedAt = new Date()
                dispatch(changeInvitations(data))
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

const prepareData = (prev, next) => {
    let oldData = {...prev}
    let newData = [...next]

    for(let a=0; a<newData.length; a++){
        let found = oldData.data.find(item => item.id === newData[a])
        if(!found){
            oldData.data.push(newData[a])
        }
    }
    oldData.page = oldData.page + 1
    oldData.badgeCount = getBadgeCount(oldData.data)
    return oldData
}

const getBadgeCount = (data) => {
    let count = 0
    for(let a=0; a<data.length; a++){
        if(data[a].status === 0){
            count += 1
        }
    }
    return count
}
