import React from 'react';
import './user-agenda.scss';
import { useSelector } from 'react-redux';
import NewAgendaPipelines from "../../Pipelines/NewAgendaPipelines";

const UserAgenda = () => {
    const agendaWeeklySelected = useSelector(state => state.agendaWeeklySelected);

    return (
      <NewAgendaPipelines type={'agenda'} weeklyEvents={agendaWeeklySelected} calendar={true} />
    );
}

export default UserAgenda;
