const initailState = []

const userCategoriesReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_CATEGORIES':
            return state = action.payload;
        case 'UNSET_CATEGORIES':
            return state = initailState
        default:
            return state
    }
}

export default userCategoriesReducer;