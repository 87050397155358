import React, {useEffect, useRef} from "react";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {format} from "date-fns";
import {getLocale} from "../../../helpers/dateFormatter";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import defaultLocale from 'date-fns/locale/en-US';
import {Box, TextField} from "@material-ui/core";
import CustomButton from "../../CustomButton/CustomButton";
import {useTranslation} from "react-i18next";


// Hook
const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener, {passive: false});
            document.addEventListener("touchstart", listener, {passive: false});
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
};

const DateBlockComponent = ({type, label, date, minDate, hideTime, onOpenPicker, onClosePicker, handleChange, absPickerStyle, pickerVisible, datePrintFormat}) => {
    const {t} = useTranslation('extraData');
    const [locale, setLocale] = React.useState(defaultLocale);
    const [time, setTime] = React.useState("00:00");

    const ref = useRef();
    useOnClickOutside(ref, (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        onClosePicker();
        return;
    });

    useEffect(() => {
        setLocale(getLocale());
    }, []);

    useEffect(() => {
        try {
            setTime(date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}))
        } catch (e) {

        }
    }, [date]);

    const formatLC = (date, str) => format(date, str, {
        locale: locale
    });

    const handleDateChange = (val) => {
        handleChange(val);
    };

    const handleStartChange = (e) => {
        let selected_time = e.target.value;
        setTime(selected_time);
        if(selected_time &&selected_time.length > 0) {
            try {
                let new_date = new Date(date);
                let sep = selected_time.split(':');
                new_date.setHours(sep[0], sep[1], 0);
                handleChange(new_date);

            } catch (err) {
                // invalid date
            }
        }
    }

    const openPicker = () => {
        onOpenPicker(type);
    };

    const onClose = () => {
        onClosePicker();
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <div className="date-block-component">
                <Box bgcolor="grid.main" className="date-block" onClick={openPicker}>
                    <div className="date-wrap">
                        <div className="date-top-label">{label}</div>
                        <div className="date-time">
                            <CalendarTodayIcon width="14" height="14" fill="whitesmoke"/>
                            <div className="date">{formatLC(date, datePrintFormat)}</div>
                            {!hideTime && (
                                <>
                                    <WatchLaterIcon width="14" height="14" fill="whitesmoke"/>
                                    <div className="time">{formatLC(date, 'HH:mm')}</div>
                                </>
                            )}
                        </div>
                    </div>
                </Box>
                {pickerVisible && (
                    <div className="abs-picker" ref={ref} style={absPickerStyle}>
                        <DatePicker
                            autoOk
                            allowKeyboardControl={false}
                            minDate={minDate}
                            value={date}
                            onChange={handleDateChange}
                            variant="static" ToolbarComponent={() => <></>}/>
                        <div className={`footer-picker${!hideTime ? ' is-spaced' : ''}`}>
                            {!hideTime && (
                                <div className="time-wrap">
                                    <TextField
                                        required
                                        type="time"
                                        value={time}
                                        placeholder="--:--"
                                        className={"quick-time"}
                                        onChange={handleStartChange}/>
                                </div>
                            )}
                            <CustomButton className="cancel-btn"
                                          onClick={onClose}> {t('extraData:button_close')} </CustomButton>
                        </div>

                    </div>
                )}

            </div>

        </MuiPickersUtilsProvider>
    );
};

DateBlockComponent.defaultProps = {
    type: 'start' | 'end',
    datePrintFormat: 'E, dd LLL',
    hideTime: false
}

export default DateBlockComponent;
