import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Loader from "../../../Loader/Loader";
import {Box, Container, TextField} from '@material-ui/core';
import './advancedauthtoken.scss';
import services from "../../../../services";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const AdvancedAuthTokensForm = () => {
    const [apiKey, setApiKey] = useState({});
    const [showApiKey, setShowApiKey] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation(['settings']);

    useEffect(() => {
        setLoading(true);
        setApiKey({});
        services.apiKeys.find({
            query: {
                revoked: false
            }
        }).then((res) => {
            if (res.total > 0) {
                setApiKey(res.data[0]);
            }
            setLoading(false);
        });
        /*
        services.trustedList.find().then((res) => {
            if (res.total > 0) {
                setFirstTime(false);
                setTrustedList(res.data);
            }
            setLoading(false);
        });
        */
    }, []);

    const handleClickShowPassword = () => {
        setShowApiKey(!showApiKey);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const revokeItem = () => {
        services.apiKeys.patch(apiKey.id, {
            revoked: true
        }).then((res) => {
            setApiKey({});
        })
    }

    const generateItem = () => {
        services.apiKeys.create({}).then((res) => {
            setApiKey(res);
        })
    }

    const regenerateItem = () => {
        services.apiKeys.patch(apiKey.id, {
            regenerate: true
        }).then((res) => {
            setApiKey(res);
        })
    }

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_advanced_apitokens')}</div>
                <div className="settings-content-title-helper">{t('section_advanced_apitokens_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-authtokens">
                {loading && (<Loader/>)}
                {!loading && (
                    <Fragment>
                        <Box bgcolor="grid.content" p={2}>
                            <div>
                                <p>{t("settings:section_advanced_apitokens_header")}</p>
                                {(apiKey.hasOwnProperty('id') && !apiKey.revoked) &&
                                <div>
                                    <TextField
                                        size={"small"}
                                        fullWidth
                                        className={"password"}
                                        type={showApiKey ? 'text' : 'password'}
                                        value={apiKey.apikey}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <code style={{
                                                        borderRadius: "12px",
                                                        padding: "0.25em 0.5em",
                                                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                                                        fontSize: 12
                                                    }}>X-API-KEY:
                                                        "TOKEN {t("settings:section_advanced_apitokens_here")}"</code>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {!showApiKey ? <Visibility style={{color: '#00D1BF'}}/> :
                                                            <VisibilityOff style={{color: '#00D1BF'}}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                                }
                            </div>
                            <Box my={1}>
                                {!apiKey.hasOwnProperty('id') &&
                                <Button className="custom-button submit-btn" onClick={generateItem} fullWidth={true}>
                                    {t("settings:section_advanced_apitokens_generate")}
                                </Button>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>{(apiKey.hasOwnProperty('id') && !apiKey.revoked) &&
                                    <Button className="custom-button white-btn large" onClick={regenerateItem}
                                            fullWidth={true} style={{fontWeight: 400}}>
                                        {t("settings:section_advanced_apitokens_regenerate")}
                                    </Button>
                                    }</Grid>
                                    <Grid item xs={12} md={6}>
                                        {(apiKey.hasOwnProperty('id') && !apiKey.revoked) &&
                                        <Button className="custom-button delete-btn large" onClick={revokeItem}
                                                fullWidth={true} style={{fontWeight: 400}}>
                                            {t("settings:section_advanced_apitokens_revoke")}
                                        </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Fragment>
                )}
            </div>
        </div>
    )
};

export default AdvancedAuthTokensForm;
