import {Box} from "@material-ui/core";
import React from "react";
import {ownTimeAgo} from "../../../../../helpers/dateFormatter";
import {setOpenedEvent} from "../../../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../../../store/eventInfoModal/actions";
import {useDispatch, useSelector} from "react-redux";
import {Event} from "../../../../../services/tracking";


const PassedItem = ({ item }) => {
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);

    const OpenInfo = () => {
        window.history.pushState({backRef: window.location.pathname}, "my calendar", `/${item.owner.slug}/${item.id}`)
        dispatch(setOpenedEvent(item.id))
        dispatch(setOpenedEventMessages(item.id))
        dispatch(openEventInfoModal())
        Event("PassedItem", "OpenEvent", item.id);
    };

    return (
        <div className={`landing-item-live ${deviceView === 'browser' ? ' is-desktop' : ''}${deviceView === 'mobile' ? ' is-mobile' : ''}${deviceView === 'tablet' ? ' is-tablet' : ''}${darkTheme ? ' is-dark-theme' : ' is-light-theme'}`} onClick={OpenInfo}>
            <div className={"item-thumb"}>
                <div className={"item-thumb-layer"}>
                    <Box className="item-thumb-live-counter" display="flex" flexDirection="row" justifyContent="flex-start"
                         alignItems="center">
                        {ownTimeAgo(item.end)}
                    </Box>
                </div>
                <div className={"item-thumb-bg"} />
                <img src={`${item.landing.hasOwnProperty('thumbnail_url') && item.landing.thumbnail_url.length > 0 ? item.landing.thumbnail_url : "/media-thumb-no-available.png"}`} />
            </div>
            <div className={"item-content"}>
                <div className={"item-user"}>
                    <div className={"item-user-avatar"}>
                        <img src={item.landing.avatar} />
                    </div>
                    <div className={"item-user-content"}>
                        <div className="item-user-title">{item.landing.twitch_user}</div>
                        <div className="item-user-subtitle">{item.title}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PassedItem;
