import {Button, TextField} from "@material-ui/core";
import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import services from "../../../services";
import CustomButton from '../../CustomButton/CustomButton';

const StageOne = (props) => {
    const { onChange, onNext } = props;
    const {t} = useTranslation(['validations, frontPage']);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [verifiedError, setVerifiedError] = useState(false);
    const [verifiedErrorLiteral, setVerifiedErrorLiteral] = useState("");
    const [verifiedWait, setVerifiedWait] = useState(false);
    const [generalError, setGeneralError] = useState("");

    const onEmailChange = (e) => {
        setEmail(e.target.value)
        !e.target.value ? setEmailError(true) : setEmailError(false)
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        if(email){
            services.authManagement.create({
                action: 'sendResetPwd',
                value: {
                    email: email
                }
            }).then((res) => {
                onChange(email)
                onNext(2)
            }).catch((err) => {
                console.log(err)
                if(err.message === "User not found."){
                    setGeneralError(t('validations:user_not_found'));
                }else if(err.message === "User is not verified."){
                    setVerifiedError(true);
                }else if(err.message === "User must wait"){
                    setGeneralError(err.data.email);
                }
            });
        }else{
            setEmailError(true);
        }
    };

    const resendVerify = () => {
        if(email) {
            services.authManagement.create({
                action: 'resendVerifySignup',
                value: {
                    email: email
                }
            }).then((res) => {
                setVerifiedError(false);
                setVerifiedWait(true);
            }).catch((err) => {
                setVerifiedError(false);
                if(err.hasOwnProperty('message')) {
                    setVerifiedErrorLiteral(err.message);
                }
            });
        }
    }

    return (
        <Fragment>
            <TextField
                className="email"
                error={emailError}
                type="email"
                label={t('frontPage:forms_email')}
                value={email}
                variant="outlined"
                onChange={onEmailChange}
                onKeyDown={handleKeyDown} />

            {verifiedError && <span className="error">{t("validations:user_not_verified")} <Button className="resend-button" onClick={resendVerify}>{t("validations:user_not_verified_btn_resend")}</Button> </span>}
            {verifiedWait && <span className="info">{t("validations:user_wait_verification")}</span>}
            {verifiedErrorLiteral.length > 0 && <span className="error">{verifiedErrorLiteral}</span>}

            {generalError && <span className="error">{generalError}</span>}

            {(!verifiedError && verifiedErrorLiteral.length === 0 && !verifiedWait) && <CustomButton className="submit-btn" color="primary" onClick={handleSubmit}>{t("validations:validation_btn_request_code")}</CustomButton>}

        </Fragment>
    )
};

export default StageOne;
