import {startOfMonth, endOfMonth, isSameMonth, isSameDay, addDays, isToday, lightFormat} from 'date-fns';
import {ownEndOfWeek, ownIsSameWeek, ownStartOfWeek} from "./dateFormatter";

export const generateDays = (month, date, counts) => {
    const monthStart = startOfMonth(month)
    const monthEnd = endOfMonth(monthStart)
    const startDate = ownStartOfWeek(monthStart)
    const endDate = ownEndOfWeek(monthEnd)
    const weeks = [];
    let days = [];
    let day = startDate;

    while (day <= endDate){
        for(let i=0; i<7; i++){
            let obj = {}
            //let item = findItem(day, counts)
            obj.date = day
            obj.disabled = !isSameMonth(day, monthStart)
            obj.selected = isSameDay(day, date)
            obj.sameWeek = ownIsSameWeek(day, date)
            obj.isCurrentDate = isToday(day)
            //obj.count = item ? item.events : 0
            days.push(obj)
            day = addDays(day, 1)
        }
        let obj = {}
        obj.week = days
        if(days[0].sameWeek){
            obj.selectedWeek = true
        } else {
            obj.selectedWeek = false
        }
        weeks.push(obj)
        days = []
    }
    return weeks;
}


const findItem = (day, list) => {
    for(let a=0; a<list.length; a++){
        if(list[a].day === lightFormat(day, 'yyyy-MM-dd')){
            return list[a]
        }
    }
}
