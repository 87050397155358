import React, { useState, useEffect, Fragment } from 'react';
import './followers-info.scss';
import { Box } from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { followUser, unfollowUser } from '../../../store/app/actions';
import CustomButton from '../../CustomButton/CustomButton';
import  { ReactComponent as DiamondIcon } from '../../../assets/icons/diamond.svg';

import { useTranslation } from 'react-i18next';
import abbreviate from "number-abbreviate";
import ButtonProfileWrapper from "../../UserSubscriptions/ButtonProfileWrapper";

const FollowersInfo = (props) => {
    const {onTab} = props
    const openedProfile = useSelector(state => state.openedProfile)
    const loggedIn = useSelector(state => state.loggedIn)
    const user = useSelector(state => state.user)
    const [follow, setFollow] = useState(openedProfile.follow)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const deviceView = useSelector(state => state.deviceView)
    const {t} = useTranslation('extraData')

    useEffect(() => {
        setFollow(loggedIn ? openedProfile.follow : false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedProfile.id, openedProfile.follow, loggedIn])

    const redirect = () => {
        history.push('/login')
    }

    const showTabs = (data) => {
        onTab(null, data)
        if(deviceView === "browser"){
            window.scrollTo(0, 600)
        } else if(deviceView === "tablet" || deviceView === "mobile"){
            window.scrollTo(0, 800)
        }
    }

    return (
        <Box className={"followers-info " + (user.id === openedProfile.id ? "small" : "")} bgcolor="background.default">
            {(deviceView === "browser" || deviceView === "tablet") &&
                <Fragment>
                    <Box className="count-box" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center">
                        <span className="count" onClick={() => showTabs(2)}><span>{openedProfile.eventsCount ? abbreviate(openedProfile.eventsCount, 1) : 0}</span> {t('text_events')} </span>
                        <span className="count" onClick={() => showTabs(2)}><span>{openedProfile.followingCount ? abbreviate(openedProfile.followingCount, 1) : 0}</span> {t('text_following')} </span>
                        <span className="count" onClick={() => showTabs(2)}><span>{openedProfile.followersCount ? abbreviate(openedProfile.followersCount, 1) : 0 }</span> {t('text_followers')} </span>
                    </Box>
                    {user.id !== openedProfile.id &&
                        <Box className="actions" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            {/*<img src={NotificationIcon} alt="latency" className="bell-icon" />*/}
                            {!follow &&
                                <CustomButton
                                    loading={loading}
                                    onClick={() => {
                                        setLoading(true)
                                        dispatch(followUser(openedProfile, redirect, "profile-info", setFollow, setLoading))
                                    }}
                                    className="follow-btn">{t('extraData:buttons_follow')}</CustomButton>
                            }

                            {user.id !== openedProfile.id && follow &&
                                <CustomButton
                                    loading={loading}
                                    onClick={() => {
                                        setLoading(true)
                                        dispatch(unfollowUser(openedProfile, redirect, "profile-info", setFollow, setLoading))
                                    }}
                                    className="unfollow-btn">{t('extraData:buttons_unfollow')}</CustomButton>
                            }
                        </Box>
                    }
                </Fragment>
            }
            {(deviceView === "mobile") &&
                <Fragment>
                    {user.id !== openedProfile.id &&
                        <Box className="actions" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            {/*<img src={NotificationIcon} alt="latency" className="bell-icon" />*/}
                            {!follow &&
                                <CustomButton
                                    loading={loading}
                                    onClick={() => {
                                        setLoading(true)
                                        dispatch(followUser(openedProfile, redirect, "profile-info", setFollow, setLoading))
                                    }}
                                    className="follow-btn">{t('extraData:buttons_follow')}</CustomButton>
                            }

                            {user.id !== openedProfile.id && follow &&
                                <CustomButton
                                    loading={loading}
                                    onClick={() => {
                                        setLoading(true)
                                        dispatch(unfollowUser(openedProfile, redirect, "profile-info", setFollow, setLoading))
                                    }}
                                    className="unfollow-btn">{t('extraData:buttons_unfollow')}</CustomButton>
                            }
                        </Box>
                    }
                    <Box className="count-box" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center">
                        <span className="count" onClick={() => showTabs(0)}><span>{openedProfile.eventsCount ? abbreviate(openedProfile.eventsCount, 1) : 0}</span> {t('text_events')}</span>
                        <span className="count" onClick={() => showTabs(1)}> <span>{openedProfile.followingCount ? abbreviate(openedProfile.followingCount, 1) : 0}</span> {t('text_following')}</span>
                        <span className="count" onClick={() => showTabs(2)}><span>{openedProfile.followersCount ? abbreviate(openedProfile.followersCount, 1) : 0 }</span> {t('text_followers')}</span>
                    </Box>
                </Fragment>
            }
        </Box>
    )
}

export default FollowersInfo;
