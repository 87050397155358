import React, {useEffect, useState} from 'react';
import BellIconOff from '../../../../../assets/icons/actions/bell-off-grey.svg';

export const ValorantResultEvent = ({ id, team_local, team_guest, original_poster, tournament}) => {
    return (
      <div className="embed-next-events-item">
          <div className="event-score--team is-home">
              <div className="event-score--team-image">
                  <img src={team_local.image} alt={team_local.name}/>
              </div>
              <div className="event-score--team-name">
                  {team_local.name}
              </div>
          </div>
          <div className="event-score--versus">
              <div className="event-score--versus-label">vs</div>
              <div className="scores">
                  <span className="team-scoreboard">{team_local.scoreboard}</span>
                  <span className="separator-scoreboard">:</span>
                  <span className="team-scoreboard">{team_guest.scoreboard}</span>
              </div>

          </div>
          <div className="event-score--team is-guest">
              <div className="event-score--team-image">
                  <img src={team_guest.image} alt={team_guest.name}/>
              </div>
              <div className="event-score--team-name">
                  {team_guest.name}
              </div>
          </div>
          <div className="event-score--tournament">
              <div className="top-bar">
                  <div className="event-score--tournament-start">
                      Finished
                  </div>
                  <div className="event-score--tournament-name">
                      <img src={`https://latencyapp.com${original_poster}`} alt={tournament} />
                      <span>{tournament.substring(0, 32)}{tournament.length > 32 ? '...' : ''}</span>
                  </div>
                  <div className="event-score--tournament-actions">
                      <img src={BellIconOff} />
                  </div>
              </div>

              <div className="bg-ss" />
          </div>
      </div>
    );
}
