import React from 'react';
import { Box } from '@material-ui/core';
import {addDays} from 'date-fns';
import {formatDate, ownStartOfWeek} from '../../helpers/dateFormatter';

const CalendarDays = (props) => {
    const dayFormat = "iii";
    const days = [0,1,2,3,4,5,6];
    let start = ownStartOfWeek(props.month);

    return (
        <Box display="flex" flexDirection="row">
            { days.map((val, i) =>
                <Box className="day" key={i}>{formatDate(addDays(start, val), dayFormat)}</Box>
            )}
        </Box>
    )
}

export default CalendarDays;
