const initailState = []

const interestsReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_INTERESTS':
            return state = action.payload;
        case 'UNSET_INTERESTS':
            return state = initailState
        default:
            return state
    }
}

export default interestsReducer;