import React, {useEffect, useState} from "react";
import {isSameDay, lightFormat} from "date-fns";
import {formatDate} from "../../helpers/dateFormatter";
import {Box} from "@material-ui/core";
import {useSelector} from "react-redux";

const CalendarCellItem = ({onDateClick, day, date}) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const monthlyEventsCount = useSelector(state => state.monthlyEventsCount)
    const dateFormat = "d"
    const [count, setCount] = useState(0);
    let _isSameDay = isSameDay(day.date, date);

    const strFormatted = formatDate(day.date, dateFormat);

    const handleOnClick = () => {
        onDateClick(day)
    }

    useEffect(() => {
        let _c = monthlyEventsCount.find(ev => ev.day === lightFormat(day.date, 'yyyy-MM-dd'))
        if(_c !== undefined) {
            setCount(_c.events)
        }else{
            setCount(0)
        }
    }, [day, monthlyEventsCount])

    return (
        <Box onClick={handleOnClick} className={'day-cell ' + (day.disabled ? ' disabled ' : ' ') + (_isSameDay ? ' selected ' : ' ') + (day.isCurrentDate ? ' isCurrentDay ': ' ') + (darkTheme ? 'dark' : 'light')}>
            <span> {strFormatted} </span>
            {count > 0 && <Box className={"day-count " + (darkTheme ? 'dark' : 'light')}> {count} </Box>}
        </Box>
    );
};

export default CalendarCellItem;
