import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Button, Input, Switch} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {showSnackbar} from "../../../../store/snackbar/actions";
import LandingConfigurationFormCropperModal from "./Fragments/LandingConfigurationFormCropperModal";
import LandingConfigurationFormCountdown from "./Fragments/LandingConfigurationFormCountdown";
import LandingConfigurationFormTwitch from "./Fragments/LandingConfigurationFormTwitch";

const LandingConfigurationForm = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation('settings');
    const [enabled, setEnabled] = useState(false);
    const darkTheme = useSelector(state => state.darkTheme);
    const [videoHeader, setVideoHeader] = useState("");
    const [imgHeader, setImgHeader] = useState("");
    const [mobileImgHeader, setMobileImgHeader] = useState("");
    const [imgCropper, setImgCropper] = useState(false);
    const [mobileImgCropper, setMobileImgCropper] = useState(false);
    const [preImgDisplay, setPreImgDisplay] = useState('');
    const [preMobileImgDisplay, setPreMobileImgDisplay] = useState('');
    const [countdown, setCountDown] = useState(new Date());
    const [twitchToken, setTwitchToken] = useState('');
    const [twitchGameIdInput, setTwitchGameIdInput] = useState('');
    const [selectionSpiderData, setSelectionSpiderData] = useState([]);
    const [twitchGameIds, setTwitchGameIds] = useState([]);
    const [spiderData, setSpiderData] = useState([]);


    const addRowHandle = () => {
        setSpiderData(prev => [...prev, {
            id: Math.random(),
            streamer: '',
            twitter: '',
            twitch: '',
            instagram: '',
            facebook: '',
            youtube: '',
            picture: '',
            latencyId: ''
        }]);
    };

    const deleteSelectionHandle = () => {
        setSpiderData(prev => prev.filter(x => !selectionSpiderData.includes(x.id)));
        setSelectionSpiderData([]);
    };

    const twitchGameIdInputHandle = (e) => setTwitchGameIdInput(e.target.value);

    const twitchTokenHandle = (e) => setTwitchToken(e.target.value);

    const addTwitchGameIdHandle = (e) => {
        e.preventDefault();
        setTwitchGameIds(a => {
            if (!a.includes(twitchGameIdInput)) {
                setTwitchGameIdInput('');
                return [...a, twitchGameIdInput];
            } else return a;
        });
    };

    const deleteTwitchGameIdHandle = (id) => {
        setTwitchGameIds(a => a.filter(i => i !== id));
    };

    const onChangeRowsHandle = (e) => {
        if (e) {
            setSpiderData(prev => {
                let rows = [...prev];
                let k = Object.keys(e)[0];

                if (k) {
                    let o = rows.filter(x => x.id === parseFloat(k))[0];
                    if (o) {
                        let index = rows.indexOf(o);
                        let k1 = Object.values(e);
                        if (k1) {
                            let k2 = Object.keys(k1[0])
                            k2.forEach(
                                x => {
                                    if (k1[0] && k1[0][x] && k1[0][x].value) {
                                        rows[index][x] = k1[0][x].value;
                                    } else {
                                        rows[index][x] = '';
                                    }
                                }
                            );
                        }
                    }
                }
                return rows;
            });
        }
    };

    const listComponents = [
        {
            id: 'live_now',
            label: t('section_landing_twitch_live_now')
        },
        {
            id: 'agenda',
            label: t('section_landing_twitch_agenda')
        },
        {
            id: 'passed_events',
            label: t('section_landing_twitch_live_now')
        },
        {
            id: 'feed',
            label: t('section_landing_twitch_feed')
        },
        {
            id: 'chat',
            label: t('section_landing_twitch_chat')
        },
        {
            id: 'countdown',
            label: t('section_landing_twitch_countdown'),
            component:
                <LandingConfigurationFormCountdown countdown={countdown} setCountDown={setCountDown}/>
        },
        {
            id: 'twitch_compatibility',
            label: t('section_landing_twitch_twitch'),
            component:
                <LandingConfigurationFormTwitch
                    twitchToken={twitchToken}
                    twitchTokenHandle={twitchTokenHandle}
                    addTwitchGameIdHandle={addTwitchGameIdHandle}
                    twitchGameIdInput={twitchGameIdInput}
                    twitchGameIdInputHandle={twitchGameIdInputHandle}
                    twitchGameIds={twitchGameIds}
                    deleteTwitchGameIdHandle={deleteTwitchGameIdHandle}
                    addRowHandle={addRowHandle}
                    deleteSelectionHandle={deleteSelectionHandle}
                    selectionSpiderData={selectionSpiderData}
                    spiderData={spiderData}
                    onChangeRowsHandle={onChangeRowsHandle}
                    setSelectionSpiderData={setSelectionSpiderData}
                />
        }
    ];
    const [enabledComponents, setEnabledComponents] = useState(['live_now', 'agenda', 'passed_events', 'feed', 'chat'])

    const videoHeaderChange = (e) => {
        let file = e.target.files[0];

        if (checkSize(file.size, 2)) return;

        let reader = new FileReader();
        if (file.type === "video/webm") {
            reader.onloadend = function () {
                setVideoHeader(reader.result)
            };
            reader.readAsDataURL(file);
        } else {
            dispatch(showSnackbar({message: t("userEditForm:invalid_format"), type: "error"}));
        }
    };

    const imgHeaderChange = (e) => {
        let file = e.target.files[0];

        if (checkSize(file.size, 2)) return;

        let reader = new FileReader();
        if (file.type === "image/jpg" || file.type === "image/png") {
            setPreImgDisplay(file)
            reader.onloadend = function () {
                setPreImgDisplay(reader.result)
            };
            setImgCropper(true)
            reader.readAsDataURL(file);
        } else {
            dispatch(showSnackbar({message: t("userEditForm:invalid_format"), type: "error"}));
        }
    };

    const handleToggleComponents = (id) => {
        if (enabledComponents.includes(id))
            setEnabledComponents(prev => prev.filter(x => x !== id));
        else
            setEnabledComponents(prev => [...prev, id]);
    };

    const mobileImgHeaderChange = (e) => {
        let file = e.target.files[0];

        if (checkSize(file.size, 2)) return;

        let reader = new FileReader();
        if (file.type === "image/jpg" || file.type === "image/png") {
            setPreMobileImgDisplay(file)
            reader.onloadend = function () {
                setPreMobileImgDisplay(reader.result)
            };
            setMobileImgCropper(true)
            reader.readAsDataURL(file);
        } else {
            dispatch(showSnackbar({message: t("userEditForm:invalid_format"), type: "error"}));
        }
    };

    const checkSize = (bytes, limitMB) => {
        let isBigger = (bytes / (1024 * 1024)).toFixed(2) > limitMB;

        if (isBigger) {
            dispatch(showSnackbar({message: `${t("userEditForm:invalid_size")} (max ${limitMB}MB)`, type: "error"}));
        }

        return isBigger;
    }

    const closeImageModal = () => setImgCropper(false);

    const closeMobileImageModal = () => setMobileImgCropper(false);

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_landing_configuration')}</div>
                <div className="settings-content-title-helper">{t('section_landing_configuration_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-landing">
                <div className="settings-landing-configuration-main">
                    <Box bgcolor="grid.content" className="settings-landing-configuration-row">
                        <div className="settings-content-subsection-header with-action">
                            <div>{t("section_landing_component_status")}</div>
                            <div>
                                <Switch checked={enabled}
                                        onChange={(e) => setEnabled(e.target.checked)}
                                        className="switch"/>
                            </div>
                        </div>
                    </Box>

                    <Box bgcolor="grid.content" className="settings-landing-configuration-row">
                        <div className="settings-content-subsection-header">{t("section_landing_customization")}</div>
                        <Box mx={2}>
                            <p>{t("section_landing_header")}:</p>
                            <div className={"settings-landing-configuration-row-special"}>
                                <div
                                    className={`settings-landing-configuration-column-header is-desktop ${darkTheme ? ' is-dark' : ''}`}>
                                    {t("section_landing_desktop")}
                                    <Box display="flex" alignItems="center" justifyContent="space-evenly" m={1}>
                                        <label htmlFor="contained-button-file-1">
                                            <Input id="contained-button-file-1" type="file"
                                                   inputProps={{"accept": ".png"}}
                                                   style={{display: "none"}} onChange={imgHeaderChange}/>
                                            <Button variant="contained" component="span" color={"primary"}>
                                                {t("section_landing_component_upload_image")}
                                            </Button>
                                        </label>
                                        <label htmlFor="contained-button-file-2">
                                            <Input id="contained-button-file-2" type="file"
                                                   inputProps={{"accept": ".webm"}}
                                                   style={{display: "none"}} onChange={videoHeaderChange}/>
                                            <Button variant="contained" component="span" color={"primary"}>
                                                {t("section_landing_component_upload_video")}
                                            </Button>
                                        </label>
                                    </Box>
                                    <div className={"text-placeholder"}>
                                        1416x300
                                    </div>
                                    {imgHeader && <img src={imgHeader} alt='desktop-header' width='100%'/>}
                                    {videoHeader && <video width='100%' autoPlay loop>
                                        <source src={videoHeader} type="video/webm"/>
                                    </video>}
                                </div>
                                <div
                                    className={`settings-landing-configuration-column-header is-mobile ${darkTheme ? ' is-dark' : ''}`}>
                                    {t("section_landing_mobile")}
                                    <Box display="flex" alignItems="center" justifyContent="space-evenly" m={1}>
                                        <label htmlFor="contained-button-file-3">
                                            <Input id="contained-button-file-3" type="file"
                                                   inputProps={{"accept": ".png"}}
                                                   style={{display: "none"}} onChange={mobileImgHeaderChange}/>
                                            <Button variant="contained" component="span" color={"primary"}>
                                                {t("section_landing_component_upload_image")}
                                            </Button>
                                        </label>
                                    </Box>
                                    <div className={"text-placeholder"}>428x122</div>
                                    {mobileImgHeader && <img src={mobileImgHeader} alt='mobile-header' width='100%'/>}
                                </div>
                            </div>
                        </Box>
                    </Box>

                    <Box bgcolor="grid.content" className="settings-landing-configuration-row">
                        <div className="settings-content-subsection-header">
                            {t("section_landing_component_customization")}
                        </div>
                        <div className="settings-content-subsection-content">
                            {listComponents.map((item) =>
                                <div key={item.id}>
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start"
                                         alignItems="center">
                                        <Switch checked={enabledComponents.includes(item.id)}
                                                onChange={() => handleToggleComponents(item.id)} className="switch"/>
                                        <span>{item.label}</span>
                                    </Box>
                                    {(enabledComponents.includes(item.id) && item.component) && item.component}
                                </div>
                            )}
                        </div>
                    </Box>
                </div>
            </div>
            <LandingConfigurationFormCropperModal
                open={imgCropper}
                close={closeImageModal}
                width={1416}
                height={300}
                poster={preImgDisplay}
                onCrop={setImgHeader}
            />
            <LandingConfigurationFormCropperModal
                open={mobileImgCropper}
                close={closeMobileImageModal}
                width={428}
                height={122}
                poster={preMobileImgDisplay}
                onCrop={setMobileImgHeader}
            />
        </div>
    );
};

export default LandingConfigurationForm;
