import React, {useEffect, useMemo, useState} from 'react';
import {Box} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import services from "../../../services";
import {getCurrentTimezone} from "../../../helpers/dateFormatter";
import Skeleton from "@material-ui/lab/Skeleton";
import Lottie from "react-lottie-player";
import animationData from "../../../assets/animations/warning-yellow.json";
import './_explore_featured_categories_component.scss';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay, Pagination, Navigation} from 'swiper';

const ExploreFeaturedCategoriesComponent = () => {
    const history = useHistory();
    const { t } = useTranslation(['explore']);
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isErrorFetching, setErrorFetching] = useState(false);

    useEffect(() => {
        services.exploreFeaturedCategories.find({
            query: {
                timezone: getCurrentTimezone(),
            }
        }).then(res => {
            setItems(res.sort(() => Math.random() - 0.5));
            setLoading(false);
            setErrorFetching(false);
        }).catch(err => {
            setLoading(true);
            setErrorFetching(true);
        });
    }, []);

    const handleOpenDiscoverCat = (ref) => {
        history.push(ref);
    }

    return (
        <Box className={`explore-featured-categories--main is-${deviceView}`}>
            {isErrorFetching && (
                <div className="explore-featured-categories--main--handling-err">
                    <Lottie
                        loop={true}
                        animationData={animationData}
                        play
                        style={{width: 200, height: 'auto'}}
                    />
                    <span>ERROR FETCHING</span>
                </div>
            )}
            {loading && (
                <div className="explore-featured-categories--main--grid">
                    {([0, 1, 2, 3, 4, 5].map(item => <Skeleton key={item} width={200} height={324}
                                                               variant="rect"
                                                               className={(darkTheme ? 'dark' : 'light')}/>))}
                </div>
            )}
            {!loading &&
                <Swiper
                    slidesPerView={deviceView === 'browser' || deviceView === 'tablet' ? 6 : 3}
                    className="mySwiperCats"
                    loop={true}
                    speed={2000}
                    navigation={false}
                    pagination={{ clickable: true }}
                    spaceBetween={10}
                    autoplay={{
                        "delay": 2000
                    }}>
                    {items.map((item) => (
                        <SwiperSlide key={`explore-featured-category-item-${item.id}`}>
                            <a href={`/${t('category_slug_label')}/${item.hasOwnProperty('parent') ? item.parent.slug + '/' : ''}${item.slug}`} onClick={(e) => {
                                e.preventDefault();
                                handleOpenDiscoverCat(`/${t('category_slug_label')}/${item.hasOwnProperty('parent') ? item.parent.slug + '/' : ''}${item.slug}`)
                            }}>
                                <Box bgcolor="grid.content" className={`explore-featured-categories--main-item ${darkTheme ? 'is-dark' : 'is-light'}`}>
                                    <div className="event-category_image-box">
                                        <picture>
                                            <source srcSet={`${process.env.REACT_APP_CDN + item.cover_url}.webp`}
                                                    type="image/webp"/>
                                            <source srcSet={process.env.REACT_APP_CDN + item.cover_url}
                                                    type="image/jpeg"/>
                                            <img width="100%" height="100%" src={process.env.REACT_APP_CDN + item.cover_url}
                                                 alt={`${t('category_label')} ${item.text}`}/>
                                        </picture>
                                    </div>
                                    <h3 className="event-category_title">{item.text}</h3>
                                    <aside className="event-category_subtitle">{item.eventsCount} {t('events_count_label')}</aside>
                                </Box>
                            </a>
                        </SwiperSlide>)
                    )}
                </Swiper>
            }
        </Box>
    );
};

export default ExploreFeaturedCategoriesComponent;
