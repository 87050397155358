import React, {useEffect, useState} from 'react';
import services from "../../../services";
import {getCurrentTimezone} from "../../../helpers/dateFormatter";
import Lottie from "react-lottie-player";
import animationData from "../../../assets/animations/warning-yellow.json";
import Skeleton from "@material-ui/lab/Skeleton";
import {useSelector} from "react-redux";
import './_category_interest_form.scss';
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Scrollbar from "react-scrollbars-custom";


export const CategoryInterestForm = ({onChange}) => {
    const {t} = useTranslation(['explore', 'completeSignup']);
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const [isErrorFetching, setErrorFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        services.exploreFeaturedCategories.find({
            query: {
                timezone: getCurrentTimezone(),
            }
        }).then(res => {
            setItems(res.sort((a, b) => a.eventsCount > b.eventsCount ? -1 : 1));
            setLoading(false);
            setErrorFetching(false);
        }).catch(err => {
            setLoading(true);
            setErrorFetching(true);
        });
    }, []);

    useEffect(() => {
        onChange(selected);
    }, [selected])

    const handleSelectItem = (itemId) => {
        if (selected.includes(itemId)) {
            setSelected((sel) => {
                //onChange(final);
                return sel.filter(i => i !== itemId);
            });
        } else {
            setSelected((sel) => {
                //onChange(final);
                return [...sel, itemId];
            });
        }
    }

    return (
        <div className={`category-interest-form ${deviceView === "mobile" ? " is-mobile" : ""}`}>
            {isErrorFetching && (
                <div className="categories--main--handling-err">
                    <Lottie
                        loop={true}
                        animationData={animationData}
                        play
                        style={{width: 200, height: 'auto'}}
                    />
                    <span>ERROR FETCHING</span>
                </div>
            )}
            {loading && (
                <div className="categories--main--grid">
                    {([0, 1, 2, 3, 4, 5, 6, 7].map(item => <Skeleton key={item} width={120} height={200}
                                                                     variant="rect"
                                                                     className={(darkTheme ? 'dark' : 'light')}/>))}
                </div>
            )}
            {!loading && (
                <>
                    <div className={`interests-info is-${(darkTheme ? 'dark' : 'light')}`}><strong>{t('completeSignup:helperCategoryInterestTitle')}</strong><br/>{t('completeSignup:helperCategoryInterestSubtitle')}</div>
                    <div className="scroller">
                        <Scrollbar style={{ width: '100%', minHeight: '634px' }} noDefaultStyles disableTracksWidthCompensation>
                            <div className="categories--main--grid">
                                {items.map((item) => (
                                    <Box key={`explore-featured-category-item-${item.id}`} bgcolor="grid.main"
                                         className={`categories--main-item ${selected.includes(item.id) ? " is-selected" : ""}`}
                                         onClick={() => handleSelectItem(item.id)}>
                                        <div className="event-category_image-box">
                                            <picture>
                                                <source srcSet={`${process.env.REACT_APP_CDN + item.cover_url}.webp`}
                                                        type="image/webp"/>
                                                <source srcSet={process.env.REACT_APP_CDN + item.cover_url}
                                                        type="image/jpeg"/>
                                                <img width="100%" height="100%"
                                                     src={process.env.REACT_APP_CDN + item.cover_url}
                                                     alt={`${t('category_label')} ${item.text}`}/>
                                            </picture>
                                        </div>
                                        <h3 className="event-category_title">{item.text}</h3>
                                        <aside
                                            className="event-category_subtitle">{item.eventsCount} {t('events_count_label')}</aside>
                                    </Box>)
                                )}
                            </div>
                        </Scrollbar>
                    </div>
                </>
            )}

        </div>
    );
};
