import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import ReactGA from "react-ga4";


const CustomAnalytics = ({ history }) => {
    useEffect(() => history.listen((location, action) => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }), [])

    //...
    return null;
}

export default withRouter(CustomAnalytics);
