import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {getLocale} from "../../../../../helpers/dateFormatter";
import {Box} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

const LandingConfigurationFormCountdown = ({countdown, setCountDown}) => {
    const {t} = useTranslation('settings');

    return (
        <Box m={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                <DateTimePicker
                    label={t("section_landing_countdown")}
                    variant="inline"
                    ampm={false}
                    value={countdown}
                    onChange={setCountDown}
                    onError={console.log}
                    disablePast
                    format="dd/MM/yyyy HH:mm"
                    style={{width: "100%"}}
                />
            </MuiPickersUtilsProvider>
        </Box>
    );
};

export default LandingConfigurationFormCountdown;
