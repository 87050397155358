import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import './_discover_category_page.scss';
import NewAgendaPipelines from "../../components/Pipelines/NewAgendaPipelines";
import {setSelectedDate} from "../../store/selectedDate/actions";
import Calendar from "../../components/Calendar/Calendar";
import {setMonthlyEventsCount} from "../../store/monthlyEventsCount/actions";
import {setOpenedDiscoverCategoriesWeeklyEvents} from "../../store/openedDiscoverCategoriesWeeklyEvents/actions";
import {Helmet} from "react-helmet";
import CategoryFeaturedUsersComponent from "../../components/CategoryFeaturedUsers/CategoryFeaturedUsersComponent";
import ArrowLeftIcon from "@material-ui/icons/ChevronLeft";

const DiscoverCategory = () => {
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const {i18n, t} = useTranslation();
    const today_date = new Date();
    const {primary, secondary} = useParams();
    const [parent, setParent] = useState(null);
    const [category, setCategory] = useState(null);
    const eventCategoriesSelector = useSelector(state => state.eventCategories);
    const weeklySelected = useSelector(state => state.openedDiscoverCategories);
    const [featuredUsersCategory, setFeaturedUsersCategory] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (category !== null && category && category.hasOwnProperty('id')) {
            dispatch(setSelectedDate(today_date));
            dispatch(setMonthlyEventsCount(today_date, null, null, category.id))
            dispatch(setOpenedDiscoverCategoriesWeeklyEvents(today_date, category.id));
            handleCategoryFeaturedUsers(category.id)
        }
    }, [category]);

    useEffect(() => {
        if (eventCategoriesSelector) {
            if (secondary !== undefined) {
                let item = eventCategoriesSelector.find(i => i.slug === secondary);
                if (item) {
                    setCategory(item);
                    setParent(eventCategoriesSelector.find(i => i.id === item.parentId));
                }
            } else {
                setCategory(eventCategoriesSelector.find(i => i.slug === primary));
            }
        }
    }, [eventCategoriesSelector, primary, secondary]);

    const centerOnDay = () => {

    };

    const handleCategoryFeaturedUsers = (catId) => {
        let cat = null;
        if (catId === '37f0a322-a2ac-480e-8345-99ce1d86abf6') { // cine y series
            cat = 6;
        } else if (catId === '181a8b9d-9ff7-4827-bb62-5d585add5623') { // podcasts
            cat = 10;
        } else if (catId === '65d4ed8c-68d8-4518-bd5c-72ea70e50265') { // variety content
            cat = 11;
        } else if (catId === 'cad9e8fb-60db-476a-9133-7fb1d6896837') { // videojuegos
            cat = 12;
        } else if (catId === 'dd1c4864-b3a8-457e-839f-5cf19c99dd3c') { // valorant
            cat = 13;
        } else if (catId === '83a75d1a-baf6-4537-8bff-d1371e2dcc16') { // deportes
            cat = 14;
        } else if (catId === '2d190db6-06f2-47c2-809e-031ec7757ba5') { // crypto
            cat = 15;
        } else if (catId === '6ed21110-efd4-4903-ad84-05cb9d9f1507') { // league of legends
            cat = 16;
        }


        setFeaturedUsersCategory(cat);
    }

    const handleGoBack = () => {

    }

    return (
        <Box className={`discover-category-page is-${deviceView}`}>
            <Helmet>
                <title>{category && category.hasOwnProperty('id') ? `${t('explore:category_label')}: ${category.text}` : t('explore:category_label')} /
                    Latency</title>
                <html lang={i18n.language}/>
                {i18n.language === "es" ? (
                    <meta name="description"
                          content={`Sigue y descubre todos los eventos relacionados con ${category && category.hasOwnProperty('id') ? category.text : "Latency Agenda"} y estate al día de los últimos acontecimientos.`}/>
                ) : (
                    <meta name="description"
                          content={`Follow and discover all the events related to ${category && category.hasOwnProperty('id') ? category.text : "Latency Agenda"} and stay up to date with the latest updates.`}/>
                )}
            </Helmet>
            <a href="/explore" className="discover-category-page--back">
                <ArrowLeftIcon fontSize={"small"} />
                <div style={{
                    fontSize: '0.85rem',
                    fontWeight: 100
                }}>Volver a Explorar</div>
            </a>

            {(category && category.hasOwnProperty('id')) && (
                <>
                    <Box bgcolor="grid.content" className="discover-category-page--heading">
                        <div className="discover-category-page--heading-image-box">
                            <picture>
                                <source srcSet={`${process.env.REACT_APP_CDN + category.cover_url}.webp`}
                                        type="image/webp"/>
                                <source srcSet={process.env.REACT_APP_CDN + category.cover_url}
                                        type="image/jpeg"/>
                                <img src={process.env.REACT_APP_CDN + category.cover_url}
                                     alt={`${t('explore:category_label')} ${category.text}`}/>
                            </picture>
                        </div>
                        <div className="discover-category-page--heading-info">
                            {parent && (
                                <div className="discover-category-page--heading-parent-title">{parent.text}</div>
                            )}
                            <div
                                className="discover-category-page--heading-title">{t('explore:category_label')}: <h1>{category.text}</h1>
                            </div>
                        </div>
                    </Box>
                    {featuredUsersCategory !== null && (
                        <Box>
                            <h2 className={`super-explore-page--section-heading`}>{t('explore:explore_title_featured_users')}</h2>
                            <CategoryFeaturedUsersComponent catId={featuredUsersCategory}/>
                        </Box>
                    )}
                    <Box className="discover-category-page--wrapper">
                        <Box bgcolor="grid.content" style={{
                            height: "fit-content",
                            borderRadius: "24px"
                        }}>
                            <Calendar categoryId={category.id}/>
                        </Box>
                        <NewAgendaPipelines
                            type={'agenda-cats'}
                            categoryId={category.id}
                            weeklyEvents={weeklySelected}
                            alwaysShowPing={true}
                            embedMode={false}
                            enableBackRef={window.location.pathname}
                            handleCenterOnDay={centerOnDay}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DiscoverCategory;
