import React, {useState, useRef, useEffect} from 'react';
import './navbar-search-form.scss';
import {Box, Popper, Paper, ClickAwayListener, Grow, CircularProgress} from '@material-ui/core';
import TextField from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import services from '../../../services';
import UserResult from './UserResult';
import EventResult from './EventResult';
import {useHistory, useRouteMatch} from 'react-router-dom';
import Scroller from '../../Scroller/Scroller';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Event} from "../../../services/tracking";

const componentName = "NavbarSearchForm";

const NavbarSearchForm = (props) => {
    const [search, setSearch] = useState("")
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})
    const history = useHistory()
    const login = useRouteMatch("/login")
    const register = useRouteMatch("/register")
    const darkTheme = useSelector(state => state.darkTheme)
    const {t} = useTranslation('extraData')
    const [isSearching, setSearching] = useState(false);
    const isEmbedMode = useRouteMatch("/embed/:userNickname/:eventID?");
    const [loadingSpinner, setLoadingSpinner] = useState(false)

    const onInputChange = (e) => {
        setSearch(e.target.value)
        if (!e.target.value) {
            setOpen(false)
        }
    }

    useEffect(() => {
        if (search.length / 3 >= 1 && !isSearching) {
            const delayDebounceFn = setTimeout(() => {
                handleSubmit();
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        } else if (!isSearching) {
            setLoadingSpinner(false)
        }
    }, [search])

    const handleSubmit = () => {
        if (!isSearching) {
            setSearching(true);
            setOpen(true);
            if (search) {
                services.search.find({
                    query: {
                        text: search
                    }
                })
                    .then((resp) => {
                        setSearching(false);
                        setData(filterData(resp))
                        setOpen(true)
                        setLoadingSpinner(false)
                    })
                Event(componentName, "search", search);
            }
        }
    };

    const showResults = () => {
        search && search.length >= 3 ? setOpen(true) : setOpen(false)
    }

    const handleKeyDown = (event) => {
        if (search.length >= 2 && (event.key.length === 1 || event.key === 'Backspace')) {
            setData({})
            setLoadingSpinner(true)
        }
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const filterData = (resp) => {
        let dd = {events: [], users: []}
        for (let a = 0; a < resp.length; a++) {
            if (resp[a].type === "event") {
                dd.events.push(resp[a])
            } else if (resp[a].type === "user") {
                dd.users.push(resp[a])
            }
        }
        return dd
    }

    const redirect = (data) => {
        if (data.type === "user") {
            history.push(`/${data.slug}`)
            Event(componentName, "OpenUser", data.id);
        } else if (data.type === "event") {
            history.push(`/${data.owner.slug}/${data.id}`)
            Event(componentName, "OpenEvent", data.id);
        }
        setOpen(false)
    }

    if (isEmbedMode) {
        return (null);
    }

    return (
        <Box className={"search " + (((login || register)) ? (darkTheme ? "dark" : "light") : "")} ref={anchorRef} display="flex" alignItems="center">
            <Box bgcolor="grid.main" onClick={showResults} className="navbar-search-form" display="flex"
                 flexDirection="row" justifyContent="center" alignItems="center">
                <TextField
                    className="search-input"
                    placeholder={t('placeholder_search')}
                    value={search}
                    onChange={onInputChange}
                    onKeyDown={handleKeyDown}
                />
                {!loadingSpinner && <SearchIcon className="icon-btn" onClick={handleSubmit}/>}
                {loadingSpinner && <CircularProgress size={20}/>}
            </Box>
            {(!isSearching && !loadingSpinner && search.length >= 3) &&
            <Box className="result">
                <Popper className="holder" open={open} anchorEl={anchorRef.current} role={undefined} transition
                        disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow {...TransitionProps}
                              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                            <Box>
                                <ClickAwayListener onClickAway={() => setOpen(false)}>
                                    <Paper className="content" elevation={5}>
                                        <Scroller
                                            count={((data && data.users ? data.users.length : 0) + (data && data.events ? data.events.length : 0))}
                                            itemHeight={60}>
                                            {data && data.users && data.users.length > 0 &&
                                            <Box>
                                                <h3>Users matching "{search}" </h3>
                                                {data.users.map((user, index) =>
                                                    <Box key={index} onClick={() => redirect(user)}>
                                                        <UserResult user={user}/>
                                                    </Box>
                                                )}
                                            </Box>
                                            }
                                            {data && data.events && data.events.length > 0 &&
                                            <Box>
                                                <h3>Events matching "{search}" </h3>
                                                {data.events.map((event, index) =>
                                                    <Box key={index} onClick={() => redirect(event)}>
                                                        <EventResult event={event}/>
                                                    </Box>
                                                )}
                                            </Box>
                                            }
                                            {data && data.events && data.events.length <= 0 && data.users && data.users.length <= 0 &&
                                            <h3>No results found for "{search}" </h3>
                                            }
                                        </Scroller>
                                    </Paper>
                                </ClickAwayListener>
                            </Box>
                        </Grow>
                    )}
                </Popper>
            </Box>
            }

        </Box>
    )
}

export default NavbarSearchForm;
