import CircleAdd from "../../../../../../../assets/icons/actions/add_event_circle.svg";
import Modal from "../../../../../../Modal/Modal";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import services from "../../../../../../../services";
import LandingFeedClip from "./LandingFeedClip";
import LandingFeedClipsForm from "./LandingFeedClipsForm";
import {
    addLandingFeedClip,
    editLandingFeedClip, removeLandingFeedClip,
    setLandingFeedClips
} from "../../../../../../../store/landingFeedClips/actions";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import LandingPreviewFeedClip from "./LandingPreviewFeedClip";
import InfiniteList from "react-infinite-scroll-list";
import LandingFeedClipItem from "./LandingFeedClipItem";
import Box from "@material-ui/core/Box";
import {Event} from "../../../../../../../services/tracking";

const componentName = "LandingFeedClips";

const LandingFeedClips = ({type, userId, allowedManage, handleSubmit, eventWithClipOpen, displayClipInfo}) => {
    const deviceView = useSelector(state => state.deviceView);
    const [openForm, setOpenForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [feedClips, _setFeedClips] = useState([]);
    const feedClipsRef = React.useRef(feedClips);
    const setFeedClips = data => {
        feedClipsRef.current = data;
        _setFeedClips(data);
    };
    const [loadingList, setLoadingList] = useState(false);
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);
    const darkTheme = useSelector(state => state.darkTheme);

    const handleNewFeedClipMessage = (data) => {
        if (data.roomId === userId && data.type === type) {
            setFeedClips([data, ...feedClipsRef.current]);
        }
    };


    const handleRemoveFeedClipMessage = (data) => {
        if (data.roomId === userId && data.type === type) {
            let filtered = feedClipsRef.current.filter(i => i.id !== data.id)
            setFeedClips([...filtered]);
        }
    }


    useEffect(() => {
        setLoadingList(true);
        services.landingFeed.find({
            query: {
                type: type,
                roomId: userId,
                $skip: 0,
                $limit: 10,
                $sort: {
                    createdAt: -1
                }
            }
        })
            .then((resp) => {
                setTotal(resp.total)
                if (resp.data.length > 0) {
                    setFeedClips([...resp.data]);
                    setSkip(skip + 10);
                }
                setLoadingList(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error)
            })
        services.landingFeed.on('created', handleNewFeedClipMessage);
        //services.landingFeed.on('patched', handlePatchFeedClipMessage);
        //services.landingFeed.on('removed', handleRemoveFeedClipMessage);
        return () => {
            services.landingFeed.removeListener('created')
            //services.landingFeed.removeListener('patched')
            //services.landingFeed.removeListener('removed')
        }
    }, [userId]);


    const openWriterModal = () => {
        if (allowedManage) {
            setOpenForm(true);
        }
    }

    const handleDeleteClip = (itemId) => {
        services.landingFeed.remove(itemId, {
            query: {
                roomId: userId
            }
        }).then((res) => {
            handleRemoveFeedClipMessage(res);
        });
        Event(componentName, "DeleteClip", itemId);
    };

    const onSubmitNewForm = (data, onSuccess, onError) => {
        handleSubmit({...data, type: type}, () => {
            onSuccess();
        }, onError)
    };

    const fetchData = () => {
        if (!loadingList) {
            setLoadingList(true);
            services.landingFeed.find({
                query: {
                    type: type,
                    roomId: userId,
                    $skip: skip,
                    $limit: 3,

                    $sort: {
                        createdAt: -1
                    }
                }
            })
                .then((resp) => {
                    if (resp.data.length > 0) {
                        setFeedClips([...feedClipsRef.current, ...resp.data]);
                        setSkip(skip + 3);
                    }
                    setLoadingList(false);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    return (
        <>
            <Box mx={deviceView === "mobile" ? 2 : 0}>
                <div className={"feed-heading"}>
                    {allowedManage &&
                    <img onClick={() => openWriterModal('clip')} src={CircleAdd} alt="add new clip item"/>}
                </div>
                <div className={"landing-feed-clips"}>
                    {loading ?
                        (<div className={"div-loading"}>
                            <CircularProgress size={32} thickness={5} className="input-loader"/>
                        </div>)
                        : <>
                            {loading ? (
                                    <div className={"div-loading"}>
                                        <CircularProgress size={32} thickness={5} className="input-loader"/>
                                    </div>) :
                                <InfiniteList
                                    isLoading={loadingList}
                                    root="container"
                                    isEndReached={feedClips.length === total}
                                    onReachThreshold={fetchData}
                                    containerClassName={`scroller container-feed-article ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}
                                    sentinelClassName="sentinel-feed-article"
                                    containerTagName="div"
                                    sentinelTagName="div"
                                    threshold={0}>
                                    <Grid container spacing={1}>
                                        {feedClips.map((item) =>
                                            <Grid key={item.id} item xs={12} sm={6} md={4} lg={4}>
                                                <LandingPreviewFeedClip item={item} allowRemove={allowedManage}
                                                                        onClickDeleteButton={handleDeleteClip}
                                                                        playVideo={eventWithClipOpen === item.id}
                                                                        showClipInfo={displayClipInfo}
                                                />
                                            </Grid>)}
                                    </Grid>
                                </InfiniteList>
                            }
                        </>}
                </div>
            </Box>
            <Modal
                state={openForm}
                max={"md"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                onClose={() => setOpenForm(false)}>
                <LandingFeedClipsForm onSubmit={onSubmitNewForm} onClose={() => setOpenForm(null)}/>
            </Modal>
        </>
    );
};

LandingFeedClips.defaultProps = {
    type: 'clip',
    displayClipInfo: false
}

export default LandingFeedClips;
