import {Switch, withStyles} from "@material-ui/core";

const SwitchMode = withStyles(theme => ({
    root: {
        width: 80,
        height: 28,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        height: 28,
        padding: 0,
        borderColor: 'none',
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(36.5px)',
            color: theme.palette.buttons.secondary,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.buttons.main,
                borderColor: 'none'
            },
        },
    },
    thumb: {
        width: 42,
        height: 26,
        boxShadow: 'none',
        borderRadius: '43px',
        borderColor: 'none'
    },
    track: {
        height: 28,
        border: 'none',
        borderRadius: '43px',
        opacity: 1,
        backgroundColor: theme.palette.buttons.main,
        borderColor: 'none',
    },
    checked: {},
}))(Switch);

export default SwitchMode;
