import React from "react";
import {IMaskInput} from "react-imask";
import * as IMask from "imask";
import { parse, format } from 'date-fns'

const BirthdayInput = ({ inputRef, onChange, ...other }) => {
    let dateFormat = "dd/MM/yyyy";
  return (
      <IMaskInput
          {...other}
          inputRef={inputRef}  // access to nested input
          mask={Date}
          pattern={'dd/mm/YYYY'}
          min={new Date(1919, 0, 1)}
          max={new Date(2030, 0, 1)}
          parse={(str) => parse(str, dateFormat, new Date())}
          format={(date) => format(date, dateFormat)}
          lazy={true}
          blocks={{
              dd: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 31,
              },
              mm: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 12,
              },
              YYYY: {
                  mask: IMask.MaskedRange,
                  from: 1919,
                  to: 2030,
              }
          }}
          autofix={true}
          onComplete={(value, mask) => {
              onChange(value)
          }}
          // ...and more mask props in a guide

          // input props also available
          placeholder='dd/mm/yyyy'
      />
  )
};

export default BirthdayInput;

