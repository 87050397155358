import React, {Fragment, useEffect, useState} from 'react';
import NotificationSkeleton from "../Skeletons/NotificationSkeleton/NotificationSkeleton";
import Scroller from "../Scroller/Scroller";
import {acceptInvite, markInvitationRead, rejectInvite, setInvitations, markAllInvitationsSeen} from "../../store/invitations/actions";
import InvitationNotification from "./InvitationNotification";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const InvitationsList = ({ handleClose }) => {
    const { t } = useTranslation(['UserMenu', 'notifications']);
    const notifications = useSelector(state => state.invitations);
    const [notiData, setNotiData] = useState(notifications.data);
    const [isEmptyList, setIsEmptyList] = useState(notifications.data.length <= 0);
    const user = useSelector(state => state.user);
    const skull = [1];
    const dispatch = useDispatch();

    useEffect(() => {
        setIsEmptyList(notifications.data.length <= 0);
        setNotiData([...notifications.data]);
    }, [notifications])


    useEffect(() => {
        dispatch(markAllInvitationsSeen());
    }, []);

    return (
        <>
            {isEmptyList && (
                <>
                    <div className="empty-notifications">
                        <p>{t('notifications:empty_list_paragraph_one_invitations')}</p>
                        <p>{t('notifications:empty_list_paragraph_two')}</p>
                    </div>
                    {skull.map((item, index) =>
                        <NotificationSkeleton key={index}/>
                    )}
                </>
            )}
            {notiData.length > 0 &&
            <Scroller count={notiData ? notiData.length : 0} itemHeight={55}>
                {notiData.map((item, index) =>
                    <Fragment key={index}>
                        <InvitationNotification onRead={markInvitationRead}
                                                onAccept={(data) => dispatch(acceptInvite(data))}
                                                onReject={(data) => dispatch(rejectInvite(data))}
                                                data={item} user={user}
                                                onClose={handleClose}/>

                    </Fragment>
                )}
                {notiData && notiData.length > 6 && <Button className="show-more-btn"
                                                                                onClick={() => dispatch(setInvitations())}>{t('notifications:buttons_see_all')}</Button>}
            </Scroller>
            }
        </>
    );
};

export default InvitationsList;