export const setDeviceView = (data) => {
    return dispatch => {
        dispatch({type: 'SET_DEVICE_VIEW', payload: data})
    }
}

export const unsetDeviceView = () => {
    return dispatch => {
        dispatch({type: 'UNSET_DEVICE_VIEW'})
    }
}
