import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import {
    format,
    formatDistance,
    startOfWeek,
    endOfWeek,
    isSameWeek,
    startOfDay,
    endOfDay,
    formatDistanceStrict,
    formatDuration,
    formatDistanceToNow,
    intervalToDuration
} from 'date-fns';
import i18n from "./internationalization";
import {getLocaleDateString} from "./localeDateFormat";

const defaultLocale = en;
const spanish = es
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getLocale = () => {
    let language = i18n.language;
    if (language !== undefined) {
        language = language.split('-')[0];
        switch (language) {
            case 'es':
                return spanish;
            default:
                return defaultLocale;
        }
    } else {
        return (timeZone === "Europe/Madrid" || timeZone === "Atlantic/Canary" || timeZone === "Africa/Ceuta") ? spanish : defaultLocale
    }
};

export const getCurrentTimezone = () => {
    return timeZone;
};

export const formatDate = (date, form) => {
    return format(new Date(date), form, {locale: getLocale(), timeZone: timeZone})
};

export const formatDateLocale = (date) => {
    return format(new Date(date), getLocaleDateString(), {locale: getLocale(), timeZone: timeZone})
};

export const timeAgo = (data) => {
    return formatDistance(new Date(data), new Date())
};

export const ownTimeAgo = (data, unit = null) => {
    return formatDistanceStrict(new Date(data), new Date(), {
        addSuffix: true,
        locale: getLocale(),
        unit,
        timeZone: timeZone
    })
};

export const ownFormatDistance = (data, includeSeconds = false) => {
    return formatDistanceToNow(new Date(data), {
        addSuffix: true,
        locale: getLocale(),
        includeSeconds
    })
};

export const ownFormatDuration = (data, format = ['years', 'weeks', 'days', 'hours', 'minutes', 'seconds'], zero = false, delimiter = ", ") => {
    let duration = intervalToDuration({
        start: data,
        end: new Date(),
    })
    return formatDuration(duration, {format, zero, delimiter, locale: getLocale(), timeZone: timeZone})
};

export const ownStartOfWeek = (date) => {
    return startOfWeek(date, {locale: getLocale()});
};

export const ownEndOfWeek = (date) => {
    return endOfWeek(date, {locale: getLocale()});
};

export const ownStartOfDay = (date) => {
    return startOfDay(date, {locale: getLocale()});
};

export const ownEndOfDay = (date) => {
    return endOfDay(date, {locale: getLocale()});
};

export const ownIsSameWeek = (day, date) => {
    return isSameWeek(day, date, {locale: getLocale()})
}

export const ownGetFormatByLocale = (date) => {
    return getLocale().formatLong
}

export const getDateWithoutTime = (dateTime) => {
    let date = new Date(dateTime);
    date.setHours(0, 0, 0, 0);
    return date;
}
