import React from 'react';
import {useSelector} from "react-redux";
import LandingLiveNow from "./sections/LandingLiveNow/LandingLiveNow";
import LandingHighlights from "./sections/LandingHighlights/LandingHighlights";
import LandingAgendaEventsToday from "../LandingAgenda/LandingAgendaEventsToday/LandingAgendaEventsToday";
import './_landing_cover.scss';
import LandingArticles from "./sections/LandingArticles/LandingArticles";
import LandingLiveScores from "./sections/LandingLiveScores/LandingLiveScores";
import {useTranslation} from "react-i18next";
import {Box, Grid} from "@material-ui/core";
import LandingHighlightsCarousel from "./sections/LandingHighlightsCarousel/LandingHighlightsCarousel";
import LandingUpcomingEvents from "./sections/LandingLiveScores/LandingUpcomingEvents";
import {Event} from "../../../services/tracking";
import LandingCryptoTopEvents from "./sections/LandingCrypto/LandingCryptoUpcoming/LandingCryptoTopEventsComponent";
import LandingSmallTeamsComponent from "../LandingMain/fragments/LandingTeamsComponent/LandingSmallTeamsComponent";
import VlrLandingMatchesComponent from "../LandingMain/fragments/VlrLandingMatchesComponent/VlrLandingMatchesComponent";
import VlrLandingMatchesMobileComponent
    from "../LandingMain/fragments/VlrLandingMatchesComponent/VlrLandingMatchesMobileComponent";
const HtmlToReactParser = require('html-to-react').Parser

const htmlToReactParser = new HtmlToReactParser()

const componentName = "LandingCoverComponent";

const LandingCoverComponent = ({
                                   userId,
                                   userSlug,
                                   onOpenPlayer,
                                   changeTab,
                                   changeCustomTab,
                                   customBlocks,
                                   onOpenClip
                               }) => {
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const {t} = useTranslation('landing');

    const handleOpenPlayer = (playerId) => {
        onOpenPlayer(playerId);
        changeTab(null, t("live_now"));
        window.scrollTo(0, 0)
        Event(componentName, "OpenPlayer", playerId);
    }

    const handleOpenArticle = () => {
        changeTab(null, t("news"));
        window.scrollTo(0, 0)
    }

    const handleOpenClip = (clipId) => {
        onOpenClip(clipId);
        changeTab(null, t("highlights"));
        window.scrollTo(0, 0)
        Event(componentName, "OpenClip", clipId);
    }

    const handleOpenBestClip = (clipId) => {
        onOpenClip(clipId);
        changeCustomTab(t("best_clip"));
        Event(componentName, "OpenBestClip", clipId);
    }

    const handleClickCustom = (index, object) => {
        // 6 base
        changeCustomTab(object.title);
    }

    const handleOpenAgenda = () => {
        changeTab(null, t("agenda"));
        window.scrollTo(0, 0)
    }

    if (userSlug === "valorant-oficial" && 1 === 2) {
        return (
            <Box component="div" className={"landing-valorant-espana"} mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin={"auto"} p={1}
                             borderRadius={16}>
                            <div className={"title-section"}>{t("upcoming_matches")}</div>
                            <LandingUpcomingEvents
                                userId={userId}
                                onClickMore={handleOpenAgenda}
                                darkTheme={darkTheme}
                                max={4}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1}
                             borderRadius={16}>
                            <div className={"scores-wrapper"}>
                                <div className={"title-section"}>{t("last_scores")}</div>
                                <LandingLiveScores userId={userId}
                                                   onClickMore={handleOpenAgenda}
                                                   onOpenPlayer={handleOpenPlayer}
                                                   darkTheme={darkTheme}
                                                   max={4}
                                                   finisheds={true}/>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1}
                             borderRadius={16}>
                            <div className={"scores-wrapper"}>
                                <div className="center-section">
                                    <div className={"title-section"}>
                                        <span className={"is-live-circle"}></span>
                                        <span>{t("matches_live")}</span>
                                    </div>
                                </div>
                                <div className="in-live">
                                    <LandingLiveScores userId={userId}
                                                       onClickMore={handleOpenAgenda}
                                                       onOpenPlayer={handleOpenPlayer}
                                                       darkTheme={darkTheme}
                                                       max={4}
                                                       finisheds={false}/>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                    {/*
                    <Grid item xs={12} md={12}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1} borderRadius={16}>
                            <div className={"title-section"}>{t("highlights")}</div>
                            <LandingHighlightsCarousel userId={userId}
                                                       darkTheme={darkTheme}
                                                       max={6}
                                                       onClickClip={handleOpenClip} />
                        </Box>
                    </Grid>
                    */}
                </Grid>
            </Box>
        );
    } else if (userSlug === "valorant-oficial" && deviceView !== "mobile") {
        return (
            <Box component="div" className={"landing-valorant-espana"} mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <VlrLandingMatchesComponent userId={userId} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LandingSmallTeamsComponent userId={userId} onChangeTab={changeTab} customTeamIds={[
                            '5e3a2c57-f089-4e48-aa09-3ae6362ea08d',
                            'ae6d5576-474e-4b74-8ad9-dfe935519470',
                            '6c311de8-60fe-4385-a512-6050f27fa278',
                            '4410cc89-840b-4fee-ba1e-9a8378065a42', // giants
                        ]} />
                    </Grid>
                </Grid>
            </Box>
        );//VlrLandingMatchesMobileComponent
    } else if (userSlug === "valorant-oficial" && deviceView === "mobile") {
        return (
            <Box component="div" className={"landing-valorant-espana"} mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <VlrLandingMatchesMobileComponent userId={userId} />
                    </Grid>
                </Grid>
            </Box>
        );
    } else if (userSlug === "espacio-crypto") {
        return (
            <Box component="div" className={"landing-espacio-crypto"} mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1}
                             borderRadius={16}>
                            <div className={"scores-wrapper"}>
                                <div className={"title-section"}>{t("featured_events")}</div>
                                <LandingCryptoTopEvents userId={userId}
                                                        type="crypto-event"
                                                        onClickMore={handleOpenAgenda}
                                                        onOpenPlayer={handleOpenPlayer}
                                                        darkTheme={darkTheme}
                                                        max={4}/>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1}
                             borderRadius={16}>
                            <div className={"scores-wrapper"}>
                                <div className={"title-section"}>{t("upcoming_ico")}</div>
                                <LandingCryptoTopEvents userId={userId}
                                                        type="crypto-ico"
                                                        onClickMore={handleOpenAgenda}
                                                        onOpenPlayer={handleOpenPlayer}
                                                        darkTheme={darkTheme}
                                                        max={4}/>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1}
                             borderRadius={16}>
                            <div className={"scores-wrapper"}>
                                <div className={"title-section"}>{t("upcoming_airdrop")}</div>
                                <LandingCryptoTopEvents userId={userId}
                                                        type="crypto-airdrop"
                                                        onClickMore={handleOpenAgenda}
                                                        onOpenPlayer={handleOpenPlayer}
                                                        darkTheme={darkTheme}
                                                        max={4}/>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <div
                    className={`special-landing-cover-container is-${userSlug} ${deviceView === 'browser' ? ' is-desktop' : ''} ${deviceView === 'mobile' ? ' is-mobile' : ''} ${deviceView === 'tablet' ? ' is-tablet' : ''}`}>
                    <div className={"special-landing-cover-section is-latest-news"}>
                        <div className={"special-landing-cover-section-heading"}>{t("latest_news")}</div>
                        <LandingArticles userId={userId} onClickArticle={handleOpenArticle} darkTheme={darkTheme}
                                         max={3}/>
                    </div>
                    <div className={"special-landing-cover-section is-agenda-today"}>
                        <div className={"special-landing-cover-section-heading"}>{t("todays_agenda")}</div>
                        <LandingAgendaEventsToday onClickMore={handleOpenAgenda} userId={userId} darkTheme={darkTheme}/>
                    </div>
                </div>
            </Box>
        )
    }

    return (
        <div
            className={`special-landing-cover-container is-${userSlug} ${deviceView === 'browser' ? ' is-desktop' : ''} ${deviceView === 'mobile' ? ' is-mobile' : ''} ${deviceView === 'tablet' ? ' is-tablet' : ''}`}>
            {userId === "7ac0b720-06a6-4257-97f9-899e63eb6fd5" ? (
                <div className={"special-landing-cover-section is-best-clip"}>
                    <div className={"special-landing-cover-section-heading"}>{t("best_clip")}</div>
                    <LandingHighlights type="best-clip" userId={userId} darkTheme={darkTheme} min={1} max={4}
                                       onClickClip={handleOpenBestClip} showClipInfo={true}/>
                </div>
            ) : (
                <div className={"special-landing-cover-section is-livenow"}>
                    <div className={"special-landing-cover-section-heading"}>{t("top_live_now")}</div>
                    <LandingLiveNow userId={userId} onOpenPlayer={handleOpenPlayer} darkTheme={darkTheme} max={3}/>
                </div>
            )}

            {customBlocks.map((item, index) => {
                if (item.preview_body) {
                    return (
                        <div
                            className={`special-landing-cover-section is-${item.title.toLowerCase().replace(/ /g, '')}`}
                            key={`cover-block-custom-${item.id}`} onClick={() => handleClickCustom(index, item)}>
                            <div className={"special-landing-cover-section-heading"}>{item.title}</div>
                            <div>{htmlToReactParser.parse(item.preview_body)}</div>
                        </div>
                    )
                }
                return null;
            })}
            <div className={"special-landing-cover-section is-highlights"}>
                <div className={"special-landing-cover-section-heading"}>{t("highlights")}</div>
                <LandingHighlights userId={userId} darkTheme={darkTheme} min={1}
                                   max={userId === "7ac0b720-06a6-4257-97f9-899e63eb6fd5" ? 6 : 4}
                                   onClickClip={handleOpenClip}/>
            </div>
            {userId !== "5b755b48-203a-40af-9934-30b4292b468b" &&
            <div className={"special-landing-cover-section is-latest-news"}>
                <div className={"special-landing-cover-section-heading"}>{t("latest_news")}</div>
                <LandingArticles userId={userId} onClickArticle={handleOpenArticle} darkTheme={darkTheme} max={3}/>
            </div>}
            <div className={"special-landing-cover-section is-agenda-today"}>
                <div className={"special-landing-cover-section-heading"}>{t("todays_agenda")}</div>
                <LandingAgendaEventsToday onClickMore={handleOpenAgenda} userId={userId} darkTheme={darkTheme}/>
            </div>
            {(userSlug === "valorant-oficial") && (
                <div className={"special-landing-cover-section is-livescores"}>
                    <div className={"special-landing-cover-section-heading"}>Partidos en vivo</div>
                    <LandingLiveScores userId={userId} onClickMore={handleOpenAgenda} onOpenPlayer={handleOpenPlayer}
                                       darkTheme={darkTheme} max={4}/>
                    <div className={"special-landing-cover-section-heading mt-1"}>Últimos resultados</div>
                    <LandingLiveScores userId={userId} onClickMore={handleOpenAgenda} onOpenPlayer={handleOpenPlayer}
                                       darkTheme={darkTheme} max={4} finisheds={true}/>
                </div>
            )}
        </div>
    );
};

export default LandingCoverComponent;
