import services from '../../services';

export const setOpenedProfileCategories = (userId, onComplete, onError) => {
    return dispatch => {
        services.userCategories.get(userId)
            .then((resp) => {
                dispatch({
                    type: 'SET_OPENED_PROFILE_CATEGORIES',
                    payload: resp
                });
                if(onComplete) {
                    onComplete(resp);
                }
            })
            .catch((error) => {
                if(onError) {
                    onError(error);
                }
            })
    }
}

export const unsetOpenedProfileCategories = (data) => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE_CATEGORIES'})
    }
}

export const subscribeUserCategory = (profileId, categoryId, refresh = true, onComplete, onError) => {
    return dispatch => {
        services.userSubCategory.remove(null, {
            query: {
                ownerId: profileId,
                categoryId: Array.isArray(categoryId) ? {
                    $in: categoryId
                } : categoryId
            }
        }).then((res) => {
            if (refresh) {
                if (onComplete) {
                    dispatch(setOpenedProfileCategories(profileId, onComplete, onError))
                } else {
                    dispatch(setOpenedProfileCategories(profileId))
                }
            } else {
                if (onComplete) {
                    onComplete(res);
                }
            }
        }).catch((err) => {
            if (onError) {
                onError(err)
            }
        })
    }
}

export const unsubscribeUserCategory = (profileId, categoryId, refresh = true, onComplete, onError) => {
    return dispatch => {
        let prepareArr = Array.isArray(categoryId) ? categoryId.map(c => ({
            categoryId: c,
            ownerId: profileId
        })) : null;
        services.userSubCategory.create(Array.isArray(categoryId) ? prepareArr : {
            ownerId: profileId,
            categoryId: categoryId
        }).then((res) => {
            if (refresh) {
                if (onComplete) {
                    dispatch(setOpenedProfileCategories(profileId, onComplete, onError))
                } else {
                    dispatch(setOpenedProfileCategories(profileId))
                }
            } else {
                if (onComplete) {
                    onComplete(res);
                }
            }
        }).catch((err) => {
            if (onError) {
                onError(err)
            }
        })
    }
}
