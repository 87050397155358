import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, CircularProgress, Grid} from "@material-ui/core";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import {closeEventInfoModal} from "../../../../../store/eventInfoModal/actions";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";


const LandingTeamItem = ({user, suscribeCategoryId, onFollowClick, onUnfollowClick, gridItemSettings, onClick}) => {
    const { t } = useTranslation('landing');
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isCategorySubscribed, setIsCategorySubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const darkTheme = useSelector(state => state.darkTheme);
    const [follow, setFollow] = useState(false)
    const handleFollowClick = () => {
        setIsLoading(true);
        onFollowClick(!follow, user.id, suscribeCategoryId, () => {
            setIsCategorySubscribed(true);
            setFollow(true);
            setIsLoading(false);
        }, () => setIsLoading(false))
    }

    const handleUnfollowClick = () => {
        setIsLoading(true);
        onUnfollowClick(user.id, suscribeCategoryId, () => {
            setIsCategorySubscribed(false);
            setIsLoading(false);
        }, () => setIsLoading(false))
    }

    useEffect(() => {
        setLoading(true);
        setFollow(user.follow);
        setIsCategorySubscribed(user.isCategorySubscribed);
        setLoading(false);
    }, [user]);

    const onOpenProfile = (usr) => {
        history.push(`/${usr.slug}`);
        dispatch(closeEventInfoModal());
    }

    return (
        <Grid item {...gridItemSettings.item} data-user-id={user.id}>
            {loading && (
                <Box py={2}
                     px={1}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     className={`landing-teams__item ${darkTheme ? 'dark' : ''}`}>
                    <CircularProgress thickness={5} className="loader"/>
                </Box>)}
            {!loading && (<>
                <Box py={gridItemSettings?.boxPadding.py || 2}
                     px={gridItemSettings?.boxPadding.px || 2}
                     display="flex"
                     className={`landing-teams__item ${darkTheme ? 'dark' : ''}`}>
                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center"
                         className="landing-teams__item-first-column">
                        <a href={`/${user.slug}`}
                           onClick={(e) => {
                               e.preventDefault();
                               e.stopPropagation();
                               onOpenProfile(user)
                           }}
                           className="user">
                            <img src={user.avatar} alt={user.nickname}/>
                        </a>
                    </Box>
                    {gridItemSettings.hasOwnProperty('team') && gridItemSettings.team.hasOwnProperty('hideContentName') && gridItemSettings.team.hideContentName === true ? (<></>) : (
                        <Box
                            className="landing-teams__item-second-column"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start">
                            <a href={`/${user.slug}`}
                               onClick={(e) => {
                                   e.preventDefault();
                                   e.stopPropagation();
                                   onOpenProfile(user)
                               }}
                               className="user">
                                <h2>{user.nickname}</h2>
                            </a>
                            <p>#valorant</p>
                        </Box>
                    )}

                    <Box className="landing-teams__item-third-column"
                         display="flex"
                         justifyContent="flex-end"
                         alignItems="center">
                        {isLoading ? (
                            <Button className='unfollow'>
                                <CircularProgress color="white" size={20} thickness={5} className="loader"/>
                            </Button>
                        ) : (
                            <>
                                {(follow && isCategorySubscribed) ?
                                    <Button onClick={handleUnfollowClick}
                                            className='unfollow'>
                                        <NotificationsActiveOutlinedIcon fontSize={"small"}/>
                                        <span>{t('teams_btn_is_activated')}</span>
                                    </Button>
                                    :
                                    <Button onClick={handleFollowClick}>
                                        <NotificationsOutlinedIcon fontSize={"small"} />
                                        <span>{t('teams_btn_activate')}</span>
                                    </Button>
                                }
                            </>
                        )}
                    </Box>
                </Box>
            </>)}
        </Grid>
    );
}

LandingTeamItem.defaultProps = {
    gridItemSettings: {
        item: {
            xs: 12,
            lg: 4,
            md: 4
        },
        boxPadding: {
            py: 2,
            px: 2
        },
        team: {
            hideContentName: false
        }
    }
}

export default LandingTeamItem;
