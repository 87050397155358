import React, { Fragment } from 'react';
import ProfileEventCard from '../../Event/ProfileEventCard/ProfileEventCard';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import PipeSkeleton from "../../Skeletons/PipeSkeleton/PipeSkeleton";

const UserEventsPipe = ({OpenInfo}) => {
  const { t } = useTranslation('extraData');
  const openedProfileNextEvents = useSelector(state => state.openedProfileNextEvents)

  return (
    <Fragment>
      {openedProfileNextEvents && openedProfileNextEvents.data.length > 0 && openedProfileNextEvents.data.map((event, index) =>
                                                                                                                <div
                                                                                                                  key={`user-events-pipe-item${index}`}
                                                                                                                  onClick={() => OpenInfo(event)}
                                                                                                                  className="user-event-pipe-item">
                                                                                                                  <ProfileEventCard
                                                                                                                    event={event}/>
                                                                                                                </div>
      )}
      {openedProfileNextEvents && openedProfileNextEvents.data.length === 0 && (
          <div className="event-pipe-empty--text">
            {t('landing_empty_content')}
          </div>
      )}
    </Fragment>
  )
}

export default UserEventsPipe;
