import React, {useEffect, useState, Fragment} from 'react';
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import UserFollowersList from "../../../Inputs/UserFollowersList";
import CustomButton from "../../../CustomButton/CustomButton";
import './roles.scss';
import services from "../../../../services";
import {showSnackbar} from "../../../../store/snackbar/actions";
import {useDispatch} from "react-redux";
import Loader from "../../../Loader/Loader";
import {Link} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";

const LandingRolesForm = () => {
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [addRoleUser, setAddRoleUser] = useState(null);
    const [blockedButton, setBlockedButton] = useState(true);
    const [firstTime, setFirstTime] = useState(true);
    const {t} = useTranslation('settings');

    useEffect(() => {
        setLoading(true);
        setAddRoleUser(null);
        services.userLandingRoles.find().then(res => {
            if (res.total > 0) {
                setFirstTime(false);
                setRoles(res.data);
            }
            setLoading(false);
        }).catch(err => {
            dispatch(showSnackbar({message: err.message, type: "error"}));
        });
        setFirstTime(true);
    }, []);

    const submit = () => {
        if(!blockedButton) {
            if(addRoleUser && addRoleUser.hasOwnProperty('id')) {
                setLoadingAdd(true);
                services.userLandingRoles.create({
                    assignedUserId: addRoleUser.id
                }).then((res) => {
                    setRoles([...roles, res]);
                    setAddRoleUser(null);
                    setBlockedButton(true);
                    setLoadingAdd(false);
                }).catch((err) => {
                    dispatch(showSnackbar({message: err.message, type: "error"}));
                    setLoadingAdd(false);
                    setBlockedButton(false);
                });
            }
        }
    };

    const deleteRow = (itemId) => {
        services.userLandingRoles.remove(itemId).then((res) => {
            let rolesFiltered = roles.filter(e => e.id !== itemId);
            setRoles(rolesFiltered);
            if(rolesFiltered.length === 0) {
                setFirstTime(true);
                setAddRoleUser(null);
            }
        });
    };

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_landing_roles')}</div>
                <div className="settings-content-title-helper">{t('section_landing_roles_helper')}</div>
            </div>
            <div className="settings-content-main settings-landing-roles">
                {loading && (<Loader/>)}
                {!loading && (
                    <Fragment>
                        <Box mt={!firstTime ? 2 : 0} mb={!firstTime ? 2 : 0} className="settings-landing-roles-form-add">
                            <UserFollowersList
                                initialData={addRoleUser}
                                handleChange={(data) => {
                                    setAddRoleUser(data);
                                    setBlockedButton(false);
                                }}
                            />

                            <CustomButton className="submit-btn large" loading={loadingAdd} disabled={blockedButton}
                                          onClick={submit}>Añadir</CustomButton>
                        </Box>

                        {roles && roles.length > 0 && (
                            <Box className="landing-roles-table">
                                <Box className="landing-roles-table-header">
                                    <span>Usuario</span>
                                    <span>Acciones</span>
                                </Box>
                                <Box className="landing-roles-table-body">
                                    {roles && roles.map(item => (
                                        <Box className="landing-roles-table-item">
                                            <Link className="landing-roles-table-item--user" to={`/${item.assignedUser.slug}`}>
                                                <div className="avatar">
                                                    <img src={item.assignedUser.avatar}
                                                         alt={item.assignedUser.nickname}/>
                                                </div>
                                                <div className="item-user-info">
                                                    <div className="user-nickname">{item.assignedUser.nickname}</div>
                                                    <div
                                                        className="user-fullname">{item.assignedUser.firstName} {item.assignedUser.lastName}</div>
                                                </div>
                                            </Link>
                                            <Box className="landing-roles-table-item--actions">
                                                <CustomButton className="delete-btn wide e-small"
                                                              onClick={() => { deleteRow(item.id); }}>
                                                    <DeleteIcon className="icon"/>
                                                    <span>Borrar permiso</span>
                                                </CustomButton>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}

                    </Fragment>
                )}

            </div>
        </div>
    );
};

export default LandingRolesForm;
