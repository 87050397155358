import React, {useEffect, useLayoutEffect} from 'react';
import { withRouter } from "react-router";
import { showSnackbar } from "../../store/snackbar/actions";
import { useDispatch } from "react-redux";

const SupervisorComponent = ({ match, location, history }) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.prerenderReady = true;
  }, []);

  useEffect(() => {
    if(location.hash.startsWith("#error")) {
      dispatch(showSnackbar({message: decodeURIComponent(location.hash.substring(7, location.hash.length)), type: "error"}));
    }
  }, [location]);

  return (
    null
  );
};

export default withRouter(SupervisorComponent);
