import React, {Fragment, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Box, Tabs, Tab, Fade} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Scroller from '../../Scroller/Scroller';
import UserCard from '../../User/UserCard/UserCard';
import {leaveEventGuest} from "../../../store/user/actions";
import {setOpenedEvent} from "../../../store/openedEvent/actions";
import Modal from '@material-ui/core/Modal';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const EventTabs = ({currentTab, changeTab}) => {
        const event = useSelector(state => state.event);
        const user = useSelector(state => state.user);
        const deviceView = useSelector(state => state.deviceView);
        const isOwner = !!(user && user.id && event && event.owner.id && user.id === event.owner.id);
        const darkTheme = useSelector(state => state.darkTheme);
        const messages = useSelector(state => state.messages);
        const [videoModal, setVideoModal] = useState(false);
        const [comments, setComments] = useState(messages);
        const {t} = useTranslation(['extraData', 'eventForm']);
        const dispatch = useDispatch();
        const [guests, setGuests] = useState([]);
        const settingsGeneral = useSelector(state => state.settings.general);
        const [formattedDescription, setFormattedDescription] = useState("");

        useEffect(() => {
            setComments(messages);
        }, [messages])

        useEffect(() => {
            if(event.guests) {
                if(isOwner) {
                    setGuests(event.guests)
                } else {
                    setGuests(event.guests.filter(i => i.event_guest.isConfirmed));
                }
            }

            const regex_es = /(\[es](.*)\[\/es])/gm;
            const regex_en = /(\[en](.*)\[\/en])/gm;
            if(event && event.hasOwnProperty('description') && event.description && event.description.length > 0) {
                let handle_desc_es = regex_es.exec(event.description);
                let handle_desc_en = regex_en.exec(event.description);
                if(handle_desc_es && handle_desc_es.length > 0 && handle_desc_en && handle_desc_en.length > 0) {
                    if(settingsGeneral.language === 'es') {
                        let desc = event.description.replace(handle_desc_es[0], handle_desc_es[2]).replace(handle_desc_en[0], '');
                        setFormattedDescription(desc);
                    } else {
                        let desc = event.description.replace(handle_desc_en[0], handle_desc_en[2]).replace(handle_desc_es[0], '')
                        setFormattedDescription(desc);
                    }
                } else {
                    setFormattedDescription(event.description);
                }
            }


        }, [event]);

        const handleCloseVideoModal = () => {
            setVideoModal(false);
        };

        return (
            <Fragment>
                <Box className="tabs">
                    <Tabs value={currentTab} onChange={changeTab} classes={{indicator: 'indicator'}} centered
                          className="menu">
                        <Tab label={t('extraData:labels_description')}
                             classes={{selected: darkTheme ? 'select-dark' : 'select-light'}} value="description"/>
                        {event.assistants.length > 0 && <Tab label={t('extraData:labels_attendees')}
                                                             classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}
                                                             value="assistants"/>}
                        {guests.length > 0 && <Tab label={t('extraData:labels_guests')}
                                                         classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}
                                                         value="guests"/>}
                    </Tabs>
                    {currentTab === "description" &&
                    <Box bgcolor="grid.content" className="section">
                        <Scroller itemHeight={55}>
                            {event && event.hasOwnProperty('description') && (event.description === null || event.description.length === 0) && (
                                <Box className="tab-empty-content" height="100%" display="flex" alignItems="center"
                                     justifyContent="center" flex={1}>
                                    <span>{t('extraData:event_no_description')}</span>
                                </Box>)}
                            <div dangerouslySetInnerHTML={{__html: formattedDescription}}/>
                        </Scroller>
                    </Box>
                    }
                    <Modal open={videoModal} onClose={handleCloseVideoModal}
                           className={"video_modal"}>
                        <Fade in={videoModal}>
                            <div className={`video_wrapper  
                        ${darkTheme ? "is-dark" : ''} 
                        ${deviceView === 'mobile' || deviceView === 'tablet' ? "mobile" : ''}`}>
                                <IconButton size="small" aria-label="close" color="inherit" className="close-icon"
                                            onClick={handleCloseVideoModal}>
                                    <CloseIcon/>
                                </IconButton>
                                <div className="iframe_wrapper">
                                    <iframe
                                        src={event.video_external_url}
                                        frameBorder="0" allowFullScreen/>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    {currentTab === "assistants" &&
                    <Box bgcolor="grid.content" className="section">
                        <Scroller itemHeight={55} count={event.assistants.length}>
                            {event && event.hasOwnProperty('assistants') && event.assistants.length === 0 && (
                                <Box className="tab-empty-content" height="100%" display="flex" alignItems="center"
                                     justifyContent="center" flex={1}>
                                    <span>{t('extraData:event_empty_pingers')}</span>
                                </Box>)}
                            {event && event.assistants.map((guest, i) =>
                                <UserCard key={i} interestsCount={3} toProfile={true} eventInfo={true} user={user}
                                          profile={guest}/>
                            )}
                        </Scroller>
                    </Box>
                    }
                    {currentTab === "guests" &&
                    <Box bgcolor="grid.content" className="section">
                        <Scroller count={guests.length} itemHeight={55}>
                            {event && event.hasOwnProperty('guests') && guests.length === 0 && (
                                <Box className="tab-empty-content" height="100%" display="flex" alignItems="center"
                                     justifyContent="center" flex={1}>
                                    <span>{t('extraData:event_empty_guests')}</span>
                                </Box>)}
                            {guests.map((guest, i) =>
                                <Box className="wrap-users-guest" key={`wrap-user-guest-${i}`}>
                                    <UserCard interestsCount={3} toProfile={true} eventInfo={true} user={user}
                                              profile={guest} displayed="event-info-guests" onClickLeaveEvent={() => {
                                        dispatch(leaveEventGuest(guest.event_guest.id, () => {
                                            dispatch(setOpenedEvent(event.id))
                                        }))
                                    }}/>
                                    {isOwner && <Box className="invitation-status"
                                                     bgcolor={"background.default"}>{t("EventForm:invitation_status")}: <span
                                        className={guest.event_guest.isConfirmed ? 'status-ok' : 'status-pending'}>{guest.event_guest.isConfirmed ? t("EventForm:accepted") : t("EventForm:pending")}</span></Box>}
                                </Box>
                            )}
                        </Scroller>
                    </Box>
                    }
                </Box>
            </Fragment>
        )
    }
;

export default EventTabs;
