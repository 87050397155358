import React, {useCallback, useMemo, useState} from 'react';
import { useDropzone } from "react-dropzone";
import Box from "@material-ui/core/Box";
import Modal from "../../../Modal/Modal";
import ImageCropper from "../../../ImageCropper/ImageCropper";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: '312px',
    textAlign: 'center'
};

const baseStyleDark = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#282828',
    borderStyle: 'dashed',
    backgroundColor: '#232323',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: '312px',
    textAlign: 'center'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const UploadAvatar = ({avatar, onChangeAvatar, onComplete}) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const [crop, setCropper] = useState(false);
    const deviceView = useSelector(state => state.deviceView);
    const {t} = useTranslation('completeSignup');

    const onDrop = useCallback(acceptedFiles => {
        onChangeAvatar(URL.createObjectURL(acceptedFiles[0]));
        setCropper(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop
    });

    const style = useMemo(() => ({
        ...(darkTheme ? baseStyleDark : baseStyle),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
        darkTheme
    ]);

    return (
        <Box className='complete-signup-upload_avatar'>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <img className={'avatar'} src={avatar ? avatar : "/images/defaultAvatar.png"} alt="avatar" />
                <p className="dropzone-HelperText">{t('personalize_avatar_dropzone_label')}</p>
            </div>

            <Modal
                state={crop}
                persist={true}
                max={"sm"}
                radius={false}
                fullScreen={deviceView === "mobile"}
                onClose={() => setCropper(false)}>

                <ImageCropper
                    round={true}
                    cropper={{
                        width: 384,
                        height: 384
                    }}
                    poster={avatar}
                    className="profile"
                    onCrop={(img) => {
                        onChangeAvatar(img);
                        onComplete(true);
                        setCropper(false);
                    }}
                    onClose={() => {
                        setCropper(false)
                    }} />
            </Modal>

        </Box>
    );
};

export default UploadAvatar;
