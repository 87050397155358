const initialState = {
    loadingSpinner: true,
    loaderErrorTimeout: false
};

const loaderReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SHOW_LOADER':
            return state = initialState;
        case 'HIDE_LOADER':
            return {...initialState, loadingSpinner: false};
        case 'SET_LOADER_TIMEOUT':
            return {...initialState, loaderErrorTimeout: true};
        case 'REMOVE_LOADER_TIMEOUT':
            return {...initialState, loaderErrorTimeout: false};
        default:
            return state
    }
}

export default loaderReducer;
