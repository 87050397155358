import React, {useEffect, useState} from 'react';
import {Badge, Box, Button, Menu, MenuItem, Divider} from '@material-ui/core';
import CustomLink from '../CustomLink/CustomLink';
import ThemeToggle from './ThemeToggle';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../store/user/actions';
import Notifications from '../Notifications/Notifications';
import {useHistory, Link, useRouteMatch} from 'react-router-dom';
import {openAddEventModal} from '../../store/addEventModal/actions';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {useTranslation} from 'react-i18next';
import {openAddAccountModal} from "../../store/addNewAccountModal/actions";
import MultiAccountItem from "../ManageAccounts/MultiAccountItem";
import client from "../../services/client";
import {openManageMultiAccountModal} from "../../store/manageMultiAccountModal/actions";
import {hideLoader, showLoader} from "../../store/loader/actions";
import {Event} from "../../services/tracking";

const componentName = "UserMenu";

const UserMenu = () => {
    const darkTheme = useSelector(state => state.darkTheme);
    const [menu, openMenu] = useState(false);
    const anchor = document.getElementById('menu-place');
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const history = useHistory();
    const calendar = useRouteMatch('/agenda');
    const deviceView = useSelector(state => state.deviceView);
    const {t} = useTranslation(['UserMenu']);
    const multiAccount = useSelector(state => state.multiAccount);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        setAccounts(multiAccount);
    }, [multiAccount]);

    const toggleMenu = () => {
        Event(componentName, "ToggleMenu", !menu ? "CloseMenu" : "OpenMenu");
        openMenu(!menu);
    };

    const logoutUser = () => {
        dispatch(logout());
    };

    const goToProfile = () => {
        history.push(`/${user.slug}`);
        toggleMenu();
    };

    const editProfile = () => {
        history.push("/edit/profile");
        toggleMenu();
    };

    const editSettings = () => {
        history.push("/edit/settings");
        toggleMenu();
    };

    const openForm = () => {
        dispatch(openAddEventModal());
    };

    const onClickMultiAccItem = (token) => {
        dispatch(showLoader());
        client.authentication.setAccessToken(token).then(res => {
            window.location.reload(false);
        }).catch(err => {
            dispatch(hideLoader());
        })
    };

    const openAddAccount = () => {
        dispatch(openAddAccountModal());
        toggleMenu();
    };

    const openManageMultiAccount = () => {
        dispatch(openManageMultiAccountModal());
        toggleMenu();
    };

    return (
        <Box color="primary" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
            {deviceView === "browser" && <Link to="/agenda"
                                               className={"link light-link " + (calendar && calendar.isExact ? "active" : "")}>{t('menu_label_calendar')}</Link>}
            {deviceView === "browser" && (
                <CustomLink className={'explore-link link light-link'} to={'/explore'}>
                    <Badge variant={"dot"} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }} badgeContent={"new"} classes={{badge: "badge"}}>
                        <span>{t('menu_label_explore')}</span>
                    </Badge>
                </CustomLink>
            )}
            {deviceView === "browser" &&
            <Button className={`link event-link highlightAddEvent is-${darkTheme ? 'dark' : 'light'}`}
                    onClick={openForm}>  {t('menu_label_add_event')} </Button>}
            {deviceView !== "browser" &&
            <Box className={`highlightAddEvent iconized is-${darkTheme ? 'dark' : 'light'}`} display="flex" flexDirection="row" justifyContent="center"
                 alignItems="center"><AddCircleOutlineIcon onClick={openForm} className={"add-icon"}/></Box>}
            <Notifications/>
            {deviceView === "browser" && <ThemeToggle/>}
            <img className="profile-menu-btn" onClick={toggleMenu}
                 src={user.avatar ? user.avatar : "/images/defaultAvatar.png"} alt="user avatar"/>
            <span id="menu-place"></span>
            <Menu
                keepMounted
                anchorEl={anchor}
                open={menu}
                classes={{paper: 'user-dropdown'}}
                onClose={toggleMenu}>

                <MenuItem onClick={goToProfile}> {t('menu_label_profile')} </MenuItem>
                <MenuItem onClick={editProfile}> {t('menu_label_edit_profile')} </MenuItem>
                <MenuItem onClick={editSettings}> {t('menu_label_settings')} </MenuItem>
                <Divider/>
                {accounts.filter(item => item.id !== user.id).map((account) =>
                    <MenuItem key={`ma-item-${account.id}`} onClick={() => onClickMultiAccItem(account.token)}>
                        <MultiAccountItem account={account}/>
                    </MenuItem>
                )}
                <MenuItem onClick={openAddAccount}> {t('menu_label_add_account')} </MenuItem>
                {accounts.length > 1 &&
                <MenuItem onClick={openManageMultiAccount}> {t('menu_label_manage_accounts')} </MenuItem>}
                <Divider/>
                <MenuItem onClick={logoutUser}> {t('menu_label_logout')} </MenuItem>
            </Menu>
        </Box>
    )
}

export default UserMenu;
