const initialState = false;

const manageMultiAccountModal = (state = initialState, action) => {
  switch(action.type){
    case 'OPEN_MANAGE_MULTI_ACCOUNT_MODAL':
      return state = true;
    case 'CLOSE_MANAGE_MULTI_ACCOUNT_MODAL':
      return state = initialState;
    default:
      return state;
  };
};

export default manageMultiAccountModal;
