import React, {useState} from 'react';
import LoginForm from "../Forms/LoginForm/LoginForm";
import {Box} from "@material-ui/core";
import './form.scss';
import client from "../../services/client";
import {useDispatch, useSelector} from "react-redux";
import {saveNewAccount} from "../../store/accounts/actions";
import {showLoader} from "../../store/loader/actions";
import {useTranslation} from "react-i18next";

const AddNewAccountForm = ({onClose}) => {
    const {t} = useTranslation('UserMenu')
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    const onChange = () => {
        setError(false);
    };

    const handleSubmit = (params) => {
        const {email, password} = params;
        setLoading(true);
        client.authentication.getAccessToken().then((res) => {
            dispatch(saveNewAccount({
                'id': currentUser.id,
                'name': currentUser.nickname,
                'avatar': currentUser.avatar,
                'token': res
            }));
            client.authenticate({strategy: 'local', email, password})
                .then((res) => {
                    dispatch(showLoader());
                    onClose();
                    dispatch(saveNewAccount({
                        'id': res.user.id,
                        'name': res.user.nickname,
                        'avatar': res.user.avatar,
                        'token': res.accessToken
                    }));
                    window.location.reload(false);
                }).catch((error) => {
                setLoading(false);
                setError(true);
            })
        });

    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center"
             className="add-new-acc-form">
            <h1>{t("UserMenu:menu_label_add_account")}</h1>
            <LoginForm loading={loading} onSubmit={handleSubmit} onChange={onChange} error={error}/>
        </Box>
    );

};

export default AddNewAccountForm;
