import React, {Fragment, useEffect, useState} from "react";
import './trustedlist.scss';
import UserFollowersList from "../../../Inputs/UserFollowersList";
import CategoriesList from "../../../Inputs/CategoriesList";
import CustomButton from "../../../CustomButton/CustomButton";
import services from "../../../../services";
import Loader from "../../../Loader/Loader";
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import {showSnackbar} from "../../../../store/snackbar/actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const TrustedListForm = () => {
    const { t } = useTranslation(['settings']);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [firstTime, setFirstTime] = useState(true);
    const [showAdd, setShowAdd] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [addTrustedListUserInput, setAddTrustedListUserInput] = useState(null);
    const [addTrustedListCategoryInput, setAddTrustedListCategoryInput] = useState(null);
    const [userCategories, setUserCategories] = useState([]);
    const [trustedList, setTrustedList] = useState([]);
    const [blockedButton, setBlockedButton] = useState(true);

    useEffect(() => {
        setLoading(true);
        setAddTrustedListUserInput(null);
        setAddTrustedListCategoryInput(null);
        setUserCategories([]);
        services.trustedList.find().then((res) => {
            if (res.total > 0) {
                setFirstTime(false);
                setTrustedList(res.data);
            }
            setLoading(false);
        });
    }, []);

    const submit = () => {
        if(!blockedButton) {
            if(addTrustedListUserInput && addTrustedListUserInput.hasOwnProperty('id')) {
                setLoadingAdd(true);
                services.trustedList.create({
                    trustedUserId: addTrustedListUserInput.id,
                    categoryId: addTrustedListCategoryInput.id
                }).then((res) => {
                    setFirstTime(false);
                    setTrustedList([...trustedList, res]);
                    setShowAdd(false);
                    setAddTrustedListUserInput(null);
                    setAddTrustedListCategoryInput(null);
                    setUserCategories([]);
                    setLoadingAdd(false);
                    setBlockedButton(true);
                }).catch((err) => {
                    dispatch(showSnackbar({message: err.message, type: "error"}));
                    setLoadingAdd(false);
                    setBlockedButton(false);
                });
            }
        }
    };

    const loadUserCategories = (data) => {
        if (data === null) {
            setUserCategories([]);
        } else {
            services.userCategories.get(data.id)
                .then((resp) => {
                    setUserCategories([
                        {
                            id: null,
                            name: '-- Todas las categorías --'
                        }, ...resp]);
                    setAddTrustedListCategoryInput({id: null, name: '-- Todas las categorías --'});
                    setBlockedButton(false);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    const deleteRow = (itemId) => {
        services.trustedList.remove(itemId).then((res) => {
            let trustedFiltered = trustedList.filter(e => e.id !== itemId);
            setTrustedList(trustedFiltered);
            if(trustedFiltered.length === 0) {
                setFirstTime(true);
                setShowAdd(false);
                setAddTrustedListUserInput(null);
                setAddTrustedListCategoryInput(null);
                setUserCategories([]);
            }
        });
    };

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_invitations_trustedlist')}</div>
                <div className="settings-content-title-helper">{t('section_invitations_trustedlist_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-trustedlist">
                {loading && (<Loader/>)}
                {!loading && (
                    <Fragment>
                        {(firstTime && !showAdd) && (
                            <div className="empty-list">Todavía no has creado ningún registro, <span
                                onClick={() => setShowAdd(true)}>empieza ahora</span></div>)}
                        {!showAdd && !firstTime && (
                            <Box mt={1} mb={showAdd ? 1 : 0} display="flex" justifyContent="flex-end">
                                <CustomButton className="submit-btn" onClick={() => setShowAdd(true)}>Añadir regla</CustomButton>
                            </Box>
                        )}
                        {showAdd && (
                            <Box mt={!firstTime ? 2 : 0} mb={!firstTime ? 2 : 0} className="settings-content-trustedlist-form-add">
                                <UserFollowersList
                                    handleChange={(data) => {
                                        setAddTrustedListUserInput(data);
                                        loadUserCategories(data);
                                    }}
                                />
                                <CategoriesList
                                    initialData={userCategories}
                                    disabled={userCategories.length === 0}
                                    handleChange={(data) => {
                                        setAddTrustedListCategoryInput(data);

                                    }}/>
                                <CustomButton className="submit-btn large" loading={loadingAdd} disabled={blockedButton}
                                              onClick={submit}>Añadir</CustomButton>
                            </Box>
                        )}
                        {trustedList && trustedList.length > 0 && (
                            <Box className="trustedlist-table">
                                <Box className="trustedlist-table-header">
                                    <span>Usuario</span>
                                    <span>Categoría</span>
                                    <span>...</span>
                                </Box>
                                <Box className="trustedlist-table-body">
                                    {trustedList && trustedList.map(item => (
                                        <Box className="trustedlist-table-item">
                                                <Link className="trustedlist-table-item--user" to={`/${item.trustedUser.slug}`}>
                                                    <div className="avatar">
                                                        <img src={item.trustedUser.avatar}
                                                             alt={item.trustedUser.nickname}/>
                                                    </div>
                                                    <div className="item-user-info">
                                                        <div className="user-nickname">{item.trustedUser.nickname}</div>
                                                        <div
                                                            className="user-fullname">{item.trustedUser.firstName} {item.trustedUser.lastName}</div>
                                                    </div>
                                                </Link>
                                            <Box className="trustedlist-table-item--category">
                                                <div>{item.categoryId === null ? "-- Todas las categorías --" : item.category.name}</div>
                                            </Box>
                                            <Box className="trustedlist-table-item--actions">
                                                <CustomButton className="delete-btn wide e-small"
                                                              onClick={() => { deleteRow(item.id); }}>
                                                    <DeleteIcon className="icon"/>
                                                    <span>Borrar regla</span>
                                                </CustomButton>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    )
};

export default TrustedListForm;
