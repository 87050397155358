import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Grid, Box } from '@material-ui/core';
import {formatDate} from '../../helpers/dateFormatter';
import {useTranslation} from 'react-i18next';

const CalendarHeader = (props) => {
    const monthFormat = "MMMM"
    const yearFormat = "yyyy"
    const {t} = useTranslation('agenda')

    return (
        <Grid container direction="row" justifyContent="center">
            <Grid item xs={2} container direction="row" justifyContent="center">
                <NavigateBeforeIcon className="icon" onClick={props.onPrevClick} />
            </Grid>
            <Grid item xs={8} container direction="column" justifyContent="center" alignItems="center">
                <span className="month-year box-title">{formatDate(props.month, monthFormat)} {formatDate(props.month, yearFormat)}</span>
                <Box onClick={props.onToday} bgcolor="grid.content" className="today"> {t('toolbar_today')} </Box>
            </Grid>
            <Grid item xs={2} container direction="row" justifyContent="center">
                <NavigateNextIcon className="icon" onClick={props.onNextClick} />
            </Grid>
        </Grid>
    )
}

export default CalendarHeader;
