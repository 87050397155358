import React from "react";
import {useTranslation} from "react-i18next";

const SettingsPrivacyForm = () => {
    const {t} = useTranslation('settings');

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_settings_privacy')}</div>
                <div className="settings-content-title-helper">{t('section_settings_privacy_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-general">
                <p>{t('helper_under_construction')}</p>
            </div>
        </div>
    );
};

export default SettingsPrivacyForm;
