const initailState = new Date()

const selectedDateReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_SELECTED_DATE':
            return state = action.payload;
        case 'UNSET_SELECTED_DATE':
            return state = initailState
        default:
            return state
    }
}

export default selectedDateReducer;
