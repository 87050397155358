import {getCurrentTimezone} from "../../helpers/dateFormatter";

const initialState = {
    start: null,
    end: null,
    timezone: getCurrentTimezone(),
    bundle: true,
    paginate: false
};

const agendaWeeklySelectedReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_AGENDA_WEEKLY_SELECTED':
            return action.payload;
        case 'UNSET_AGENDA_WEEKLY_SELECTED':
            return state = initialState
        default:
            return state
    }
}

export default agendaWeeklySelectedReducer;
