/* eslint no-undefined: 0 */

export default function getStorage() {
    const storageKey = '_localstorage_test_';
    let retrieved;
    try {
        if (window.localStorage) {
            window.localStorage.setItem(storageKey, storageKey);
            retrieved = window.localStorage.getItem(storageKey);
            window.localStorage.removeItem(storageKey);
        }
    } catch (e) {
        // ... ignore
    }

    if (storageKey === retrieved) {
        return window.localStorage;
    }

    return {
        data: {},
        setItem: function (key, val) {
            this.data[key] = val;
        },
        removeItem: function (key) {
            delete this.data[key];
        },
        getItem: function (key) {
            const value = this.data[key];
            return value === undefined ? null : value;
        }
    };
}
