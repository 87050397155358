import {TextField} from "@material-ui/core";
import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import CustomButton from '../../CustomButton/CustomButton';

const StageTwo = (props) => {
    const { onChange, onNext } = props;
    const {t} = useTranslation(['validations, frontPage']);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const onPasswordChange = (e) => {
        setPassword(e.target.value)
        !e.target.value ? setPasswordError(true) : setPasswordError(false)
    }

    const onConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value)
        !e.target.value ? setPasswordError(true) : setPasswordError(false)
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        if(password){
            if(confirmPassword){
                if(password === confirmPassword){
                    onChange(password);
                    onNext(3);
                }else{
                    // password no match
                }
            }else{
                setConfirmPasswordError(true);
            }
        }else{
            setPasswordError(true);
        }
    };

    return (
        <Fragment>
            <TextField
                className="password"
                error={passwordError}
                type="password"
                label={t('validations:forms_new_password')}
                value={password}
                variant="outlined"
                onChange={onPasswordChange}
                onKeyDown={handleKeyDown} />

            <TextField
                className="password"
                error={confirmPasswordError}
                type="password"
                label={t('validations:forms_confirm_new_password')}
                value={confirmPassword}
                variant="outlined"
                onChange={onConfirmPasswordChange}
                onKeyDown={handleKeyDown} />

            <CustomButton className="custom-button submit-btn" color="primary" onClick={handleSubmit}>{t('validations:forms_btn_next_step')}</CustomButton>
        </Fragment>
    )
};

export default StageTwo;
