import services from '../../services/';
import client from '../../services/client';
import {showLoader, hideLoader, loaderTimeoutError} from '../loader/actions';
import {updateApp, updateCalendar} from '../app/actions';
import {unsetRecommendations} from '../recommendations/actions';
import {unsetDiscoverUsers} from '../discoverUsers/actions';
import {unsetUserGroups} from '../userGroups/actions';
import {unsetUserCategories} from '../userCategories/actions';
import {unsetOpenedEvent} from '../openedEvent/actions';
import {unsetOpenedEventMessages} from '../openedEventMessages/actions';
import {unsetInterests} from '../interests/actions';
import {setLogin, unsetLogin} from '../loggedIn/actions';
import {unsetAgendaWeeklyEvents} from '../agendaWeeklyEvents/actions';
import {setUserFollowing} from '../userFollowing/actions';
import {setNotifications, unsetNotifications} from '../notifications/actions';
import {openCompleteSignupModal} from "../completeSignupModal/actions";
import {setSubscription, unsetSubscription} from "../subscription/actions";
import { saveNewAccount, unsetMultiAccount } from "../accounts/actions";
import {setInvitations, unsetInvitations} from "../invitations/actions";
import {openDiscoverUsersModal} from "../discoverUsersModal/actions";
import {setParticipants} from "../participants/actions";

export const login = (data, onError) => {
    const {email, password} = data
    return dispatch => {
        client.authenticate({strategy: 'local', email, password})
            .then((res) => {
                dispatch(updateCalendar(new Date()))
              dispatch(saveNewAccount({
                                        'id': res.user.id,
                                        'name': res.user.nickname,
                                        'avatar': res.user.avatar,
                                        'token': res.accessToken
                                      }));
                dispatch(setLogin())
            }).catch((error) => {
            onError()
        })
    }
}

export const register = (data, onError) => {
    return dispatch => {
        services.users.create(data)
            .then(() => {
                dispatch(login(data, onError))
            })
            .catch((error) => {
                onError()
            })
    }
}

export const logout = () => {
    return dispatch => {
        client.logout()
            .then(() => {
                dispatch(forceClearLogged());
            }).catch((err) => {
            dispatch(forceClearLogged());
        });
    }
};

export const forceClearLogged = () => {
    return dispatch => {
        dispatch({type: 'LOGOUT'});
        dispatch(unsetSubscription());
        dispatch(unsetLogin());
        dispatch(unsetRecommendations());
        dispatch(unsetDiscoverUsers());
        dispatch(unsetUserGroups());
        dispatch(unsetUserCategories());
        dispatch(unsetOpenedEvent());
        dispatch(unsetOpenedEventMessages());
        dispatch(unsetInterests());
        dispatch(unsetAgendaWeeklyEvents());
        dispatch(unsetNotifications());
        dispatch(unsetInvitations());
        dispatch(unsetMultiAccount());
    };
};

export const refreshUser = (id) => {
    return dispatch => {
        services.users.get(id)
            .then((resp) => {
                dispatch({type: 'REFRESH_SESSION', payload: resp})
                dispatch(setUserFollowing(id))
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const reAuthenticate = () => {
    return dispatch => {
        dispatch(showLoader());
        try {
            client.reAuthenticate()
                .then((resp) => {
                    dispatch(setNotifications());
                    dispatch(setInvitations());
                    dispatch(setSubscription());
                    dispatch(updateApp(resp.user.id));
                    //dispatch(refreshUser(resp.user.id));
                    dispatch(setParticipants())
                    dispatch({type: 'REFRESH_SESSION', payload: resp.user})
                    if ((resp.user.birthday === null || resp.user.birthday === undefined) || (resp.user.categoryInterest && resp.user.categoryInterest.length === 0)) {
                        dispatch(openCompleteSignupModal());
                    } else {
                        if(resp.user.followingCount === 0) {
                            dispatch(openDiscoverUsersModal());
                        }
                    }
                    dispatch(setLogin());
                    dispatch(hideLoader());
                  let noHashURL = window.location.href.substr(0, window.location.href.indexOf('#'))
                  window.history.replaceState('', document.title, noHashURL)
                })
                .catch((error) => {
                    if (error.name === "Timeout") {
                        console.log(error)
                        dispatch(loaderTimeoutError());
                    } else {
                        dispatch(logout());
                        dispatch(hideLoader());
                    }
                });
        } catch (err) {
            console.log('backend is currently out of service')
        }
    }
}

export const getUserProfile = (nickname, redirect, onComplete) => {
    return dispatch => {
        services.users.find({
            query: {
                slug: nickname
            }
        })
            .then((resp) => {
                if (resp.total > 0) {
                    onComplete(resp.data[0])
                } else {
                    redirect()
                }
            })
            .catch((error) => {
                if (redirect) {
                    redirect()
                }
                console.log(error)
            })
    }
};

export const updateUserAvatar = (userId, avatar, onComplete) => {
    return dispatch => {
        services.users.patch(userId, {
            avatar
        }, null).then((resp) => {
            dispatch({type: 'CHANGE_AVATAR', payload: resp.avatar})
            onComplete();
        }).catch((error) => {
            console.log('updateUserAvatar::', error)
        })
    };
};

export const leaveEventGuest = (guestId, onComplete) => {
    return dispatch => {
      services.guests.remove(guestId).then(() => {
              onComplete()
      });
    };
};
