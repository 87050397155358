import React, {useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Lottie from "react-lottie-player";

import './_landing_highlights.scss';
import animationData from "../../../../../assets/animations/monkey-eyes.json";
import services from "../../../../../services";
import {setLandingFeedClips} from "../../../../../store/landingFeedClips/actions";
import {getMonkeyPhrase} from "../../../../../helpers/monkeyPhrases";
import LandingPreviewFeedClip
    from "../../../LandingMain/fragments/LandingFeedComponent/Feed/LandingFeedClips/LandingPreviewFeedClip";

const LandingHighlights = ({type, userId, darkTheme, containerClassname, max, onClickClip, showClipInfo}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const defaultClips = useSelector(state => state.landingFeedClips.hasOwnProperty(userId) ? state.landingFeedClips[userId].data : []);
    const [highlights, setHighlights] = useState([]);
    const lang = useSelector(state => state.settings.general.language);

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        if (userId) {
            services.landingFeed.find({
                query: {
                    type: type,
                    roomId: userId,
                    $limit: max,
                    $sort: {
                        createdAt: -1
                    }
                }
            }).then((res) => {
                if(mounted) {
                    if (type === "clip") {
                        setHighlights(res.data)
                        dispatch(setLandingFeedClips(userId, res));
                    } else {
                        setHighlights(res.data)
                    }
                    //dispatch(setLandingFeedClips(userId, res));
                    setLoading(false);
                }
            });
        }
        return () => mounted = false;
    }, [userId]);

    const HandleRenderClip = (props) => {
        const {item} = props;

        if (item.hasOwnProperty('twitch')) {
            return <>
                <div className={"play-icon"}>
                    <PlayArrowIcon/>
                </div>
                <img src={item.thumbnails?.medium}/>
            </>;
        } else if (item.hasOwnProperty('youtube')) {
            return <>
                <div className={"play-icon"}>
                    <PlayArrowIcon/>
                </div>
                <img src={`https://img.youtube.com/vi/${item.youtube}/mqdefault.jpg`}/>
            </>;
        } else {
            return (
                <>invalid clip</>
            )
        }
    };

    const RenderHighlights = useMemo(() =>
            [
                ...highlights.slice(0, max),
                ...Array.from({length: highlights.length === 0 ? 1 : max - highlights.length}, () => 'placeholder')
            ].map((item, index) =>
                item.hasOwnProperty('id') ?
                    <div key={`landing-highlight-item-${item.id}`} onClick={() => {
                        onClickClip(item.id)
                    }}>
                        {/*<HandleRenderClip item={item}/>*/}
                        <LandingPreviewFeedClip item={item} allowRemove={false}
                                                playVideo={false}
                                                showClipInfo={showClipInfo}
                        />
                    </div>
                    : <div key={`landing-highlight-placeholder-${index}`}
                           className={`item item-placeholder ${darkTheme ? ' is-dark-theme' : ' is-light-theme'} ${highlights.length === 0 ? ' is-full' : ''}`}>
                        <div className={"animation"}>
                            <Lottie
                                loop={true}
                                animationData={animationData}
                                play
                                style={{width: 96}}
                            />
                        </div>
                        <span>{getMonkeyPhrase(index, lang)}</span>
                    </div>
            )
        , [darkTheme, highlights, max])

    return (
        <div className={containerClassname}>
            {RenderHighlights}
        </div>
    );
};

LandingHighlights.defaultProps = {
    type: 'clip',
    containerClassname: 'landing-highlights-container',
    showClipInfo: false
}

export default LandingHighlights;
