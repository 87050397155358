import React, {useRef} from 'react';
import './mobile-profile-tabs.scss';
import {Box, Tab, Tabs} from '@material-ui/core';
import {useSelector} from 'react-redux';
import Scroller from '../../Scroller/Scroller';
import UserFollowersPipe from '../ProfileFollowersPipe/UserFollowersPipe';
import UserFollowingPipe from '../ProfileFollowingPipe/UserFollowingPipe';
import ProfileAgenda from '../ProfileAgenda/ProfileAgenda';
import {useTranslation} from "react-i18next";
import TopCategoriesByUser from "../../TopCategoriesByUser/TopCategoriesByUser";
import NewTrophy from "../../../assets/icons/achievement.png";
import ProfileArchivementsComponent from "../ProfileArchivements/ProfileArchivementsComponent";

const ProfileTabs = (props) => {
    const {tab, onTab, userId} = props
    const darkTheme = useSelector(state => state.darkTheme)
    const {t} = useTranslation('extraData')
    const profileTabsRef = useRef(null);


    return (
        <Box className="profile-tabs" ref={profileTabsRef}>
            <Tabs value={tab} onChange={onTab} classes={{indicator: 'indicator'}} className="menu">
                <Tab label="Agenda" classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                <Tab icon={<img width="18" height="18" src={NewTrophy} alt={t('tab_label_archivements')}
                                className="trophy"/>} label={t('tab_label_archivements')}
                     classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                <Tab label="Following" classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                <Tab label="Followers" classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                <Tab label={t('tag_categories')} classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
            </Tabs>
            {tab === 0 &&
                <Box bgcolor="grid.main" className="section">
                    <ProfileAgenda/>
                </Box>
            }
            {tab === 1 && (
                <Box bgcolor="grid.main" className="section">
                    <ProfileArchivementsComponent profileTabsRef={profileTabsRef} userId={userId}
                                                  userSlug={props.userSlug}
                                                  userArchivementList={props.userArchivementList}
                                                  cbFetchAchievements={props.cbFetchAchievements}/>
                </Box>
            )}
            {tab === 2 &&
                <Box bgcolor="grid.main" className="section" display="flex" flexDirection="row"
                     justifyContent="flex-start">
                    <Box className="following-pipe">
                        <span className="box-title">following</span>
                        <Box bgcolor="grid.content" className="following">
                            <Scroller count={5} itemHeight={60}>
                                <UserFollowingPipe/>
                            </Scroller>
                        </Box>
                    </Box>
                </Box>
            }
            {tab === 3 &&
                <Box bgcolor="grid.main" className="section" display="flex" flexDirection="row"
                     justifyContent="flex-start">
                    <Box className="followers-pipe">
                        <span className="box-title">followers</span>
                        <Box bgcolor="grid.content" className="followers">
                            <Scroller count={5} itemHeight={60}>
                                <UserFollowersPipe/>
                            </Scroller>
                        </Box>
                    </Box>
                </Box>
            }
            {tab === 4 &&
                <Box bgcolor="grid.main"
                     className="section"
                     display="flex"
                     flexDirection="row"
                     justifyContent="flex-start">
                    <TopCategoriesByUser
                        topCategories={props.topCategories}
                        containerMarginTop="0.5rem"
                        containerPaddingY="0.5rem"
                        boxWidth="6rem"
                        boxMarginBottom="0.5rem"/>
                </Box>
            }
        </Box>
    )
}

export default ProfileTabs;
