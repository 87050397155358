import React, {useEffect, useState} from 'react';
import './profile-agenda.scss';
import {useSelector} from 'react-redux';
import NewAgendaPipelines from "../../Pipelines/NewAgendaPipelines";

const ProfileAgenda = (props) => {
    const openedProfileWeeklySelected = useSelector(state => state.openedProfileWeeklySelected);
    const [weekEvents, setWeekEvents] = useState(openedProfileWeeklySelected);

    useEffect(() => {
        setWeekEvents(openedProfileWeeklySelected);
    }, [openedProfileWeeklySelected]);



    return (
        <NewAgendaPipelines type={'profile'} weeklyEvents={weekEvents} alwaysShowPing={true}
                            embedMode={props.embedMode} enableBackRef={props.enableBackRef}
                            handleCenterOnDay={props.centerOnDay}/>
    )
}

ProfileAgenda.defaultProps = {
    enableBackRef: false,
    centerOnDay: undefined
}

export default ProfileAgenda;
