import React from 'react';
import {useSelector} from "react-redux";
import './new-event-info.scss';
import {useTranslation} from "react-i18next";
import MiddleEllipsis from "react-middle-ellipsis";
import Box from "@material-ui/core/Box";
import hyperlink from "../../../assets/icons/event/icon-hyperlink.svg";
import twitchLightIcon from "../../../assets/icons/actions/twitch-white.svg";

const BigEventWebsite = () => {
    const darkTheme = useSelector(state => state.darkTheme)

    const event = useSelector(state => state.event);
    const {t} = useTranslation('extraData')

    const filterURL = (url) => {
        if (url) {
            url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
            return url
        }
        return ""
    }

    return <>
        {event.website && event.website.length > 0 && (
            <a href={(event.website && event.website.indexOf('://') === -1) ? 'https://' + event.website : event.website} rel="noopener noreferrer" target="_blank" className="website">
                <Box bgcolor="grid.content" className={`event-info-website-wrapper ${event.website.includes('twitch.tv/') ? " is-twitch" : ""}${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
                    {event.website.includes('twitch.tv/') ? <img src={twitchLightIcon} alt="twitch icon" style={{width: "18px"}}/> : <img src={hyperlink} alt="hyperlink" className="icon" style={{width: "18px"}}/>}
                    <span>Abrir en</span>
                    <MiddleEllipsis>
                        <span>{filterURL(event.website)}</span>
                    </MiddleEllipsis>
                </Box>
            </a>
        )}
    </>
}

export default BigEventWebsite;
