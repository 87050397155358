import React from 'react';
import './user-card-skeleton.scss'
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {useSelector} from 'react-redux';

const RecommendationSkeleton = (props) => {
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <Box bgcolor="background.default" className="recommendation-skeleton" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Skeleton className={"circle " + (darkTheme ? 'dark' : 'light')} animation="wave" variant="circle" />
            <Box className="content" >
                <Skeleton animation="wave" variant="text" className={(darkTheme ? 'dark' : 'light')} />
                <Skeleton animation="wave" variant="text" className={(darkTheme ? 'dark' : 'light')} />
            </Box>
        </Box>
    )
}

export default RecommendationSkeleton;
