export const openAddAccountModal = () => {
    return dispatch => {
        dispatch({type: 'OPEN_ADD_ACCOUNT_MODAL'})
    }
}

export const closeAddAccountModal = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_ADD_ACCOUNT_MODAL'})
    }
}
