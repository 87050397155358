import React, { useEffect, Fragment, useState } from 'react';
import './agenda.scss';
import { Box } from '@material-ui/core';
import Calendar from '../../components/Calendar/Calendar';
import UserAgenda from '../../components/User/UserAgenda/UserAgenda';
import { useDispatch, useSelector } from 'react-redux';
import { unsetSelectedGroup } from '../../store/selectedGroup/actions';
import { unsetMonthlyEventsCount } from '../../store/monthlyEventsCount/actions';
import { updateCalendar } from '../../store/app/actions';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import CalendarPopupFragment from "../../components/Calendar/CalendarPopup/CalendarPopupFragment";
import {Helmet} from "react-helmet";

const AgendaPopupCal = () => {
    const dispatch = useDispatch()
    const deviceView = useSelector(state => state.deviceView)
    const settingsExperimental = useSelector(state => state.settings.general.experimental);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (deviceView !== null) {
            dispatch(updateCalendar(new Date()))
        }
        return () => {
            dispatch(unsetSelectedGroup())
            dispatch(unsetMonthlyEventsCount())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceView])

    return (
        <Box display="flex" flexDirection="column" flexWrap="wrap"
             className={`agenda-page ${settingsExperimental ? ' is-experimental-mode' : ''}`}>
            <Helmet>
                <title>Agenda / Latency</title>
            </Helmet>
            {deviceView === "browser" &&
            <Fragment>
                <CalendarPopupFragment />
                <Box className="agenda-pipelines" bgcolor="grid.main">
                    <UserAgenda />
                </Box>
            </Fragment>
            }
            {deviceView === "tablet" &&
            <Fragment>
                <CalendarPopupFragment />
                <Box mb={2} minWidth="100%" display="flex" flexDirection="row" justifyContent="center"
                     alignItems="center">
                    <div className="agenda-calendar-wrapper" >
                        <div>
                            <IconButton>
                                <DateRangeIcon />
                            </IconButton>
                        </div>
                        <Box className="agenda-calendar" bgcolor="grid.main">
                            <Calendar />
                        </Box>
                    </div>
                </Box>
                <Box className="agenda-pipelines" bgcolor="grid.main">
                    <UserAgenda />
                </Box>
            </Fragment>
            }
            {deviceView === "mobile" &&
            <Fragment>
                <Box className="agenda-calendar" bgcolor="grid.main">
                    <Calendar />
                </Box>
                <Box className="agenda-pipelines" bgcolor="grid.main">
                    <UserAgenda />
                </Box>
            </Fragment>
            }
        </Box>
    )
}

export default AgendaPopupCal;
