import React, {useMemo} from 'react';
import NewPipelineItem from "../../../PipelineItem/NewPipelineItem";
import {setOpenedEvent} from "../../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../../store/eventInfoModal/actions";
import {useDispatch, useSelector} from "react-redux";
import Lottie from "react-lottie-player";
import animationData from "../../../../assets/animations/dog-swimming.json";
import {getComponentPhrase} from "../../../../helpers/phrases";
import {useTranslation} from "react-i18next";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import services from "../../../../services";
import {Event} from "../../../../services/tracking";

const LandingAgendaEventsToday = ({ userId, darkTheme, onClickMore }) => {
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const dateFormat = "iii. d";
    const date = new Date();
    const {t} = useTranslation('landing');
    const lang = useSelector(state => state.settings.general.language);


    const OpenInfo = (event) => {
        window.history.pushState({backRef: window.location.pathname}, "my calendar", `/${event.owner.slug}/${event.id}`)
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("LandingAgendaEventsToday", "OpenInfo", `/${event.owner.slug}/${event.id}`);
    };

    const emptyAgenda = () => {
      return (
          <div className={`item item-placeholder ${darkTheme ? ' is-dark' : ' is-light'}`}>
              <div className={"animation"}>
                  <Lottie
                      loop={true}
                      animationData={animationData}
                      play
                      style={{ width: 160 }}
                  />
              </div>
              <div className="column-text">
                  <span>{t("empty_agenda")}</span>
                  {getComponentPhrase(lang)}
              </div>
          </div>
      )
    };

    const handleCustomServiceEndpoint = (query) => {
        return services.landingAgendaToday.get(query.ownerId, {
            query: {
                start: query.start.substring(0, query.start.length - 9) + "00:00.000",
                end: query.end,
                timezone: query.timezone
            }
        });
    };

    const RenderAgenda = useMemo(() =>           <NewPipelineItem
        key={`pipeline-item-${date.getDate()}-${userId}`}
        defaultLimit={4}
        loadMoreEnabled={false}
        loadMoreDisabledPlaceholder={<div onClick={onClickMore} className="pipeline-load-more animated infinite slower">Ver más eventos en la agenda <ArrowRightAltIcon fontSize="small" /></div>}
        type={"special"}
        ownerId={userId}
        groupId={null}
        groupEvents={false}
        item={date}
        OpenInfo={OpenInfo}
        calendar={false}
        alwaysShowPing={true}
        dateFormat={dateFormat}
        deviceView={deviceView}
        emptyContentPlaceHolderView={emptyAgenda}
        selectedDate={date.getDate()}
        hideLiveNowEvents={false}
        nextEvents={true}
        customServiceEndpoint={handleCustomServiceEndpoint}
    />, [userId, darkTheme] )

    return (
      <div className="landing-agenda-today-container">
          {RenderAgenda}
      </div>
    );
};

export default LandingAgendaEventsToday;
