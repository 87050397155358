export const showSnackbar = (data) => {
    return dispatch => {
        dispatch({type: 'OPEN_SNACKBAR', payload: data})
    }
}

export const hideSnackbar = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_SNACKBAR'})
    }
}
