import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
const HtmlToReactParser = require('html-to-react').Parser

const htmlToReactParser = new HtmlToReactParser()

const LandingFeedArticle = ({ item, allowRemove, onClickDeleteButton, onClickEditButton}) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const reactElement = htmlToReactParser.parse(item.text)

    useEffect(() => {
        try {
            // eslint-disable-next-line no-undef
            twttr.widgets.load()
        } catch (err) {
        }
    });

    return (
        <div className={`feed-article-item ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
            <div className={"feed-article-item-heading"}>
                <h1>{item.title}</h1>
                <div className={"heading-subtitle"}>enviado por <span>{item.author}</span></div>
            </div>
            <div className={"feed-article-item-content"}>
                {(item.hasOwnProperty('cover') && item.cover !== null && item.cover !== "") && <img src={item.cover} /> }
                <div className={"feed-article-item-article"}>
                    {reactElement}
                </div>
                {allowRemove && (
                    <div className={"feed-article-item-foot"}>
                        <IconButton size={"small"}
                                    className="edit-btn"
                                    color="secondary"
                                    onClick={() => {onClickEditButton(item.id)}}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton size={"small"}
                                    className="delete-btn"
                                    onClick={() => onClickDeleteButton(item.id)}>
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LandingFeedArticle;
