import React, {useEffect, useState} from 'react';
import {Box, Button} from '@material-ui/core';
import {Link} from "react-router-dom";
import { timeAgo } from '../../helpers/dateFormatter';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const InvitationNotification = ({data, onAccept, onReject, onRead, onClose}) => {
    const { t } = useTranslation(['notifications']);
    const darkTheme = useSelector(state => state.darkTheme)
    const actor = data.notificationObject.notificationChange.actor
    const [entity, setEntity] = useState(data.notificationObject.entity);

    const [isRejected, setIsRejected] = useState(entity && (!entity?.isConfirmed && new Date(entity?.createdAt).getTime() !== new Date(entity?.updatedAt).getTime()));
    const [isAccepted, setIsAccepted] = useState(entity?.isConfirmed);

    useEffect(() => {
        setEntity(data.notificationObject.entity);
        setIsRejected(data.notificationObject.hasOwnProperty('entity') && (!data.notificationObject.entity.isConfirmed && new Date(entity.createdAt).getTime() !== new Date(entity.updatedAt).getTime()));
        setIsAccepted(data.notificationObject.hasOwnProperty('entity') &&  data.notificationObject.entity.isConfirmed);
    }, [data]);

    const handleOnAccept = () => {
        onAccept(data);
        setIsRejected(false);
        setIsAccepted(true);
    };

    const handleOnReject = () => {
        onReject(data);
        setIsRejected(true);
        setIsAccepted(false);
    };

    if(!entity) {
        return null;
    }

    return (
        <Box bgcolor={"search.main"} className={"invite-notification "  + (darkTheme ? "dark" : "light")} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Box className="image-box">
                <img src={actor.avatar ? actor.avatar : "/images/defaultAvatar.png"} alt="latency" className="image" />
            </Box>
            <Box className="noti-content" minHeight="40px" display="flex" flexDirection="column" justifyContent="center">
                <span className="text-content">
                    <span className="actor"> <Link to={`/${actor.slug}`} onClick={onClose}> {actor.nickname} </Link> </span>
                    <span className="text"> {t('notification_invitation_message')} </span>
                    <span className="entity"> <Link to={`/${actor.slug}/${entity.event.id}`} onClick={onClose}> {entity.event.title} </Link> </span>
                </span>
                    <Box className="noti-actions" display="flex" flexDirection="row" justifyContent="flex-start">
                        <Button onClick={handleOnAccept} className={isAccepted ? "accepted-btn" : "accept-btn"}>{isAccepted ? t('notification_invitation_accepted') : t('notification_invitation_accept')}</Button>
                        <Button onClick={handleOnReject} className={isRejected ? "rejected-btn" : "reject-btn"}>{isRejected ? t('notification_invitation_rejected') : t('notification_invitation_reject') }</Button>
                    </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <span className="time"> {timeAgo(data.createdAt)} </span>
                    {data && data.status === 0 && <VisibilityIcon onClick={() => onRead(data)} className={"read-icon green"} />}
                    {data && data.status === 1 && <VisibilityIcon className={"read-icon grey"} />}
                </Box>
            </Box>
        </Box>
    );
};

export default InvitationNotification;
