import {Box, Button, ListItem, ListItemIcon, ListItemText, TextField} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import DeleteIcon from "@material-ui/icons/Delete";
import {DataGrid} from "@material-ui/data-grid";

const LandingConfigurationFormTwitch = (
    {
        twitchToken,
        twitchTokenHandle,
        addTwitchGameIdHandle,
        twitchGameIdInput,
        twitchGameIdInputHandle,
        twitchGameIds,
        deleteTwitchGameIdHandle,
        addRowHandle,
        deleteSelectionHandle,
        selectionSpiderData,
        spiderData,
        onChangeRowsHandle,
        setSelectionSpiderData
    }) => {
    const {t} = useTranslation('settings');

    const columns = [
        {field: 'twitch', headerName: 'Twitch', width: 200, editable: true},
        {field: 'streamer', headerName: 'Streamer', width: 200, editable: true},
        {field: 'twitter', headerName: 'Twitter', width: 200, editable: true},
        {field: 'instagram', headerName: 'Instagram', width: 200, editable: true},
        {field: 'facebook', headerName: 'Facebook', width: 200, editable: true},
        {field: 'youtube', headerName: 'Youtube', width: 200, editable: true},
        {field: 'picture', headerName: t('section_landing_twitch_image'), width: 200, editable: true},
        {field: 'latencyId', headerName: 'Latency id', width: 200, editable: true}
    ];

    return (
        <Box mx={2} my={1}>
            <Box display="flex" my={1}>
                <Box mr={1}>
                    <TextField
                        label="Twitch token"
                        aria-label='Twitch token'
                        value={twitchToken}
                        onChange={twitchTokenHandle}
                    />
                </Box>
                <Box>
                    <Box onSubmit={addTwitchGameIdHandle} component="form" display="flex"
                         justifyContent="center" alignItems="center">
                        <TextField
                            label="Twitch game id"
                            aria-label='Add twitch game id'
                            value={twitchGameIdInput}
                            onChange={twitchGameIdInputHandle}
                        />
                        <IconButton type="submit" aria-label="add" color="primary">
                            <AddIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <List aria-label="twitch game ids list">
                {twitchGameIds.map(id =>
                    <ListItem key={id} dense="dense">
                        <ListItemText primary={id}/>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small"
                                        className="delete-twitch-game-id-btn"
                                        onClick={() => deleteTwitchGameIdHandle(id)}/>
                        </ListItemIcon>
                    </ListItem>
                )}
            </List>
            <Box my={1}>
                <Button onClick={addRowHandle} color="primary" className="add-row-btn">
                    {t("section_landing_twitch_add_row")}
                </Button>
                <Button onClick={deleteSelectionHandle} color="primary" className="delete-rows-btn"
                        disabled={!selectionSpiderData || selectionSpiderData.length === 0}>
                    {t("section_landing_twitch_delete_rows_selected")}
                </Button>
            </Box>

            <Box width="100%" height="400px">
                <DataGrid
                    rows={spiderData}
                    columns={columns}
                    checkboxSelection
                    density="dense"
                    onEditRowsModelChange={onChangeRowsHandle}
                    onSelectionModelChange={setSelectionSpiderData}
                    disableSelectionOnClick
                />
            </Box>
        </Box>
    );
};

export default LandingConfigurationFormTwitch;
