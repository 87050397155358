import React, {useEffect, useMemo, useRef, useState} from 'react';
import {format, isToday} from 'date-fns'
import ChatInputForm from "./ChatInputForm";
import {useDispatch, useSelector} from "react-redux";
import services from "../../../../services";
import {addRoomMessage, editRoomMessage, setRoomMessages} from "../../../../store/rooms/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Link} from "react-router-dom";
import {showSnackbar} from "../../../../store/snackbar/actions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ScrollToBottom, {useScrollToBottom, useSticky} from 'react-scroll-to-bottom';
import BlockIcon from '@material-ui/icons/Block';
import HistoryIcon from '@material-ui/icons/History';
import {CircularProgress} from "@material-ui/core";

const MessageItem = ({id, user, text, createdAt, censored}) => {
    const [loading, setLoading] = useState(false);
    const authUser = useSelector(state => state.user);
    const [isAdmin, setAdmin] = useState((authUser && authUser.hasOwnProperty('roles') && authUser.roles.includes('admin')));

    useEffect(() => {
        setAdmin((authUser && authUser.hasOwnProperty('roles') && authUser.roles.includes('admin')))
    }, [authUser]);

    useEffect(() => {
        setLoading(false);
    }, [censored]);

    const handleMentions = (text) => {
        // convert to link
        const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
        text = text.replace(reg, "<a target='_blank' rel='noopener noreferrer' href='$1$2'>$1$2</a>");
        // convert mentions to link
        text = text.replace(/@\[(.*?)\]\((.*?)\)/gi, '<a class="commentMentionUser" href="https://latencyapp.com/$2">@$1</a>');
        return text;
    };

    const toggleCensorMessage = () => {
        setLoading(true);
        services.landingChat.patch(id, {
            censored: !censored
        })
    };

    return (
        <div className={`landing-chat-message-item ${isAdmin ? ' is-admin' : ''}`}>
            <div className={"message-wrap"}>
                <div className={"message-time"}>
                    {isToday(new Date(createdAt)) ? format(new Date(createdAt), 'HH:mm') : format(new Date(createdAt), 'dd/MM')}
                </div>
                <div className={"message-content"}>
                    <span className={"author"}><Link className="trustedlist-table-item--user"
                                                     to={`/${user.slug}`}>{user.nickname}{user.account_verified &&
                    <CheckCircleIcon className="check-icon"/>}</Link>:</span>
                    {censored && <span className={"moderated"}>[El mensaje ha sido moderado]</span>}
                    {!censored && <span dangerouslySetInnerHTML={{__html: handleMentions(text)}}/>}
                </div>
                {isAdmin && (
                    <div className={"message-actions"}>
                        {loading && <CircularProgress size={14} thickness={5} className="loading"/>}
                        {(!loading && !censored) &&
                        <BlockIcon onClick={toggleCensorMessage} className={"moderator-btn"}/>}
                        {(!loading && censored) &&
                        <HistoryIcon onClick={toggleCensorMessage} className={"moderator-btn"}/>}
                    </div>
                )}
            </div>
        </div>
    )
};

const Content = ({userID}) => {
    const room = useSelector(state => state.rooms.hasOwnProperty(userID) ? state.rooms[userID] : [])
    const scrollToBottom = useScrollToBottom();
    const [sticky] = useSticky();

    const RenderMessages = useMemo(() => room.map((message) => <MessageItem key={message.id} {...message} />), [room])

    return (
        <React.Fragment>
            {RenderMessages}
        </React.Fragment>
    );
};

const LandingChat = ({userId}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const darkTheme = useSelector(state => state.darkTheme);

    const [loadingChat, setLoadingChat] = useState(true);

    const handleChatMessage = (query) => {
        services.landingChat.create({
            roomId: userId,
            text: query.text
        }).then(() => {
            // success
        }).catch((err) => {
            dispatch(showSnackbar({message: err.message, type: "error"}));
        });
    }

    const handleNewMessage = (data) => {
        if (data.roomId === userId) {
            dispatch(addRoomMessage(data));
        }
    };

    const handlePatchMessage = (data) => {
        if (data.roomId === userId) {
            dispatch(editRoomMessage(userId, data.id, data))
        }
    };

    useEffect(() => {
        setLoadingChat(true);
        services.landingChat.on('created', handleNewMessage);
        services.landingChat.on('patched', handlePatchMessage);
        services.landingChat.find({
            query: {
                roomId: userId,
                $sort: {
                    createdAt: -1
                },
                $limit: 60
            }
        }).then((res) => {
            dispatch(setRoomMessages(userId, res.data.reverse()));
            return services.rooms.create({
                roomId: userId
            }).then(() => {
                setLoadingChat(false);
            });
        }).catch((err) => {
            console.log('ERROR LOADING CHAT')
        });
        return () => {
            services.landingChat.removeListener('created', handleNewMessage)
            services.landingChat.removeListener('patched', handlePatchMessage)
        }
    }, [userId]);

    return (
        <div className={"container-chat"}>
            <div className={`chat-messages ${darkTheme ? " is-dark-theme" : ""}`}>
                {loadingChat && <LinearProgress/>}
                <ScrollToBottom className={"chat-scroll scroller"} debug={false}>
                    <Content userID={userId}/>
                </ScrollToBottom>
            </div>
            <div className={"chat-input"}>
                <ChatInputForm user={user} onSubmit={handleChatMessage}/>
            </div>
        </div>
    );
};

export default LandingChat;
