import React, {useState} from 'react';
import './contact-form.scss';
import {Box, TextField, Grid, withStyles} from '@material-ui/core';
import CustomButton from '../../CustomButton/CustomButton';
import services from "../../../services";
import {showSnackbar} from "../../../store/snackbar/actions";
import {useDispatch} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Link} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
    root: {
        color: '#03CDBF',
        '&$checked': {
            color: '#03CDBF',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
    root: {
        color: '#D75454',
        '&$checked': {
            color: '#D75454',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);



const ContactForm = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(['contactForm']);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [checkTOS, setCheckTOS] = useState(false);
    const [checkTOSError, setCheckTOSError] = useState(false);

    const handleSubmit = () => {
        if (checkTOS) {
            if (name) {
                if (email) {
                    if (subject) {
                        if (message) {
                            submit({name, email, subject, message})
                        } else {
                            setMessageError(true)
                        }
                    } else {
                        setSubjectError(true)
                    }
                } else {
                    setEmailError(true)
                }
            } else {
                setNameError(true)
            }
        } else {
            setCheckTOSError(true);
        }
    }

    const submit = (data) => {
        services.contact.create(data)
            .then((res) => {
                dispatch(showSnackbar({message: "Contact form submitted", type: "success"}));
                setEmail("");
                setName("");
                setSubject("");
                setMessage("");
            }).catch((err) => {
            dispatch(showSnackbar({message: "Oops!", type: "error"}));
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    return (
        <Box className="contact-form" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
            <Box className="form-box" display="flex" flexDirection="row" justifyContent="center" alignItems="center"
                 flexWrap="wrap">
                <Grid container>
                    <Grid item container xs={6} justifyContent="center">
                        <TextField
                            className={"name"}
                            error={nameError}
                            type="text"
                            label={t('label_name')}
                            value={name}
                            name="contact-name"
                            variant="outlined"
                            onKeyDown={handleKeyDown}
                            helperText={nameError ? t('helper_empty_field') : ''}
                            onChange={(e) => {
                                setName(e.target.value)
                                !e.target.value ? setNameError(true) : setNameError(false)
                            }}/>
                    </Grid>
                    <Grid item container xs={6} justifyContent="center">
                        <TextField
                            className={"email"}
                            error={emailError}
                            type="email"
                            label={t('label_email')}
                            value={email}
                            name="contact-email"
                            variant="outlined"
                            onKeyDown={handleKeyDown}
                            helperText={emailError ? t('helper_empty_field') : ''}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                !e.target.value ? setEmailError(true) : setEmailError(false)
                            }}/>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <TextField
                            className={"subject"}
                            error={subjectError}
                            type="text"
                            label={t('label_subject')}
                            name="contact-subject"
                            value={subject}
                            variant="outlined"
                            onKeyDown={handleKeyDown}
                            helperText={subjectError ? t('helper_empty_field') : ''}
                            onChange={(e) => {
                                setSubject(e.target.value)
                                !e.target.value ? setSubjectError(true) : setSubjectError(false)
                            }}/>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <TextField
                            className={"message"}
                            multiline
                            rows="7"
                            error={messageError}
                            type="text"
                            label={t('label_message')}
                            name="contact-message"
                            value={message}
                            variant="outlined"
                            onKeyDown={handleKeyDown}
                            helperText={messageError ? t('helper_empty_field') : ''}
                            onChange={(e) => {
                                setMessage(e.target.value)
                                !e.target.value ? setMessageError(true) : setMessageError(false)
                            }}/>
                    </Grid>
                    <div className={`wrap-tos ${checkTOSError ? ' is-error' : ''}`}>
                        {!checkTOSError ? (
                            <GreenCheckbox
                                checked={checkTOS}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                onChange={() => setCheckTOS(!checkTOS)}
                                name="check-TOS"
                                color="primary"
                            />
                        ) : (
                            <RedCheckbox
                                checked={checkTOS}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                onChange={() => setCheckTOS(!checkTOS)}
                                name="check-TOS"
                                color="primary"
                            />
                        )}

                        <span className="tos-text">
                            <Trans i18nKey="frontPage:forms_labelCheckTOS">
                            I have read and accept the <Link to="/privacy-policy">privacy policy</Link> and <Link
                                to="/legal-notice">legal notice</Link>.
                            </Trans>
                            {checkTOSError && <span className="error"> {t('validations:validations_REQUIRED')} </span>}
                        </span>
                    </div>
                    <Grid item container xs={12} justifyContent="center">
                        <CustomButton loading={props.loading} disabled={props.loading} className="submit-btn large"
                                      color="primary" onClick={handleSubmit}>{t('button_send')}</CustomButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ContactForm;
