import {Link, useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getUserProfile} from "../../store/user/actions";
import {prepareProfile, removeProfile} from "../../store/app/actions";
import {setOpenedProfileFilterCategory} from "../../store/openedProfileFilterCategory/actions";
import {setMonthlyEventsCount} from "../../store/monthlyEventsCount/actions";
import {setOpenedProfileWeeklyEvents} from "../../store/openedProfileWeeklyEvents/actions";
import {Box} from "@material-ui/core";
import Calendar from "../../components/Calendar/Calendar";
import Loader from "../../components/Loader/Loader";
import Scroller from "../../components/Scroller/Scroller";
import ProfileEventCard from "../../components/Event/ProfileEventCard/ProfileEventCard";
import EmbedUserCategories from "../../components/Embed/EmbedUserCategories/EmbedUserCategories";
import ProfileAgenda from "../../components/Profile/ProfileAgenda/ProfileAgenda";
import './new_embed.scss';
import logoLatency from '../../assets/images/logo_latency.svg';
import logoLatencyComplex from "../../assets/images/logo_latency_complex.svg";
import logoPingingIsotype from "../../assets/images/logo_isotipo_pinging.svg";
import Logo from "../../components/Logo/Logo";

const NewEmbedPage = (props) => {
    const {userNickname, eventID} = useParams();
    const [userID, setUserId] = useState(undefined);
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceView = useSelector(state => state.deviceView);
    const {t} = useTranslation(['userDetails', 'eventDetails']);
    const [loadingNextEvents, setLoadingNextEvents] = useState(true);
    const selectedDate = useSelector(state => state.selectedDate);
    const openedProfileNextEvents = useSelector(state => state.openedProfileNextEvents);
    const openedProfileFilterCategory = useSelector(state => state.openedProfileFilterCategory);
    const [filterCategories, setFilterCategories] = useState(openedProfileFilterCategory);

    useEffect(() => {
        if(openedProfileNextEvents && openedProfileNextEvents.hasOwnProperty('data')){
            setLoadingNextEvents(false)
        } else {
            setLoadingNextEvents(true);
        }
        return () => {
            setLoadingNextEvents(true);
        }
    }, [openedProfileNextEvents]);

    useEffect(() => {
        dispatch(getUserProfile(userNickname, redirect, (user) => {
            setUserId(user.id)
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNickname]);

    useEffect(() => {
        if(userID && eventID){
            props.onParams({userID, eventID})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID, eventID]);

    useEffect(() => {
        if(userID) {
            dispatch(prepareProfile(new Date(), userID, redirect));
            window.scrollTo(0,0);
        }
        return () => {
            dispatch(removeProfile());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID, deviceView]);

    const redirect = () => {
        history.push('/not-found');
    };

    const onFilterCategories = (val) => {
        setFilterCategories(val);
        dispatch(setOpenedProfileFilterCategory(val, () => {
            dispatch(setMonthlyEventsCount(selectedDate, userID, false));
            dispatch(setOpenedProfileWeeklyEvents(selectedDate, userID, false))
        }));
    };

    useEffect(() => {
        document.body.classList.toggle('bg-transparent', true);
        return () => {
            document.body.classList.remove('bg-transparent');
        }
    }, []);

    return (
        <Box className="new-embed-page">
            <Box className="embed-sidebar">
                <Box className="embed-calendar" bgcolor="grid.main">
                    <Calendar ownerId={userID} />
                </Box>
                <Box className="embed-coming-events" bgcolor="grid.main">
                    <span className="box-title">{t('userDetails:headings_coming_events')}</span>
                    <Box className="embed-coming-events-content" bgcolor="grid.content">
                        {loadingNextEvents && <Loader withoutBox={true} withoutBg={true} />}
                        {!loadingNextEvents && (<Scroller>
                            {openedProfileNextEvents && openedProfileNextEvents.hasOwnProperty('data') && openedProfileNextEvents.data.length > 0 && openedProfileNextEvents.data.map((event, index) =>
                                <ProfileEventCard key={`next-events-${event.id}-${index}`} event={event} />
                            )}
                        </Scroller>)}
                    </Box>
                </Box>
            </Box>
            <Box className="embed-agenda">
                <ProfileAgenda embedMode={true} />
                <Box className="powered-by" bgcolor="grid.main">
                    <Link to="/">
                        <div className="wrap">
                            <span>powered by</span>
                            <img src={logoLatency} alt="Latency" />
                        </div>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default NewEmbedPage;
