import React, {useEffect, useState} from 'react';
import './complete-signup-form.scss';
import {Grid} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import OwnStepper from "./OwnStepper";
import UploadAvatar from "./steps/UploadAvatar";
import UserDetails from "./steps/UserDetails";
import {useDispatch, useSelector} from "react-redux";
import {closeCompleteSignupModal} from "../../../store/completeSignupModal/actions";
import services from "../../../services";
import {refreshUser, updateUserAvatar} from "../../../store/user/actions";
import CustomButton from "../../CustomButton/CustomButton";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {CategoryInterestForm} from "../CategoryInterestForm/CategoryInterestForm";

const CompleteSignupForm = ({onClose}) => {
    const {t} = useTranslation('completeSignup')
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const user = useSelector(state => state.user);
    const [slug, setSlug] = useState("");
    const [birthday, setBirthday] = useState("");
    const [city, setCity] = useState("");
    const [gender, setGender] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [interests, setInterests] = useState([]);

    const [loading, setLoading] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        if (user.slug) {
            setSlug(user.slug);
        }
        if (user.gender) {
            setGender(user.gender);
        }
        if (user.avatar) {
            setAvatar(user.avatar);
        }
        if (user.location) {
            setCity(user.location);
        }
        if (user.birthday) {
            setBirthday(user.birthday ? format(new Date(user.birthday), "dd/MM/yyyy") : "");
        }
        if (user.birthday !== null && user.birthday !== undefined) {
            setActiveStep(2);
        }
    }, [user]);

    const handleNext = () => {
        if (!loading) {
            if (activeStep === 0) {
                setSlug(user.slug);
                setLoading(true);
                if(avatar.startsWith('/dist/')) {
                    setNextDisabled(true);
                    setActiveStep(1);
                    setLoading(false);
                } else {
                    dispatch(updateUserAvatar(user.id, avatar, () => {
                        setNextDisabled(true);
                        setActiveStep(1);
                        setLoading(false);
                    }));
                }

            } else if (activeStep === 1) {
                setLoading(true);
                let data = {
                    slug: slug,
                    birthday: birthday,
                    location: city,
                    gender: gender
                };
                services.users.patch(user.id, data, null)
                    .then((resp) => {
                        dispatch(refreshUser(user.id))
                        setNextDisabled(true);
                        setActiveStep(2);
                        setLoading(false);
                    }).catch((error) => {
                    console.log(error);
                });
            } else if (activeStep === 2) {
                setLoading(true);
                let data = {
                    categoryInterest: interests
                };
                services.users.patch(user.id, data, null)
                    .then((resp) => {
                        dispatch(refreshUser(user.id))
                        setNextDisabled(true);
                        setLoading(false);
                        onClose();
                    }).catch((error) => {
                    console.log(error);
                });
            } else {
                setNextDisabled(true);
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleCategoryInterestChange = (data) => {
        if (data.length >= 3) {
            setNextDisabled(false);
        } else {
            setNextDisabled(true);
        }
        setInterests(data);
    }


    return (
        <div
              className={`complete-signup-form ${deviceView === "mobile" ? " is-mobile" : ""} ${darkTheme ? ' is-dark-theme' : ' is-light-theme'}`}>
            <div>
                <span className="box-title">{t("completeSignup:welcome")}</span>
            </div>
            {activeStep !== 2 && (
                <OwnStepper activeStep={activeStep}
                            steps={[t("completeSignup:step_personalize_avatar"), t("completeSignup:step_about_you"), t("completeSignup:step_interests")]}/>
            )}

            <div style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center'
            }}>
                <Box className={"complete-signup-wrapper"}>
                    {activeStep === 0 && (
                        <UploadAvatar onComplete={() => {
                            setNextDisabled(false);
                        }} avatar={avatar} onChangeAvatar={setAvatar}
                        />
                    )}
                    {activeStep === 1 && (
                        <UserDetails
                            slug={slug}
                            setSlug={setSlug}
                            birthday={birthday}
                            setBirthday={setBirthday}
                            city={city}
                            setCity={setCity}
                            gender={gender}
                            setGender={setGender}
                            onComplete={() => {
                                setNextDisabled(false);
                            }}
                        />
                    )}
                    {activeStep === 2 && (
                        <CategoryInterestForm onChange={handleCategoryInterestChange} onClose={() => {
                            dispatch(closeCompleteSignupModal())
                        }}/>
                    )}
                </Box>
            </div>
            <div>
                <Box className={"complete-signup-footer"} display="flex" justifyContent="space-between">
                    {activeStep !== 0 && (
                        <CustomButton loading={false} disabled={activeStep === 0} className="submit-btn button-back"
                                      color="primary" onClick={handleBack}>{t("completeSignup:back")}</CustomButton>
                    )}
                    <CustomButton loading={loading} disabled={nextDisabled} className="submit-btn button-next"
                                  color="primary" onClick={handleNext}>{t("completeSignup:next")}</CustomButton>
                </Box>
            </div>
        </div>
    )
}

export default CompleteSignupForm;
