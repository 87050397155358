import React from 'react';
import './cookies-terms.scss';
import {Box} from '@material-ui/core';
import CustomButton from '../CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCookiesTerms } from '../../store/cookiesTerms/actions';
import CustomLink from '../CustomLink/CustomLink';

const CookitesTerms = (props) => {
    const {t} = useTranslation('extraData')
    const dispatch = useDispatch()

    return (
        <Box bgcolor="grid.main" className="cookies-terms" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box className="content" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <p> {t('text_cookies_terms')} <CustomLink className="cookies-link" to="/terms"> {t("buttons_more_information")} </CustomLink></p>
            </Box>
            <Box className="actions" display="flex" flexDirection="row" justifyContent="flex-end">
                <CustomButton className="submit-btn" onClick={() => dispatch(setCookiesTerms())}> {t('buttons_agree')} </CustomButton>
            </Box>
        </Box>
    )
}

export default CookitesTerms;