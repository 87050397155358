import React, { useState } from 'react';
import './user-info.scss';
import { Box, Tooltip } from '@material-ui/core';
import facebook from '../../../assets/icons/actions/fb.svg';
import linkedin from '../../../assets/icons/actions/in.svg';
import instagram from '../../../assets/icons/actions/insta.svg';
import twitter from '../../../assets/icons/actions/twitter.svg';
import twitch from '../../../assets/icons/actions/twitch.svg';
import youtube from '../../../assets/icons/actions/youtube.svg';

import { ReactComponent as IconFacebook } from '../../../assets/icons/actions/fb.svg';
import { ReactComponent as IconLinkedin } from '../../../assets/icons/actions/in.svg';
import { ReactComponent as IconInstagram } from '../../../assets/icons/actions/insta.svg';
import { ReactComponent as IconTwitter } from '../../../assets/icons/actions/twitter.svg';
import { ReactComponent as IconTwitch } from '../../../assets/icons/actions/twitch.svg';
import { ReactComponent as IconYoutube } from '../../../assets/icons/actions/youtube.svg';
import {ReactComponent as VerifiedUserIcon} from "../../../assets/icons/user/check-new.svg";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { followUser, unfollowUser } from '../../../store/app/actions';
import CustomButton from '../../CustomButton/CustomButton';
import { closeEventInfoModal } from '../../../store/eventInfoModal/actions';

const UserInfo = (props) => {
    const {profile, isProfile, toProfile, isEmbed} = props
    const user = useSelector(state => state.user)
    const [follow, setFollow] = useState(profile.follow)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const redirect = () => {
        history.push('/login')
    }

    const getType = () => {
        if(window.history.state && window.history.state.calendar === true){
            return "agenda-event"
        }
        else {
            return "profile-event"
        }
    }

    const goToProfile = () => {
        if(isEmbed) {
            window.open(`https://latencyapp.com/${profile.slug}`, '_blank');
        } else {
            history.push(`/${profile.slug}`);
            dispatch(closeEventInfoModal());
        }
    }

    return (
        <Box className="user-info" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box className="picture-wrap">
                {toProfile && <img onClick={goToProfile} src={profile.avatar ? profile.avatar : "/images/defaultAvatar.png"} className="picture pointer" alt="latency" />}
                {!toProfile && <img src={profile.avatar ? profile.avatar : "/images/defaultAvatar.png"} className="picture" alt="latency" />}
            </Box>
            <Box className={"extra-data " + (isProfile ? "wide" : "")}>
                <Box className="wrapUserNickname" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    {toProfile && profile.nickname &&
                        <Tooltip title={profile.nickname} placement="top" arrow>
                            <span onClick={goToProfile} className="nick box-title pointer">{profile.nickname} </span>
                        </Tooltip>
                    }
                    {!toProfile && profile.nickname &&
                        <Tooltip title={profile.nickname} placement="top" arrow>
                            <span className="nick box-title"> {profile.nickname} </span>
                        </Tooltip>
                    }
                    {profile.account_verified && <VerifiedUserIcon width={14} height={15} className="check-icon"/>}
                </Box>
                {profile.firstName && profile.lastName ? (
                    <Tooltip title={`${profile.firstName} ${profile.lastName}`} placement="top">
                        {<span className="name subtitle"> {`${profile.firstName} ${profile.lastName}`} </span>}
                    </Tooltip>) : (<span className="name subtitle"></span>)
                }
                <Box className="social-icons" display="flex" flexDirection="row" justifyContent="center">
                    {profile.instagram_reference && profile.instagram_reference.length > 0 ? <a href={'https://www.instagram.com/' + profile.instagram_reference} rel="noopener noreferrer" target="_blank" className="socialNetwork-Link"><img src={instagram} alt="latency" className="icon" /></a> : <IconInstagram style={{ fill: 'red' }} className={'icon iconGrey'} />}
                    {profile.twitter_reference && profile.twitter_reference.length > 0 ? <a href={'https://www.twitter.com/' + profile.twitter_reference} rel="noopener noreferrer" target="_blank" className="socialNetwork-Link"><img src={twitter} alt="latency" className="icon" /></a> : <IconTwitter className={'icon iconGrey'} />}
                    {profile.linkedin_reference && profile.linkedin_reference.length > 0 ? <a href={'https://www.linkedin.com/' + profile.linkedin_reference} rel="noopener noreferrer" target="_blank" className="socialNetwork-Link"><img src={linkedin} alt="latency" className="icon" /></a> : <IconLinkedin className={'icon iconGrey'} />}
                    {profile.twitch_reference && profile.twitch_reference.length > 0 ? <a href={'https://www.twitch.tv/' + profile.twitch_reference} rel="noopener noreferrer" target="_blank" className="socialNetwork-Link"><img src={twitch} alt="latency" className="icon" /></a> : <IconTwitch className={'icon iconGrey'} />}
                    {profile.facebook_reference && profile.facebook_reference.length > 0 ? <a href={'https://www.facebook.com/' + profile.facebook_reference} rel="noopener noreferrer" target="_blank" className="socialNetwork-Link"><img src={facebook} alt="latency" className="icon" /></a> : <IconFacebook className={'icon iconGrey'} />}
                    {profile.youtube_reference && profile.youtube_reference.length > 0 ? <a href={'https://www.youtube.com' + (!profile.youtube_reference.startsWith('/') ? '/' : '') + profile.youtube_reference} rel="noopener noreferrer" target="_blank" className="socialNetwork-Link"><img src={youtube} alt="latency" className="icon" /></a> : <IconYoutube className={'icon iconGrey'} />}
                </Box>
                {!isEmbed && !isProfile && user.id !== profile.id && !follow &&
                    <CustomButton
                        loading={loading}
                        onClick={() => {
                            setLoading(true)
                            dispatch(followUser(profile, redirect, getType(), setFollow, setLoading))
                        }}
                        className="follow-btn">follow</CustomButton>
                }
                {!isEmbed && !isProfile && user.id !== profile.id && follow &&
                    <CustomButton
                        loading={loading}
                        onClick={() => {
                            setLoading(true)
                            dispatch(unfollowUser(profile, redirect, getType(), setFollow, setLoading))
                        }}
                        className="unfollow-btn">unfollow</CustomButton>
                }
                {!isEmbed && !isProfile && user.id === profile.id &&
                    <CustomButton className="userextra-button is-you">Tú</CustomButton>
                }
            </Box>
        </Box>
    )
}

export default UserInfo;
