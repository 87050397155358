export const saveNewAccount = (account) => {
  return dispatch => {
    dispatch({
               type: 'MA_ADD_NEW_ACCOUNT',
               payload: account
             });
  };
};

export const removeAccount = (userId) => {
  return dispatch => {
    dispatch({
               type: 'MA_REMOVE_ACCOUNT',
               payload: userId
             });
  };
};

export const unsetMultiAccount = () => {
  return dispatch => {
    dispatch({
               type: 'RESET_MULTI_ACCOUNTS'
             });
  };
};
