const initailState = {
    id: "",
    nickname: "",
    firstName: "",
    lastName: "",
    header: "",
    avatar: ""
}

const openedProfileReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_OPENED_PROFILE':
            return {...state, ...action.payload}
        case 'CHANGE_OPENED_PROFILE':
            return {...state, ...action.payload}
        case 'UNSET_OPENED_PROFILE':
            return state = initailState
        default:
            return state
    }
}

export default openedProfileReducer;
