import React, {useEffect, useMemo, useState} from "react";
import services from "../../../../../services";
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import Chip from "@material-ui/core/Chip";
import LiveNowItem from "../../../MarbellaVice/Landing/LiveNowItem";
import {makeStyles} from "@material-ui/core";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const LandingMainTabLiveNow = ({userId, onOpenPlayer, onDeleteOpenPlayer, eventsWithPlayerOpen}) => {
    const classes = useStyles();
    const [eventsLive, setEventsLive] = useState([]);

    useEffect(() => {
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 4);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 2);
        services.landingEventsLive.get(userId, {
            query: {
                start: formatDate(ownStartOfDay(dateObj), dateFormat),
                end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
                timezone: getCurrentTimezone(),
                $limit: 20
            }
        }).then((res) => setEventsLive(res)).catch((err) => {
            console.log(err);
        });
    }, [userId]);

    const RenderPlayerTabs = useMemo(() => {
        return (
            <div className={classes.root}>
                {eventsWithPlayerOpen.map((data, index) => {
                    let name = data.split('#+#')[0];

                    return (
                        <li key={data}>
                            <Chip
                                color="primary"
                                label={name}
                                onDelete={() => onDeleteOpenPlayer(data)}
                                className={classes.chip}
                            />
                        </li>
                    );
                })}
            </div>
        )
    }, [eventsWithPlayerOpen]);

    const RenderEvents = useMemo(() =>
            eventsLive.map((item) => <LiveNowItem customClasses={'landing-item-live'} key={`render-event-live-${item.id}`} item={item}
                                                  onPlayerOpen={onOpenPlayer}
                                                  isPlayerOpen={eventsWithPlayerOpen.includes(`${item.hasOwnProperty('twitch_channel') ? item.twitch_channel : 'no-twitch-channel'}#+#${item.id.replaceAll('-', '')}`)}/>)
        , [eventsLive, eventsWithPlayerOpen])

    return (
        <div className={"landing-tab-live-now-container"}>
            {RenderPlayerTabs}
            <div className={"landing-live-now-grid"}>
                {RenderEvents}
            </div>

        </div>
    );
};

export default LandingMainTabLiveNow;
