import React from 'react';
import './event-upcoming.scss';
import {Box, Tooltip} from '@material-ui/core';
import {useSelector} from "react-redux";
import {formatDate, getDateWithoutTime} from "../../../helpers/dateFormatter";
import {isSameDay, parseISO} from "date-fns";

const EventUpcomingCard = ({event, onOpenEventModal, nowDate}) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const sameDate = isSameDay(nowDate, getDateWithoutTime(parseISO(event.start)));
    const openEventModalHandler = () => onOpenEventModal(event);
    const formattedDate = event.hasOwnProperty("start")
        ? sameDate ? formatDate(event.start, "HH:mm") + "h" : formatDate(event.start, "iii. d")
        : "vs";
    return (
        <Tooltip title={event.title} placement="bottom" arrow>
            <Box onClick={openEventModalHandler}
                 className={`event-score-card v-new animated fadeIn ${darkTheme ? '  is-dark-theme ' : ' is-light-theme'}
                 ${(event.hasOwnProperty('is_destacado') && event.is_destacado ? ' destacado ' : '')}`}>
                <div className="event-score-info">
                    {event.poster && <img src={event.poster} alt=""/>}
                    <span>{formattedDate}</span>
                </div>
                <div className="event-score-info-col">
                    <div className="tournament--name">{event.tournament}</div>
                    <div className="event-score--wrapped">
                        <div className="event-score-team is-team-home">
                            <img src={event.team_local.image} alt={event.team_local.name}/>
                            <span>{event.team_local.name}</span>
                        </div>
                        <div className="event-score-center">vs</div>
                        <div className="event-score-team is-team-guest">
                            <span>{event.team_guest.name}</span>
                            <img src={event.team_guest.image} alt={event.team_guest.name}/>
                        </div>
                    </div>
                </div>

            </Box>
        </Tooltip>
    )
}

export default EventUpcomingCard;

