const themeData = (darkTheme) => {
    return {
        overrides: {
            MuiIconButton: {
              root: {
                borderRadius: 30,
                color: darkTheme ? '#ffffff' : '#283654',
              },
            },
        },
        palette: {
            background: {
                default: darkTheme ? '#0a0a0a' : '#ffffff',
                secondary: darkTheme ? '#0a0909' : 'red',
                paper: darkTheme ? '#232323' : '#ffffff',
                loginBg: darkTheme ? '#0a0a0a' : '#283654',
                login: darkTheme ? '#0b0b0b' : '#283654',
            },
            header: {
                main: darkTheme ? '#131314' : '#23304B',
            },
            events: {
              card: darkTheme ? '#09090a' : '#F6F5F5',
              wrap: 'transparent',
              group: darkTheme ? '#09090a' : '#F6F5F5'
            },
            grid: {
                main: darkTheme ? '#0b0b0b' : '#F6F5F5',
                content: darkTheme ? '#131314' : '#E7E7E7',
            },
            primary: {
                main: darkTheme ? '#00D1BF' : '#23304B'
            },
            buttons: {
                main: darkTheme ? '#282828' : '#31425f',
                secondary: darkTheme ? '#0d0e0e' : '#004166',
                hover: '#00D1BF'
            },
            secondary: {
                main: darkTheme ? '#00D1BF' : '#23304B',
            },
            text: {
                primary: darkTheme ? '#efefef' : '#23304B'
            },
            search:{
                main: darkTheme ? '#282828' : '#F3F3F3',
                content: darkTheme ? '#09090a' : '#E7E7E7'
            },
            action: {
                hover: darkTheme ? '#2c2c2c' : '#E7E7E7',
                active: darkTheme ? '#23304B' : '#ffffff',
                hoverOpacity: 0,
            },
            typography:{
                main: darkTheme ? '#31425F' : '#F5F4F4',
            },
        },
        typography: {
            fontFamily: 'Barlow-Regular',
        }
    }
}

export default themeData;
