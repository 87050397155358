const initailState = {}

const userReducer = (state = initailState, action) => {
    switch(action.type){
        case 'LOGIN':
            return {...state, ...action.payload}
        case 'LOGOUT':
            return state = initailState
        case 'REFRESH_SESSION':
            return {...state, ...action.payload}
        case 'CHANGE_AVATAR':
            return {...state, avatar: action.payload}
        default:
            return state
    }
}

export default userReducer;
