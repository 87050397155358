import React from 'react';
import './not-found.scss';
import {Box} from '@material-ui/core';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import Status from "../../components/Routes/Status";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

const NotFound = (props) => {
    const { t } = useTranslation(['extraData']);

    return (
        <Status code={404}>
            <Helmet>
                <title>{t('extraData:404_page_not_found')} - Latency</title>
            </Helmet>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                 className="not-found">
                <h1 className="error-code">404</h1>
                <h2 className="error-message"> {props.message ? props.message : t('extraData:404_page_not_found')}
                    <SentimentVeryDissatisfiedIcon className="icon"/></h2>
            </Box>
        </Status>
    )
}

export default NotFound;
