import React, {useState,useEffect} from 'react';
import './_profile_archivements_component.scss';
import Box from "@material-ui/core/Box";
import ProfileArchivementListComponent from "./ProfileArchivementListComponent";
import {useParams} from "react-router-dom";
import services from "../../../services";
import {useSelector} from "react-redux";


const ProfileArchivementsComponent = ({ profileTabsRef, userId, userSlug, userArchivementList, cbFetchAchievements }) => {
    const deviceView = useSelector(state => state.deviceView);
    const {achievementListSlug} = useParams()
    const [achievementOpened, setAchievementOpen] = useState(undefined);

    const [data, setData] = useState([]);

    useEffect(() => {
        if(userArchivementList.hasOwnProperty('data')) {
            setData(userArchivementList.data);
        }
    }, [userArchivementList]);

    useEffect(() => {
        if(userId !== undefined) {
            if(achievementListSlug !== undefined && achievementListSlug.length > 0) {
                services.userAchievementList.find({
                    query: {
                        ownerId: userId,
                        slug: achievementListSlug,
                    }
                }).then(res => {
                    if(res.data.length > 0) {
                        setAchievementOpen(res.data[0]);
                    }
                }).catch(err => {

                })
            }
        }
    }, [userId, achievementListSlug]);

    return (
        <Box p={3} className={`profile-archivements-component ${(achievementListSlug !== undefined && achievementListSlug.length > 0) ? ' is-fullwidth' : ''} is-${deviceView}`}>
            {(achievementListSlug !== undefined && achievementListSlug.length > 0) ? (
                achievementOpened !== undefined && (<ProfileArchivementListComponent
                    profileTabsRef={profileTabsRef}
                    isOpened={true}
                    userSlug={userSlug}
                    listId={achievementOpened.id}
                    listSlug={achievementOpened.slug}
                    label={achievementOpened.label}
                    ownerId={achievementOpened.ownerId}
                    onRefresh={cbFetchAchievements} />)
            ) : (
                <>
                    {data.map(item => <ProfileArchivementListComponent profileTabsRef={profileTabsRef} userSlug={userSlug} key={item.id} listId={item.id} listSlug={item.slug} label={item.label} ownerId={item.ownerId} onRefresh={cbFetchAchievements} />)}
                </>
            )}
        </Box>
    );
};

ProfileArchivementsComponent.defaultProps = {
    userArchivementList: {
        data: [],
        total: 0
    }
}

export default ProfileArchivementsComponent;
