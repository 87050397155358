import React, {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {subscribeUserCategory, unsubscribeUserCategory} from "../../../store/openedProfileCategories/actions";
import {useDispatch} from "react-redux";
import {showSnackbar} from "../../../store/snackbar/actions";

const UserCategoryChip = (props) => {
    const dispatch = useDispatch();
    const { userId, category } = props;
    const [isSub, setIsSub] = useState(category.subscribe);

    useEffect(() => {
       setIsSub(category.subscribe)
    }, [category]);

    const handleClick = (userId, categoryId) => {
        let sub = !isSub;
        if(props.handleOnChange) {
            if(sub) {
                props.handleOnChange({
                    userId, categoryId, mode: 'subscribe'
                });

            } else {
                props.handleOnChange({
                    userId, categoryId, mode: 'unsubscribe'
                });
            }
            setIsSub(sub);
        }
        /*
        if(sub) {
            dispatch(subscribeUserCategory(userId, categoryId, false, () => {
                setIsSub(true)
            }, () => {
                dispatch(showSnackbar({message: "Error! something went wrong." , type: "error"}))
            }));
        } else {
            dispatch(unsubscribeUserCategory(userId, categoryId, false, () => {
                setIsSub(false)
            }, () => {
                dispatch(showSnackbar({message: "Error! something went wrong." , type: "error"}))
            }));
        }
         */
    };

    return (
        <Box onClick={() => handleClick(userId, category.id)} bgcolor={
                isSub ? "#03CDBF" : "background.default"
        } style={
                isSub ? { color: 'white' } : null
        } className={"chip"}> {category.name.toLowerCase()} </Box>
    );
};

export default UserCategoryChip;
