import {Box} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import abbreviate from "number-abbreviate";
import {useTranslation} from "react-i18next";
import "./_category.scss"
import {setOpenedProfileFilterCategory} from "../../store/openedProfileFilterCategory/actions";
import {useDispatch, useSelector} from "react-redux";
import {setMonthlyEventsCount} from "../../store/monthlyEventsCount/actions";
import {setOpenedProfileWeeklyEvents} from "../../store/openedProfileWeeklyEvents/actions";

const Category = (props) => {
    const { category, isFilter } = props;
    const dispatch = useDispatch();
    const {t} = useTranslation('extraData');
    const [isCategoryFilterActive, setCategoryFilterActive] = useState(category && category.isFilter);
    const openedProfileWeeklySelected = useSelector(state => state.openedProfileWeeklySelected);
    const cdn = !!process.env.REACT_APP_CDN && !!props.category && !!props.category.cover_url
        ? process.env.REACT_APP_CDN + props.category.cover_url : "";

    const onFilterCategories = (e, val) => {
        if(isCategoryFilterActive) {
            dispatch(setOpenedProfileFilterCategory(false, () => {
                dispatch(setMonthlyEventsCount(new Date(openedProfileWeeklySelected.start), props.userId, false));
                dispatch(setOpenedProfileWeeklyEvents(new Date(openedProfileWeeklySelected.start), props.userId, false))
                if(props.changeTab) {
                    props.changeTab(e, 0)
                }
            }));
        } else {
            dispatch(setOpenedProfileFilterCategory(val, () => {
                dispatch(setMonthlyEventsCount(new Date(openedProfileWeeklySelected.start), props.userId, false));
                dispatch(setOpenedProfileWeeklyEvents(new Date(openedProfileWeeklySelected.start), props.userId, false))
                if(props.changeTab) {
                    props.changeTab(e, 0)
                }
            }));
        }

    };

    useEffect(() => {
            setCategoryFilterActive(isFilter);
    }, [isFilter])

    return (
        <>
            {(props.category !== null && props.category !== undefined) ? (
                <a onClick={(e) => {
                    e.preventDefault();
                    onFilterCategories(e, props.category.id)
                }} href={`/${props.userSlug}/${t('explore:category_slug_label')}/${props.category.hasOwnProperty('parentSlug') && props.category.parentSlug !== null ? props.category.parentSlug+'/' : ''}${props.category.slug === null ? "uncategorized" : props.category.slug}`}>
                    <Box flex="1 0 auto"
                         width={props.boxWidth}
                         maxWidth={props.boxWidth}
                         padding="0px 0.5rem"
                         marginBottom={props.boxMarginBottom}
                         display="flex"
                         flexWrap="nowrap"
                         flexDirection="column"
                         overflow="hidden"
                         flexShrink="0"
                         className={`event-category${isCategoryFilterActive ? ' is-filtered' : ''}`}>
                        {isCategoryFilterActive && (
                            <div className="event-category_wrap-filtered">
                                <div className="event-category_wrap-filtered--action">Quitar filtro</div>
                            </div>
                        )}
                        <Box position="relative" width="100%" overflow="hidden">
                            <div className="event-category_image-box">
                                {!props.category
                                    ? <Skeleton className="event-category_image-style"/>
                                    : <picture>
                                        <source srcSet={cdn + ".webp"} type="image/webp"/>
                                        <source srcSet={cdn} type="image/jpeg"/>
                                        <img className="event-category_image-style" src={cdn} alt="category cover"/>
                                    </picture>
                                }
                            </div>
                        </Box>
                        <Box position="relative" lineHeight="1" paddingY="6px">
                            {!props.category
                                ? <Skeleton variant="rect"/>
                                : <span className="event-category_title">{props.category.text}</span>
                            }
                        </Box>
                        {!!props.category &&
                        <Box position="relative" lineHeight="1" paddingBottom="0.5rem">
                    <span className="event-category_subtitle">
                        {abbreviate(props.category.count)} {t("text_events")}
                    </span>
                        </Box>
                        }
                    </Box>
                </a>
            ) : (
                <Box flex="1 0 auto"
                     width={props.boxWidth}
                     maxWidth={props.boxWidth}
                     padding="0px 0.5rem"
                     marginBottom={props.boxMarginBottom}
                     display="flex"
                     flexWrap="nowrap"
                     flexDirection="column"
                     overflow="hidden"
                     flexShrink="0"
                     className="event-category">
                    <Box position="relative" width="100%" overflow="hidden">
                        <div className="event-category_image-box">
                            <Skeleton className="event-category_image-style"/>
                        </div>
                    </Box>
                    <Box position="relative" lineHeight="1" paddingY="6px">
                        <Skeleton variant="rect"/>
                    </Box>
                </Box>
            )}
        </>
    );
}

export default Category;
