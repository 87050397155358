const initailState = []

const opnenedEventMesagesReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_OPENED_EVENT_MESSAGES':
            return state = action.payload;
        case 'UNSET_OPENED_EVENT_MESSAGES':
            return state = initailState;
        case 'UPDATE_OPENED_EVENT_MESSAGES':
            return [
                ...action.payload
            ];
        default:
            return state
    }
}

export default opnenedEventMesagesReducer;
