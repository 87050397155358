import React from 'react';
import {Box, Tooltip} from "@material-ui/core";
import {useSelector} from "react-redux";
import {
    formatDate,
    ownFormatDuration,
} from "../../../../../helpers/dateFormatter";
import './_item_inline_styles.scss';
import {useTranslation} from "react-i18next";

const VlrInlineUpcomingItemComponent = ({event, onOpenEventModal, nowDate, type}) => {
    const {t} = useTranslation('extraData');
    const darkTheme = useSelector(state => state.darkTheme);

    const openEventModalHandler = () => onOpenEventModal(event);

    return (
        <Tooltip title={event.title} placement="bottom" arrow>
            <Box onClick={openEventModalHandler}
                 className={`vlr-inline-match animated fadeIn ${darkTheme ? '  is-dark-theme ' : ' is-light-theme'}
                 ${(event.hasOwnProperty('is_destacado') && event.is_destacado ? ' destacado ' : '')} is-${type}`}>

                <div className="vlr-inline-match-body">
                    <div className="event-score--datetime">
                        <div className="event-score--time-start">
                            {formatDate(event.start, "HH:mm")}h
                        </div>
                        <div className="event-score--time-remaining">
                            {t('date_prefix_in')} {ownFormatDuration(new Date(event.start), ['days', 'hours', 'minutes'], false, "")
                            .replace("hours", "h")
                            .replace("hour", "h")
                            .replace('minutes', "m")
                            .replace("minute", "m")
                            .replace("horas", "h")
                            .replace("hora", "h")
                            .replace('minutos', "m")
                            .replace("minuto", "m")
                            }
                        </div>
                    </div>
                    <div className="event-score--team">
                        <div className="event-score--team-image">
                            <img src={event.team_local.image} alt={event.team_local.name}/>
                        </div>
                        <div className="event-score--team-name">
                            {event.team_local.name}
                        </div>
                    </div>
                    <div className="event-score--versus">
                        vs
                    </div>
                    <div className="event-score--team">
                        <div className="event-score--team-image">
                            <img src={event.team_guest.image} alt={event.team_guest.name}/>
                        </div>
                        <div className="event-score--team-name">
                            {event.team_guest.name}
                        </div>
                    </div>
                </div>
            </Box>
        </Tooltip>
    );
};

export default VlrInlineUpcomingItemComponent;
