const initailState = {
    loading: true,
    data: []
}

const recommendationsReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_RECOMMENDATIONS':
            return {...state, loading: false, data:action.payload};
        case 'UNSET_RECOMMENDATIONS':
            return state = initailState
        case 'SET_RECOMMENDATIONS_LOADING':
            return {...state, loading: true}
        default:
            return state
    }
}

export default recommendationsReducer;