import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Box } from "@material-ui/core";
import CustomButton from "../../../../../../CustomButton/CustomButton";

const LandingFeedClipsForm = ({ onSubmit, onClose }) => {
    const [blockedButton, setBlockedButton] = useState(true);
    const [error, setError] = useState(false);
    const darkTheme = useSelector(state => state.darkTheme);
    const [body, setBody] = useState("");

    const handleSubmit = () => {
        let data = {
            type: 'clip',
            custom_fields: {
                clip: body
            }
        }
        onSubmit(data, () => {
            onClose();
        }, () => {
            console.log("error!");
        })
    }

    const handleInputURL = (event) => {
        let url = event.target.value;
        if (url.startsWith("https://www.youtube.com") || url.startsWith("https://youtube.com") || url.startsWith("https://youtu.be")) {
            let yt_regex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
            let exc_yt = yt_regex.exec(url);
            if (exc_yt != null && exc_yt.length > 0 && exc_yt[1] !== null && exc_yt[1].length > 0) {
                setBody('youtube:' + exc_yt[1]);
                setError(false);
                setBlockedButton(false);
            } else {
                setError(true);
            }
        } else if (url.startsWith("https://www.twitch.tv") || url.startsWith("https://twitch.tv") || url.startsWith("https://clips.twitch.tv")) {
            const twitch_regex = /https:\/\/(?:clips|www)\.twitch\.tv\/(?:(?:.*)\/clip\/)?([A-Za-z0-9-_]+)/;
            let exc_twitch = twitch_regex.exec(url);
            if (exc_twitch != null && exc_twitch.length > 0 && exc_twitch[1] !== null && exc_twitch[1].length > 0) {
                setBody('twitch:' + exc_twitch[1]);
                setError(false);
                setBlockedButton(false);
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    }

    return (
        <div className={"landing-feed-clip-form"}>
            <div className={"landing-feed-clip-form-row"}>
                <TextField
                    id="standard-full-width"
                    label="Dirección URL del CLIP (Youtube, Twitch, Twitter)"
                    className={darkTheme ? "input-dark" : "input-light"}
                    placeholder="https://..."
                    defaultValue={body}
                    error={error}
                    helperText={error ? "Invalid URL" : ""}
                    onChange={handleInputURL}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                <CustomButton className="cancel-btn" onClick={onClose}>Cancel</CustomButton>
                <CustomButton loading={false} disabled={blockedButton} className="submit-btn" color="primary" onClick={handleSubmit}>Publicar</CustomButton>
            </Box>
        </div>
    );
};

export default LandingFeedClipsForm;
