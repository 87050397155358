import React, {useState} from 'react';
import {Tooltip} from "@material-ui/core";
import CustomButton from "../../CustomButton/CustomButton";
import {followUser, unfollowUser} from "../../../store/app/actions";
import {useDispatch, useSelector} from "react-redux";
import {closeEventInfoModal} from "../../../store/eventInfoModal/actions";
import {useHistory} from "react-router-dom";

const EventGuest = ({guest}) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [follow, setFollow] = useState(guest.follow)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const redirect = () => {
        history.push('/login')
    }

    const getType = () => {
        if (window.history.state && window.history.state.calendar === true)
            return "agenda-event"
        return "profile-event"
    }

    const goToProfile = () => {
        history.push(`/${guest.slug}`);
        dispatch(closeEventInfoModal());
    }

    return <>
        <Tooltip title={guest.nickname} placement="top">
            <div className="guest-icon-wrapper">
                <img onClick={goToProfile} src={guest.avatar}
                     alt="latency-avatar"
                     className="guest-icon"/>
                {user.id !== guest.id && !follow &&
                <CustomButton
                    loading={loading}
                    onClick={() => {
                        setLoading(true)
                        dispatch(followUser(guest, redirect, getType(), setFollow, setLoading))
                    }}
                    className="follow-btn">follow</CustomButton>
                }
                {user.id !== guest.id && follow &&
                <CustomButton
                    loading={loading}
                    onClick={() => {
                        setLoading(true)
                        dispatch(unfollowUser(guest, redirect, getType(), setFollow, setLoading))
                    }}
                    className="unfollow-btn">unfollow</CustomButton>
                }
                {user.id === guest.id &&
                <CustomButton className="userextra-button is-you">Tú</CustomButton>
                }
            </div>
        </Tooltip>
    </>
}

export default EventGuest;
