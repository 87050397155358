import React from 'react';
import {Box} from '@material-ui/core';
import {Helmet} from "react-helmet";

const TermsES = () => {
    return (
        <Box className="terms-page" m={2}>
            <Helmet>
                <title>Términos y condiciones / Latency</title>
            </Helmet>
            <div className="static-content">
                <h2>Términos y condiciones</h2>
                <p>En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, le informamos que el Sitio Web https://latencyapp.com (en adelante, la &ldquo;Web&rdquo;) es titularidad de LATENCY E-SPORTS SL, con domicilio social en Plaza de España 10, 1ºB, Las Rozas de Madrid, Madrid, Código postal 28231, con CIF B88053871.</p>

                <h2>Condiciones de uso</h2>
                <p>Las presentes Condiciones describen los términos aplicables a los servicios y contenidos ofrecidos a través del sitio web https://latencyapp.com y regulan las relaciones jurídicas entre LATENCY E-SPORTS SL y el cliente.</p>
                <p>El acceso a la web es de carácter libre y gratuito, la utilización de la Web supone la aceptación sin reservas de la totalidad de las Condiciones de Uso. Si no estuvieras de acuerdo con estas condiciones debes abstenerte de utilizar esta página.</p>
                <p>La visita al sitio eCommerce por parte del usuario deberá hacerse de forma responsable y de conformidad a la legalidad vigente, la buena fe, las presentes Condiciones y respetando los derechos de propiedad intelectual e industrial de titularidad de LATENCY o cualquier otras personas físicas o jurídicas.</p>
                <p>El uso de cualquiera de los contenidos del sitio Web con finalidades que sean o pudieran ser ilícitas queda totalmente prohibido, así como la realización de cualquier acción que cause o pueda causar daños o alteraciones de cualquier tipo no consentidas por LATENCY, al sitio eCommerce o a sus contenidos.</p>
                <p>El sitio Web y los productos y servicios ofrecidos por LATENCY E-SPORTS SL está dirigido a mayores de edad con la capacidad jurídica suficiente para poder quedar vinculado por las presentes Condiciones de Uso. Es posible que la utilización de ciertos servicios o la contratación de algunos bienes o servicios esté sujeto a condiciones especiales complementaries que requerirán el consentimiento expreso y que prevalecerán en todo caso sobre las presentes condiciones generales.</p>

                <h2>Registro de los usuarios</h2>
                <p>La utilización de los servicios prestados por LATENCY E-SPORTS SL y la compra en la Web requiere el registro como usuario en la Web. Con la finalidad de completar el registro se deberán aportar una serie de datos personales que deberán ser veraces y exactos. Puedes consultar nuestra política de privacidad en el siguiente enlace: https://latencyapp.com/privacy-policy</p>
                <p>Asimismo, debes tener en cuenta que en todo momento puedes modificar tus datos personales, así como ejercer los derechos de acceso, rectificación, supresión, limitación y de oposición de conformidad con la LO 3/2018, de 5 de diciembre, de Protección de Datos y de Garantía de los Derechos Digitales (LOPDGDD) y con el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD).</p>

                <h2>Pedidos</h2>
                <p>Una vez realizado un pedido, te llegará un mensaje confirmando la recepción del mismo así como con información sobre el envío y la facturación del pedido realizado.</p>

                <h2>Forma de pago</h2>
                <p>La adquisición de servicios o productos a través de LATENCYAPP.COM permite las siguientes formas de pago:</p>
                <ul>
                    <li>Pago mediante tarjeta de débito o crédito a través de un TPV virtual.</li>
                    <li>Pago por Paypal.</li>
                    <li>Pago mediante contrareembolso.</li>
                    <li>Pago mediante vales o cheques regalo.</li>
                </ul>

                <h2>Precios</h2>
                <p>Los precios de los productos son los indicados en la descripción que se les adjunta. Todos los precios indicados en la descripción de producto se expresan en la moneda Euro (&euro;) e incluyen el Impuesto sobre el Valor Añadido (I.V.A.).</p>
                <h2>Condiciones de envío</h2>
                <p>____________ únicamente envía sus productos a: España, ....</p>
                <p>La compra está sujeta al pago de gastos de envío, a cuenta del comprador. Los gastos de envío dependerán del destino y del importe total del pedido, los gastos se detallarán a lo largo del proceso de compra y podrás revisar a cuanto ascienden los gastos de envío antes de finalizar la compra.</p>
                <p>____________ no se hará responsable de la demora en la entrega de los pedidos por causas no imputables a la misma, así como por casos fortuitos o de fuerza mayor.</p>
                <p>La entrega se realizará mediante la empresa de transporte _______.</p>
                <p>Dispones de las siguientes opciones de entrega:</p>
                <p>__________</p>
                <p>__________</p>

                <h2>Garantía de bienes de consumo</h2>
                <p>Como cliente, tienes la consideración de consumidor de conformidad con el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias (en adelante, "TRLGDCU").</p>
                <p>En consecuencia, cuentas con el derecho de desistimiento que consiste en que puedas dejar sin efecto el contrato celebrado sin necesidad de justificar tu decisión durante los catorce (14) días naturales desde que se reciba el bien adquirido o desde la celebración del contrato si se trata de una prestación de servicios.</p>
                <p>Deberás manifestar tu intención de desistir en el plazo indicado y proceder a la devolución del producto, reembolsándote el precio abonado.</p>
                <p>En caso de tener problemas con productos o servicios adquiridos en esta Web puedes presentar una reclamación mediante la plataforma de resolución de litigios de la Unión Europea <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">aquí</a>. LATENCY se reserva la potestad de negarse a aceptar la reclamación presentada a través de la plataforma.</p>

                <h2>Nulidad e ineficacia de las cláusulas</h2>
                <p>Si cualquier cláusula incluida en las presentes Condiciones fuese declarada total o parcialmente, nula o ineficaz, tal nulidad o ineficacia tan sólo afectará a dicha disposición, subsistiendo las presentes Condiciones Generales en todo lo demás, teniéndose tal disposición por no incluida.</p>

                <h2>Legislación aplicable y jurisdicción competente</h2>
                <p>Las presentes condiciones se rigen de conformidad con la legislación española. LATENCY y el usuario acuerdan someter las controversias que pudieran surgir en relación a la contratación de productos o servicios objeto de estas Condiciones Generales, a los Juzgados y Tribunales del domicilio del Usuario.</p>
            </div>
        </Box>
    )
}

export default TermsES;
