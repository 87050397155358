const initialState = false

const openedProfileFilterCategoryReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_OPENED_PROFILE_FILTER_CAT':
            return state = action.payload
        case 'UNSET_OPENED_PROFILE_FILTER_CAT':
            return state = initialState
        default:
            return state
    }
}

export default openedProfileFilterCategoryReducer;
