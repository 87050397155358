import React, {useEffect, useState} from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useSelector} from 'react-redux';
import services from '../../services';
import CircularProgress from "@material-ui/core/CircularProgress";

const UserFollowersList = (props) => {
    const currentUser = useSelector(state => state.user);
    const darkTheme = useSelector(state => state.darkTheme)
    const [values, setValues] = useState(props.initialData)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        // fill with his following users first
        setLoading(true);
        services.userFollowers.get(currentUser.id).then((res) => {
            setList(res.data);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        setValues(props.initialData)
    }, [props.initialData]);

    const handleChange = (event, data) => {
        setValues(data)
        props.handleChange(data)
    }

    const handleSearch = (event) => {
        if(event.target.value && event.target.value.length >= 2){
            setLoading(true);
            services.search.find({
                query: {
                    text: event.target.value,
                    participants: true
                }
            })
                .then((resp) => {
                    setList(resp.users);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <Autocomplete
            renderInput={params => (
                <TextField
                    {...params}
                    className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                    variant="outlined"
                    onChange={handleSearch}
                    placeholder={"Usuario"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    fullWidth
                />
            )}
            className={"tag-select owner " + (darkTheme ? "tag-select-dark" : "tag-select-light")}
            loading={loading}
            classes={{tag: "chip", popper: "full-tag-select-dropdown", endAdornment: "clear-icon", popupIndicator: "down-btn", loading: "static", noOptions: "static"}}
            options={list}
            getOptionLabel={option => option.nickname ? option.nickname : ""}
            value={values}
            onChange={handleChange}
        />
    )
}


export default UserFollowersList;
