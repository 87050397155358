import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import LegalNoticeES from "./LegalNoticeES";
import LegalNoticeEN from "./LegalNoticeEN";

const LegalNotice = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const settingsGeneral = useSelector(state => state.settings.general);
  if(settingsGeneral.language === 'es') {
    return <LegalNoticeES/>
  } else {
    return <LegalNoticeEN/>
  }
};

export default LegalNotice;
