import services from '../../services/';
import {formatDate, getCurrentTimezone} from '../../helpers/dateFormatter';

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"

export const setOpenedProfileNextEvents = (id) => {
    return dispatch => {
        let params = {
            query: {
                start: formatDate(new Date(), dateFormat),
                nextEvents: true,
                timezone: getCurrentTimezone(),
                ownerId: id,
                $limit: 10
            }
        }
        services.events.find(params)
            .then((events) => {
                dispatch({
                    type: 'SET_OPENED_PROFILE_NEXT_EVENTS',
                    payload: events
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetOpenedProfileNextEvents = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE_NEXT_EVENTS'})
    }
}
