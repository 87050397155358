import {getCurrentTimezone} from "../../helpers/dateFormatter";

const initialState = {
    start: null,
    end: null,
    timezone: getCurrentTimezone(),
    bundle: true,
    paginate: false
};

const openedProfileWeeklySelectedReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_OPENED_PROFILE_WEEKLY_SELECTED':
            return action.payload;
        case 'UNSET_OPENED_PROFILE_WEEKLY_SELECTED':
            return state = initialState
        default:
            return state
    }
}

export default openedProfileWeeklySelectedReducer;
