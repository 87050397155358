import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import './custom-link.scss';

const CustomLink = (props) => {
    return (
        <Fragment>
            <NavLink onClick={props.onClick} to={props.to} className={props.className} color="primary" activeClassName="active">
                {props.textLight && <span className="light">{props.textLight}</span> }
                {props.textBold && <span className="bold">{props.textBold}</span>}
                {props.children}
            </NavLink>
        </Fragment>
    )
}

export default CustomLink;
