import {Box, CircularProgress, TextField} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import CustomButton from "../../CustomButton/CustomButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

const MetaDataItem = ({metadataKey, value, handleDeleteKey, onSubmit, isNew, onChange}) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const [metadataTitle, setMetadataTitle] = React.useState(metadataKey || "");
    const [metadataValue, setMetadataValue] = React.useState(value || "");
    const [isReady, setReady] = React.useState(true);
    const [timer, setTimer] = React.useState(null)

    const updateChangeValue = (key, value) => {
        if (onChange) {
            setReady(false);
            clearTimeout(timer);
            const newTimer = setTimeout(() => {
                onChange(metadataKey, key, value)
                setReady(true);
            }, 500);
            setTimer(newTimer);
        }
    };

    const handleOnSubmit = () => {
        onSubmit(metadataTitle, metadataValue);
        setMetadataTitle("");
        setMetadataValue("");
    };

    return (
        <Box className="form-field is-container r-mobile mt" style={{
            gridTemplateColumns: "1fr 1fr auto"
        }}>
            <TextField
                fullWidth
                className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                placeholder="Metadata title"
                value={metadataTitle}
                variant="outlined"
                onChange={(e) => {
                    setMetadataTitle(e.target.value);
                    if (!isNew) {
                        updateChangeValue(e.target.value, metadataValue);
                    }
                }}
            />
            <TextField
                fullWidth
                className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                placeholder="Metadata value"
                value={metadataValue}
                variant="outlined"
                onChange={(e) => {
                    setMetadataValue(e.target.value);
                    if (!isNew) {
                        updateChangeValue(metadataTitle, e.target.value);
                    }
                }}
            />
            <div className="actions">
                {!isNew && metadataTitle && metadataTitle.length > 0 && (
                    <CustomButton className="delete-btn wide e-small"
                                  onClick={() => {
                                      handleDeleteKey(metadataTitle);
                                  }}>
                        {isReady ? (<DeleteIcon className="icon"/>) : (<CircularProgress size={20}/>)}
                    </CustomButton>
                )}
                {isNew && (
                    <CustomButton className="wide e-small"
                                  onClick={handleOnSubmit}>
                        <SaveIcon className="icon"/>
                    </CustomButton>
                )}
            </div>
        </Box>
    );
}

export const MetaDataForm = ({metadata, onChange}) => {
    const [data, setData] = React.useState(metadata);

    const handleOnChangeKey = (originalKey, newKey, valueKey) => {
        if (originalKey) {
            setData(curr => {
                let ggzp = curr;
                delete ggzp[originalKey];
                let new_item = {
                    ...ggzp,
                    [newKey]: valueKey
                }
                onChange(new_item);
                return new_item;
            })
        } else {
            setData(curr => {
                let new_item = {
                    ...curr,
                    [newKey]: valueKey
                };
                onChange(new_item);
                return new_item
            })
        }
    }

    const handleAddNewRow = (key, value) => {
        setData(curr => {
            let new_item = {
                ...curr,
                [key]: value
            }
            onChange(new_item);
            return new_item;
        })
    }

    return (
        <div>
            {Object.keys(data).map((it, idx) => <MetaDataItem key={idx} metadataKey={it} value={data[it]}
                                                              onChange={handleOnChangeKey}/>)}
            <MetaDataItem isNew={true} onSubmit={handleAddNewRow}/>
        </div>
    )
};
