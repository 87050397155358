import React, { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import { Box, Switch } from "@material-ui/core";
import services from "../../../../services";
import { useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";

const SettingsNotificationsForm = () => {
  const {t} = useTranslation('settings');
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [reminderId, setReminderId] = useState(null);
  const [reminderBefore1Day, setReminderBefore1Day] = useState(false);
  const [reminderBefore8Hours, setReminderBefore8Hours] = useState(false);
  const [reminderBefore4Hours, setReminderBefore4Hours] = useState(false);
  const [reminderBefore1Hour, setReminderBefore1Hour] = useState(false);
  const [reminderBefore30Min, setReminderBefore30Min] = useState(false);
  const [reminderBefore15Min, setReminderBefore15Min] = useState(false);
  const [reminderBefore5Min, setReminderBefore5Min] = useState(false);
  const [reminderStarts, setReminderStarts] = useState(false);

  useEffect(() => {
    setLoading(true);
    services.userReminderSettings.find({
      query: {
        userId: user.id
      }
                                       }).then(res => {
      if(res.total > 0) {
        let item = res.data[0];
        setReminderId(item.id);
        setReminderBefore1Day(item.remindBefore1Day);
        setReminderBefore8Hours(item.remindBefore8Hours);
        setReminderBefore4Hours(item.remindBefore4Hours);
        setReminderBefore1Hour(item.remindBefore1Hour);
        setReminderBefore30Min(item.remindBefore30Min);
        setReminderBefore15Min(item.remindBefore15Min);
        setReminderBefore5Min(item.remindBefore5Min);
        setReminderStarts(item.remindStarts);
        setLoading(false);
      } else {
        services.userReminderSettings.create({
          userId: user.id
                                             }).then((res) => {
                                               setReminderId(res.id);
            setReminderBefore1Day(res.remindBefore1Day);
            setReminderBefore8Hours(res.remindBefore8Hours);
            setReminderBefore4Hours(res.remindBefore4Hours);
            setReminderBefore1Hour(res.remindBefore1Hour);
            setReminderBefore30Min(res.remindBefore30Min);
            setReminderBefore15Min(res.remindBefore15Min);
            setReminderBefore5Min(res.remindBefore5Min);
            setReminderStarts(res.remindStarts);
          setLoading(false);
        })
      }
    })
  }, []);

  const handleSwitchReminder = (type) => {
    if(type === "before1Day") {
      services.userReminderSettings.patch(reminderId, {
        remindBefore1Day: !reminderBefore1Day
      }).then((res) => {
        setReminderBefore1Day(!reminderBefore1Day);
      });
    } else if (type === "before8Hours") {
      services.userReminderSettings.patch(reminderId, {
        remindBefore8Hours: !reminderBefore8Hours
      }).then((res) => {
        setReminderBefore8Hours(!reminderBefore8Hours);
      });
    } else if (type === "before4Hours") {
      services.userReminderSettings.patch(reminderId, {
        remindBefore4Hours: !reminderBefore4Hours
      }).then((res) => {
        setReminderBefore4Hours(!reminderBefore4Hours);
      });
    } else if (type === "before1Hour") {
      services.userReminderSettings.patch(reminderId, {
        remindBefore1Hour: !reminderBefore1Hour
      }).then((res) => {
        setReminderBefore1Hour(!reminderBefore1Hour);
      });
    } else if (type === "before30Min") {
      services.userReminderSettings.patch(reminderId, {
        remindBefore30Min: !reminderBefore30Min
      }).then((res) => {
        setReminderBefore30Min(!reminderBefore30Min);
      });
    } else if (type === "before15Min") {
      services.userReminderSettings.patch(reminderId, {
        remindBefore15Min: !reminderBefore15Min
      }).then((res) => {
        setReminderBefore15Min(!reminderBefore15Min);
      });
    } else if (type === "before5Min") {
      services.userReminderSettings.patch(reminderId, {
        remindBefore5Min: !reminderBefore5Min
      }).then((res) => {
        setReminderBefore5Min(!reminderBefore5Min);
      });
    } else if (type === "whenStarts") {
      services.userReminderSettings.patch(reminderId, {
        remindStarts: !reminderStarts
      }).then((res) => {
        setReminderStarts(!reminderStarts);
      });
    }
  };

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_settings_notifications')}</div>
                <div className="settings-content-title-helper">{t('section_settings_notifications_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-general settings-content-notifications">
              <div className="settings-notifications-main">
                <Box bgcolor="grid.content" className="settings-notifications-column">

                </Box>
                <Box bgcolor="grid.content" className="settings-notifications-column">
                  <div className="settings-content-subsection-header">{t('section_settings_notifications_subsection_reminders')}</div>
                  {loading && (<Loader/>)}
                  {!loading && (
                    <div className="settings-content-subsection-content">
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderBefore1Day} onChange={() => handleSwitchReminder('before1Day')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_before1day')}</span>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderBefore8Hours} onChange={() => handleSwitchReminder('before8Hours')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_before8hours')}</span>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderBefore4Hours} onChange={() => handleSwitchReminder('before4Hours')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_before4hours')}</span>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderBefore1Hour} onChange={() => handleSwitchReminder('before1Hour')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_before1hour')}</span>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderBefore30Min} onChange={() => handleSwitchReminder('before30Min')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_before30min')}</span>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderBefore15Min} onChange={() => handleSwitchReminder('before15Min')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_before15min')}</span>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderBefore5Min} onChange={() => handleSwitchReminder('before5Min')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_before5min')}</span>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <Switch checked={reminderStarts} onChange={() => handleSwitchReminder('whenStarts')} className="switch"/>
                        <span>{t('section_settings_notifications_subsection_reminder_starts')}</span>
                      </Box>
                    </div>
                  )}
              </Box>

              </div>
            </div>
        </div>
    );
};

export default SettingsNotificationsForm;
