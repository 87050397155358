import React from 'react';
import {Box} from '@material-ui/core';

const CookiesPolicyES = () => {
    return (
        <Box className="cookies-policy-page" m={2}>
          <div className="static-content">
            <h2>Política de cookies</h2>
            <p>En cumplimiento con el deber de información recogido en el apartado 2º del artículo 22 de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, la presente política de cookies tiene por finalidad informarle de manera clara y precisa sobre las cookies que se utilizan en la página web de LATENCY E-SPORTS SL.</p>

            <h2>¿Qué son las cookies?</h2>
            <p>Una cookie es un fichero de pequeño tamaño que los sitios web envían al navegador y se descargan en su ordenador. Las cookies permiten que la página web almacene y recupere la información sobre su visita, como su idioma preferido y otras opciones, con el fin de mejorar los servicios que se ofrecen y contribuir a tener una mejor experiencia de navegación para el usuario.</p>

            <h2>Tipos de cookies</h2>
            <p>En función de quién gestione el dominio desde donde se envían las cookies y se traten los datos, se distinguirá entre cookies propias y cookies de terceros.</p>
            <p>Las cookies también pueden clasificarse según el plazo de tiempo que permanezcan almacenadas en el navegador del usuario, distinguiéndose entre cookies de sesión o cookies persistentes.</p>

            <p>Finalmente, en función de la finalidad para la que se trate la información recopilada, las cookies se pueden clasificar en las siguientes categorías:</p>
            <ul>
                <li>Cookies técnicas: permiten al usuario navegar por un sitio web y utilizar algunos de los servicios ofrecidos por el mismo (como por ejemplo, las que sirven para recordar los elementos integrantes de un carrito de compra).</li>
                <li>Cookies de personalización: permiten que el usuario acceda al sitio web con unas características determinadas, como puede ser el idioma.</li>
                <li>Cookies de seguridad: sirven para impedir o dificultar los ataques contra el sitio web.</li>
                <li>Cookies de complemento para intercambiar contenidos sociales: los llamados plug-in, que permiten compartir contenidos en redes sociales.</li>
                <li>Cookies de análisis: permiten al responsable el seguimiento del comportamiento del usuario.</li>
                <li>Cookies publicitarias: permiten gestionar los espacios de publicidad del sitio web.</li>
                <li>Cookies de publicidad comportamental: como las anteriores, sirven para gestionar los espacios de publicidad del sitio web en función de la información recogida sobre el comportamiento del usuario y sus hábitos, adaptando la publicidad al perfil del usuario. Cookies de geolocalización: utilizadas para averiguar el país dónde se encuentra el usuario.</li>
            </ul>

            <h2>Autorización para el uso de cookies</h2>
            <p>De conformidad con el aviso de cookies que aparece en el sitio web, el usuario puede consentir expresamente el uso de cookies que requieran consentimiento; sin embargo, el uso de cookies técnicas o necesarias no requiere de consentimiento. Sin perjuicio de todo ello, el usuario puede modificar la configuración de su navegador para rechazar el uso de las cookies.</p>

            <h2>Cómo modificar la configuración de las cookies</h2>
            <p>Usted puede restringir, bloquear o borrar las cookies de LATENCY o cualquier otra página web utilizando su navegador. En cada navegador la operativa es diferente, puede encontrar cómo hacerlo en el menú de ayuda de su navegador dónde se establece el procedimiento para su eliminación. Para más información:</p>
            <ul>
                <li>
                    <p>
                        <strong>Google Chrome: </strong>
                        <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Mozilla Firefox: </strong>
                        <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Safari: </strong>
                        <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Internet Explorer: </strong>
                        <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer</a>
                    </p>
                </li>
            </ul>
            <p>Es posible que al deshabilitar las cookies la página web no funcione correctamente o no pueda acceder a determinadas funciones de la misma.</p>

            <h2>Cookies utilizadas en la web</h2>
            <p>En el portal web se utilizan diferentes tipos de cookies con distintas finalidades, a continuación se enumeran las cookies empleadas:</p>
            <table className="table table-striped table-bordered mb-5" id="CookieTable">
                <thead>
                <tr>
                    <th scope="col">Cookie</th>
                    <th scope="col" width="50%">Description</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Type</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>__cfduid</td>
                    <td>The cookie is set by CloudFare. The cookie is used to identify individual clients behind a
                        shared IP address and apply security settings on a per-client basis. It does not correspond to
                        any user ID in the web application and does not store any personally identifiable information.
                    </td>
                    <td>4 weeks</td>
                    <td>Necessary</td>
                </tr>
                <tr>
                    <td>_ga</td>
                    <td>This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session,
                        campaign data and keep track of site usage for the site's analytics report. The cookies store
                        information anonymously and assign a randomly generated number to identify unique visitors.
                    </td>
                    <td>2 years</td>
                    <td>Analytics</td>
                </tr>
                <tr>
                    <td>_gid</td>
                    <td>This cookie is installed by Google Analytics. The cookie is used to store information of how
                        visitors use a website and helps in creating an analytics report of how the wbsite is doing. The
                        data collected including the number visitors, the source where they have come from, and the
                        pages visited in an anonymous form.
                    </td>
                    <td>1 day</td>
                    <td>Analytics</td>
                </tr>
                <tr>
                    <td>_gat</td>
                    <td>This cookies is installed by Google Universal Analytics to throttle the request rate to limit
                        the colllection of data on high traffic sites.
                    </td>
                    <td>1 minute</td>
                    <td>Performance</td>
                </tr>
                </tbody>
            </table>

            <h2>Modificación de las condiciones</h2>
            <p>LATENCY E-SPORTS SL se reserva expresamente el derecho a modificar unilateralmente, total o parcialmente, sin necesidad de previo aviso, la presente Política de Cookies. El usuario reconoce y acepta que es su responsabilidad revisar la presente Política de Cookies.</p>
          </div>
        </Box>
    );
};

export default CookiesPolicyES;
