import services from '../../services';

export const setOpenedProfileFollowers = (userId) => {
    return dispatch => {
        services.userFollowers.get(userId)
            .then((resp) => {
                dispatch({
                    type: 'SET_OPENED_PROFILE_FOLLOWERS',
                    payload: resp
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetOpenedProfileFollowers = (data) => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE_FOLLOWERS'})
    }
}
