import React, {Fragment, useState, useEffect} from 'react';
import {Box, Tooltip, Popover} from '@material-ui/core';
import {useSelector} from 'react-redux';
import location from '../../../assets/icons/event/icon-pin-location.svg';
import guest from '../../../assets/icons/event/icon-guests.svg';
import assistants from '../../../assets/icons/event/attendees_colored.svg';
import hyperlink from '../../../assets/icons/event/icon-hyperlink.svg';
import tags from '../../../assets/icons/event/icon-tags.svg';
import privateEvent from '../../../assets/icons/event/icon-visibility-private-red.svg';
import publicEvent from '../../../assets/icons/event/icon-visibility-public.svg';
import {useTranslation} from 'react-i18next';
import MiddleEllipsis from "react-middle-ellipsis";
import abbreviate from "number-abbreviate";
import LinesEllipsis from "react-lines-ellipsis";
import UserCard from "../../User/UserCard/UserCard";

const EventTags = ({changeTab}) => {
    const user = useSelector(state => state.user);
    const event = useSelector(state => state.event)
    const {t} = useTranslation(['extraData', 'eventForm'])
    const [cats, setCats] = useState([])
    const [attendees, setAttendees] = useState([])
    const [guests, setGuests] = useState([])
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPrivateUsersCanSee, setOpenPrivateUserCanSee] = useState(false);


    useEffect(() => {
        if (event.categories) {
            if (event.categories.length > 2) {
                let cc = []
                for (let a = 0; a < 2; a++) {
                    cc.push(event.categories[a].name)
                }
                cc.sort()
                cc.push("...")
                setCats(cc)
            } else {
                let cc = []
                for (let a = 0; a < event.categories.length; a++) {
                    cc.push(event.categories[a].name)
                }
                cc.sort()
                cc.push("...")
                setCats(cc)
            }
        }
    }, [event.categories])

    useEffect(() => {
        if (event.assistants) {
            if (event.assistants.length > 3) {
                let aa = []
                for (let a = 0; a < 3; a++) {
                    aa.push(event.assistants[a])
                }
                setAttendees(aa)
            } else {
                setAttendees([...event.assistants])
            }
        }
    }, [event.assistants])

    useEffect(() => {
        if (event.guests) {
            if (event.guests.length > 3) {
                let gg = []
                for (let a = 0; a < 3; a++) {
                    gg.push(event.guests[a])
                }
                setGuests(gg)
            } else {
                setGuests([...event.guests])
            }
        }
    }, [event.guests])

    const filterURL = (url) => {
        if (url) {
            url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
            return url
        }
        return ""
    }

    const showAll = (e) => {
        setOpen(true);
        setAnchorEl(e.currentTarget);
    }


    const showPrivateUsersCanSee = (e) => {
        setOpenPrivateUserCanSee(true);
        setAnchorEl(e.currentTarget);
    }

    const descriptionHandler = (val) => {
        const regex = /(<([^>]+)>)/ig;
        const description = val.replace(regex, ' ').trim();
        if (description.length > 100) {
            return (
                <p className={'description-content'}>{description.substring(0, 100) + '...'} <span className="read-more"
                                                                                                   onClick={(e) => changeTab(e, "description")}>{t("EventForm:read_more")}</span>
                </p>
            )
        } else {
            return (
                <p className={'description-content'}>{description}</p>
            )
        }
        return (
            <p className={'description-content'}>{description.substring(0, 100) + '...'} <span className="read-more"
                                                                                               onClick={(e) => changeTab(e, "description")}>{t("EventForm:read_more")}</span>
            </p>
        )
    }

    return (
        <Fragment>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end" flexWrap="wrap"
                 className="tags">
                {event.location && event.location.length > 0 && (
                    <span className="parent" style={{
                        width: "100%",
                        justifyContent: "flex-end"
                    }}>
                                                  <LinesEllipsis
                                                      text={event.location}
                                                      maxLine='1'
                                                      ellipsis='..'
                                                      trimRight
                                                      basedOn='letters'
                                                  />
                        <Tooltip title={`${t('extraData:tag_location')}: ${event.location}`} placement="top" arrow>
                        <img src={location} alt="latency" className="icon"/>
                    </Tooltip>
                </span>
                )}
                <Box display="flex" flexDirection="row" justifyContent="flex-end" className="cats" flexWrap="wrap"
                     alignItems="center">
                    <div className={'wrapCats'} onClick={(e) => showAll(e)}>
                        {event.categories.map((item, i) => <span
                            key={`category-${i}`}>#{item.name.toLowerCase()}</span>)}
                    </div>
                    <Tooltip title={t('extraData:tag_categories')} placement="top" arrow>
                        <img src={tags} alt="latency" className="icon" onClick={(e) => showAll(e)}/>
                    </Tooltip>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => setOpen(false)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{className: "cat-modal"}}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <Box bgcolor="grid.main" className="all-cats" display="flex" flexDirection="row"
                             justifyContent="center" flexWrap="wrap">
                            {event.categories && event.categories.map(item =>
                                <span key={item.id}>#{item.name.toLowerCase()}</span>
                            )}
                        </Box>
                    </Popover>
                </Box>
                <span className="parent clickable" onClick={(e) => changeTab(e, "assistants")}>
                    <span className="icons">
                        {event.assistants.length > 3 &&
                        <span className='guest-icon with-background' key={4}>
                                <span>{`+${event.assistants.length - 3}`}</span>
                        </span>}
                        {attendees.map((item, index) => <img src={item.avatar} key={index}
                                                             alt="latency"
                                                             className="guest-icon"/>)}
                    </span>
                    <span className="text"> {t('extraData:labels_attendees')} </span>
                    <span className="count">{event.assistants ? abbreviate(event.assistants.length, 1) : 0}</span>
                    <Tooltip title={t('extraData:tag_attendees')} placement="top" arrow>
                        <img src={assistants} alt="Latency" className="icon"/>
                    </Tooltip>
                </span>
                {event.guests.length > 0 && (<span className="parent clickable" onClick={(e) => changeTab(e, "guests")}>
                    <span className="icons">
                        {event.guests.length > 3 && <span className="fake-user-circle">...</span>}
                        {guests.map((item, index) => <img src={item.avatar} alt="latency" key={index}
                                                          className="guest-icon"/>)}
                    </span>
                    <span className="text"> {t('extraData:labels_guests')} </span>
                    <span className="count">{event.guests ? abbreviate(event.guests.length, 1) : 0}</span>
                    <Tooltip title={t('extraData:tag_guests')} placement="top" arrow>
                        <img src={guest} alt="latency" className="icon"/>
                    </Tooltip>
                </span>)}
                {!event.is_private &&
                <span className="parent">
                    <span className="text">{t('eventForm:heading_public_event')}</span>
                    <Tooltip title={t('extraData:tag_visibility')} placement="top" arrow>
                            <img src={publicEvent} alt="Latency" className="icon"/>
                        </Tooltip>
                    </span>
                }
                {event.is_private &&
                <>
                    <span className="parent" style={{cursor: 'pointer'}} onClick={(e) => showPrivateUsersCanSee(e)}>
                        <span className="text">{t('eventForm:heading_private_event')}</span>
                        <Tooltip title={t('extraData:tag_visibility')} placement="top" arrow>
                                <img src={privateEvent} alt="Latency" className="icon eye"/>
                        </Tooltip>
                    </span>
                    <Popover
                        open={openPrivateUsersCanSee}
                        anchorEl={anchorEl}
                        onClose={() => setOpenPrivateUserCanSee(false)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{className: "cat-modal"}}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <Box bgcolor="grid.main" className="event-private-list-users" display="flex" flexDirection="row"
                             justifyContent="center" flexWrap="wrap">
                            <div
                                className={"subtitle"}>{t('eventForm:forms_search__private_participants_placeholder')}</div>
                            <UserCard
                                colored={true}
                                interestsCount={0}
                                toProfile={true}
                                eventInfo={true}
                                user={user}
                                profile={event.owner}
                                displayActionButtons={false}
                                handleOnGoToProfile={() => setOpenPrivateUserCanSee(false)}
                            />
                            {event.private_guest.map(e =>
                                <UserCard
                                    colored={true}
                                    key={`ev-private-guest-${e.id}`}
                                    interestsCount={0}
                                    toProfile={true}
                                    eventInfo={true}
                                    user={user}
                                    profile={e}
                                    displayActionButtons={false}
                                    handleOnGoToProfile={() => setOpenPrivateUserCanSee(false)}
                                />
                            )}
                            {event.guests.filter(e => e.event_guest.isConfirmed === true).map(e =>
                                <UserCard
                                    colored={true}
                                    key={`ev-private-guest-${e.id}`}
                                    interestsCount={0}
                                    toProfile={true}
                                    eventInfo={true}
                                    user={user}
                                    profile={e}
                                    displayActionButtons={false}
                                    handleOnGoToProfile={() => setOpenPrivateUserCanSee(false)}
                                />
                            )}
                        </Box>
                    </Popover>
                </>
                }
                {event.website && event.website.length > 0 && (
                    <span className="parent website">
                        <MiddleEllipsis>
                            <a href={(event.website && event.website.indexOf('://') === -1) ? 'https://' + event.website : event.website}
                               rel="noopener noreferrer" target="_blank"
                               className="website">{filterURL(event.website)}</a>
                        </MiddleEllipsis>
                        <Tooltip title={t('extraData:tag_website')} placement="top" arrow>
                            <img src={hyperlink} alt="Latency" className="icon"/>
                        </Tooltip>
                    </span>
                )}
            </Box>
            <Box bgcolor="grid.main"
                 className={["description", event.description && event.description.length > 0 ? '' : 'isEmpty'].join(' ')}>
                {event.description && event.description.length > 0 ? (descriptionHandler(event.description)) : (
                    <span className="description-empty">{t('extraData:event_no_description')}</span>)}
            </Box>
        </Fragment>
    )
}

export default EventTags;
