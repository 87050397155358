import React from "react";
import ReactDOM from 'react-dom';
import {Animated} from "react-animated-css";
import ArrowUpward from "../../assets/images/icons8-downward-arrow-24.png";
import {useTranslation} from "react-i18next";
import {unfollowUser} from "../../store/app/actions";
import CustomButton from "../CustomButton/CustomButton";
import CloseIcon from "@material-ui/icons/Close";
import {useDispatch} from "react-redux";
import {tutorialActionsSubEvent} from "../../store/tutorials/actions";


const TutorialSubEvent = ({refTutorialOverlayEl}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('tutorials');

    const handleOnCloseTutorial = () => {
        dispatch(tutorialActionsSubEvent(false));
    }

    const Overlay = () => {
        if (refTutorialOverlayEl.hasOwnProperty('current') && refTutorialOverlayEl.current !== null) {
            return ReactDOM.createPortal(
                <div className="suscriptor-tutorial-overlay">
                    <CustomButton
                        className="delete-btn"
                        onClick={handleOnCloseTutorial}>
                        <CloseIcon className="close-btn" /> Cerrar tutorial
                    </CustomButton>
                </div>,
                refTutorialOverlayEl.current
            )
        } else {
            return null;
        }

    }

    return (
        <>
            <Overlay/>
            <div className="suscriptor-tutorial-container">
                <Animated animationIn="pulse" animationInDelay={100} animationInDuration={1000} isVisible={true}>
                    <div className="suscriptor-tutorial-wrapper">
                        <img src={ArrowUpward}/>
                        <span className="heading">{t('tutorial_sub_event_heading')}</span>
                        <span>{t('tutorial_sub_event_paragraph')}</span>
                    </div>
                </Animated>
            </div>
        </>
    );
};

export default TutorialSubEvent;
