import React from 'react';
import './foot.scss';
import {Box} from '@material-ui/core';
import CustomLink from '../CustomLink/CustomLink';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';
import ReactStoreBadges from "react-store-badges";
import {useSelector} from 'react-redux';

const Foot = () => {
    const {t} = useTranslation(['UserMenu', 'footer', 'extraData']);

    let isEmbedMode = useRouteMatch("/embed/:userNickname/:eventID?");
    const settingsExperimental = useSelector(state => state.settings.general.experimental);
    const deviceView = useSelector(state => state.deviceView);

    if (isEmbedMode) {
        return (null)
    }

    return (
        <Box className="wrapper-footer-extended" bgcolor="header.main">
            <Box className={"footer-extended" + (settingsExperimental ? ' is-experimental-mode' : '')}>
                {/*
                <Box className={"footer-section footer-section-first"} display="flex" flexDirection="row">
                    <Logo className="loader-logo" variant={'isotype'} />
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" style={{ marginLeft: '1em'}}>
                        <div className={"footer-section-title"}>Company</div>
                        <div className={"footer-section-item"}>
                            <CustomLink to="/explore" className="foot-link"> {t('menu_label_explore')} </CustomLink>
                        </div>
                        <div className={"footer-section-item"}>
                            <CustomLink to="/contact" className="foot-link"> {t('footer:static_pages_contact')} </CustomLink>
                        </div>
                    </Box>
                </Box>
                */}
                <Box className={"footer-section footer-section-second"}>
                    <div className={"footer-section-title"}>Legal</div>
                    <Box className={"wrap-links"}>
                        <div className={"footer-section-item"}>
                            <CustomLink to="/contact"
                                        className="foot-link"> {t('footer:static_pages_contact')} </CustomLink>
                        </div>
                        <div className={"footer-section-item"}>
                            <CustomLink to="/legal-notice"
                                        className="foot-link"> {t('footer:static_pages_legal_notice')} </CustomLink>
                        </div>
                        <div className={"footer-section-item"}>
                            <CustomLink to="/terms"
                                        className="foot-link"> {t('footer:static_pages_terms')} </CustomLink>
                        </div>
                        <div className={"footer-section-item"}>
                            <CustomLink to="/privacy-policy"
                                        className="foot-link"> {t('footer:static_pages_privacy')} </CustomLink>
                        </div>
                        <div className={"footer-section-item"}>
                            <CustomLink to="/cookies-policy"
                                        className="foot-link"> {t('footer:static_pages_cookies')} </CustomLink>
                        </div>
                    </Box>
                    {deviceView === "browser" &&
                        <div>
                            <Box className="slogan-box" display="flex" flexDirection="column" justifyContent="center"
                                 alignItems="flex-start">
                                <span className="version"> BETA v{process.env.REACT_APP_VERSION} </span>
                            </Box>
                        </div>
                    }
                    <div className={"foot-copyright"}>
                        <span>&copy; Copyright 2022 LATENCY E-SPORTS SL</span>
                    </div>
                </Box>
                <Box className={"footer-section footer-section-third"}>
                    <div className={"footer-section-title"}>{t('footer:static_pages_download_app')}</div>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" className="wrap-buttons">
                        <div className={"footer-section-item"} style={{marginRight: 24}}>
                            {/*
                            <ReactStoreBadges
                                platform={'ios'}
                                url={'https://apps.apple.com/us/app/pinging/id1535464602?itsct=apps_box&amp;itscg=30200'}
                                locale={'en-us'}
                            />
                            */}
                            <a href="https://apps.apple.com/us/app/pinging/id1535464602?itsct=apps_box_badge&amp;itscg=30200"
                               style={{
                                   display: 'inline-block',
                                   overflow: 'hidden',
                                   borderRadius: '13px',
                                   width: "135px",
                                   height: "40px"
                               }}>
                                <img
                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1608336000&h=1b291f4552c4a332fa45d366ea42f4c8"
                                    alt="Download on the App Store"
                                    style={{borderRadius: "13px", width: "135px", height: "40px"}}/>
                            </a>
                        </div>
                        <div className={"footer-section-item"}>
                            <ReactStoreBadges
                                platform={'android'}
                                url={'https://play.google.com/store/apps/details?id=com.pingingapp.mobile'}
                                locale={'en-us'}
                            />
                        </div>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
}

export default Foot;
