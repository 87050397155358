import CircleAdd from "../../../../../../../assets/icons/actions/add_event_circle.svg";
import Modal from "../../../../../../Modal/Modal";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import services from "../../../../../../../services";
import LandingFeedArticle from "./LandingFeedArticle";
import LandingFeedArticleForm from "./LandingFeedArticleForm";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfiniteList from "react-infinite-scroll-list";
import "./_landing_feed_articles.scss"

const LandingFeedArticles = ({userId, allowedManage, handleSubmit}) => {
    const {t} = useTranslation('extraData');
    const deviceView = useSelector(state => state.deviceView);
    const [openForm, setOpenForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingList, setLoadingList] = useState(false);
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(4);
    const [news, setNews] = useState([]);
    const darkTheme = useSelector(state => state.darkTheme);
    const [editItem, setEditItem] = useState(undefined);

    const handlePatchFeedArticle = (data) => {
        services.landingFeed.patch(data.id, {...data, roomId: userId}).then((res) => {
            closeForm();
            loadData();
        });
    };

    useEffect(() => {
        loadData();
    }, [userId]);

    const loadData = () => {
        setLoading(true);
        if (userId) {
            services.landingFeed.find({
                query: {
                    type: 'article',
                    roomId: userId,
                    $limit: 4,
                    $sort: {
                        createdAt: -1
                    }
                }
            }).then((res) => {
                setNews(res.data)
                setTotal(res.total)
                setLoading(false);
            });
        }
    };

    const openWriterModal = () => {
        if (allowedManage) {
            setOpenForm(true);
        }
    }

    const handleDeleteArticle = (itemId) => {
        services.landingFeed.remove(itemId).then((res) => {
            setTotal(total - 1)
            setNews(news.filter(i => i.id !== itemId));
        });
    };

    const onSubmitNewForm = (data, onSuccess, onError) => {
        handleSubmit(data, () => {
            onSuccess();
        }, onError)
    };

    const onSubmitForm = (data, onSuccess, onError) => {
        if (!data.hasOwnProperty('id')) {
            onSubmitNewForm(data, () => {
                loadData();
                onSuccess();
            }, onError);
        } else {
            handlePatchFeedArticle(data);
        }
    };

    const fetchData = () => {
        if (!loadingList) {
            setLoadingList(true);
            services.landingFeed.find({
                query: {
                    type: 'article',
                    roomId: userId,
                    $skip: skip,
                    $limit: 2,
                    $sort: {
                        createdAt: -1
                    }
                }
            })
                .then((resp) => {
                    if (resp.data.length > 0) {
                        setNews([...news, ...resp.data]);
                        setSkip(skip + 2);
                    }
                    setLoadingList(false);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    const handleClickEditButton = (item) => {
        setEditItem(item);
        openWriterModal();
    };


    const closeForm = () => {
        setEditItem(undefined);
        setOpenForm(false);
    };

    return (<>
        <div className={"feed-heading"}>
            News
            {allowedManage && <img onClick={openWriterModal} src={CircleAdd} alt="add new article item"/>}
        </div>
        <div className={"landing-feed-articles"}>
            {loading ? (
                <div className={"div-loading"}><CircularProgress size={32} thickness={5} className="input-loader"/>
                </div>) : <InfiniteList
                isLoading={loadingList}
                root="container"
                isEndReached={news.length === total}
                onReachThreshold={fetchData}
                containerClassName={`scroller container-feed-article ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}
                sentinelClassName="sentinel-feed-article"
                containerTagName="div"
                sentinelTagName="div"
                threshold={100}>
                {news.length === 0 && (
                    <div className={"empty"}>{t('extraData:landing_empty_content')}</div>
                )}
                {news.map((item) =>
                    <LandingFeedArticle
                        key={item.id}
                        item={item}
                        allowRemove={allowedManage}
                        onClickDeleteButton={handleDeleteArticle}
                        onClickEditButton={() => {
                            handleClickEditButton(item)
                        }}
                    />)}
            </InfiniteList>}
        </div>
        <Modal
            state={openForm}
            max={"md"}
            radius={true}
            fullScreen={deviceView === "mobile"}
            onClose={closeForm}>
            <LandingFeedArticleForm onSubmit={onSubmitForm} onClose={closeForm} item={editItem}/>
        </Modal>
    </>);
};

export default LandingFeedArticles;
