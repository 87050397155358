import React, {Fragment, useEffect, useState} from 'react';
import NotificationSkeleton from "../Skeletons/NotificationSkeleton/NotificationSkeleton";
import Scroller from "../Scroller/Scroller";
import FollowNotification from "./FollowNotification";
import {
    markAllNotificationsSeen,
    markNotificationRead,
    setNotifications
} from "../../store/notifications/actions";
import EventChatNotification from "./EventChatNotification";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const NotificationsList = ({ handleClose }) => {
    const { t } = useTranslation(['UserMenu', 'notifications']);
    const notifications = useSelector(state => state.notifications);
    const [isEmptyList, setIsEmptyList] = useState(notifications.data.length <= 0);
    const user = useSelector(state => state.user);
    const skull = [1];
    const dispatch = useDispatch();

    useEffect(() => {
        setIsEmptyList(notifications.data.length <= 0);
    }, [notifications]);

    useEffect(() => {
        dispatch(markAllNotificationsSeen());
    }, []);

    return (
        <>
            {isEmptyList && (
                <>
                    <div className="empty-notifications">
                        <p>{t('notifications:empty_list_paragraph_one')}</p>
                        <p>{t('notifications:empty_list_paragraph_two')}</p>
                    </div>
                    {skull.map((item, index) =>
                        <NotificationSkeleton key={index}/>
                    )}
                </>
            )}
            {notifications.data.length > 0 &&
            <Scroller count={notifications.data ? notifications.data.length : 0} itemHeight={55}>
                {notifications.data.map((item, index) =>
                    <Fragment key={index}>
                        {item.notificationObject.notificationChange.actionOnEntity === "follow" &&
                        <FollowNotification onRead={(data) => dispatch(markNotificationRead(data))}
                                            data={item} user={user} onClose={handleClose}/>
                        }
                        {item.notificationObject.entityType === "message" &&
                        <EventChatNotification onRead={markNotificationRead} data={item} user={user}
                                               onClose={handleClose}/>
                        }
                    </Fragment>
                )}
                {notifications.data && notifications.data.length > 6 && <Button className="show-more-btn"
                                                                                onClick={() => dispatch(setNotifications())}>{t('notifications:buttons_see_all')}</Button>}
            </Scroller>
            }
        </>
    );
};

export default NotificationsList;