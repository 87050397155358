import {TextField} from "@material-ui/core";
import React, {Fragment, useState} from "react";
import services from "../../../services";
import CustomButton from '../../CustomButton/CustomButton';
import {useTranslation} from "react-i18next";

const StageThird = (props) => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");
    const [tokenError, setTokenError] = useState("");
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState("");
    const {t} = useTranslation(['frontPage']);

    const onTokenChange = (e) => {
        setToken(e.target.value)
        !e.target.value ? setTokenError(true) : setTokenError(false)
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        if(token){
            services.authManagement.create({
                action: 'resetPwdShort',
                value: {
                    user: {
                        email: props.email
                    },
                    token: token,
                    password: props.password
                }
            }).then((res) => {
                // success!
                setLoading(false);
                setSuccess(true);
            }).catch((err) => {
                // ups show error :)
                if(err.message === 'Invalid token. Get for a new one. (authLocalMgnt)') {
                    setFail(t('recovery_reset_pwd_invalid_token'))
                } else {
                    console.log(err)
                    setFail('Check console for expand the error..')
                }
                setLoading(false);
            });
        }else{
            setLoading(false);
            setTokenError(true);
        }
    };

    return (
        <Fragment>
            <p>{t('recovery_label-6digitcode')}</p>
            <TextField
                className="token"
                error={tokenError}
                type="text"
                label={t('recovery_labelInput-6digitcode')}
                value={token}
                variant="outlined"
                onChange={onTokenChange}
                onKeyDown={handleKeyDown} />

            {!success && <CustomButton loading={loading} className="submit-btn" color="primary" onClick={handleSubmit}>{t('recovery_stageThird_btn')}</CustomButton>}
            {success && <p>{t('recovery_stageThird_success')}</p>}
            {fail && <p>{fail}</p>}
        </Fragment>
    )
};

export default StageThird;
