import React, {Fragment} from "react";
import Button from "@material-ui/core/Button";
import './advancedbutton.scss';

const AdvancedButton = ({type, className, iconLeft, iconRight, label, status, onClick, disabled}) => {
    return (
        <Button
            className={"advanced-button " + (className ? className : "") + " "
            + (type === "outline" ? " is-outline" : type === "text" ? " is-text" : " is-normal") +
            (status === true ? " is-status-on" : " is-status-off") + (iconLeft !== undefined ? " has-icon-left" : "") + (iconRight !== undefined ? " has-icon-right" : "")}
            onClick={onClick}
            disabled={disabled}>
            <Fragment>
                {iconLeft && <span className="icon icon-left"><img src={iconLeft} alt={label}/></span>}
                <span className="label">{label}</span>
                {iconRight && <span className="icon icon-right"><img src={iconRight} alt={label}/></span>}
            </Fragment>
        </Button>
    );
};

export default AdvancedButton;
