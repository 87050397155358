import React from 'react';
import './legalnotice.scss';
import {Box} from '@material-ui/core';
import {Helmet} from "react-helmet";

const LegalNotice = () => {
    return (
        <Box className="legalnotice-page">
            <Helmet>
                <title>Legal Notice / Latency</title>
            </Helmet>
            <div className="static-content">
                <h2>Legal Information</h2>
                <p>We inform you that the content of this website https://latencyapp.com (hereinafter, the "Web") belongs to LATENCY E-SPORTS SL, with registered office at Plaza de España 10, 1ºB, Las Rozas de Madrid, Madrid, Postal Code 28231 (Spain) and registered number B88053871.</p>

                <h2>User Acceptance</h2>
                <p>This Legal Notice regulates access to and use of the following website https://latencyapp.com, which LATENCY E-SPORTS SL makes available to Internet users. Therefore, access to it implies the acquisition of the condition of user and the acceptance of the conditions of use of the website. In the event of disagreement with the conditions, the user should abstain from using the website.</p>
                <p>The user is informed and accepts that access to this website does not imply, in any way, the beginning of a commercial relationship with LATENCY E-SPORTS SL.</p>

                <h2>Content and Use</h2>
                <p>The user's visit to the website should be made in a responsible manner and in accordance with current legislation, good faith, this Legal Notice and respecting the intellectual and industrial property rights owned by LATENCY E-SPORTS SL.</p>
                <p>The use of any of the contents of the website for purposes that are or could be illicit is totally prohibited, as well as causing any damage or alterations of any type not consented by LATENCY E-SPORTS SL, to the website or its contents.</p>
                <p>The owner of the website does not identify himself with the opinions expressed therein by his collaborators.</p>
                <p>The company reserves the right to modify, discontinue or terminate the Site, Web Services, Data and Third Party Data or to modify this Agreement, any time and without prior notice.</p>

                <h2>Warranties & limitation of liability</h2>
                <p>LATENCY E-SPORTS SL declares that it has adopted the necessary measures which, within its possibilities and the state of the technology, allow the correct functioning of the website as well as the absence of viruses and harmful components. However,LATENCY E-SPORTS SL cannot be held responsible for the following situations listed by way of illustration, but not limitation:</p>
                <ul>
                    <li>The continuity and availability of the contents.</li>
                    <li>The absence of errors in such content or the correction of any defect that may occur.</li>
                    <li>The absence of viruses and/or other harmful components.</li>
                    <li>Damages caused by any person who violates LATENCY E-SPORTS SL security systems.</li>
                    <li>The use that the users can make of the contents included in the web. Consequently, LATENCY E-SPORTS SL does not guarantee that the use that users may make of the contents included in the website, if any, comply with this legal notice, nor that the use of the website is carried out in a diligent manner.</li>
                    <li>The use by minors of the website or the sending of their personal data without the permission of their tutors, the tutors being responsible for the use they make of the Internet.</li>
                    <li>The contents to which the user can access through unauthorized links or introduced by users through comments or similar tools.</li>
                    <li>The introduction of erroneous data by the user or a third party.</li>
                    <li>Content and use Intellectual and industrial property.</li>
                </ul>
                <p>LATENCY E-SPORTS SL may temporarily suspend, without prior notice, accessibility to the website for the purposes of maintenance, repair, updating or improvement operations. Consequently, LATENCY E-SPORTS SL shall under no circumstances be liable for any damages that may derive from the lack of availability or accessibility to the website or from the interruption in the operation of the website. However, whenever circumstances permit, LATENCY E-SPORTS SL shall inform the user, sufficiently in advance, of the date foreseen for the suspension of the contents.</p>
                <p>LATENCY E-SPORTS SL undertakes to eliminate or, where appropriate, block the contents that could affect or be contrary to current legislation, the rights of third parties or public order and morality.</p>

                <h2>Intellectual and industrial property policy</h2>
                <p>LATENCY E-SPORTS SL is the exclusive owner of the intellectual property rights relating to its website and social networks. All site, product and services content as text, graphic designs, user interfaces, trademarks or computer codes that are part of https://latencyapp.com are exclusive property of LATENCY E-SPORTS SL. Their reproduction, distribution, public communication, transformation or any other activity carried out with the contents is prohibited, not even if the sources are mentioned, except with the prior, express and written consent of LATENCY E-SPORTS SL</p>
                <p>LATENCY E-SPORTS SL does not grant any kind of authorisation to use its intellectual and industrial property rights or any other property or right related to the website, and in no case shall it be understood that access and browsing by users implies a waiver, transmission, licence or total or partial cession of said rights on the part of LATENCY E-SPORTS SL. Any use of these contents not previously authorised by LATENCY E-SPORTS SL will be considered a serious breach of the intellectual or industrial property rights and will give rise to the legally established responsibilities. LATENCY E-SPORTS SL reserves the right to exercise the corresponding judicial and extrajudicial actions against the user.</p>


                <h2>Data protection clause</h2>
                <p>In accordance with Data Protection current legislation, users are informed that their data will be incorporated into the Record of Processing Activities owned by LATENCY E-SPORTS SL in order to carry out the contracted services.</p>
                <p>In compliance with current legislation, LATENCY E-SPORTS SL informs that the data will be stored for the period strictly necessary to comply with the services provided.</p>
                <p>Data will also be treated in a lawful, fair, transparent, appropriate, relevant, limited, accurate and up-to-date manner. For this reason, LATENCY E-SPORTS SL undertakes to adopt all reasonable technical and security measures so that these are deleted or rectified without delay when they are inaccurate or untrue.</p>
                <p>In accordance with data protection regulations, you may exercise your rights of access, rectification, limitation of treatment, suppression, portability and opposition to the treatment of your personal data, as well as renounce the consent given for their treatment. The request should be sent to the following e-mail address: info@latencyapp.com</p>


                <h2>Links policy</h2>
                <p>It is not allowed to introduce links in any web page property of LATENCY E-SPORTS SL without the express consent of the owner.</p>
                <p>LATENCY E-SPORTS SL does not control the content of other sites from which this website can be accessed. Although LATENCY E-SPORTS SL reserves the right to contact the owner of the website on which the link is established if it considers that the regulations are not being complied with, as well as to exercise the corresponding judicial and extrajudicial actions.</p>

                <h2>Reservation of the right to modify this legal notice</h2>
                <p>LATENCY E-SPORTS SL reserves the right to modify this legal notice in order to adapt it to any change of the regulations, for technical reasons, for changes in the services offered by LATENCY E-SPORTS SL or for strategic decisions of the company. In these cases, LATENCY E-SPORTS SL will notify you about the changes to the terms and conditions through the Website.</p>
                <p>If the user is not satisfied with the changes, he or she must stop using the website. The use of the website after the changes have been made will imply acceptance of the changes by the Users.</p>

                <h2>Contact with us</h2>
                <p>You can contact us at the above address. Also from the website itself by e-mail to info@latencyapp.com , provided that the computer from which you connect has a mail program properly configured and operational.</p>
            </div>
        </Box>
    )
}

export default LegalNotice;
