import React from 'react';
import { isPast } from 'date-fns';
import { ownFormatDuration } from "../../../helpers/dateFormatter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const EventDateCountdown = ({ date }) => {
    const { t } = useTranslation(['extraData']);
    const darkTheme = useSelector(state => state.darkTheme);

    return (
        <>
            {
                !isPast(date) && (<div className={`custom-date-countdown ${(darkTheme ? " is-dark" : " is-light")}`}>
                    <span className={"label"}>{t('countdown_starts_in')} </span>
                    <span className={"countdown"}>{ownFormatDuration(date)}</span>
                </div>)
            }
        </>
    )
};

export default EventDateCountdown;
