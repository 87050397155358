import React, {useState} from 'react';
import {
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Grid,
    IconButton, Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import twitchLightIcon from '../../../../../assets/icons/actions/twitch-white.svg';
import twitchDarkIcon from '../../../../../assets/icons/actions/twitch-dark.svg';
import {useSelector} from "react-redux";
import "./_landing_participants.scss"

const LandingParticipantsComponent = ({userId}) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const [tortillaLandData, setTortillaLandData] = useState(
        [
            {
                "name": "Ibai",
                "twitter": "https://twitter.com/IbaiLlanos",
                "twitch": "https://www.twitch.tv/ibai",
                "youtube": "https://www.youtube.com/channel/UCaY_-ksFSQtTGk0y1HA_3YQ",
                "instagram": "https://www.instagram.com/ibaillanos/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/ibai.jpg",
                "latency_id": ""
            },
            {
                "name": "Auron",
                "twitter": "https://twitter.com/auronplay",
                "twitch": "https://www.twitch.tv/AuronPlay",
                "youtube": "https://www.youtube.com/user/AuronPlay",
                "instagram": "https://www.instagram.com/auronplay/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/AuronPlay.jpg",
                "latency_id": ""
            },
            {
                "name": "Rubius",
                "twitter": "https://twitter.com/Rubiu5",
                "twitch": "https://www.twitch.tv/Rubius",
                "youtube": "https://www.youtube.com/c/elrubiusOMG",
                "instagram": "https://www.instagram.com/elrubiuswtf/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/Rubius.jpg",
                "latency_id": ""
            },
            {
                "name": "TheGrefg",
                "twitter": "https://twitter.com/TheGrefg",
                "twitch": "https://www.twitch.tv/thegrefg",
                "youtube": "https://www.youtube.com/user/TheGrefg",
                "instagram": "https://www.instagram.com/grefg_official/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/thegrefg.jpg",
                "latency_id": ""
            },
            {
                "name": "Cristinini",
                "twitter": "https://twitter.com/IamCristinini",
                "twitch": "https://www.twitch.tv/iamcristinini",
                "youtube": "https://www.youtube.com/c/Cristinini",
                "instagram": "https://www.instagram.com/iamcristinini/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/iamcristinini.jpg",
                "latency_id": ""
            },
            {
                "name": "Luzu",
                "twitter": "https://twitter.com/LuzuGames",
                "twitch": "https://www.twitch.tv/luzu",
                "youtube": "https://www.youtube.com/user/luzugames",
                "instagram": "https://www.instagram.com/luzu/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/luzu.jpg",
                "latency_id": ""
            },
            {
                "name": "Mister Jagger",
                "twitter": "https://twitter.com/MisterJagger_",
                "twitch": "https://www.twitch.tv/jaggerprincesa",
                "youtube": "https://www.youtube.com/user/themisterjagger",
                "instagram": "https://www.instagram.com/mister_jagger/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/jaggerprincesa.jpg",
                "latency_id": ""
            },
            {
                "name": "Jacky",
                "twitter": "https://twitter.com/CooLifeGame",
                "twitch": "https://www.twitch.tv/coolifegame",
                "youtube": "https://www.youtube.com/user/CooLifeGame",
                "instagram": "https://www.instagram.com/reybronza/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/coolifegame.jpg",
                "latency_id": ""
            },
            {
                "name": "MenosTrece",
                "twitter": "https://twitter.com/menos_trece",
                "twitch": "https://www.twitch.tv/menostrece",
                "youtube": "https://www.youtube.com/user/MenosTreceYT",
                "instagram": "https://www.instagram.com/menos_trece/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/menostrece.jpg",
                "latency_id": ""
            },
            {
                "name": "Lolito",
                "twitter": "https://twitter.com/LOLiTOFDEZ",
                "twitch": "",
                "youtube": "https://www.youtube.com/user/LOLiTOBRO",
                "instagram": "https://www.instagram.com/lowlito/",
                "facebook": "https://fb.gg/lolitofdez",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/lolitofdez.jpg",
                "latency_id": ""
            },
            {
                "name": "Zorman",
                "twitter": "https://twitter.com/ZormanVideos",
                "twitch": "https://www.twitch.tv/zormanworld",
                "youtube": "https://www.youtube.com/user/senorterror",
                "instagram": "https://www.instagram.com/zormanyeh/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/zormanworld.jpg",
                "latency_id": ""
            },
            {
                "name": "Folagor",
                "twitter": "https://twitter.com/FolagoR",
                "twitch": "https://www.twitch.tv/folagorlives",
                "youtube": "https://www.youtube.com/c/Folagor03",
                "instagram": "https://www.instagram.com/yoel__ramirez/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/folagorlives.jpg",
                "latency_id": ""
            },
            {
                "name": "Fargan",
                "twitter": "https://twitter.com/xFaRgAnx",
                "twitch": "",
                "youtube": "https://www.youtube.com/user/xFaRgAnx",
                "instagram": "https://www.instagram.com/xfarganx_/",
                "facebook": "https://fb.gg/farganfb",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/farganfb.jpg",
                "latency_id": ""
            },
            {
                "name": "Perxitaa",
                "twitter": "https://twitter.com/Perxitaa",
                "twitch": "https://www.twitch.tv/perxitaa",
                "youtube": "https://www.youtube.com/user/perxitaa",
                "instagram": "https://www.instagram.com/perxitaa/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/perxitaa.jpg",
                "latency_id": ""
            },
            {
                "name": "Karchez",
                "twitter": "https://twitter.com/Karchezzz",
                "twitch": "https://www.twitch.tv/karchez",
                "youtube": "https://www.youtube.com/c/karchez",
                "instagram": "https://www.instagram.com/karchezz/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/karchez.jpg",
                "latency_id": ""
            },
            {
                "name": "Juan Garnizo",
                "twitter": "https://twitter.com/JuanSGuarnizo",
                "twitch": "https://www.twitch.tv/juansguarnizo",
                "youtube": "https://www.youtube.com/c/JuanSGuarnizo",
                "instagram": "https://www.instagram.com/juansguarnizo/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/juansguarnizo.jpg",
                "latency_id": ""
            },
            {
                "name": "Genesis",
                "twitter": "https://twitter.com/GenesisGtv",
                "twitch": "https://www.twitch.tv/gtv_genesis",
                "youtube": "",
                "instagram": "https://www.instagram.com/genesisgtv/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/gtv_genesis.jpg",
                "latency_id": ""
            },
            {
                "name": "Carola",
                "twitter": "https://twitter.com/TheCarolos",
                "twitch": "https://www.twitch.tv/carola",
                "youtube": "https://www.youtube.com/channel/UCdhcvooVFqcc2eQUwM38J3Q",
                "instagram": "https://www.instagram.com/thecarolos/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/carola.jpg",
                "latency_id": ""
            },
            {
                "name": "Biyin",
                "twitter": "https://twitter.com/_biyin_",
                "twitch": "https://www.twitch.tv/biyin_",
                "youtube": "https://www.youtube.com/channel/UC4oYV_hjH0XhMl7thB_jZhQ",
                "instagram": "https://www.instagram.com/_bjean_/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/biyin_.jpg",
                "latency_id": ""
            },
            {
                "name": "Axozer",
                "twitter": "https://twitter.com/aXoZerr",
                "twitch": "https://www.twitch.tv/axozer",
                "youtube": "https://www.youtube.com/c/aXoZer",
                "instagram": "https://www.instagram.com/aXoZerr/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/axozer.jpg",
                "latency_id": ""
            },
            {
                "name": "Arigameplays",
                "twitter": "https://twitter.com/arigameplays",
                "twitch": "",
                "youtube": "https://www.youtube.com/arigameplays",
                "instagram": "https://www.instagram.com/arigameplays/",
                "facebook": "https://fb.gg/arigameplays",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/arigameplays.jpg",
                "latency_id": ""
            },
            {
                "name": "Betra",
                "twitter": "https://twitter.com/FranCiancioci",
                "twitch": "https://www.twitch.tv/betra",
                "youtube": "",
                "instagram": "https://www.instagram.com/franciancioci/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/betra.jpg",
                "latency_id": ""
            },
            {
                "name": "Deqiuv",
                "twitter": "https://twitter.com/DeqiuV",
                "twitch": "https://www.twitch.tv/deqiuv",
                "youtube": "https://www.youtube.com/c/DeqiuV",
                "instagram": "https://www.instagram.com/deqiuv/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/deqiuv.jpg",
                "latency_id": ""
            },
            {
                "name": "Laura Escanes",
                "twitter": "https://twitter.com/LauraEscanes",
                "twitch": "https://www.twitch.tv/lauraescanes",
                "youtube": "https://www.youtube.com/lauraescanes",
                "instagram": "https://www.instagram.com/lauraescanes/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/lauraescanes.jpg",
                "latency_id": ""
            },
            {
                "name": "Momon",
                "twitter": "https://twitter.com/MomonKun_",
                "twitch": "https://www.twitch.tv/momonkunn",
                "youtube": "https://www.youtube.com/c/MomonKun",
                "instagram": "https://www.instagram.com/momonkunlive/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/momonkunn.jpg",
                "latency_id": ""
            },
            {
                "name": "Nia Lakshart",
                "twitter": "https://twitter.com/LakshartNia",
                "twitch": "https://www.twitch.tv/lakshartnia",
                "youtube": "https://www.youtube.com/user/LakshartNia",
                "instagram": "https://www.instagram.com/lakshartniayt/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/lakshartnia.jpg",
                "nombre real": "",
                "latency_id": ""
            },
            {
                "name": "Paracetamor",
                "twitter": "https://twitter.com/paracetamor",
                "twitch": "https://www.twitch.tv/paracetamor",
                "youtube": "https://www.youtube.com/paracetamor",
                "instagram": "https://www.instagram.com/_paracetamor/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/paracetamor.jpg",
                "latency_id": ""
            },
            {
                "name": "Pato",
                "twitter": "https://twitter.com/PatodeAqualand",
                "twitch": "https://www.twitch.tv/patodeaqualand",
                "youtube": "https://www.youtube.com/c/DvdcdzGames",
                "instagram": "https://www.instagram.com/patodeaqualand/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/patodeaqualand.jpg",
                "latency_id": ""
            },
            {
                "name": "Xthefocusx",
                "twitter": "https://twitter.com/xTheFocuSx",
                "twitch": "https://www.twitch.tv/xxxthefocusxxx",
                "youtube": "https://www.youtube.com/c/xTheFocuSx",
                "instagram": "",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/xxxthefocusxxx.jpg",
                "latency_id": ""
            },
            {
                "name": "Tanizen",
                "twitter": "https://twitter.com/TanizeN",
                "twitch": "https://www.twitch.tv/tanizen",
                "youtube": "https://www.youtube.com/c/TanizenTV",
                "instagram": "https://www.instagram.com/tanizen/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/tanizen.jpg",
                "latency_id": ""
            },
            {
                "name": "Mayichi",
                "twitter": "https://twitter.com/Mayichii",
                "twitch": "https://www.twitch.tv/mayichi",
                "youtube": "https://www.youtube.com/c/Mayichi",
                "instagram": "https://www.instagram.com/mayichiii/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/mayichi.jpg",
                "latency_id": ""
            },
            {
                "name": "Violeta G",
                "twitter": "https://twitter.com/soyvioletag",
                "twitch": "https://www.twitch.tv/violetag",
                "youtube": "https://www.youtube.com/c/SuperVioletaGamerYT",
                "instagram": "https://www.instagram.com/soyvioletag/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/violetag.jpg",
                "latency_id": ""
            },
            {
                "name": "Gemita",
                "twitter": "https://twitter.com/gema327",
                "twitch": "https://www.twitch.tv/gemita",
                "youtube": "",
                "instagram": "https://www.instagram.com/gemita327/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/gemita.jpg",
                "latency_id": ""
            },
            {
                "name": "Pol",
                "twitter": "https://twitter.com/polispol",
                "twitch": "https://www.twitch.tv/polmanzan",
                "youtube": "https://www.youtube.com/channel/UCISR0iuscKPmi57GndEyElA",
                "instagram": "https://www.instagram.com/polispol/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/polmanzan.jpg",
                "latency_id": ""
            },
            {
                "name": "Josecristo",
                "twitter": "https://twitter.com/Jos3cristo",
                "twitch": "https://www.twitch.tv/josecristo_",
                "youtube": "https://www.youtube.com/channel/UCgGTOiKZUqiCCEWRHaQ-KuQ",
                "instagram": "https://www.instagram.com/_josecristo_/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/josecristo_.jpg",
                "latency_id": ""
            },
            {
                "name": "Ampeterby7",
                "twitter": "https://twitter.com/Ampeterby7",
                "twitch": "https://www.twitch.tv/ampeterby7",
                "youtube": "https://www.youtube.com/ampeterby7",
                "instagram": "https://www.instagram.com/ampeterby7_/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/ampeterby7.jpg",
                "latency_id": ""
            },
            {
                "name": "Komanche",
                "twitter": "https://twitter.com/komanch",
                "twitch": "https://www.twitch.tv/komanche",
                "youtube": "https://www.youtube.com/c/Komanche",
                "instagram": "https://www.instagram.com/elkomanche/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/komanche.jpg",
                "latency_id": ""
            },
            {
                "name": "D3stri",
                "twitter": "https://twitter.com/D3strii",
                "twitch": "https://www.twitch.tv/d3stri",
                "youtube": "https://www.youtube.com/c/D3stri",
                "instagram": "https://www.instagram.com/d3strii/",
                "facebook": "",
                "picture": "https://cdn-01.latencyapp.com/static-tortillaland-resources/d3stri.jpg",
                "latency_id": ""
            },
        ]
    );

    if (userId !== '4e42510f-afd8-4f3b-b072-32ad4fc9cf19') { //check is tortillaland
        return null;
    }

    return (
        <Box m={1} className="landing-participants">
            <Grid container spacing={2}>
                {tortillaLandData.map((x, i) =>
                    <Grid item key={i} md={3} xs={6} lg={2}>
                        <Paper className="participant-card" elevation={3}>
                            <Box>
                                <Box style={{padding: "0px"}}>
                                    <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                                        <Typography variant="h5" component="h2" color="primary">
                                            {x.name}
                                        </Typography>
                                    </Box>
                                    <Box m={0} p={0} overflow="hidden">
                                        <img src={x.picture} alt={x.name} width="100%" className="card-img animated"/>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box display="flex" justifyContent="space-evenly" alignItems="center" width="100%"
                                     padding="4px">
                                    {x.twitch &&
                                    <a href={x.twitch} rel="noopener noreferrer" target="_blank">
                                        <IconButton size="small" disabled>
                                            {darkTheme &&
                                            <img src={twitchLightIcon} alt="twitch icon"
                                                 style={{width: "18px"}}/>
                                            }
                                            {!darkTheme &&
                                            <img src={twitchDarkIcon} alt="twitch icon"
                                                 style={{width: "18px"}}/>
                                            }
                                        </IconButton>
                                    </a>
                                    }
                                    {x.facebook &&
                                    <a href={x.facebook} target="_blank">
                                        <IconButton size="small">
                                            <FacebookIcon/>
                                        </IconButton>
                                    </a>
                                    }
                                    {x.twitter &&
                                    <a href={x.twitter} target="_blank">
                                        <IconButton size="small">
                                            <TwitterIcon/>
                                        </IconButton>
                                    </a>
                                    }
                                    {x.youtube &&
                                    <a href={x.youtube} target="_blank">
                                        <IconButton size="small">
                                            <YouTubeIcon/>
                                        </IconButton>
                                    </a>
                                    }
                                    {x.instagram &&
                                    <a href={x.instagram} target="_blank">
                                        <IconButton size="small">
                                            <InstagramIcon/>
                                        </IconButton>
                                    </a>
                                    }
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                )}</Grid>
        </Box>
    );
};


export default LandingParticipantsComponent;
