import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useHistory} from "react-router-dom";
import {matchPath} from "react-router";
import {getUserProfile} from "../../../store/user/actions";
import {removeProfile} from "../../../store/app/actions";
import {Box, Tab, Tabs} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import LandingCoverComponent from "../LandingCover/LandingCoverComponent";
import ProfileAgenda from "../../Profile/ProfileAgenda/ProfileAgenda";
import LandingMainTabLiveNow from "./fragments/LiveNowComponent/LandingMainTabLiveNow";
import './_landing_main.scss';
import LandingHeader from "./fragments/Header/LandingHeader";
import LandingFeedComponent from "./fragments/LandingFeedComponent/LandingFeedComponent";
import AddIcon from '@material-ui/icons/Add';
import services from "../../../services";
import LandingHighlightsComponent from "./fragments/LandingHighlightsComponent/LandingHighlightsComponent";
import {useTranslation} from "react-i18next";
import DragScrollProvider from 'drag-scroll-provider'
import EventsPassedComponentByDay from "./fragments/EventsPassedComponent/LandingEventsPassedByDayComponent";
import LandingCustomTabComponent from "./fragments/LandingCustomTabComponent/LandingCustomTabComponent";
import {TwitchChat} from "react-twitch-embed";
import LandingCustomTabForm from "./fragments/LandingCustomTabComponent/fragments/LandingCustomTabForm";
import FeedbackForm from "./fragments/FeedbackForm/FeedbackForm";
import LandingChat from "../MarbellaVice/Landing/LandingChat";
import LandingParticipantsComponent from "./fragments/LandingParticipantsComponent/LandingParticipantsComponent";
import LandingTeamsComponent from "./fragments/LandingTeamsComponent/LandingTeamsComponent";
import Modal from "../../Modal/Modal";
import {setOpenedProfileWeeklyEvents} from "../../../store/openedProfileWeeklyEvents/actions";
import {Event} from "../../../services/tracking";
import {Helmet} from "react-helmet";

const componentName = "LandingMain";

const LandingMain = () => {
    const location = useLocation();
    const [eventsWithPlayerOpen, setEventsWithPlayerOpen] = useState([]);
    const [eventWithClipOpen, setEventWithClipOpen] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const darkTheme = useSelector(state => state.darkTheme);
    const [userID, setUserID] = useState(undefined);
    const [userSlug, setUserSlug] = useState("");
    const [profileData, setProfileData] = useState(undefined);
    const deviceView = useSelector(state => state.deviceView);
    const dispatch = useDispatch();
    const history = useHistory();
    const [tab, setTab] = useState(0);
    const [sidebarTab, setSidebarTab] = useState(0);
    const [customTabs, setCustomTabs] = useState([]);
    const [openTabForm, setOpenTabForm] = useState(false);
    const [openFeedbackForm, setOpenFeedbackForm] = useState(false);
    const {i18n, t} = useTranslation('landing');
    const _defaultLandingTabs = [t("home"), t("news"), t("live_now"), t("highlights"), t("agenda"), t("passed_vods")];
    const [landingTabs, _setLandingTabs] = useState(_defaultLandingTabs);
    const landingTabsRef = React.useRef(landingTabs);
    const setLandingTabs = data => {
        landingTabsRef.current = data;
        _setLandingTabs(data);
    };

    const loggedIn = useSelector(state => state.loggedIn)
    const user = useSelector(state => state.user)
    const [allowedManage, setAllowedManage] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const resources = {
        "giants.crew.by.latency": {
            headerVideo: "/dist/special/giantscrew.mp4",
            headerMobile: "/dist/special/giantscrew-mobile.jpg"
        },
        "aimlab.master.challenge": {
            headerVideo: "/dist/special/aimlabmasterchallenge2-compress.mp4",
            headerVideoWebm: "/dist/special/aimlabmasterchallenge2.webm",
            headerMobile: "/dist/special/aimlabmasterchallenge-mobile2.jpg"
        },
        "valorant.oficial": {
            headerVideo: "/dist/special/valorant.mp4",
            headerVideoWebm: "/dist/special/valorant.webm",
            headerMobile: "/dist/special/valorant-mobile.jpg"
        },
        "tortillaland": {
            headerVideo: "/dist/special/tortillaland.mp4",
            headerVideoWebm: "/dist/special/tortillaland.webm",
            headerMobile: "/dist/special/tortillaland-mobile.jpg"
        },
        "espacio.crypto": {
            headerVideo: "/dist/special/crypto.mp4",
            headerVideoWebm: "/dist/special/crypto.webm",
            headerMobile: "/dist/special/crypto-mobile.jpg"
        }
    };

    const redirect = () => {
        history.push('/not-found');
    }

    useEffect(() => {
        const match = matchPath(history.location.pathname, {
            path: "/:user/:type(live)?",
            exact: true,
            strict: false
        });
        if (match.isExact) {
            if (profileData && profileData.hasOwnProperty('slug') && profileData.slug === match.params.user) {
                return;
            }
            let param_user = match.params.user;
            if(param_user === "valorant") {
                param_user = "valorant.oficial"
            }
            setLoading(true);
            dispatch(getUserProfile(param_user, redirect, (user) => {
                setProfileData(user);
                setUserID(user.id);
                setUserSlug(user.slug.replaceAll('.', '-'))
                setLoading(false);
            }));
        } else {
            redirect();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (userID && user && user.hasOwnProperty('id')) {
            setIsOwner(!!(user && user.id === userID));
        }
        if (user && user.hasOwnProperty('id')) {
            setIsAdmin(user && user.id ? user.roles.includes('admin') : false)
        }
    }, [user, userID]);

    useEffect(() => {
        checkTabs();
    }, [user, isOwner, allowedManage])

    const changeTab = (e, val) => {
        if (typeof val === 'number' && (val % 1) === 0) {
            let tab = landingTabsRef.current[val];
            if (tab !== undefined) {
                setTab(val);
                let slugify = tab.toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
                history.push(`#${slugify}`)
                Event("LandingMain", "ChangeTab", tab);
            }
        } else {
            handleCustomTab(val);
            let tab = landingTabsRef.current.indexOf(val);
            if (tab !== undefined) {
                let slugify = landingTabsRef.current[tab].toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
                history.push(`#${slugify}`)
                Event(componentName, "ChangeTab", landingTabsRef.current[tab]);
            }
        }
    }

    const handleCustomTab = (val) => {
        let tab = landingTabsRef.current.indexOf(val);
        if (tab !== -1) {
            setTab(tab)
            window.scrollTo(0, 0)
        }
    }

    const changeSidebarTab = (e, val) => {
        setSidebarTab(val);
    }

    const checkTabs = () => {
        if (userID) {
            setTab(0);
            //dispatch(prepareProfile(new Date(), userID, redirect));
            dispatch(setOpenedProfileWeeklyEvents(new Date(), userID))
            let tabs = _defaultLandingTabs;
            if (userID === "7ac0b720-06a6-4257-97f9-899e63eb6fd5") {
                tabs = [t("home"), t("best_clip"), t("news"), t("highlights"), t("agenda")];
            } else if (userID === "5b755b48-203a-40af-9934-30b4292b468b") {
                tabs = [t("home"), t("agenda"), t("live_now"), t("highlights"), t('teams')];
                if (allowedManage) {
                    tabs.push(t('news'))
                }
            } else if (userID === "b8b5720c-5cd2-4638-9d51-b832b9443d34") {
                tabs = [t("home"), t("agenda"), t("highlights"), t('teams')];
                if (allowedManage) {
                    tabs.push(t('news'))
                }
            } else if (userID === "4e42510f-afd8-4f3b-b072-32ad4fc9cf19") {
                tabs = [t("home"), t("news"), t("live_now"), t("highlights"), t("agenda"), t("passed_vods"), t('participants')];
            } else if (userID === "ce777e3c-f047-424d-9ee5-070b578caed3") {
                tabs = [t("home"), t("news"), t("live_now"), t("highlights"), t("agenda"), t("passed_vods")]
            } else if (userID === "f11e468d-7cc9-4846-9aff-199d85a5457e") {
                tabs = [t("home"), t("news"), t("agenda")]

            }

            services.landingFeed.find({
                query: {
                    type: 'custom',
                    roomId: userID,
                    $sort: {
                        createdAt: -1
                    }
                }
            }).then((res) => {
                let data_reversed = res.data.reverse()
                setCustomTabs(data_reversed);
                let final_tabs = [...tabs, ...data_reversed.map(item => item.title)]
                setLandingTabs(final_tabs);
                if (location.hash && location.hash.length > 0) {
                    const found = final_tabs.find(element => element.toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '') === location.hash.replace("#", ""));
                    if (found) {
                        setTab(final_tabs.indexOf(found));
                    }
                }
            }).catch((err) => {
                setCustomTabs([]);
                setLandingTabs([...tabs]);
                if (location.hash && location.hash.length > 0) {
                    const found = tabs.find(element => element.toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '') === location.hash.replace("#", ""));
                    if (found) {
                        setTab(tabs.indexOf(found));
                    }
                }
            });

            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        loadCustomTabs();
        return () => {
            dispatch(removeProfile());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID]);

    const handleOpenPlayer = (eventId) => {
        if (!eventsWithPlayerOpen.includes(eventId)) {
            setEventsWithPlayerOpen([...eventsWithPlayerOpen, eventId]);
        }
        Event(componentName, "OpenPlayer", eventId);
    };
    const handleOpenClip = (eventId) => {
        setEventWithClipOpen(eventId);
        Event(componentName, "OpenClip", eventId);
    };

    const handleDeleteOnePlayer = (eventId) => {
        setEventsWithPlayerOpen([...eventsWithPlayerOpen.filter(i => i !== eventId)]);
        if (sidebarTab !== 0) {
            setSidebarTab(0);
        }
        Event(componentName, "DeleteOnePlayer", eventId);
    }
    useEffect(() => {
        if (loggedIn) {
            if (user.id === userID) {
                setAllowedManage(true);
            } else {
                services.userLandingRoles.find({
                    query: {
                        roomId: userID
                    }
                }).then((res) => {
                    if (res.hasOwnProperty('allowed') && res.allowed === true) {
                        setAllowedManage(true);
                    }
                }).catch(err => setAllowedManage(false));
            }
        } else {
            setAllowedManage(false);
        }
    }, [loggedIn, user, userID]);

    const loadCustomTabs = () => {
        if (userID) {
            services.landingFeed.find({
                query: {
                    type: 'custom',
                    roomId: userID,
                    $sort: {
                        createdAt: -1
                    }
                }
            }).then((res) => {
                let data_reversed = res.data.reverse()
                setCustomTabs(data_reversed);
                setLandingTabs([...landingTabs, ...data_reversed.map(item => item.title)])
            });
        }
        checkTabs();
    }

    const onSubmitTabModal = (data, onSuccess, onError) => {
        services.landingFeed.create({...data, roomId: userID}).then(res => {
            onSuccess(res);
            loadCustomTabs();
            Event(componentName, "AddCustomTab", data.custom_fields.title);
        }).catch(err => {
            onError(err);
        })
    };

    const handleDeleteClip = (itemId) => {
        services.landingFeed.remove(itemId).then((res) => {
            loadCustomTabs();
            setTab(0);
            Event(componentName, "DeleteClip", itemId);
        });
    };

    const setOpenFeedbackFormOn = () => {
        setOpenFeedbackForm(true);
    };

    const setOpenFeedbackFormOff = () => {
        setOpenFeedbackForm(false);
    };

    return (
        <Box
            className={`latency-landing-main ${darkTheme ? " is-dark-theme" : " is-light-theme"} ${deviceView === 'browser' ? ' is-desktop' : ''} ${deviceView === 'mobile' ? ' is-mobile' : ''} ${deviceView === 'tablet' ? ' is-tablet' : ''}`}
            display="flex"
            flexDirection="row" justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">
            {loading && <div className="loading">
                <CircularProgress size={48} thickness={4} className="input-loader"/>
            </div>}
            {!loading && (
                <>
                    <Helmet>
                        <title>{profileData.nickname} · Live / Latency</title>
                        <html lang={i18n.language}/>
                        {i18n.language === "es" ? (
                            <meta name="description"
                                  content={`Sigue todo el panorama competitivo de Valorant en esta exclusiva Landing y no te pierdas ningún evento de tu equipo favorito. Construye tu agenda interactiva en Latency. Encuentra y sigue tus intereses para disfrutar de un calendario de eventos en cualquier momento del día.`}/>
                        ) : (
                            <meta name="description"
                                  content={`Follow the full competitive scene of Valorant in this exclusive landing and don't miss any of your favorite team's events. Get your own interactive agenda in Latency. Find and follow your interests to enjoy a calendar of events at any time of the day.`}/>
                        )}
                        {profileData.slug === "valorant.oficial" && (
                            <meta name="keywords"
                                  content={`latency,agenda,eventos,agenda interactiva,valorant calendario,valorant partidos,valorant competitivo,valorant g2,valorant heretics,valorant giants,valorant kru,valorant koi,valorant resultados,valorant results,valorant matches,valorant tournament,valorant highlights,valorant actualidad`}/>
                        )}
                        <link rel="canonical" href={`https://latencyapp.com/${profileData.slug}/live`}/>
                        {profileData.slug === "valorant.oficial" && (
                            <link rel="canonical" href={`https://latencyapp.com/valorant`}/>
                        )}
                            </Helmet>
                    <LandingHeader
                        isFollow={profileData ? profileData.follow : false}
                        userId={userID}
                        userNickname={profileData ? profileData.nickname : ""}
                        headerMobile={profileData ? resources[profileData.slug].headerMobile : null}
                        headerVideo={profileData ? resources[profileData.slug].hasOwnProperty('headerVideoWebm') ? [
                                resources[profileData.slug].headerVideoWebm,
                                resources[profileData.slug].headerVideo
                            ] :
                            resources[profileData.slug].headerVideo : null}
                        userSlug={profileData ? profileData.slug : ""}
                        changeTab={handleCustomTab}
                    />

                    <Box
                        className={`latency-landing-content is-${userSlug}`}
                        display={`${deviceView === 'browser' ? 'flex' : ''}`} flexDirection="row">
                        <div
                            className={`latency-landing-container ${deviceView === 'browser' ? ' is-desktop' : ''} ${deviceView === 'mobile' ? ' is-mobile' : ''} ${deviceView === 'tablet' ? ' is-tablet' : ''}`}>
                            <Box className="landing-menu-tabs"> <Tabs scrollButtons="auto" variant="scrollable"
                                                                      value={tab}
                                                                      onChange={changeTab}
                                                                      classes={{indicator: 'indicator'}}
                                                                      className="menu"
                            >
                                {landingTabs && landingTabs.map((value, index) =>
                                    <Tab label={value} key={`landing-tab-option-${index}`}
                                         classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                                )}
                                {allowedManage && <Tab icon={<AddIcon/>}
                                                       classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}
                                                       onClick={() => setOpenTabForm(true)}/>
                                }
                            </Tabs>
                                <Box bgcolor="grid.main" className="section">
                                    {landingTabs[tab] === t("home") && <LandingCoverComponent
                                        userId={userID}
                                        userSlug={userSlug}
                                        changeTab={changeTab}
                                        changeCustomTab={handleCustomTab}
                                        onOpenPlayer={handleOpenPlayer}
                                        onOpenClip={handleOpenClip}
                                        customBlocks={customTabs}
                                    />}
                                    {landingTabs[tab] === t("news") && <LandingFeedComponent userId={userID}/>}
                                    {landingTabs[tab] === t("live_now") &&
                                    <LandingMainTabLiveNow userId={userID} onOpenPlayer={handleOpenPlayer}
                                                           onDeleteOpenPlayer={handleDeleteOnePlayer}
                                                           eventsWithPlayerOpen={eventsWithPlayerOpen}/>}
                                    {landingTabs[tab] === t("highlights") &&
                                    <LandingHighlightsComponent userId={userID}
                                                                eventWithClipOpen={eventWithClipOpen}
                                                                displayClipInfo={true}
                                                                onOpenClip={handleOpenClip}/>}
                                    {landingTabs[tab] === t("agenda") && (
                                        <DragScrollProvider>
                                            {({onMouseDown, ref, scrollTo}) => (
                                                <div className="landing-agenda-container scrollable"
                                                     ref={ref}
                                                     onMouseDown={onMouseDown}>

                                                    <ProfileAgenda
                                                        enableBackRef={window.location.pathname}
                                                        centerOnDay={scrollTo}
                                                    />
                                                </div>
                                            )}
                                        </DragScrollProvider>
                                    )}
                                    {landingTabs[tab] === t("passed_vods") &&
                                    <EventsPassedComponentByDay userId={userID}/>}
                                    {landingTabs[tab] === t("best_clip") &&
                                    <LandingHighlightsComponent type="best-clip" userId={userID}
                                                                eventWithClipOpen={eventWithClipOpen}
                                                                onOpenClip={handleOpenClip} displayClipInfo={true}/>}
                                    {landingTabs[tab] === t("participants") &&
                                    <LandingParticipantsComponent userId={userID}/>
                                    }
                                    {landingTabs[tab] === t("teams") &&
                                    <LandingTeamsComponent userId={userID}/>
                                    }
                                    {(!openTabForm && !_defaultLandingTabs.includes(landingTabs[tab])
                                        && landingTabs[tab] !== t("best_clip")
                                        && landingTabs[tab] !== t("participants")
                                        && landingTabs[tab] !== t("teams")) &&
                                    <LandingCustomTabComponent
                                        userId={userID}
                                        changeTab={changeTab}
                                        onOpenPlayer={handleOpenPlayer}
                                        handleRefreshTabs={loadCustomTabs}
                                        customTab={customTabs[tab - (landingTabs.length - customTabs.length)]}
                                        allowRemove={allowedManage}
                                        onClickDeleteButton={handleDeleteClip}/>}
                                </Box>
                            </Box>
                        </div>
                        {deviceView === "browser" && (
                            <div className={"latency-landing-container-side"}>
                                <Box className="chat-tabs">
                                    <Tabs variant="scrollable" scrollButtons="auto" value={sidebarTab}
                                          onChange={changeSidebarTab} classes={{indicator: 'indicator'}}
                                          className="menu">
                                        <Tab label="Chat"
                                             classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                                        {eventsWithPlayerOpen.map((data, index) => {
                                            let name = data.split('#+#')[0];

                                            return (
                                                <Tab key={`tab-chat-player-open-${name}`} label={name}
                                                     classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                                            );
                                        })}
                                    </Tabs>
                                    <Box bgcolor="grid.main" className="section">
                                        <div className={`${sidebarTab === 0 ? 'chat-visible' : 'chat-invisible'}`}>
                                            <LandingChat userId={userID}/>
                                        </div>
                                        {eventsWithPlayerOpen.map((data) => {
                                                return (
                                                    <div key={`tab-content-chat-player-open-${data.split('#+#')[0]}`}
                                                         className={`${(sidebarTab - 1) === eventsWithPlayerOpen.indexOf(data) ? 'chat-visible' : 'chat-invisible'}`}>
                                                        <TwitchChat width={"100%"} height={"675"}
                                                                    theme={darkTheme ? "dark" : "light"}
                                                                    channel={data.split('#+#')[0]}/>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Box>
                                </Box>
                            </div>
                        )}
                    </Box>
                    <Modal
                        state={openTabForm}
                        max={"md"}
                        radius={true}
                        fullScreen={deviceView === "mobile"}
                        onClose={() => setOpenTabForm(false)}>
                        <LandingCustomTabForm
                            onSubmit={onSubmitTabModal}
                            onClose={() => setOpenTabForm(false)}/>
                    </Modal>
                </>
            )}
            {(user && user.hasOwnProperty('id') && userID === '7ac0b720-06a6-4257-97f9-899e63eb6fd5') &&
            <div>
                <div className='feedback-btn' onClick={setOpenFeedbackFormOn}>
                    <div className='feedback-btn_emoji'>❤️</div>
                    <div className='feedback-btn_text'>| Feedback</div>
                </div>
                <FeedbackForm open={openFeedbackForm} onClose={setOpenFeedbackFormOff}/>
            </div>
            }
        </Box>
    );
};

export default LandingMain;
