import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import CustomButton from "../../CustomButton/CustomButton";
import {followUser, unfollowUser} from "../../../store/app/actions";
import {useTranslation} from "react-i18next";

const FollowUserButton = ({ isFollow, userData }) => {
    const {t} = useTranslation('extraData');
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => state.loggedIn);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [follow, setFollow] = useState(loggedIn ? isFollow : false);

    const redirect = () => {
        history.push('/login')
    }

    return (
        <Fragment>
            {!follow &&
            <CustomButton
                loading={loading}
                onClick={() => {
                    setLoading(true)
                    dispatch(followUser(userData, redirect, "landing-view", setFollow, setLoading))
                }}
                className="follow-btn">{t('extraData:buttons_follow')}</CustomButton>
            }
            {follow &&
            <CustomButton
                loading={loading}
                onClick={() => {
                    setLoading(true)
                    dispatch(unfollowUser(userData, redirect, "landing-view", setFollow, setLoading))
                }}
                className="unfollow-btn">{t('extraData:buttons_unfollow')}</CustomButton>
            }
        </Fragment>
    );
};

export default FollowUserButton;
