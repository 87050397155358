import React, {useEffect} from 'react';

const HtmlToReactParser = require('html-to-react').Parser

const htmlToReactParser = new HtmlToReactParser()

const LandingArticleItem = ({item, showContent, showCover, link, onClickArticle}) => {
    const reactElement = htmlToReactParser.parse(item.text)

    const renderItem = () => (
        <>
            {(item.hasOwnProperty('cover') && item.cover !== null && showCover) &&
            <img className={"cover"} src={item.cover ? item.cover : "/images/defaultHeader.png"}/>}
            <div className={"title-box"}>
                <div className={"title"}>
                    <h1>{item.title}</h1>
                </div>
                {showContent && (
                    <div className={"intro"}>
                        {reactElement}
                    </div>
                )}
            </div>
        </>
    )

    return (
        <div className={"item"}>
            {link && (
                <div className={"link-el"} onClick={onClickArticle}>
                    {renderItem()}
                </div>
            )}
            {!link && renderItem()}
        </div>
    );
};

LandingArticleItem.defaultProps = {
    showContent: true,
    showCover: true,
    link: false
};

export default LandingArticleItem;
