import React, {Fragment, useState} from "react";
import './settings.scss';
import {useSelector} from "react-redux";
import TrustedListForm from "../../components/Forms/SettingsForm/TrustedListForm/TrustedListForm";
import GeneralDisplayForm from "../../components/Forms/SettingsForm/GeneralDisplayForm/GeneralDisplayForm";
import GeneralSuscriptionForm from "../../components/Forms/SettingsForm/GeneralSuscriptionForm/GeneralSuscriptionForm";
import GeneralLanguageForm from "../../components/Forms/SettingsForm/GeneralLanguageForm/GeneralLanguageForm";
import {useTranslation} from "react-i18next";
import SettingsPrivacyForm from "../../components/Forms/SettingsForm/SettingsPrivacyForm/SettingsPrivacyForm";
import SettingsNotificationsForm
    from "../../components/Forms/SettingsForm/SettingsNotificationsForm/SettingsNotificationsForm";
import SettingsEventsForm from "../../components/Forms/SettingsForm/SettingsEventsForm/SettingsEventsForm";
import SettingsConnectionsForm
    from "../../components/Forms/SettingsForm/SettingsConnectionsForm/SettingsConnectionsForm";
import AdvancedAuthTokensForm from "../../components/Forms/SettingsForm/AdvancedAuthTokensForm/AdvancedAuthTokensForm";
import AdvancedDeactivateAccountForm
    from "../../components/Forms/SettingsForm/AdvancedDeactivateAccountForm/AdvancedDeactivateAccountForm";
import LandingConfigurationForm
    from "../../components/Forms/SettingsForm/LandingConfigurationForm/LandingConfigurationForm";
import LandingRolesForm from "../../components/Forms/SettingsForm/LandingRolesForm/LandingRolesForm";
import {Grid, Hidden, Tab, Tabs} from "@material-ui/core";
import {Helmet} from "react-helmet";
import GeneralTutorialsForm from "../../components/Forms/SettingsForm/GeneralTutorialsForm/GeneralTutorialsForm";

const Settings = () => {
    const {t} = useTranslation('settings');
    const darkTheme = useSelector(state => state.darkTheme);
    const [tab, setTab] = useState(0);
    return (
        <div className="settings-page">
            <Helmet>
                <title>{t('UserMenu:menu_label_settings')} / Latency</title>
            </Helmet>
            <Hidden only={["md","lg","xl"]}>
                <Tabs variant="scrollable" value={tab} scrollButtons="auto" >
                    <Tab label={t('section_settings')} value={0} onClick={() => setTab(0)} className={(tab === 0 ? ' is-active' : '')}/>
                    <Tab label={t('section_landing')} value={10} onClick={() => setTab(10)} className={(tab === 10 ? ' is-active' : '')}/>
                    <Tab label={t('section_invitations')} value={4} onClick={() => setTab(4)}  className={(tab === 4 ? ' is-active' : '')}/>
                    <Tab label={t('section_general')} value={5} onClick={() => setTab(5)} className={(tab === 5 ? ' is-active' : '')}/>
                    <Tab label={t('section_advanced')} value={8} onClick={() => setTab(8)} className={(tab === 8 ? ' is-active' : '')}/>
                    <Tab label={t('section_advanced_deactivate_account')} value={9} onClick={() => setTab(9)} className={(tab === 9 ? ' is-active' : '')}/>
                </Tabs>
            </Hidden>
            <Hidden only={["xs","sm"]}>
                <div className={"settings-sidebar" + (darkTheme ? ' is-dark' : ' is-light')}>
                    <div className="settings-sidebar-section-title">{t('section_settings')}</div>
                    <div className={"settings-sidebar-link" + (tab === 0 ? ' is-active' : '')}
                         onClick={() => setTab(0)}>{t('section_settings_privacy')}
                    </div>
                    <div className={"settings-sidebar-link" + (tab === 1 ? ' is-active' : '')}
                         onClick={() => setTab(1)}>{t('section_settings_connections')}
                    </div>
                    <div className={"settings-sidebar-link" + (tab === 2 ? ' is-active' : '')}
                         onClick={() => setTab(2)}>{t('section_settings_notifications')}
                    </div>
                    <div className={"settings-sidebar-link" + (tab === 3 ? ' is-active' : '')}
                         onClick={() => setTab(3)}>{t('section_settings_events')}
                    </div>
                    <div className="settings-sidebar-section-title">{t('section_landing')}</div>
                    <div className={"settings-sidebar-link" + (tab === 10 ? ' is-active' : '')}
                         onClick={() => setTab(10)}>{t('section_landing_configuration')}
                    </div>
                    <div className={"settings-sidebar-link" + (tab === 11 ? ' is-active' : '')}
                         onClick={() => setTab(11)}>{t('section_landing_roles')}
                    </div>
                    <div className="settings-sidebar-section-title">{t('section_invitations')}</div>
                    <div className={"settings-sidebar-link" + (tab === 4 ? ' is-active' : '')}
                         onClick={() => setTab(4)}>{t('section_invitations_trustedlist')}
                    </div>
                    <div className="settings-sidebar-section-title">{t('section_general')}</div>
                    <div className={"settings-sidebar-link" + (tab === 5 ? ' is-active' : '')}
                         onClick={() => setTab(5)}>{t('section_general_subscription')}
                    </div>
                    <div className={"settings-sidebar-link" + (tab === 6 ? ' is-active' : '')}
                         onClick={() => setTab(6)}>{t('section_general_display')}
                    </div>
                    <div className={"settings-sidebar-link" + (tab === 7 ? ' is-active' : '')}
                         onClick={() => setTab(7)}>{t('section_general_language')}
                    </div>
                    <div className={"settings-sidebar-link" + (tab === 8 ? ' is-active' : '')}
                         onClick={() => setTab(8)}>{t('section_general_tutorials')}
                    </div>
                    <div className="settings-sidebar-section-title">{t('section_advanced')}</div>
                    <div className={"settings-sidebar-link" + (tab === 9 ? ' is-active' : '')}
                         onClick={() => setTab(9)}>{t('section_advanced_apitokens')}
                    </div>
                    <div className={"settings-sidebar-link deactivate-account" + (tab === 9 ? ' is-active' : '')}
                         onClick={() => setTab(10)}>{t('section_advanced_deactivate_account')}
                    </div>
                </div>
            </Hidden>
            <div className={"settings-content" + (darkTheme ? ' is-dark' : ' is-light')}>
                {(tab === 0 || tab === 1 || tab === 2 || tab === 3) && (
                    <Fragment>
                        <SettingsPrivacyForm/>
                        <SettingsConnectionsForm/>
                        <SettingsNotificationsForm/>
                        <SettingsEventsForm/>
                    </Fragment>
                )}
                {tab === 4 && (
                    <TrustedListForm/>
                )}
                {(tab === 5 || tab === 6 || tab === 7 || tab === 8) && (
                    <Fragment>
                        <GeneralSuscriptionForm/>
                        <GeneralDisplayForm/>
                        <GeneralLanguageForm/>
                        <GeneralTutorialsForm/>
                    </Fragment>
                )}
                {tab === 9 && (
                    <AdvancedAuthTokensForm/>
                )}
                {tab === 10 && (
                    <AdvancedDeactivateAccountForm/>
                )}
                {(tab === 11 || tab === 12) && (
                    <Fragment>
                        <LandingConfigurationForm/>
                        <LandingRolesForm/>
                    </Fragment>
                )}
            </div>
        </div>
    )
};

export default Settings;
