import ReactGA from "react-ga4";

export const initGA = (trackingId) => {
    ReactGA.initialize(trackingId);
}

export const PageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
    if(action === "search") {
        ReactGA.event({
            category: category,
            action: action,
            search_term: label.toString()
        });
    } else {
        ReactGA.event({
            category: category,
            action: action,
            label: label.toString()
        });
    }
};
