import React, {useState, useCallback } from 'react';
import './image-cropper.scss';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../helpers/imageCropper';
import {Box, Slider, Button} from '@material-ui/core';
import CustomButton from "../CustomButton/CustomButton";
import {useTranslation} from "react-i18next";

const ImageCropper = (props) => {
    const {t} = useTranslation(['extraData']);
    const {poster, cropper} = props;
    const [isCropping, setIsCropping] = useState(false);
    const [crop, setCrop] = useState({x:0, y:0});
    const [zoom, setZoom] = useState(3);
    const [cropPixels, setCropPixels] = useState(0);
    const cropShape = props.round ? 'round' : 'rect';
    const blurredBg = props.hasOwnProperty('bg') ? props.bg : undefined;

    const onCropChange = (crop) => {
        setCrop(crop)
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCropPixels(croppedAreaPixels)
    }, []);

    const onZoomChange = (event, newValue) => {
        setZoom(newValue ? newValue : event)
    };

    const cropImage = useCallback(async () => {
        if(!isCropping) {
            setIsCropping(true);
            try {
                let image = await getCroppedImg(poster, cropPixels);
                props.onCrop(image);
                props.onClose();
            } catch (e) {
                console.log('err cropping', e);
                setIsCropping(false);
            }
        }
    }, [cropPixels, poster, props]);

    return (
        <Box className={"image-cropper " + props.className} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box className="crop-box">
                {blurredBg !== undefined && (
                  <Box className="bg-poster" style={{
                      backgroundImage: `url(${blurredBg})`,
                  }}></Box>
                )}
                <Cropper
                    image={poster}
                    crop={crop}
                    zoom={zoom}
                    zoomSpeed={1}
                    maxZoom={5}
                    showGrid={true}
                    restrictPosition={false}
                    aspect={4/3}
                    cropSize={cropper}
                    cropShape={cropShape}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}/>
            </Box>
            <Box className="crop-actions" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Slider
                    value={zoom}
                    color="secondary"
                    min={1}
                    max={5}
                    step={.10}
                    onChange={onZoomChange}
                    valueLabelDisplay="auto"/>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Button className="cancel-btn" onClick={props.onClose}>{t("extraData:buttons_cancel")}</Button>
                    <CustomButton loading={isCropping} disabled={isCropping} className="crop-btn" onClick={cropImage}>{t("extraData:crop")}</CustomButton>
                </Box>
            </Box>
        </Box>
    )
}

export default ImageCropper;
