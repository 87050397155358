const initialState = []

const exploreReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_EXPLORE':
            return state = action.payload;
        case 'UNSET_EXPLORE':
            return state = initialState
        default:
            return state
    }
}

export default exploreReducer;