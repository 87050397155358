export const setLandingFeedTimeline = (roomId, data) => {
    return dispatch => {
        dispatch({
            type: 'SET_LANDING_FEED_TIMELINE',
            payload: {
                roomId: roomId,
                messages: data
            }
        })
    }
};

export const addLandingFeedTimeline = (data) => {
    return dispatch => {
        dispatch({
            type: 'ADD_LANDING_FEED_TIMELINE',
            payload: {
                roomId: data.roomId,
                message: data
            }
        })
    }
}

export const editLandingFeedTimeline = (roomId, messageId, data) => {
    return dispatch => {
        dispatch({
            type: 'EDIT_LANDING_FEED_TIMELINE',
            payload: {
                roomId: roomId,
                messageId: messageId,
                message: data
            }
        })
    }
};


export const removeLandingFeedTimeline = (roomId, messageId) => {
    return dispatch => {
        dispatch({
            type: 'REMOVE_LANDING_FEED_TIMELINE',
            payload: {
                roomId: roomId,
                messageId: messageId,
            }
        })
    }
};
