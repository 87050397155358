import './_landing_valorant.scss';
import {Box, Tab, Tabs} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import LandingHeader from "../LandingMain/fragments/Header/LandingHeader";
import {getUserProfile} from "../../../store/user/actions";
import {useHistory, useLocation} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Event} from "../../../services/tracking";
import LandingMainTabLiveNow from "../LandingMain/fragments/LiveNowComponent/LandingMainTabLiveNow";
import DragScrollProvider from "drag-scroll-provider";
import ProfileAgenda from "../../Profile/ProfileAgenda/ProfileAgenda";
import LandingTeamsComponent from "../LandingMain/fragments/LandingTeamsComponent/LandingTeamsComponent";
import EmbedTeamsComponent from "./fragments/EmbedTeamsComponent/EmbedTeamsComponent";
import {EmbedLeaguesComponent} from "./fragments/EmbedLeaguesComponent/EmbedLeaguesComponent";
import VlrLandingMatchesComponent from "./fragments/VlrLandingMatchesComponent/VlrLandingMatchesComponent";
import {EmbedNextEvents} from "./fragments/EmbedNextEventsComponent/EmbedNextEvents";
import {EmbedResultEvents} from "./fragments/EmbedResultEventsComponent/EmbedResultEventsComponent";

const componentName = "EmbedValorant"

export const EmbedValorant = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {i18n, t} = useTranslation('landing');
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const [cssDeviceView, setCSSDeviceView] = useState("");

    const [loading, setLoading] = useState(true);
    const [userID, setUserID] = useState(undefined);
    const [profileData, setProfileData] = useState(undefined);
    const [tab, setTab] = useState(1);

    const [eventsWithPlayerOpen, setEventsWithPlayerOpen] = useState([]);
    const [eventWithClipOpen, setEventWithClipOpen] = useState(undefined);

    const _defaultLandingTabs = [t("agenda"), "next events", "results"];
    const [landingTabs, _setLandingTabs] = useState(_defaultLandingTabs);
    const landingTabsRef = React.useRef(landingTabs);

    useEffect(() => {
        setLoading(true);
        dispatch(getUserProfile("valorant.oficial", () => {
            history.push('/not-found');
        }, (user) => {
            setProfileData(user);
            setUserID(user.id);
            setLoading(false);
        }));
    }, []);

    useEffect(() => {
        let class_string = darkTheme ? "is-dark-theme" : "is-light-theme"
        if (deviceView === "mobile" || deviceView === "tablet") {
            class_string += ` is-${deviceView}`;
        } else {
            class_string += ` is-desktop`;
        }
        setCSSDeviceView(class_string);
    }, [darkTheme, deviceView]);

    const handleCustomTab = (val) => {
        let tab = landingTabsRef.current.indexOf(val);
        if (tab !== -1) {
            setTab(tab)
            window.scrollTo(0, 0)
        }
    }

    const changeTab = (e, val) => {
        if (typeof val === 'number' && (val % 1) === 0) {
            let tab = landingTabsRef.current[val];
            if (tab !== undefined) {
                setTab(val);
                let slugify = tab.toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
                history.push(`#${slugify}`)
                Event("LandingMain", "ChangeTab", tab);
            }
        } else {
            handleCustomTab(val);
            let tab = landingTabsRef.current.indexOf(val);
            if (tab !== undefined) {
                let slugify = landingTabsRef.current[tab].toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
                history.push(`#${slugify}`)
                Event(componentName, "ChangeTab", landingTabsRef.current[tab]);
            }
        }
    }

    const handleOpenPlayer = (eventId) => {
        if (!eventsWithPlayerOpen.includes(eventId)) {
            setEventsWithPlayerOpen([...eventsWithPlayerOpen, eventId]);
        }
        Event(componentName, "OpenPlayer", eventId);
    };
    const handleOpenClip = (eventId) => {
        setEventWithClipOpen(eventId);
        Event(componentName, "OpenClip", eventId);
    };
    const handleDeleteOnePlayer = (eventId) => {
        setEventsWithPlayerOpen([...eventsWithPlayerOpen.filter(i => i !== eventId)]);
        Event(componentName, "DeleteOnePlayer", eventId);
    }

    return (
        <>
            <Helmet>
                <title>Valorant Oficial · Live / Latency</title>
                <html lang={i18n.language}/>
                {i18n.language === "es" ? (
                    <meta name="description"
                          content={`Sigue todo el panorama competitivo de Valorant en esta exclusiva Landing y no te pierdas ningún evento de tu equipo favorito. Construye tu agenda interactiva en Latency. Encuentra y sigue tus intereses para disfrutar de un calendario de eventos en cualquier momento del día.`}/>
                ) : (
                    <meta name="description"
                          content={`Follow the full competitive scene of Valorant in this exclusive landing and don't miss any of your favorite team's events. Get your own interactive agenda in Latency. Find and follow your interests to enjoy a calendar of events at any time of the day.`}/>
                )}
                <meta name="keywords"
                      content={`latency,agenda,eventos,agenda interactiva,valorant calendario,valorant partidos,valorant competitivo,valorant g2,valorant heretics,valorant giants,valorant kru,valorant koi,valorant resultados,valorant results,valorant matches,valorant tournament,valorant highlights,valorant actualidad`}/>
                <link rel="canonical" href={`https://latencyapp.com/valorant/live`}/>
                <link rel="canonical" href={`https://latencyapp.com/valorant`}/>
            </Helmet>
            <Box
                className={`latency-valorant-embed ${cssDeviceView}`}
                display="flex"
                flexDirection="row" justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">

                {loading && (
                    <div className="loading">
                        <CircularProgress size={48} thickness={4} className="input-loader"/>
                    </div>
                )}

                {!loading && (
                    <>
                        <div className={`latency-landing-container ${cssDeviceView}`}>
                            <Box className={`section is-top is-${deviceView}`}>
                                <div className={`embed-section is-regions is-${deviceView}`}>
                                    <div className="embed-section-heading">Regions</div>
                                    <div className="embed-section-content">
                                        {['All', 'VCT EMEA', 'VCT AMÉRICA', 'VCT ASIA', 'VCT APAC'].map((item, ind) =>
                                            <div key={ind} className="embed-region-box">
                                                <div className="overlay-image" style={{
                                                    backgroundImage: `url(/images/vct-${item.replace('VCT ', '').replace('É', 'e').toLowerCase()}.png)`
                                                }}/>
                                                <img src={`/images/vct-${item.replace('VCT ', '').replace('É', 'e').toLowerCase()}.png`} alt={item} />
                                                <div className="embed-region-name">{item}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="embed-section is-teams">
                                    <div className="embed-section-heading">Teams VCT</div>
                                    <Box bgcolor="grid.content" className="embed-section-content">
                                        <EmbedTeamsComponent userId={userID}/>
                                    </Box>
                                </div>
                            </Box>
                            <Box className="section">
                                <div className={`embed-section is-leagues is-${deviceView}`}>
                                    <div className="embed-section-heading">Leagues</div>
                                    <Box bgcolor="grid.content" className="embed-section-content">
                                        <EmbedLeaguesComponent userId={userID} />
                                    </Box>
                                </div>
                            </Box>
                            <Box className="section main">
                                <Box className="landing-menu-tabs">
                                    <Tabs scrollButtons="auto" variant="scrollable"
                                          value={tab}
                                          onChange={changeTab}
                                          classes={{indicator: 'indicator'}}
                                          className="menu"
                                    >
                                        {landingTabs && landingTabs.map((value, index) =>
                                            <Tab label={value} key={`landing-tab-option-${index}`}
                                                 classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                                        )}
                                    </Tabs>
                                </Box>
                                {landingTabs[tab] === "next events" && <EmbedNextEvents userId={userID} />}
                                {landingTabs[tab] === "results" && <EmbedResultEvents userId={userID}/>}
                                {landingTabs[tab] === t("agenda") && (
                                    <DragScrollProvider>
                                        {({onMouseDown, ref, scrollTo}) => (
                                            <div className="landing-agenda-container scrollable"
                                                 ref={ref}
                                                 onMouseDown={onMouseDown}>

                                                <ProfileAgenda
                                                    enableBackRef={window.location.pathname}
                                                    centerOnDay={scrollTo}
                                                />
                                            </div>
                                        )}
                                    </DragScrollProvider>
                                )}
                                {landingTabs[tab] === t("teams") &&
                                <LandingTeamsComponent userId={userID}/>
                                }
                            </Box>
                        </div>
                    </>
                )}


            </Box>
        </>
    );
};
