import React, { useEffect } from 'react';
import './privacy-policy.scss';
import { useSelector } from "react-redux";
import PrivacyPolicyES from "./PrivacyPolicyES";
import PrivacyPolicyEN from "./PrivacyPolicyEN";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const settingsGeneral = useSelector(state => state.settings.general);
    if(settingsGeneral.language === 'es') {
        return <PrivacyPolicyES/>
    } else {
        return <PrivacyPolicyEN/>
    }
}

export default PrivacyPolicy;
