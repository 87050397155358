const initialState = {
    page: 0,
    badgeCount: 0,
    data: []
}

const invitationsReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_INVITATIONS':
            return {...state,  ...action.payload};
        case 'UNSET_INVITATIONS':
            return {...state,  page: 0, badgeCount: 0, data: []}
        default:
            return state
    }
}

export default invitationsReducer;