import React, {forwardRef, Fragment, useEffect, useRef, useState} from 'react';
import Popover from "@material-ui/core/Popover";
import {formatDate} from "../../../helpers/dateFormatter";
import {FixedSizeList as List} from "react-window";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import TextField from "@material-ui/core/InputBase/InputBase";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EventDateCountdown from "./EventDateCountdown";

const {utcToZonedTime} = require('date-fns-tz');

let aryIannaTimeZones =
    [
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
    ];


const EventTimezoneSwitcher = (props) => {
    const {allDay, eventStart, eventEnd} = props;
    const deviceView = useSelector(state => state.deviceView);
    const [open, setOpen] = useState(false);
    const darkTheme = useSelector(state => state.darkTheme)
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTz, setCurrentTz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const dateFormat = "iii d LLLL";
    const {t} = useTranslation(['eventForm']);
    const [start, setStart] = useState(new Date(eventStart));
    const [end, setEnd] = useState(new Date(eventEnd));
    const listRef = useRef(null);
    const [search, setSearch] = useState("")
    const [timezoneList, setTimezoneList] = useState(aryIannaTimeZones);
    const PADDING_SIZE = 0;
    const innerElementType = forwardRef(({style, ...rest}, ref) => (
        <div
            ref={ref}
            style={{
                ...style,
                height: `${parseFloat(style.height) + PADDING_SIZE * 2}px`
            }}
            {...rest}
        />
    ));

    const onInputChange = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        setTimezoneList(
            aryIannaTimeZones.filter(function (name) {
                return name.toLowerCase().includes(search.toLowerCase());
            })
        )
    }, [search]);

    const TimezoneItem = ({index, style}) => (
        <div
            onClick={() => triggerItem(timezoneList[index])}
            className={['tzItem', timezoneList[index] === currentTz ? 'tzItemSelected' : ''].join(' ')}
            style={{
                ...style,
                top: `${parseFloat(style.top) + PADDING_SIZE}px`
            }}
        >
            {timezoneList[index]}
        </div>
    );

    useEffect(() => {
        const startZoned = utcToZonedTime(eventStart, currentTz)
        setStart(startZoned)
        if (eventEnd) {
            const endZoned = utcToZonedTime(eventEnd, currentTz)
            setEnd(endZoned)
        }

    }, [eventStart, eventEnd, currentTz]);

    const triggerAnchor = (e) => {
        setOpen(true);
        setAnchorEl(e.currentTarget);
    };

    const triggerItem = (tz) => {
        setCurrentTz(tz);
        setOpen(false);
    };

    return (
        <Fragment>
            <div className="time subtitle">
                {allDay && (
                    <span>
                                {formatDate(start, dateFormat) === formatDate(end, dateFormat) ? (
                                    <span> {formatDate(start, dateFormat)} - <strong>{t('forms_label_allDay')}</strong> </span>
                                ) : (
                                    <span> {formatDate(start, dateFormat)} {formatDate(start, 'p')} - {formatDate(end, dateFormat)} {formatDate(end, 'p')} </span>
                                )}
                            </span>
                )}
                {!allDay && (
                    <span>
                                {start.getTime() !== end.getTime() &&
                                <span>{formatDate(start, dateFormat)}
                                    <strong> {`(${formatDate(start, 'p')} - ${formatDate(end, 'p')})`}</strong> </span>
                                }
                        {new Date(start).getTime() === new Date(end).getTime() &&
                        <span> {formatDate(start, dateFormat)} <strong>{`(${formatDate(end, 'p')})`}</strong> </span>
                        }
                            </span>
                )}
                <span className={"tz-switcher-button " + (darkTheme ? 'isDarkMode' : '')}
                      onClick={(e) => triggerAnchor(e)}>{currentTz}<ArrowDropDownIcon/></span>
            </div>
            <Popover
                className={'tz-switcher-popover'}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                //onEnter={() => listRef.current.scrollToItem(aryIannaTimeZones.indexOf(currentTz), "center")}
                {...(deviceView === 'browser' ? {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                } : {
                    anchorReference: "anchorPosition",
                    anchorPosition: {
                        top: 90,
                        left: 0
                    }
                })}
            >

                <div>

                    <Box bgcolor="grid.main" className="tzSearchForm" display="flex" flexDirection="row"
                         justifyContent="center" alignItems="center">
                        <TextField
                            className="search-input"
                            placeholder={'Search'}
                            value={search}
                            onChange={onInputChange}
                        />
                    </Box>

                    <List
                        ref={listRef}
                        className="tzList"
                        height={300}
                        innerElementType={innerElementType}
                        itemCount={timezoneList.length}
                        itemSize={35}
                        width={300}
                    >
                        {TimezoneItem}
                    </List>
                </div>

            </Popover>
            <EventDateCountdown date={start}/>
        </Fragment>
    );
};

export default EventTimezoneSwitcher;
