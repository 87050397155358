import React, {useState} from 'react';
import {Box, Button, Container, Drawer} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {Alert} from "@material-ui/lab";
import "./_feedback_form.scss"
import services from "../../../../../services";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import FeedbackTextareaInput from "./FeedbackTextareaInput";
import FeedbackCheckboxInput from "./FeedbackCheckboxInput";
import FeedbackStarsInput from "./FeedbackStarsInput";

const FeedbackForm = ({open, onClose}) => {
    const {t} = useTranslation('feedbackForm');
    const deviceView = useSelector(state => state.deviceView);

    const [errorCreate, setErrorCreate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [successCreate, setSuccessCreate] = useState(false);

    const [answerFirst, setAnswerFirst] = useState('');
    const [answerSecond, setAnswerSecond] = useState('');
    const [answerThird, setAnswerThird] = useState('');
    const [answerFourth, setAnswerFourth] = useState('');
    const [answerFifth, setAnswerFifth] = useState('');

    const formItems = [
        {"type": "textarea", "label": t('first_question'), "value": answerFirst, "answer": 'answer_first'},
        {"type": "textarea", "label": t('second_question'), "value": answerSecond, "answer": 'answer_second'},
        {"type": "textarea", "label": t('third_question'), "value": answerThird, "answer": 'answer_third'},
        {"type": "textarea", "label": t('fourth_question'), "value": answerFourth, "answer": 'answer_fourth'},
        {"type": "textarea", "label": t('fifth_question'), "value": answerFifth, "answer": 'answer_fifth'},
    ]

    const answersDict = {
        'answer_first': t('first_question_literal'),
        'answer_second': t('second_question_literal'),
        'answer_third': t('third_question_literal'),
        'answer_fourth': t('fourth_question_literal'),
        'answer_fifth': t('fifth_question_literal'),
    };

    const onSubmitModal = (event) => {
        event.preventDefault();

        let sendForm = {
            answer_first: answerFirst,
            answer_second: answerSecond,
            answer_third: answerThird,
            answer_fourth: answerFourth,
            answer_fifth: answerFifth,
        };

        if (sendForm['answer_first'] === '') {
            sendForm['answer_first'] = null;
        }
        if (sendForm['answer_second'] === '') {
            sendForm['answer_second'] = null;
        }
        if (sendForm['answer_third'] === '') {
            sendForm['answer_third'] = null;
        }
        if (sendForm['answer_fourth'] === '') {
            sendForm['answer_fourth'] = null;
        }
        if (sendForm['answer_fifth'] === '') {
            sendForm['answer_fifth'] = null;
        }

        services.landingFeedback.create(sendForm).then(res => {
            setSuccessCreate(true);
            setErrorCreate(false);
        }).catch(err => {
            console.error(err);
            if (err.hasOwnProperty('code') && err.code === 400) {
                if (err.message.match(/^Field (\w+) is null\. \(required\)$/)) {
                    let match = err.message.match(/^Field (\w+) is null\. \(required\)$/)[1];
                    setErrorMessage(`${t('required_pre')}${answersDict[match]}${t('required_post')}`)
                } else {
                    setErrorMessage(t('error-already-send'));
                }
            } else {
                setErrorMessage(t('other-error'));
            }
            setErrorCreate(true);
            setSuccessCreate(false);
        })
    };

    const changeFormValue = (index, newValue) => {
        switch (index) {
            case 'answer_first':
                setAnswerFirst(newValue);
                break;
            case 'answer_second':
                setAnswerSecond(newValue);
                break;
            case 'answer_third':
                setAnswerThird(newValue);
                break;
            case 'answer_fourth':
                setAnswerFourth(newValue);
                break;
            case 'answer_fifth':
                setAnswerFifth(newValue);
                break;
        }
    };

    return <Drawer anchor={"right"} open={open} onClose={onClose}>
        <form className={`feedback_form ${deviceView === 'browser' ? "browser" : "mobile"}`} onSubmit={onSubmitModal}>
            <Container>
                <div className='close-icon_wrapper'>
                    <CloseIcon className='close-icon' onClick={onClose}/>
                </div>
                <Box m={1}>
                    <div>
                        <Box mt={2} mb={3}>
                            <Box mb={1}>
                                <h1 className='margin0'>{t('title_form')}</h1>
                            </Box>
                            <p className='margin0'>{t('subtitle_form')}</p>
                        </Box>
                        {
                            formItems.map((item, i) => {
                                if (item.type === 'stars') {
                                    return <FeedbackStarsInput
                                        key={i}
                                        label={item.label}
                                        value={item.value}
                                        answer={item.answer}
                                        onChange={changeFormValue}
                                    />
                                } else if (item.type === 'checkbox') {
                                    return <FeedbackCheckboxInput
                                        key={i}
                                        label={item.label}
                                        value={item.value}
                                        answer={item.answer}
                                        onChange={changeFormValue}
                                    />
                                } else if (item.type === 'textarea') {
                                    return <FeedbackTextareaInput
                                        key={i}
                                        label={item.label}
                                        value={item.value}
                                        answer={item.answer}
                                        onChange={changeFormValue}
                                    />
                                }
                            })
                        }
                        {errorCreate && <Box my={1}><Alert severity="error"> {errorMessage}</Alert></Box>}
                        {successCreate &&
                        <Box my={1}>
                            <Alert severity="success">{t('gratitude')}</Alert>
                        </Box>
                        }
                        <Box mt={4}>
                            <Button type="submit" value="Submit" fullWidth={true}
                                    className='send-btn'
                            >{t('send')}</Button>
                        </Box>
                    </div>
                </Box>
            </Container>
        </form>
    </Drawer>
};

export default FeedbackForm;
