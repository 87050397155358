import {Box, Grid} from "@material-ui/core";
import VlrLandingMatchesComponent from "./fragments/VlrLandingMatchesComponent/VlrLandingMatchesComponent";
import LandingSmallTeamsComponent from "../LandingMain/fragments/LandingTeamsComponent/LandingSmallTeamsComponent";
import React from "react";

export const LandingValorantCoverComponent = ({ userId, changeTab }) => {
  return (
      <Box component="div" className={"landing-valorant-espana"} mt={1}>
          <Grid container style={{
              backgroundColor: 'transparent'
          }} spacing={2}>
              <Grid item xs={12} md={9}>
                  <VlrLandingMatchesComponent userId={userId} />
              </Grid>
              <Grid item xs={12} md={3}>
                  <LandingSmallTeamsComponent userId={userId} onChangeTab={changeTab} customTeamIds={[
                      '5e3a2c57-f089-4e48-aa09-3ae6362ea08d',
                      'ae6d5576-474e-4b74-8ad9-dfe935519470',
                      '6c311de8-60fe-4385-a512-6050f27fa278',
                      '4410cc89-840b-4fee-ba1e-9a8378065a42', // giants
                  ]} />
              </Grid>
          </Grid>
      </Box>
  );
};
