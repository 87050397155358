import React, {useState} from 'react';
import './login.scss';
import {Box, Button} from '@material-ui/core';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import Logo from '../../components/Logo/Logo';
import CustomLink from '../../components/CustomLink/CustomLink';
import {login} from '../../store/user/actions';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/Modal/Modal';
import PasswordRecoveryForm from '../../components/Forms/PasswordRecoveryForm/PasswordRecoveryForm';
import SocialNetworkButtons from "../../components/Forms/LoginForm/SocialNetworkButtons";
import {Helmet} from "react-helmet";

const Login = () => {
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const {t} = useTranslation('frontPage')
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const deviceView = useSelector(state => state.deviceView)

    const handleSubmit = (params) => {
        setLoading(true)
        dispatch(login(params, onError))
    };

    const onChange = () => {
        setError(false)
    };

    const onError = () => {
        setLoading(false)
        setError(true)
    };

    const openModal = () => {
        setModal(true)
    };

    return (
        <Box className="page-content" bgcolor="background.loginBg">
            <Helmet>
                <title>Login / Latency</title>
            </Helmet>
            <Box className="login-page" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box bgcolor="background.login" className="login-wrap animated bounceIn bounce" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Logo className="login-logo" />
                    <SocialNetworkButtons />
                    <div className="pinging-divider">
                        or
                    </div>
                    <LoginForm loading={loading} onSubmit={handleSubmit} onChange={onChange} error={error} />
                    <Button className="forgot-pass" onClick={openModal}> {t('buttons_recover_password')} </Button>
                    <CustomLink className={'light-link'} to="/register" textLight={t('buttons_sign_up_intro')} textBold={t('buttons_sign_up_action')} />
                </Box>
            </Box>

            <Modal
                state={modal}
                max={"sm"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                onClose={() =>  setModal(false)}>
                <PasswordRecoveryForm onClose={() =>  setModal(false)} />
            </Modal>
        </Box>
    )
}

export default Login;
