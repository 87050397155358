const initialState = null;

const subscriptionReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_SUBSCRIPTION':
            return state = action.payload;
        case 'UNSET_SUBSCRIPTION':
            return state = initialState;
        default:
            return state;
    }
};

export default subscriptionReducer;
