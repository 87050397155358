import React from 'react';
import { Box } from '@material-ui/core';
import {formatDate} from '../../../helpers/dateFormatter';
import {useSelector} from 'react-redux';

const EventResult = (props) => {
    const {event} = props
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <Box bgcolor="search.content" className={"event-result " + (darkTheme ? "dark" : "light")} display="flex" flexDirection="row" justifyContent="flex-start">
            <img src={event.owner.avatar ? event.owner.avatar : "/images/defaultAvatar.png"} alt ="latency" className="image" />
            <Box minHeight="40px" display="flex" flexDirection="column" justifyContent="center">
                <h5 className="event-name"> {event.title} </h5>
                <h5 className="time">{formatDate(event.start, "iii d LLLL")} {`${formatDate(event.start, "p")} - ${formatDate(event.end, "p")}`}</h5>
            </Box>
        </Box>
    )
}

export default EventResult;