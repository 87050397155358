const initailState = false

const loginReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_LOGIN':
            return state = true;
        case 'UNSET_LOGIN':
            return state = initailState
        default:
            return state
    }
}

export default loginReducer;