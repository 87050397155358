import React from 'react';

const spanishPhrases = [
    "Hoy no hay nada, vuelva mañana",
    "Hoy no se fía, mañana sí.",
    "No hay nada por aquí.",
    "Yo solo soy un mono, no me mires así."
];

const englishPhrases = [
    "Nothing today, come back tomorrow",
    "No credit today, come back tomorrow",
    "There is nothing",
    "I'm just a monkey, don't look at me like that"
];

export const getRandomPhrase = (lang = "es") => {
    switch (lang) {
        case "en":
            return englishPhrases[Math.floor(Math.random() * englishPhrases.length)];
        default:
            return spanishPhrases[Math.floor(Math.random() * spanishPhrases.length)];
    }
};

export const getMonkeyPhrase = (index, lang = "es") => {
    switch (lang) {
        case "en":
            return englishPhrases[index];
        default:
            return spanishPhrases[index];
    }
};

export const getComponentMonkeyPhrase = (lang = "es") => {
    let phrase = getRandomPhrase(lang);

    return (
        <span>{phrase}</span>
    )
}
