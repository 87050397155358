export const setOpenedProfileFilterCategory = (categories, cb = null) => {
    return dispatch => {
        dispatch({
            type: 'SET_OPENED_PROFILE_FILTER_CAT',
            payload: categories
        });
        if(cb != null) {
            cb();
        }
    }
}

export const unsetOpenedProfileFilterCategory = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE_FILTER_CAT'})
    }
}
