import React from 'react';
import './modal.scss';
import {Dialog} from '@material-ui/core';
import {useSelector} from 'react-redux';

const GenericModal = (props) => {
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <Dialog
            disableEnforceFocus
            open={props.state ? props.state : false}
            classes={{
                paper: `modal ${deviceView === "mobile" ? " is-mobile" : " is-desktop"}${props.hasOwnProperty('radius') && props.radius ? ' radius' : ''}${props.hasOwnProperty('padd') && props.padd ? ' padd' : ''}${darkTheme ? ' dark' : ' light'}${props.hasOwnProperty('className') ? ' ' + props.className : ''}`
            }}
            maxWidth={props.max}
            fullScreen={props.fullScreen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' || (reason === 'backdropClick' && !props.persist)) {
                    if(props.onClose && typeof props.onClose === 'function'){
                        props.onClose();
                    }
                }
            }}
            BackdropProps={{style: {backgroundColor: darkTheme ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.5)'}}}
            disableEscapeKeyDown={props.disableEscape}
        >
            {props.children}
        </Dialog>
    )
}

GenericModal.defaultProps = {
    disableEscape: false
}

export default GenericModal;
