import services from '../../services';
import { setParticipants } from '../participants/actions';

export const setUserGroups = (id) => {
    return dispatch => {
        services.groups.find({query: {ownerId: id, $limit: 100}})
            .then((resp) => {
                let obj = {add: true}
                if(resp.data.length >= 3){
                    obj.id = Math.random().toString(36).substring(7)
                    resp.data.push(obj)
                } else if(resp.data.length < 3){
                    if(resp.data.length === 0){
                        for(let a=0; a< 4; a++){
                            obj.id = Math.random().toString(36).substring(7) + a
                            resp.data.push(obj)
                        }
                    } else {
                        let cc = 4 - resp.data.length
                        for(let a=0; a<(cc); a++){
                            obj.id = Math.random().toString(36).substring(7) + a
                            resp.data.push(obj)
                        }
                    }
                }
                let index = resp.data.findIndex(item => item.isUnassigned === true)
                if(index !== -1){
                    let unAssigned = resp.data.splice(index, 1)
                    resp.data.push(unAssigned[0])
                }
                dispatch({
                    type: 'SET_USER_GROUPS',
                    payload: resp.data
                })
                dispatch(setParticipants())
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetUserGroups = () => {
    return dispatch => {
        dispatch({type: 'UNSET_USER_GROUPS'})
    }
}

export const changeUserGroups = (data) => {
    return dispatch => {
        dispatch({type: 'SET_USER_GROUPS', payload: data})
    }
}
