import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';
import getStorage from "./customStorage";

const socket = io(process.env.REACT_APP_API_URL, {
    transports: ['websocket'],
    upgrade: false
});
const socketOptions = { timeout: Number(process.env.REACT_APP_REQUEST_TIMEOUT) }
const client = feathers()
    .configure(socketio(socket, socketOptions))
    .configure(auth({ storage: getStorage() }));

client.hooks({
    error(context) {
        const { error } = context;

        if (error.message === "jwt expired") {
            window.location.reload(true);   // reload page | should work on both desktop, mobile (cordova)
        }

        return context;
    }
});

export default client;
