import services from '../../services/';

export const setOpenedProfile = (id, redirect) => {
    return dispatch => {
        services.users.get(id)
            .then((profile) => {
                dispatch({type: 'SET_OPENED_PROFILE', payload: profile})
            })
            .catch((error) => {
                console.log(error)
                if(redirect){
                    redirect()
                }
                dispatch(unsetOpenedProfile())
            })
    }
}

export const changeOpenedProfile = (data) => {
    return dispatch => {
        dispatch({type: 'CHANGE_OPENED_PROFILE', payload: data})
    }
}

export const unsetOpenedProfile = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE'})
    }
}
