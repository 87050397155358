import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Tooltip,
} from "@material-ui/core";
import './new-event-info.scss';
import {useTranslation} from "react-i18next";
import NewUserInfo from "../../User/NewUserInfo/NewUserInfo";
import {useRouteMatch} from "react-router-dom";
import EventTabs from "./EventTabs";
import abbreviate from "number-abbreviate";
import EventGuest from "./EventGuest";
import NewEventHeader from "./NewEventHeader";
import Modal from "../../Modal/Modal";
import ImageCropper from "../../ImageCropper/ImageCropper";
import CloseIcon from "@material-ui/icons/Close";
import services from "../../../services";
import {closeEventInfoModal} from "../../../store/eventInfoModal/actions";
import {setAgendaWeeklyEvents} from "../../../store/agendaWeeklyEvents/actions";
import {setMonthlyEventsCount} from "../../../store/monthlyEventsCount/actions";
import {setOpenedProfileWeeklyEvents} from "../../../store/openedProfileWeeklyEvents/actions";
import CustomButton from "../../CustomButton/CustomButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {showSnackbar} from "../../../store/snackbar/actions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowLeftIcon from "@material-ui/icons/ChevronLeft";
import UserInfo from "../../User/UserInfo/UserInfo";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ZoomIcon from "@material-ui/icons/ZoomOutMap";
import EventHeader from "./EventHeader";
import EventTags from "./EventTags";
import EventActionButtons from "./EventActionButtons";
import ParticipantsList from "../../Forms/AddEventForm/ParticipantsList";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import {Helmet} from 'react-helmet'
import BigEventWebsite from "./BigEventWebsite";
import AddEventFormComponent from "../../Forms/NewAddEventForm/AddEventFormComponent";
import ArchivementForm from "../../Archivements/ArchivementForm";

const NewEventInfo = ({onClose}) => {
    const user = useSelector(state => state.user);
    const event = useSelector(state => state.event);
    const darkTheme = useSelector(state => state.darkTheme);
    const deviceView = useSelector(state => state.deviceView);
    const selectedDate = useSelector(state => state.selectedDate);
    const openedProfile = useSelector(state => state.openedProfile);
    const {t} = useTranslation(['eventDetails', 'extraData', 'userEditForm']);
    const [isOwner, setIsOwner] = useState(false);
    const [eventPoster, setEventPoster] = useState((event && event.poster ? event.poster : isOwner ? "/images/defaultHeader3.png" : "/images/defaultHeader2.png"));
    const [newPoster, setNewPoster] = useState("");
    const [currentTab, setCurrentTab] = useState("description");
    const [openConfirmationEraseEvent, setOpenConfirmEraseEvent] = useState(false);
    const [guests, setGuests] = useState([]);
    const [guestsConfirmeds, setGuestsConfirmeds] = useState([]);
    const [edit, setEdit] = useState(false);
    const [quickCropModal, showQuickCropModal] = useState(false);
    const [modalPoster, setModalPoster] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [eventOriginalPoster, setEventOriginalPoster] = useState((event && event.original_poster ? event.original_poster : ""));
    const [loading, setLoading] = useState(false);
    const [hideControllersForAdmin, setHideControllersForAdmin] = useState(true);
    const [inviteModal, setInviteModal] = useState(false);
    const [parts, setParts] = useState([]);
    const inputPosterRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [archivementModal, setArchivementModal] = useState(false);

    const dispatch = useDispatch();

    const TutorialOverlayEl = useRef(null);

    let isEmbedMode = useRouteMatch("/embed/:userNickname/:eventID?");

    const onChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const back = () => {
        setEdit(false)
    };

    const quickPosterChange = (e) => {
        showQuickCropModal(true);
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            setNewPoster(reader.result);
            setEventOriginalPoster(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const saveEvent = (e) => {
        e.preventDefault();
        setLoading(true);
        services.events.patch(event.id, {
            poster: newPoster,
            original_poster: eventOriginalPoster
        }, null)
            .then(() => {
                dispatch(showSnackbar({message: t("EventForm:new_poster_added"), type: "success"}));
                setLoading(false);
                setEventPoster(newPoster);
                setNewPoster("");
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
    };

    const deleteEvent = () => {
        services.events.remove(event.id)
            .then((resp) => {
                dispatch(closeEventInfoModal())
                if (window.history.state && window.history.state.calendar === true) {
                    dispatch(setAgendaWeeklyEvents(selectedDate));
                    dispatch(setMonthlyEventsCount(selectedDate))
                    window.history.pushState({}, "calendar", `/agenda`)
                } else if (window.history.state && window.history.state.hasOwnProperty('backRef')) {
                    dispatch(setAgendaWeeklyEvents(selectedDate));
                    dispatch(setMonthlyEventsCount(selectedDate))
                    window.history.pushState({}, "calendar", `${window.history.state.backRef}`)
                } else {
                    dispatch(setOpenedProfileWeeklyEvents(selectedDate, openedProfile.id))
                    dispatch(setMonthlyEventsCount(selectedDate, openedProfile.id))
                    window.history.pushState({}, "profile", `/${openedProfile.slug}`)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const closeInviteModal = () => setInviteModal(false);

    const openInviteModal = () => setInviteModal(true);

    const handlePartsChange = (data) => {
        setParts(data);
    }

    const saveGuests = (e) => {
        e.preventDefault();

        if (parts && parts.length && parts.length > 0) {
            setLoading(true);
            let pp = parts.map(x => x.id);

            services.events.patch(event.id, {
                guests: pp,
                categories: Array.isArray(event.categories) ? event.categories : []
            }, null)
                .then(() => {
                        setGuests([...parts]);
                        setParts([]);
                        dispatch(showSnackbar({message: t("userEditForm:snackbar_profile_saved"), type: "success"}));
                        setLoading(false);
                        closeInviteModal();
                    }
                )
                .catch((error) => {
                    console.log(error);
                    dispatch(showSnackbar({message: "Error", type: "error"}));
                    setLoading(false);
                })
        } else {
            closeInviteModal();
        }
    }

    const handleOpenVideoModal = () => {
        if (event.video_external_url && event.video_external_url.length > 0) {

            const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - 960) / 2 / systemZoom + dualScreenLeft
            const top = (height - 680) / 2 / systemZoom + dualScreenTop

            let url = event.video_external_url
                .replace("autoplay=false", "autoplay=true")
                .replace("autostart=false", "autostart=true")
                .replace("fichaPelicula", "fichapelicula");
            window.open(url, 'targetWindow',
                `toolbar=no,
                                titlebar=no,
                                location=no,
                                status=no,
                                menubar=no,
                                scrollbars=false,
                                resizable=false,
                                width=960,
                                dialog=no,
                                height=680,
                                centerscreen=yes,
                                left=${left},
                                top=${top}`).focus();
        }
        //setVideoModal(true);
    };

    useEffect(() => {
        if (event.guests) {
            let only_accepted = event.guests.filter(i => i.event_guest.isConfirmed);
            setGuestsConfirmeds(only_accepted);
            setGuests(only_accepted.slice(0,3));
        }
    }, [event])

    useEffect(() => {
        if (event && event.id) {
            setIsOwner(!!(user && user.id && user.id === event.owner.id));
            setEventPoster((event && event.poster ? event.poster : isOwner ? "/images/defaultHeader3.png" : "/images/defaultHeader2.png"));
            setIsAdmin(user && user.id ? user.roles.includes('admin') : false);
            setEventOriginalPoster((event && event.original_poster ? event.original_poster : ""));
        }
    }, [user, event, isOwner]);

    const handleToggleArchivementModal = () => {
        setArchivementModal(curr => !curr);
    }

    return <>
        {event.id &&
        <Box bgcolor="background.default" className="new-event-info-wrapper">
            <Helmet>
                <title>{t('extraData:singular_text_event')}: {event.title} | Latency</title>
            </Helmet>
            {(deviceView === "browser" || deviceView === "tablet") && (
                <div className="event-info">
                    <div className="info">
                        <div id="portal--tutorial-overlay" ref={TutorialOverlayEl}></div>
                        <NewEventHeader refTutorialOverlayEl={TutorialOverlayEl} onClose={onClose} handleToggleArchivementModal={handleToggleArchivementModal}/>
                        <div className="event-info-center">
                            <div>
                                <div className="title">{t("details_created_by")}:</div>
                                <NewUserInfo profile={event.owner} id={event.owner.id} toProfile={true}
                                             isProfile={false}
                                             isEmbed={isEmbedMode}/>
                            </div>
                            <div>
                                <div className="title"><span
                                    className="count">{guests ? abbreviate(guests.length, 1) : ''}</span> {t("guests")}:
                                </div>
                                <div>
                                    {guests.length > 0 ? (
                                            <div
                                                className={"guests-wrapper-parent " + (darkTheme ? 'is-dark' : 'is-light')}>
                                                <div className="guests-wrapper">
                                                    {guests.map((item, index) =>
                                                        <EventGuest key={index} guest={item}/>
                                                    )}
                                                </div>
                                                {guests.length > 3 &&
                                                <div className="see-more-wrapper">
                                            <span
                                                onClick={(e) =>
                                                    onChangeTab(e, "guests")}
                                                className="see-more">
                                                {t("see_more")}
                                            </span>
                                                </div>}
                                            </div>) :
                                        <Box className={"no-guests-wrapper " + (darkTheme ? 'is-dark' : 'is-light')}
                                             p={1} textAlign="center">
                                            <div><span>{t("no_guests")}</span></div>
                                            {isOwner && (
                                                <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                                    <CustomButton className="invite-btn" onClick={openInviteModal}>
                                                        {t("invite")}
                                                    </CustomButton>
                                                </Box>

                                            )}
                                        </Box>
                                    }
                                </div>
                            </div>
                            {event.website && event.website.length > 0 && (
                                <div className="event-info-highlight-link">
                                    <BigEventWebsite />
                                </div>
                            )}
                        </div>
                        <EventTabs changeTab={onChangeTab} currentTab={currentTab}/>
                    </div>
                    <div className="poster">
                        <div className="background-poster" style={{
                            backgroundImage: `url(${newPoster ? newPoster : eventOriginalPoster})`
                        }}/>
                        <div className="poster-overlay">
                            {(deviceView === "tablet" || deviceView === "browser") && isOwner &&
                            <Fragment>
                                {!isEmbedMode && (
                                    <Fragment>
                                        <div className="floating-action-buttons">
                                            <CustomButton className="submit-btn wide e-small"
                                                          onClick={() => setEdit(true)}>
                                                <EditIcon className="icon"/>
                                                <span>{t('eventDetails:buttons_edit_event')}</span>
                                            </CustomButton>
                                            <CustomButton className="delete-btn wide e-small"
                                                          onClick={() => {
                                                              setOpenConfirmEraseEvent(true);
                                                          }}>
                                                <DeleteIcon className="icon"/>
                                                <span>{t('eventDetails:buttons_delete_event')}</span>
                                            </CustomButton>
                                        </div>
                                    </Fragment>
                                )}
                                <div className="floating-action-buttons-2">
                                    {!newPoster && <CustomButton className="submit-btn wide e-small"
                                                                 onClick={() => inputPosterRef.current.click()}>add
                                        poster</CustomButton>}
                                    {!newPoster &&
                                    <input ref={inputPosterRef} type="file" className="poster-input"
                                           id="quick-event-image"
                                           accept="image/gif, image/jpeg, image/png"
                                           onChange={quickPosterChange}/>}
                                    {newPoster &&
                                    <CustomButton loading={loading} disabled={loading}
                                                  className="submit-btn wide e-small"
                                                  onClick={(e) => saveEvent(e)}> {t('extraData:buttons_confirm')} </CustomButton>}
                                    {newPoster && <CustomButton disabled={loading} className="cancel-btn wide e-small"
                                                                onClick={() => setNewPoster("")}> {t('extraData:buttons_cancel')} </CustomButton>}
                                </div>
                            </Fragment>}
                            {(deviceView === "tablet" || deviceView === "browser") && (!isOwner && isAdmin) &&
                            <Fragment>
                                {hideControllersForAdmin &&
                                <div className="floating-action-buttons" style={{top: '1em', minWidth: 'auto'}}>
                                    <CustomButton className="submit-btn e-hidebtn"
                                                  onClick={() => setHideControllersForAdmin(false)}>
                                        <MoreVertIcon className="icon"/>
                                    </CustomButton>
                                </div>
                                }
                                {!hideControllersForAdmin && (
                                    <>
                                        {!isEmbedMode && (
                                            <Fragment>
                                                <div className="floating-action-buttons">
                                                    <CustomButton className="submit-btn wide e-small"
                                                                  onClick={() => setEdit(true)}>
                                                        <EditIcon className="icon"/>
                                                        <span>{t('eventDetails:buttons_edit_event')}</span>
                                                    </CustomButton>
                                                    <CustomButton className="delete-btn wide e-small"
                                                                  onClick={() => {
                                                                      setOpenConfirmEraseEvent(true);
                                                                      //deleteEvent();
                                                                  }}>
                                                        <DeleteIcon className="icon"/>
                                                        <span>{t('eventDetails:buttons_delete_event')}</span>
                                                    </CustomButton>
                                                </div>
                                            </Fragment>
                                        )}
                                        <div className="floating-action-buttons-2">
                                            {!newPoster && <CustomButton className="submit-btn wide e-small"
                                                                         onClick={() => inputPosterRef.current.click()}>add
                                                poster</CustomButton>}
                                            {!newPoster &&
                                            <input ref={inputPosterRef} type="file" className="poster-input"
                                                   id="quick-event-image"
                                                   accept="image/gif, image/jpeg, image/png"
                                                   onChange={quickPosterChange}/>}
                                            {newPoster &&
                                            <CustomButton loading={loading} disabled={loading}
                                                          className="submit-btn wide e-small"
                                                          onClick={(e) => saveEvent(e)}> {t('extraData:buttons_confirm')} </CustomButton>}
                                            {newPoster &&
                                            <CustomButton disabled={loading} className="cancel-btn wide e-small"
                                                          onClick={() => setNewPoster("")}> {t('extraData:buttons_cancel')} </CustomButton>}
                                        </div>
                                    </>
                                )}
                            </Fragment>}
                            {((deviceView === "tablet" || deviceView === "browser") && event.video_external_url && event.video_external_url.length > 0) &&
                            <Box position="absolute" top="50%" padding="1rem" display="flex" width="100%"
                                 alignItems="center" justifyContent="center">
                                <Button className={"trailer-btn " + (darkTheme ? 'is-dark' : 'is-light')}
                                        onClick={handleOpenVideoModal}>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <span className="icon">
                                            <OndemandVideoIcon fontSize="small"/>
                                        </span>
                                        <span className="label">{t("eventDetails:see_trailer")}</span>
                                    </Box>
                                </Button>
                            </Box>
                            }
                            {(newPoster || !eventPoster.endsWith('.webp')) ? (
                                <img className="img-poster" src={newPoster ? newPoster : eventPoster} alt="event-header" />
                            ) : (
                                <picture className="img-poster">
                                    <source  srcSet={eventPoster} type="image/webp" alt="event-header" />
                                    <img src={event.original_poster} alt="event-header" />
                                </picture>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {deviceView === "mobile" && (
                <Box className="event-info is-mobile" display="grid">
                    <Box className="modal-header" display="flex" flexDirection="row" alignItems="center">
                        <ArrowLeftIcon onClick={onClose} className="close-icon"/>
                        <UserInfo profile={event.owner} id={event.owner.id} toProfile={true} isProfile={false}
                                  isEmbed={isEmbedMode}/>
                        {(isOwner || isAdmin) && (
                            <Fragment>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon/>
                                </IconButton>
                                <Menu
                                    id="more-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => {
                                        setEdit(true);
                                        handleClose();
                                    }}>{t('eventDetails:buttons_edit_event')}</MenuItem>
                                    <MenuItem onClick={() => {
                                        deleteEvent();
                                        handleClose();
                                    }}>{t('eventDetails:buttons_delete_event')}</MenuItem>
                                </Menu>
                            </Fragment>)}
                    </Box>
                    <Box className="poster" onClick={() => setModalPoster(true)}>
                                <span className="icon">
                                    <ZoomIcon fontSize="large"/>
                                </span>
                        <img src={newPoster ? newPoster : eventPoster} alt="event-header"/>
                    </Box>
                    <Box className="info" bgcolor="background.default">
                        <Box className={"event-header" + (darkTheme ? ' is-dark' : ' is-light')}>
                            <EventHeader isOwner={isOwner}/>
                        </Box>
                        <Box className="info-middle">
                            <EventTags event={event} changeTab={onChangeTab}/>
                            <EventActionButtons />
                        </Box>
                        <EventTabs changeTab={onChangeTab} currentTab={currentTab}/>
                    </Box>
                </Box>
            )}
            <Modal
                state={edit}
                persist
                fullScreen={deviceView === "mobile"}
                max={"md"}>

                <AddEventFormComponent event={event} onBack={back} onClose={onClose}/>
            </Modal>
            <Modal
                state={quickCropModal}
                persist={false}
                max={"lg"}
                onClose={() => showQuickCropModal(false)}>

                <ImageCropper
                    cropper={deviceView === "browser" ? {
                        width: 380,
                        height: 680
                    } : (deviceView === "tablet" ? {width: 380, height: 680} : {width: 380, height: 680})}
                    className="add-event"
                    bg={eventOriginalPoster}
                    poster={newPoster} onCrop={(img) => setNewPoster(img)}
                    onClose={() => showQuickCropModal(false)}/>
            </Modal>
            <Modal
                state={modalPoster}
                persist={false}
                fullScreen={false}
                max={"md"}>
                <Box className={'event-modal-poster'}>
                    <CloseIcon onClick={() => setModalPoster(false)} className="close-icon"/>
                    <img className={'event-modal-poster'} src={newPoster ? newPoster : eventPoster}
                         alt="event-header"/>
                </Box>
            </Modal>
            <Modal
                state={inviteModal}
                fullScreen={deviceView === "mobile"}
                max={"sm"}
                onClose={closeInviteModal}
                className="participants-modal"
                persist
                pad>
                <Box width="100%" p={2}>
                    <p>{t("invite")}:</p>
                    <Box my={2}>
                        <ParticipantsList handleChange={handlePartsChange} quick={false}/>
                    </Box>
                    <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                        <CustomButton className="cancel-btn wide mr" disabled={loading}
                                      onClick={closeInviteModal}> {t('extraData:buttons_cancel')} </CustomButton>
                        <CustomButton className="submit-btn wide" loading={loading} onClick={saveGuests}
                                      disabled={loading}> {t('extraData:buttons_save_changes')} </CustomButton>
                    </Box>
                </Box>
            </Modal>
            <Modal
                state={archivementModal}
                fullScreen={deviceView === "mobile"}
                max={"sm"}
                radius={true}
                onClose={handleToggleArchivementModal}
                className="archivements-modal"
                persist
                pad>
                <ArchivementForm eventId={event && event.hasOwnProperty('id') ? event.id : null} onClose={handleToggleArchivementModal} />
            </Modal>
            <Dialog
                open={openConfirmationEraseEvent}
                onClose={() => setOpenConfirmEraseEvent(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('eventForm:dialog_erase_event_headtitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"
                                       className={"dialog-description " + (darkTheme ? ' is-dark' : ' is-light')}>
                        {t('eventForm:dialog_erase_event_text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmEraseEvent(false)} color="primary">
                        {t('eventForm:dialog_erase_event_cancel_btn')}
                    </Button>
                    <Button onClick={() => {
                        deleteEvent();
                        setOpenConfirmEraseEvent(false);
                    }} color="primary" autoFocus>
                        {t('eventForm:dialog_erase_event_confirm_btn')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
        }
    </>
}

export default NewEventInfo;
