import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import './discover-form.scss';
import {Box} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import CustomButton from '../../CustomButton/CustomButton';
import {setDiscoverUsers} from "../../../store/discoverUsers/actions";
import ExploreFeaturedUserItemComponent from "../../Explore/ExploreFeaturedUsers/ExploreFeaturedUserItemComponent";

const DiscoverForm = (props) => {
    const [clicky, setClicky] = useState(0);
    const didMount = useRef(false);
    const clicks = useRef(0);
    const dispatch = useDispatch();
    const darkTheme = useSelector(state => state.darkTheme);
    const deviceView = useSelector(state => state.deviceView);
    const user = useSelector(state => state.user);
    const discoverUsers = useSelector(state => state.discoverUsers);
    const {t} = useTranslation('extraData');

    useLayoutEffect(() => {
        if(!didMount.current) {
            setClicky(user.followingCount);
            clicks.current = user.followingCount;
            didMount.current = true;
        }
        return () => {
            didMount.current = false;
        }
    }, []);

    useEffect(() => {
        dispatch(setDiscoverUsers());
    }, []);


    return (
        <Box className={`discover-form hide-scroll ${deviceView === "mobile" ? " is-mobile" : ""}`} display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
            <span className="box-title">{t('discover_form_title')}</span>
            <div className={`discover-info is-${darkTheme ? 'dark' : 'light'}`}>{t('discover_form_subtitle')}</div>
            <div className={`discover-users ${deviceView === "mobile" ? " is-mobile" : ""}`}>
                {discoverUsers.map((item, index) =>
                    <ExploreFeaturedUserItemComponent key={item.id} onClick={(user, cb) => {
                        cb();
                        clicks.current = clicks.current + 1;
                        setClicky(dr => dr + 1);
                    }} user={item.user} />
                )}
            </div>
            <Box className="discover-form-actions" display="flex" flexDirection="row" justifyContent="center">
                <CustomButton disabled={clicks.current < 4} onClick={props.onClose} className="submit-btn wide"> {t('buttons_continue')}</CustomButton>
            </Box>
            <div style={{
                visibility: 'hidden'
            }}>
                {clicks.current} - {clicky}
            </div>
        </Box>
    )
}

export default DiscoverForm;
