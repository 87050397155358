import React, {useState} from 'react';
import './register.scss';
import {Box} from '@material-ui/core';
import RegisterForm from '../../components/Forms/RegisterForm/RegisterForm';
import Logo from '../../components/Logo/Logo';
import CustomLink from '../../components/CustomLink/CustomLink';
import {register} from '../../store/user/actions';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet";

const Register = () => {
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const {t} = useTranslation('frontPage')

    const handleSubmit = (params) => {
        dispatch(register(params, onError))
    }

    const onError = () => {
        setError(true)
    }

    return (
        <Box className="page-content" bgcolor="header.main" color="text.primary">
            <Helmet>
                <title>{t('title_sign_up')} / Latency</title>
            </Helmet>
            <Box className="register-page" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Logo className="register-logo" />
                {/* <h2 className="heading"> {t('headings_sign_up')} </h2> */}
                <RegisterForm onSubmit={handleSubmit} error={error} />
                <CustomLink className={'light-link'} to="/login" textLight={t('buttons_already_sign_up_intro')} textBold={t('buttons_log_in')} />
            </Box>
        </Box>
    )
}

export default Register;
