import './_landing_valorant.scss';
import {Box, Tab, Tabs} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import LandingHeader from "../LandingMain/fragments/Header/LandingHeader";
import {getUserProfile} from "../../../store/user/actions";
import {useHistory, useLocation} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Event} from "../../../services/tracking";
import LandingCoverComponent from "../LandingCover/LandingCoverComponent";
import LandingFeedComponent from "../LandingMain/fragments/LandingFeedComponent/LandingFeedComponent";
import LandingMainTabLiveNow from "../LandingMain/fragments/LiveNowComponent/LandingMainTabLiveNow";
import LandingHighlightsComponent from "../LandingMain/fragments/LandingHighlightsComponent/LandingHighlightsComponent";
import DragScrollProvider from "drag-scroll-provider";
import ProfileAgenda from "../../Profile/ProfileAgenda/ProfileAgenda";
import EventsPassedComponentByDay
    from "../LandingMain/fragments/EventsPassedComponent/LandingEventsPassedByDayComponent";
import LandingParticipantsComponent
    from "../LandingMain/fragments/LandingParticipantsComponent/LandingParticipantsComponent";
import LandingTeamsComponent from "../LandingMain/fragments/LandingTeamsComponent/LandingTeamsComponent";
import LandingCustomTabComponent from "../LandingMain/fragments/LandingCustomTabComponent/LandingCustomTabComponent";
import {LandingValorantCoverComponent} from "./LandingValorantCoverComponent";

const componentName = "LandingValorant"

export const LandingValorant = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const {i18n, t} = useTranslation('landing');
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const [cssDeviceView, setCSSDeviceView] = useState("");

    const [loading, setLoading] = useState(true);
    const [userID, setUserID] = useState(undefined);
    const [userSlug, setUserSlug] = useState("");
    const [profileData, setProfileData] = useState(undefined);
    const [tab, setTab] = useState(0);

    const [eventsWithPlayerOpen, setEventsWithPlayerOpen] = useState([]);
    const [eventWithClipOpen, setEventWithClipOpen] = useState(undefined);

    const [customTabs, setCustomTabs] = useState([]);
    const _defaultLandingTabs = [t("home"), t("agenda"), t("live_now"), t("teams")];
    const [landingTabs, _setLandingTabs] = useState(_defaultLandingTabs);
    const landingTabsRef = React.useRef(landingTabs);
    const setLandingTabs = data => {
        landingTabsRef.current = data;
        _setLandingTabs(data);
    };

    const resources = {
        headerVideo: "/dist/special/valorant.mp4",
        headerVideoWebm: "/dist/special/valorant.webm",
        headerMobile: "/dist/special/valorant-mobile.jpg"
    };

    useEffect(() => {
        setLoading(true);
        dispatch(getUserProfile("valorant.oficial", () => {
            history.push('/not-found');
        }, (user) => {
            setProfileData(user);
            setUserID(user.id);
            setUserSlug(user.slug.replaceAll('.', '-'))
            setLoading(false);
        }));
    }, []);

    useEffect(() => {
        let class_string = darkTheme ? "is-dark-theme" : "is-light-theme"
        if (deviceView === "mobile" || deviceView === "tablet") {
            class_string += ` is-${deviceView}`;
        } else {
            class_string += ` is-desktop`;
        }
        setCSSDeviceView(class_string);
    }, [darkTheme, deviceView]);

    const handleCustomTab = (val) => {
        let tab = landingTabsRef.current.indexOf(val);
        if (tab !== -1) {
            setTab(tab)
            window.scrollTo(0, 0)
        }
    }

    const changeTab = (e, val) => {
        if (typeof val === 'number' && (val % 1) === 0) {
            let tab = landingTabsRef.current[val];
            if (tab !== undefined) {
                setTab(val);
                let slugify = tab.toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
                history.push(`#${slugify}`)
                Event("LandingMain", "ChangeTab", tab);
            }
        } else {
            handleCustomTab(val);
            let tab = landingTabsRef.current.indexOf(val);
            if (tab !== undefined) {
                let slugify = landingTabsRef.current[tab].toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
                history.push(`#${slugify}`)
                Event(componentName, "ChangeTab", landingTabsRef.current[tab]);
            }
        }
    }

    const handleOpenPlayer = (eventId) => {
        if (!eventsWithPlayerOpen.includes(eventId)) {
            setEventsWithPlayerOpen([...eventsWithPlayerOpen, eventId]);
        }
        Event(componentName, "OpenPlayer", eventId);
    };
    const handleOpenClip = (eventId) => {
        setEventWithClipOpen(eventId);
        Event(componentName, "OpenClip", eventId);
    };
    const handleDeleteOnePlayer = (eventId) => {
        setEventsWithPlayerOpen([...eventsWithPlayerOpen.filter(i => i !== eventId)]);
        Event(componentName, "DeleteOnePlayer", eventId);
    }

    return (
        <>
            <Helmet>
                <title>Valorant Oficial · Live / Latency</title>
                <html lang={i18n.language}/>
                {i18n.language === "es" ? (
                    <meta name="description"
                          content={`Sigue todo el panorama competitivo de Valorant en esta exclusiva Landing y no te pierdas ningún evento de tu equipo favorito. Construye tu agenda interactiva en Latency. Encuentra y sigue tus intereses para disfrutar de un calendario de eventos en cualquier momento del día.`}/>
                ) : (
                    <meta name="description"
                          content={`Follow the full competitive scene of Valorant in this exclusive landing and don't miss any of your favorite team's events. Get your own interactive agenda in Latency. Find and follow your interests to enjoy a calendar of events at any time of the day.`}/>
                )}
                <meta name="keywords"
                      content={`latency,agenda,eventos,agenda interactiva,valorant calendario,valorant partidos,valorant competitivo,valorant g2,valorant heretics,valorant giants,valorant kru,valorant koi,valorant resultados,valorant results,valorant matches,valorant tournament,valorant highlights,valorant actualidad`}/>
                <link rel="canonical" href={`https://latencyapp.com/valorant/live`}/>
                <link rel="canonical" href={`https://latencyapp.com/valorant`}/>
            </Helmet>
            <Box
                className={`latency-valorant-landing ${cssDeviceView}`}
                display="flex"
                flexDirection="row" justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">

                {loading && (
                    <div className="loading">
                        <CircularProgress size={48} thickness={4} className="input-loader"/>
                    </div>
                )}

                {!loading && (
                    <>
                        <LandingHeader
                            isFollow={profileData ? profileData.follow : false}
                            userId={userID}
                            userNickname={profileData ? profileData.nickname : ""}
                            headerMobile={profileData ? resources.headerMobile : null}
                            headerVideo={profileData ? resources.hasOwnProperty('headerVideoWebm') ? [
                                    resources.headerVideoWebm,
                                    resources.headerVideo
                                ] :
                                resources.headerVideo : null}
                            userSlug={profileData ? profileData.slug : ""}
                            changeTab={handleCustomTab}
                        />
                        <div className={`latency-landing-container ${cssDeviceView}`}>
                            <Box className="landing-menu-tabs">
                                <Tabs scrollButtons="auto" variant="scrollable"
                                      value={tab}
                                      onChange={changeTab}
                                      classes={{indicator: 'indicator'}}
                                      className="menu"
                                >
                                    {landingTabs && landingTabs.map((value, index) =>
                                        <Tab label={value} key={`landing-tab-option-${index}`}
                                             classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                                    )}
                                </Tabs>
                            </Box>
                            <Box className="section">
                                {landingTabs[tab] === t("home") && <LandingValorantCoverComponent userId={userID} changeTab={changeTab} />}
                                {landingTabs[tab] === t("live_now") &&
                                <LandingMainTabLiveNow userId={userID} onOpenPlayer={handleOpenPlayer}
                                                       onDeleteOpenPlayer={handleDeleteOnePlayer}
                                                       eventsWithPlayerOpen={eventsWithPlayerOpen}/>}
                                {landingTabs[tab] === t("agenda") && (
                                    <DragScrollProvider>
                                        {({onMouseDown, ref, scrollTo}) => (
                                            <div className="landing-agenda-container scrollable"
                                                 ref={ref}
                                                 onMouseDown={onMouseDown}>

                                                <ProfileAgenda
                                                    enableBackRef={window.location.pathname}
                                                    centerOnDay={scrollTo}
                                                />
                                            </div>
                                        )}
                                    </DragScrollProvider>
                                )}
                                {landingTabs[tab] === t("teams") &&
                                <LandingTeamsComponent userId={userID}/>
                                }
                            </Box>
                        </div>
                    </>
                )}


            </Box>
        </>
    );
};
