import React from 'react';
import {Box} from '@material-ui/core';
import {useSelector} from 'react-redux';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {ReactComponent as VerifiedUserIcon} from "../../../assets/icons/user/check-new.svg";


const UserResult = (props) => {
    const {user} = props
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <Box bgcolor="search.content" className={"user-result " + (darkTheme ? "dark" : "light")} display="flex"
             flexDirection="row" justifyContent="flex-start">
            <img src={user.avatar ? user.avatar : "/images/defaultAvatar.png"} alt="latency" className="image"/>
            <Box minHeight="40px" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                {(!user.firstName || !user.lastName) &&
                <h6 className="user-name">{user.nickname}</h6>}
                {user.firstName && user.lastName &&
                <h6 className="user-name">{user.firstName} {user.lastName}</h6>}
                {user.account_verified &&
                <VerifiedUserIcon width={14} height={15} className="check-icon"/>}
            </Box>
        </Box>
    )
}

export default UserResult;
