import React, {useState, useEffect} from 'react';
import './_profile_archivements_component.scss';
import {isSameYear} from 'date-fns'
import {archivementStatusPreValues} from "../../../helpers/archivementStatusStaticList";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import EventIcon from '@material-ui/icons/Event';
import {formatDate} from "../../../helpers/dateFormatter";
import {getLocaleDateString} from "../../../helpers/localeDateFormat";

import {setOpenedEvent} from "../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../store/eventInfoModal/actions";
import TrophyIcon from "@material-ui/icons/EmojiEvents";
import {Box} from "@material-ui/core";
import NewTrophy from "../../../assets/icons/achievement.png";
import {Event} from "../../../services/tracking";


const ProfileArchivementListItemNewComponent = ({id, event, statusId, achieved_date}) => {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const current_date = new Date();
    const [status, setStatus] = useState({});

    useEffect(() => {
        let find_status = archivementStatusPreValues.find(i => i.id === statusId);
        if (find_status) {
            setStatus(find_status);
        }

    }, [statusId]);

    const handleOpenEvent = (e) => {
        e.preventDefault();
        window.history.pushState({backRef: window.location.pathname}, "Latency", `/${event.owner.slug}/${event.id}`)
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("ProfileArchivementListItemNewComponent", "handleOpenEvent", `/${event.owner.slug}/${event.id}`);
    }

    return (
        <a href={`/${event.owner.slug}/${event.id}`} onClick={handleOpenEvent}>
            <Box bgcolor="grid.main" className="profile-archivements-list-component-item--new">
                {status && status.hasOwnProperty('label') && status.label === "Completed" && (
                    <div className="profile-archivements-list-component-item--trophy-badge">
                        {/*
                        <TrophyIcon />
                        */}
                        <img width="32" height="32" src={NewTrophy} alt="Achivement Completed" className="trophy"/>
                    </div>
                )}
                <div className="profile-archivements-list-component-item--new-image_box">
                    <div className="background-poster" style={{
                        backgroundImage: `url(${event.original_poster})`
                    }}/>
                    <img width="100%" height="100%" src={event.poster}
                         alt={`${event.title}`}/>
                </div>
                <h2 className="profile-archivements-list-component-item--new-title">{event.title}</h2>
                <div className="profile-archivements-list-component-item--info-inline">
                    <div className={`profile-archivements-list-component-item--info-inline-archivement-wrap`}>
                        <div
                            className={`profile-archivements-list-component-item--info-inline-archivement is-${status.hasOwnProperty('label') ? status.label.toLowerCase() : "nostatus"}`}>
                            {status.hasOwnProperty('icon') && status.icon}
                            {status.hasOwnProperty(`text_${i18n.language}`) ? status[`text_${i18n.language}`] : status.label}
                        </div>
                        <div className={`profile-archivements-list-component-item--info-inline-achieved`}>
                            <EventIcon/>
                            {isSameYear(achieved_date, current_date) ? formatDate(achieved_date, "d LLL") : formatDate(achieved_date, getLocaleDateString())}
                        </div>
                    </div>
                </div>
            </Box>
        </a>
    );
};

export default ProfileArchivementListItemNewComponent;
