import React, {useEffect, useState} from 'react';
import UserGroupEventsCard from "../../User/UserGroupEventsCard/UserGroupEventsCard";
import {Box, ButtonBase} from "@material-ui/core";
import EventCard from "../EventCard/EventCard";
import './user-grouped-events-card.scss';
import {useSelector} from "react-redux";
import {isPast} from 'date-fns';


const UserGroupedEventsCard = ({ownerId, events, pipeDate, isCalendar, alwaysShowPing, onClickEvent}) => {
    const date_right_now = new Date();
    const darkTheme = useSelector(state => state.darkTheme);
    const [expand, setExpand] = useState(false);
    const [haveReping, setHaveReping] = useState(false);
    const [havePassedEvents, setPassedEvents] = useState(false);

    useEffect(() => {
        let BreakException = {};
        try {
            let countPassedEvents = 0;
            events.forEach((event) => {
                if (event.event_cache_view?.isPing) {
                    setHaveReping(true);
                    throw BreakException;
                }
                if (isPast(new Date(event.end), date_right_now)) {
                    countPassedEvents++;
                }

            });
            if (countPassedEvents === events.length) {
                setPassedEvents(true);
            } else {
                setPassedEvents(false);
            }
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }, [events]);


    return (
        <>
            {events.length === 1 ? (
                <div key={`${events[0].id}.${pipeDate.getTime()}`} onClick={() => onClickEvent(events[0])}
                     className="event">
                    <EventCard pipeDate={pipeDate} isCalendar={isCalendar} alwaysShowPing={alwaysShowPing}
                               event={events[0]}/>
                </div>
            ) : (
                <Box
                    className={`user-group-events-agenda ${havePassedEvents ? ' is-passed' : ''} ${expand ? ' is-open' : ''} ${darkTheme ? ' is-dark-theme' : ' is-light-theme'} ${haveReping ? ' is-reping' : ''}`}
                    display="flex"
                    flexDirection="column" bgcolor="events.wrap">
                    <ButtonBase centerRipple={true} onClick={() => setExpand(!expand)}>
                        <UserGroupEventsCard isExpanded={expand} profile={events[0].owner} count={events.length}/>
                    </ButtonBase>

                    {expand && (
                        <div className={"events"}>
                            {events.map((event, i2) =>
                                <div key={`${event.id}.${i2}.${pipeDate.getTime()}`} onClick={() => onClickEvent(event)}
                                     className="event">
                                    <EventCard pipeDate={pipeDate} isCalendar={isCalendar}
                                               alwaysShowPing={alwaysShowPing}
                                               event={event}/>
                                </div>
                            )}
                        </div>
                    )}
                </Box>
            )}

        </>
    );
};

export default UserGroupedEventsCard;
