import EXIF from "exif-js";

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

function base64ToArrayBuffer (base64) {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    let mimeType = "image/jpeg";
    try {
        mimeType = imageSrc.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    } catch (e) {
        mimeType = "image/png";
    }
    mimeType = "image/png";

    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    let safeArea = Math.max(image.width, image.height) * 2;
    if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        safeArea = Math.max(image.width, image.height);
        mimeType = "image/png";
    }
    try {
        if(!imageSrc.startsWith('blob:')) {
            let exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(imageSrc));
            switch(exif.Orientation){
                case 8:
                    ctx.rotate(90*Math.PI/180);
                    break;
                case 3:
                    ctx.rotate(180*Math.PI/180);
                    break;
                case 6:
                    ctx.rotate(-90*Math.PI/180);
                    break;
            }
        }
    }catch(e){

    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    )

    // As Base64 string
    return canvas.toDataURL(mimeType);

    // As a blob
    // return new Promise(resolve => {
    //   canvas.toBlob(file => {
    //     resolve(URL.createObjectURL(file))
    //   }, 'image/jpeg')
    // })
}
