import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import './_styles.scss';
import {Grid} from "@material-ui/core";
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import services from "../../../../../services";
import VlrItemComponent from "./VlrItemComponent";
import {setOpenedEvent} from "../../../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../../../store/eventInfoModal/actions";
import {Event} from "../../../../../services/tracking";
import VlrGroupItemComponent from "./VlrGroupItemComponent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import AdBoxComponent from "../../../../AdsenseBlocks/AdBoxComponent";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const VlrLandingMatchesMobileComponent = ({userId}) => {
    const dispatch = useDispatch();
    const nowDate = new Date();

    const { t } = useTranslation('landing');

    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loadingUpcoming, setLoadingUpcoming] = useState(true);

    const [liveEvents, setLiveEvents] = useState([]);
    const [loadingLiveEvents, setLoadingLiveEvents] = useState(true);

    const [passedEvents, setPassedEvents] = useState([]);
    const [loadingPassedEvents, setLoadingPassedEvents] = useState(true);

    const [tournaments, setTournaments] = useState([]);
    const [filterByTournament, setFilterByTournament] = useState(null);

    const [currentTab, setCurrentTab] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [datar, setData] = useState({
        live: [],
        upcoming: {}
    });
    const darkTheme = useSelector(state => state.darkTheme);

    const fetchUpcomingEvents = (user, max, extraQuery) => {
        const dateObj = formatDate(new Date(), dateFormat)
        let data = {
            start: dateObj.substring(0, dateObj.length - 9) + "00:00.000",
            timezone: getCurrentTimezone(),
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.eventsLandingUpcoming.get(user, {
            query: data
        });
    }

    const fetchMatchesEvents = (user, max, is_live, extraQuery) => {
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        const dateObjFuture = new Date();
        dateObjFuture.setDate(dateObjFuture.getDate() + 2);
        let data = {
            start: formatDate(ownStartOfDay(dateObj), dateFormat),
            end: formatDate(ownEndOfDay(dateObjFuture), dateFormat),
            timezone: getCurrentTimezone(),
            isLive: is_live
        }
        data = {
            ...data,
            ...extraQuery
        }
        if (Number.isInteger(max) && max > 0) {
            data.$limit = max;
        }
        return services.landingEventsLiveScores.get(userId, {
            query: data
        });
    }

    useEffect(() => {
        let mounted = true;
        setLoadingUpcoming(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchUpcomingEvents(userId, 12, extraQuery).then((res) => {
            if (mounted) {
                setUpcomingEvents(res);
                setLoadingUpcoming(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);


    useEffect(() => {
        let mounted = true;
        setLoadingLiveEvents(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchMatchesEvents(userId, 12, true, extraQuery).then((res) => {
            if (mounted) {
                setLiveEvents(res);
                setLoadingLiveEvents(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);

    useEffect(() => {
        let mounted = true;
        setLoadingPassedEvents(true);
        let extraQuery = {};
        if(filterByTournament !== null) {
            extraQuery.location = filterByTournament;
        }
        fetchMatchesEvents(userId, 12, false, extraQuery).then((res) => {
            if (mounted) {
                setPassedEvents(res);
                setLoadingPassedEvents(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        return () => mounted = false;
    }, [userId, filterByTournament]);

    useEffect(() => {
        if (!loadingUpcoming && !loadingLiveEvents && !loadingPassedEvents) {
            let combine = [...upcomingEvents, ...liveEvents, ...passedEvents];
            let grouped = combine.reduce(
                (result, item) => ({
                    ...result,
                    [item['tournament']]: [
                        ...(result[item['tournament']] || []),
                        item,
                    ],
                }),
                {},
            );
            if(filterByTournament === null) {
                setTournaments(Object.keys(grouped));
            }

            let watfaq = upcomingEvents.reduce(
                (result, item) => {
                    let date_formatted = formatDate(new Date(item['start']), 'yyyyMMdd');
                    return {
                        ...result,
                        [date_formatted]: [
                            ...(result[date_formatted] || []),
                            item,
                        ],
                    }
                },
                [])

            let new_data = {
                live: [...liveEvents],
                upcoming: {...watfaq}
            };
            setData(new_data);
        }
    }, [upcomingEvents, liveEvents, passedEvents, loadingUpcoming, loadingLiveEvents, loadingPassedEvents, filterByTournament]);


    const OpenInfo = (event) => {
        window.history.pushState({backRef: window.location.pathname}, "Event: " + event.title + " | Latency", `/${event.hasOwnProperty('slug') ? event.slug : event.owner.slug}/${event.id}`)
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("VlrLandingMatchesComponent", "OpenEvent", event.id);
    };

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    return (
        <div className="landing-cover-vlr-matches">
            <Box className={`container-background${darkTheme ? ' dark' : ' light'}`} margin="auto" p={1}
                 borderRadius={0}>

                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Tabs value={currentTab} onChange={handleChangeTab}>
                        <Tab label={t('coming_matches')} />
                        <Tab label={t('results_matches')} />
                    </Tabs>

                    <div>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClickMenu}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '24ch',
                                },
                            }}
                        >
                            <MenuItem selected={filterByTournament === null} onClick={handleCloseMenu}>
                                Todas
                            </MenuItem>
                            {tournaments.map((option) => (
                                <MenuItem key={option} selected={option === filterByTournament} onClick={handleCloseMenu}>
                                    {option.replace("Champions Tour", "")}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </Box>

                <Grid container spacing={2} p={1}>
                    <Grid item xs={12} md={6}>
                        {currentTab === 0 && (
                            <div className="matches">
                                {datar.live.map(ev => <VlrItemComponent key={ev.id} event={ev} onOpenEventModal={OpenInfo}
                                                                        nowDate={nowDate} type="live" />)}
                                {Object.keys(datar.upcoming).map((group, index) => <VlrGroupItemComponent key={index} dateString={group} events={datar.upcoming[group]} onOpenEventModal={OpenInfo}
                                                                                                          nowDate={nowDate} type="upcoming" />)}
                                {(datar.live.length === 0 && Object.keys(datar.upcoming).length === 0) && (
                                    <div className="vlr-landing-score-empty">
                                        {t("empty_matches")}
                                    </div>
                                )}
                            </div>
                        )}
                        {currentTab === 1 && (
                            <div className="matches">
                                {passedEvents.map(ev => <VlrItemComponent key={ev.id} event={ev} onOpenEventModal={OpenInfo}
                                                                          nowDate={nowDate} type="passed" />)}
                                {passedEvents.length === 0 && (
                                    <div className="vlr-landing-score-empty">
                                        {t("empty_results")}
                                    </div>
                                )}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default VlrLandingMatchesMobileComponent;
