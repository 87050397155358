import React, {useEffect, useState} from 'react';
import Flag from 'react-world-flags'
import {isToday} from "date-fns";
import {formatDate} from "../../../../../helpers/dateFormatter";
import BellIconOff from '../../../../../assets/icons/actions/bell-off-grey.svg';
import {isSameDay} from 'date-fns'
const {utcToZonedTime} = require('date-fns-tz');

export const ValorantUpcomingEvent = ({ start, team_local, team_guest, original_poster, tournament}) => {
    const [startDate, setStartDate] = useState({
        original: null,
        es: null,
        arg: null,
        mx: null,
        us: null
    });

    const prepareDate = (date, tz) => {
        return utcToZonedTime(date, tz)
    }

    useEffect(() => {
        setStartDate({
            original: new Date(start),
            es: prepareDate(start, 'Europe/Madrid'),
            arg: prepareDate(start, 'America/Argentina/Buenos_Aires'),
            mx: prepareDate(start, 'America/Mexico_City'),
            us: prepareDate(start, 'America/Los_Angeles')
        });
    }, [start]);

    return (
      <div className="embed-next-events-item">
          <div className="event-score--team is-home">
              <div className="event-score--team-image">
                  <img src={team_local.image} alt={team_local.name}/>
              </div>
              <div className="event-score--team-name">
                  {team_local.name}
              </div>
          </div>
          <div className="event-score--versus">
              <div className="event-score--versus-label">vs</div>
              <div className="timezones">
                  <div className="event-score--versus-timezone">
                      <Flag code="es" height="12" />
                      <span>{formatDate(startDate.es, 'HH:mm')}h{!isSameDay(startDate.es, startDate.original) && <span className="dayplus">+1</span>}</span>
                  </div>
                  <div className="event-score--versus-timezone">
                      <Flag code="ar" height="12" />
                      <span>{formatDate(startDate.arg, 'HH:mm')}h{!isSameDay(startDate.arg, startDate.original) && <span className="dayplus">+1</span>}</span>
                  </div>
                  <div className="event-score--versus-timezone">
                      <Flag code="mx" height="12" />
                      <span>{formatDate(startDate.mx, 'HH:mm')}h{!isSameDay(startDate.mx, startDate.original) && <span className="dayplus">+1</span>}</span>
                  </div>
                  <div className="event-score--versus-timezone">
                      <Flag code="us" height="12" />
                      <span>{formatDate(startDate.us, 'HH:mm')}h{!isSameDay(startDate.us, startDate.original) && <span className="dayplus">+1</span>}</span>
                  </div>
              </div>

          </div>
          <div className="event-score--team is-guest">
              <div className="event-score--team-image">
                  <img src={team_guest.image} alt={team_guest.name}/>
              </div>
              <div className="event-score--team-name">
                  {team_guest.name}
              </div>
          </div>
          <div className="event-score--tournament">
              <div className="top-bar">
                  <div className="event-score--tournament-start">
                      {isToday(new Date(start)) ? 'Hoy' : formatDate(start, "iii d")}
                  </div>
                  <div className="event-score--tournament-name">
                      <img src={`https://latencyapp.com${original_poster}`} alt={tournament} />
                      <span>{tournament.substring(0, 32)}{tournament.length > 32 ? '...' : ''}</span>
                  </div>
                  <div className="event-score--tournament-actions">
                      <img src={BellIconOff} />
                  </div>
              </div>

              <div className="bg-ss" />
          </div>
      </div>
    );
}
