import React from "react";
import { Box } from "@material-ui/core";
import './social-network-buttons.scss';
import facebookIcon from '../../../assets/icons/actions/fb.svg';
import twitterIcon from '../../../assets/icons/actions/twitter.svg';
import twitchIcon from '../../../assets/icons/actions/twitch.svg';
import googleIcon from '../../../assets/icons/actions/google-plus.svg';
import appleIcon from '../../../assets/icons/actions/apple.svg';

const SocialNetworkButtons = () => {
  return (
    <Box className="pinging-social-network-buttons-login">
      <a href={`${process.env.REACT_APP_API_URL}/oauth/facebook`}
         className="social-network-button social-network-facebook">
        <span className="icon">
          <img src={facebookIcon} />
        </span>
        <span className="text">
        Continuar con Facebook
        </span>
      </a>
      <a href={`${process.env.REACT_APP_API_URL}/oauth/twitter`}
         className="social-network-button social-network-twitter">
        <span className="icon">
          <img src={twitterIcon} />
        </span>
        <span className="text">
        Twitter
        </span>
      </a>
        <a href={`${process.env.REACT_APP_API_URL}/auth/apple`}
           className="social-network-button social-network-apple">
        <span className="icon">
          <img src={appleIcon} />
        </span>
            <span className="text">
        Apple
        </span>
        </a>
      <a href={`${process.env.REACT_APP_API_URL}/oauth/google`}
         className="social-network-button social-network-google">
        <span className="icon">
          <img src={googleIcon} />
        </span>
        <span className="text">
        Google
        </span>
      </a>
      <a href={`${process.env.REACT_APP_API_URL}/oauth/twitch`} className="social-network-button social-network-twitch">
        <span className="icon">
          <img src={twitchIcon} />
        </span>
        <span className="text">
        Twitch
        </span>
      </a>
    </Box>
  );
};

export default SocialNetworkButtons;
