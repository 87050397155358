import React, {useEffect} from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SwitchMode from "../SwitchMode/SwitchMode";
import Brightness3Icon from '@material-ui/icons/Brightness3';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import {useDispatch, useSelector} from "react-redux";
import {changeDarkTheme} from '../../store/darkTheme/actions';
import {Event} from "../../services/tracking";

const ThemeToggle = () => {
    const darkTheme = useSelector(state => state.darkTheme)
    const dispatch = useDispatch()

    const changeTheme = (event) => {
        Event('ThemeToggle', "changeTheme", !darkTheme ? 'DarkTheme' : 'LightTheme');
        dispatch(changeDarkTheme(!darkTheme))
    }

    return (
        <Typography bgcolor="grid.content" component="div" className="switch-mode-wrapper">
            <Grid component="label" container alignItems="center" spacing={0}>
                <Grid item className="switch-mode-sun">
                    <WbSunnyIcon color="secondary" className={"sun-icon " + (darkTheme ? " black " : " green ")}/>
                </Grid>
                <Grid item>
                    <SwitchMode
                        checked={darkTheme}
                        onChange={changeTheme}
                        value="darkMode"
                    />
                </Grid>
                <Grid item className="switch-mode-moon">
                    <Brightness3Icon color="secondary" className={"moon-icon " + (darkTheme ? " green " : " black ")}/>
                </Grid>
            </Grid>
        </Typography>
    )
}

export default ThemeToggle;
