import React from 'react';
import {Box} from '@material-ui/core';
import {Link} from "react-router-dom";
import { timeAgo } from '../../helpers/dateFormatter';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const FollowNotification = (props) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const {user, data} = props;
    const { t } = useTranslation(['notifications']);
    const actor = data.notificationObject.notificationChange.actor;
    const entity = data.notificationObject.entity;

    return (
        <Box bgcolor={"search.main"} className={"follow-notification " + (darkTheme ? "dark" : "light")} display="flex" flexDirection="row" justifyContent="flex-start">
            <Box className="image-box">
                <img src={actor.avatar ? actor.avatar : "/images/defaultAvatar.png"} alt ="latency" className="image" />
            </Box>
            <Box className="noti-content" minHeight="40px" display="flex" flexDirection="column" justifyContent="center">
                <span className="text-content">
                    {actor.id !== user.id ? <span className="actor"> <Link to={`/${actor.slug}`} onClick={props.onClose}> {actor.nickname} </Link> </span> : t('notification_you_actor')}
                    <span className="text"> {t('notification_follow_message')} </span>
                    {entity.id !== user.id ? <span className="entity"> <Link to={`/${entity.slug}`} onClick={props.onClose}> {entity.nickname} </Link> </span> : t('notification_you_entity')}
                </span>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <span className="time"> {timeAgo(data.createdAt)} </span>
                    {data && data.status === 0 && <VisibilityIcon onClick={() => props.onRead(data)} className={"read-icon green"} />}
                    {data && data.status === 1 && <VisibilityIcon className={"read-icon grey"} />}
                </Box>
            </Box>
        </Box>
    );
};

export default FollowNotification;
