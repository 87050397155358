import React, {Fragment, useEffect, useRef} from 'react';
import './event-card.scss';
import {Box, Tooltip} from '@material-ui/core';
import {formatDate} from '../../../helpers/dateFormatter';
import ChatIconGrey from '../../../assets/icons/actions/commentsGrey.svg'
import ChatIcon from '../../../assets/icons/actions/comments.svg'
import BellIconOn from '../../../assets/icons/actions/bell-on.svg';
import privateEvent from '../../../assets/icons/event/icon-visibility-private-red.svg'
import PingTag from './PingTag';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {isSameDay, format, isPast} from 'date-fns'
import abbreviate from "number-abbreviate";
import LinesEllipsis from 'react-lines-ellipsis'
const EventCard = (props) => {
    const ref = useRef();

    const currentUser = useSelector(state => state.user);
    const userFollowing = useSelector(state => state.userFollowing);

    const {event, isCalendar, pipeDate} = props
    const {t} = useTranslation(['eventForm', 'extraData'])
    const openedProfile = useSelector(state => state.openedProfile)
    const darkTheme = useSelector(state => state.darkTheme)
    const eventStart = new Date(event.start);
    const eventEnd = new Date(event.end);
    const _isSameDay = isSameDay(eventStart, eventEnd);
    const formatPipeDate = pipeDate && pipeDate instanceof Date ? format(pipeDate, 'yyy-MM-dd') : null;
    const settingsExperimental = useSelector(state => state.settings.general.experimental);

    const eventPoster = event.owner && event.poster && event.poster !== "/images/defaultHeader2.png" ? event.poster : "/images/defaultAvatar.png";

    const showGuestLayer = (ev) => {
        let showGuest = null;
        if(ev.hasOwnProperty('event_cache_view')) {
            if (openedProfile && openedProfile.id && ev.event_cache_view.isGuest && ev.guests.length > 0 && ev.guests.some(i => i.userId === openedProfile.id && i.isConfirmed === true)) {
                showGuest = {user: openedProfile};
            } else if ((ev.event_cache_view.hasFollowerGuest || ev.event_cache_view.isGuest) && !ev.event_cache_view.isPing && ev.guests.length > 0) {
                showGuest = event.guests.find(guest => guest.follow === true && guest.isConfirmed === true);
                if (showGuest === undefined) {
                    showGuest = event.guests.find(guest => guest.userId === currentUser.id);
                    if (showGuest === undefined) {
                        const multipleExist = event.guests.filter(value => {
                            return userFollowing.includes(value.userId);
                        });
                        if(multipleExist && multipleExist.length > 0) {
                            showGuest = multipleExist[0]
                        } else {
                            return null;
                        }
                    }
                }
            } else {
                return null;
            }
        } else {
            return null;
        }


        return (
            <span className={"overlapGuests" + (darkTheme ? ' isDarkMode ' : '')}>
                <img src={showGuest.user.avatar} className="tmpAvatar" alt={showGuest.user.nickname}/>
            </span>
        );
    };

    return (
        <Box ref={ref}
             className={`event-card animated fadeIn ${darkTheme ? " is-dark-theme" : " is-light-theme"} ${isCalendar && (
                 event.hasOwnProperty('event_cache_view') && event.event_cache_view.isPing &&
                     currentUser && currentUser.hasOwnProperty('id') &&
                 event.event_cache_view.userId === currentUser.id
             ) ? "ping" : "trans"} ${(event.premium_display_poster && event.poster && event.poster !== "/images/defaultHeader2.png") ? "is-premium-poster" : ""} ${(!event.isLive && isPast(new Date(event.end))) ? " is-past" : ""} ${event.isNotifiable ? " is-notifiable" : ""} ${event.is_destacado ? " destacado" : ""} ${(isCalendar || (!isCalendar && openedProfile && event.owner.id !== openedProfile.id)) ? ' is-calendar' : ' is-profile-calendar'}`} bgcolor="events.card">
            {(isCalendar || (!isCalendar && openedProfile && event.owner.id !== openedProfile.id)) && (
                <Tooltip title={event.owner.nickname} placement="bottom-start" arrow>
                    <img src={event.owner && event.owner.avatar ? event.owner.avatar : "/images/defaultAvatar.png"}
                         className={"avatar"} alt={event.owner.nickname}/>
                </Tooltip>
            )}
            {event.premium_display_poster && event.poster && event.poster !== "/images/defaultHeader2.png" && (
                <div className="poster-wrap">
                    {eventPoster.endsWith(".webp") ? (
                        <picture className={"poster"}>
                            <source srcSet={eventPoster}/>
                            <img src={event.original_poster} alt={event.title}/>
                        </picture>
                    ) : (
                        <img src={eventPoster} className={"poster"} alt={event.title}/>
                    )}
                </div>
            )}
            <Box className={`event-card-content ${(isCalendar || (!isCalendar && openedProfile && event.owner.id !== openedProfile.id)) ? "is-calendar" : " is-profile-calendar"}`} display="flex" flexDirection="column" justifyContent="center">
                <Tooltip title={event.title} placement="top" arrow>
                    <Box className="title-w-line-ellipsis">
                        <LinesEllipsis
                            text={event.title}
                            maxLine='2'
                            ellipsis='...'
                            trimRight
                            basedOn='words'
                        />
                    </Box>
                </Tooltip>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    {event.isLive && (
                        <Box className="is-live" display="flex" flexDirection="row" justifyContent="flex-start"
                             alignItems="center">
                            <span className={"is-live-circle"}/>
                            <span className={"is-live-label"}>{t('extraData:event_in_live')}</span>
                        </Box>
                    )}
                    {!event.isLive && (
                        <>
                            {!event.is_fullday && _isSameDay &&
                            <Fragment>
                                {new Date(event.start).getTime() !== new Date(event.end).getTime() &&
                                <span
                                    className="date">{`${formatDate(event.start, "p")} - ${formatDate(event.end, "p")}`}</span>
                                }
                                {new Date(event.start).getTime() === new Date(event.end).getTime() &&
                                <span className="date">{`${formatDate(event.start, "p")}`}</span>
                                }
                            </Fragment>
                            }
                            {!event.is_fullday && !_isSameDay &&
                            <Fragment>
                                {formatDate(event.start, 'yyyy-MM-dd') === formatPipeDate &&
                                <span
                                    className="date">{`${formatDate(event.start, "p")} - ${formatDate(event.end, "iii d, p")}`}</span>
                                }
                                {formatDate(event.end, 'yyyy-MM-dd') === formatPipeDate &&
                                <span
                                    className="date">{`${formatDate(event.start, "iii d, p")} - ${formatDate(event.end, "p")}`}</span>
                                }
                                {formatDate(event.start, 'yyyy-MM-dd') !== formatPipeDate && formatDate(event.end, 'yyyy-MM-dd') !== formatPipeDate &&
                                <span
                                    className="date">{`${formatDate(event.start, "iii d, p")} - ${formatDate(event.end, "iii d, p")}`}</span>
                                }
                            </Fragment>
                            }
                            {event.is_fullday && _isSameDay &&
                                <span className="date">{t('forms_label_allDay')}</span>
                            }
                            {event.is_fullday && !_isSameDay &&
                            <span
                                className="date"> {t('forms_label_allDay')} - {formatDate(event.end, "iii d, p")}</span>
                            }
                        </>
                    )}
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                        {event.comments > 0 && (
                            <Box className="chat" display="flex" flexDirection="row" justifyContent="flex-end"
                                 alignItems="center">
                                <span className="chat-count">{abbreviate(event.comments, 1)} </span>
                                <img src={event.comments > 0 ? ChatIcon : ChatIconGrey} alt="comments"
                                     className="chat-icon"/>
                            </Box>)}
                        {event.is_private && (
                            <Box className="is-private" display="flex" flexDirection="row" justifyContent="flex-end"
                                 alignItems="center">
                                <Tooltip title={`${t('eventForm:heading_private_event')}`} placement="right" arrow>
                                    <img src={privateEvent} alt={t('eventForm:heading_private_event')}
                                         className="icon"/>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    {event.isNotifiable && (
                        <Box className="is-notifiable" display="flex" flexDirection="row" justifyContent="flex-end"
                             alignItems="center">
                            <Tooltip title={t('extraData:event_placeholder_notifications_enabled')} placement="right"
                                     arrow>
                                <img src={BellIconOn} alt={t('extraData:event_placeholder_notifications_enabled')}
                                     className="noti-icon"/>
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            </Box>
            {event.hasOwnProperty('pings') && event.pings.length > 0 && isCalendar && event.hasOwnProperty('event_cache_view') && !event.event_cache_view.hasFollowerOwner && event.event_cache_view.hasFollowerPing && (
                <PingTag pings={event.pings}/>
            )}
            {event.hasOwnProperty('pings') && event.pings.length > 0 && openedProfile && event.pings.some(e => e.id === openedProfile.id) && (
                <PingTag pings={[
                    {
                        id: openedProfile.id,
                        nickname: openedProfile.nickname,
                        slug: openedProfile.slug,
                        avatar: openedProfile.avatar
                    },
                    ...Array(event.pings.length - 1).fill({id: 'undefined'})
                ]}/>
            )}
            {showGuestLayer(event)}
        </Box>
    )
}

export default EventCard;

