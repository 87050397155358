import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Countdown from "react-countdown";

const LandingHeaderCountdownComponent = ({userID}) => {
    const {t} = useTranslation('landing');

    const SVGCircle = ({radius}) => (
        <svg className='countdown-svg'>
            <path fill="none" stroke="#00D1BF" strokeWidth="3" d={describeArc(33, 33, 31, 0, radius)}/>
        </svg>
    );

    const describeArc = (x, y, radius, startAngle, endAngle) => {
        let start = polarToCartesian(x, y, radius, endAngle);
        let end = polarToCartesian(x, y, radius, startAngle);
        let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        let d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;
    }

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    const mapNumber = (number, in_min, in_max, out_min, out_max) => {
        return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    }

    const renderer_catdown = ({days, hours, minutes, seconds, completed}) => {
        if (completed) return null;
        const daysRadius = mapNumber(days, 30, 0, 0, 360);
        const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);
        return (
            <div className='countdown-wrapper'>
                {(days !== undefined && days > 0) && (
                    <div className='countdown-item'>
                        <SVGCircle radius={daysRadius}/>
                        {days}
                        <span>{t("days")}</span>
                    </div>
                )}
                {(hours || hours === 0) && (
                    <div className='countdown-item'>
                        <SVGCircle radius={hoursRadius}/>
                        {hours}
                        <span>{t("hours")}</span>
                    </div>
                )}
                {(minutes || minutes === 0) && (
                    <div className='countdown-item'>
                        <SVGCircle radius={minutesRadius}/>
                        {minutes}
                        <span>{t("minutes")}</span>
                    </div>
                )}
                {(seconds || seconds === 0) && (
                    <div className='countdown-item'>
                        <SVGCircle radius={secondsRadius}/>
                        {seconds}
                        <span>{t("seconds")}</span>
                    </div>
                )}
            </div>
        );
    };

    if (userID === '4e42510f-afd8-4f3b-b072-32ad4fc9cf19' && 1 === 2) {
        return (
            <div className="landing-header-counter-wrapper">
                <div className="landing-header-counter scroller">
                    <Countdown
                        date={new Date(2021, 7, 15)}
                        renderer={renderer_catdown}
                    />
                </div>
            </div>
        );
    }

    if (userID === '5b755b48-203a-40af-9934-30b4292b468b') {
        if (new Date(Date.UTC(2021, 8, 19, 19)) < new Date()) return null;

        return (
            <div className="landing-header-counter-wrapper">
                <div className="landing-header-counter scroller">
                    <div>
                        <h1>Masters Berlín {t("ends_in")}</h1>
                        <Countdown
                            date={new Date(Date.UTC(2021, 8, 19, 19))}
                            renderer={renderer_catdown}
                        />
                    </div>
                </div>
            </div>
        );
    }


    return null;
};

export default LandingHeaderCountdownComponent;
