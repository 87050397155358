import React from 'react';
import {useSelector} from "react-redux";
import {isSameDay, parseISO} from "date-fns";
import {formatDate, getDateWithoutTime} from "../../../helpers/dateFormatter";
import {Box, Tooltip} from "@material-ui/core";
import './event-line-sm.scss';
import {useTranslation} from "react-i18next";

const EventLineSmComponent = ({ event, onClick, nowDate }) => {
    const { t } = useTranslation(['eventForm']);
    const darkTheme = useSelector(state => state.darkTheme);
    const sameDate = isSameDay(nowDate, getDateWithoutTime(parseISO(event.start)));
    const openEventModalHandler = () => onClick(event);
    const formattedDate = event.hasOwnProperty("start")
        ? event.is_fullday ? `${formatDate(event.start, "iii. d")} - ${t('forms_label_allDay')}` : sameDate ? `Today ${formatDate(event.start, "HH:mm")}h` : formatDate(event.start, "iii. d - HH:mm")
        : "-";

    return (
        <Tooltip title={event.title} placement="bottom" arrow>
            <Box onClick={openEventModalHandler}
                 className={`event-line-sm-card animated fadeIn ${darkTheme ? '  is-dark-theme ' : ' is-light-theme'}
                 ${(event.hasOwnProperty('is_destacado') && event.is_destacado ? ' destacado ' : '')}`}>
                <div className="event-line-sm-title">
                    <span>{event.title}</span>
                </div>
                <div className="event-line-sm-info">
                    <span>{formattedDate}</span>
                </div>
            </Box>
        </Tooltip>
    );

};

export default EventLineSmComponent;
