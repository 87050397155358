import React from 'react';
import {Box, Tooltip} from "@material-ui/core";
import {useSelector} from "react-redux";
import {
    formatDate,
    getDateWithoutTime,
    ownFormatDistance,
    ownFormatDuration,
    ownTimeAgo
} from "../../../../../helpers/dateFormatter";
import './_item_styles.scss';
import {useTranslation} from "react-i18next";

const VlrItemComponent = ({event, onOpenEventModal, nowDate, type}) => {
    const {t} = useTranslation('extraData');
    const darkTheme = useSelector(state => state.darkTheme);

    const openEventModalHandler = () => onOpenEventModal(event);

    return (
        <Tooltip title={event.title} placement="bottom" arrow>
            <Box onClick={openEventModalHandler}
                 className={`vlr-landing-score-match animated fadeIn ${darkTheme ? '  is-dark-theme ' : ' is-light-theme'}
                 ${(event.hasOwnProperty('is_destacado') && event.is_destacado ? ' destacado ' : '')} is-${type}`}>

                <div className="event-score-heading">
                    {type !== 'upcoming' && (
                        <>
                            {event.original_poster && <img src={event.original_poster} alt={event.tournament}/>}
                            <div className="tournament--name">{event.tournament}</div>
                        </>
                    )}
                    {event.isLive && (
                        <div className="event-score--is-live">
                            <div className={"is-circle"}/>
                            <span className={"is-live-label"}>{t('extraData:event_in_live')}</span>
                        </div>
                    )}
                </div>

                <div className="event-score-body">
                    <div className="event-score--team">
                        <div className="event-score--team-image">
                            <img src={event.team_local.image} alt={event.team_local.name}/>
                        </div>
                        <div className="event-score--team-name">
                            {event.team_local.name}
                        </div>
                        {type === "upcoming" ? (
                            <div className="event-score--time-start">
                                {formatDate(event.start, "HH:mm")}h
                            </div>
                        ) : (
                            <>
                                <div className="event-score--global-maps">
                                    {event.team_local.scoreboard}
                                </div>
                                <div className="event-score--curr-map">
                                    {event.team_local.current_scoreboard}
                                </div>
                            </>
                        )}

                    </div>

                    <div className="event-score--team">
                        <div className="event-score--team-image">
                            <img src={event.team_guest.image} alt={event.team_guest.name}/>
                        </div>
                        <div className="event-score--team-name">
                            {event.team_guest.name}
                        </div>
                        {type === "upcoming" ? (
                            <div className="event-score--time-remaining">
                                {t('date_prefix_in')} {ownFormatDuration(new Date(event.start), ['days', 'hours', 'minutes'], false, "")
                                .replace("hours", "h")
                                .replace("hour", "h")
                                .replace('minutes', "m")
                                .replace("minute", "m")
                                .replace("horas", "h")
                                .replace("hora", "h")
                                .replace('minutos', "m")
                                .replace("minuto", "m")
                            }
                            </div>
                        ) : (
                            <>
                                <div className="event-score--global-maps">
                                    {event.team_guest.scoreboard}
                                </div>
                                <div className="event-score--curr-map">
                                    {event.team_guest.current_scoreboard}
                                </div>
                            </>)}
                    </div>
                </div>
            </Box>
        </Tooltip>
    );
};

export default VlrItemComponent;
