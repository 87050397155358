import React, {useEffect, useRef, useState} from "react";
import {Box, Button} from "@material-ui/core";
import {formatDate, getCurrentTimezone, ownEndOfDay, ownStartOfDay} from "../../helpers/dateFormatter";
import {isPast, isSameDay, isToday, parseISO} from 'date-fns'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {useTranslation} from "react-i18next";
import services from "../../services";
import ArrowDown from '../../assets/icons/actions/arrow_down.svg';
import EventCard from "../Event/EventCard/EventCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserCardSkeleton from "../Skeletons/UserCardSkeleton/UserCardSkeleton";
import {useSelector} from "react-redux";
import UserGroupedEventsCard from "../Event/UserGroupedEventsCard/UserGroupedEventsCard";
import PipelineRenderPastEvents from "./PipelineRenderPastEvents";

const NewPipelineItem = ({
                             executeCenterOnDay,
                             openQuickEvent,
                             openCatsModal,
                             OpenInfo,
                             calendar,
                             alwaysShowPing,
                             dateFormat,
                             deviceView,
                             item,
                             type,
                             ownerId,
                             categoryId,
                             groupId,
                             groupEvents,
                             emptyContentPlaceHolderView,
                             selectedDate,
                             hideLiveNowEvents,
                             nextEvents,
                             defaultLimit,
                             loadMoreEnabled,
                             loadMoreDisabledPlaceholder,
                             customServiceEndpoint
                         }) => {
    const {t} = useTranslation('eventForm');
    const refDay = useRef(null);
    const darkTheme = useSelector(state => state.darkTheme);
    const [skeleton, setSkeleton] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        data: [],
        limit: defaultLimit,
        skip: 0,
        total: []
    });

    const [pastEventsOpen, setPastEventsOpen] = useState(false);
    const [pastEvents, setPastEvents] = useState([]);
    const [skip, setSkip] = useState(0);
    const [objEventsGroup, setObjEventsGroup] = useState([]);

    useEffect(() => {
        let isCancelled = false;
        setTimeout(() => {
            if (!isCancelled) {
                setSkeleton(true);
                let query = {
                    start: formatDate(ownStartOfDay(item), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
                    end: formatDate(ownEndOfDay(item), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
                    timezone: getCurrentTimezone(),
                    $limit: data.limit
                }
                if (ownerId) {
                    query.ownerId = ownerId;
                }
                if (groupId) {
                    query.groupId = groupId;
                }
                if (categoryId !== false) {
                    query.categoryId = categoryId;
                }
                if (hideLiveNowEvents !== undefined && hideLiveNowEvents === true) {
                    query.isLive = false;
                }
                if (nextEvents) {
                    query.start = formatDate(item, "yyyy-MM-dd'T'HH:mm:ss.SSS")
                    query.nextEvents = true;
                }
                executeQuery(query, true, () => {
                    if (!isCancelled) {
                        setSkeleton(false);
                    }
                }, (err) => console.log('err', err), true)
            }
        }, 500);
        return () => {
            isCancelled = true;
        }
    }, [item, categoryId]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (executeCenterOnDay && refDay.current) {
                if (isSameDay(new Date(item), new Date())) {
                    let calc = refDay.current.offsetLeft - (refDay.current.offsetWidth / 2)
                    executeCenterOnDay(calc)
                }
            }
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [item, executeCenterOnDay, refDay]);

    const process_group_events = async (items) => {
        return await items.reduce((catMemo, item) => {
            (catMemo[item.owner.id] = catMemo[item.owner.id] || []).push(item);
            return catMemo;
        }, {});
    }

    useEffect(() => {
        let mounted = true;
        if (groupEvents) {
            let items = [...data.data, ...pastEvents];
            if (mounted) {
                process_group_events(items).then(r => {
                    if (mounted) {
                        setObjEventsGroup(r)
                    }
                });
            }
        }
        return () => mounted = false;
    }, [data, pastEvents, groupEvents]);

    const loadMore = () => {
        if (!loading) {
            setLoading(true);
            let query = {
                start: formatDate(ownStartOfDay(item), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
                end: formatDate(ownEndOfDay(item), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
                timezone: getCurrentTimezone(),
                $skip: skip + data.limit,
                $limit: data.limit
            }
            if (ownerId) {
                query.ownerId = ownerId;
            }
            if (groupId) {
                query.groupId = groupId;
            }
            if (hideLiveNowEvents !== undefined && hideLiveNowEvents === false) {
                query.isLive = false;
            }
            if (nextEvents) {
                query.start = formatDate(item, "yyyy-MM-dd'T'HH:mm:ss.SSS")
                query.nextEvents = true;
            }

            executeQuery(query, false, () => {
                setLoading(false);
            }, (error) => {
                console.log('error', error);
            })

        }
    };

    const executeQuery = (query, isFirstRequest, onComplete, onError, clear = false) => {

        const handleResponse = (res) => {
            setSkip(res.skip);
            let currentDay = res;
            let passedEvents = [];

            res.data = res.data.filter(ev => {
                if ((isPast(new Date(ev.end)) || (!isToday(item) && isPast(item))) && !ev.isLive) {
                    passedEvents.push(ev);
                    return false;
                } else {
                    return true;
                }
            })

            if (clear) {
                setData({
                    limit: res.limit,
                    skip: res.skip,
                    total: res.total,
                    data: res.data
                });
            } else {
                setData({
                    limit: res.limit,
                    skip: res.skip,
                    total: res.total,
                    data: [...data.data, ...currentDay.data]
                });
            }

            if (isFirstRequest) {
                if (type === "agenda") {
                    setPastEvents(passedEvents);
                } else {
                    setPastEvents(passedEvents.reverse());
                }

            } else {
                let attach = [...pastEvents, ...passedEvents];
                setPastEvents(attach);
            }
            onComplete();
        }


        if (customServiceEndpoint !== null && typeof customServiceEndpoint === 'function') {
            customServiceEndpoint(query).then(handleResponse).catch(onError);
        } else {
            services.events.find({
                query
            }).then(handleResponse).catch(onError);
        }
    }

    return (
        <Box ref={refDay} className="pipeline">
            {item && <Box
                className={'pipe-date ' + (isSameDay(parseISO(selectedDate), new Date(item)) ? ' current-date ' : ' ')}>{formatDate(item, dateFormat)}</Box>}
            <Box
                className={"pipe" + (isSameDay(new Date(item), new Date()) ? ' is-selected ' : ' ') + (darkTheme ? '  is-dark-theme ' : ' is-light-theme')}
                bgcolor="grid.content">
                {calendar &&
                <Box className="pipe-title-box" display="flex" flexDirection="column" justifyContent="center"
                     alignItems="center">
                    <Button className="add-event-btn"
                            onClick={(e) => openQuickEvent(e, item)}><AddCircleOutlineIcon/></Button>
                    <span className="add-event-text"> {t('heading_add_new_event')} </span>
                </Box>
                }
                <Box className="pipe-content">
                    {skeleton &&
                    Array.from({length: Math.floor(Math.random() * 10)}).map((_item, index) =>
                        <UserCardSkeleton key={`skeleton-${index}-${item.getTime()}`}/>
                    )}
                    {(groupEvents && type === "agenda") ? (
                        <>
                            {
                                Object.keys(objEventsGroup).map((key) =>
                                    <div key={`ps-group-${key}.${item.getTime()}`} className="event">
                                        <UserGroupedEventsCard events={objEventsGroup[key]} ownerId={key}
                                                               pipeDate={item} isCalendar={calendar}
                                                               alwaysShowPing={alwaysShowPing} onClickEvent={OpenInfo}/>
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        <>

                            <PipelineRenderPastEvents isOpenGroup={pastEventsOpen} handleOnOpenGroup={setPastEventsOpen}
                                                      events={pastEvents} alwaysShowPing={alwaysShowPing}
                                                      isCalendar={calendar} pipelineDate={item}
                                                      mainLength={data.data.length} handleOnClickEvent={OpenInfo}/>
                            {!skeleton && data.data.map((event, i2) =>
                                <div key={`${event.id}.${i2}.${item.getTime()}`} onClick={() => OpenInfo(event)}
                                     className="event">
                                    <EventCard pipeDate={item} isCalendar={calendar} alwaysShowPing={alwaysShowPing}
                                               event={event}/>
                                </div>
                            )}
                        </>
                    )}


                    {(loadMoreEnabled && ((skip + data.limit) < data.total.length)) &&
                    <div className="pipeline-load-more animated infinite flash slower" onClick={loadMore}>
                        {!loading && <img src={ArrowDown} alt="latency" className="arrow-down"/>}
                        {loading && <CircularProgress thickness={5} className="loader"/>}
                    </div>
                    }
                    {(!loadMoreEnabled && ((skip + data.limit) < data.total.length)) &&
                    loadMoreDisabledPlaceholder
                    }

                    {(!skeleton && data.data.length === 0 && pastEvents.length === 0 && emptyContentPlaceHolderView !== undefined) && emptyContentPlaceHolderView()}
                </Box>
            </Box>
        </Box>
    );
};

NewPipelineItem.defaultProps = {
    hideLiveNowEvents: false,
    nextEvents: false,
    defaultLimit: 40,
    loadMoreEnabled: true
}

export default NewPipelineItem;
