import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './fragments/_landing_custom_tab.scss';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Modal from "../../../../Modal/Modal";
import LandingCustomTabForm from "./fragments/LandingCustomTabForm";
import services from "../../../../../services";
import InjectValorantChampionSpecial from "./InjectValorantChampionSpecial";

const HtmlToReactParser = require('html-to-react').Parser

const htmlToReactParser = new HtmlToReactParser()

const LandingCustomTabComponent = ({userId, handleRefreshTabs, changeTab, onOpenPlayer, customTab, allowRemove, onClickDeleteButton}) => {
        const [customTabOpened, setCustomTabOpened] = useState(customTab);
        const {t} = useTranslation(['settings']);
        const deviceView = useSelector(state => state.deviceView);
        const [openEditDialog, setOpenEditDialog] = useState(false);
        const [showDialog, setShowDialog] = useState(false);
        const darkTheme = useSelector(state => state.darkTheme);
        const [reactBodyElement, setReactBodyElement] = useState(htmlToReactParser.parse(customTab.hasOwnProperty('text') ? customTab.text.replace(/\n/g, "")
            .replace(/[\t ]+\</g, "<")
            .replace(/\>[\t ]+\</g, "><")
            .replace(/\>[\t ]+$/g, ">") : ''))
        useEffect(() => {
                setCustomTabOpened(customTab);
                setReactBodyElement(htmlToReactParser.parse(customTab.hasOwnProperty('text') ? customTab.text.replace(/\n/g, "")
                    .replace(/[\t ]+\</g, "<")
                    .replace(/\>[\t ]+\</g, "><")
                    .replace(/\>[\t ]+$/g, ">") : ''))
        }, [customTab])

        const handleEdit = () => {
                setOpenEditDialog(true)
        }

        const onSubmitEdit = (data) => {
                services.landingFeed.patch(customTabOpened.id, {...data, roomId: customTabOpened.roomId}).then(res => {
                        setReactBodyElement(htmlToReactParser.parse(res.text))
                        setOpenEditDialog(false);
                        handleRefreshTabs();
                }).catch(err => {
                        console.log(err)
                })
        }

        return (
            <div className={`feed-article-item ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
                {allowRemove &&
                        <div style={{textAlign: "right"}}>
                            <Button color="secondary" onClick={() => handleEdit(customTabOpened.id)}><EditIcon/></Button>
                            <Button color="secondary" onClick={() => setShowDialog(true)}><DeleteIcon/></Button>
                        </div>
                }
                    {customTabOpened.id === "c9df70b8-2222-4e5f-a76e-0a0d681904aa" && (
                        <InjectValorantChampionSpecial userId={userId} changeTab={changeTab} onOpenPlayer={onOpenPlayer} />
                    )}
                <div className={`feed-article-item-article ${darkTheme ? " is-dark-theme" : " is-light-theme"}  tinymce`}>
                    {reactBodyElement}
                </div>
                {allowRemove && (
                    <>
                        <Dialog
                            open={showDialog}
                            onClose={() => setShowDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title">Atención</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description"
                                                   className={"dialog-description " + (darkTheme ? ' is-dark' : ' is-light')}>
                                    Estás a punto de borrar una página de forma permanente, estás seguro de continuar?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setShowDialog(false)} color="primary">
                                    {t('advanced_deactivate_account_dialog_erase_event_cancel_btn')}
                                </Button>
                                <Button onClick={() => {
                                    onClickDeleteButton(customTabOpened.id);
                                    setShowDialog(false);
                                }} color="primary" autoFocus>
                                    Sí, quiero continuar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Modal
                            state={openEditDialog}
                            max={"md"}
                            radius={true}
                            fullScreen={deviceView === "mobile"}
                            onClose={() => setOpenEditDialog(false)}>
                            <LandingCustomTabForm
                                defaultData={customTabOpened}
                                onSubmit={onSubmitEdit}
                                onClose={() => setOpenEditDialog(false)}/>
                        </Modal>
                    </>
                )}
            </div>
        );
    }
;

export default LandingCustomTabComponent;
