import React, {useEffect, useState} from 'react'
import {Box, Tooltip} from '@material-ui/core';
import CustomLink from '../../CustomLink/CustomLink';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';

const PingTag = (props) => {
    const {pings} = props;
    const darkTheme = useSelector(state => state.darkTheme);
    const {t} = useTranslation('extraData');

    const [tooltiptText, setTooltipText] = useState("");

    useEffect(() => {
        setTooltipText(generateText());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pings]);

    const generateText = () => {
        let startWith = pings[0].nickname + " ";
        if(pings.length > 1) {
            startWith += `${t('label_ping_and')} ${pings.length - 1}+ ${t('pingtag_label')}`;
        }else if(pings.length === 1){
            startWith += t('pingtag_label');
        }else{
            return null;
        }
        return startWith;
    };

    return (
        <Tooltip title={tooltiptText} placement="bottom-start" arrow>
            <Box className={"ping-tag " + (darkTheme ? 'dark' : 'light')} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                <CustomLink onClick={(e) => e.stopPropagation()} className={"ping-user"} to={`/${pings[0].slug}`}> {pings[0].nickname} </CustomLink>
                {pings.length > 1 && <span> {t('label_ping_and')} {pings.length - 1}+ {t('pingtag_plural_label')}</span>}
                {pings.length === 1 && <span> {t('pingtag_label')} </span>}
            </Box>
        </Tooltip>
    )
}

export default PingTag;
