import React from 'react';
import { parse, isToday } from 'date-fns'
import CalendarIcon from '@material-ui/icons/CalendarToday';

import {formatDate} from "../../../../../helpers/dateFormatter";
import VlrItemComponent from "./VlrItemComponent";
import './_group_styles.scss';
import {useTranslation} from "react-i18next";

const VlrGroupItemComponent = ({ dateString, events, onOpenEventModal, nowDate, type }) => {
    const { t } = useTranslation('extraData');
    const dateFormatted = parse(dateString, 'yyyyMMdd', new Date());

    return (
        <div className="vlr-landing-score-group">
            <div className="vlr-landing-score-group--heading">
                <CalendarIcon fontSize="small" className="icon" />
                {isToday(dateFormatted) ? t('today') : formatDate(dateFormatted, "iii. d")}
            </div>
            <div className="vlr-landing-score-group--matches">
                {events.map(ev => <VlrItemComponent key={ev.id} event={ev} onOpenEventModal={onOpenEventModal}
                                                          nowDate={nowDate} type={type} />)}
            </div>
        </div>
    );
};

export default VlrGroupItemComponent;
