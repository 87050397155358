import {addDays, subDays, isSameDay, startOfDay, endOfDay, parseISO} from 'date-fns';
import {deviceType} from 'react-device-detect';
import { store } from '../';
import {formatDate, getCurrentTimezone, ownEndOfWeek, ownStartOfWeek} from '../../helpers/dateFormatter';

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"

export const setOpenedProfileWeeklyEvents = (date, id, sameWeek=false) => {
    return dispatch => {
        const state = store.getState();
        let extra_params = {};
        if(state.openedProfileFilterCategory !== false) {
            //extra_params.filter_categories = state.openedProfileFilterCategory;
            extra_params.categoryId = state.openedProfileFilterCategory;
        }

        let deviceView = state.deviceView;



        if(!sameWeek || deviceView !== 'browser') {
            dispatch(setOpenedProfileWeeklyEventsLoading())
        }
        let params = prepareParams(deviceView ? deviceView : deviceType, date)
        if(id){params.query.ownerId = id}
        if(extra_params != null){params.query = {...params.query, ...extra_params}}
        dispatch({
            type: 'UNSET_OPENED_PROFILE_WEEKLY_SELECTED'
        })
        dispatch({
            type: 'SET_OPENED_PROFILE_WEEKLY_SELECTED',
            payload: params.query
        });
        /*
        services.calendar.find(params)
        .then((resp) => {
            dispatch({
                type: 'SET_OPENED_PROFILE_WEEKLY_EVENTS',
                payload: prepareWeeklyData(date, resp.data)
            })
        })
        .catch((error) => {
            console.log(error)
        })
         */
    }
}

export const unsetOpenedProfileWeeklyEvents = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE_WEEKLY_EVENTS'})
    }
}

export const setOpenedProfileWeeklyEventsLoading = () => {
    return dispatch => {
        dispatch({type: 'SET_OPENED_PROFILE_WEEKLY_EVENTS_LOADING'})
    }
}

// helper funtions are written down here
const prepareWeeklyData = (date, data) => {
    date = new Date(date)
    let week = [];
    for(let i=0; i<data.length; i++){
        let objDate = parseISO(data[i].day);
        week.push({
            day: data[i].day,
            date: objDate,
            current:  isSameDay(objDate, date),
            events: data[i].events
        });
    }
    return week;
}

const prepareParams = (type, date) => {
    let dateObj = new Date(date);
    switch(type){
        case "browser":
            return {
                query: {
                    start: formatDate(ownStartOfWeek(dateObj), dateFormat),
                    end: formatDate(ownEndOfWeek(dateObj), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false
                }
            }
        case "mobile":
            return {
                query: {
                    start: formatDate(startOfDay(dateObj), dateFormat),
                    end: formatDate(endOfDay(dateObj), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        case "tablet":
            return {
                query: {
                    start: formatDate(subDays(dateObj, 1), dateFormat),
                    end: formatDate(addDays(dateObj, 1), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
        default:
            return {
                query: {
                    start: formatDate(ownStartOfWeek(dateObj), dateFormat),
                    end: formatDate(ownEndOfWeek(dateObj), dateFormat),
                    timezone: getCurrentTimezone(),
                    bundle: true,
                    paginate: false,
                }
            }
    }
}

