export const setLandingFeedClips = (roomId, data) => {
    return dispatch => {
        dispatch({
            type: 'SET_LANDING_FEED_CLIPS',
            payload: {
                roomId: roomId,
                messages: data
            }
        })
    }
};

export const addLandingFeedClip = (data) => {
    return dispatch => {
        dispatch({
            type: 'ADD_LANDING_FEED_CLIP',
            payload: {
                roomId: data.roomId,
                message: data
            }
        })
    }
};

export const editLandingFeedClip = (roomId, messageId, data) => {
    return dispatch => {
        dispatch({
            type: 'EDIT_LANDING_FEED_CLIP',
            payload: {
                roomId: roomId,
                messageId: messageId,
                message: data
            }
        })
    }
};

export const removeLandingFeedClip = (roomId, messageId) => {
    return dispatch => {
        dispatch({
            type: 'REMOVE_LANDING_FEED_CLIP',
            payload: {
                roomId: roomId,
                messageId: messageId,
            }
        })
    }
};
