import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import services from '../../../../services';
import { useTranslation } from 'react-i18next';
import { MentionsInput, Mention } from 'react-mentions'
import Picker from 'emoji-picker-react';
import { showSnackbar } from "../../../../store/snackbar/actions";
import Portal from "@material-ui/core/Portal";
import SendIcon from '@material-ui/icons/Send';
import IconButton from "@material-ui/core/IconButton";

const ChatInputForm = (props) => {
  const dispatch = useDispatch();
  const { user, event } = props;
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const darkTheme = useSelector(state => state.darkTheme);
  const { t } = useTranslation('extraData');
  const elSuggestionsRef = useRef(null);
  const timer = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const container = React.useRef(null);

    const onEmojiClick = (event, emojiObject) => {
        setText(text + emojiObject.emoji)
        setShowEmojis(false);
    };

  useEffect(() => {
      return () => {
          if(timer.current) {
              clearTimeout(timer.current)
          }
      }
  }, [])

  const handleSubmit = () => {
    if (user && user.hasOwnProperty('id')) {
      const params = { text: text };
      if (text) {
        if (text.length > 255) {
          dispatch(showSnackbar({ message: t("extraData:input_max_length_255"), type: "error" }))
        } else {
            if(text.trim().length > 0) {
                props.onSubmit(params);
                setText("");
            }
        }
      }
    } else {
      dispatch(showSnackbar({ message: "you need to login in order to perfom this action", type: "error" }))
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
      handleSubmit()
    }
  };

  const queryMentions = (query, callback) => {
    if (!query) return;
      if(timer.current) clearTimeout(timer.current);
      setLoading(true);
      timer.current = setTimeout(() => doQueryMentions(query, callback), 500);
  };

  const doQueryMentions = (query, callback) => {
      services.search.find({
          query: {
              text: query,
              users: true
          }
      })
          .then((res) => {
              setLoading(false);
              return res.map(user => ({
                  id: user.slug,
                  display: `${user.nickname} (@${user.slug})`,
              }))
          } ).then(callback)
  }

  return (
    <>
      <Box bgcolor="grid.main" display="flex" flexDirection="row" justifyContent="flex-start" className="chat-input-form" ref={container}>
        <Box style={{ flex: 1, maxWidth: '85%', paddingRight: 0.5 }} display="flex" flexDirection="row"
             alignItems="center" justifyContent="flex-start">
          <MentionsInput
              singleLine={false}
            className={"input " + (darkTheme ? ' isDark ' : ' isLight ')}
            value={text}
              onKeyDown={handleKeyDown}
            onChange={(e) => setText(e.target.value)}
            placeholder={t('placeholder_send_message')}
            suggestionsPortalHost={elSuggestionsRef ? elSuggestionsRef.current : null}
          >
            <Mention
              displayTransform={username => `@${username}`}
              trigger="@"
              isLoading={loading}
              data={queryMentions}
              appendSpaceOnAdd
              style={(darkTheme ? {
                borderBottomColor: 'white',
                borderBottomWidth: 1.0,
                color: 'white',
                fontWeight: 'bold'
              } : {
                borderBottomColor: 'black',
                borderBottomWidth: 1.0,
                color: 'black',
                fontWeight: 'bold'
              })}
            />
          </MentionsInput>
        </Box>
          <Box color="text.main" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <IconButton id="emoji_button" onClick={() => setShowEmojis(!showEmojis)}>
                  {String.fromCodePoint(0x1f60a)}
              </IconButton>
              <IconButton id="send_button" aria-label="Send button" color={"primary"} onClick={handleSubmit}> <SendIcon/> </IconButton>
          </Box>
      </Box>
      <div className={`refElSuggestions ${(darkTheme ? ' is-dark ' : ' is-light ')}`} ref={elSuggestionsRef}></div>
        {showEmojis ? (
                <div className={"absolute-emoji-picker"}>
                    <Picker disableSkinTonePicker onEmojiClick={onEmojiClick} />
                </div>
        ) : null}
    </>
  )
}

export default ChatInputForm;
