import React, {useEffect, useMemo} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Agenda from './pages/Agenda/Agenda';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import CookiesPolicy from './pages/CookiesPolicy/CookiesPolicy';
import Terms from './pages/Terms/Terms';
import Contact from './pages/Contact/Contact';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Profile from './pages/Profile/Profile';
import NotFound from './pages/404NotFound/404NotFound';
import Navbar from './components/Navbar/Navbar';
import {Grid} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {reAuthenticate} from './store/user/actions';
import CssBaseline from "@material-ui/core/CssBaseline";
import {createTheme} from "@material-ui/core/styles";
import {ThemeProvider} from '@material-ui/core/styles';
import themeData from './helpers/themeData';
import {openEventInfoModal, closeEventInfoModal} from './store/eventInfoModal/actions';
import Verify from "./pages/Verify/Verify";
import EditProfile from './pages/EditProfile/EditProfile';
import {closeAddEventModal} from './store/addEventModal/actions';
import CookitesTerms from './components/CookiesTerms/CookiesTerms';
import {unsetSelectedGroup} from './store/selectedGroup/actions';
import {unsetAgendaFilter} from './store/agendafFilter/actions';
import {setOpenedEvent} from './store/openedEvent/actions';
import {setDeviceView} from './store/deviceView/actions';
import {addNewNotification, unsetNotifications} from './store/notifications/actions';
import {setOpenedEventMessages} from "./store/openedEventMessages/actions";
import Footer from "./components/Footer/Foot";
import Settings from "./pages/Settings/Settings";
import Loader from "./components/Loader/Loader";
import LegalNotice from "./pages/LegalNotice/LegalNotice";
import SupervisorComponent from "./components/Supervisor/SupervisorComponent";
import services from "./services";
import {showSnackbar} from "./store/snackbar/actions";
import {useTranslation} from "react-i18next";
import {closeAddAccountModal} from "./store/addNewAccountModal/actions";
import {closeManageMultiAccountModal} from "./store/manageMultiAccountModal/actions";
import {addNewInvitation, unsetInvitations} from "./store/invitations/actions";
import LandingMain from "./components/special/LandingMain/LandingMain";
import MobileSearchComponent from "./components/MobileSearchComponent/MobileSearchComponent";
import {Event, initGA} from "./services/tracking";
import DeepLinkMobile from "./pages/Redirects/DeeplinkMobile";
import {setEventCategories} from "./store/eventCategories/actions";
import NewExplorePage from "./pages/NewExplore/NewExplorePage";
import DiscoverCategory from "./pages/DiscoverCategory/DiscoverCategory";
import NewEmbedPage from "./pages/Embed/NewEmbedPage";
import {LandingValorant} from "./components/special/LandingValorant/LandingValorant";
import CustomAnalytics from "./CustomAnalytics";
import {EmbedValorant} from "./components/special/EmbedValorant/EmbedValorant";

const trackingId = "G-R0KX97JCKM";

initGA(trackingId);

const PrivateRoute = ({component: Component, loggedIn, onParams, ...rest}) => (
    <Route {...rest} render={(props) => (
        loggedIn ? <Component {...props} onParams={onParams}/> : <Redirect to='/login'/>
    )}/>
)

const SessionRoute = ({component: Component, loggedIn, ...rest}) => (
    <Route {...rest} render={(props) => (
        loggedIn ? <Redirect to='/agenda'/> : <Component {...props} />
    )}/>
)

const App = () => {
    const {t} = useTranslation(['notifications']);
    const loggedIn = useSelector(state => state.loggedIn);
    const loader = useSelector(state => state.loader);
    const dispatch = useDispatch()
    const darkTheme = useSelector(state => state.darkTheme);
    const cookiesTerms = useSelector(state => state.cookiesTerms);
    const deviceView = useSelector(state => state.deviceView);
    const theme = useMemo(() =>
        createTheme(themeData(darkTheme)), [darkTheme]
    );
    const settingsExperimental = useSelector(state => state.settings.general.experimental);

    useEffect(() => {
        dispatch(reAuthenticate())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn])

    useEffect(() => {
        checkScreen();
        window.addEventListener("resize", (ev) => {
            ev.preventDefault();
            checkScreen()
        });
        dispatch(closeEventInfoModal());
        dispatch(setEventCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            dispatch(closeEventInfoModal());
            dispatch(closeAddEventModal());
            dispatch(unsetSelectedGroup());
            dispatch(unsetAgendaFilter());
            dispatch(unsetNotifications());
            dispatch(unsetInvitations());
            dispatch(closeAddAccountModal());
            dispatch(closeManageMultiAccountModal());
        }
    })

    useEffect(() => {
        services.notifications.on('created', data => {
            dispatch(showSnackbar({message: t("notifications:snackbar_new_notification"), type: "success"}));
            if (data.notificationObject.notificationChange.actionOnEntity === "invitation") {
                dispatch(addNewInvitation(data.id));
            } else {
                dispatch(addNewNotification(data.id));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkScreen = () => {
        if (document.documentElement.clientWidth <= 600) {
            dispatch(setDeviceView("mobile"))
        } else if ((document.documentElement.clientWidth > 600) && (document.documentElement.clientWidth <= 1080)) {
            dispatch(setDeviceView("tablet"))
        } else if (document.documentElement.clientWidth > 1080) {
            dispatch(setDeviceView("browser"))
        }
    }

    const onParams = (params) => {
        if (params.userID && params.eventID) {
            dispatch(setOpenedEventMessages(params.eventID))
            dispatch(setOpenedEvent(params.eventID))
            dispatch(openEventInfoModal())
            Event("App", "OpenEvent", params.eventID);
        } else {
            dispatch(closeEventInfoModal())
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                <CustomAnalytics />
                    <SupervisorComponent/>
                    {loader.loadingSpinner && <Loader timeoutError={true}/>}
                    {!loader.loadingSpinner && (
                        <Grid container className={`app ${settingsExperimental ? ' is-experimental-mode' : ''}`} direction="column" justifyContent={"flex-start"} alignItems={"flex-start"}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="navbar-container">
                                <Navbar/>
                            </Grid>
                            {deviceView === "mobile" && <MobileSearchComponent/>}
                            <Grid item xs={12} sm={12} md={12} lg={12} className={`app-content ${darkTheme ? ' is-dark' : ' is-light'}`}>
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect push to="/explore"/>
                                    </Route>
                                    <Route exact path="/mobile-oauth">
                                        <DeepLinkMobile oauth={true} />
                                    </Route>
                                    <Route exact path="/mobile">
                                        <DeepLinkMobile />
                                    </Route>
                                    <Route exact path="/cookies-policy" component={CookiesPolicy}/>
                                    <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                                    <Route exact path="/terms" component={Terms}/>
                                    <Route exact path="/legal-notice" component={LegalNotice}/>
                                    <Route exact path="/contact" component={Contact}/>
                                    <Route path="/explore" component={NewExplorePage}/>
                                    <SessionRoute exact path="/login" component={Login} loggedIn={loggedIn}/>
                                    <SessionRoute exact path="/register" component={Register} loggedIn={loggedIn}/>
                                    <Route
                                        exact
                                        path="/calendar/:userNickname/:eventID?"
                                        render={props => (
                                            <Redirect push
                                                      to={`/${props.match.params.userNickname}/${props.match.params.hasOwnProperty('eventID') ? props.match.params.eventID : ''}`}/>
                                        )}
                                    />
                                    <PrivateRoute exact path="/edit/profile" component={EditProfile}
                                                  loggedIn={loggedIn}/>
                                    <PrivateRoute exact path="/edit/settings" component={Settings} loggedIn={loggedIn}/>
                                    <PrivateRoute exact path="/agenda" component={Agenda} onParams={onParams}
                                                  loggedIn={loggedIn}/>
                                    <Route exact path="/(category|categoria)/:primary/:secondary?"
                                           render={() => <DiscoverCategory />}/>
                                    {/*
                                    <Route exact path="/embed/:userNickname/:eventID?"
                                           render={() => <Embed onParams={onParams}/>}/>
                                     */}
                                    <Route exact path="/embed/valorant"
                                           render={() => <EmbedValorant onParams={onParams}/>}/>
                                    <Route exact path="/embed/:userNickname/:eventID?"
                                           render={() => <NewEmbedPage onParams={onParams}/>}/>
                                    <Route exact path="/verify" component={Verify}/>
                                    <Route exact path="/not-found" component={NotFound} status={404}/>
                                    <Route exact path="/giants.crew.by.latency/live"
                                           render={() => <LandingMain onParams={onParams}/>}/>
                                    <Route exact path="/valorant"
                                           render={() => <LandingMain onParams={onParams}/>}/>
                                    <Route exact path="/valorant2"
                                           render={() => <LandingValorant onParams={onParams}/>}/>

                                    <Route exact path="/valorant.espana/live"
                                           render={() => <LandingMain onParams={onParams}/>}/>
                                    <Route exact path="/tortillaland/live"
                                           render={() => <LandingMain onParams={onParams}/>}/>
                                    <Route exact path="/espacio.crypto/live"
                                           render={() => <LandingMain onParams={onParams}/>}/>
                                    <Route exact path="/:userNickname/live">
                                        <Redirect push to="/explore"/>
                                    </Route>
                                    <Route exact path="/valorant.oficial">
                                        <Redirect push to="/valorant"/>
                                    </Route>
                                    <Route exact path="/valorant.espana">
                                        <Redirect push to="/valorant"/>
                                    </Route>
                                    <Route exact path="/:userNickname/:tabType(agenda|logros|achievements|info|categories|categorias)"
                                           render={() => <Profile onParams={onParams}/>}/>
                                    <Route exact path="/:userNickname/(category|categoria)/:primary/:secondary?"
                                           render={() => <Profile filterByCategory={true} onParams={onParams}/>}/>
                                    <Route exact path="/:userNickname/:tabType(logros|achievements)/:achievementListSlug"
                                           render={() => <Profile onParams={onParams}/>}/>
                                    <Route exact path="/:userNickname/:eventID?"
                                           render={() => <Profile onParams={onParams}/>}/>
                                </Switch>
                                <Footer/>
                            </Grid>
                        </Grid>
                    )}
                    {!cookiesTerms && <CookitesTerms/>}
            </Router>
        </ThemeProvider>
    )
}

export default App;
