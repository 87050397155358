export const setSelectedDate = (data) => {
    return dispatch => {
        dispatch({type: 'SET_SELECTED_DATE', payload: data})
    }
}

export const unsetSelectedDate = () => {
    return dispatch => {
        dispatch({type: 'UNSET_SELECTED_DATE'})
    }
}
