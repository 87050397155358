import React from 'react';
import Box from "@material-ui/core/Box";
import './MultiAccountItem.scss';

const MultiAccountItem = ({account}) => {
  return (
    <Box className="multi-account-item" component="span">
      <img src={account.avatar ? account.avatar : "/images/defaultAvatar.png"}
           className={"avatar"} alt={account.name}/>
           <span className={"nickname"}>{account.name}</span>
    </Box>
  );
};

export default MultiAccountItem;
