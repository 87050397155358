import React from 'react';
import { Box } from '@material-ui/core';
import ball from '../../../assets/icons/interests-icons/basketball-icon.svg';
import book from '../../../assets/icons/interests-icons/book-icon.svg';
import fashion from '../../../assets/icons/interests-icons/fashion-icon.svg';
import gamepad from '../../../assets/icons/interests-icons/gamepad-icon.svg';
import music from '../../../assets/icons/interests-icons/music-icon.svg';
import tv from '../../../assets/icons/interests-icons/tv-icon.svg';
import ballColored from '../../../assets/icons/interests-icons/basketball-icon-colored.svg';
import bookColored from '../../../assets/icons/interests-icons/book-icon-colored.svg';
import fashionColored from '../../../assets/icons/interests-icons/fashion-icon-colored.svg';
import gamepadColored from '../../../assets/icons/interests-icons/gamepad-icon-colored.svg';
import musicColored from '../../../assets/icons/interests-icons/music-icon-colored.svg';
import tvColored from '../../../assets/icons/interests-icons/tv-icon-colored.svg';
import i18n from '../../../helpers/internationalization';

const ChipData = (props) => {
    const {data, parent, darkTheme} = props

    const getName = (item) => {
        const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || 'en-US';
        if(item.translations.length){
            let data = item.translations.find(dd => dd.language.code === getCurrentLng().split("-")[0])
            return data.translation
        } else {
            return item.text
        }
    }

    return (
        <Box className="children-chips" p={1}>
            <Box mb={2} className="parent-chip" display="flex" flexDirection="column" justifyContent="center" alignItems="center" onClick={() => props.addToList(parent)}>
                {parent.icon === "fashion" && <img className="interest-icon" src={parent.userChecked ? fashionColored : fashion} alt="latency" />}
                {parent.icon === "gamepad" && <img className="interest-icon" src={parent.userChecked ? gamepadColored : gamepad} alt="latency" />}
                {parent.icon === "music" && <img className="interest-icon" src={parent.userChecked ?  musicColored : music} alt="latency" />}
                {parent.icon === "tv" && <img className="interest-icon" src={parent.userChecked ?  tvColored : tv} alt="latency" />}
                {parent.icon === "basketball" && <img className="interest-icon" src={parent.userChecked ?  ballColored : ball} alt="latency" />}
                {parent.icon === "culture" && <img className="interest-icon" src={parent.userChecked ?  bookColored : book} alt="latency" />}
                {parent.icon !== "culture" &&
                    parent.icon !== "fashion" &&
                        parent.icon !== "music" &&
                            parent.icon !== "basketball" &&
                                parent.icon !== "tv" &&
                                    parent.icon !== "gamepad" && <img className="interest-icon" src={parent.userChecked ?  bookColored : book} alt="latency" />
                }

                <span className="subtitle">{getName(parent)}</span>
            </Box>
            {data.map((item, i) =>
                <span key={i} onClick = {(()=> {props.addToList(item)})} className={"chip " + (darkTheme ? ' chip-dark ' : ' chip-light ') + (item.userChecked ? "checked" : " ")}> {getName(item)} </span>
            )}
        </Box>
    )
}

export default ChipData;
