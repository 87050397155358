const initialState = {};

const eventCategoriesReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_EVENT_CATEGORIES':
            return state = action.payload;
        case 'UNSET_EVENT_CATEGORIES':
            return state = initialState
        default:
            return state
    }
}

export default eventCategoriesReducer;
