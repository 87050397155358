import services from '../../services';

export const setOpenedEventMessages = (id) => {
    return dispatch => {
        if(id){
            const params = {query: {eventId: id, $limit: 100, $sort: { createdAt: -1 }}}
            services.messages.find(params)
                .then((resp) => {
                    dispatch({
                        type: 'SET_OPENED_EVENT_MESSAGES',
                        payload: resp.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(unsetOpenedEventMessages())
                })
        }
    }
}

export const unsetOpenedEventMessages = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_EVENT_MESSAGES'})
    }
}

export const toggleEventMessageLike = (messageId, userId) => {
    return (dispatch, getState) => {
        if(messageId) {
            services.messageLike.create({
                messageId: messageId,
                ownerId: userId
            }).then((res) => {
                let messages = getState().messages;
                let messageIndex = messages.findIndex(el => el.id === messageId);
                let message = messages[messageIndex]; //messages.find(el => el.id === messageId)
                if(message) {
                    if(res.deleted) {
                        message.likes = parseInt(message.likes)-1;
                        message.isLiked = false;
                    }else{
                        message.likes = parseInt(message.likes)+1;
                        message.isLiked = true;
                    }
                    messages[messageIndex] = message;
                }
                dispatch({
                    type: 'UPDATE_OPENED_EVENT_MESSAGES',
                    payload: messages
                })
            });
        }
    }
}

export const onRemoveEventMessage = (messageId, onSuccess) => {
    return (dispatch, getState) => {
        if(messageId) {
            services.messages.remove(messageId).then((res) => {
                if(onSuccess) {
                    let messages = getState().messages;
                    dispatch({
                        type: 'UPDATE_OPENED_EVENT_MESSAGES',
                        payload: messages.filter(el => el.id !== messageId)
                    });

                    onSuccess()
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    }
}

export const onEditEventMessage = (messageId, newMessageText, onSuccess) => {
  return (dispatch, getState) => {
      if(messageId) {
          services.messages.patch(messageId, {
              text: newMessageText
          }).then((res) => {
              let messages = getState().messages;
              let messageIndex = messages.findIndex(el => el.id === messageId);
              let message = messages[messageIndex]; //messages.find(el => el.id === messageId)
              if(message) {
                  message.text = newMessageText;
                  messages[messageIndex] = message;
              }
              dispatch({
                  type: 'UPDATE_OPENED_EVENT_MESSAGES',
                  payload: messages
              })
              if(onSuccess) {
                  onSuccess();
              }
          })
      }
  }
};
