import React from 'react';
import './legalnotice.scss';
import {Box} from '@material-ui/core';
import {Helmet} from "react-helmet";

const LegalNoticeES = () => {

    return (
        <Box className="legalnotice-page">
            <Helmet>
                <title>Aviso legal / Latency</title>
            </Helmet>
            <div className="static-content">
                <h2>Aviso legal</h2>
                <p>En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, le informamos que el Sitio Web y el portal de Internet https://latencyapp.com (en adelante, el "Web") es titularidad de LATENCY E-SPORTS SL, con domicilio social en Plaza de España 10, 1ºB, Las Rozas de Madrid, Madrid, Código postal 28231 con CIF nº B88053871. El presente aviso legal regula las condiciones de uso del citado portal de Internet.</p>

                <h2>Ley aplicable y jurisdicción</h2>
                <p>Con carácter general las relaciones entre LATENCY y los usuarios de sus servicios telemáticos, presentes en la web, se encuentran sometidas a la legislación y jurisdicción españolas.</p>
                <p>Las partes renuncian expresamente al fuero que les pudiera corresponder y someten expresamente a los Juzgados y Tribunales de Las Rozas de Madrid para resolver cualquier controversia que pueda surgir en la interpretación o ejecución de las presentes condiciones contractuales.</p>

                <h2>Aceptación del usuario</h2>
                <p>Este Aviso Legal regula el acceso y utilización de la página web que LATENCY pone a la disposición de los usuarios de Internet. Se considera usuario la persona que acceda, navegue, utilice o participe en los servicios y actividades de la página web.</p>
                <p>El usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con LATENCY.</p>
                <p>El acceso y navegación en el sitio web por parte del usuario supone la aceptación de la totalidad de las presentes Condiciones de Uso. En caso de desacuerdo con las condiciones debe abstenerse a usar el sitio web.</p>

                <h2>Acceso a la web</h2>
                <p>El acceso a la página web por parte de los usuarios es de carácter libre y gratuito. Algunos servicios pueden encontrarse sujetos a contratación previa del servicio.</p>
                <p>En caso de ser necesario que el usuario aporte datos personales para acceder a alguno de los servicios, la recogida y el tratamiento de los datos se realizará de conformidad con la normativa vigente, en concreto con el RGPD. Para más información, consulte nuestra política de privacidad <a href="https://latencyapp.com/">https://latencyapp.com</a>.</p>

                <h2>Contenido y uso</h2>
                <p>La visita al sitio web por parte del usuario deberá hacerse de forma responsable y de conformidad a la legalidad vigente, la buena fe, el presente Aviso Legal y respetando los derechos de propiedad intelectual e industrial de titularidad de LATENCY o cualquier otras personas físicas o jurídicas.</p>
                <p>El uso de cualquiera de los contenidos del sitio web con finalidades que sean o pudieran ser ilícitas queda totalmente prohibido, así como la realización de cualquier acción que cause o pueda causar daños o alteraciones de cualquier tipo no consentidas por LATENCY, al sitio web o a sus contenidos.</p>
                <p>El titular del web no se identifica con las opiniones vertidas en el mismo por sus colaboradores. La empresa se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su Web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en sus servidores.</p>

                <h2>Propiedad intelectual e industrial</h2>
                <p>Los derechos de propiedad intelectual del contenido de las páginas web, su diseño gráfico y códigos son titularidad de LATENCY y, por tanto, queda prohibida su reproducción, distribución, comunicación pública, transformación o cualquier otra actividad que se realice con los contenidos de la página web, ni siquiera aunque se citen las fuentes, salvo que se cuente con el consentimiento previo, expreso y por escrito de LATENCY. Todos los nombres comerciales, marcas o signos distintos de cualquier clase contenidos en las páginas web de la empresa son propiedad de sus dueños y están protegidos por ley.</p>
                <p>LATENCY no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el sitio web, y en ningún caso se entenderá que el acceso y navegación de los usuarios implica una renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos por parte de LATENCY.</p>
                <p>Cualquier uso de esos contenidos no autorizado previamente por parte de LATENCY será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial y dará lugar a las responsabilidades legalmente establecidas. LATENCY se reserva el derecho de ejercitar frente al usuario las acciones judiciales y extrajudiciales que correspondan.</p>

                <h2>Responsabilidad y garantías</h2>
                <p>LATENCY declara que ha adoptado las medidas necesarias que, dentro de sus posibilidades y el estado de la tecnología, permitan el correcto funcionamiento de su sitio web así como la ausencia de virus y componentes dañinos. Sin embargo, LATENCY no puede hacerse responsable de las siguientes situaciones que se enumeran a título enunciativo, pero no limitativo:</p>
                <ul>
                    <li>La continuidad y disponibilidad de los Contenidos.</li>
                    <li>La ausencia de errores en dichos Contenidos ni la corrección de cualquier defecto que pudiera ocurrir.</li>
                    <li>La ausencia de virus y/o demás componentes dañinos.</li>
                    <li>Los daños o perjuicios que cause cualquier persona que vulnere los sistemas de seguridad de LATENCY.</li>
                    <li>El uso que los usuarios puedan hacer de los contenidos incluidos en el web. En consecuencia, LATENCY no garantiza que el uso que los usuarios puedan hacer de los contenidos que en su caso se incluyan en el sitio web, se ajusten al presente aviso legal, ni que el uso del sitio web se realice de forma diligente.</li>
                    <li>El uso por menores de edad del sitio web o del envío de sus datos personales sin el permiso de sus tutores, siendo los tutores responsables del uso que hagan de Internet.</li>
                    <li>Los contenidos a los que el usuario pueda acceder a través de enlaces no autorizados o introducidos por usuarios mediante comentarios herramientas similares.</li>
                    <li>La introducción de datos erróneos por parte del usuario o de un tercero.</li>
                </ul>
                <p>LATENCY podrá suspender temporalmente y sin previo aviso, la accesibilidad al sitio web con motivo de operaciones de mantenimiento, reparación, actualización o mejora. No obstante, siempre que las circunstancias lo permitan, LATENCY comunicará al usuario, con antelación suficiente, la fecha prevista para la suspensión de los contenidos.</p>
                <p>Asimismo, de conformidad con los arts. 11 y 16 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSICE), LATENCY se compromete a la eliminación o, en su caso, bloqueo de los contenidos que pudieran afectar o ser contrarios a la legislación vigente, los derechos de terceros o la moral y orden público.</p>

                <h2>Cookies</h2>
                <p>LATENCY utiliza cookies, a los efectos de optimizar y personalizar su navegación por el sitio web. Las cookies son ficheros físicos de información que se alojan en el propio terminal del usuario, la información recogida mediante las cookies sirve para facilitar la navegación del usuario por el portal y optimizar la experiencia de navegación. Los datos recopilados mediante las cookies pueden ser compartidos con los creadores de las mismas, pero en ningún caso la información obtenida por las mismas será asociada a datos personales ni a datos que puedan identificar al usuario.</p>
                <p>Sin embargo, si el usuario no desea que se instalen cookies en su disco duro, tiene la posibilidad de configurar el navegador de tal modo que impida la instalación de estos archivos. Para obtener más información consulte nuestra Política de Cookies <a href="https://latencyapp.com/cookies-policy">https://latencyapp.com/cookies-policy</a>.</p>

                <h2>Enlaces (links)</h2>
                <p>La presencia de enlaces (links) en la página web de LATENCY hacia otros sitios de Internet tiene finalidad meramente informativa y en ningún caso suponen sugerencia, invitación o recomendación sobre los mismos. LATENCY no asumirá responsabilidad por los contenidos de un enlace perteneciente a un sitio web ajeno, ni garantizará la fiabilidad, exactitud, amplitud, veracidad, validez y disponibilidad técnica.</p>
                <p>En el caso de que en otros sitios web se establezcan enlaces a la página web de LATENCY, no se entenderá que LATENCY ha autorizado el enlace o el contenido del sitio web en el que se contiene el enlace, tampoco podrá incluirse en la página del enlace contenidos inapropiados, difamatorios, ilegales, obscenos o ilícitos, ni otros contenidos que sean contrarios a la legalidad vigente.</p>
                <p>LATENCY se reserva la posibilidad de contactar con el propietario del sitio web en el que se establezca el enlace si considera que se incumple la normativa, así como de ejercitar las correspondientes acciones judiciales y extrajudiciales.</p>

                <h2>Modificación de las condiciones</h2>
                <p>LATENCY se reserva expresamente el derecho a modificar unilateralmente, total o parcialmente, sin necesidad de previo aviso, el presente Aviso Legal. El usuario reconoce y acepta que es su responsabilidad revisar periódicamente el presente Aviso Legal.</p>
            </div>
        </Box>
    )
}

export default LegalNoticeES;
