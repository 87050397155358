import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import services from '../../../services';

const EventKeywordsInput = (props) => {
  const darkTheme = useSelector(state => state.darkTheme)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState([])
  const { t } = useTranslation('eventForm')
  const [txtValue, setTxtValue] = useState('');

  useEffect(() => {
    if (props.initialData && props.initialData.length) {
      let cc = []
      for (let a = 0; a < props.initialData.length; a++) {
        let item = props.initialData[a];
        if(item && item.hasOwnProperty('name')) {
          cc.push(props.initialData[a].name.toLowerCase())
        } else {
          cc.push(props.initialData[a].toLowerCase())
        }
      }
      setValues(cc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, data) => {
    if (data.length > 0) {
      let text = data[data.length - 1]
      let exists = list.find(item => item.toLowerCase() === text.toLowerCase());
      if (exists) { // if exists in the search-results, let's take his format (uppercase,..)
        let index = data.findIndex(item => item === text)
        data[index] = exists;
      }

      // check comma's
      data = data.filter((item) => {
        if(item === ",") {
          return false;
        }
        return true;
      });

      data = data.map((item) => item.toLowerCase());

    }
    setValues(data)
    props.handleChange(data);
    setTxtValue('');
  }

  const handleSpace = (event) => {
    let tmptext = event.target.value.trim();
    let arrText = tmptext.split(/,| /);

    if (event.keyCode === 13) {
      let data = values.length > 0 ? [...values] : [];
      arrText.forEach(text => {
        if (text !== " " && text !== "" && text.length > 0) {
          let exist = values.find(item => item.toLowerCase() === text.toLowerCase());
          if (!exist) {
            data.push(text.toLowerCase());
          }
        }
      });
      //props.handleChange(data);
      //setValues(data);
      handleChange(event, data);
      setTxtValue('');
    } else if (event.keyCode === 32) {
      let data = values.length > 0 ? [...values] : [];
      arrText.forEach(text => {
        if (text !== " " && text !== "" && text.length > 0) {
          let exist = values.find(item => item.toLowerCase() === text.toLowerCase());
          if (!exist) {
            data.push(text.toLowerCase());
          }
        }
      });
      //props.handleChange(data);
      //setValues(data);
      handleChange(event, data);
      setTxtValue('');
    } else if (event.keyCode === 188) {
      let data = values.length > 0 ? [...values] : [];
      arrText.forEach(text => {
        if (text !== " " && text !== "" && text.length > 0) {
          let exist = values.find(item => item.toLowerCase() === text.toLowerCase());
          if (!exist) {
            data.push(text.toLowerCase());
          }
        }
      });
      //setTxtValue('');
      //props.handleChange(data);
      handleChange(event, data);
      setValues(data);
    }
    return event;
  };

  const handleSearch = (event) => {
    let val = event.target.value.trim().replace(',', '');
    if (val && val.length > 2) {
      setLoading(true)
      services.search.find({
                             query: {
                               text: val,
                               categories: 1
                             }
                           })
        .then((resp) => {
          let data = resp.map(item => item.name)
          setList(data.filter(e => !values.some(t => t === e)).map(i => i.toLowerCase()))
          setTxtValue(val)
        })
    } else {
      if (val.length === 0) {
        setTxtValue('')
      } else {
        setTxtValue(val)
        setList([])
      }
    }
  }

  return (
    <Autocomplete
      freeSolo
      inputValue={txtValue}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('forms_search_keywords_placeholder')}
          onKeyDown={handleSpace}
          onChange={handleSearch}
          fullWidth
        />
      )}
      className={"tag-select " + (darkTheme ? "tag-select-dark" : "tag-select-light")}
      multiple
      loading={loading}
      autoSelect={true}
      autoComplete={true}
      value={values}
      classes={{
        tag: "chip",
        popper: props.quick ? "quick-tag-select-dropdown" : "",
        endAdornment: props.quick ? "clear-icon" : "",
        loading: "loading",
        noOptions: "static"
      }}
      options={list}
      onChange={handleChange}
    />
  )
}


export default EventKeywordsInput;
