import React, {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import Category from "./Category";
import {useSelector} from "react-redux";

const TopCategoriesByUser = (props) => {
    const openedProfileFilterCategory = useSelector(state => state.openedProfileFilterCategory);
    const [cats, setCats] = useState([]);

    useEffect(() => {
        let prepare_cats = props.topCategories.map(item => {
            if(openedProfileFilterCategory === item.id) {
                item.isFilter = true;
            } else {
                item.isFilter = false;
            }
            return item;
        });
        setCats(prepare_cats);
    }, [openedProfileFilterCategory, props.topCategories]);

    return (
        <Box display="flex"
             flexWrap="wrap"
             minWidth="100%"
             width="100%"
             position="relative"
             margin="0px auto"
             marginTop={props.containerMarginTop}
             maxWidth="200rem"
             paddingY={props.containerPaddingY}
             justifyContent={props.justifyContent}
             alignItems="center">
            {(cats.length === 0)
                ? <Category {...props}/>
                : cats.map(c => <Category key={c.id} category={c} isFilter={c.isFilter} {...props}/>)}
        </Box>
    );
}


TopCategoriesByUser.defaultProps = {
    containerMarginTop: "1rem",
    containerPaddingY: "1rem",
    boxWidth: "10rem",
    justifyContent: "flex-start",
    boxMarginBottom: "1rem"
}

export default TopCategoriesByUser;
