import React, {useEffect, useState} from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useSelector} from 'react-redux';
import {createFilterOptions} from "@material-ui/lab";

const CategoriesList = (props) => {
    const darkTheme = useSelector(state => state.darkTheme);
    const [value, setValue] = React.useState(null);
    const options = props.initialData;
    const [inputValue, setInputValue] = React.useState('');
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setLoading(true);
        if(options !== null && options.length > 0) {
            setInputValue(options[0].name);
            setValue(options[0]);
            setLoading(false);
        }
    }, [options]);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    return (
        <Autocomplete
            value={value}
            inputValue={inputValue}
            disabled={props.disabled}
            options={options}
            onChange={(event, newValue) => {
                setValue(newValue);
                props.handleChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            getOptionLabel={option => option.name ? option.name : ""}
            filterOptions={filterOptions}
            renderInput={params => (
                <TextField
                    {...params}
                    className={"custom-input" + (darkTheme ? " input-dark" : " input-light")}
                    variant="outlined"
                    placeholder={"Categoria"}
                    helperText="Filtro de auto-aceptar"
                    fullWidth
                />
            )}
            className={"tag-select owner " + (darkTheme ? "tag-select-dark" : "tag-select-light")}
            loading={loading}
            classes={{tag: "chip", popper: "full-tag-select-dropdown", endAdornment: "clear-icon", popupIndicator: "down-btn", loading: "static", noOptions: "static"}}
        />
    )
}


export default CategoriesList;
