const initailState = false

const eventInfoModalReducer = (state = initailState, action) => {
    switch(action.type){
        case 'OPEN_EVENT_INFO_MODAL':
            return state = true
        case 'CLOSE_EVENT_INFO_MODAL':
            return state = initailState
        default:
            return state
    }
}

export default eventInfoModalReducer;