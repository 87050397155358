export const openAddEventModal = () => {
    return dispatch => {
        dispatch({type: 'OPEN_ADD_EVENT_MODAL'})
    }
}

export const closeAddEventModal = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_ADD_EVENT_MODAL'})
    }
}
