export const setSettingsData = (data) => {
    return dispatch => {
        dispatch({type: 'SET_SETTINGS', payload: data})
    }
}

export const unsetSettingsData = () => {
    return dispatch => {
        dispatch({type: 'UNSET_SETTINGS'})
    }
}

export const toggleExperimentalMode = (val) => {
    return dispatch => {
        dispatch({type: 'TOGGLE_SETTINGS_GEN_EXPERIMENTAL_MODE', payload: val})
    }
};

export const setSettingsGeneralLanguage = (val) => {
    return dispatch => {
        dispatch({type: 'SET_SETTINGS_GEN_LANGUAGE', payload: val})
    }
};

export const toggleGroupedEvents = (val) => {
  return dispatch => {
      dispatch({type: 'TOGGLE_SETTINGS_GEN_GROUPED_MODE', payload: val})
  };
};
