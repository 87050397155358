import React, {useEffect} from 'react';
import './contact.scss';
import {Box} from '@material-ui/core';
import ContactForm from '../../components/Forms/ContactForm/ContactForm';
import Logo from '../../components/Logo/Logo';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

const Contact = (props) => {

    const { t } = useTranslation('');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Box bgcolor="header.main" className="contact-page" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Helmet>
                <title>{t('footer:static_pages_contact')} / Latency</title>
            </Helmet>
            <Logo className="login-logo" />
            <ContactForm />
        </Box>
    )
}

export default Contact;
