import React, {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import EyeIcon from '@material-ui/icons/Visibility';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {TwitchEmbed} from 'react-twitch-embed';
import {useDispatch, useSelector} from "react-redux";
import {setOpenedEvent} from "../../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../../store/eventInfoModal/actions";
import { isMobile } from 'react-device-detect';
import {Event} from "../../../../services/tracking";

const componentName = "LiveNowItem";

const LiveNowItem = ({ item, isPlayerOpen, onPlayerOpen, customClasses, displayActions }) => {
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const [playerOpen, setPlayerOpen] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const {t} = useTranslation('extraData');

    const handleBoxToggle = () => {
        if (!playerOpen) {
            setShowActions(true);
        }
    };

    const handlePlayStream = () => {
        setShowActions(false);
        let stream =`${item.hasOwnProperty('twitch_channel') ? item.twitch_channel : 'no-twitch-channel'}#+#${item.id.replaceAll('-', '')}`
        onPlayerOpen(stream);
        Event(componentName, "PlayStream", stream);
    };

    const OpenInfo = () => {
        window.history.pushState({backRef: window.location.pathname}, "my calendar", `/${item.owner.slug}/${item.id}`)
        dispatch(setOpenedEvent(item.id))
        dispatch(setOpenedEventMessages(item.id))
        dispatch(openEventInfoModal())
        setShowActions(false)
        Event(componentName, "OpenEvent", item.id);
    };

    useEffect(() => {
        setPlayerOpen(isPlayerOpen);
        if(isPlayerOpen && showActions) {
            setShowActions(false);
        }
    }, [isPlayerOpen]);

    return (
        <div className={
            `landing-event-item-live${customClasses !== undefined ? ' ' +customClasses : ''}${playerOpen ? ' is-player' : ''}${deviceView === 'browser' ? ' is-desktop' : ''}${deviceView === 'mobile' ? ' is-mobile' : ''}${deviceView === 'tablet' ? ' is-tablet' : ''}${darkTheme ? ' is-dark-theme' : ' is-light-theme'}`}
             onMouseEnter={isMobile ? () => false : handleBoxToggle}
             onMouseLeave={() => setShowActions(false)}
             onClick={!isMobile ? () => false : handleBoxToggle}
        >
            {!playerOpen && (
                <>
                    <div className={"item-thumb"}>
                        <div className={"item-thumb-layer"}>
                            <Box className="item-thumb-on-live is-live" display="flex" flexDirection="row"
                                 justifyContent="flex-start"
                                 alignItems="center">
                                <span className={"is-live-label"}>{t('extraData:event_in_live')}</span>
                            </Box>
                            <Box className="item-thumb-live-counter" display="flex" flexDirection="row"
                                 justifyContent="flex-start"
                                 alignItems="center">
                                <span className={"live-counter-icon"}>
                                    <EyeIcon/>
                                </span>
                                <span className={"live-counter-label"}>{item?.twitch_viewer_count}</span>
                            </Box>
                        </div>
                        <div className={"item-thumb-bg"}/>
                        <img src={`${item.hasOwnProperty('thumbnail_url') ? item.thumbnail_url : ""}`}/>
                    </div>
                    <div className={"item-content"}>
                        <div className={"item-user"}>
                            <div className={"item-user-avatar"}>
                                <img src={item.avatar}/>
                            </div>
                            <div className={"item-user-content"}>
                                <div className="item-user-title">{item.twitch_user}</div>
                                <div className="item-user-subtitle">{item.title}</div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {(displayActions && showActions) && (
                <div className={"item-actions"}>
                    <PlayArrowIcon onClick={handlePlayStream}/>
                    <OpenInNewIcon onClick={OpenInfo}/>
                </div>
            )}
            {playerOpen &&
            <TwitchEmbed width={"100%"} withChat={false} channel={item.hasOwnProperty('twitch_channel') ? item.twitch_channel : 'no-twitch-channel'} id={`${item.hasOwnProperty('twitch_channel') ? item.twitch_channel : 'no-twitch-channel'}-${item.id}`} theme={darkTheme ? "dark" : "light"} muted/>}
        </div>
    );
};

LiveNowItem.defaultProps = {
    displayActions: true
}

export default LiveNowItem;
