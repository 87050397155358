import React from 'react';
import './scroller.scss';
import {Box} from '@material-ui/core';
import Scrollbar from "react-scrollbars-custom";
import {useSelector} from "react-redux";

const Scroller = (props) => {
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <Box className={`scroller ${darkTheme ? 'dark-theme' : 'light-theme'}`}>
            <Scrollbar noDefaultStyles disableTracksWidthCompensation onScroll={props.onScroll}>
                {props.children}
            </Scrollbar>
        </Box>
    )
}

export default Scroller;
