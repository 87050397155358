import React from 'react';
import './logo.scss';
import {Box} from '@material-ui/core';
import logoLatency from '../../assets/images/logo_latency.svg';
import logoLatencyComplex from '../../assets/images/logo_latency_complex.svg';
import logoPingingIsotype from '../../assets/images/logo_isotipo_pinging.svg';

const Logo = (props) => {

    return (
        <Box className="logo-box" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <img src={props.variant === 'white' ? logoLatencyComplex : props.variant === 'isotype' ? logoPingingIsotype : logoLatency} className={props.className} alt="Latency" />
            {/*(props.className === "navbar-logo") && deviceView === "browser"  &&
                <Box className="slogan-box" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                    <span className="slogan"> {t('website_slogan')} </span>
                    <span className="version"> ALPHA v{process.env.REACT_APP_VERSION} </span>
                </Box>
            */}
        </Box>
    )
}

export default Logo;
