import React, {Fragment} from 'react';
import './event-card.scss';
import {Box, Tooltip} from '@material-ui/core';
import {formatDate} from '../../../helpers/dateFormatter';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {isSameDay, format} from 'date-fns'
import LinesEllipsis from 'react-lines-ellipsis'

const EventCardSimple = (props) => {
    const currentUser = useSelector(state => state.user);
    const {event, isCalendar} = props
    const pipeDate = new Date();
    const {t} = useTranslation(['eventForm', 'extraData'])
    const darkTheme = useSelector(state => state.darkTheme)
    const eventStart = new Date(event.start);
    const eventEnd = new Date(event.end);
    const _isSameDay = isSameDay(eventStart, eventEnd);
    const _isToday = isSameDay(pipeDate, eventStart);
    const formatPipeDate = pipeDate && pipeDate instanceof Date ? format(pipeDate, 'yyy-MM-dd') : null;

    const eventPoster = event.owner && event.poster && event.poster !== "/images/defaultHeader2.png" ? event.poster : "/images/defaultAvatar.png";

    return (
        <Box className={`event-card-simple animated fadeIn ${darkTheme ? ' is-dark-theme' : 'is-light-theme'}`}
             bgcolor="background.default">
            <Tooltip title={event.owner.nickname} placement="bottom-start" arrow>
                <img width="55" height="55" src={event.owner && event.owner.avatar ? event.owner.avatar : "/images/defaultAvatar.png"}
                     className={"avatar"} alt={event.owner.nickname}/>
            </Tooltip>
            {event.poster && event.poster !== "/images/defaultHeader2.png" && (
                <div className="poster-wrap">
                    {eventPoster.endsWith(".webp") ? (
                        <picture className={"poster"}>
                            <source srcSet={eventPoster}/>
                            <img src={event.original_poster} alt={event.title}/>
                        </picture>
                    ) : (
                        <img src={eventPoster} className={"poster"} alt={event.title}/>
                    )}
                </div>
            )}
            <Box
                className={`event-card-content`}
                display="flex" flexDirection="column" justifyContent="center">
                <Tooltip title={event.title} placement="top" arrow>
                    <Box className="title-w-line-ellipsis">
                        <LinesEllipsis
                            component={"h3"}
                            text={event.title}
                            maxLine='2'
                            ellipsis='...'
                            trimRight
                            basedOn='words'
                        />
                    </Box>
                </Tooltip>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    {event.isLive && (
                        <Box className="is-live" display="flex" flexDirection="row" justifyContent="flex-start"
                             alignItems="center">
                            <span className={"is-live-circle"}/>
                            <span className={"is-live-label"}>{t('extraData:event_in_live')}</span>
                        </Box>
                    )}
                    {!event.isLive && (
                        <>
                            {(!event.is_fullday && _isSameDay) &&
                            <Fragment>
                                {_isToday && (
                                    <Fragment>
                                        {new Date(event.start).getTime() !== new Date(event.end).getTime() &&
                                        <span
                                            className="date">{`${t('explore:explore_label_event_today')} ${formatDate(event.start, "p")} - ${formatDate(event.end, "p")}`}</span>
                                        }
                                        {new Date(event.start).getTime() === new Date(event.end).getTime() &&
                                        <span className="date">{`${t('explore:explore_label_event_today')} ${formatDate(event.start, "p")}`}</span>
                                        }
                                    </Fragment>
                                )}
                                {!_isToday && (
                                    <span className="date">{`${formatDate(event.start, "iii d, p")}`}</span>
                                )}

                            </Fragment>
                            }
                            {(!event.is_fullday && !_isSameDay) &&
                            <Fragment>
                                {formatDate(event.start, 'yyyy-MM-dd') === formatPipeDate &&
                                <span
                                    className="date">{`${formatDate(event.start, "p")} - ${formatDate(event.end, "iii d, p")}`}</span>
                                }
                                {formatDate(event.end, 'yyyy-MM-dd') === formatPipeDate &&
                                <span
                                    className="date">{`${formatDate(event.start, "iii d, p")} - ${formatDate(event.end, "p")}`}</span>
                                }
                                {formatDate(event.start, 'yyyy-MM-dd') !== formatPipeDate && formatDate(event.end, 'yyyy-MM-dd') !== formatPipeDate &&
                                <span
                                    className="date">{`${formatDate(event.start, "iii d, p")} - ${formatDate(event.end, "iii d, p")}`}</span>
                                }
                            </Fragment>
                            }
                            {(event.is_fullday && _isSameDay) && <>
                                {_isToday ? <span className="date"> {t('forms_label_allDay')} </span> : <span
                                    className="date">{formatDate(event.start, "iii d, p")}</span>}
                            </>
                            }
                            {(event.is_fullday && !_isSameDay) &&
                            <span
                                className="date"> {t('forms_label_allDay')} - {formatDate(event.end, "iii d, p")}</span>
                            }
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default EventCardSimple;

