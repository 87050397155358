const initailState = []

const userGroupsReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_USER_GROUPS':
            return state = action.payload;
        case 'UNSET_USER_GROUPS':
            return state = initailState
        default:
            return state
    }
}

export default userGroupsReducer;