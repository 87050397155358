import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useDispatch, useSelector} from "react-redux";
import DateBlockComponent from "../Forms/NewAddEventForm/DateBlockComponent";
import CustomButton from "../CustomButton/CustomButton";
import {useTranslation} from "react-i18next";
import services from "../../services";
import {formatDate} from "../../helpers/dateFormatter";
import {archivementStatusPreValues} from "../../helpers/archivementStatusStaticList";
import {showSnackbar} from "../../store/snackbar/actions";

const AchievementListForm = ({listId, onSaveData, onClose}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);

    const [listName, setListName] = useState("");
    const [listNameError, setListNameError] = useState(false);
    const [weight, setWeight] = useState(0);
    const [weightError, setWeightError] = useState(false);

    const darkTheme = useSelector(state => state.darkTheme);

    useEffect(() => {
        setLoading(true);
        if(user && user.hasOwnProperty('id')) {
            // fetch user own list
            // if empty add this
            services.userAchievementList.get(listId).then((res) => {
                setListName(res.label);
                setWeight(res.weight);
                setLoading(false);
            });
        }
    }, [user, listId]);

    const handleSubmit = () => {
        if(listName.length > 0 && weight >= 0) {
            setLoading(true);
            services.userAchievementList.patch(listId, {
                label: listName,
                weight: weight
            }).then(res => {
                dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_patch_success"), type: "success"}));
                onClose();
                if(onSaveData){
                    onSaveData(user.id);
                }
            }).catch(err => {
                dispatch(showSnackbar({message: t("archivementForm:snackbar_archivement_patch_error"), type: "error"}));
                setLoading(true);
            });
        }
    };

    return (
        <Box p={1}>
            <div className="title">{t('archivementForm:achievement_list_form_edit_title')}</div>
            <div className="archivements-modal--fields">
                <div>
                    <label className="field-label">{t('archivementForm:achievement_list_form_list_label')}*</label>
                    <TextField
                        fullWidth
                        required
                        className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                        error={listNameError}
                        type="text"
                        placeholder={t('eventForm:forms_no_title')}
                        value={listName}
                        variant="outlined"
                        onChange={(e) => {
                            setListName(e.target.value)
                            !e.target.value ? setListNameError(true) : setListNameError(false)
                        }}
                    />
                </div>
                <div>
                    <label className="field-label">{t('archivementForm:achievement_list_form_weight_label')}</label>
                    <TextField
                        fullWidth
                        required
                        className={(darkTheme ? "input-dark" : "input-light") + " grey"}
                        error={weightError}
                        type="number"
                        min="0"
                        placeholder={t('eventForm:achievement_list_form_weight_label')}
                        value={weight}
                        variant="outlined"
                        onChange={(e) => {
                            setWeight(e.target.value)
                            !e.target.value ? setWeightError(true) : setWeightError(false)
                        }}
                    />
                </div>
            </div>

            <Box mt={2} mb={2} ml={1} mr={1} display="flex" flexDirection="row" justifyContent="center">
                <CustomButton className="cancel-btn wide" size="large" disabled={loading}
                              onClick={onClose}> {t('archivementForm:archivement_form_cancel_btn')} </CustomButton>
                <div style={{
                    width: 32
                }}></div>
                <CustomButton className="submit-btn wide" loading={loading} disabled={loading} size="large"
                              onClick={(e) => handleSubmit(e)}> {t('archivementForm:archivement_form_save_btn')} </CustomButton>
            </Box>
        </Box>
    );
};

export default AchievementListForm;
