const initialState = {};

const openedDiscoverCategoriesWeeklyEventsReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_OPENED_DISCOVER_CATEGORIES_WEEKLY_SELECTED':
            return action.payload;
        case 'UNSET_OPENED_DISCOVER_CATEGORIES_WEEKLY_SELECTED':
            return state = initialState;
        default:
            return state
    }
}

export default openedDiscoverCategoriesWeeklyEventsReducer;
