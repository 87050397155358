const initialState = [];

const multiAccountsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'MA_ADD_NEW_ACCOUNT':
      let exists = state.find((item) => item.id === action.payload.id);
      if(exists) {
        return state;
      } else {
        return [...state, action.payload];
      }
    case 'MA_REMOVE_ACCOUNT':
      return state.filter((item) => item.id !== action.payload);
    case 'RESET_MULTI_ACCOUNTS':
      return state = initialState
    default:
      return state
  }
}

export default multiAccountsReducer;
