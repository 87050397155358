import React, {useState} from 'react';
import './password-recovery-form.scss';
import {Box} from '@material-ui/core';
import StageOne from "./StageOne";
import StageThird from "./StageThird";
import StageTwo from "./StageTwo";
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from "react-i18next";

const PasswordRecoveryForm = (props) => {
    const [stage, setStage] = useState(1);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {t} = useTranslation(['validations']);

    const handleNextStage = (num) => {
        setStage(num);
    };

    return (
        <Box className="password-recovery-form" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <CloseIcon onClick={props.onClose} className="close-icon" />
            <h2>{t('validation_title')}</h2>
            <h3>{stage}/3</h3>
            <Box minWidth="300px" maxWidth="300px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                {stage === 1 && <StageOne onChange={setEmail} onNext={handleNextStage} />}
                {stage === 2 && <StageTwo onChange={setPassword} onNext={handleNextStage} />}
                {stage === 3 && <StageThird email={email} password={password} />}
            </Box>
        </Box>
    )
}

export default PasswordRecoveryForm;
