import {createStore, applyMiddleware} from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { encryptTransform } from 'redux-persist-transform-encrypt';
import allReducers from './reducers'


const encryptor = encryptTransform({
    secretKey: 'latency-frontend'
});


const persistConfig = {
    key: 'latency220921',
    storage,
    transforms: [encryptor],
    blacklist: ['notifications', 'invitations', 'loader', 'openedProfile', 'completeSignupModal', 'openedProfileFilterCategory']
};

const persistedReducer = persistReducer(persistConfig, allReducers);
export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
