const initialState = {};

const landingFeedClipsReducer = (state = initialState, action) => {
    let roomId = undefined;
    let messageId = undefined;
    switch(action.type){
        case 'SET_LANDING_FEED_CLIPS':
            let tmp_state = Object.assign({}, state);
            roomId = action.payload.roomId;
            tmp_state[roomId] = {
                total: action.payload.messages.total,
                data: action.payload.messages.data
            };
            return tmp_state;
        case 'ADD_LANDING_FEED_CLIP':
            let tmp = Object.assign({}, state);
            roomId = action.payload.roomId;
            if(tmp.hasOwnProperty(roomId)){
                tmp[roomId] = {
                    total: tmp[roomId].total,
                    data: [action.payload.message, ...tmp[roomId].data]
                };
            } else {
                tmp[roomId] = {
                    total: 1,
                    data:  [action.payload.message]
                };
            }
            return tmp;
        case 'EDIT_LANDING_FEED_CLIP':
            roomId = action.payload.roomId;
            messageId = action.payload.messageId;
            let _arr = Object.assign({}, state);
            if(_arr.hasOwnProperty(roomId)){
                let roomMessages = _arr[roomId].data
                roomMessages = roomMessages.map(item => {
                    if(item.id === messageId) {
                        return action.payload.message
                    } else {
                        return item
                    }
                });
                _arr[roomId].data = [...roomMessages];
            }
            return _arr;
        case 'REMOVE_LANDING_FEED_CLIP':
            roomId = action.payload.roomId;
            messageId = action.payload.messageId;
            let __arr = Object.assign({}, state);
            if(__arr.hasOwnProperty(roomId)){
                let roomMessages = __arr[roomId].data;
                __arr[roomId].data = roomMessages.filter(item => item.id !== messageId);
            }
            return __arr;
        case 'ERASE_LANDING_CLIPS':
            return state = initialState;
        default:
            return state;
    }
}

export default landingFeedClipsReducer;
