import {store} from '../';

export const setParticipants = (data) => {
    return dispatch => {
        dispatch({
            type: 'SET_PARTICIPANTS',
            payload: prepareData()
        })
    }
}

export const unsetParticipants = () => {
    return dispatch => {
        dispatch({type: 'UNSET_PARTICIPANTS'})
    }
}

const prepareData = () => {
    let newData = []
    let obj = {}
    let groups = store.getState().userGroups
    let data = store.getState().userFollowing
    for(let a=0; a<groups.length; a++){
        if(!groups[a].add){
            obj.type = "group"
            obj.data = groups[a]
            obj.nickname = groups[a].name
            newData.push(obj)
            obj = {}
        }
    }
    for(let a=0; a<data.length; a++){
        newData.push(data[a])
    }
    return newData
}
