import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import RedeemIcon from '@material-ui/icons/Redeem';
import services from "../../../../services";
import {showSnackbar} from "../../../../store/snackbar/actions";
import {useDispatch, useSelector} from "react-redux";
import {formatDate} from '../../../../helpers/dateFormatter';
import {setSubscription} from "../../../../store/subscription/actions";

const GeneralSuscriptionForm = () => {
    const {t} = useTranslation('settings');
    const subscription = useSelector(state => state.subscription);
    const dispatch = useDispatch();
    const [redeemVoucherCode, setRedeemVoucherCode] = useState("");
    const redeemCode = () => {
        if(redeemVoucherCode.length > 0) {
            services.subscriptions.create({
                code: redeemVoucherCode
            }).then((res) => {
                dispatch(setSubscription());
                dispatch(showSnackbar({message: t('settings:section_general_subscription_code_ok'), type: "success"}));
            }).catch((err) => {
                dispatch(showSnackbar({message: err.message, type: "error"}));
            });
        }
    };

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_general_subscription')}</div>
                <div className="settings-content-title-helper">{t('section_general_subscription_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-general">
                {subscription && Object.keys(subscription).length > 0 && subscription.is_processed === true && (
                    <div className="subscription-container">
                        <div className="subscription-text-until">
                            {t('settings:section_general_subscription_expires')} <strong>{formatDate(subscription.valid_until, 'dd-MM-yyyy')}</strong>
                        </div>
                    </div>
                )}
                {subscription && Object.keys(subscription).length > 0 && subscription.is_processed === false && (
                    <div className="subscription-container">
                        <div className="subscription-text-until">
                            {t('settings:section_general_subscription_processing')}
                        </div>
                    </div>
                )}
                {subscription && Object.keys(subscription).length === 0 && (
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">{t('settings:section_general_subscription_code')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-voucher-code"
                            type='text'
                            value={redeemVoucherCode}
                            onChange={(e) => setRedeemVoucherCode(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={redeemCode}
                                        edge="end"
                                    >
                                        <RedeemIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={100}
                        />
                    </FormControl>
                )}
            </div>
        </div>
    );
};

export default GeneralSuscriptionForm;
