import React from 'react';

const spanishPhrases = [
    {
        text: "Siempre nos quedará París",
        author: "Casablanca"
    },
    {
        text: "¿Por qué nos caemos? Para levantarnos.",
        author: "Batman Begins"
    },
    {
        text: "Al menos usted nunca será una hortaliza porque hasta las alcachofas tienen corazón.",
        author: "Amelie"
    },
    {
        text: "La vida es demasiado corta para estar siempre cabreado.",
        author: "American History X"
    },
    {
        text: "Todos esos momentos se perderán en el tiempo como lágrimas en la lluvia.",
        author: "Blade Runner"
    },
    {
        text: "La gente ama a las personas apasionadas.",
        author: "La La Land"
    },
    {
        text: "El maestro Pai Mei odia a los americanos, desprecia a las mujeres y no soporta a las rubias. Le caerás bien.",
        author: "Kill Bill"
    },
    {
        text: "No existen preguntas sin respuesta, sólo preguntas mal formuladas.",
        author: "Matrix"
    },
    {
        text: "Podrán quitarnos nuestras vidas, pero jamás, nuestra libertad.",
        author: "Braveheart"
    },
    {
        text: "Mi mamá siempre me decía que la vida era como una caja de bombones: nunca sabes lo que te va a tocar.",
        author: "Forrest Gump"
    },
    {
        text: "Houston tenemos un problema.",
        author: "Apolo 13"
    },
    {
        text: "He cruzado océanos de tiempo para encontrarte.",
        author: "Drácula"
    },
    {
        text: "Un mago nunca llega tarde, ni pronto: llega exactamente cuándo se lo propone.",
        author: "El señor de los anillos: La Comunidad del Anillo"
    },
    {
        text: "Tus excusas son más previsibles que las muertes de Krillin.",
        author: ""
    },
    {
        text: "Si te pierdes tres veces en Ikea, te convalidan el camino de Santiago.",
        author: ""
    },
    {
        text: "Eres más falso que las peleas de los Power Rangers.",
        author: ""
    },
    {
        text: "Yo nunca me voy a convertir en zombie, si me cuesta levantarme de la cama, imagínate de la tumba.",
        author: ""
    },
    {
        text: "Estar contigo se me hace más largo que un partido de Oliver y Benji.",
        author: ""
    },
    {
        text: "Si no puedes con ellos, bájales el nivel de dificultad.",
        author: ""
    },
    {
        text: "El eco siempre dice la última palabra.",
        author: ""
    },
    {
        text: "Los ordenadores son buenos siguiendo instrucciones, no leyendo tu mente.",
        author: ""
    },
    {
        text: "Sin café no soy persona, soy un gremlin recién mojado.",
        author: ""
    },
    {
        text: "¡Es peligroso ir por allí solo! Toma ésto.",
        author: "The Legend of Zelda"
    },
    {
        text: "Mira detrás de ti, ¡un mono de tres cabezas!",
        author: "Monkey Island"
    },
    {
        text: "El hombre adecuado en el sitio equivocado puede cambiar el rumbo del mundo",
        author: "Half-Life 2"
    },
    {
        text: "Nada es verdad. Todo está permitido",
        author: "Assasin's Creed"
    },
    {
        text: "Let's Rock, baby!",
        author: "Devil May Cry"
    },
    {
        text: "FUS-RO-DAH!",
        author: "The Elder Scrolls V: Skyrim"
    },
    {
        text: "Hail to the king, baby!",
        author: "Duke Nukem"
    },
    {
        text: "Nadie es innecesario.",
        author: "Yitán - Final Fantasy IX"
    },
    {
        text: "Al final, solo lamentamos lo que no hemos hecho.",
        author: "Taric - League of Legends"
    },
    {
        text: "Leyendas, el primero la cuenta, el segundo la escucha y el tercero la divulga.",
        author: "Solid Snake - Metal Gear Solid 2"
    },
    {
        text: "No desees que fuera más fácil, desea que fueras mejor. ",
        author: "Zoilo - Animal Crossing"
    }
];

const englishPhrases = [
    {
        text: "Let's Rock, baby!",
        author: "Devil May Cry"
    },
    {
        text: "FUS-RO-DAH!",
        author: "The Elder Scrolls V: Skyrim"
    },
    {
        text: "Hail to the king, baby!",
        author: "Duke Nukem"
    },
];

export const getRandomPhrase = (lang = "es") => {
    switch (lang) {
        case "en":
            return englishPhrases[Math.floor(Math.random() * englishPhrases.length)];
        default:
            return spanishPhrases[Math.floor(Math.random() * spanishPhrases.length)];
    }
};

export const getComponentPhrase = (lang = "es") => {
    let {text, author} = getRandomPhrase(lang);

    return (
        <figure className={"phrase-placeholder-component"}>
            <blockquote>
                {text}
            </blockquote>
            <figcaption>{author}</figcaption>
        </figure>
    )
}
