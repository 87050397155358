export const openEventInfoModal = () => {
    return dispatch => {
        dispatch({type: 'OPEN_EVENT_INFO_MODAL'})
    }
}

export const closeEventInfoModal = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_EVENT_INFO_MODAL'})
    }
}
