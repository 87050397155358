import React from 'react';
import {Box} from '@material-ui/core';
import {Helmet} from "react-helmet";

const PrivacyPolicyES = () => {

    return (
        <Box className="privacy-policy-page" m={2}>
            <Helmet>
                <title>Política de privacidad / Latency</title>
            </Helmet>
            <div className="static-content">
                <h2>Política de privacidad</h2>

                <table>
                    <tbody>
                    <tr>
                        <td colSpan="2">Información básica sobre Protección de Datos</td>
                    </tr>
                    <tr>
                        <td><strong>Responsable</strong></td>
                        <td><p>LATENCY E-SPORTS SL</p></td>
                    </tr>
                    <tr>
                        <td><strong>Finalidad</strong></td>
                        <td>
                            <p>Prestación de servicios online</p>
                            <p>Gestión de usuarios web</p>
                            <p>Comunicaciones comerciales relacionadas con nuestros servicios</p>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Legitimación</strong></td>
                        <td>
                            <p>Consentimiento expreso e interés legítimo</p>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Destinatarios</strong></td>
                        <td><p>Destinatarios	No se ceden datos a terceros, salvo obligación legal</p></td>
                    </tr>
                    <tr>
                        <td><strong>Derechos</strong></td>
                        <td><p>Acceder, rectificar y suprimir los datos, así Derechos	como otros derechos, como se explica en la información adicional</p></td>
                    </tr>
                    <tr>
                        <td><strong>Información adicional</strong></td>
                        <td>
                            <p>
                                Puede  consultar  la  información  adicional  y  detallada  sobre Información adicional	Protección de Datos en las cláusulas anexas que se encuentran en <a href="https://latencyapp.com/privacy-policy">https://latencyapp.com/privacy-policy</a>
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p>En LATENCY trabajamos para ofrecerte a través de nuestros productos y servicios la mejor experiencia posible. En algunos casos, es necesario recabar información para conseguirlo. Nos importa tu privacidad y creemos que debemos ser transparentes al respecto.</p>
                <p>Por ello, y a efectos de lo previsto en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016 (en adelante, “RGPD”) relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, y la LEY 34/2002, de 11 de julio, de Servicios de la Sociedad de la información y de comercio electrónico (en adelante, “LSSI”), LATENCY E-SPORTS SL informa al usuario que, como responsable del tratamiento, incorporará los datos de carácter personal facilitados por los usuarios en un fichero automatizado.</p>
                <p>Nuestro compromiso empieza por explicarte lo siguiente:</p>
                <ul>
                    <li>
                        <p>Se recogen tus datos para que la experiencia de usuario mejore, atendiendo a tus intereses y necesidades.</p>
                    </li>
                    <li>
                        <p>Somos transparentes en relación a los datos que obtenemos acerca de ti y la razón por la que lo hacemos.</p>
                    </li>
                    <li>
                        <p>Nuestra intención es ofrecerte la mejor experiencia posible. Por ello, cuando vayamos a usar tu información personal lo haremos siempre cumpliendo la normativa, y cuando sea necesario, solicitaremos tu consentimiento.</p>
                    </li>
                    <li>
                        <p>Entendemos que tus datos te pertenecen. Por tanto, si decides no autorizarnos a procesarlos puedes solicitarnos que dejemos de tratarlos.</p>
                    </li>
                    <li>
                        <p>Nuestra prioridad consiste en garantizar tu seguridad y tratar tus datos de acuerdo con la normativa europea.</p>
                    </li>
                </ul>
                <p>Si deseas obtener más información sobre el tratamiento de tus datos, consulta los distintos apartados de la política de privacidad que se encuentran a continuación:</p>

                <h2>¿Quién es el responsable del tratamiento de sus datos personales?</h2>
                <p>Identidad: LATENCY E-SPORTS SL</p>
                <p>Domicilio social: Plaza de España 10, 1ºB, Las Rozas de Madrid, Madrid, Código postal 28231</p>
                <p>C.I.F. nº: B88053871</p>
                <p>Email: info@latencyapp.com</p>

                <p>LATENCY ha designado un Delegado de Protección de Datos o una persona de contacto interna dentro de su organización. Si deseas hacer una consulta en relación al tratamiento de tus datos personales, puedes ponerte en contacto con él mediante el correo info@latencyapp.com</p>

                <h2>¿Qué datos personales recopilamos?</h2>
                <p>Los datos personales que el usuario puede llegar a proporcionar:</p>
                <ul>
                    <li>Nombre, dirección y fecha de nacimiento.</li>
                    <li>Número de teléfono y dirección de correo electrónico.</li>
                    <li>Ubicación.</li>
                    <li>Información relativa a pagos y devoluciones.</li>
                    <li>Dirección IP, fecha y hora en la que has accedido a nuestros servicios, navegador de internet que uses y datos sobre el sistema operativo del dispositivo.</li>
                    <li>Cualquier otra información o datos que decidas compartir con nosotros.</li>
                </ul>
                <p>En algunos casos, es obligatoria la cumplimentación del formulario de registro para acceder y disfrutar de determinados servicios ofrecidos en la web; asimismo, no facilitar los datos personales solicitados o el no aceptar la presente política de protección de datos supone la imposibilidad de suscribirse, registrarse o participar en cualquiera de las promociones en las que se soliciten datos carácter personal.</p>

                <h2>¿Por qué y para qué tratamos tus datos?</h2>
                <p>En LATENCY tratamos la información que nos facilitan las personas interesadas con las siguientes finalidades:</p>
                <ul>
                    <li>Gestionar pedidos o contratar alguno de nuestros servicios, ya sea online o en las tiendas físicas.</li>
                    <li>Gestionar el envío de la información que nos soliciten.</li>
                    <li>Desarrollar acciones comerciales y realizar el mantenimiento y gestión de la relación con el usuario, así como la gestión de los servicios ofrecido a través del sitio web y las labores de información, pudiendo realizar valoraciones automáticas, obtención de perfiles y labores de segmentación de los clientes con el objeto de personalizar el trato conforme a sus características y necesidades y mejorar la experiencia en línea del cliente.</li>
                    <li>Desarrollar y gestionar los concursos, sorteos u otras actividades promocionales que se puedan organizar.</li>
                    <li>En algunos casos será necesario facilitar información a las Autoridades o terceras empresas por motivos de auditoría, así como manejar datos personales de facturas, contratos y documentos para responder a reclamaciones de clientes o de las Administraciones Públicas</li>
                </ul>
                <p>Informamos que los datos personales que se obtengan como consecuencia de tu registro como usuario formarán parte del Registro de Actividades y operaciones de Tratamiento (RAT), que se actualizará periódicamente de acuerdo con lo establecido en el RGPD.</p>

                <h2>¿Cuál es la legitimación para el tratamiento de tus datos?</h2>
                <p>El tratamiento de tus datos puede fundamentarse en las siguientes bases legales:</p>
                <ul>
                    <li>Consentimiento del interesado para la contratación de servicios y productos, para los formularios de contacto, las solicitudes de información o alta en e-newsletters.</li>
                    <li>Interés legítimo para el tratamiento de datos de nuestros clientes en acciones de marketing directo y consentimiento expreso del interesado para todo lo relativo a las valoraciones automáticas y elaboración de perfiles.</li>
                    <li>Cumplimiento de obligaciones legales para prevención del fraude, comunicación con Autoridades públicas y reclamaciones de terceros.</li>
                </ul>

                <h2>¿Cuánto tiempo conservamos tus datos?</h2>
                <p>El tratamiento de los datos con las finalidades descritas se mantendrá durante el tiempo necesario para cumplir con la finalidad de su recogida (por ejemplo, mientras dure la relación comercial), así como para el cumplimiento de las obligaciones legales que se deriven del tratamiento de los datos.</p>

                <h2>¿A qué destinatarios se comunican tus datos?</h2>
                <p>En algunos casos, solo cuando sea necesario, LATENCY proporcionará datos de los usuarios a terceros. Sin embargo, nunca se venderán los datos a terceros. Los proveedores de servicios externos (por ejemplo, proveedores de pago o empresas de reparto) con los que LATENCY trabaje pueden usar los datos para proporcionar los servicios correspondientes, sin embargo no usarán dicha información para fines propios o para cesión a terceros.</p>
                <p>LATENCY procura garantizar la seguridad de los datos personales cuando se envían fuera de la empresa y se asegura que los terceros proveedores de servicio respetan la confidencialidad y cuentan con las medidas adecuadas para proteger los datos personales. Dichos terceros tienen la obligación de garantizar que la información se trata conforme con la normativa de privacidad de datos.</p>
                <p>En algunos casos, la ley puede exigir que se revelen datos personales a organismos públicos u otras partes, solo se revelará lo estrictamente necesario para el cumplimiento de dichas obligaciones legales.</p>
                <p>Los datos personales obtenidos también podrán ser compartidos con otras empresas del grupo.</p>

                <h2>¿Dónde se almacenan tus datos?</h2>
                <p>Con carácter general, los datos se almacenan dentro de la UE. Los datos que se envíen a terceros que no pertenezcan a la UE, nos aseguraremos que ofrezcan un nivel de protección suficiente, ya sea porque cuentan con Normas Corporativas Vinculantes (BCR) o porque se hayan adherido al “Privacy Shield”.</p>

                <h2>¿Qué derechos te asisten y cómo puedes ejercerlos?</h2>
                <p>Puedes dirigir tus comunicaciones y ejercitar tus derechos mediante una petición en el siguiente correo electrónico: info@latencyapp.com.</p>
                <p>En virtud de lo que establece el RGPD puedes solicitar:</p>
                <ul>
                    <li>Derecho de acceso: puedes pedir información de aquellos datos personales que dispongamos acerca de ti.</li>
                    <li>Derecho de rectificación: puedes comunicar cualquier cambio en tus datos personales.</li>
                    <li>Derecho de supresión y al olvido: puedes solicitar la eliminación previo bloqueo de los datos personales.</li>
                    <li>Derecho de limitación al tratamiento: supone la restricción del tratamiento de los datos personales.</li>
                    <li>Derecho de oposición: puedes retirar el consentimiento del tratamiento de los datos, oponiéndose a que se sigan tratando.</li>
                    <li>Derecho a la portabilidad: en algunos casos, puedes pedir una copia de los datos personales en un formato estructurado, de uso común y lectura mecánica para su transmisión a otro responsable.</li>
                    <li>Derecho a no ser objeto de decisiones individualizadas: puedes solicitar que no se tomen decisiones que se basen únicamente en el tratamiento automatizado, incluyendo la elaboración de perfiles, que produzca efectos jurídicos o afecte significativamente al interesado.</li>
                </ul>
                <p>En algunos casos, se podrá rechazar la solicitud si solicitas que se eliminen datos necesarios para el cumplimiento de obligaciones legales.</p>
                <p>Asimismo, si tienes alguna queja sobre el tratamiento de los datos puedes presentar una reclamación a la autoridad de protección de datos.</p>

                <h2>¿Quién es el responsable de la exactitud y veracidad de los datos facilitados?</h2>
                <p>El usuario es el único responsable de la veracidad y corrección de los datos incluidos, exonerando a LATENCY de cualquier responsabilidad al respecto. Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de registro o suscripción. LATENCY se reserva el derecho de finalizar los servicios contratados que se hubiera celebrado con los usuarios, en caso que los datos que haya facilitado sean falsos, incompletos, inexactos o no estén actualizados.</p>
                <p>LATENCY no responde de la veracidad de las informaciones que no sean de elaboración propia y de las que se indique otra fuente, por lo que tampoco asume responsabilidad alguna en cuanto a hipotéticos perjuicios que pudieran originarse por el uso de dicha información.</p>
                <p>LATENCY se reserva el derecho a actualizar, modificar o eliminar la información contenida en sus páginas web pudiendo incluso limitar o no permitir el acceso a dicha información. Se exonera a LATENCY de responsabilidad ante cualquier daño o perjuicio que pudiera sufrir el usuario como consecuencia de errores, defectos u omisiones, en la información facilitada por LATENCY siempre que proceda de fuentes ajenas a la misma.</p>
                <p>Asimismo, el usuario certifica que es mayor de 14 años y que posee la capacidad legal necesaria para la prestación del consentimiento en cuanto al tratamiento de sus datos de carácter personal.</p>

                <h2>¿Cómo tratamos los datos personales de los menores de edad?</h2>
                <p>En principio nuestros servicios no van dirigidos específicamente a menos de edad. Sin embargo, en el supuesto de que alguno de ellos se dirija a menores de catorce años, de conformidad con el artículo 8 del RGPD y el artículo 7 de la LO3/2018, de 5 de diciembre (LOPDGDD), LATENCY exigirá el consentimiento válido, libre, inequívoco, específico e informado de sus tutores legales para tratar los datos personales de los menores. En este caso, se exigirá el DNI u otra forma de identificación de quien preste el consentimiento.</p>
                <p>En el caso de mayores de catorce años podrá procederse al tratamiento de los datos con el consentimiento del usuario, exceptuando aquellos casos en los que la Ley exija la asistencia de los titulares de la patria potestad o tutela.</p>

                <h2>¿Qué medidas de seguridad aplicamos para proteger tus datos personales?</h2>
                <p>LATENCY ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente requeridos, y procura instalar aquellos otros medios y medidas técnicas adicionales a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los Datos Personales facilitados a LATENCY.</p>
                <p>LATENCY no es responsable de hipotéticos daños o perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de este sistema electrónico, motivadas por causas ajenas a LATENCY; de retrasos o bloqueos en el uso del presente sistema electrónico causados por deficiencias o sobrecargas de líneas telefónicas o sobrecargas en el Centro de Procesos de Datos, en el sistema de Internet o en otros sistemas electrónicos, así como de daños que puedan ser causados por terceras personas mediante intromisiones ilegítimas fuera del control de LATENCY. Ello no obstante, el usuario debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables.</p>

                <h2>Enlaces a otros sitios web</h2>
                <p>En el sitio web https://latencyapp.com puede haber enlaces a otras páginas web. Al clicar en uno de estos enlaces y acceder a un sitio web externo, la visita estará sujeta a la política de privacidad de dicho sitio web, quedando LATENCY desvinculada de cualquier tipo de responsabilidad acerca de su política de privacidad.</p>

                <h2>¿Cómo utilizamos las cookies?</h2>
                <p>El sitio web y las RRSS de LATENCY utilizan cookies, a los efectos de optimizar y personalizar su navegación por el mismo. Las cookies son ficheros físicos de información que se alojan en el propio terminal del usuario, la información recogida mediante las cookies sirve para facilitar la navegación del usuario por el portal y optimizar la experiencia de navegación. Los datos recopilados mediante las cookies pueden ser compartidos con los creadores de las mismas, pero en ningún caso la información obtenida por las mismas será asociada a datos personales ni a datos que puedan identificar al usuario.</p>
                <p>Sin embargo, si el usuario no desea que se instalen cookies en su disco duro, tiene la posibilidad de configurar el navegador de tal modo que impida la instalación de estos archivos. Para obtener más información consulte nuestra Política de Cookies <a href="https://latencyapp.com/cookies-policy">https://latencyapp.com/cookies-policy</a>.</p>

                <h2>¿Puede modificarse la política de privacidad?</h2>
                <p>Esta política de privacidad puede modificarse. Te recomendamos que revises la política de privacidad con cierta periodicidad.</p>
            </div>
        </Box>
    )
}

export default PrivacyPolicyES;
