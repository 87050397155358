import React from 'react';
import {Box} from '@material-ui/core';
import {Helmet} from "react-helmet";

const PrivacyPolicyEN = () => {

    return (
      <Box className="privacy-policy-page" m={2}>
          <Helmet>
              <title>Privacy Policy / Latency</title>
          </Helmet>
          <div className="static-content">
              <h2>Privacy Policy</h2>

              <table>
                  <tbody>
                  <tr>
                      <td colSpan="2">Basic Information on Data Protection</td>
                  </tr>
                  <tr>
                      <td><strong>Controller</strong></td>
                      <td><p>LATENCY E-SPORTS SL</p></td>
                  </tr>
                  <tr>
                      <td><strong>Purposes of treatment</strong></td>
                      <td>
                          <p>Informative website.</p>
                          <p>Management of queries through forms.</p>
                          <p>Management of queries through forms.</p>
                      </td>
                  </tr>
                  <tr>
                      <td><strong>Lawfulness of treatment</strong></td>
                      <td>
                          <p>Express consent of the data subject</p>
                          <p>Execution of a contract or application of pre-contractual measures.</p>
                          <p>Legitimate interest for data processing.</p>
                          <p>Compliance with legal obligations.</p>
                      </td>
                  </tr>
                  <tr>
                      <td><strong>Recipients</strong></td>
                      <td><p>Recipients	Personal data collected will not be transferred to third parties. Without
                          prejudice  to  those  third  parties  who  provide  services  to LATENCY-ESPORTS SL for the purpose of managing the provision of services, the
                          contractual and/or pre-contractual relationship with the interested parties or
                          processing requests made by them.
                      </p></td>
                  </tr>
                  <tr>
                      <td><strong>Rights of data subjects</strong></td>
                      <td><p>Rights of data subjects	Access, rectify and erasure the data, as well as other rights, as explained in the additional information.</p></td>
                  </tr>
                  <tr>
                      <td><strong>Información adicional</strong></td>
                      <td>
                          <p>
                              Additional information	You can consult the additional and detailed information on Data Protection
                              in the attached clauses that can be found in <a href="https://latencyapp.com/privacy-policy">https://latencyapp.com/privacy-policy</a>
                          </p>
                      </td>
                  </tr>
                  </tbody>
              </table>

              <h2>ADDITIONAL INFORMATION ON DATA PROTECTION</h2>


              <h2>Who is the controller for the processing of your personal data?</h2>
                <p>The personal data that you have provided us with in this and other communications maintained with you will be included in the Registries of Processing Activities of LATENCY E-SPORTS SL with postal address at Plaza de España 10, 1ºB, Las Rozas de Madrid 28231 Spain and e-mail info@latencyapp.com.</p>

              <h2>What personal data do we collect?</h2>
              <p>The personal data that the user may provide:</p>
              <ul>
                  <li>Name, address and born date.</li>
                  <li>Telephone number and email address.</li>
                  <li>Location.</li>
                  <li>Payments and deliveries information.</li>
                  <li>IP address, date and time in which you access to our services, browser and data about the operating system of your device.</li>
                  <li>Any other information or data that you decide to share with us.</li>
              </ul>
              <p>Most of the personal information we process is provided to us directly by you. In some cases, it is obligatory to fill in a form to access certain services. Not providing the personal data requested or not accepting this data protection policy means the impossibility of navigating the website.</p>

              <h2>Who is responsible for the accuracy and veracity of the data provided?</h2>
              <p>LATENCY E-SPORTS SL., is not liable for the accuracy of the data provided by their users. It is user’s liability to guarantee, in any case, the accuracy and authenticity of the personal data provided. The user agrees to provide complete and correct information. LATENCY E-SPORTS SL is exonerated from any liability in this respect and therefore does not assume any responsibility with regard to hypothetical damages that may arise from the use of said information.</p>
              <p>LATENCY E-SPORTS SL is only responsible for the veracity of its own elaboration information. LATENCY E-SPORTS SL reserves the right to update, modify or eliminate the information contained in its web pages and may even limit or deny access to said information. LATENCY E-SPORTS SL is exonerated from any responsibility for any damage or harm that the user may suffer as a result of errors, defects or omissions in the information provided by LATENCY E-SPORTS SL, provided that this comes from sources outside or publics the company.</p>

              <h2>What is the lawful basis for the processing of your data?</h2>
              <p>The processing of your data can be based on the following legal bases:</p>
              <ul>
                  <li>Express consent of the data subject. The user is informed of the possibility of withdrawing consent in the event that it has been granted for a specific purpose, without affecting the legality of the processing based on consent prior to withdrawal.</li>
                  <li>Execution of a contract or application of pre-contractual measures.</li>
                  <li>Legitimate interest for data processing.</li>
                  <li>Compliance with legal obligations.</li>
              </ul>

              <h2>What rights do you have and how can you exercise them?</h2>
              <p>Under data protection law, you have rights we need to make you aware of. You may address your communications and exercise your rights by written communication to the following email: info@latencyapp.com.</p>

              <p>Under the provisions of data protection regulations you can apply:</p>
              <ul>
                  <li><strong>Right of access: </strong>You can ask for information of those personal data that we have about you. You have the right to ask us for copies of your personal information.</li>
                  <li><strong>Right to rectification: </strong>You have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. You can communicate any change in your personal data.</li>
                  <li><strong>Right to erasure: </strong>You can request the deletion after blocking personal data.</li>
                  <li><strong>Right to restriction of processing: </strong>You have the right to ask us to restrict the processing of your information in certain circumstances.</li>
                  <li><strong>Right to object to processing: </strong>You can withdraw your consent to the processing of your data, opposing further processing.</li>
                  <li><strong>Right to portability: </strong>In some cases, you can request a copy of the personal data in a structured format, commonly used and machine-readable for transmission to another data controller.</li>
                  <li><strong>The right not to be the subject of individualised decisions: </strong>You may request that decisions not be taken which are based solely on automated processing, including profiling, which produces legal effects or significantly affects the data subject.</li>
              </ul>
              <p>In some cases, the request may be refused if you request that data necessary for the fulfilment of legal obligations be deleted. Also, if you have a complaint about the processing of data you can submit a complaint to the data protection authority.</p>

              <h2>Why do we process your data?</h2>
              <p>Depending on the purposes we will need to process some data or others, which in general will be, depending on the case, the following:</p>
              <ul>
                  <li>Manage orders or hire any of our services, either online or in the physical stores.</li>
                  <li>Manage the sending of the information requested.</li>
                  <li>Develop commercial actions and carry out the maintenance and management of the relationship with the user, as well as the management of the services offered through the website and the information tasks, being able to carry out automatic evaluations, obtaining profiles and customer segmentation tasks in order to personalize the treatment according to their characteristics and needs and improve the customer's online experience.</li>
                  <li>Develop and manage contests, raffles or other promotional activities that may be organized.</li>
                  <li>In some cases it will be necessary to provide information to Authorities or third companies for auditing purposes, as well as to handle personal data of invoices, contracts and documents to respond to customer or Public Administration complaints</li>
              </ul>

              <h2>How long do we keep your personal data for?</h2>
              <p>The processing of the data for the purposes described will be maintained for the time necessary to meet the purpose of their collection, as well as for compliance with legal obligations arising from the processing of data. Without prejudice to the fact that conservation is necessary for the formulation, exercise or defence of potential claims and/or whenever permitted by applicable legislation.</p>
              <p>LATENCY E-SPORTS SL undertakes to cease the processing of personal data at the end of the storage period and to block them in our databases.</p>

              <h2>To which recipients is your data communicated?</h2>
              <p>In general,LATENCY E-SPORTS SL will not transfer personal data to third parties, except in those situations where such data may be transferred to other collaborators who provide services toLATENCY E-SPORTS SL, for the purpose of managing the provision of these services. Likewise, any transfer, will be informed to the data subject through the consent clauses established by data protection regulations.</p>
              <p>LATENCY E-SPORTS SL ensures the security of personal data when it is sent outside the company, and ensures that third party service providers respect confidentiality and have appropriate measures in place to protect personal data. Such third parties have an obligation to ensure that the information is treated in accordance with data privacy regulations. In some cases, the law may require that personal data be disclosed to public bodies or other parties, only as strictly necessary for the fulfilment of such legal obligations.</p>

              <h2>Updates to this Notice</h2>
              <p>LATENCY E-SPORTS SL keep its privacy notice under regular review to make sure it is up to date and accurate. We recommend that you review the privacy policy from time to time.</p>

          </div>
      </Box>
    )
}

export default PrivacyPolicyEN;
