import {deviceType} from 'react-device-detect';

const initialState = deviceType

const deviceViewReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_DEVICE_VIEW':
            return state = action.payload;
        case 'UNSET_DEVICE_VIEW':
            return state = initialState
        default:
            return state ? state : initialState
    }
}

export default deviceViewReducer;