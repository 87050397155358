import React, {useEffect, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const NewDescriptionBox = ({description, setDescription}) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const {t} = useTranslation(['eventForm']);
    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {
        const input = document.querySelector(
            "input[data-link]"
        );
        input.dataset.link = "https://www.latencyapp.com/";
        input.placeholder = "https://www.latencyapp.com/";

    }, []);

    return (
        <Box className={`description-box`}>
            <ReactQuill
                className={`${(darkTheme ? "is-input-dark" : "is-input-light")} ${isFocus ? "is-focus" : ""}`}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                autocorrect="off"
                theme="snow"
                value={description}
                onChange={setDescription}
                placeholder={t('eventForm:forms_description_placeholder')}
                modules={{
                    toolbar: [
                        ['link', 'bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                    ],
                }}
                formats={["bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "link"]}
                bounds=".description-box"
            />
        </Box>
    );
};

export default NewDescriptionBox;
