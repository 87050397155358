import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {useSelector, useDispatch} from "react-redux";
import services from "../../../../services";
import {logout} from "../../../../store/user/actions";


const AdvancedDeactivateAccountForm = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user);
    const darkTheme = useSelector(state => state.darkTheme);
    const [check, setCheck] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const { t } = useTranslation(['settings']);

    const RedCheckbox = withStyles({
        root: {
            color: darkTheme ? 'white' : '#23304B',
            '&$checked': {
                color: '#D75454',
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);


    const handleConfirmDelete = () => {
        services.users.remove(currentUser.id, null).then((res) => {
            dispatch(logout())
        })
    }

    return (
        <div>
            <div className="settings-content-main settings-content-deactivate-account">
                <div className={"warn-text"}>
                    <div>{t('section_advanced_deactivate_account_firstline')}</div>
                    <div>{t('section_advanced_deactivate_account_secondline')}</div>
                    <div>{t('section_advanced_deactivate_account_thirdline')}</div>
                </div>
                <div className={"help-text"}>{t('section_advanced_deactivate_account_warning')}</div>
                <div className={"action-btn"}>
                    <div className={"wrap-check"} onClick={() => setCheck(!check)}>
                        <RedCheckbox
                            checked={check}
                            icon={<CheckBoxOutlineBlankIcon fontSize="large"/>}
                            checkedIcon={<CheckBoxIcon fontSize="large"/>}
                            name="check-deactivate-acc"
                            color="primary"
                        />
                        <span className={`text-check ${check ? ' is-checked' : ''}`}>Entiendo las consecuencias de esta acción y estoy de acuerdo en iniciar el proceso de desactivación de mi cuenta.</span>
                    </div>
                    {check &&
                    <a className="custom-button delete-btn large"
                       onClick={() => setShowDialog(true)}>Desactivar mi cuenta</a>}
                </div>
            </div>

            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('advanced_deactivate_account_dialog_erase_event_headtitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"
                                       className={"dialog-description " + (darkTheme ? ' is-dark' : ' is-light')}>
                        {t('advanced_deactivate_account_dialog_erase_event_text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDialog(false)} color="primary">
                        {t('advanced_deactivate_account_dialog_erase_event_cancel_btn')}
                    </Button>
                    <Button onClick={() => {
                        handleConfirmDelete();
                        setShowDialog(false);
                    }} color="primary" autoFocus>
                        {t('advanced_deactivate_account_dialog_erase_event_confirm_btn')}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default AdvancedDeactivateAccountForm;
