import React from 'react';
import { isFuture } from 'date-fns';
import {useSelector, useDispatch} from 'react-redux';
import {Box} from '@material-ui/core';
import PingIconOff from '../../../assets/icons/actions/ping-off.svg';
import PingIconOn from '../../../assets/icons/actions/ping-on.svg';
import AgendaIconOff from '../../../assets/icons/actions/agenda-off-colored.svg';
import AgendaIconOn from '../../../assets/icons/actions/agenda-on.svg';
import BellIconOn from '../../../assets/icons/actions/bell-on.svg';
import BellIconOff from '../../../assets/icons/actions/bell-off-grey.svg';
import ArrowUpward from '../../../assets/images/icons8-downward-arrow-24.png';
import {showSnackbar} from '../../../store/snackbar/actions';
import services from '../../../services';
import {setOpenedEvent} from '../../../store/openedEvent/actions';
import {useTranslation} from 'react-i18next';
import {setAgendaWeeklyEvents} from '../../../store/agendaWeeklyEvents/actions';
import AdvancedButton from "../../Buttons/AdvancedButton/AdvancedButton";
import abbreviate from "number-abbreviate";
import {Animated} from "react-animated-css";
import TutorialSubEvent from "../../Tutorials/TutorialSubEvent";
import {tutorialActionsSubEvent} from "../../../store/tutorials/actions";

const NewEventActionButtons = ({tutorials, refTutorialOverlayEl}) => {
    const event = useSelector(state => state.event);
    const user = useSelector(state => state.user);
    const selectedDate = useSelector(state => state.selectedDate);
    const dispatch = useDispatch();
    const {t} = useTranslation(['extraData', 'eventForm']);

    const pingEvent = () => {
        if (user.id) {
            services.ping.create({eventId: event.id})
                .then(() => {
                    dispatch(setOpenedEvent(event.id))
                    if (window.history.state && window.history.state.calendar === true) {
                        dispatch(setAgendaWeeklyEvents(selectedDate))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(showSnackbar({message: t("extraData:default_error"), type: "error"}))
                })
        } else {
            dispatch(showSnackbar({message: t("extraData:must_be_registered"), type: "error"}))
        }
    }

    const unpingEvent = () => {
        if (user.id) {
            services.ping.remove(null, {query: {eventId: event.id}})
                .then(() => {
                    dispatch(setOpenedEvent(event.id))
                    if (window.history.state && window.history.state.calendar === true) {
                        dispatch(setAgendaWeeklyEvents(selectedDate))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(showSnackbar({message: t("extraData:default_error"), type: "error"}))
                })
        } else {
            dispatch(showSnackbar({message: t("extraData:must_be_registered"), type: "error"}))
        }
    }

    const subscribeEvent = () => {
        if (user.id) {
            let params = {eventId: event.id, is_unsubscribed: false}
            services.subscribe.create(params)
                .then(() => {
                    dispatch(setOpenedEvent(event.id))
                    if (window.history.state && window.history.state.calendar === true) {
                        dispatch(setAgendaWeeklyEvents(selectedDate))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(showSnackbar({message: t("extraData:default_error"), type: "error"}))
                })
        } else {
            dispatch(showSnackbar({message: t("extraData:must_be_registered"), type: "error"}))
        }
    }

    const unSubscribeEvent = () => {
        if (user.id) {
            let params = {eventId: event.id, is_unsubscribed: true}
            services.subscribe.create(params)
                .then(() => {
                    dispatch(setOpenedEvent(event.id))
                    if (window.history.state && window.history.state.calendar === true) {
                        dispatch(setAgendaWeeklyEvents(selectedDate))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(showSnackbar({message: t("extraData:default_error"), type: "error"}))
                })
        } else {
            dispatch(showSnackbar({message: t("extraData:must_be_registered"), type: "error"}))
        }
    }

    const notifyEvent = () => {
        if (user.id) {
            let params = {eventId: event.id, is_notificable: true}
            services.subscribe.create(params)
                .then(() => {
                    dispatch(tutorialActionsSubEvent(false))
                    dispatch(setOpenedEvent(event.id))
                    if (window.history.state && window.history.state.calendar === true) {
                        dispatch(setAgendaWeeklyEvents(selectedDate))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(showSnackbar({message: t("extraData:default_error"), type: "error"}))
                })
        } else {
            dispatch(showSnackbar({message: t("extraData:must_be_registered"), type: "error"}))
        }
    };

    const unnotifyEvent = () => {
        if (user.id) {
            let params = {eventId: event.id, is_notificable: false}
            services.subscribe.create(params)
                .then(() => {
                    dispatch(setOpenedEvent(event.id))
                    if (window.history.state && window.history.state.calendar === true) {
                        dispatch(setAgendaWeeklyEvents(selectedDate))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(showSnackbar({message: t("extraData:default_error"), type: "error"}))
                })
        } else {
            dispatch(showSnackbar({message: t("extraData:must_be_registered"), type: "error"}))
        }
    };

    return (
        <Box className="event-btn-actions">
            {event.id && event.hasOwnProperty('isSubscribe') && (
                <>
                    {user && user.hasOwnProperty('id') && tutorials.tutorial_sub_event && !event.isNotifiable && isFuture(new Date(event.start)) ? (
                        <>
                            <Animated className="suscriptor-tutorial-actionable" animationIn="tada" animationInDelay={100} animationInDuration={1000} isVisible={true} style={{
                                animationIterationCount: 'infinite'
                            }}>
                                <AdvancedButton
                                    type="outline"
                                    className={event.isNotifiable ? "enabled-icon" : "disabled-icon"}
                                    onClick={event.isNotifiable ? unnotifyEvent : notifyEvent}
                                    label={event.isNotifiable ? t('extraData:event_btn_enabled_notifications') : t('extraData:event_btn_disabled_notifications')}
                                    iconRight={event.isNotifiable ? BellIconOn : BellIconOff}
                                    status={event.isNotifiable}
                                />
                            </Animated>
                            <TutorialSubEvent refTutorialOverlayEl={refTutorialOverlayEl} />
                        </>
                    ) : (
                        <AdvancedButton
                            type="outline"
                            className={event.isNotifiable ? "enabled-icon" : "disabled-icon"}
                            onClick={event.isNotifiable ? unnotifyEvent : notifyEvent}
                            label={event.isNotifiable ? t('extraData:event_btn_enabled_notifications') : t('extraData:event_btn_disabled_notifications')}
                            iconRight={event.isNotifiable ? BellIconOn : BellIconOff}
                            status={event.isNotifiable}
                        />
                    )}

                </>
            )}

            {(user && user.id !== event.owner.id) && (
                <AdvancedButton
                    type="outline"
                    className={event.isSubscribe ? "enabled-icon" : "disabled-icon"}
                    onClick={event.isSubscribe ? unSubscribeEvent : subscribeEvent}
                    label={event.isSubscribe ? t('extraData:event_btn_remove_agenda') : t('extraData:event_btn_add_agenda')}
                    iconRight={event.isSubscribe ? AgendaIconOn : AgendaIconOff}
                    status={!event.isSubscribe}
                />
            )}

            {event.id && event.hasOwnProperty('isPing') && (
                <AdvancedButton
                    type="outline"
                    onClick={event.isPing ? unpingEvent : pingEvent}
                    label={(event.assistants && event.assistants.length > 0 ? abbreviate(event.assistants.length, 1) : '') + ' ' + (!event.isPing ? t('extraData:event_btn_is_reping') : t('extraData:event_btn_remove_reping'))}
                    iconRight={event.isPing ? PingIconOn : PingIconOff}
                    status={!event.isPing}
                />
            )}
        </Box>
    )
}

export default NewEventActionButtons;
