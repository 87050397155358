import React, {useEffect, useMemo, useState} from 'react';
import {Box} from "@material-ui/core";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {Autoplay, Pagination, Navigation} from 'swiper';
import Lottie from "react-lottie-player";
import Skeleton from "@material-ui/lab/Skeleton";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import services from "../../services";
import {closeEventInfoModal} from "../../store/eventInfoModal/actions";
import CategoryFeaturedUserItemComponent from "./CategoryFeaturedUserItemComponent";
import animationData from "../../assets/animations/warning-yellow.json";
import './_category_featured_users_component.scss';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const CategoryFeaturedUsersComponent = ({ catId = 9 }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isErrorFetching, setErrorFetching] = useState(false);

    useEffect(() => {
        services.exploreLight.get(catId).then((res) => {
            let ordered = res.sort((a, b) => a.weight < b.weight ? 1 : -1);
            setItem(ordered);
            setLoading(false);
            setErrorFetching(false);
        }).catch(err => {
            setLoading(true);
            setErrorFetching(true);
        });
    }, [catId]);

    const onOpenProfile = (user) => {
        history.push(`/${user.slug}`);
        dispatch(closeEventInfoModal());
    }

    const RenderItems = useMemo(() =>
            [...item].map((item) => (
                <SwiperSlide key={`explore-user-item-${item.id}`}>
                    <CategoryFeaturedUserItemComponent onClick={onOpenProfile} user={item}/>
                </SwiperSlide>)
            )
        , [darkTheme, item])

    return (
        <Box className="category-featured-users--main">
            {isErrorFetching && (
                <div className="category-featured-users--main--handling-err">
                    <Lottie
                        loop={true}
                        animationData={animationData}
                        play
                        style={{width: 200, height: 'auto'}}
                    />
                    <span>ERROR FETCHING</span>
                </div>
            )}
            {loading &&
                <Box className='category-featured-users--main-skeleton'>
                    {([0, 1, 2, 3].map(item => <Skeleton key={item} width={"100%"} height={148} variant="rect"
                                                         className={(darkTheme ? 'dark' : 'light')}/>))}
                </Box>
            }

            {!loading && (
                <Swiper
                    slidesPerView={deviceView === 'browser' || deviceView === 'tablet' ? 4 : 1}
                    className="mySwiper swiperCats"
                    loop={false}
                    speed={1500}
                    pagination={{
                        "clickable": true
                    }}
                    slidesPerColumn={catId ===  14 ? 2 : 1}
                    slidesPerGroup={2}
                    slidesPerColumnFill="row"
                    navigation={true}
                    spaceBetween={20}
                    autoplay={{
                        "delay": 2000
                    }}>
                    {RenderItems}
                </Swiper>
            )}


        </Box>
    );
};

export default CategoryFeaturedUsersComponent;
