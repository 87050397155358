import React, {useState, useEffect} from 'react';
import './user-card.scss';
import {Box} from '@material-ui/core';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { followUser, unfollowUser } from '../../../store/app/actions';
import CustomButton from '../../CustomButton/CustomButton';
import { closeEventInfoModal } from '../../../store/eventInfoModal/actions';
import {useTranslation} from "react-i18next";
import abbreviate from 'number-abbreviate';

const UserCard = (props) => {
    const {profile, interestsCount, toProfile, group, colored, recommendations, portfolio, eventInfo, displayed, onClickLeaveEvent, displayActionButtons, handleOnGoToProfile} = props
    const [follow, setFollow] = useState(profile.follow)
    const [loading, setLoading] = useState(false)
    const [ints, setInts] = useState([])
    const user = useSelector(state => state.user)
    const loggedIn = useSelector(state => state.loggedIn)
    const history = useHistory()
    const dispatch = useDispatch()
    const {t} = useTranslation(['userBlock'])
    const darkTheme = useSelector(state => state.darkTheme);
    let isEmbedMode = useRouteMatch("/embed/:userNickname/:eventID?");

    useEffect(() => {
        setFollow(loggedIn ? profile.follow : false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.follow, loggedIn])


    useEffect(() => {
        if(user.id && user.interests && user.interests.length > 0){
            if(interestsCount === 0){
                setInts([]);
                return;
            }
            let ii = []
            let limit = interestsCount ? interestsCount : 2
            for(let a=0; a<user.interests.length; a++){
                if(ii.length === limit){
                    break
                }
                if(user.interests[a].text.length <= 8){
                    ii.push(user.interests[a].text)
                }
            }
            setInts(ii)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.following])

    const redirect = () => {
        history.push('/login')
    }

    const getType = () => {
        if(recommendations){
            return "recommendations"
        }
        if(portfolio){
            return "portfolio"
        }
        if(eventInfo){
            if(window.history.state && window.history.state.calendar === true){
                return "agenda-event"
            }
            else {
                return "profile-event"
            }
        }

        return "event-guest"
    }

    const goToProfile = () => {
        if(handleOnGoToProfile) {
            handleOnGoToProfile();
        }
        if(isEmbedMode){
            window.open(`https://latencyapp.com/${profile.slug}`, '_blank');
        }else{
            history.push(`/${profile.slug}`)
            dispatch(closeEventInfoModal())
        }
    }

    return (
        <Box className={"user-card " + ((group && colored) ? "colored" : "") + (recommendations ? " is-box-recommendations" : "")} display="flex" flexDirection="row" bgcolor={group ? "grid.main" : "background.default"}>
            {toProfile && <img onClick={goToProfile} src={profile.avatar ? profile.avatar : "/images/defaultAvatar.png"} className="avatar" alt={profile.nickname} />}
            {!toProfile && <img src={profile.avatar ? profile.avatar : "/images/defaultAvatar.png"} className="avatar" alt={profile.nickname} />}
            <Box className="user-card-content" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                {profile.nickname && <span className="name">{profile.nickname} </span>}
                {!colored &&
                    <Box>
                        {profile.interests && profile.interests.length > 0 &&
                            ints.map((item, index) =>
                                <span key={index} className="user-int">{item} </span>
                            )
                        }
                        {!recommendations && (<span className="else" dangerouslySetInnerHTML={{ __html: t("userBlock:texts_followers", {count: profile.followingCount ? abbreviate(profile.followingCount, 1) : 0})}}/>)}
                        <span className="else" dangerouslySetInnerHTML={{ __html: t("userBlock:texts_following", {count: profile.followersCount ? abbreviate(profile.followersCount, 1) : 0})}}/>
                    </Box>
                }
            </Box>
            {displayActionButtons &&
                <>
                    {!group && user.id !== profile.id &&
                    <Box className="user-card-action">
                        {!isEmbedMode && !follow &&
                        <CustomButton
                            loading={loading}
                            onClick={() => {
                                setLoading(true)
                                dispatch(followUser(profile, redirect, getType(), setFollow, setLoading))
                            }}
                            className="follow-btn">{t("userBlock:buttons_follow")}</CustomButton>
                        }
                        {!isEmbedMode && follow &&
                        <CustomButton
                            loading={loading}
                            onClick={() => {
                                setLoading(true)
                                dispatch(unfollowUser(profile, redirect, getType(), setFollow, setLoading))
                            }}
                            className="unfollow-btn">{t("userBlock:buttons_unfollow")}</CustomButton>
                        }
                    </Box>
                    }
                    {!group && user.id === profile.id && displayed === "event-info-guests" && (
                        <Box className="user-card-action">
                            <CustomButton
                                loading={loading}
                                onClick={onClickLeaveEvent}
                                className={`leave-event-guest-btn ${(darkTheme ? ' is-dark' : 'is-light')}`}>{t("userBlock:abandon")}</CustomButton>
                        </Box>
                    )}
                    {group && colored && <CloseIcon className="close-icon" onClick={() => props.onRemove(profile)} />}
                </>
            }
        </Box>
    )
}

UserCard.defaultProps = {
    displayActionButtons: true
}

export default UserCard;
