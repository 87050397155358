import ImageCropper from "../../../../ImageCropper/ImageCropper";
import Modal from "../../../../Modal/Modal";
import React from "react";
import {useSelector} from "react-redux";

const LandingConfigurationFormCropperModal = ({open, close, width, height, poster, onCrop}) => {
    const deviceView = useSelector(state => state.deviceView);

    return (
        <Modal
            state={open}
            persist={true}
            fullScreen={deviceView === "mobile"}
            onClose={close}
            max={"lg"}
            radius={false}>
            <ImageCropper
                className="header"
                round={false}
                cropper={{width: width, height: height}}
                poster={poster}
                onCrop={onCrop}
                onClose={close}/>
        </Modal>
    );
};

export default LandingConfigurationFormCropperModal;
