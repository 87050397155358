import React, {useState, Fragment} from 'react';
import './edit-profile.scss'
import {Container, Grid, Box, Button} from '@material-ui/core';
import EditProfileForm from '../../components/Forms/EditProfileForm/EditProfileForm';
import services from '../../services';
import {useSelector} from 'react-redux';
import {refreshUser} from '../../store/user/actions';
import {useDispatch} from 'react-redux';
import {showSnackbar} from '../../store/snackbar/actions';
import Modal from '../../components/Modal/Modal';
import ImageCropper from '../../components/ImageCropper/ImageCropper';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import {useTranslation} from "react-i18next";
import CircleAdd from "../../assets/icons/actions/add_event_circle.svg";
import ClearIcon from '@material-ui/icons/Clear';
import {Helmet} from "react-helmet";

const EditProfile = (props) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [profileDisplay, setProfileDisplay] = useState("");
    const [headerDisplay, setHeaderDisplay] = useState("");
    const [avatarCropper, setAvatarCropper] = useState(false);
    const [headerCropper, setHeaderCropper] = useState(false);
    const [loading, setLoading] = useState(false);
    const deviceView = useSelector(state => state.deviceView);
    const [posterChanged, setPosterChanged] = useState(false);
    const [posterCropper, setPosterCropper] = useState(false);
    const [posterDisplay, setPosterDisplay] = useState(user.default_poster);
    const [originalPosterDisplay, setOriginalPosterDisplay] = useState(user.default_original_poster);
    const [preHeaderDisplay, setPreHeaderDisplay] = useState('');
    const [preProfileDisplay, setPreProfileDisplay] = useState('');
    const [prePosterDisplay, setPrePosterDisplay] = useState('');
    const [preOriginalPosterDisplay, setPreOriginalPosterDisplay] = useState('');
    const darkTheme = useSelector(state => state.darkTheme);
    const {t} = useTranslation(['userEditForm', 'extraData']);


    const avatarChange = (e) => {
        let file = e.target.files[0];
        var reader = new FileReader();
        if (file.type === "image/gif" || file.type === "image/jpeg" || file.type === "image/png") {
            if (file.type === "image/jpeg" || file.type === "image/png") {
                setPreProfileDisplay(file)
                reader.onloadend = function () {
                    setPreProfileDisplay(reader.result)
                };
                setAvatarCropper(true)
            } else {
                setProfileDisplay(file)
                reader.onloadend = function () {
                    setProfileDisplay(reader.result)
                };
            }
            reader.readAsDataURL(file);
        } else {
            dispatch(showSnackbar({message: t("userEditForm:invalid_format"), type: "error"}));
        }
    };

    const headerChange = (e) => {
        let file = e.target.files[0];
        var reader = new FileReader();
        if (file.type === "image/gif" || file.type === "image/jpeg" || file.type === "image/png") {
            if (file.type === "image/jpeg" || file.type === "image/png") {
                setPreHeaderDisplay(file)
                reader.onloadend = function () {
                    setPreHeaderDisplay(reader.result)
                };
                setHeaderCropper(true)
            } else {
                setHeaderDisplay(file)
                reader.onloadend = function () {
                    setHeaderDisplay(reader.result)
                };
            }
            reader.readAsDataURL(file);
        } else {
            dispatch(showSnackbar({message: t("userEditForm:invalid_format"), type: "error"}));
        }
    };

    const saveData = (data, e) => {
        e.preventDefault();
        setLoading(true);
        if (profileDisplay) {
            data.avatar = profileDisplay
        }
        if (headerDisplay) {
            data.header = headerDisplay
        }
        if (posterChanged) {
            if (posterDisplay === "/images/defaultHeader2.png") {
                data.default_poster = "";
                data.default_original_poster = "";
            } else {
                data.default_poster = posterDisplay;
                data.default_original_poster = originalPosterDisplay;
            }
        }
        services.users.patch(user.id, data, null)
            .then((resp) => {
                dispatch(refreshUser(user.id));
                dispatch(showSnackbar({message: t("userEditForm:snackbar_profile_saved"), type: "success"}));
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                dispatch(showSnackbar({message: error.message, type: "error"}));
                setLoading(false);
            });
    };

    const posterChange = (e) => {
        let file = e.target.files[0];
        var reader = new FileReader();
        if (file.type === "image/gif" || file.type === "image/jpeg" || file.type === "image/png") {
            if (file.type === "image/jpeg" || file.type === "image/png") {
                reader.onloadend = function () {
                    setPrePosterDisplay(reader.result);
                    setPreOriginalPosterDisplay(reader.result);
                }
                setPosterCropper(true)
            }
            if (file.type === "image/gif") {
                reader.onloadend = function () {
                    setPosterDisplay(reader.result);
                    setOriginalPosterDisplay(reader.result);
                    setPosterChanged(true);
                }
            }
            reader.readAsDataURL(file);
        } else {
            dispatch(showSnackbar({message: t("userEditForm:invalid_format"), type: "error"}));
        }
    }

    return (
        <Container maxWidth="xl">
            <Grid container className="edit-profile">
                <Helmet>
                    <title>{t('headings_editProfile')} / Latency</title>
                </Helmet>
                <Grid item xs={12} className="header">
                    <img src={headerDisplay ? headerDisplay : user.header} alt="latency" className="header-img"/>
                    <Box className="head-up-mobile">
                        <label htmlFor="header-image"><AddAPhotoIcon className="icon"/></label>
                        <input type="file" id="header-image" accept="image/gif, image/jpeg, image/png"
                               onChange={headerChange}/>
                    </Box>
                    <Box className="head-up-laptop">
                        <label htmlFor="header-image">{t('userEditForm:upload_header')}</label>
                        <input type="file" id="header-image" accept="image/gif, image/jpeg, image/png"
                               onChange={headerChange}/>
                    </Box>
                </Grid>
                {(deviceView === "tablet" || deviceView === "browser") &&
                <Fragment>
                    <Grid item xs={12} sm={3} md={3} lg={3} container direction="column" justifyContent="flex-start"
                          alignItems="center">
                        <Box className="profile">
                            <img
                                src={profileDisplay ? profileDisplay : (user.avatar ? user.avatar : "/images/defaultAvatar.png")}
                                alt="latency" className="profile-img"/>
                            <Box className="profile-up-mobile">
                                <label htmlFor="profile-image"><AddAPhotoIcon className="icon"/></label>
                                <input type="file" id="profile-image" accept="image/gif, image/jpeg, image/png"
                                       onChange={avatarChange}/>
                            </Box>
                            <Box className="profile-up-laptop">
                                <label htmlFor="profile-image">{t('userEditForm:upload_profile')}</label>
                                <input type="file" id="profile-image" accept="image/gif, image/jpeg, image/png"
                                       onChange={avatarChange}/>
                            </Box>
                        </Box>

                        <Box className="poster-row">
                            <p
                                className={"edit-profile-section-title" + (darkTheme ? " isDark" : " isLight")}>{t('userEditForm:forms_title_default_poster')}</p>
                            <Box bgcolor="grid.main" className={'user-default-poster'} display="flex"
                                 flexDirection="column" justifyContent="center" alignItems="center">

                                {originalPosterDisplay && originalPosterDisplay.length > 0 &&
                                <Box className="bg-poster" style={{
                                    backgroundImage: `url(${originalPosterDisplay})`,
                                }}></Box>}

                                <Box className="group-poster">
                                    {(posterDisplay) && <img className="poster-display"
                                                             src={posterDisplay ? posterDisplay : user.default_poster}
                                                             alt=""/>}

                                    <Box className={posterDisplay ? "absolute" : "upload-icon"} display="flex"
                                         flexDirection="column" justifyContent="center" alignItems="center">
                                        <label id="add-event-image-label" htmlFor="add-event-image"><img src={CircleAdd}
                                                                                                         alt="latency"/></label>
                                        <input type="file" id="add-event-image"
                                               accept="image/gif, image/jpeg, image/png" onChange={posterChange}/>
                                        <p>{t('extraData:buttons_upload_poster_event')}</p>
                                    </Box>
                                </Box>
                            </Box>
                            <Button
                                onClick={() => {
                                    setPosterDisplay("/images/defaultHeader2.png");
                                    setPosterChanged(true);
                                }}
                                startIcon={<ClearIcon/>}
                            >{t('userEditForm:forms_title_default_poster_remove')}</Button>
                        </Box>
                    </Grid>
                </Fragment>
                }
                {deviceView === "mobile" &&
                <Fragment>
                    <Grid item xs={12} sm={3} md={3} lg={3} container direction="column" justifyContent="center"
                          alignItems="center">
                        <Box className="profile">
                            <img
                                src={profileDisplay ? profileDisplay : (user.avatar ? user.avatar : "/images/defaultAvatar.png")}
                                alt="latency" className="profile-img"/>
                            <Box className="profile-up-mobile">
                                <label htmlFor="profile-image"><AddAPhotoIcon className="icon"/></label>
                                <input type="file" id="profile-image" accept="image/gif, image/jpeg, image/png"
                                       onChange={avatarChange}/>
                            </Box>
                        </Box>
                    </Grid>
                </Fragment>
                }
                <Grid item xs={12} sm={9} md={8} lg={8}>
                    <EditProfileForm
                        loading={loading}
                        user={user}
                        posterDisplay={posterDisplay}
                        onPosterChange={posterChange}
                        onSubmit={saveData}/>
                    <Modal
                        state={avatarCropper}
                        persist={true}
                        max={"sm"}
                        radius={false}
                        fullScreen={deviceView === "mobile"}
                        onClose={() => setAvatarCropper(false)}>

                        <ImageCropper
                            round={true}
                            cropper={{
                                width: 384,
                                height: 384
                            }}
                            poster={preProfileDisplay}
                            className="profile"
                            onCrop={(img) => {
                                setProfileDisplay(img);
                            }}
                            onClose={() => {
                                setAvatarCropper(false);
                            }}/>
                    </Modal>
                    <Modal
                        state={headerCropper}
                        persist={true}
                        max={"lg"}
                        radius={false}
                        fullScreen={deviceView === "mobile"}
                        onClose={() => setHeaderCropper(false)}>

                        <ImageCropper
                            cropper={deviceView === "browser" ? {
                                width: 1050,
                                height: 222.457
                            } : (deviceView === "tablet" ? {width: 500, height: 105.93} : {width: 350, height: 74.15})}
                            poster={preHeaderDisplay}
                            className="header"
                            onCrop={(img) => setHeaderDisplay(img)}
                            onClose={() => {
                                setHeaderCropper(false);
                            }}/>
                    </Modal>
                    <Modal
                        state={posterCropper}
                        persist={true}
                        max={"lg"}
                        radius={false}
                        fullScreen={deviceView === "mobile"}
                        onClose={() => setPosterCropper(false)}>

                        <ImageCropper
                            cropper={deviceView === "browser" ? {
                                width: 480,
                                height: 680
                            } : (deviceView === "tablet" ? {width: 220, height: 350} : {width: 170, height: 250})}
                            poster={prePosterDisplay}
                            className="add-event"
                            bg={preOriginalPosterDisplay}
                            onCrop={(img) => {
                                setPosterDisplay(img);
                                setOriginalPosterDisplay(preOriginalPosterDisplay);
                                setPosterChanged(true);
                            }}
                            onClose={() => {
                                setPosterCropper(false);
                            }}/>
                    </Modal>
                </Grid>
            </Grid>
        </Container>
    )
}

export default EditProfile;
