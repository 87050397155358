export const setSelectedDateCalendar = (data) => {
    return dispatch => {
        dispatch({type: 'SET_SELECTED_DATE_CALENDAR', payload: data})
    }
}

export const unsetSelectedDateCalendar = () => {
    return dispatch => {
        dispatch({type: 'UNSET_SELECTED_DATE_CALENDAR'})
    }
}
