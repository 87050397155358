import React from "react";
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import {ReactComponent as NounCheckIcon} from "../assets/icons/achievements/noun-check.svg";



export const archivementStatusPreValues = [
    {
        'id': '61ab03f7-af43-4257-a6e5-43524babf7ab',
        'label': 'Completed',
        'text_en': 'Completed',
        'text_es': 'Completado',
        'icon': <NounCheckIcon className="completed-icon" />
    },
    {
        'id': '60a732ba-e53c-4b3f-92f4-36c354e2fcce',
        'label': 'Pending',
        'text_en': 'Pending',
        'text_es': 'Pendiente',
        'icon': <WatchLaterIcon />
    }
];
