import React, {useEffect, useState} from "react";
import {Box, Switch} from "@material-ui/core";
import "./general-tutorials-form.scss";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {tutorialActionsSubEvent} from "../../../../store/tutorials/actions";

const GeneralTutorialsForm = () => {
    const {t} = useTranslation('settings');
    const tutorials = useSelector(state => state.tutorials);

    const dispatch = useDispatch();

    const changeTutorialSubEvent = () => {
        dispatch(tutorialActionsSubEvent(!tutorials.tutorial_sub_event))
    };

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_general_tutorials')}</div>
                <div className="settings-content-title-helper">{t('section_general_tutorials_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-general settings-content-display">
                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                    <Switch checked={tutorials.tutorial_sub_event} onChange={changeTutorialSubEvent} className="switch"/>
                    <span>{t('section_general_tutorials_sub_event')}</span>
                </Box>
            </div>
        </div>
    );
};

export default GeneralTutorialsForm;
