import React, {useEffect, useState} from 'react';
import './navbar.scss';
import {Box, Drawer, Grid} from '@material-ui/core';
import Logo from '../Logo/Logo';
import MenuItems from './MenuItems';
import UserMenu from './UserMenu';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import NavbarSearchForm from "../Forms/NavbarSearchForm/NavbarSearchForm";
import {useSelector, useDispatch} from 'react-redux';
import EventInfo from '../Event/EventInfo/EventInfoNew';
import {closeEventInfoModal} from '../../store/eventInfoModal/actions';
import Modal from '../Modal/Modal';
import InterestsForm from '../Forms/InterestsForm/InterestsForm';
import {closeInterestsModal} from '../../store/interestsModal/actions';
import {closeAddEventModal} from '../../store/addEventModal/actions';
import Snackbar from '../Snackbar/Snackbar';
import {unsetOpenedEvent} from '../../store/openedEvent/actions';
import MenuIcon from '@material-ui/icons/Menu';
import CustomLink from '../CustomLink/CustomLink';
import ThemeToggle from './ThemeToggle';
import CloseIcon from '@material-ui/icons/Close';
import {closeCompleteSignupModal} from "../../store/completeSignupModal/actions";
import CompleteSignupForm from "../Forms/CompleteSignupForm/CompleteSignupForm";
import {changeDarkTheme} from "../../store/darkTheme/actions";
import {closeAddAccountModal} from "../../store/addNewAccountModal/actions";
import AddNewAccountForm from "../ManageAccounts/AddNewAccountForm";
import {closeManageMultiAccountModal} from "../../store/manageMultiAccountModal/actions";
import ManageMultiAccountForm from "../ManageAccounts/ManageMultiAccountForm";
import {useTranslation} from "react-i18next";
import TrendItems from "./TrendItems";
import NewEventInfo from "../Event/EventInfo/NewEventInfo";
import AddEventFormComponent from "../Forms/NewAddEventForm/AddEventFormComponent";
import DiscoverForm from "../Forms/DiscoverForm/DiscoverForm";
import {closeDiscoverUsersModal, openDiscoverUsersModal} from "../../store/discoverUsersModal/actions";

const Navbar = () => {
    const user = useSelector(state => state.user);
    const loggedIn = useSelector(state => state.loggedIn);
    const dispatch = useDispatch();
    const discoverModal = useSelector(state => state.discoverUsersModal);
    const interestsModal = useSelector(state => state.interestsModal);
    const openedProfile = useSelector(state => state.openedProfile);
    const eventInfoModal = useSelector(state => state.eventInfoModal);
    const addEventModal = useSelector(state => state.addEventModal);
    const completeSignupModal = useSelector(state => state.completeSignupModal);
    const addNewAccountModal = useSelector(state => state.addNewAccountModal);
    const manageMultiAccountModal = useSelector(state => state.manageMultiAccountModal);
    const [drawer, setDrawer] = useState(false);
    const calendar = useRouteMatch('/calendar')
    const darkTheme = useSelector(state => state.darkTheme)
    const deviceView = useSelector(state => state.deviceView)
    const [byPassEventInfoModal, setClosingModal] = useState(eventInfoModal);
    const history = useHistory();
    const {t} = useTranslation(['UserMenu', 'footer']);
    let isEmbedMode = useRouteMatch("/embed/:userNickname/:eventID?");

    useEffect(() => {
        setClosingModal(eventInfoModal);
    }, [eventInfoModal]);

    useEffect(() => {
        return history.listen(location => {
            if (eventInfoModal) {
                setClosingModal(false);
                dispatch(closeEventInfoModal());
            }
        })
    }, [eventInfoModal, history]);

    const closeInfo = () => {
        if (!byPassEventInfoModal) {
            return;
        }
        setClosingModal(false);
        dispatch(closeEventInfoModal())
        if (window.history.state && window.history.state.calendar === true) {
            //window.history.pushState({}, "calendar", `/calendar`)
            history.push("/agenda");
        } else if (window.history.state && window.history.state.explore === true) {
            history.push("/explore");
        } else if (window.history.state && window.history.state.embed === true) {
            history.push(`/embed/${openedProfile.slug}`);
        } else if (window.history.state && window.history.state.hasOwnProperty('backRef')) {
            history.push(`${window.history.state.backRef}`);
        } else {
            history.push(`/${openedProfile.slug}`);
        }
        setClosingModal(false);
        dispatch(unsetOpenedEvent())
    }

    const closeAddEvent = () => {
        dispatch(closeAddEventModal())
    }

    const closeInterests = () => {
        dispatch(closeInterestsModal())
    }

    const closeCompleteSignupForm = () => {
        dispatch(closeCompleteSignupModal());
        if (user.followingCount === 0) {
            dispatch(openDiscoverUsersModal());
        }
    }

    const finishDiscoverUsersModal = () => {
        dispatch(closeDiscoverUsersModal());
    }

    const closeNewAccountModal = () => {
        dispatch(closeAddAccountModal());
    }

    const closeManageMultiAccount = () => {
        dispatch(closeManageMultiAccountModal());
    }


    if (isEmbedMode) {
        dispatch(changeDarkTheme(true))
    }

    return (
        <Box style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
        }} bgcolor="header.main">
            {!isEmbedMode && (
                <>
                    <Box className="navbar" display="flex" flexDirection="row" justifyContent="space-between"
                         alignItems="center">
                        <Box className="nav-logo-box" display="flex" flexDirection="row" justifyContent="flex-start"
                             alignItems="center">
                            {loggedIn && (deviceView === "tablet" || deviceView === "mobile") &&
                            <MenuIcon onClick={() => setDrawer(true)} className="burger-btn"/>}
                            <Link to="/agenda"><Logo
                                className={deviceView === "mobile" ? "mobile-logo" : "navbar-logo"}/></Link>
                        </Box>

                        {(deviceView === "tablet" || deviceView === "browser") &&
                        <Box className="search-box">
                            <NavbarSearchForm/>
                        </Box>
                        }

                        <Box className="menu-box">
                            {loggedIn ? <UserMenu/> : <MenuItems/>}
                        </Box>
                        {loggedIn && (deviceView === "tablet" || deviceView === "mobile") &&
                        <Drawer open={drawer} onClose={() => setDrawer(!drawer)}
                                classes={{paper: ("drawer-menu " + (darkTheme ? "dark" : "light"))}}>
                            <Box mb={3} minWidth="100%" display="flex" flexDirection="row"
                                 justifyContent="space-between" alignItems="center">
                                <Link to="/agenda"><Logo
                                    className={deviceView === "mobile" ? "mobile-logo" : "navbar-logo"}/></Link>
                                <CloseIcon onClick={() => setDrawer(!drawer)} className="close-icon"/>
                            </Box>
                            <Link replace={false} onClick={() => setDrawer(!drawer)} to="/agenda"
                                  className={"link light-link " + (calendar && calendar.isExact ? "active" : "")}>{t('menu_label_calendar')}</Link>
                            <CustomLink onClick={() => setDrawer(!drawer)} className={'link light-link'}
                                        to="/explore">{t('menu_label_explore')}</CustomLink>
                            <CustomLink onClick={() => setDrawer(!drawer)} className={'link light-link'}
                                        to="/contact">{t('footer:static_pages_contact')}</CustomLink>
                            <CustomLink onClick={() => setDrawer(!drawer)} className={'link light-link'}
                                        to="/terms">{t('footer:static_pages_terms')}</CustomLink>
                            <CustomLink onClick={() => setDrawer(!drawer)} className={'link light-link'}
                                        to="/privacy-policy">{t('footer:static_pages_privacy')}</CustomLink>
                            <CustomLink onClick={() => setDrawer(!drawer)} className={'link light-link'}
                                        to="/cookies-policy">{t('footer:static_pages_cookies')}</CustomLink>
                            <ThemeToggle/>
                        </Drawer>
                        }
                    </Box>
                </>
            )}
            <Modal
                state={interestsModal}
                persist={true}
                max={"sm"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                padd={true}
                onClose={closeInterests}>

                <InterestsForm onClose={closeInterests}/>
            </Modal>
            <Modal
                state={byPassEventInfoModal}
                persist={false}
                max={"lg"}
                radius={false}
                fullScreen={deviceView === "mobile"}
                onClose={closeInfo}>

                {deviceView === "mobile" ?
                    <EventInfo onClose={closeInfo}/> :
                    <NewEventInfo onClose={closeInfo}/>}
            </Modal>
            <Modal
                state={addEventModal}
                persist
                max={"lg"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                onClose={closeAddEvent}>

                <AddEventFormComponent event={{}} onClose={closeAddEvent}/>
            </Modal>
            {completeSignupModal && (
                <Modal
                    state={completeSignupModal}
                    persist={true}
                    max={"lg"}
                    radius={true}
                    fullScreen={deviceView === "mobile"}
                    padd={true}
                    onClose={closeCompleteSignupForm}
                    disableEscape={true}
                >
                    <CompleteSignupForm onClose={closeCompleteSignupForm}/>
                </Modal>
            )}
            <Modal
                state={addNewAccountModal}
                persist={false}
                max={"xs"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                onClose={closeNewAccountModal}>

                <AddNewAccountForm onClose={closeNewAccountModal}/>
            </Modal>
            <Modal
                state={manageMultiAccountModal}
                persist={false}
                max={"sm"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                onClose={closeManageMultiAccount}>

                <ManageMultiAccountForm onClose={closeManageMultiAccount}/>
            </Modal>
            <Modal
                state={discoverModal}
                persist={true}
                max={"lg"}
                radius={true}
                fullScreen={deviceView === "mobile"}
                onClose={finishDiscoverUsersModal}>
                <DiscoverForm onClose={finishDiscoverUsersModal}/>
            </Modal>
            <Snackbar/>
        </Box>
    )
}

export default Navbar;
