import React, { useEffect } from 'react';
import './cookies-policy.scss';
import { useSelector } from "react-redux";
import CookiesPolicyEN from "./CookiesPolicyEN";
import CookiesPolicyES from "./CookiesPolicyES";

const CookiesPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const settingsGeneral = useSelector(state => state.settings.general);

    if(settingsGeneral.language === 'es') {
        return <CookiesPolicyES/>
    } else {
        return <CookiesPolicyEN/>
    }
};

export default CookiesPolicy;
