import React from 'react';
import './loader.scss';
import { Box } from '@material-ui/core';
import Logo from '../Logo/Logo';
import {useSelector} from "react-redux";

const Loader = ({withoutBox, withoutBg, timeoutError}) => {
    const loader = useSelector(state => state.loader);
    return (
        <Box bgcolor={withoutBg ? "" : "background.default"} className={withoutBox ? "loader-box without" : "loader-box"}>
            <div className="loader"></div>
            <Logo className="loader-logo" variant={'isotype'} />
            {timeoutError && loader.loaderErrorTimeout && (
                <div className="loader-error-message"><span>We have detected an issue with your connection to our platform, please try again in a few minutes</span></div>
            )}
        </Box>
    )
}

export default Loader;
