export const showLoader = () => {
    return dispatch => {
        dispatch({type: 'SHOW_LOADER'})
    }
};

export const hideLoader = () => {
    return dispatch => {
        dispatch({type: 'HIDE_LOADER'})
    }
};

export const loaderTimeoutError = () => {
    return dispatch => {
        dispatch({type: 'SET_LOADER_TIMEOUT'})
    }
};

export const loaderRemoveTimeoutError = () => {
    return dispatch => {
        dispatch({type: 'REMOVE_LOADER_TIMEOUT'})
    }
};
