export const setAgendaFilter = (data) => {
    return dispatch => {
        dispatch({type: 'SET_AGENDA_FILTER', payload: data})
    }
}

export const unsetAgendaFilter = () => {
    return dispatch => {
        dispatch({type: 'UNSET_AGENDA_FILTER'})
    }
}
