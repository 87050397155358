import React, {useState} from 'react';
import {Box} from "@material-ui/core";
import {ReactComponent as VerifiedUserIcon} from "../../../assets/icons/user/check-new.svg";
import CustomButton from "../../CustomButton/CustomButton";
import {followUser, unfollowUser} from "../../../store/app/actions";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {showSnackbar} from "../../../store/snackbar/actions";
import './_explore_featured_users_item_component.scss';


const ExploreFeaturedUserItemComponent = ({user, onClick}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('extraData');

    const currentUser = useSelector(state => state.user);
    const [follow, setFollow] = useState(user.follow);
    const [loading, setLoading] = useState(false);
    const showErrorFollow = () => dispatch(showSnackbar({message: `To do this you must be logged in.`, type: "info"}));

    const handleOnClick = () => {
        onClick(user, callbackBtnFollow)
    }

    const callbackBtnFollow = () => {
        if(!follow) {
            callbackFollow();
        } else if (user.id !== currentUser.id && follow) {
            callbackUnfollow();
        }
    }

    const callbackFollow = () => {
        setLoading(true);
        dispatch(followUser(user, showErrorFollow, "explore-user", setFollow, setLoading, () => {
            // probablemente ya lo este siguiendo! fallo visual o algo por el estilo, así que:
            setFollow(true);
        }))
    }

    const callbackUnfollow = () => {
        setLoading(true);
        dispatch(unfollowUser(user, showErrorFollow, "explore-user", setFollow, setLoading))
    }

    return (
        <Box className="explore-featured-users--main--item">
            <Box className="explore-featured-users--main--item-actions">
                {user.id !== currentUser.id &&
                <Box className="actions" display="flex" flexDirection="row" justifyContent="center"
                     alignItems="center">
                    {!follow &&
                    <CustomButton
                        loading={loading}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOnClick();
                            //callbackFollow();
                        }}
                        className="follow-btn">{t('extraData:buttons_follow')}</CustomButton>
                    }

                    {user.id !== currentUser.id && follow &&
                    <CustomButton
                        loading={loading}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOnClick()
                            //callbackUnfollow();
                        }}
                        className="unfollow-btn">{t('extraData:buttons_unfollow')}</CustomButton>
                    }
                </Box>
                }
            </Box>
            <a href={`/${user.slug}`}
               onClick={(e) => {
                   e.preventDefault();
                   e.stopPropagation();
                   handleOnClick()
               }}
               className="user">
                <Box className="explore-featured-users--main--item-content">
                    <div className="explore-featured-users--main--item-background" style={{
                        backgroundImage: `url(${user.header})`,
                        backgroundPosition: "left center",
                        backgroundSize: "cover"
                    }}></div>
                    <div className="explore-featured-users--main--item-info">
                        <div className="explore-featured-users--main--item-info-avatar">
                            <img width="64" height="64" src={user.avatar ? user.avatar : "/images/defaultAvatar.png"} alt={user.nickname}
                                 className="image"/>
                        </div>
                        <div className="explore-featured-users--main--item-info-inline">
                            <h3 className="explore-featured-users--main--item-info-title">
                                {user.nickname}
                                {user.account_verified &&
                                <VerifiedUserIcon width={18} height={17} className="check-icon"/>}
                            </h3>
                            <div className="explore-featured-users--main--item-info-tagline">@{user.slug}</div>
                        </div>
                    </div>
                </Box>
            </a>
        </Box>
    );
};

export default ExploreFeaturedUserItemComponent;
