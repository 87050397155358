import React from 'react';
import {Box, TextareaAutosize} from "@material-ui/core";
import {useSelector} from "react-redux";

const FeedbackTextareaInput = ({label, value, answer, onChange}) => {
    const darkTheme = useSelector(state => state.darkTheme);

    return <div>
        <label>{label}</label>
        <Box mt={1} mb={2}>
            <TextareaAutosize aria-label="empty textarea" placeholder="" rowsMin={2}
                              className={`text-area ${darkTheme ? 'is-input-dark' : ''}`}
                              maxLength={1000}
                              value={value}
                              onChange={(event) => {
                                  onChange(answer, event.target.value)
                              }}
                              required
            />
        </Box>
    </div>
};

export default FeedbackTextareaInput;
