import React, {useEffect, useRef, useState} from 'react';
import './profile-tabs.scss';
import {Box, Tab, Tabs} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import Scroller from '../../Scroller/Scroller';
import UserEventsPipe from '../ProfileEventsPipe/UserEventsPipe';
import UserFollowersPipe from '../ProfileFollowersPipe/UserFollowersPipe';
import UserFollowingPipe from '../ProfileFollowingPipe/UserFollowingPipe';
import ProfileAgenda from '../ProfileAgenda/ProfileAgenda';
import {useTranslation} from 'react-i18next';
import {setOpenedEvent} from "../../../store/openedEvent/actions";
import {setOpenedEventMessages} from "../../../store/openedEventMessages/actions";
import {openEventInfoModal} from "../../../store/eventInfoModal/actions";
import TopCategoriesByUser from "../../TopCategoriesByUser/TopCategoriesByUser";
import ProfileArchivementsComponent from "../ProfileArchivements/ProfileArchivementsComponent";
import NewTrophy from "../../../assets/icons/achievement.png";
import IconButton from "@material-ui/core/IconButton";
import {Event} from "../../../services/tracking";

const ProfileTabs = (props) => {
    const {tab, onTab, userId, userSlug} = props;
    const dispatch = useDispatch();
    const {t} = useTranslation('extraData')
    const darkTheme = useSelector(state => state.darkTheme);
    const profileTabsRef = useRef(null);

    const OpenInfo = (event) => {
        window.history.pushState({calendar: false}, "my calendar", `/${event.owner.slug}/${event.id}`)
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("ProfileTabs", "OpenInfo", `/${event.owner.slug}/${event.id}`);
    };

    return (
        <Box className="profile-tabs" ref={profileTabsRef}>
            <Tabs value={tab} onChange={onTab} classes={{indicator: 'indicator'}} className="menu">
                <Tab label="Agenda" classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                <Tab icon={<img width="18" height="18" src={NewTrophy} alt={t('tab_label_archivements')}
                                className="trophy"/>} label={t('tab_label_archivements')}
                     classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                <Tab label={t('buttons_more_info')} classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
                <Tab label={t('tag_categories')} classes={{selected: darkTheme ? 'select-dark' : 'select-light'}}/>
            </Tabs>
            {tab === 0 &&
            <Box bgcolor="grid.main" className="section">
                <ProfileAgenda/>
            </Box>
            }
            {tab === 1 && (
                <Box bgcolor="grid.main" className="section">
                    <ProfileArchivementsComponent profileTabsRef={profileTabsRef} userId={userId}
                                                  userSlug={props.userSlug}
                                                  userArchivementList={props.userArchivementList}
                                                  cbFetchAchievements={props.cbFetchAchievements}/>
                </Box>
            )}
            {tab === 2 &&
            <Box bgcolor="grid.content" className="section" display="flex" flexDirection="row" justifyContent="flex-start">
                <Box className="events-pipe">
                    <span className="box-title"> {t('text_next_events')} </span>
                    <Box bgcolor="grid.content" className="events">
                        <Scroller count={5} itemHeight={70}>
                            <UserEventsPipe OpenInfo={OpenInfo}/>
                        </Scroller>
                    </Box>
                </Box>
                <Box className="following-pipe">
                    <span className="box-title"> {t('text_following')} </span>
                    <Box bgcolor="grid.content" className="following">
                        <UserFollowingPipe/>
                    </Box>
                </Box>
                <Box className="followers-pipe">
                    <span className="box-title"> {t('text_followers')} </span>
                    <Box bgcolor="grid.content" className="followers">
                        <UserFollowersPipe/>
                    </Box>
                </Box>
            </Box>
            }
            {tab === 3 &&
            <Box bgcolor="grid.main" className="section">
                <TopCategoriesByUser changeTab={onTab} userSlug={userSlug} userId={userId} topCategories={props.topCategories}/>
            </Box>
            }
        </Box>
    )
}

export default ProfileTabs;
