export const openManageMultiAccountModal = () => {
  return dispatch => {
    dispatch({type: 'OPEN_MANAGE_MULTI_ACCOUNT_MODAL'})
  }
}

export const closeManageMultiAccountModal = () => {
  return dispatch => {
    dispatch({type: 'CLOSE_MANAGE_MULTI_ACCOUNT_MODAL'})
  }
}
