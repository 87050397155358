import React, {useState, useEffect} from 'react';
import './pipelines.scss';
import {Box, IconButton, Popover} from '@material-ui/core';
import QuickEventForm from '../Forms/QuickEventForm/QuickEventForm';
import {useDispatch, useSelector} from 'react-redux';
import {setOpenedEvent} from '../../store/openedEvent/actions';
import {setOpenedEventMessages} from '../../store/openedEventMessages/actions';
import {openEventInfoModal} from '../../store/eventInfoModal/actions';
import ProfileCategories from '../Profile/ProfileCategories/ProfileCategories';
import NewPipelineItem from "../PipelineItem/NewPipelineItem";
import {addDays, isSameDay, parseISO} from "date-fns";
import {formatDate, getDateWithoutTime} from "../../helpers/dateFormatter";
import {setSelectedDateCalendar} from "../../store/selectedDateCalendar/actions";
import {setOpenedProfileWeeklyEvents} from "../../store/openedProfileWeeklyEvents/actions";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Chip from "@material-ui/core/Chip";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {setOpenedProfileFilterCategory} from "../../store/openedProfileFilterCategory/actions";
import {setMonthlyEventsCount} from "../../store/monthlyEventsCount/actions";
import {useTranslation} from "react-i18next";
import {Event} from "../../services/tracking";

const NewAgendaPipelines = (props) => {
    const { t } = useTranslation('agenda');
    const {calendar, alwaysShowPing, embedMode, weeklyEvents} = props
    const dateFormat = "iii. d"
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [catsAnchor, setCatsAnchor] = useState(null);
    const [openCats, setOpenCats] = useState(false);
    const [date, setDate] = useState("")
    const dispatch = useDispatch()
    const deviceView = useSelector(state => state.deviceView)
    const [week, setWeek] = useState([]);
    const {start, end} = weeklyEvents;
    const settingsGroupEvents = useSelector(state => state.settings.general.hasOwnProperty('groupedEvents') ? state.settings.general.groupedEvents : true);
    const selectedDate = useSelector(state => state.selectedDate);
    const sameStartEnd = isSameDay(getDateWithoutTime(parseISO(start)), getDateWithoutTime(parseISO(end)));
    const openedProfileFilterCategory = useSelector(state => state.openedProfileFilterCategory);
    const openedProfileWeeklySelected = useSelector(state => state.openedProfileWeeklySelected);
    const openedProfile = useSelector(state => state.openedProfile);
    const eventCategoriesSelector = useSelector(state => state.eventCategories);

    useEffect(() => {
        // prepare week
        if (start !== null) {
            let weekStart = parseISO(weeklyEvents.start);
            let weekEnd = parseISO(weeklyEvents.end);
            let days = [];
            for (let day = weekStart; day <= weekEnd; day = addDays(day, 1)) {
                days.push(day);
            }
            setWeek(days);
        }
    }, [weeklyEvents, start]);

    const openQuickEvent = (event, item) => {
        setOpen(true)
        if (item.hasOwnProperty('date')) {
            setDate(item.date)
        } else {
            setDate(item)
        }
        setAnchorEl(event.currentTarget);
    };

    const openCatsModal = (event, item) => {
        window.scrollTo(0, 800);
        setOpenCats(true)
        setCatsAnchor(event.currentTarget);
    };

    const closeForm = () => {
        setOpen(false);
        window.scrollTo(0, 0);
        setAnchorEl(null);
    };

    const closeCats = () => {
        setOpenCats(false)
        setCatsAnchor(null);
    };

    const OpenInfo = (event) => {
        if (props.hasOwnProperty('enableBackRef') && props.enableBackRef !== false) {
            window.history.pushState({backRef: props.enableBackRef}, "my calendar", `/${event.owner.slug}/${event.id}`)
        } else if (embedMode) {
            window.history.pushState({embed: true}, "my calendar", `/embed/${event.owner.slug}/${event.id}`)
        } else {
            window.history.pushState({calendar: calendar}, "my calendar", `/${event.owner.slug}/${event.id}`)
        }
        dispatch(setOpenedEvent(event.id))
        dispatch(setOpenedEventMessages(event.id))
        dispatch(openEventInfoModal())
        Event("NewAgendaPipelines", "OpenInfo", `/${event.owner.slug}/${event.id}`);

    };

    const goBackAgendaHandler = () => {
        let selectedAgenda = {...weeklyEvents};
        let d = new Date(selectedAgenda.start);

        if (deviceView === "browser") {
            d.setDate(d.getDate() - 7);
            dispatch(setSelectedDateCalendar(d));
        } else if (deviceView === "tablet") {
            d.setDate(d.getDate() - 2);
            dispatch(setOpenedProfileWeeklyEvents(d, selectedAgenda.ownerId))
        } else if (deviceView === "mobile") {
            d.setDate(d.getDate() - 1);
            dispatch(setOpenedProfileWeeklyEvents(d, selectedAgenda.ownerId))
        }
    };

    const goForwardAgendaHandler = () => {
        let selectedAgenda = {...weeklyEvents};
        let d = new Date(selectedAgenda.start);

        if (deviceView === "browser") {
            d.setDate(d.getDate() + 7);
            dispatch(setSelectedDateCalendar(d));
        } else if (deviceView === "tablet") {
            d.setDate(d.getDate() + 4);
            dispatch(setOpenedProfileWeeklyEvents(d, selectedAgenda.ownerId))
        } else if (deviceView === "mobile") {
            d.setDate(d.getDate() + 1);
            dispatch(setOpenedProfileWeeklyEvents(d, selectedAgenda.ownerId))
        }
    };


    const handleRemoveProfileFilterCategory = () => {
        dispatch(setOpenedProfileFilterCategory(false, () => {
            dispatch(setMonthlyEventsCount(new Date(openedProfileWeeklySelected.start), openedProfile.id, false));
            dispatch(setOpenedProfileWeeklyEvents(new Date(openedProfileWeeklySelected.start), openedProfile.id, false))
        }));
    }

    return (
        <div className={"agenda-pipelines-wrap-content"}>
            {(props.type === 'profile') && (
                <>
                    <Box display="flex" width="100%" alignItems="center" pt={1} pb={openedProfileFilterCategory !== false ? 0 : 1}
                         justifyContent="center">
                        <IconButton onClick={goBackAgendaHandler}><NavigateBeforeIcon/></IconButton>
                        {!sameStartEnd && <div
                            className="info-date">{formatDate(start, dateFormat)} - {formatDate(end, dateFormat)}</div>}
                        <IconButton onClick={goForwardAgendaHandler}><NavigateNextIcon/></IconButton>
                    </Box>
                    {openedProfileFilterCategory !== false && (
                        <Box display="flex" width="100%" alignItems="center" pb={1}
                             justifyContent="center">
                            <div style={{
                                paddingRight: '1rem',
                                fontFamily: 'Barlow-Medium'
                            }}>{t('label_chip_filter')}</div>
                            <Chip
                                size="small"
                                icon={<LocalOfferIcon fontSize="small" />}
                                label={eventCategoriesSelector.find(i => i.id === openedProfileFilterCategory)?.text || "Sin categoría"}
                                onDelete={handleRemoveProfileFilterCategory}
                                color="primary"
                            />
                        </Box>
                    )}
                </>
            )}
            <Box className={'agenda-pipelines-content'} display="flex"
                 flexDirection="row"
                 justifyContent="flex-start">
                {week.map((item, i) =>
                    <NewPipelineItem
                        key={`pipeline-item-${new Date(item).getDate()}-${weeklyEvents.hasOwnProperty('ownerId') ? weeklyEvents.ownerId : weeklyEvents.hasOwnProperty('groupId') ? `group-${weeklyEvents.groupId}` : "agenda"}`}
                        type={props.type}
                        executeCenterOnDay={props.handleCenterOnDay}
                        selectedDate={selectedDate}
                        ownerId={weeklyEvents.hasOwnProperty('ownerId') ? weeklyEvents.ownerId : null}
                        groupId={weeklyEvents.hasOwnProperty('groupId') ? weeklyEvents.groupId : null}
                        categoryId={weeklyEvents.hasOwnProperty('categoryId') ? weeklyEvents.categoryId : false}
                        groupEvents={props.type === 'agenda' ? settingsGroupEvents : false}
                        index={i}
                        item={item}
                        openQuickEvent={openQuickEvent}
                        openCatsModal={openCatsModal}
                        OpenInfo={OpenInfo}
                        calendar={calendar}
                        alwaysShowPing={alwaysShowPing}
                        dateFormat={dateFormat}
                        deviceView={deviceView}
                    />
                )}
                {calendar && open &&
                <Popover
                    open={open}
                    onClose={closeForm}
                    anchorEl={anchorEl}
                    classes={{root: 'popover-overlay', paper: 'form'}}
                    placement={'bottom'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <QuickEventForm date={date} onClose={closeForm}/>
                </Popover>
                }
                {!calendar && openCats &&
                <Popover
                    open={openCats}
                    onClose={closeCats}
                    anchorEl={catsAnchor}
                    classes={{root: 'popover-overlay', paper: 'cats-form'}}
                    placement={'bottom'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <ProfileCategories/>
                </Popover>
                }
            </Box>
        </div>
    )
}

export default NewAgendaPipelines;
