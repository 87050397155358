import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Tooltip} from '@material-ui/core';
import EventTimezoneSwitcher from "./EventTimezoneSwitcher";
import EventActionButtons from "./EventActionButtons";
import {ReactComponent as ShareIcon} from "../../../assets/icons/event/share.svg";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import LinkIcon from '@material-ui/icons/Link';
import ListSubheader from "@material-ui/core/ListSubheader";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {showSnackbar} from "../../../store/snackbar/actions";
import {useTranslation} from "react-i18next";
import {formatDate} from '../../../helpers/dateFormatter';

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

const EventHeader = (props) => {
    const {isOwner} = props;
    const dispatch = useDispatch();
    const darkTheme = useSelector(state => state.darkTheme);
    const event = useSelector(state => state.event);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {t} = useTranslation(['eventDetails']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Box className="header" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
            <Box className="title-container" display="flex" flexDirection="row">
                <Box className="title-box">
                    {event.title &&
                    <Tooltip title={event.title} placement="top" arrow>
                        <span className="title box-title">
                            <span className="event-title-container">{event.title}</span>
                            <Box className="share-dropdown" component="span">
                            <IconButton color="primary" aria-label={t('tooltip_share_event')} component="span"
                                        onClick={handleClick}>
                                <ShareIcon color="red" title={t('tooltip_share_event')} tooltip={t('tooltip_share_event')}
                                           alt={t('tooltip_share_event')}/>
                            </IconButton>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div className={"content-popover-share" + (darkTheme ? " is-dark" : " is-light")}>
                                    <List dense={true}
                                          component="nav"
                                          subheader={
                                              <ListSubheader component="div" id="nested-list-subheader">
                                                  {t('title_share_event_popover')}
                                              </ListSubheader>
                                          }>
                                        <FacebookShareButton url={`“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}`}>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <FacebookIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Facebook"/>
                                            </ListItem>
                                        </FacebookShareButton>
                                        <TwitterShareButton url={`“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}`}>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <TwitterIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Twitter"/>
                                            </ListItem>
                                        </TwitterShareButton>
                                        <WhatsappShareButton
                                            url={`“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}`}>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <WhatsappIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Whatsapp"/>
                                            </ListItem>
                                        </WhatsappShareButton>
                                        <CopyToClipboard
                                            text={`${window.location.hostname}/${event.owner.slug}/${event.id}`}
                                            onCopy={() => dispatch(showSnackbar({
                                                message: t('option_share_event_popover_clipboard_success'),
                                                type: "success"
                                            }))}
                                        >
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <LinkIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('option_share_event_popover_clipboard')}/>
                                            </ListItem>
                                        </CopyToClipboard>
                                        <CopyToClipboard
                                            text={`<blockquote class="pinging-event-card" data-event="${event.id}"><a href="https://${window.location.hostname}/${event.owner.slug}/${event.id}">“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}</a></blockquote><script async src="//widget.pingingapp.com/widget.js" charset="UTF-8"></script>`}
                                            onCopy={() => dispatch(showSnackbar({
                                                message: t('option_share_event_popover_clipboard_success'),
                                                type: "success"
                                            }))}
                                        >
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <LinkIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('option_share_event_popover_clipboard_embed')}/>
                                            </ListItem>
                                        </CopyToClipboard>
                                    </List>
                                </div>
                            </Popover>
                        </Box>
                        </span>
                    </Tooltip>
                    }
                </Box>
            </Box>
            <EventTimezoneSwitcher allDay={event.is_fullday} eventStart={event.start} eventEnd={event.end}/>
            <EventActionButtons />
        </Box>
    )
}

export default EventHeader;
