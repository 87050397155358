import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Lottie from "react-lottie-player";
import {CircularProgress} from "@material-ui/core";

import './_landing_articles.scss';
import {getComponentPhrase} from "../../../../../helpers/phrases";
import animationData from "../../../../../assets/animations/cute-cat.json";
import services from "../../../../../services";
import {setLandingFeedArticles} from "../../../../../store/landingFeedArticles/actions";
import LandingArticleItem from "./LandingArticleItem";
import {useTranslation} from "react-i18next";


const LandingArticles = ({userId, darkTheme, onClickArticle, containerClassname, max}) => {
    const [articles, setArticles] = useState([])
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation('landing');
    const lang = useSelector(state => state.settings.general.language);

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        if (userId) {
            services.landingFeed.find({
                query: {
                    type: 'article',
                    roomId: userId,
                    $limit: 3,
                    $sort: {
                        createdAt: -1
                    }
                }
            }).then((res) => {
                if (mounted) {
                    setArticles(res.data);
                    setLoading(false);
                }
            });
        }
        return () => mounted = false;
    }, [userId]);

    const RenderArticles = useMemo(() =>
            [
                ...articles,
                ...Array.from({length: 1 - articles.length}, () => 'placeholder')
            ].map((item, index) =>
                item.hasOwnProperty('id') ?
                    <LandingArticleItem key={`landing-article-item-${item.id}`} item={item} link={true}
                                        showContent={index !== 0}
                                        showCover={index === 0} onClickArticle={onClickArticle}/> :
                    <div key={`landing-article-placeholder-${index}`}
                         className={`item item-placeholder ${darkTheme ? ' is-dark' : ' is-light'}`}>
                        <div className={"animation"}>
                            <Lottie
                                loop={true}
                                animationData={animationData}
                                play
                                style={{width: 320}}
                            />
                        </div>
                        <span>{t('empty_section')}</span>
                        {getComponentPhrase(lang)}
                    </div>
            )
        , [userId, articles, max, darkTheme]);

    const RenderPlaceholders = useMemo(() => [
            ...Array.from(max, () => 'placeholder')
        ].map((item, index) => <div key={`landing-article-placeholder-${index}`}
                                    className={`item item-placeholder ${darkTheme ? ' is-dark' : ' is-light'}`}>
            <div className={"animation"}>
                <Lottie
                    loop={true}
                    animationData={animationData}
                    play
                    style={{width: 320}}
                />
            </div>
            <span>{t("empty_section")}</span>
            {getComponentPhrase(lang)}
        </div>)
        , [max]);


    return (
        <div className={containerClassname}>
            {loading ? <CircularProgress thickness={5} className="input-loader"/> : RenderArticles}
        </div>
    );
};

LandingArticles.defaultProps = {
    containerClassname: 'landing-articles-container'
}


export default LandingArticles;
