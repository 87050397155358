export const setSelectedGroup = (data) => {
    return dispatch => {
        dispatch({type: 'SET_SELECTED_GROUP', payload: data})
    }
}

export const unsetSelectedGroup = () => {
    return dispatch => {
        dispatch({type: 'UNSET_SELECTED_GROUP'})
    }
}
