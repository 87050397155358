import services from '../../services/';

export const setEventCategories = () => {
    return dispatch => {
        services.eventCategories.find({
            query: { $limit: 50, $sort: { text: 1 } }
        }).then((resp) => {
            dispatch({
                type: 'SET_EVENT_CATEGORIES',
                payload: resp.data.map(x => ({ id: x.id, cover_url: x.cover_url, parentId: x.parentId, text: x.text, slug: x.slug }))
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}
