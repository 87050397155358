const initialState = {
    tutorial_sub_event: true
};

const tutorialsReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_TUTORIAL_SUB_EVENT':
            return {
                ...state,
                tutorial_sub_event: action.payload
            };
        case 'UNSET_TUTORIAL':
            return state = initialState;
        default:
            return state;
    }
};

export default tutorialsReducer;
