import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/index.scss'
import App from './App';
import {store, persistor} from './store';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './helpers/internationalization';

ReactDOM.render(
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>,
    document.getElementById('root'));
serviceWorker.unregister();
