import React from 'react';
import './profile-event-card.scss';
import {Box} from '@material-ui/core';
import { formatDate } from '../../../helpers/dateFormatter';
import ChatIcon from '../../../assets/icons/actions/comments.svg'
import FollowIcon from '../../../assets/icons/actions/user-plus-2.svg';
import subscribeOff from '../../../assets/icons/actions/notification-off.svg';
import subscribeOn from '../../../assets/icons/actions/bell-on.svg';
import {useTranslation} from 'react-i18next';

const ProfileEventCard = (props) => {
    const {event} = props
    const {t} = useTranslation('extraData')

    return (
        <Box className={"profile-event-card"} bgcolor="grid.main">
            <img src={event.owner.avatar ? event.owner.avatar : "/images/defaultAvatar.png"} className={"avatar"} alt={event.owner.nickname} />
            <span className="high-date">
                <span>{formatDate(event.start, "d LLL")}</span>
            </span>
            <Box className="event-card-content" display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <span className="title">{event.title}</span>
                    <span className="date">{`${formatDate(event.start, "HH:mm")} - ${formatDate(event.end, "HH:mm")}`}</span>
                </Box>
            </Box>
        </Box>
    )
}

export default ProfileEventCard;
