import React, {Fragment, useEffect, useState} from 'react';
import UserCard from '../../User/UserCard/UserCard';
import { useSelector } from 'react-redux';
import InfiniteList from 'react-infinite-scroll-list';
import services from "../../../services";
import {useTranslation} from "react-i18next";

const UserFollowersPipe = (props) => {
    const { t } = useTranslation('extraData');
    const openedProfile = useSelector(state => state.openedProfile);
    const openedProfileFollowers = useSelector(state => state.openedProfileFollowers);
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(10);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(openedProfileFollowers.hasOwnProperty('data') ? openedProfileFollowers.data : []);
    }, [openedProfileFollowers])

    const fetchData = () => {
        if(!loading) {
            setLoading(true);
            services.userFollowers.get(openedProfile.id, {
                query: {
                    $skip: skip,
                    $limit: 10
                }
            })
                .then((resp) => {
                    setData([...data, ...resp.data]);
                    setSkip(skip + 10);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    };


    return (
        <Fragment>
            {(data && data.length > 0) && (
                <InfiniteList
                    root="container"
                    isLoading={loading}
                    isEndReached={data.length === openedProfileFollowers.total}
                    onReachThreshold={fetchData}
                    containerClassName="scroller container-followers-pipe"
                    sentinelClassName="sentinel-followers-pipe"
                    containerTagName="div"
                    sentinelTagName="div"
                    threshold={0}
                >
                    {data.map((item) =>
                        <UserCard toProfile={true} interestsCount={1} portfolio={true} key={item.id} profile={item} />
                    )}
                </InfiniteList>
            )}
            {data.length === 0 && (
                <div className="user-pipe-empty--text">
                    {t('landing_empty_content')}
                </div>
            )}
        </Fragment>
    );
}

export default UserFollowersPipe;
