import React, {useEffect, useState} from 'react';
import services from "../../../../../services";
import LandingFeedClips from "../LandingFeedComponent/Feed/LandingFeedClips/LandingFeedClips";
import {useSelector} from "react-redux";
import {Event} from "../../../../../services/tracking";

const componentName = "LandingHighlightsComponent";

const LandingHighlightsComponent = ({userId, eventWithClipOpen, type, displayClipInfo}) => {
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);
    const loggedIn = useSelector(state => state.loggedIn)
    const user = useSelector(state => state.user)
    const [allowedManage, setAllowedManage] = useState(false);

    useEffect(() => {
        if (loggedIn) {
            if (user.id === userId) {
                setAllowedManage(true);
            } else {
                services.userLandingRoles.find({
                    query: {
                        roomId: userId
                    }
                }).then((res) => {
                    if (res.hasOwnProperty('allowed') && res.allowed === true) {
                        setAllowedManage(true);
                    }
                });
            }
        }
    }, [loggedIn, user]);


    const onSubmitModal = (data, onSuccess, onError) => {
        services.landingFeed.create({...data, roomId: userId}).then(res => {
            onSuccess(res);
            Event(componentName, "AddHighlight", data.custom_fields.clip);
        }).catch(err => {
            console.log(err)
            onError(err);
        })
    };

    return (
        <div
            className={`${deviceView === 'browser' ? ' is-desktop' : ''} ${deviceView === 'mobile' ? ' is-mobile' : ''} ${deviceView === 'tablet' ? ' is-tablet' : ''} ${darkTheme ? " is-dark-theme" : " is-light-theme"}`}>
            <LandingFeedClips
                type={type}
                userId={userId}
                allowedManage={allowedManage}
                handleSubmit={onSubmitModal}
                eventWithClipOpen={eventWithClipOpen}
                displayClipInfo={displayClipInfo}
            />
        </div>
    );
};

LandingHighlightsComponent.defaultProps = {
    type: 'clip',
    displayClipInfo: false
}

export default LandingHighlightsComponent;
