const initialState = new Date()

const selectedDateCalendarReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_SELECTED_DATE_CALENDAR':
            return state = action.payload;
        case 'UNSET_SELECTED_DATE_CALENDAR':
            return state = initialState
        default:
            return state
    }
}

export default selectedDateCalendarReducer;
