import {combineReducers} from 'redux';
import userReducer from './user/reducer';
import addEventModalReducer from './addEventModal/reducer';
import interestsReducer from './interests/reducer';
import interestsModalReducer from './interestsModal/reducer';
import eventInfoModalReducer from './eventInfoModal/reducer';
import darkThemeReducer from './darkTheme/reducer';
import monthlyEventsCountReducer from './monthlyEventsCount/reducer';
import loaderReducer from './loader/reducer';
import snackbarReducer from './snackbar/reducer';
import discoverUserReducer from './discoverUsers/reducer';
import userGroupsReducer from './userGroups/reducer';
import recommendationsReducer from './recommendations/reducer';
import openedProfileReducer from './openedProfile/reducer';
import opnenedEventReducer from './openedEvent/reducer';
import opnenedEventMesagesReducer from './openedEventMessages/reducer';
import userCategoriesReducer from './userCategories/reducer';
import participantsReducer from './participants/reducer';
import selectedDateReducer from './selectedDate/reducer';
import loginReducer from './loggedIn/reducer';
import openedProfileFollowersReducer from './openedProfileFollowers/reducer';
import openedProfileFollowingReducer from './openedProfileFollowing/reducer';
import openedProfileWeeklyEventsReducer from './openedProfileWeeklyEvents/reducer';
import agendaWeeklyEventsReducer from './agendaWeeklyEvents/reducer';
import openedProfileNextEventsReducer from './openedProfileNextEvents/reducer';
import userFollowingReducer from './userFollowing/reducer';
import openedProfileCategoriesReducer from './openedProfileCategories/reducer';
import selectedGroupReducer from './selectedGroup/reducer';
import cookiesTermsReducer from './cookiesTerms/reducer';
import agendaFilterReducer from './agendafFilter/reducer';
import deviceViewReducer from './deviceView/reducer';
import notificationsReducer from './notifications/reducer';
import exploreReducer from './explore/reducer';
import completeSignupModalReducer from './completeSignupModal/reducer';
import settingsReducer from "./settings/reducer";
import openedProfileFilterCategoryReducer from "./openedProfileFilterCategory/reducer";
import subscriptionReducer from "./subscription/reducer";
import addNewAccountModalReducer from "./addNewAccountModal/reducer";
import multiAccountsReducer from "./accounts/reducer";
import manageMultiAccountModal from "./manageMultiAccountModal/reducers";
import invitationsReducer from "./invitations/reducer";
import agendaWeeklySelectedReducer from "./agendaWeeklySelected/reducer";
import openedProfileWeeklySelectedReducer from "./openedProfileWeeklySelected/reducer";
import roomsReducer from "./rooms/reducer";
import landingFeedTimelineReducer from "./landingFeedTimeline/reducer";
import landingFeedArticlesReducer from "./landingFeedArticles/reducer";
import landingFeedClipsReducer from "./landingFeedClips/reducer";
import selectedDateCalendarReducer from "./selectedDateCalendar/reducer";
import eventCategoriesReducer from "./eventCategories/reducer";
import openedDiscoverCategoriesWeeklyEventsReducer from "./openedDiscoverCategoriesWeeklyEvents/reducer";
import tutorialsReducer from "./tutorials/reducer";
import discoverUsersModalReducer from "./discoverUsersModal/reducer";

const allReducers = combineReducers({
    loggedIn: loginReducer,
    user: userReducer,
    userFollowing: userFollowingReducer,
    agendaWeeklyEvents: agendaWeeklyEventsReducer,
    openedProfileWeeklyEvents: openedProfileWeeklyEventsReducer,
    addEventModal: addEventModalReducer,
    interests: interestsReducer,
    interestsModal: interestsModalReducer,
    eventInfoModal: eventInfoModalReducer,
    darkTheme: darkThemeReducer,
    monthlyEventsCount: monthlyEventsCountReducer,
    loader: loaderReducer,
    snackbar: snackbarReducer,
    discoverUsers: discoverUserReducer,
    userGroups: userGroupsReducer,
    recommendations: recommendationsReducer,
    openedProfile: openedProfileReducer,
    openedProfileNextEvents: openedProfileNextEventsReducer,
    openedProfileFollowers: openedProfileFollowersReducer,
    openedProfileFollowing: openedProfileFollowingReducer,
    openedProfileCategories: openedProfileCategoriesReducer,
    event: opnenedEventReducer,
    messages: opnenedEventMesagesReducer,
    userCategories: userCategoriesReducer,
    participantsList: participantsReducer,
    selectedDate: selectedDateReducer,
    selectedGroup: selectedGroupReducer,
    cookiesTerms: cookiesTermsReducer,
    agendaFilter: agendaFilterReducer,
    deviceView: deviceViewReducer,
    notifications: notificationsReducer,
    explore: exploreReducer,
    completeSignupModal: completeSignupModalReducer,
    settings: settingsReducer,
    openedProfileFilterCategory: openedProfileFilterCategoryReducer,
    subscription: subscriptionReducer,
    addNewAccountModal: addNewAccountModalReducer,
    multiAccount: multiAccountsReducer,
    manageMultiAccountModal: manageMultiAccountModal,
    invitations: invitationsReducer,
    agendaWeeklySelected: agendaWeeklySelectedReducer,
    openedProfileWeeklySelected: openedProfileWeeklySelectedReducer,
    rooms: roomsReducer,
    landingFeedTimeline: landingFeedTimelineReducer,
    landingFeedArticles: landingFeedArticlesReducer,
    landingFeedClips: landingFeedClipsReducer,
    selectedDateCalendar: selectedDateCalendarReducer,
    eventCategories: eventCategoriesReducer,
    openedDiscoverCategories: openedDiscoverCategoriesWeeklyEventsReducer,
    tutorials: tutorialsReducer,
    discoverUsersModal: discoverUsersModalReducer
});

export default allReducers;
