import React, {useState, useEffect} from 'react';
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import './_profile_archivements_component.scss';
import services from "../../../services";
import ProfileArchivementListItemComponent from "./ProfileArchivementListItemComponent";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';

import {showSnackbar} from "../../../store/snackbar/actions";
import Modal from "../../Modal/Modal";
import AchievementListForm from "../../Archivements/AchivementListForm";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Skeleton from "@material-ui/lab/Skeleton";
import ProfileArchivementListItemNewComponent from "./ProfileArchivementListItemNewComponent";
import CallMadeIcon from '@material-ui/icons/CallMade';

const ProfileArchivementListComponent = ({profileTabsRef, isOpened, userSlug, listId, listSlug, label, ownerId, onRefresh}) => {
    const {i18n, t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceView = useSelector(state => state.deviceView);
    const darkTheme = useSelector(state => state.darkTheme);

    const user = useSelector(state => state.user);
    const [achivementListModal, setAchivementListModal] = useState(false);
    const [openConfirmationEraseList, setOpenConfirmationEraseList] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        services.userAchievementRecord.find({
            query: {
                populate: true,
                listId: listId,
                $limit: !isOpened ? 4 : 24
            }
        }).then(res => {
            if(!isOpened) {
                setData(res.data);
            } else {
                setData(res.data);
            }

            setTotal(res.total);
        })
    }, [listId, isOpened]);

    const handleRemoveList = () => {
        setAnchorEl(null);
        setOpenConfirmationEraseList(true);
    }

    const doDeleteList = () => {
        services.userAchievementList.remove(listId).then((res) => {
            dispatch(showSnackbar({
                message: t("archivementForm:snackbar_achievement_list_remove_success"),
                type: "success"
            }));
            setOpenConfirmationEraseList(false);
            setIsDeleted(true);
        }).catch(err => {
            dispatch(showSnackbar({
                message: t("archivementForm:snackbar_achievement_list_remove_error"),
                type: "error"
            }));
            setOpenConfirmationEraseList(false);
        });
    }

    const handleToggleAchivementListModal = () => {
        setAnchorEl(null);
        setAchivementListModal(curr => !curr);
    }

    if (isDeleted) {
        return null;
    }

    const obtainOpenBigUri = () => {
        if (i18n.language === "es") {
            return `/${userSlug}/logros/${listSlug}`;
        } else {
            return `/${userSlug}/achievements/${listSlug}`;
        }
    }

    const handleOpenBig = (e) => {
        if(e) {
            e.preventDefault();
        }
        let uri = obtainOpenBigUri();
        history.push(uri)
        if(profileTabsRef.current) {
            profileTabsRef.current.scrollIntoView()
        } else {
            window.scrollTo(0, 0);
        }
    }

    const handleGoBack = () => {
        if (i18n.language === "es") {
            history.push(`/${userSlug}/logros`)
        } else {
            history.push(`/${userSlug}/achievements`)
        }
        if(profileTabsRef.current) {
            profileTabsRef.current.scrollIntoView()
        } else {
            window.scrollTo(0, 0);
        }
    }

    return (
        <Box bgcolor="grid.content" className="profile-archivements-list-component">
            <Box mb={1} className="profile-archivements-list-component--heading">
                {isOpened && (
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleGoBack}
                    >
                        <ChevronLeftIcon/>
                    </IconButton>
                )}
                <h2>{label} ({total})</h2>
                <div>
                    {!isOpened && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleOpenBig}
                        >
                            <CallMadeIcon/>
                        </IconButton>
                    )}
                    {user && user.id === ownerId && (
                        <>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon/>
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: 24 * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem onClick={handleToggleAchivementListModal}>
                                    Editar
                                </MenuItem>
                                <MenuItem onClick={handleRemoveList}>
                                    Borrar
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </div>
            </Box>

            <div className="profile-archivements-list-component-items-wrapper">
                <Box p={1} className="profile-archivements-list-component-items">
                    {data.map(item => <ProfileArchivementListItemNewComponent key={item.id} id={item.id} event={item.event}
                                                                           statusId={item.statusId}
                                                                           achieved_date={new Date(item.achieved_date)}/>)}
                    {/*
                    {!isOpened && total > 3 && (
                        <a href={obtainOpenBigUri()} onClick={handleOpenBig}>
                            <div className="profile-archivements-list-component-item is-placeholder">
                                <div className="num">+{total}</div>
                                <div>ver más</div>
                            </div>
                        </a>
                    )}
                    */}
                    {!isOpened && total < 4 && (
                        Array.from({ length: 4 - data.length }, (x, i) => i).map(i => <Skeleton key={i} width={"100%"} height={"100%"} variant="rect" className={(darkTheme ? 'dark' : 'light')} />)
                    )}
                </Box>

            </div>
            <Modal
                state={achivementListModal}
                fullScreen={deviceView === "mobile"}
                max={"sm"}
                radius={true}
                onClose={handleToggleAchivementListModal}
                className="archivements-modal"
                persist
                pad>
                <AchievementListForm listId={listId} onSaveData={onRefresh} onClose={handleToggleAchivementListModal}/>
            </Modal>
            <Dialog
                open={openConfirmationEraseList}
                onClose={() => setOpenConfirmationEraseList(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('eventForm:dialog_erase_event_headtitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"
                                       className={"dialog-description " + (darkTheme ? ' is-dark' : ' is-light')}>
                        {t('archivementForm:dialog_erase_achievement_list_text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmationEraseList(false)} color="primary">
                        {t('eventForm:dialog_erase_event_cancel_btn')}
                    </Button>
                    <Button onClick={() => {
                        doDeleteList();
                        setOpenConfirmationEraseList(false);
                    }} color="primary" autoFocus>
                        {t('eventForm:dialog_erase_event_confirm_btn')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProfileArchivementListComponent;
