import React from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core'
import NotificationsList from "./NotificationsList";
import InvitationsList from "./InvitationsList";
import {useTranslation} from "react-i18next";

const TabPanel = ({children, value, index, ...other}) => {
    return (
        <div hidden={value !== index} style={{
            flex: 1,
            height: '100%'
        }} id={`tabpanel-${index}`} {...other}>
            {value === index && children}
        </div>
    );
};

const NotificationsWrapper = ({ onClose }) => {
    const { t } = useTranslation('notifications');
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <>
            <AppBar position={"static"} color={"transparent"}>
                <Tabs value={value} onChange={handleChange} variant={"fullWidth"} centered>
                    <Tab label={t('headings_notifications')} />
                    <Tab label={t('headings_invitations')} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <NotificationsList handleClose={onClose} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <InvitationsList handleClose={onClose} />
            </TabPanel>
        </>
    );
};

export default NotificationsWrapper;