const initialState = false

const completeSignupModalReducer = (state = initialState, action) => {
    switch(action.type){
        case 'OPEN_COMPLETE_SIGNUP_MODAL':
            return state = true;
        case 'CLOSE_COMPLETE_SIGNUP_MODAL':
            return state = initialState
        default:
            return state
    }
}

export default completeSignupModalReducer;
