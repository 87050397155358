export const tutorialActionsSubEvent = (value) => {
    return dispatch => {
        dispatch({
            type: 'SET_TUTORIAL_SUB_EVENT',
            payload: value
        })
    };
};

export const unsetTutorials = () => {
    return dispatch => {
        dispatch({type: 'UNSET_TUTORIAL'})
    };
};
