import React, {useEffect, useState} from "react";
import {Box, Switch} from "@material-ui/core";
import "./general-display-form.scss";
import {useDispatch, useSelector} from "react-redux";
import {changeDarkTheme} from "../../../../store/darkTheme/actions";
import {toggleGroupedEvents, toggleExperimentalMode} from "../../../../store/settings/actions";
import {useTranslation} from "react-i18next";

const GeneralDisplayForm = () => {
    const {t} = useTranslation('settings');
    const user = useSelector(state => state.user);
    const darkTheme = useSelector(state => state.darkTheme);
    const settingsGeneral = useSelector(state => state.settings.general);
    const dispatch = useDispatch();

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setIsAdmin(user && user.id ? user.roles.includes('admin') : false);
    }, [user]);

    const changeTheme = () => {
        dispatch(changeDarkTheme(!darkTheme))
    };

    const changeExperimental = () => {
        dispatch(toggleExperimentalMode(!settingsGeneral.experimental));
    };

    const changeGroupedEvents = () => {
        let val = settingsGeneral.hasOwnProperty('groupedEvents') ? settingsGeneral.groupedEvents : true;
        dispatch(toggleGroupedEvents(!val));
    }

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_general_display')}</div>
                <div className="settings-content-title-helper">{t('section_general_display_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-general settings-content-display">
                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                    <Switch checked={darkTheme} onChange={changeTheme} className="switch"/>
                    <span>{t('section_general_display_enable_dark_theme')}</span>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                    <Switch checked={settingsGeneral.hasOwnProperty('groupedEvents') ? settingsGeneral.groupedEvents : true} onChange={changeGroupedEvents} className="switch"/>
                    <span>{t('section_general_display_grouped_events')}</span>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                    <Switch checked={settingsGeneral.experimental} onChange={changeExperimental} className="switch"/>
                    <span>{t('section_general_display_enable_experimental')}</span>
                </Box>
            </div>
        </div>
    );
};

export default GeneralDisplayForm;
