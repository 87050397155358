import React from 'react';
import './snackbar.scss';
import {Snackbar} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {hideSnackbar} from '../../store/snackbar/actions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

const Popup = () => {
    const snackbar = useSelector(state => state.snackbar)
    const deviceView = useSelector(state => state.deviceView)
    const dispatch = useDispatch()

    return (
        <Snackbar
            anchorOrigin={{
                vertical: (deviceView === "browser" || deviceView === "tablet") ?  'top' : "bottom",
                horizontal: (deviceView === "browser" || deviceView === "tablet") ?  'right' : "center",
            }}
            className={" snackbar " + (snackbar ? snackbar.type : "info")}
            open={snackbar ? snackbar.open : false}
            onClose={() => dispatch(hideSnackbar())}
            autoHideDuration={2000}
            message={<span id="message-id">{snackbar ? snackbar.message : ""}</span>}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch(hideSnackbar())}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}

export default Popup;
