const initailState = {
    id: "",
    title: "",
    start: "",
    end: "",
    poster: null,
    organizer: null,
    website: null,
    location: null,
    description: null,
    is_online: null,
    is_private: false,
    weight: 0,
    createdAt: "",
    updatedAt: "",
    ownerId: "",
    owner: {},
    assistants: [],
    guests: [],
    categories: [],
    comments: [],
    isPing: false,
    isSubscribe: true
}

const opnenedEventReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_OPENED_EVENT':
            return {...state, ...action.payload}
        case 'UNSET_OPENED_EVENT':
            return state = initailState
        default:
            return state
    }
}

export default opnenedEventReducer;