import React from 'react';
import {useSelector} from "react-redux";
import AgendaComplete from "./AgendaComplete";
import AgendaPopupCal from "./AgendaPopupCal";

const Agenda = () => {
    const settingsExperimental = useSelector(state => state.settings.general.experimental);

    return <AgendaPopupCal />;

    {/*
        if(settingsExperimental) {
            return <AgendaPopupCal />
        } else {
            return <AgendaComplete />
        }
    */}

};

export default Agenda;
