import services from '../../services/';

export const setRecommendations = () => {
    return dispatch => {
        dispatch(setRecommendationsLoading())
        services.customUserRecommendation.find()
            .then((resp) => {
                dispatch({
                    type: 'SET_RECOMMENDATIONS',
                    payload: resp
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetRecommendations = () => {
    return dispatch => {
        dispatch({type: 'UNSET_RECOMMENDATIONS'})
    }
}

export const setRecommendationsLoading = () => {
    return dispatch => {
        dispatch({type: 'SET_RECOMMENDATIONS_LOADING'})
    }
}
