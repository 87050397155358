const initialState = {};

const landingFeedArticlesReducer = (state = initialState, action) => {
    let roomId = undefined;
    let messageId = undefined;
    switch(action.type){
        case 'SET_LANDING_FEED_ARTICLES':
            let new_state = Object.assign({}, state);
            roomId = action.payload.roomId;
            new_state[roomId] = [...action.payload.messages];
            return new_state;
        case 'ADD_LANDING_FEED_ARTICLE':
            let tmp = Object.assign({}, state);
            roomId = action.payload.roomId;
            if(tmp.hasOwnProperty(roomId)){
                tmp[roomId] = [...tmp[roomId], action.payload.message];
            } else {
                tmp[roomId] = [action.payload.message];
            }
            return tmp;
        case 'EDIT_LANDING_FEED_ARTICLE':
            roomId = action.payload.roomId;
            messageId = action.payload.messageId;
            let _arr = Object.assign({}, state);
            if(_arr.hasOwnProperty(roomId)){
                let roomMessages = _arr[roomId]
                roomMessages = roomMessages.map(item => {
                    if(item.id === messageId) {
                        return action.payload.message
                    } else {
                        return item
                    }
                });
                _arr[roomId] = [...roomMessages];
            }
            return _arr;
        case 'REMOVE_LANDING_FEED_ARTICLE':
            roomId = action.payload.roomId;
            messageId = action.payload.messageId;
            let __arr = Object.assign({}, state);
            if(__arr.hasOwnProperty(roomId)){
                let roomMessages = __arr[roomId]
                __arr[roomId] = roomMessages.filter(item => item.id !== messageId);
            }
            return __arr;
        case 'ERASE_LANDING_ARTICLES':
            return state = initialState;
        default:
            return state;
    }
}

export default landingFeedArticlesReducer;
