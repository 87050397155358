import React, {useEffect, useState} from "react";
import './quick-event-form.scss';
import {Grid, TextField, Box, Switch} from "@material-ui/core";
import services from "../../../services";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {showSnackbar} from "../../../store/snackbar/actions";
import ParticipantsList from '../AddEventForm/ParticipantsList';
import {updateCalendar} from "../../../store/app/actions";
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from '../../CustomButton/CustomButton';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {formatDate} from "../../../helpers/dateFormatter";
import privateEvent from '../../../assets/icons/event/icon-visibility-private-red.svg';
import publicEvent from '../../../assets/icons/actions/group.svg';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import OwnerList from "../AddEventForm/OwnerList";
import EventCategoriesList from "../AddEventForm/EventCategoriesList";


const QuickEventForm = (props) => {
    const {date} = props;
    const user = useSelector(state => state.user);
    const [title, setTitle] = useState("");
    const [start, setStart] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [categories, setCat] = useState([]);
    const [participants, setPart] = useState([]);
    const [privateParticipants, setPrivatePart] = useState([]);
    const {t} = useTranslation('eventForm');
    const dispatch = useDispatch();
    const [isPublic, setIsPublic] = useState(true);
    const [allDay, setAllDay] = useState(true);
    const [owner, setOwner] = useState(user.id);
    const subscription = useSelector(state => state.subscription);
    const [isPremiumDisplayPoster, setIsPremiumDisplayPoster] = useState(false);
    const darkTheme = useSelector(state => state.darkTheme);
    const [isFocus, setIsFocus] = useState(false);
    const emptyValue = {text: '', id: ''};
    const eventCategoriesSelector = useSelector(state => state.eventCategories);
    const eventCategories = [...eventCategoriesSelector];
    const [eventCategoryParent, setEventCategoryParent] = useState(emptyValue);
    const [eventCategoryChild, setEventCategoryChild] = useState(emptyValue);

    useEffect(() => {
        if (subscription && Object.keys(subscription).length > 0 && subscription.is_processed === true) {
            setIsPremiumDisplayPoster(true);
        }
    }, [subscription]);

    const handleSubmit = () => {

        let categoryId = eventCategoryChild && eventCategoryChild.id !== ''
            ? eventCategoryChild.id
            : eventCategoryParent && eventCategoryParent.id !== ''
                ? eventCategoryParent.id
                : '';

        if (!categoryId || categoryId === '') {
            dispatch(showSnackbar({message: t("event_category_not_empty"), type: "error"}))
            return;
        }

        if (!title) {
            setTitleError(true)
            return;
        }

        let startEvent = new Date(date);
        let endEvent = new Date(date);
        if (allDay) {
            startEvent.setHours(0);
            startEvent.setMinutes(0);
            endEvent.setHours(23);
            endEvent.setMinutes(59);
        } else {
            let time = start.split(":");
            startEvent.setHours(time[0]);
            startEvent.setMinutes(time[1]);
            endEvent.setHours(startEvent.getHours() + 1);
            endEvent.setMinutes(startEvent.getMinutes());
        }

        let pp = [];
        for (let a = 0; a < participants.length; a++) {
            pp.push(participants[a].id)
        }

        let pp2 = [];
        for (let a = 0; a < privateParticipants.length; a++) {
            pp2.push(privateParticipants[a].id)
        }

        const data = {
            title,
            start: formatDate(startEvent, 'yyyy-MM-dd HH:mm:ssXXX'),
            end: formatDate(endEvent, 'yyyy-MM-dd HH:mm:ssXXX'),
            is_private: !isPublic,
            guests: pp,
            private_guest: pp2,
            categories: categories,
            is_fullday: allDay,
            ownerId: owner,
            premium_display_poster: isPremiumDisplayPoster,
            categoryId: categoryId
        };
        saveEvent(data)

    }

    const handleStartChange = (e) => {
        setStart(e.target.value)
        setAllDay(false)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handlePartChange = (data) => {
        setPart(data)
    }

    const handlePrivatePartChange = (data) => {
        setPrivatePart(data)
    }

    const handleCatChange = (data) => {
        setCat(data)
    }

    const handleAllDayChange = (e) => {
        if (e.target.checked) {
            setStart("")
            setAllDay(e.target.checked)
        } else {
            setStart("09:00")
            setAllDay(e.target.checked)
        }
    }

    const saveEvent = (data) => {
        services.events.create(data)
            .then((resp) => {
                dispatch(updateCalendar(date))
                dispatch(showSnackbar({message: "event added successfully", type: "success"}))
                props.onClose()
            })
    }

    const checkAdmin = () => {
        if (user && user.roles && user.roles.includes('admin')) {
            return true
        }
        return false
    }

    const handleOwnerChange = (data) => {
        if (data) {
            setOwner(data.id)
        } else {
            setOwner(user.id)
        }
    }

    const handleChangeEventCategoryParent = (e, newCategory) => {
        setEventCategoryParent(newCategory);
    }

    const handleChangeEventCategoryChild = (e, newCategory) => {
        setEventCategoryChild(newCategory);
    }

    const initCategories = () => {

        if (eventCategories) {
            let privateLife = eventCategories.find(r => r.id === "4c1d1a83-4e25-4068-aac6-9688d51aeedf");

            if (privateLife) {
                setEventCategoryParent(privateLife);
            } else {
                setEventCategoryParent(eventCategories[0]);
            }
        }

    }

    const eventCategoryParentTrigger = () => {
        setEventCategoryChild(emptyValue);
    };

    const ChildEventCategoriesList = () => {

        if (eventCategoryParent && eventCategoryParent.id && eventCategories.some(c => c.parentId === eventCategoryParent.id)) {
            return <Grid item xs={12}>
                <EventCategoriesList
                    options={eventCategories.filter(c => c.parentId === eventCategoryParent.id)}
                    value={eventCategoryChild}
                    onChange={handleChangeEventCategoryChild}
                    placeholder={t('eventForm:forms_search_subcategories_placeholder')}
                    quick
                />
            </Grid>
        }

        return null;
    }

    useEffect(initCategories, []);

    useEffect(eventCategoryParentTrigger, [eventCategoryParent]);

    return (
        <Box bgcolor="grid.content">
            <Grid container className="hide-scroll quick-event-form">
                <Grid item xs={12} className="header">
                    <span className="subtitle"> {t('heading_quick_event')} </span>
                    <CloseIcon className="close-btn" onClick={props.onClose}/>
                </Grid>
                {checkAdmin() &&
                <Grid item xs={12}>
                    <OwnerList initialData={user} owner={true} handleChange={handleOwnerChange}/>
                </Grid>
                }
                <Grid item xs={12}>
                    <Box
                        className={`title-time-box ${(darkTheme ? "is-input-dark" : "is-input-light")} ${isFocus ? "is-focus" : ""} ${titleError ? " error" : ""}`}
                        bgcolor="background.default" display="flex" flexDirection="row" justifyContent="center"
                        alignItems="center">
                        <TextField
                            fullWidth
                            required
                            className="quick-input"
                            error={titleError}
                            type="text"
                            placeholder={t('forms_label_title')}
                            value={title}
                            variant="outlined"
                            onKeyDown={handleKeyDown}
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                            ref={input => input && input.focus()}
                            autoFocus
                            onChange={(e) => {
                                setTitle(e.target.value)
                                !e.target.value ? setTitleError(true) : setTitleError(false)
                            }}
                        />
                        <TextField
                            required
                            type="time"
                            value={start}
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                            placeholder="--:--"
                            className={"quick-time"}
                            onChange={handleStartChange}/>
                        <AccessTimeIcon className="quick-time-icon"/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <EventCategoriesList
                        options={eventCategories.filter(c => !c.parentId)}
                        value={eventCategoryParent}
                        onChange={handleChangeEventCategoryParent}
                        placeholder={t('forms_search_categories_placeholder')}
                        quick
                    />
                </Grid>
                <ChildEventCategoriesList/>
                <Grid item xs={12}>
                    <ParticipantsList quick={true} handleChange={handlePartChange}/>
                </Grid>
                <Grid className="private-box" item container direction="row" justifyContent="flex-start" xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <img src={isPublic ? publicEvent : privateEvent} alt="latency" className="switch-icon"/>
                        <span>{isPublic ? t('eventForm:heading_public_event') : t('eventForm:heading_private_event')}</span>
                        <Switch size="small" checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)}
                                className="switch"/>
                    </Box>
                </Grid>
                {!isPublic &&
                <Grid item xs={12}>
                    <ParticipantsList quick={true} private={true} handleChange={handlePrivatePartChange}/>
                </Grid>
                }
                <Grid className="all-day-box" item container direction="row" justifyContent="flex-start" xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <WatchLaterIcon className={"switch-icon " + (allDay ? "green" : "grey")}/>
                        <span>{allDay ? t('eventForm:forms_label_allDay') : t('eventForm:forms_label_selectedHours')}</span>
                        <Switch size="small" checked={allDay} onChange={handleAllDayChange} className="switch"/>
                    </Box>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <CustomButton className="submit-btn small"
                                  onClick={handleSubmit}> {t('buttons_save_event')} </CustomButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default QuickEventForm;
