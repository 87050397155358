import React, {useEffect, useState} from "react";
import {eachDayOfInterval} from "date-fns";

import {formatDate, getCurrentTimezone, ownStartOfDay} from "../../../../../helpers/dateFormatter";
import services from "../../../../../services";
import LandingEventsPassedPipe from "./LandingEventsPassedPipe";
import LandingEventsPassedPipeByDay from "./LandingEventsPassedPipeByDay";
import CircularProgress from "@material-ui/core/CircularProgress";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const EventsPassedComponentByDay = ({userId}) => {
    const [inputDay, setInputDay] = useState(new Date());
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const prepareData = async (day) => {
        let q = {
            ownerId: userId,
            start: formatDate(ownStartOfDay(day), dateFormat),
            timezone: getCurrentTimezone(),
            paginate: false,
            bundle: true,
            landingVOD: true,
            isLive: false,
            $limit: 8,
            $skip: 0
        }
        return await services.events.find({
            query: q
        }).then((res) => {
            if (res.data.length > 0) {
                return {
                    day: day,
                    events: res,
                    query: q
                }
            } else {
                return {
                    day: day,
                    events: {data: [], total: {length: 0}},
                    query: q
                }
            }
        }).catch((err) => {
            return {
                day: day,
                events: {data: [], total: {length: 0}},
                query: q
            }
        });
    }

    useEffect(() => {
        setLoading(true);

        prepareData(inputDay).then(res => {
            setData(res);
            setLoading(false);
        });
    }, [userId]);

    const addDateHandler = () => {
        setLoading(true);

        setInputDay((pre) => {
            let date = pre;
            date.setDate(date.getDate() + 1);

            prepareData(date).then(res => {
                setData(res);
                setLoading(false);
            });

            return date
        })
    };

    const subtractDateHandler = () => {
        setLoading(true);

        setInputDay((pre) => {
            let date = pre;
            date.setDate(date.getDate() - 1);

            prepareData(date).then(res => {
                setData(res);
                setLoading(false);
            });

            return date
        })
    };

    return (
        <div className={"landing-tab-passed-vods-container"}>
            {data && <LandingEventsPassedPipeByDay
                day={data.day}
                data={data.events}
                query={data.query}
                addDayHandler={addDateHandler}
                subtractDayHandler={subtractDateHandler}/>}
        </div>
    );

};

export default EventsPassedComponentByDay;
