import React from 'react';
import {Box, Tooltip} from '@material-ui/core';
import { formatDateLocale } from '../../../helpers/dateFormatter';
import { useSelector } from 'react-redux';
import location from "../../../assets/icons/event/icon-pin-location.svg";
import cake from "../../../assets/icons/user/balloon.svg";
import hyperlink from '../../../assets/icons/event/icon-hyperlink.svg';
import MiddleEllipsis from "react-middle-ellipsis";
import {useTranslation} from "react-i18next";

const ProfileDescription = ({ responsive }) => {
    const openedProfile = useSelector(state => state.openedProfile);
    const {t} = useTranslation(['userEditForm']);

    return (
        <Box className="profile-description" display="flex" flexDirection={responsive ? "column-reverse" : "row"} justifyContent="space-between">
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-end" className="personal-data">
                <span className="location">
                    {openedProfile.location ? openedProfile.location : ""} {openedProfile.location && openedProfile.location.length > 0 && <Tooltip title={t('userEditForm:forms_city')} placement="right" arrow><img src={location} alt="city" className="icon" /></Tooltip>}
                </span>
                <span className="dob"> {formatDateLocale(new Date(openedProfile.birthday ? openedProfile.birthday : new Date()))} {openedProfile.birthday && openedProfile.birthday.length > 0 && <Tooltip title={t('userEditForm:forms_birthDate')} placement="right" arrow><img src={cake} alt="birthday" className="icon" /></Tooltip>}</span>
                <span className="website-wrap">
                    <MiddleEllipsis>
                        <a href={(openedProfile.website && openedProfile.website.indexOf('://') === -1) ? 'https://' + openedProfile.website : openedProfile.website} rel="noopener noreferrer" target="_blank" className="website">{openedProfile.website}</a>
                    </MiddleEllipsis>
                    {openedProfile.website && openedProfile.website.length > 0 && <Tooltip title={t('userEditForm:forms_website')} placement="right" arrow><img src={hyperlink} alt="website" className="icon" /></Tooltip>}</span>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" className="bio">
                <span className="biography"> {openedProfile.biography} </span>
            </Box>
        </Box>
    )
}

export default ProfileDescription;
