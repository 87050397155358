import React, { useState, Fragment, useEffect, useRef } from 'react';
import './event-info-new.scss';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@material-ui/core';
import services from '../../../services/';
import { useSelector, useDispatch } from 'react-redux';
import UserInfo from '../../User/UserInfo/UserInfo';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '../../Modal/Modal';
import { setOpenedEvent } from '../../../store/openedEvent/actions';
import { showSnackbar } from '../../../store/snackbar/actions';
import { useTranslation } from 'react-i18next';
import ImageCropper from "../../ImageCropper/ImageCropper";
import CustomButton from '../../CustomButton/CustomButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import ZoomIcon from '@material-ui/icons/ZoomOutMap';
import { closeEventInfoModal } from '../../../store/eventInfoModal/actions';
import { setAgendaWeeklyEvents } from '../../../store/agendaWeeklyEvents/actions';
import { setMonthlyEventsCount } from '../../../store/monthlyEventsCount/actions';
import EventTags from './EventTags';
import EventHeader from './EventHeader';
import EventTabs from './EventTabs';
import { useRouteMatch } from 'react-router-dom';
import EventActionButtons from "./EventActionButtons";
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {setOpenedProfileWeeklyEvents} from "../../../store/openedProfileWeeklyEvents/actions";
import AddEventFormComponent from "../../Forms/NewAddEventForm/AddEventFormComponent";

const EventInfo = (props) => {
  const darkTheme = useSelector(state => state.darkTheme);
  const event = useSelector(state => state.event);
  const user = useSelector(state => state.user);
  const selectedDate = useSelector(state => state.selectedDate);
  const openedProfile = useSelector(state => state.openedProfile);
  const deviceView = useSelector(state => state.deviceView);
  const [quickCropModal, showQuickCropModal] = useState(false);
  const [newPoster, setNewPoster] = useState("");
  const [edit, setEdit] = useState(false);
  const [modalPoster, setModalPoster] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation(['extraData', 'eventForm']);
  const [loading, setLoading] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentTab, setCurrentTab] = useState("chat");
  const [eventPoster, setEventPoster] = useState((event && event.poster ? event.poster : isOwner ? "/images/defaultHeader3.png" : "/images/defaultHeader2.png"));
  const [eventOriginalPoster, setEventOriginalPoster] = useState((event && event.original_poster ? event.original_poster : ""));
  const [openConfirmationEraseEvent, setOpenConfirmEraseEvent] = useState(false);
  const [hideControllersForAdmin, setHideControllersForAdmin] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const TutorialOverlayEl = React.useRef(null);

  const tutorialsSelector = useSelector(state => state.tutorials);

  let isEmbedMode = useRouteMatch("/embed/:userNickname/:eventID?");
  const inputPosterRef = useRef(null);

  useEffect(() => {
    if (event && event.id) {
      setIsOwner(!!(user && user.id && user.id === event.owner.id));
      setIsAdmin(user && user.id ? user.roles.includes('admin') : false);
      setEventPoster((event && event.poster ? event.poster : isOwner ? "/images/defaultHeader3.png" : "/images/defaultHeader2.png"));
      setEventOriginalPoster((event && event.original_poster ? event.original_poster : ""));
    }
  }, [user, event, isOwner]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const back = () => {
    setEdit(false)
  };

  const onClose = () => {
    setEdit(false);
    dispatch(setOpenedEvent(event.id));
  };

  const saveEvent = (e) => {
    e.preventDefault();
    setLoading(true);
    services.events.patch(event.id, {
      poster: newPoster,
      original_poster: eventOriginalPoster
    }, null)
      .then(() => {
        dispatch(showSnackbar({ message: t("EventForm:new_poster_added"), type: "success" }));
        setLoading(false);
        setEventPoster(newPoster);
        setNewPoster("");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
  };

  const quickPosterChange = (e) => {
    showQuickCropModal(true);
    let file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setNewPoster(reader.result);
      setEventOriginalPoster(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const deleteEvent = () => {
    services.events.remove(event.id)
      .then((resp) => {
        dispatch(closeEventInfoModal())
        if (window.history.state && window.history.state.calendar === true) {
          dispatch(setAgendaWeeklyEvents(selectedDate));
          dispatch(setMonthlyEventsCount(selectedDate))
          window.history.pushState({}, "calendar", `/agenda`)
        } else if (window.history.state && window.history.state.hasOwnProperty('backRef')) {
          dispatch(setAgendaWeeklyEvents(selectedDate));
          dispatch(setMonthlyEventsCount(selectedDate))
          window.history.pushState({}, "calendar", `${window.history.state.backRef}`)
        } else {
          dispatch(setOpenedProfileWeeklyEvents(selectedDate, openedProfile.id))
          dispatch(setMonthlyEventsCount(selectedDate, openedProfile.id))
          window.history.pushState({}, "profile", `/${openedProfile.slug}`)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const onChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Fragment>
      {event.id && (
        <Box bgcolor="background.default" className="event-info-wrapper">
          <div id="portal--tutorial-overlay" ref={TutorialOverlayEl}></div>

          {(deviceView === "browser" || deviceView === "tablet") && (
            <Box className={"event-info" + (darkTheme ? ' is-dark' : ' is-light')} display="grid">
              <Box className="info" display="grid">
                <div className="floating-action-buttons">
                  {deviceView === "tablet" &&
                  <CloseIcon onClick={props.onClose} className="close-icon"/>}
                </div>
                <EventHeader isOwner={isOwner}/>
                <Box className="info-middle">
                  <EventTags event={event} changeTab={onChangeTab}/>
                  <UserInfo profile={event.owner} id={event.owner.id} toProfile={true}
                            isProfile={false}
                            isEmbed={isEmbedMode}/>
                </Box>
                <EventTabs changeTab={onChangeTab} currentTab={currentTab}/>
              </Box>
              <Box className="poster" display="grid">
                {eventOriginalPoster.length > 0 && <Box className="bg-poster" style={{
                  backgroundImage: `url(${eventOriginalPoster})`,
                }}></Box>}
                <Box className="poster-overlay">
                  {(deviceView === "tablet" || deviceView === "browser") && isOwner &&
                  <Fragment>
                    {!isEmbedMode && (
                      <Fragment>
                        <div className="floating-action-buttons">
                          <CustomButton className="submit-btn wide e-small"
                                        onClick={() => setEdit(true)}>
                            <EditIcon className="icon"/>
                            <span>{t('eventDetails:buttons_edit_event')}</span>
                          </CustomButton>
                          <CustomButton className="delete-btn wide e-small"
                                        onClick={() => {
                                          setOpenConfirmEraseEvent(true);
                                          //deleteEvent();
                                        }}>
                            <DeleteIcon className="icon"/>
                            <span>{t('eventDetails:buttons_delete_event')}</span>
                          </CustomButton>
                        </div>
                      </Fragment>
                    )}
                    <div className="floating-action-buttons-2">
                      {!newPoster && <CustomButton className="submit-btn wide e-small"
                                                   onClick={() => inputPosterRef.current.click()}>add
                        poster</CustomButton>}
                      {!newPoster &&
                      <input ref={inputPosterRef} type="file" className="poster-input"
                             id="quick-event-image"
                             accept="image/gif, image/jpeg, image/png"
                             onChange={quickPosterChange}/>}
                      {newPoster &&
                      <CustomButton loading={loading} disabled={loading}
                                    className="submit-btn wide e-small"
                                    onClick={(e) => saveEvent(e)}> {t('extraData:buttons_confirm')} </CustomButton>}
                      {newPoster && <CustomButton disabled={loading} className="cancel-btn wide e-small"
                                                  onClick={() => setNewPoster("")}> {t('extraData:buttons_cancel')} </CustomButton>}
                    </div>
                  </Fragment>}
                  {(deviceView === "tablet" || deviceView === "browser") && (!isOwner && isAdmin) &&
                  <Fragment>
                    {hideControllersForAdmin &&
                    <div className="floating-action-buttons" style={{top: '1em', minWidth: 'auto'}}>
                      <CustomButton className="submit-btn e-hidebtn"
                                    onClick={() => setHideControllersForAdmin(false)}>
                        <MoreVertIcon className="icon"/>
                      </CustomButton>
                    </div>
                    }
                    {!hideControllersForAdmin && (
                      <>
                        {!isEmbedMode && (
                          <Fragment>
                            <div className="floating-action-buttons">
                              <CustomButton className="submit-btn wide e-small"
                                            onClick={() => setEdit(true)}>
                                <EditIcon className="icon"/>
                                <span>{t('eventDetails:buttons_edit_event')}</span>
                              </CustomButton>
                              <CustomButton className="delete-btn wide e-small"
                                            onClick={() => {
                                              setOpenConfirmEraseEvent(true);
                                              //deleteEvent();
                                            }}>
                                <DeleteIcon className="icon"/>
                                <span>{t('eventDetails:buttons_delete_event')}</span>
                              </CustomButton>
                            </div>
                          </Fragment>
                        )}
                        <div className="floating-action-buttons-2">
                          {!newPoster && <CustomButton className="submit-btn wide e-small"
                                                       onClick={() => inputPosterRef.current.click()}>add
                            poster</CustomButton>}
                          {!newPoster &&
                          <input ref={inputPosterRef} type="file" className="poster-input"
                                 id="quick-event-image"
                                 accept="image/gif, image/jpeg, image/png"
                                 onChange={quickPosterChange}/>}
                          {newPoster &&
                          <CustomButton loading={loading} disabled={loading}
                                        className="submit-btn wide e-small"
                                        onClick={(e) => saveEvent(e)}> {t('extraData:buttons_confirm')} </CustomButton>}
                          {newPoster && <CustomButton disabled={loading} className="cancel-btn wide e-small"
                                                      onClick={() => setNewPoster("")}> {t('extraData:buttons_cancel')} </CustomButton>}
                        </div>
                      </>
                    )}
                  </Fragment>}
                  <img src={newPoster ? newPoster : eventPoster} alt="event-header"/>
                </Box>
              </Box>
            </Box>
          )}

          {deviceView === "mobile" && (
            <Box className="event-info is-mobile" display="grid">
              <Box className="modal-header" display="flex" flexDirection="row" alignItems="center">
                <ArrowLeftIcon onClick={props.onClose} className="close-icon"/>
                <UserInfo profile={event.owner} id={event.owner.id} toProfile={true} isProfile={false}
                          isEmbed={isEmbedMode}/>
                {(isOwner || isAdmin) && (
                  <Fragment>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon/>
                    </IconButton>
                    <Menu
                      id="more-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => {
                        setEdit(true);
                        handleClose();
                      }}>{t('eventDetails:buttons_edit_event')}</MenuItem>
                      <MenuItem onClick={() => {
                        deleteEvent();
                        handleClose();
                      }}>{t('eventDetails:buttons_delete_event')}</MenuItem>
                    </Menu>
                  </Fragment>)}
              </Box>
              <Box className="poster" onClick={() => setModalPoster(true)}>
                                <span className="icon">
                                    <ZoomIcon fontSize="large"/>
                                </span>
                <img src={newPoster ? newPoster : eventPoster} alt="event-header"/>
              </Box>
              <Box className="info" bgcolor="background.default">
                <Box className={"event-header" + (darkTheme ? ' is-dark' : ' is-light')}>
                  <EventHeader isOwner={isOwner}/>
                </Box>
                <Box className="info-middle">
                  <EventTags event={event} changeTab={onChangeTab}/>
                  <EventActionButtons tutorials={tutorialsSelector} refTutorialOverlayEl={TutorialOverlayEl} />
                </Box>
                <EventTabs changeTab={onChangeTab} currentTab={currentTab}/>
              </Box>
            </Box>
          )}
          <Modal
            state={edit}
            persist={false}
            fullScreen={deviceView === "mobile"}
            max={"md"}>

            <AddEventFormComponent event={event} onBack={back} onClose={onClose}/>
          </Modal>
          <Modal
            state={quickCropModal}
            persist={false}
            max={"lg"}
            onClose={() => showQuickCropModal(false)}>

            <ImageCropper
              cropper={deviceView === "browser" ? {
                width: 380,
                height: 680
              } : (deviceView === "tablet" ? { width: 380, height: 680 } : { width: 380, height: 680 })}
              className="add-event"
              bg={eventOriginalPoster}
              poster={newPoster} onCrop={(img) => setNewPoster(img)}
              onClose={() => showQuickCropModal(false)}/>
          </Modal>
          <Modal
            state={modalPoster}
            persist={false}
            fullScreen={false}
            max={"md"}>
            <Box className={'event-modal-poster'}>
              <CloseIcon onClick={() => setModalPoster(false)} className="close-icon"/>
              <img className={'event-modal-poster'} src={newPoster ? newPoster : eventPoster}
                   alt="event-header"/>
            </Box>
          </Modal>
          <Dialog
            open={openConfirmationEraseEvent}
            onClose={() => setOpenConfirmEraseEvent(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t('eventForm:dialog_erase_event_headtitle')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"
                                 className={"dialog-description " + (darkTheme ? ' is-dark' : ' is-light')}>
                {t('eventForm:dialog_erase_event_text')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmEraseEvent(false)} color="primary">
                {t('eventForm:dialog_erase_event_cancel_btn')}
              </Button>
              <Button onClick={() => {
                deleteEvent();
                setOpenConfirmEraseEvent(false);
              }} color="primary" autoFocus>
                {t('eventForm:dialog_erase_event_confirm_btn')}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Fragment>
  )
}

export default EventInfo;
