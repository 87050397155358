import React from "react";
import i18next from 'i18next';
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setSettingsGeneralLanguage} from "../../../../store/settings/actions";
import {useTranslation} from "react-i18next";

const GeneralLanguageForm = () => {
    const {t} = useTranslation('settings');
    const dispatch = useDispatch();
    const settingsGeneral = useSelector(state => state.settings.general);

    const handleChangeLanguage = (lang) => {
        i18next.changeLanguage(lang).then(() => dispatch(setSettingsGeneralLanguage(lang)));
    };

    return (
        <div>
            <div className="settings-content-header">
                <div className="settings-content-title">{t('section_general_language')}</div>
                <div className="settings-content-title-helper">{t('section_general_language_helper')}</div>
            </div>
            <div className="settings-content-main settings-content-general settings-content-language">
                <FormControl variant="outlined">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={settingsGeneral.language}
                        onChange={(e) => handleChangeLanguage(e.target.value)}
                    >
                        <MenuItem value="es"> Español </MenuItem>
                        <MenuItem value="en"> English </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default GeneralLanguageForm;
