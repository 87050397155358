import { Grid, TextField } from "@material-ui/core";
import React from "react";

const CustomSocialNetworkField = ({imageIcon, isDarkTheme, placeholder, value, onChange, helperText, isError}) => {
  return (
    <Grid container spacing={1} wrap="nowrap" alignItems="flex-start" className="custom-social-network-field">
      <Grid item>
        <img src={imageIcon} alt={placeholder} className="icon" />
      </Grid>
      <Grid item className="fullFlex">
        <TextField
          fullWidth
          className={(isDarkTheme ? "input-dark" : "input-light") + " withHelperText"}
          type="text"
          placeholder={placeholder}
          value={value}
          helperText={helperText}
          variant="outlined"
          onChange={onChange}
          error={isError}
        />
      </Grid>
    </Grid>
  )
};

export default CustomSocialNetworkField;
