import React, { useEffect } from 'react';
import './terms.scss';
import { useSelector } from "react-redux";
import TermsES from "./TermsES";
import TermsEN from "./TermsEN";

const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const settingsGeneral = useSelector(state => state.settings.general);
    if(settingsGeneral.language === 'es') {
        return <TermsES/>
    } else {
        return <TermsEN/>
    }
}

export default Terms;
