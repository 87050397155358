import React from 'react';
import {Box} from '@material-ui/core';
import CustomLink from '../CustomLink/CustomLink';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import ThemeToggle from "./ThemeToggle";

const MenuItems = () => {
    const {t} = useTranslation(['UserMenu']);
    const deviceView = useSelector(state => state.deviceView);

    const items = [
        {name: t('menu_label_explore'), to: '/explore'},
        {name: 'login', to: '/login'},
    ]
    return (
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
            {items.map((item, index) =>
                <CustomLink className={'link light-link'} key={index} to={item.to}>{item.name}</CustomLink>
            )}
            {deviceView === "browser" && <ThemeToggle/>}
        </Box>
    )
}

export default MenuItems;
