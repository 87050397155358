import React, {useEffect, useState} from 'react';
import './_group_styles.scss';
import {useTranslation} from "react-i18next";
import VlrInlineUpcomingItemComponent
    from "./VlrInlineUpcomingItemComponent";

const VlrGroupTournamentItemComponent = ({ tournamentName, events, onOpenEventModal, nowDate, type }) => {
    const { t } = useTranslation('extraData');
    const [tournamentImage, setTournamentImage] = useState(null);

    useEffect(() => {
        let monkprint = events[0];
        setTournamentImage(monkprint.original_poster);
    }, [events])

    return (
        <div className="vlr-landing-score-group">
            <div className="vlr-landing-score-group--heading is-tournament">
                {tournamentImage && <img src={tournamentImage} alt={tournamentName}/>}
                {tournamentName}
            </div>
            <div className="vlr-landing-score-group--matches">
                {events.map(ev => <VlrInlineUpcomingItemComponent key={ev.id} event={ev} onOpenEventModal={onOpenEventModal}
                                                          nowDate={nowDate} type={type} />)}
            </div>
        </div>
    );
};

export default VlrGroupTournamentItemComponent;
