export const setLogin = () => {
    return dispatch => {
        dispatch({type: 'SET_LOGIN'})
    }
}

export const unsetLogin = () => {
    return dispatch => {
        dispatch({type: 'UNSET_LOGIN'})
    }
}
