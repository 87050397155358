const initailState = {
    loading: true,
    data: []
}

const agendaWeeklyEventsReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SET_AGENDA_WEEKLY_EVENTS':
            return {...state, loading: false, data: action.payload}
        case 'UNSET_AGENDA_WEEKLY_EVENTS':
            return state = initailState
        case 'SET_AGENDA_WEEKLY_EVENTS_LOADING':
            return {...state, loading: true}
        default:
            return state
    }
}

export default agendaWeeklyEventsReducer;
