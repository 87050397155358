import React, {useEffect, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Box, Tooltip} from "@material-ui/core";
import {ReactComponent as ShareIcon} from "../../../assets/icons/event/share.svg";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import {formatDate} from "../../../helpers/dateFormatter";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FacebookIcon from "@material-ui/icons/Facebook";
import ListItemText from "@material-ui/core/ListItemText";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {showSnackbar} from "../../../store/snackbar/actions";
import LinkIcon from "@material-ui/icons/Link";
import EventTimezoneSwitcher from "./EventTimezoneSwitcher";
import NewEventActionButtons from "./NewEventActionButtons";
import EventIsPrivate from "./EventIsPrivate";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import NewTrophy from "../../../assets/icons/achievement.png";

const NewEventHeader = ({refTutorialOverlayEl, onClose, handleToggleArchivementModal}) => {
    const user = useSelector(state => state.user);
    const darkTheme = useSelector(state => state.darkTheme);
    const event = useSelector(state => state.event);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [categoryCover, setCategoryCover] = React.useState('');
    const dispatch = useDispatch();
    const {t} = useTranslation(['eventDetails']);
    const eventCategoriesSelector = useSelector(state => state.eventCategories);
    const tutorialsSelector = useSelector(state => state.tutorials);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (event && event.id) {
            setIsOwner(!!(user && user.id && user.id === event.owner.id));
        }
    }, [user, event, isOwner]);

    useEffect(() => {
        if (event && event.categoryId && eventCategoriesSelector) {
            let category = eventCategoriesSelector.find(x => x.id == event.categoryId && x.cover_url);
            if (category) {
                setCategoryCover(process.env.REACT_APP_CDN + category.cover_url);
            }
        }
    }, [event]);
    return (
        <div className="info-header-wrapper">
            <div className="info-header">
                {categoryCover ? (
                    <picture>
                        <source srcSet={categoryCover + ".webp"} type="image/webp"/>
                        <source srcSet={categoryCover} type="image/jpeg"/>
                        <img width={"72px"} src={categoryCover} alt="category cover"/>
                    </picture>
                ) : <span/>}
                {event.title &&
                <div className="info-middle">
                    <div className="info-middle-title-wrap">
                        <Tooltip title={event.title} placement="top" arrow>
                                <span className="event-title-container">
                                    {event.title}
                                </span>
                        </Tooltip>
                        <span className="share-icon">
                                <IconButton color="primary" aria-label={t('tooltip_share_event')} component="span"
                                            onClick={handleClick} size="small">
                                    <ShareIcon title={t('tooltip_share_event')}
                                               tooltip={t('tooltip_share_event')}
                                               alt={t('tooltip_share_event')}/>
                                </IconButton>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <div
                                        className={"content-popover-share" + (darkTheme ? " is-dark" : " is-light")}>
                                        <List dense={true}
                                              component="nav"
                                              subheader={
                                                  <ListSubheader component="div" id="nested-list-subheader">
                                                      {t('title_share_event_popover')}
                                                  </ListSubheader>
                                              }>
                                            <FacebookShareButton
                                                url={`“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}`}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <FacebookIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Facebook"/>
                                                </ListItem>
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={`“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}`}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <TwitterIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Twitter"/>
                                                </ListItem>
                                            </TwitterShareButton>
                                            <WhatsappShareButton
                                                url={`“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}`}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <WhatsappIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Whatsapp"/>
                                                </ListItem>
                                            </WhatsappShareButton>
                                            <CopyToClipboard
                                                text={`${window.location.hostname}/${event.owner.slug}/${event.id}`}
                                                onCopy={() => dispatch(showSnackbar({
                                                    message: t('option_share_event_popover_clipboard_success'),
                                                    type: "success"
                                                }))}
                                            >
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <LinkIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t('option_share_event_popover_clipboard')}/>
                                                </ListItem>
                                            </CopyToClipboard>
                                            <CopyToClipboard
                                                text={`<blockquote class="pinging-event-card" data-event="${event.id}"><a href="https://${window.location.hostname}/${event.owner.slug}/${event.id}">“${event.title}” @${formatDate(event.start, "dd/MM p")} +info https://${window.location.hostname}/${event.owner.slug}/${event.id}</a></blockquote><script async src="//widget.pingingapp.com/widget.js" charset="UTF-8"></script>`}
                                                onCopy={() => dispatch(showSnackbar({
                                                    message: t('option_share_event_popover_clipboard_success'),
                                                    type: "success"
                                                }))}
                                            >
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <LinkIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t('option_share_event_popover_clipboard_embed')}/>
                                                </ListItem>
                                            </CopyToClipboard>
                                        </List>
                                    </div>
                                </Popover>
                            </span>
                        {user && user.hasOwnProperty('id') && (
                            <span className="archivement-icon is-active">
                                <IconButton color="primary" aria-label={t('tooltip_share_event')} component="span"
                                            onClick={handleToggleArchivementModal} size="small">
                                    {/*
                                    <TrophyIcon title={t('tooltip_share_event')}
                                                tooltip={t('tooltip_share_event')}
                                                alt={t('tooltip_share_event')}/>
                                                */}
                                                <img width="27" height="27" src={NewTrophy} alt="Achivement" className="trophy"/>

                                </IconButton>
                            </span>
                        )}
                    </div>
                    <EventTimezoneSwitcher allDay={event.is_fullday} eventStart={event.start}
                                           eventEnd={event.end}/>
                    <NewEventActionButtons tutorials={tutorialsSelector} refTutorialOverlayEl={refTutorialOverlayEl}/>
                    <Box display="flex" alignItems="center" justifyContent="start">
                        <EventIsPrivate/>
                    </Box>
                </div>
                }
            </div>
        </div>
    )
}

export default NewEventHeader;
